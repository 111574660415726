import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { Loading } from '../helper/Misc'
import { toIMGcanvas } from '../helper/PDFImage'
import ChartsPieSVG from './Charts-Pie-SVG'
import { YearDropDown, PieGetPartnerGroupBilling } from './Charts-AClass-Functions'

import { Segment, Grid, Checkbox } from 'semantic-ui-react';

// import DisplayState from '../helper/DisplayState';

class ChartsPie extends Component {

  state = {
    TokenReady: false,
    loading: true,
    currentYear: new Date().getFullYear(),
    selectedYear: new Date().getFullYear(),
    selectedPartner: 0,
    showByInvoicingPartner: !this.props.byOriginating,
    fetchObject: {},
    Loading: true,
  }


  async componentDidMount() {
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      PieGetPartnerGroupBilling(this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ fetchObjectByInvoicing: value[0], fetchObjectByOriginating: value[1], Loading: false }))
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.loading) {
      this.setState({ TokenReady: true })
    }
    if (prevProps.login.currentToken !== this.props.login.currentToken) {
      PieGetPartnerGroupBilling(this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ fetchObjectByInvoicing: value[0], fetchObjectByOriginating: value[1], Loading: false }))
    }
  }

  render() {

    let chartStyle = {
      background: "#ebf5fa",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      fontFamily: "'Fira Sans', sans-serif",
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderColor: '#a5a5a5',
      borderRadius: '15px',
      boxShadow: '2px 2px 8px #888888',
      cursor: 'pointer',
    }

    return (

      <React.Fragment>

        {/* <DisplayState state={this.state} /> */}
        {/* <Header as='h3'>Pie Chart</Header>
        <Divider /> */}

        <Grid>
          <Grid.Row verticalAlign='bottom'>
            <Grid.Column width={4}>
              <YearDropDown year={this.state.selectedYear} maxYears={this.props.maxYears} includeAll={true} currentYear={this.state.currentYear}
                callback={(value) => this.setState({ selectedYear: value })} />
            </Grid.Column>
            <Grid.Column width={12}>
              <Segment compact color='blue'>
                <Checkbox
                  toggle
                  // style={checkboxStyle}
                  label={this.state.showByInvoicingPartner ? 'By Invoicing Partner' : 'By Originating Partner'}
                  // value={this.state}
                  checked={this.state.showByInvoicingPartner}
                  onChange={() => this.setState((prevState) => ({ showByInvoicingPartner: !prevState.showByInvoicingPartner }))}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
            <div id='Pie' >
              {this.state.Loading ? <Loading /> :
                <svg
                  style={chartStyle}
                  onClick={() => toIMGcanvas('Pie')}
                  height={600 * this.props.maxViewPercentage / 100} viewBox="0 0 600 350">
                  <ChartsPieSVG
                    selectedYear={this.state.selectedYear}
                    currentYear={this.state.currentYear}
                    selectedPartner={this.state.selectedPartner}
                    maxViewPercentage={this.props.maxViewPercentage}
                    maxYears={this.props.maxYears}
                    fetchObject={this.state.showByInvoicingPartner ? this.state.fetchObjectByInvoicing : this.state.fetchObjectByOriginating}
                    byInvoicingEmployee={this.state.showByInvoicingPartner}
                  />
                </svg>
              }
            </div>
          </Segment>
        </div>
      </React.Fragment >
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsPie))


ChartsPie.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  byOriginating: true
  // handleSomething: () => { },
}
