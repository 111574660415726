//This reducer is to keep track of whether we are logged in or not

const initialstate = {
  authenticated: null,
  currentUserName: '',
  currentUserEmail: '',
  currentToken: '',
  currentIcon: '',
  currentLevel: 5,
  currentGuest: 0,
  currentObserver: 0,
  currentShowCharts: 1,
  currentShowFinances: 1,
  currentDB: '',
  currentDev: false,
  refreshToken: 0,
}

export default (state =
  initialstate,
  action) => {
  switch (action.type) {

    // case 'LOG_IN':
    //   console.log('Logging in in simplereducer....')
    //   return {
    //     ...state,
    //     loggedIn: true,
    //     number: action.payload
    //   }

    // case 'LOG_OUT':
    //   console.log('Logging out in simplereducer....')
    //   return {
    //     ...state,
    //     loggedIn: false,
    //     number: action.payload
    //   }

    case 'AUTHENTICATE':
      // console.log('Authenticating....')
      return {
        ...state,
        authenticated: action.payload
      }

    case 'CURRENT_USER_EMAIL':
      // console.log('Adding user email....')
      return {
        ...state,
        currentUserEmail: action.payload
      }

    case 'CURRENT_USER_NAME':
      // console.log('Adding user name....')
      return {
        ...state,
        currentUserName: action.payload
      }

    case 'CURRENT_TOKEN':
      // console.log('Adding token....')
      return {
        ...state,
        currentToken: action.payload
      }

    case 'CURRENT_ICON':
      // console.log('Adding icon....', action.payload)
      return {
        ...state,
        currentIcon: action.payload
      }

    case 'CURRENT_ID':
      // console.log('Adding level....')
      return {
        ...state,
        currentUserID: action.payload
      }

    case 'CURRENT_LEVEL':
      // console.log('Adding level....')
      return {
        ...state,
        currentLevel: action.payload
      }

    case 'CURRENT_GUEST':
      // console.log('Adding level....')
      return {
        ...state,
        currentGuest: action.payload
      }

    case 'CURRENT_OBSERVER':
      // console.log('Adding level....')
      return {
        ...state,
        currentObserver: action.payload
      }

    case 'CURRENT_SHOW_CHARTS':
      // console.log('Adding level....')
      return {
        ...state,
        currentShowCharts: action.payload
      }

    case 'CURRENT_SHOW_FINANCES':
      // console.log('Adding level....')
      return {
        ...state,
        currentShowFinances: action.payload
      }

    case 'CURRENT_DEV':
      // console.log('Changing DB....')
      return {
        ...state,
        currentDev: action.payload
      }

    case 'CURRENT_DB':
      // console.log('Changing DB....')
      return {
        ...state,
        currentDB: action.payload
      }

    case 'LOGIN_RESET':
      // console.log('Finished the fetch.... This is the payload: ', action.payload)
      switch (action.payload) {

        case 'authenticated':
          return {
            ...state,
            authenticated: null
          }
        case 'currentUserName':
          return {
            ...state,
            currentUserName: ''
          }
        case 'currentUserEmail':
          return {
            ...state,
            currentUserEmail: ''
          }
        case 'currentToken':
          return {
            ...state,
            currentToken: ''
          }
        case 'currentIcon':
          return {
            ...state,
            currentIcon: ''
          }
        case 'currentLevel':
          return {
            ...state,
            currentLevel: 5
          }
        case 'currentDB':
          return {
            ...state,
            currentDB: ''
          }
        case 'all':
          return initialstate
        default:
          return { ...state }

      }

      case 'REFRESH_TOKEN_RESET':
        // console.log(`Refreshing token with this payload: ${action.payload}`)
        return {
          ...state,
          refreshToken: action.payload
        }

    default:
      return state
  }
} 