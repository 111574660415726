import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

import DeleteNotes from './Component-DeleteNotes'
import DeletePerson from './Component-DeletePerson'
import DeleteCounterparty from './Component-DeleteCounterparty'
// import DeleteLawFirm from './Component-DeleteLawFirm'

// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'

import { isEmptyNullUndefined } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';

let initialDeleteLawFirm = {
  LNotesExist: true,
  LawyersExist: true,
  CounterpartiesExist: true,
}

class LawFirms extends Component {

  state = {
    LawFirms: [],
    selectedLawFirm: null,
    DeleteLawFirm: initialDeleteLawFirm,
    modalOpen: false,
    Row: {},
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getLawFirms()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getLawFirms(), 200)
    }
  }

  getLawFirms = async () => {
    // console.log('In getLawFirms...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getLawFirms', { fetchstr: 'nothing' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse getLawFirms: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      LawFirms: fetchresponse.payload.map(item => item)
    })
  }

  setStateLawFirm = (law_firm_id, callback) => {
    this.setState({ DeleteLawFirm: initialDeleteLawFirm })
    this.setState({ selectedLawFirm: law_firm_id }, () => {if (callback) callback()})
    // if (callback) callback()
  }

  DeleteLawFirm = async () => {
    // console.log('Running DeleteLawFirm on this law firm: ', this.state.selectedLawFirm)

    if (!this.state.DeleteLawFirm.CounterpartiesExist && !this.state.DeleteLawFirm.LNotesExist && !this.state.DeleteLawFirm.LawyersExist) {

      // console.log(`This is law_firm_id: ${this.state.selectedLawFirm}`)

      try {

        //Clear fetcherrors, info and such
        this.props.fetchResetState('all')

        let fetchresponse = {}
        fetchresponse = await szenokPostAPI('deleteLawFirm', {
          lawfirmID: this.state.selectedLawFirm
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteLawFirm: ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {

          //Update the matters store
          // reduxupdate('matters')

          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          this.getLawFirms()
          reduxupdate('lawfirms')
          this.setState({ selectedLawFirm: null, DeleteLawFirm: initialDeleteLawFirm })
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        this.props.fetchErrors(error.toString())
      }
    }
    // else console.log('There is a problem inside Component-LawFirms in DeleteLawFirms.  For some reason, all of the toggles have not been switched.')
  }

  handleDeleteLNote = () => {
    this.setState({ DeleteLawFirm: { ...this.state.DeleteLawFirm, LNotesExist: false } })
    this.DeleteLawFirm()
  }

  handleDeleteLawyers = () => {
    this.setState({ DeleteLawFirm: { ...this.state.DeleteLawFirm, LawyersExist: false } })
    this.DeleteLawFirm()
  }

  handleDeleteCounterparty = () => {
    this.setState({ DeleteLawFirm: { ...this.state.DeleteLawFirm, CounterpartiesExist: false } })
    this.DeleteLawFirm()
  }

  handleCancelDelete = () => {
    this.setState({ DeleteLawFirm: initialDeleteLawFirm })
    this.setState({ selectedLawFirm: null })
  }

  predicatesExist = () => {
    let notesExist = this.state.DeleteLawFirm.LNotesExist
    let personsExist = this.state.DeleteLawFirm.LawyersExist
    let predicatesExist = notesExist || personsExist
    // console.log('Returning from predicatesExist: ', predicatesExist.toString())
    return predicatesExist
  }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }
  setRow = (value) => {
    if (!isEmptyNullUndefined(value)) this.setState({ Row: { law_firm_id: value.values.law_firm_id, law_firm: value.values.law_firm } })
    else this.setState({ Row: {} })
  }

  render() {

    return (
      <div>
        <h1> <Icon name='balance scale' size='large' circular /> Law Firms</h1>

        {this.state.DeleteLawFirm.LNotesExist && (!isEmptyNullUndefined(this.state.selectedLawFirm) || !isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeleteNotes lawfirmID={this.state.selectedLawFirm}
            handleDeleteLNote={this.handleDeleteLNote}
            handleCancelDelete={this.handleCancelDelete}
          />
          // getCounterparties={this.getCounterparties}
          // handleDeleteCounterparty={this.handleDeleteCounterparty} handleCancelDelete={this.handleCancelDelete} />
        }

        {!this.state.DeleteLawFirm.LNotesExist && this.state.DeleteLawFirm.LawyersExist && (!isEmptyNullUndefined(this.state.selectedLawFirm) || !isEmptyNullUndefined(this.state.selectedCParty) || !isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeletePerson lawfirmID={this.state.selectedLawFirm}
            handleDeleteLawyers={this.handleDeleteLawyers}
            handleCancelDelete={this.handleCancelDelete} />
          }

        {!this.predicatesExist() && (!isEmptyNullUndefined(this.state.selectedLawFirm) || !isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeleteCounterparty lawfirmID={this.state.selectedLawFirm}
          handleDeleteCounterparty={this.handleDeleteCounterparty}
          handleCancelDelete={this.handleCancelDelete} />
          
          // getCounterparties={this.getCounterparties}
          // handleDeleteCounterparty={this.handleDeleteCounterparty} handleCancelDelete={this.handleCancelDelete} />
        }

        <ReactTableMaker passedData={this.state.LawFirms}
          passedColumns={LawFirmsColumns(this.setStateLawFirm, this.DeleteLawFirm, this.props.history, this.setModalOpen, this.setRow, !!this.props.login.currentObserver)}
          handleClick={(rowID, cellID, rowValues) => {
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
            // this.setState({ selectedTType: rowID })
            // this.getPaymentsForInvoice(rowID) 
          }}
          includeSearchBar={false}
        />

        {/* This is the "are you sure" modal for deleting a row */}
        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              this.setStateLawFirm(this.state.Row.law_firm_id,
                this.DeleteLawFirm
              )
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Law Firm'
            deleteObject={this.state.Row.law_firm}
          />}

        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LawFirms))

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
function minusFontColor(e) { e.target.style.color = "" }

let LawFirmsColumns = (setStateLawFirm, DeleteLawFirm, history, setModalOpen, setRow, observer) => {
  return [
    // {
    //   Header: () => <span style={{ 'float': 'left' }}>Law Firm ID</span>,
    //   accessor: 'law_firm_id',
    //   show: true,
    //   width: 0,
    //   sortType: 'alphanumeric',
    //   Filter: 'defaultColumnFilterLeft'
    // },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
      accessor: 'law_firm',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row, rows }) => !isEmptyNullUndefined(rows) &&
        <React.Fragment>

          <span
            onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
            style={{ 'float': 'left', cursor: 'pointer' }}
            onClick={() => history.push(`/lawfirm/${cell.value}?lawfirm=${cell.value}&lawFirmID=${row.values.law_firm_id}`)}
          // onClick={() => console.log('Yo, this is the value: ', cell.value)}
          >
            {cell.value}
          </span>
          {/* {!isEmptyNullUndefined(rows[row.index]) && <span style={{ paddingLeft: '15px' }}>{rows[row.index].original.counterparties_advised > 0 ? ` (Advise ${rows[row.index].original.counterparties_advised} counterparties)` : null}</span>}
          {!isEmptyNullUndefined(rows[row.index]) && <span style={{ paddingLeft: '15px' }}>{rows[row.index].original.notes_for_law_firm > 0 ? ` (${rows[row.index].original.notes_for_law_firm} notes entered)` : null}</span>} */}
        </React.Fragment>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparties{<br/>}Advised</span>,
      accessor: 'counterparties_advised',
      Filter: '',
      Cell: ({ cell }) => <span >{cell.value>0 ? cell.value : '-'}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Lawyers{<br/>}Involved</span>,
      accessor: 'lawyers_involved',
      Filter: '',
      Cell: ({ cell }) => <span >{cell.value>0 ? cell.value : '-'}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Notes{<br/>}Entered</span>,
      accessor: 'notes_for_law_firm',
      Filter: '',
      Cell: ({ cell }) => <span >{cell.value>0 ? cell.value : '-'}</span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
      accessor: 'law_firm_id',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
        <Link to={`addlawfirm/${cell.value}`}><Icon name='edit' />Edit</Link></span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'law_firm_id2',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
        <Link to={''} onClick={(e) => {
          e.preventDefault()
          setModalOpen(true)
          setRow(row)
        }}>
          <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
    },
  ]
}

