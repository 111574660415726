import React from 'react'
import { Icon, Table } from 'semantic-ui-react'
import { useTable, useFilters, useSortBy, usePagination, useGroupBy, useExpanded } from 'react-table'

import { getDefaultColumn } from './ReactTableMaker_Filters'
import ReactTableMaker_PaginationFooter from './ReactTableMaker_PaginationFooter'
import ReactTableMakerSearchBar from './ReactTableMaker_SearchBar'
// import { isEmptyNullUndefined } from './Misc'

import { toIMGcanvas, toPDFcanvas } from '../helper/PDFImage'
import './ReactTableMaker_ReactTable.css'

// import { getHighlightedText } from './FormHelper'

export default function ReactTableMakerReactTable({ columns, data, handleClick,
  searchString, setSearchStringHandler, searchAll, setSearchAll,
  setFilterObj, passedData, passedDataLength, includeSearchBar, includePaginationFooter, includeDensePaginationFooter = false, groupByRow = [], color, showDownloads, passedPageSize }) {

  const filterTypes = React.useMemo(
    () => ({
      // These are the various searches we can run
      text: (rows, id, filterValue) => {
        // console.log('In text filterType')
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
      globalSearchBoxSearch: (rows, id, filterValue) => {
        // console.log('Trying newsearch')
        return rows.filter(row => row)
      },
      subZeroSearch: (rows, id, subZeroCheck) => {
        // console.log('Trying subZeroSearch and here is subzeroCheck')
        if (subZeroCheck) {
          return rows.filter(row => {
            const rowValue = row.values[id]
            // console.log(rowValue)
            if (rowValue > 0) return rowValue
            else return null
          })
        }
        else return rows
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    // filterValue,
    preFilteredRows,
    prepareRow,
    //The rest of these for pagination purposes
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    // instance: {state},
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        groupBy: groupByRow, pageIndex: 0, pageSize: passedPageSize,
        hiddenColumns: columns.map(column => {
          if (column.show === false) return column.accessor || column.id;
          else return []
        })
      },
      defaultColumn: getDefaultColumn(), // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGroupBy,
    useSortBy,
    useExpanded, // useGroupBy would be pretty useless without useExpanded ;)
    usePagination,
  )

  // console.log('This is prefilteredrows: ', preFilteredRows)
  // console.log('This is rows: ', rows)
  // console.log('This is columns: ', columns)
  // console.log('This is filterValue: ', filterValue)
  setFilterObj.setFilter = setFilter
  // console.log('This is setFilter: ', setFilterObj.setFilter )

  // console.log(state)


  return (
    <div className='so-ReactTable-container'>
      <div className='so-ReactTable-left'
        style={{
          overflowY: 'auto',
          overflowX: 'auto',
        }}>
        {includeSearchBar && ReactTableMakerSearchBar(
          setSearchStringHandler,
          searchAll,
          setSearchAll,
        )}
        {/* The commented text here will show values in search inputs */}
        {/* <div>
        <pre>
          <code>{JSON.stringify(state, null, 2)}</code>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
          <code>{JSON.stringify(rows.length, null, 2)}</code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
            },
            null,
            2
          )}
        </pre>
      </div> */}
        <Table {...getTableProps()} striped selectable compact unstackable color={color} verticalAlign='bottom' size='small' >
          <Table.Header rowSpan={includeSearchBar ? '2' : undefined} >
            {headerGroups.map((headerGroup, i) => (
              <React.Fragment key={'headerGroup' + i}>

                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => {
                    // console.log(column)
                    return (
                      //This column.width thing is so I can have invivisble rows (e.g., to get rid of ID #s)
                      column.width > 0 &&
                      <React.Fragment key={'col' + i}>
                        <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())} style={{ 'paddingBottom': '10px', borderBottom: '0px', }}>
                          {/* {column.isGrouped ? '🛑 ' : '👊 '} */}
                          <span style={{ cursor: 'pointer' }}>{column.render('Header')}</span>
                          {/* <span> */}
                          {/* Fix:  Why does the caret appear on the left instead of the right? */}
                          {column.isSorted ? (column.isSortedDesc ? <Icon name='caret up' /> : <Icon name='caret down' />) : ''}
                          {/* </span> */}
                          {/* Render the columns filter UI */}
                        </Table.HeaderCell>
                        {/* <Table.Row> */}
                        {/* 
                        <Table.HeaderCell>
                          <div>{column.canFilter ? column.render('Filter') : null}</div>
                        </Table.HeaderCell> */}
                        {/* </Table.Row> */}
                      </React.Fragment>
                    )
                  })}
                </Table.Row>

                {
                  <Table.Row >
                    {headerGroup.headers.map(column => {
                      // console.log(column)
                      return (
                        column.width > 0 &&
                        <Table.HeaderCell style={{
                          'paddingTop': '0px', 'paddingBottom': '10px',
                          borderBottom: '1px dotted blue', borderTop: '0px'
                        }} {...column.getHeaderProps()}>

                          {/* Render the columns filter UI */}
                          <div>{column.canFilter ? column.render('Filter') : null}</div>
                        </Table.HeaderCell>
                      )
                    })}
                  </Table.Row>
                }



              </React.Fragment>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()} >




            {page.map(
              (row, i) =>
                prepareRow(row, i) || (
                  // row.subRows[0].original.length>2 && 
                  <Table.Row {...row.getRowProps()} >
                    {row.cells.map((cell, i) => {
                      // console.log(cell.render('Cell').props.cell.value)
                      // console.log(cell.render(({ props }) => console.log(props)))
                      // if(isEmptyNullUndefined(cell.value)) return null
                      return (
                        //This column.width thing is so I can have invivisble rows (e.g., to get rid of ID #s)
                        cell.row.cells[i].column.width > 0 &&
                        <Table.Cell onClick={() => {
                          //This takes the header of the first cell and gets the value in that cell (presumably
                          // the ID), plus the column index.
                          // console.log('Clicking on this row.values: ', cell.row.values)
                          // console.log('Clicking on this row.cells: ', cell.row.cells)
                          handleClick(cell.row.values[cell.row.cells[0].column.id], cell.column.index, cell.row.values)
                        }
                        } {...cell.getCellProps({})}
                          style={groupByRow.length > 0 ? {
                            background: cell.isGrouped
                              ? '#f3f3f3'
                              : cell.isAggregated
                                ? '#ffffff'
                                : cell.isPlaceholder
                                  ? '#ffffff'
                                  : '#e8ffff',
                          } : null}>


                          {cell.isGrouped ? (
                            // If it's a grouped cell, add an expander and row count
                            <>
                              {/* I did not use react-table's toggleProps because I wanted to add additional actions on click */}
                              <span style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  //This objSize business is just to avoid expanding for rows that only have null
                                  // objSize(row.subRows[0].original) > 2 &&
                                  Object.keys(row.subRows[0].original).length > 2 &&
                                    row.toggleRowExpanded()

                                }}>{row.isExpanded ? <Icon name='long arrow alternate down' /> :
                                  <Icon bordered name='long arrow alternate right' color='blue' disabled={Object.keys(row.subRows[0].original).length <= 2} />}
                              </span>
                              <span style={{ fontWeight: 'bold' }}> {cell.render('Cell')} </span>
                              {/* ({row.subRows.length})  */}
                              {/* <span {...row.getExpandedToggleProps()}>
                              {row.isExpanded ? '👇' : '👉'}
                            </span>{' '} */}
                            </>
                          ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render('Aggregated')
                          ) : cell.isPlaceholder ? <Icon name='circle' size='tiny' style={{ float: 'right' }} /> : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            cell.render('Cell')
                          )}


                          {/* {cell.render('Cell')} */}
                          {/* {cell.render(({ cell }) => <span style={{ 'float': 'right' }}>{cell.value}</span>)} */}
                          {/* {getHighlightedText(`CellID`, cell.value.toString(), searchString)} */}
                          {/* {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <span {...row.getExpandedToggleProps()}>
                              {row.isExpanded ? '👇' : '👉'}
                            </span>{' '}
                            {cell.render('Cell')} ({row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render('Aggregated')
                        ) : cell.isRepeatedValue ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render('Cell')
                        )} */}
                        </Table.Cell>
                      )
                    })}
                  </Table.Row>
                )
            )}
          </Table.Body>
          <Table.Footer >
            <Table.Row verticalAlign='bottom'

            >
              <Table.HeaderCell colSpan={columns.length} verticalAlign='top'

                style={{
                  'paddingTop': '10px', 'paddingBottom': '10px',
                  borderTop: '1px dotted blue'
                }}

              >
                {includePaginationFooter && ReactTableMaker_PaginationFooter(gotoPage, previousPage, nextPage, canNextPage, pageCount,
                  canPreviousPage, pageIndex, pageOptions, pageSize, setPageSize, preFilteredRows.length, passedData, passedDataLength, rows.length, rows, includeDensePaginationFooter)}
                {/* {includeDensePaginationFooter && ReactTableMaker_PaginationFooter(gotoPage, previousPage, nextPage, canNextPage, pageCount,
                canPreviousPage, pageIndex, pageOptions, pageSize, setPageSize, preFilteredRows.length, passedDataLength, rows.length, rows, includeDensePaginationFooter)} */}
              </Table.HeaderCell>
            </Table.Row>
            {showDownloads && <Table.Row>
              <Table.HeaderCell>

                <Icon.Group style={{ cursor: 'pointer', marginRight: '15px' }} onClick={() => toPDFcanvas('ReactTableWrapper')} size='large'>
                  <Icon name='file pdf outline' color='grey'  ></Icon>
                  <Icon name='arrow circle down' color='green' corner></Icon>
                </Icon.Group>
                <Icon.Group style={{ cursor: 'pointer', marginRight: '15px' }} onClick={() => toIMGcanvas('ReactTableWrapper')} size='large'>
                  <Icon name='image' color='blue' ></Icon>
                  <Icon name='arrow circle down' color='green' corner></Icon>
                </Icon.Group>
                {/* 
              {'To PDF'}<Icon name='file pdf outline' style={{ cursor: 'pointer' }} size='large' color='grey' onClick={() => toPDFcanvas('ReactTableWrapper')} ></Icon>
              {'To PNG'}<Icon name='image' style={{ cursor: 'pointer' }} size='large' color='blue' onClick={() => toIMGcanvas('ReactTableWrapper')} ></Icon> */}
              </Table.HeaderCell>
            </Table.Row>}
          </Table.Footer>
        </Table>
      </div>
      <div className='so-ReactTable-right'/>
    </div>
  )
}