import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import ReactTableMaker from '../helper/ReactTableMaker'

// import "react-table/react-table.css"
import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, ListMakerSimple, filterSimpleInput } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Header, Label, Divider, Button, Segment } from 'semantic-ui-react';
// import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';
// import { isNullOrUndefined } from 'util';


class AddAssetClasses extends Component {

  state = {
    visible: false,
    AssetClass2Regex: [],
    AssetClass2RegexSpecific: '',
    AssetClassArray: [],
    selectedAssetClass: null,
    initialValues: {},
  }

  componentDidMount() {
    this.props.fetchResetState('all')
    if (this.props.update) this.getSpecificAssetClass()
  }

  getSpecificAssetClass = async () => {
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('assetClassInfo', { fetchstr: 'getAssetClass', IDNumber: this.props.match.params.aClassID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getSpecificAssetClass: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { AssetClass: fetchresponse.payload.asset_class }
    })
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ AssetClass2Regex: updatedListGeneralSorted })
    if (updatedListSpecific.length > 0) {
      if (this.props.update && updatedListSpecific[0][1] !== this.state.initialValues.AssetClass) {
        this.setState({ AssetClass2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
      else if (this.props.update && updatedListSpecific[0][1] === this.state.initialValues.AssetClass) {
        this.setState({ AssetClass2RegexSpecific: '' })
        setStatus({ errors: {} })
      }
      if (!this.props.update) {
        this.setState({ AssetClass2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ AssetClass2RegexSpecific: '' })
      setStatus({ errors: {} })
    }
  }, 100)

  toggletable = () => this.setState((prevState) => ({ showtable: !prevState.showtable }))

  render() {

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      AssetClass: '',
    } : { AssetClass: this.state.initialValues.AssetClass }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300

    // const filterCaseInsensitive = ({ id, value }, row) =>
    //   row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true

    // let AssetClassColumns = [
    //   {
    //     Header: "Asset Class ID",
    //     accessor: 'asset_class_id',
    //     show: false
    //   },
    //   {
    //     Header: "Asset Classes",
    //     accessor: 'asset_class',
    //   }
    // ]

    let AssetClassTableObj =
      this.props.clients.assetclassarray.reduce((tempobj, v) => {
        let obj = {}
        obj['asset_class_id'] = v[0]
        obj['asset_class'] = v[1]
        // console.log('This is obj: ', obj)
        tempobj.push(obj)
        // console.log('This is tempobj: ', tempobj)
        return tempobj;
      }, [])


    // if (AssetClassTableObj.length > 0) console.log(AssetClassTableObj)

    return (
      <div>
        <Header as='h1'>Asset Class {this.props.update === true ? 'Edit' : 'Add'} Form</Header>
        {this.props.update &&
          <Header as='h2'>Editing: {this.state.initialValues.AssetClass}</Header>
        }

        {/* <TableMaker
          propsdata={this.props.clients.transactiontypesandsubsarray}
          handleClick={() => null}
          handleHover={() => null}
          updateDataState={() => console.log('Nothin')} 
        includeIDColumn={true}/> */}
        {/* 

        <ReactTable className="-striped -highlight"
          key={AssetClassTableObj.length}
          data={AssetClassTableObj}
          columns={AssetClassColumns}
          defaultPageSize={AssetClassTableObj.length}
          noDataText={"Loading..."}
          defaultFilterMethod={filterCaseInsensitive}
          showPagination={false}
          // filterable
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                // console.log("A Td Element was clicked!");
                // console.log("It was in this row:", rowInfo.row._original);
                // console.log("It was in this column:", column);
                // console.log("It was in this instance:", instance);
                console.log('We clicked on: ', rowInfo.row._original)

                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                  handleOriginal();
                }
              }
            };
          }}
        /> */}



        <Formik

          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={AddAssetClassSchema}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              this.setState({ AssetClass2Regex: [], AssetClass2RegexSpecific: '' })
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editAssetClass', { AssetClass: values.AssetClass, AClassID: this.props.match.params.aClassID }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addAssetClass', { AssetClass: values.AssetClass }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for edit/addAssetClass: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                //Update the clients store
                reduxupdate('assetclasses')
                if (this.props.update) await this.getSpecificAssetClass()

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                // setTimeout(
                //   function () {
                //     //this.setState({ success: false });
                //     this.setState({ fetcherrors: [], success: null, successmessage: '', visible: true, CName: '', clienttype: '' })
                //   }
                //     .bind(this),
                //   transitionduration - 100
                // )
                actions.resetForm({ ...initialValues })
              }
            }
            catch (error) {
              console.log('In Component-AddAssetClass and this is the caught error: ', error)
              this.props.fetchErrors(error.toString())
            }
            actions.setSubmitting(false)
            this.props.history.push(`/assetclasses`)
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
            //this.setState({error: null})
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment color='grey' raised>
                  <Form onSubmit={handleSubmit} noValidate autoComplete='off'>
                    <Form.Group>
                      <Form.Field width={12}>
                        <Form.Input
                          name="AssetClass" type="text"
                          label='Asset Class'
                          loading={this.props.fetch.fetching}
                          required={true}
                          placeholder='Asset Class Description'
                          error={(errors.AssetClass && touched.AssetClass) || (has(status, 'errors.AssetClass'))}
                          onChange={async (e) => {
                            await handleChange(e);
                            this.setState({ AssetClass: e.target.value })
                            //This function is to add the duplicate clients list
                            this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, this.props.clients.assetclassarray, { AssetClass: 'Name already used' })
                          }}
                          onBlur={handleBlur}
                          value={values.AssetClass}
                        />

                        {this.errorTag(errors, touched, status, 'AssetClass')}

                      </Form.Field>

                    </Form.Group>




                    <Segment secondary={this.state.AssetClass2RegexSpecific.length === 0} color={this.state.AssetClass2RegexSpecific.length > 0 ? 'red' : 'blue'}
                      hidden={this.state.AssetClass2Regex.length === 0}>
                      {ListMakerSimple(this.state.AssetClass2Regex, values, this.state.AssetClass2RegexSpecific, 'AssetClass')}
                    </Segment>



                    <Divider />
                    <Button.Group size='large'>
                      <Button type='button' icon='cancel'
                        onClick={() => {
                          handleReset();
                          this.setState({ TType: '', TType2Regex: [], TType2RegexSpecific: '', SubTType2Regex: [], SubTType2RegexSpecific: '' })
                        }}
                        color='red'
                        content='Cancel'></Button>
                      <Button.Or />
                      <Button color='teal'
                        onClick={() => { this.setState({ TType: '', TType2Regex: [], TType2RegexSpecific: '', SubTType2Regex: [], SubTType2RegexSpecific: '' }); handleReset() }}
                        type='button'
                        disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Form'>
                      </Button>
                      <Button.Or />
                      <Button color='orange'
                        onClick={() => { setStatus({}) }}
                        type='button'
                        //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Error Notifications'>
                      </Button>
                      <Button.Or />
                      <Button type='submit'
                        // disabled={isSubmitting}
                        //Eventually use the below, the one above is just for testing
                        disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                        icon='upload'
                        loading={isSubmitting}
                        floated='right'
                        color={dirty ? 'green' : 'grey'}
                        content={(this.props.update === true ? 'Edit' : 'Add') + ' Asset Class'}></Button>
                    </Button.Group>
                    {/* <DisplayState state={this.state} />
                    <DisplayFormikState /> */}
                  </Form>
                </Segment>
              </div>
            )}
        </Formik>

        <p />

        <ReactTableMaker passedData={AssetClassTableObj} passedColumns={AClassColumns()}
          handleClick={(rowID, cellID, rowValues) => {
          }}
          includeSearchBar={false}
          includePaginationFooter={false}
        />

      </div>
    )
  }
}


const AddAssetClassSchema = Yup.object().shape({
  AssetClass: Yup
    .string()
    .min(3, 'Asset class type must be at least 3 characters.')
    .max(50, 'Asset class type name cannot exceed 50 characters.')
    .required('Must have an asset class type.'),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAssetClasses))

AddAssetClasses.defaultProps = {
  update: false,
  // showAddLawFirmFormToggle: () => { },
  // showCounterpartyInfoFormToggle: () => { },
}


let AClassColumns = () => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Existing Asset Classes</span>,
      accessor: 'asset_class',
      show: true,
      // width: 0,
      sortType: 'alphanumeric',
      Filter: ''
    },
  ]
}