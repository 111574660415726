import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { Loading } from '../helper/Misc'
import { toIMGcanvas } from '../helper/PDFImage'
import ChartsStackedAreaFiscalYearsSVG from './Charts-Stacked-Fiscal-Years-SVG'
import { YearDropDown, LineIndividualGetBilling } from './Charts-AClass-Functions'

import { Segment, Grid, Checkbox } from 'semantic-ui-react';


// import DisplayState from '../helper/DisplayState';

class ChartsStackedAreaFiscalYears extends Component {

  state = {
    loading: true,
    currentYear: new Date().getFullYear(),
    selectedYear: new Date().getFullYear(),
    fetchObject: [],
    Loading: true,
    showByInvoicingPartner: !this.props.byOriginating,
  }

  async componentDidMount() {
    LineIndividualGetBilling(this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ fetchObject: value, Loading: false }))
  }

  async componentDidUpdate(prevProps, prevState) {
  }

  componentWillUnmount() {
    // this.setState({ isMounted: false })
    // this.props.setLoadingMattersOverTimeChart(false)
  }

  render() {

    let chartStyle = {
      background: "#ebf5fa",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      fontFamily: "'Fira Sans', sans-serif",
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderColor: '#a5a5a5',
      borderRadius: '15px',
      boxShadow: '2px 2px 8px #888888',
      cursor: 'pointer',
    }

    return (

      <React.Fragment>

        {/* <Header as='h3'>Stacked Invoices Chart</Header>
        <Divider /> */}
        <Grid>
          <Grid.Row verticalAlign='bottom'>
            <Grid.Column width={4}>
              <YearDropDown year={this.state.selectedYear} maxYears={this.props.maxYears} includeAll={true} currentYear={this.state.currentYear}
                callback={(value) => this.setState({ selectedYear: value })} />
            </Grid.Column>
            <Grid.Column width={6}>
              <Segment compact color='blue'>
                <Checkbox
                  toggle
                  // style={checkboxStyle}
                  label={this.state.showByInvoicingPartner ? 'By Invoicing Partner' : 'By Originating Partner'}
                  // value={this.state}
                  checked={this.state.showByInvoicingPartner}
                  onChange={() => this.setState((prevState) => ({ showByInvoicingPartner: !prevState.showByInvoicingPartner }))}
                />
              </Segment>

            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
            <div id='Stacked' >
              {this.state.Loading ? <Loading /> :
                <svg
                  style={chartStyle}
                  onClick={() => toIMGcanvas('Stacked')}
                  height={600 * this.props.maxViewPercentage / 100}
                  viewBox="0 0 600 350">

                  <ChartsStackedAreaFiscalYearsSVG
                    selectedYear={this.state.selectedYear}
                    currentYear={this.state.currentYear}
                    selectedPartner={this.state.selectedPartner}
                    maxViewPercentage={this.props.maxViewPercentage}
                    maxYears={this.props.maxYears}
                    fetchObject={this.state.fetchObject}
                    byInvoicingEmployee={this.state.showByInvoicingPartner}
                  />
                </svg>
              }
            </div>
          </Segment>
        </div>
      </React.Fragment>

    )
  }
}


const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsStackedAreaFiscalYears))

ChartsStackedAreaFiscalYears.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  matters: [],
  byOriginating: true,
  setLoadingMattersOverTimeChart: () => { }
  // handleSomething: () => { },
}