import React, { Component } from 'react'

import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'

import { connect } from 'react-redux';

import { Button, Form, Segment, Message, Icon } from 'semantic-ui-react'
//Fix this (has old willreceiveprops).  Use: https://www.npmjs.com/package/rc-input-number
import NumericInput from 'react-numeric-input'
import { isEmptyNullUndefined } from '../helper/Misc'

// import ErrorMessage from '../helper/MessageBar'
import { reduxupdate } from '../helper/ReduxHelp'

import config from '../../JO-CONFIG'


//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

class FakeDataComponent extends Component {

  state = {
    fakeClientNumber: 1,
    fakeMatterNumber: 1,
    fakeCounterpartyNumber: 1,
    seedCounterpartyNumber: 1,
    fakePersonNumber: 1,
    seedPersonLawyerNumber: 1,
    seedPersonTycoonNumber: 1,
    fakeNotesNumber: 1,
    seedCounterpartyNoteNumber: 1,
    seedLawyerNoteNumber: 1,
    seedMatterNoteNumber: 1,
    fakeInvoicesNumber: 1,
    fakePaymentsNumber: 1,
    fakePrecedentsNumber: 1,
    fakeBillNumber: 1,
    Message: '',
    TokenReady: false,
    Loaded: false,
    Fetching: false,
    fetcherrors: [],
    fetchinfo: [],
    fetchmessage: '',
    success: false,
    successmessage: '',
    ExistingCParties: [],
    ExistingPersons: [],
    ExistingNotes: [],
    ExistingInvoices: [],
    ExistingPayments: [],
    ExistingPrecedents: [],
  }

  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      this.props.fetchResetState('all')
      //This nonsense below is only to get around Heroku's free version 10 concurrent requests
      setTimeout(() => this.getCParties(), 30)
      setTimeout(() => this.getPersons(), 30)
      setTimeout(() => this.getNotes(), 60)
      setTimeout(() => this.getInvoices(), 60)
      setTimeout(() => this.getPayments(), 90)
      setTimeout(() => this.getPrecedents(), 90)
    }
  }

  componentDidUpdate() {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true })
    }
  }

  async getCParties() {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'counterparties' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse.payload: ', fetchresponse.payload)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {
      this.setState({ ExistingCParties: fetchresponse.payload.map(row => [row.counterparty_id, row.counterparty]) })
    }
    else this.setState({ ExistingCParties: [] })
  }

  async getPersons() {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'persons' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse.payload: ', fetchresponse.payload)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {
      this.setState({ ExistingPersons: fetchresponse.payload.map(row => [row.person_id, row.person_name, row.person_email]) })
    }
    else this.setState({ ExistingPersons: [] })
  }

  async getNotes() {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'notes' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse.payload: ', fetchresponse.payload)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {
      this.setState({ ExistingNotes: fetchresponse.payload.map(row => [row.note_id, row.note]) })
    }
    else this.setState({ ExistingNotes: [] })
  }

  async getInvoices() {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'invoices' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse.payload: ', fetchresponse.payload)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {
      this.setState({ ExistingInvoices: fetchresponse.payload.map(row => [row.invoice_id, row.invoice_number, row.invoice_amount, row.invoice_date, row.invoice_description, row.matter_id, row.employe_id]) })
    }
    else this.setState({ ExistingInvoices: [] })
  }

  async getPayments() {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'payments' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse.payload: ', fetchresponse.payload)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {
      this.setState({ ExistingPayments: fetchresponse.payload.map(row => [row.payment_id, row.payment_number, row.payment_amount, row.payment_date, row.payment_description, row.invoice_id]) })
    }
    else this.setState({ ExistingPayments: [] })
  }

  async getPrecedents() {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'precedents' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse.payload: ', fetchresponse.payload)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {
      this.setState({ ExistingPrecedents: fetchresponse.payload.map(row => row) })
    }
    else this.setState({ ExistingPrecedents: [] })
  }

  handleRecreateTables = async (seed) => {

    //Clear userfetcherrors, info and such
    this.props.fetchResetState('all')

    this.setState({ Fetching: true })

    await szenokPostAPI('recreateTables', { test: 'Not much here' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for recreateTables: ', fetchresponse)
    // this.setState({
    //   fetcherrors: fetchresponse.errors,
    //   fetchinfo: fetchresponse.info,
    //   fetchmessage: fetchresponse.message,
    //   successmessage: fetchresponse.successmessage,
    //   success: fetchresponse.success,
    // })

    //This part is if we click on the button that also seeds the employees, transaction types, asset classes and law firms.
    if (seed) {
      let seedings = ['seedemployees', 'seedtransactiontypes', 'seedsubtransactiontypes', 'seedassetclasses', 'seedlawfirms', 'seedcounterpartiesfromJSON']
      for (const indseed of seedings) {
        if (this.state.fetcherrors.length <= 0) {
          await szenokPostAPI('seedTable', { actiontype: indseed, devType: this.props.login.currentDev },
            this.props.login.currentToken)
          // console.log('Now printing fetchresponse for seedTable: ', fetchresponse)
          // this.setState({
          //   fetcherrors: fetchresponse.errors,
          //   fetchinfo: fetchresponse.info,
          //   fetchmessage: fetchresponse.message,
          //   success: fetchresponse.success,
          //   successmessage: fetchresponse.successmessage
          // })
        }
      }

      setTimeout(() => { reduxupdate('clients') }, 400)
      setTimeout(() => { reduxupdate('clientsarray') }, 400)
      setTimeout(() => { reduxupdate('employees') }, 400)
      setTimeout(() => { reduxupdate('matters') }, 400)
      setTimeout(() => { reduxupdate('transactions') }, 400)
      setTimeout(() => { reduxupdate('transactionsandsubs') }, 400)
      setTimeout(() => { reduxupdate('assetclasses') }, 400)
      setTimeout(() => { reduxupdate('lawfirms') }, 400)
    }
    // console.log('This is successmessage: ', this.state.successmessage)

    //Do this if you want to automatically insert the employees
    // if(!this.state.fetcherrors.length >0) {this.handleResets('seedemployees')} 
    this.setState({ Fetching: false })
  }


  handleResets = async (actiontype) => {

    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    // console.log('In handleResets and this  is actiontype: ', actiontype)
    await szenokPostAPI('seedTable', { actiontype: actiontype, devType: this.props.login.currentDev },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for seedTable: ', fetchresponse)
    // this.setState({
    //   fetcherrors: fetchresponse.errors,
    //   fetchinfo: fetchresponse.info,
    //   fetchmessage: fetchresponse.message,
    //   success: fetchresponse.success,
    //   successmessage: fetchresponse.successmessage
    // })
    // setTimeout(() => {
    //   reduxupdate('employees')
    //   reduxupdate('transactions')
    //   reduxupdate('transactionsandsubs')
    //   reduxupdate('assetclasses')
    //   reduxupdate('lawfirms')
    //   this.getCParties()
    //   this.getPersons()
    // }, 400)
    //In case the above doesn't work
    setTimeout(() => { reduxupdate('employees') }, 400)
    setTimeout(() => { reduxupdate('transactions') }, 400)
    setTimeout(() => { reduxupdate('transactionsandsubs') }, 400)
    setTimeout(() => { reduxupdate('assetclasses') }, 400)
    setTimeout(() => { reduxupdate('lawfirms') }, 400)
    setTimeout(() => { reduxupdate('counterparties') }, 400)
    setTimeout(() => { this.getCParties() }, 400)
    setTimeout(() => { this.getPersons() }, 400)
    setTimeout(() => { this.getNotes() }, 400)
    setTimeout(() => { this.getInvoices() }, 400)
    setTimeout(() => { this.getPayments() }, 400)
    setTimeout(() => { this.getPrecedents() }, 400)
  }

  handleFakeClients = async (actionType, employees) => {
    // console.log('In handleFakeClients and this  is actiontype: ', actionType)
    // console.log('In handleFakeClients and this is the employees array: ', employees)
    //Pass along the employee ID numbers (to generate fakes with in routes-fakes)

    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    //Seed only for employees with level 2 or lower
    const employeeIDArray = employees.reduce((acc, result) => {
      //Reduce array to only those whose level is equal to or below ORIGINATING_PARTNER_LEVEL
      // console.log('This is result: ', result)
      if (result[5] <= config.LEVELS.ORIGINATING_PARTNER_LEVEL) {
        acc.push(result[0])
      }
      return acc
    }, []
    )
    // console.log('This is employeeIDArray: ', employeeIDArray)
    if ((actionType === 'deleteclientrows') ||
      (actionType !== 'deleteclientrows' && typeof employees !== 'undefined' && employees.length > 0)) {

      this.setState({ Fetching: true })

      await szenokPostAPI('seedClientsTable', { actionType: actionType, fakeClientNumber: this.state.fakeClientNumber, employeeIDArray: employeeIDArray },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for seedClientsTable: ', fetchresponse)
      // this.setState({
      //   fetcherrors: fetchresponse.errors,
      //   fetchinfo: fetchresponse.info,
      //   fetchmessage: fetchresponse.message, 
      //   success: fetchresponse.success,
      //   successmessage: fetchresponse.successmessage
      // })

      setTimeout(() => {
        reduxupdate('clients')
        reduxupdate('clientsarray')
        reduxupdate('employees')
      }, 400)
    }
    else {
      // this.setState({ fetcherrors: ['No employees set.  Please add employees first (including by clicking the "Add Employees to Database" button below).'] })
      this.props.fetchErrors(['No employees set.  Please add employees first (including by clicking the "Add Employees to Database" button below).'])
    }
    this.setState({ Fetching: false })
  }

  handleFakeMatters = async (actionType, clients) => {
    // console.log('In handleFakeMatters and this  is actiontype: ', actionType)
    // console.log('In handleFakeMatters and this is the clients array: ', clients)
    //Pass along the clients ID numbers (to generate fakes with in routes-fakes)

    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    // const clientIDArray = clients.map(client => client[0])
    if ((actionType === 'deletematterrows') ||
      (actionType !== 'deletematterrows' && typeof clients !== 'undefined' && clients.length > 0)) {

      this.setState({ Fetching: true })

      await szenokPostAPI('seedMattersTable', { actionType: actionType, fakeMatterNumber: this.state.fakeMatterNumber },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for seedMattersTable: ', fetchresponse)
      // this.setState({
      //   fetcherrors: fetchresponse.errors,
      //   fetchinfo: fetchresponse.info,
      //   fetchmessage: fetchresponse.message,
      //   success: fetchresponse.success,
      //   successmessage: fetchresponse.successmessage,
      // })

      setTimeout(() => { reduxupdate('matters'); reduxupdate('locations') }, 400)
    }
    else {
      // this.setState({ fetcherrors: ['No clients set.  Please add clients first (including by clicking the "Add Clients to Database" button below).'] })
      this.props.fetchErrors(['No clients set.  Please add clients first (including by clicking the "Add Clients to Database" button below).'])
    }
    this.setState({ Fetching: false })
  }

  handleFakeCounterparties = async (actionType, matters, counterpartyNumber) => {
    // console.log('In handleFakeCounterparties and this  is actiontype: ', actionType)
    // console.log('In handleFakeCounterparties and this is the matters array: ', matters)
    //Pass along the clients ID numbers (to generate fakes with in routes-fakes)

    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    // const clientIDArray = clients.map(client => client[0])
    if ((actionType === 'deletecounterpartyrows') ||
      (actionType !== 'deletecounterpartyrows' && typeof matters !== 'undefined' && matters.length > 0)) {

      this.setState({ Fetching: true })

      await szenokPostAPI('seedCounterparties', { actionType: actionType, fakeCounterpartyNumber: counterpartyNumber },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for seedCounterparties: ', fetchresponse)
      // this.setState({
      //   fetcherrors: fetchresponse.errors,
      //   fetchinfo: fetchresponse.info,
      //   fetchmessage: fetchresponse.message,
      //   success: fetchresponse.success,
      //   successmessage: fetchresponse.successmessage,
      // })

      // setTimeout(() => { reduxupdate('counterparties') }, 400)
    }
    else {
      // this.setState({ fetcherrors: ['No counterparties set.  Please add counterparties first (including by clicking the "Add Persons to Database" button below).'] })
      this.props.fetchErrors(['No counterparties set.  Please add counterparties first (including by clicking the "Add Counterparties to Database" button below), or perhaps add matters if none.'])
    }
    this.setState({ Fetching: false })
    this.getCParties()
    await reduxupdate('counterparties')
  }

  handleFakePersons = async (actionType, fakePersonNumber) => {
    // console.log('In handleFakeCounterparties and this  is actiontype: ', actionType)
    // console.log('In handleFakeCounterparties and this is fakePersonNumber: ', fakePersonNumber)
    //Pass along the clients ID numbers (to generate fakes with in routes-fakes)

    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    // const clientIDArray = clients.map(client => client[0])
    if ((actionType === 'deletepersonsrows') ||
      (actionType === 'addpersons') ||
      (actionType === 'seedlawyerpersons' && this.state.ExistingPersons.length > 0 && this.state.ExistingCParties.length > 0 && this.props.clients.lawfirmsarray.length > 0) ||
      (actionType === 'seedtycoonpersons' && this.state.ExistingPersons.length > 0 && this.state.ExistingCParties.length > 0)) {

      this.setState({ Fetching: true })

      await szenokPostAPI('seedPersons', { actionType: actionType, fakePersonNumber: fakePersonNumber },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse seedPersons: ', fetchresponse)

      // this.setState({
      //   fetcherrors: fetchresponse.errors,
      //   fetchinfo: fetchresponse.info,
      //   fetchmessage: fetchresponse.message,
      //   success: fetchresponse.success,
      //   successmessage: fetchresponse.successmessage,
      // })

      // setTimeout(() => { reduxupdate('counterparties') }, 400)
    }
    else {
      this.props.fetchErrors(['No Persons or Counterparties or maybe Law Firms set.'])
      // this.setState({ fetcherrors: ['No persons set.  Please add persons first (including by clicking the "Add Persons to Database" button below).'] })
    }
    this.setState({ Fetching: false })
    this.getPersons()
    await reduxupdate('persons')
  }

  handleFakeNotes = async (actionType, fakeNoteNumber) => {
    // console.log('In handleFakeNotes and this  is actiontype: ', actionType)
    // console.log('In handleFakeNotes and this is fakeNoteNumber: ', fakeNoteNumber)

    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    // console.log('got to here with this notes length', this.state.ExistingNotes.length)
    // console.log('got to here with this CParties length', this.state.ExistingCParties.length)
    // const clientIDArray = clients.map(client => client[0])
    if ((actionType === 'deletenotesrows') ||
      (actionType === 'addnotes') ||
      (actionType === 'seedlawyersnotes' && this.state.ExistingCParties.length > 0) ||
      (actionType === 'seedcounterpartiesnotes' && this.state.ExistingCParties.length > 0) ||
      (actionType === 'seedmattersnotes' && this.props.clients.mattersarray.length > 0)) {

      this.setState({ Fetching: true })

      await szenokPostAPI('seedNotes', { actionType: actionType, fakeNoteNumber: fakeNoteNumber },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for seedNotes: ', fetchresponse)

      // this.setState({
      //   fetcherrors: fetchresponse.errors,
      //   fetchinfo: fetchresponse.info,
      //   fetchmessage: fetchresponse.message,
      //   success: fetchresponse.success,
      //   successmessage: fetchresponse.successmessage,
      // })

      // setTimeout(() => { reduxupdate('counterparties') }, 400)
    }
    else {
      this.props.fetchErrors(['No Persons or Counterparties set.'])
      // this.setState({ fetcherrors: ['No persons set.  Please add persons first (including by clicking the "Add Persons to Database" button below).'] })
    }
    this.setState({ Fetching: false })
    this.getNotes()
  }

  handleFakeInvoices = async (actionType, fakeInvoiceNumber) => {
    console.log('In handleFakeInvoices and this  is actiontype: ', actionType)
    console.log('In handleFakeInvoices and this is fakeInvoiceNumber: ', fakeInvoiceNumber)

    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    // console.log('got to here with this invoices length', this.state.ExistingInvoices.length)
    // const clientIDArray = clients.map(client => client[0])
    if ((actionType === 'deleteinvoicesrows') ||
      (actionType === 'addinvoices' && this.props.clients.mattersarray.length > 0)) {

      this.setState({ Fetching: true })

      await szenokPostAPI('addInvoices', { actionType: actionType, fakeInvoiceNumber: fakeInvoiceNumber },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for handleFakeInvoices: ', fetchresponse)
    }
    else {
      this.props.fetchErrors(['No Invoices or Matters set.'])
      // this.setState({ fetcherrors: ['No persons set.  Please add persons first (including by clicking the "Add Persons to Database" button below).'] })
    }
    this.setState({ Fetching: false })
    this.getInvoices()
    await reduxupdate('invoices')

  }

  handleFakePayments = async (actionType, fakePaymentNumber) => {
    // console.log('In handleFakePayments and this  is actiontype: ', actionType)
    // console.log('In handleFakePayments and this is fakePaymentNumber: ', fakePaymentNumber)

    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    // console.log('got to here with this Payments length', this.state.ExistingPayments.length)
    // const clientIDArray = clients.map(client => client[0])
    if ((actionType === 'deletepaymentsrows') ||
      (actionType === 'addpayments' && this.state.ExistingInvoices.length > 0 && this.props.clients.mattersarray.length > 0)) {

      this.setState({ Fetching: true })
      
      await szenokPostAPI('addPayments', { actionType: actionType, fakePaymentNumber: fakePaymentNumber },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for handleFakePayments: ', fetchresponse)
    }
    else {
      this.props.fetchErrors(['No Payments or Invoices or Matters set.'])
      // this.setState({ fetcherrors: ['No persons set.  Please add persons first (including by clicking the "Add Persons to Database" button below).'] })
    }
    this.setState({ Fetching: false })
    this.getPayments()
    await reduxupdate('payments')

  }

  handleFakePrecedents = async (actionType, fakePrecedentNumber) => {
    // console.log('In handleFakePrecedents and this  is actiontype: ', actionType)
    // console.log('In handleFakePrecedents and this is fakePrecedentNumber: ', fakePrecedentNumber)

    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    // console.log('got to here with this Precedent length', this.state.ExistingPrecedents.length)
    // const clientIDArray = clients.map(client => client[0])
    if ((actionType === 'deleteprecedentsrows') ||
      (actionType === 'addPrecedents' && this.props.clients.mattersarray.length > 0)) {

      this.setState({ Fetching: true })

      await szenokPostAPI('addPrecedents', { actionType: actionType, fakePrecedentNumber: fakePrecedentNumber },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for handleFakePrecedents: ', fetchresponse)
    }
    else {
      this.props.fetchErrors(['No Matters set.'])
      // this.setState({ fetcherrors: ['No persons set.  Please add persons first (including by clicking the "Add Persons to Database" button below).'] })
    }
    this.setState({ Fetching: false })
    this.getPrecedents()
    await reduxupdate('precedents')

  }

  handleFakeBills = async (actionType, fakeBillNumber) => {
    // console.log('In handleFakeBills and this  is actiontype: ', actionType)
    // console.log('In handleFakeBills and this is fakeBillNumber: ', fakeBillNumber)

    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    if ((actionType === 'deletebillsrows') ||
      (actionType === 'addBills' && this.props.clients.mattersarray.length > 0)) {

      this.setState({ Fetching: true })

      await szenokPostAPI('addBills', { actionType: actionType, fakeBillNumber: fakeBillNumber },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for handleFakeBills: ', fetchresponse)
    }
    else {
      this.props.fetchErrors(['No Bills set.'])
      // this.setState({ fetcherrors: ['No persons set.  Please add persons first (including by clicking the "Add Persons to Database" button below).'] })
    }
    this.setState({ Fetching: false })
    await reduxupdate('bills')

  }

  handlePickerChange = (type, new_value) => {
    switch (type) {
      case 'clients':
        this.setState({ fakeClientNumber: new_value });
        break
      case 'matters':
        this.setState({ fakeMatterNumber: new_value });
        break
      case 'counterpartiesfake':
        this.setState({ fakeCounterpartyNumber: new_value });
        break
      case 'counterpartiesseed':
        this.setState({ seedCounterpartyNumber: new_value });
        break
      case 'personsfake':
        this.setState({ fakePersonNumber: new_value });
        break
      case 'personslawyerseed':
        this.setState({ seedPersonLawyerNumber: new_value });
        break
      case 'personstycoonsseed':
        this.setState({ seedPersonTycoonNumber: new_value });
        break
      case 'notesfake':
        this.setState({ fakeNotesNumber: new_value });
        break
      case 'lawfirmnotesseed':
        this.setState({ seedLawyerNoteNumber: new_value });
        break
      case 'counterpartynotesseed':
        this.setState({ seedCounterpartyNoteNumber: new_value });
        break
      case 'matternotesseed':
        this.setState({ seedMatterNoteNumber: new_value });
        break
      case 'invoicesfake':
        this.setState({ fakeInvoicesNumber: new_value });
        break
      case 'paymentsfake':
        this.setState({ fakePaymentsNumber: new_value });
        break
      case 'precedentsfake':
        this.setState({ fakePrecedentsNumber: new_value });
        break
      case 'billsfake':
        // console.log('here, ', new_value)
        this.setState({ fakeBillNumber: new_value });
        break
      default:
        break
    }

  }

  resetFetchState = (stateID) => {
    switch (stateID) {
      case 'fetcherrors':
        this.setState({ fetcherrors: [] })
        break
      case 'fetchinfo':
        this.setState({ fetchinfo: [] })
        break
      case 'success':
        this.setState({ success: false, successmessage: '' })
        break
      case 'all':
        this.setState({ fetcherrors: [], fetchinfo: [], success: false, successmessage: '' })
        break
      default:
        break
    }
  }


  render() {


    return (
      <div >
        <Segment >

          <h1> <Icon name='adn' size='large' circular />Creating Fake Database and Data</h1>
          <Message attached warning
            header='Careful!'
            content='These actions will overwrite all your data.'
          />
          {/* <ErrorMessage generalmessage='Something Went Wrong!'
            errorarray={this.state.fetcherrors}
            infoarray={this.state.fetchinfo}
            submessage={`We ran into ${this.state.fetcherrors.length} problems...`}
            success={this.state.success}
            successmessage={this.state.successmessage}
            resetFetchState={this.resetFetchState} />
          <br /> */}
          <Form>
            <Segment color='red'>

              <h4>If you want to recreate the clients database from scratch, click here...</h4>
              <Button color='red' loading={this.state.Fetching} onClick={() => this.handleRecreateTables(false)}>
                <Button.Content>Delete Database And Start From Scratch</Button.Content>
              </Button>
              <br /><br />
              If you want to recreate the client database from scratch and seed employees, transaction types, asset classes and law firms, click here...
              <br />
              <Button color='red' loading={this.state.Fetching} onClick={() => this.handleRecreateTables(true)}>
                <Button.Content>Delete Database And Start From Scratch + Seeding</Button.Content>
              </Button>
              <br />
              <span style={{ color: 'red' }}>(For some reason, set_foreign_key_checks=0 doesn't always work so if you get a bunch of errors, wait a couple of seconds and try again.)</span>
            </Segment>

            {/* Employees Section */}
            <Segment color='green'>
              <h4>Add employees to 'employees' database...</h4>
              Clear all rows (currently {!Array.isArray(this.props.clients.employeesarray) || !this.props.clients.employeesarray.length ? 'zero' : this.props.clients.employeesarray.length} rows) from 'employees' database
              <br />
              <Button color='green' loading={this.state.Fetching} onClick={() => this.handleResets('deleteemployeesrows')}>
                <Button.Content>Delete All Rows From 'employees' Table</Button.Content>
              </Button>
              <br />
              <br />
              Seed 'employees' table using seed-employees.json file...
              <br />
              <Button color='green' loading={this.state.Fetching} onClick={() => this.handleResets('seedemployees')}>
                <Button.Content>Add Employees to Database</Button.Content>
              </Button>
            </Segment>

            {/* Clients Section */}
            <Segment color='blue'>
              <h4>Add fake clients to 'clients' database...</h4>
              Clear all rows (currently {!Array.isArray(this.props.clients.clientsarray) || !this.props.clients.clientsarray.length ? 'zero' : this.props.clients.clientsarray.length} rows) from 'clients' database...
              <br />
              <Button color='blue' loading={this.state.Fetching} onClick={() => this.handleFakeClients('deleteclientrows', this.props.clients.employeesarray)}>
                <Button.Content>Delete All Rows From 'clients' Table</Button.Content>
              </Button>
              <br />
              <br />
              Add fake rows to 'clients' to Table (only for employees level {config.LEVELS.ORIGINATING_PARTNER_LEVEL} or lower)...
              <br />
              <NumericInput min={1} max={1000} strict //step={2} 
                size={3}
                value={this.state.fakeClientNumber}
                onChange={(value) => this.handlePickerChange('clients', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='blue' loading={this.state.Fetching} onClick={() => this.handleFakeClients('addclients', this.props.clients.employeesarray)}>
                <Button.Content>Add Clients to Database</Button.Content>
              </Button>
            </Segment>

            {/* Matters Section */}
            <Segment color='violet'>
              <h4>Add fake matters to 'matters' database...</h4>
              Clear all rows (currently {!Array.isArray(this.props.clients.mattersarray) || !this.props.clients.mattersarray.length ? 'zero' : this.props.clients.mattersarray.length} rows) from 'matters' database...
               {/* {this.props.clients.mattersarray.length > 0 ? this.props.clients.mattersarray.length : 'zero'} rows) from 'matters' database... */}
              <br />
              <Button color='violet' loading={this.state.Fetching} onClick={() => this.handleFakeMatters('deletematterrows', this.props.clients.clientsarray)}>
                <Button.Content>Delete All Rows From 'matters' Table</Button.Content>
              </Button>
              <br />
              <br />
              Add fake rows to 'matters' to Table...
              <br />
              <NumericInput min={1} max={1000} strict //step={2} 
                size={3}
                value={this.state.fakeMatterNumber}
                onChange={(value) => this.handlePickerChange('matters', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='violet' loading={this.state.Fetching} onClick={() => this.handleFakeMatters('addmatters', this.props.clients.clientsarray)}>
                <Button.Content>Add Matters to Database</Button.Content>
              </Button>
            </Segment>

            {/* Transaction Types Section */}
            <Segment color='yellow'>
              <h4>Add transaction types to 'transaction_types' database...</h4>
              Clear all rows (currently {!Array.isArray(this.props.clients.transactiontypesarray) || !this.props.clients.transactiontypesarray.length ? 'zero' : this.props.clients.transactiontypesarray.length} rows) from 'transaction_types' database
              <br />
              <Button color='yellow' loading={this.state.Fetching} onClick={() => this.handleResets('deletetransactiontypesrows')}>
                <Button.Content>Delete All Rows From 'transaction_types' Table</Button.Content>
              </Button>
              <br />
              <br />
              Seed 'transaction_types' table using seed-transaction-types.json file...
              <br />
              <Button color='yellow' loading={this.state.Fetching} onClick={() => this.handleResets('seedtransactiontypes')}>
                <Button.Content>Add Transaction Types to Database</Button.Content>
              </Button>
              <br />
              <br />
              Clear all rows (currently {!Array.isArray(this.props.clients.transactiontypesandsubsarray) || !this.props.clients.transactiontypesandsubsarray.length ? 'zero' : this.props.clients.transactiontypesandsubsarray.length} rows) from 'sub_transaction_types' database
              <br />
              <Button color='yellow' loading={this.state.Fetching} onClick={() => this.handleResets('deletesubtransactiontypesrows')}>
                <Button.Content>Delete All Rows From 'sub_transaction_types' Table</Button.Content>
              </Button>
              <br />
              <br />
              Seed 'sub_transaction_types' table by adding "_General" to each transaction type
              <br />
              <Button color='yellow' loading={this.state.Fetching} onClick={() => this.handleResets('seedsubtransactiontypes')}>
                <Button.Content>Add Sub-Transaction Types to Database</Button.Content>
              </Button>
            </Segment>

            {/* Asset Classes Section */}
            <Segment color='teal'>
              <h4>Add asset classes to 'asset_classes' database...</h4>
              Clear all rows (currently {!Array.isArray(this.props.clients.assetclassarray) || !this.props.clients.assetclassarray.length ? 'zero' : this.props.clients.assetclassarray.length} rows) from 'asset_classes' database
              <br />
              <Button color='teal' loading={this.state.Fetching} onClick={() => this.handleResets('deleteassetclassesrows')}>
                <Button.Content>Delete All Rows From 'asset_classes' Table</Button.Content>
              </Button>
              <br />
              <br />
              Seed 'asset_classes' table using seed-asset-classes.json file...
              <br />
              <Button color='teal' loading={this.state.Fetching} onClick={() => this.handleResets('seedassetclasses')}>
                <Button.Content>Add Asset Classes to Database</Button.Content>
              </Button>
            </Segment>

            {/* Law Firms Section */}
            <Segment color='grey'>
              <h4>Add law firms to 'law_firms' database...</h4>
              Clear all rows (currently {!Array.isArray(this.props.clients.lawfirmsarray) || !this.props.clients.lawfirmsarray.length ? 'zero' : this.props.clients.lawfirmsarray.length} rows) from 'law_firms' database
              <br />
              <Button color='grey' loading={this.state.Fetching} onClick={() => this.handleResets('deletelawfirmsrows')}>
                <Button.Content>Delete All Rows From 'law_firms' Table</Button.Content>
              </Button>
              <br />
              <br />
              Seed 'law_firms' table using seed-law-firms.json file...
              <br />
              <Button color='grey' loading={this.state.Fetching} onClick={() => this.handleResets('seedlawfirms')}>
                <Button.Content>Add Law Firms to Database</Button.Content>
              </Button>
            </Segment>

            {/* Counterparties Section */}
            <Segment color='brown'>
              <h4>Add counterparties to 'counterparties' database...</h4>
              Clear all rows (currently {!Array.isArray(this.state.ExistingCParties) || !this.state.ExistingCParties.length ? 'zero' : this.state.ExistingCParties.length} rows) from 'counterparties' database
              <br />
              <Button color='brown' loading={this.state.Fetching} onClick={() => this.handleFakeCounterparties('deletecounterpartyrows', this.state.ExistingCParties)}>
                <Button.Content>Delete All Rows From 'counterparites' Table</Button.Content>
              </Button>
              <br />
              Seed 'counterparties' table using seed-counterparties.json file...
              <br />
              <Button color='brown' loading={this.state.Fetching} onClick={() => this.handleResets('seedcounterpartiesfromJSON')}>
                <Button.Content>Add Major Counterparties to Database</Button.Content>
              </Button>
              <br />
              Add fake rows to 'counterparties' to Table...
              <br />
              <NumericInput min={1} max={1000} strict //step={2} 
                size={3}
                value={this.state.fakeCounterpartyNumber}
                onChange={(value) => this.handlePickerChange('counterpartiesfake', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='brown' loading={this.state.Fetching} onClick={() => this.handleFakeCounterparties('addcounterparties', this.props.clients.mattersarray, this.state.fakeCounterpartyNumber)}>
                <Button.Content>Add Counterparties to Database</Button.Content>
              </Button>
              <br />
              Seed Counterparties to Matters (from 0 to {this.state.seedCounterpartyNumber} for each Matter)
              <br />
              <NumericInput min={1} max={4} strict //step={2} 
                size={3}
                value={this.state.seedCounterpartyNumber}
                onChange={(value) => this.handlePickerChange('counterpartiesseed', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='brown' loading={this.state.Fetching} onClick={() => this.handleFakeCounterparties('seedcounterparties', this.props.clients.mattersarray, this.state.seedCounterpartyNumber)}>
                <Button.Content>Seed Counterparties</Button.Content>
              </Button>
            </Segment>

            {/* People Section */}
            <Segment color='pink'>
              <h4>Add people to 'persons' database...</h4>
              Clear all rows (currently {!Array.isArray(this.state.ExistingPersons) || !this.state.ExistingPersons.length ? 'zero' : this.state.ExistingPersons.length} rows) from 'persons' database
              <br />
              <Button color='pink' loading={this.state.Fetching} onClick={() => this.handleFakePersons('deletepersonsrows', this.state.ExistingPersons)}>
                <Button.Content>Delete All Rows From 'persons' Table</Button.Content>
              </Button>
              <br />
              <br />
              Add fake rows to 'persons' to Table...
              <br />
              <NumericInput min={1} max={1000} strict //step={2} 
                size={3}
                value={this.state.fakePersonNumber}
                onChange={(value) => this.handlePickerChange('personsfake', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='pink' loading={this.state.Fetching} onClick={() => this.handleFakePersons('addpersons', this.state.fakePersonNumber, this.props.clients.mattersarray)}>
                <Button.Content>Add Persons to Database</Button.Content>
              </Button>
              <br />
              Seed Lawyers to Counterparties (from 0 to {this.state.seedPersonLawyerNumber} for each Counterparty/Law Firm)
              <br />
              <NumericInput min={1} max={4} strict //step={2} 
                size={3}
                value={this.state.seedPersonLawyerNumber}
                onChange={(value) => this.handlePickerChange('personslawyerseed', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='pink' loading={this.state.Fetching} onClick={() => this.handleFakePersons('seedlawyerpersons', this.state.seedPersonLawyerNumber)}>
                <Button.Content>Seed Lawyers</Button.Content>
              </Button>
              <br />
              Seed Tycoons to Counterparties (from 0 to {this.state.seedPersonTycoonNumber} for each Counterparty)
              <br />
              <NumericInput min={1} max={4} strict //step={2} 
                size={3}
                value={this.state.seedPersonTycoonNumber}
                onChange={(value) => this.handlePickerChange('personstycoonsseed', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='pink' loading={this.state.Fetching} onClick={() => this.handleFakePersons('seedtycoonpersons', this.state.seedPersonTycoonNumber)}>
                <Button.Content>Seed Tycoons</Button.Content>
              </Button>
            </Segment>

            {/* Notes Section */}
            <Segment color='purple'>
              <h4>Add notes to 'notes' database...</h4>
              Clear all rows (currently {!Array.isArray(this.state.ExistingNotes) || !this.state.ExistingNotes.length ? 'zero' : this.state.ExistingNotes.length} rows) from 'notes' database
              <br />
              <Button color='purple' loading={this.state.Fetching} onClick={() => this.handleFakeNotes('deletenotesrows', this.state.ExistingNotes)}>
                <Button.Content>Delete All Rows From 'notes' Table</Button.Content>
              </Button>
              <br />
              <br />
              Add fake rows to 'notes' to Table...
              {/* I moved away from this once I started making bespoke notes for each category
              <br />
              <NumericInput min={1} max={1000} strict //step={2} 
                size={3}
                value={this.state.fakeNotesNumber}
                onChange={(value) => this.handlePickerChange('notesfake', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='purple' loading={this.state.Fetching} onClick={() => this.handleFakeNotes('addnotes', this.state.fakeNotesNumber)}>
                <Button.Content>Add Notes to Database</Button.Content>
              </Button>
              <br /> 
              Seed Notes to Matters (from 0 to {this.state.seedMatterNoteNumber} for each Matter)
              */}
              <br />
              <NumericInput min={1} max={4} strict //step={2} 
                size={3}
                value={this.state.seedMatterNoteNumber}
                onChange={(value) => this.handlePickerChange('matternotesseed', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='purple' loading={this.state.Fetching} onClick={() => this.handleFakeNotes('seedmattersnotes', this.state.seedMatterNoteNumber)}>
                <Button.Content>Seed Matter Notes</Button.Content>
              </Button>
              <br />
              Seed Notes to Counterparties (from 0 to {this.state.seedCounterpartyNoteNumber} for each Counterparty)
              <br />
              <NumericInput min={1} max={4} strict //step={2} 
                size={3}
                value={this.state.seedCounterpartyNoteNumber}
                onChange={(value) => this.handlePickerChange('counterpartynotesseed', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='purple' loading={this.state.Fetching} onClick={() => this.handleFakeNotes('seedcounterpartiesnotes', this.state.seedCounterpartyNoteNumber)}>
                <Button.Content>Seed Counterparty Notes</Button.Content>
              </Button>
              <br />
              Seed Notes to Law Firms (from 0 to {this.state.seedLawyerNoteNumber} for each Counterparty)
              <br />
              <NumericInput min={1} max={4} strict //step={2} 
                size={3}
                value={this.state.seedLawyerNoteNumber}
                onChange={(value) => this.handlePickerChange('lawfirmnotesseed', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='purple' loading={this.state.Fetching} onClick={() => this.handleFakeNotes('seedlawyersnotes', this.state.seedLawyerNoteNumber)}>
                <Button.Content>Seed Law Firm Notes</Button.Content>
              </Button>
            </Segment>

            {/* Invoices Section */}
            <Segment color='black'>
              <h4>Add invoices to 'invoices' database...</h4>
              Clear all rows (currently {!Array.isArray(this.state.ExistingInvoices) || !this.state.ExistingInvoices.length ? 'zero' : this.state.ExistingInvoices.length} rows) from 'invoices' database
              <br />
              <Button color='black' loading={this.state.Fetching} onClick={() => this.handleFakeInvoices('deleteinvoicesrows', 0)}>
                <Button.Content>Delete All Rows From 'invoices' Table</Button.Content>
              </Button>
              <br />
              <br />
              Add fake rows to 'invoices' to Table...
              <br />
              <NumericInput min={1} max={1000} strict //step={2} 
                size={3}
                value={this.state.fakeInvoicesNumber}
                onChange={(value) => this.handlePickerChange('invoicesfake', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='black' loading={this.state.Fetching} onClick={() => this.handleFakeInvoices('addinvoices', this.state.fakeInvoicesNumber)}>
                <Button.Content>Add Invoices to Database</Button.Content>
              </Button>
            </Segment>

            {/* Payments Section */}
            <Segment color='teal'>
              <h4>Add payments to 'payments' database...</h4>
              Clear all rows (currently {!Array.isArray(this.state.ExistingPayments) || !this.state.ExistingPayments.length ? 'zero' : this.state.ExistingPayments.length} rows) from 'payments' database
              <br />
              {/* <Button color='teal' loading={this.state.Fetching} onClick={() => this.handleFakePayments('deletepaymentsrows', this.state.ExistingPayments)}> */}
              <Button color='teal' loading={this.state.Fetching} onClick={() => this.handleFakePayments('deletepaymentsrows', 0)}>
                <Button.Content>Delete All Rows From 'payments' Table</Button.Content>
              </Button>
              <br />
              <br />
              Add fake rows to 'payments' to Table...
              <br />
              <NumericInput min={1} max={1000} strict //step={2} 
                size={3}
                value={this.state.fakePaymentsNumber}
                onChange={(value) => this.handlePickerChange('paymentsfake', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='teal' loading={this.state.Fetching} onClick={() => this.handleFakePayments('addpayments', this.state.fakePaymentsNumber)}>
                <Button.Content>Add Payments to Database</Button.Content>
              </Button>
            </Segment>

            {/* Precedents Section */}
            <Segment color='olive'>
              <h4>Add precedents to 'precedents' database...</h4>
              Clear all rows (currently {!Array.isArray(this.state.ExistingPrecedents) || !this.state.ExistingPrecedents.length ? 'zero' : this.state.ExistingPrecedents.length} rows) from 'precedents' database
              <br />
              {/* <Button color='olive' loading={this.state.Fetching} onClick={() => this.handleFakePrecedents('deleteprecedentsrows', this.state.ExistingPrecedents)}> */}
              <Button color='olive' loading={this.state.Fetching} onClick={() => this.handleFakePrecedents('deleteprecedentsrows', 0)}>
                <Button.Content>Delete All Rows From 'precedents' Table</Button.Content>
              </Button>
              <br />
              <br />
              Add fake rows to 'precedents' to Table...
              <br />
              <NumericInput min={1} max={1000} strict //step={2} 
                size={3}
                value={this.state.fakePrecedentsNumber}
                onChange={(value) => this.handlePickerChange('precedentsfake', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='olive' loading={this.state.Fetching} onClick={() => this.handleFakePrecedents('addPrecedents', this.state.fakePrecedentsNumber)}>
                <Button.Content>Add Precedents to Database</Button.Content>
              </Button>
            </Segment>

            {/* Bills Section */}
            <Segment color='orange'>
              <h4>Add bills to 'bills' database...</h4>
              Clear all rows (currently {this.props.clients.bills_total} rows) from 'bills' database
              <br />
              <Button color='orange' loading={this.state.Fetching} onClick={() => this.handleFakeBills('deletebillsrows')}>
                <Button.Content>Delete All Rows From 'bills' Table</Button.Content>
              </Button>
              <br />
              <br />
              Add fake rows to 'bills' to Table...
              <br />
              <NumericInput min={1} max={1000} strict //step={2} 
                size={3}
                value={this.state.fakeBillNumber}
                onChange={(value) => this.handlePickerChange('billsfake', value)} />
              <span>&nbsp;&nbsp;</span>
              <Button color='orange' loading={this.state.Fetching} onClick={() => this.handleFakeBills('addBills', this.state.fakeBillNumber)}>
                <Button.Content>Add Bills to Database</Button.Content>
              </Button>
            </Segment>

            {/* Redux Section */}
            <Segment color='grey' secondary >
              <h4>For testing purposes only...</h4>
              <Button onClick={() => {
                reduxupdate('clients')
                reduxupdate('clientsarray')
                reduxupdate('employees')
                reduxupdate('matters')
              }}>
                <Button.Content>Update redux</Button.Content>
              </Button>
              <Button onClick={() => {
                reduxupdate('clients')
              }}>
                <Button.Content>Update clientslist</Button.Content>
              </Button>
              <Button onClick={() => {
                reduxupdate('matters')
              }}>
                <Button.Content>Update matters</Button.Content>
              </Button>
              <Button onClick={() => {
                this.getCParties()
                // console.log('Got CParties')
              }}>
                <Button.Content>Update CParties</Button.Content>
              </Button>
              <Button onClick={() => {
                this.getPersons()
                // console.log('Got Persons')
              }}>
                <Button.Content>Update Persons</Button.Content>
              </Button>
            </Segment>
          </Form>
        </Segment>
        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default connect(mapStateToProps, mapDispatchToProps)(FakeDataComponent)