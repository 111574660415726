
import React, { Component } from 'react'

import Dashboard from './Component-Dashboard'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

// import Profile from '../layout/Profile'
import { Header, Icon, Table, Divider, Dropdown, Image } from 'semantic-ui-react';
import { isEmptyNullUndefined } from '../helper/Misc';
import SzenokTinyLogo from '../layout/SzenokTinyLogo'
import './Component-Welcome.css'


class Welcome extends Component {

  state = {
    SelectedPartner: null,
    PartnerDropDownArray: []
  }


  componentDidMount() {
    this.setState({ PartnerDropDownArray: this.PartnerDropDownArray() })
    !isEmptyNullUndefined(this.props.login.currentUserID) && this.setState({ SelectedPartner: this.props.login.currentUserID })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.login.currentUserID !== this.props.login.currentUserID) {
      this.setState({ SelectedPartner: this.props.login.currentUserID })
    }
  }

  PartnerDropDownArray = () => {
    let yearsArray = []

    var obj = {}
    obj.key = `PDA0x`
    obj.text = `    Szenberg & Okun`
    obj.value = 0
    obj.image = <svg width={28} height={21} viewBox='5 0 55 40 '><SzenokTinyLogo /></svg>

    yearsArray.push(obj)

    for (let i = 0; i < this.props.clients.employeesarray.length; i++) {
      obj = {}
      obj.key = `PDA${i}`
      obj.text = `${this.props.clients.employeesarray[i][1] + ' ' + this.props.clients.employeesarray[i][2]}`
      obj.value = i + 1
      obj.image = this.props.clients.employeesarray[i][4]
      yearsArray.push(obj)
    }
    return yearsArray
  }

  render() {

    let name
    if (isEmptyNullUndefined(this.state.SelectedPartner) || this.state.SelectedPartner === 0) name = 'Szenberg & Okun PLLC'
    else if (isEmptyNullUndefined(this.props.clients.employeesarray)) name = '...'
    else name = this.props.clients.employeesarray.find(item => item[0] === this.state.SelectedPartner)[1] + ' ' + this.props.clients.employeesarray.find(item => item[0] === this.state.SelectedPartner)[2] + ' '

    return (
      <div className='dashboard-overall-container'>
        <div className='so-welcome-container'>
          <div className='so-welcome-icon'>
            <h1><Icon name="drivers license" size='large' circular/></h1>
          </div>
          <div className='so-welcome-header'>
            <Header as="h1">
              {!isEmptyNullUndefined(this.state.SelectedPartner) && name
              }
                Dashboard
              </Header>
          </div>
          <div className='so-welcome-selector'>
            <Table celled collapsing striped color='black' style={{ float: 'right' }}>
              <Table.Body >
                <Table.Row>
                  <Table.Cell>User ID:</Table.Cell>
                  <Table.Cell>{!isEmptyNullUndefined(this.state.SelectedPartner) ? this.state.SelectedPartner : ''}</Table.Cell>
                  <Table.Cell>{!isEmptyNullUndefined(this.state.SelectedPartner) ? !isEmptyNullUndefined(this.props.clients.employeesarray[this.state.SelectedPartner - 1]) ? <Image size='mini' src={this.props.clients.employeesarray[this.state.SelectedPartner - 1][4]} /> : <svg width={25} height={25}> <SzenokTinyLogo /> </svg> : null}</Table.Cell>
                  {this.props.login.currentLevel < 5 &&
                    <Table.Cell>
                      {!isEmptyNullUndefined(this.state.SelectedPartner) && <Dropdown
                        placeholder='Select Partner'
                        name="Partner"
                        width={7}
                        required
                        search
                        label='Partner'
                        closeOnBlur
                        selection
                        value={this.state.SelectedPartner}
                        options={this.PartnerDropDownArray()}
                        onChange={async (e, { name, value }) => {
                          this.setState({
                            SelectedPartner: value
                          }, () => this.setState({
                          }))
                        }}
                      />}
                    </Table.Cell>
                  }
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
    <Divider />


    { !isEmptyNullUndefined(this.state.SelectedPartner) && this.props.login.currentLevel < 5 && <Dashboard EmployeeID={this.state.SelectedPartner} /> }
    { this.props.login.currentLevel >= 5 && <h2>Welcome.  Please click on links to the left.</h2> }
      </div >
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(Welcome)
