import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';
import ReactTableMaker from '../helper/ReactTableMaker'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Button, Segment } from 'semantic-ui-react';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

const initialState = {
  // LNotes: [],
  // LNoteIDs: [],
  // NLCIDs: [],
  // TNotes: [],
  // TNoteIDs: [],
  // NTCIDs: [],
  Lawyers: [],
  LawyersIDs: [],
  Tycoons: [],
  TycoonsIDs: [],
}

class DeletePerson extends Component {

  state = {
    hasError: false, ...initialState
  }

  async componentDidMount() {
    // console.log('Mounted DeletePerson')
    this.props.fetchResetState('all')
    if (!isEmptyNullUndefined(this.props.lawfirmID) || !isEmptyNullUndefined(this.props.personID) || !isEmptyNullUndefined(this.props.counterpartyID) || !isEmptyNullUndefined(this.props.matterID) || !isEmptyNullUndefined(this.props.clientID)) {
      this.getLawyers()
      this.getTycoons()
      // this.getTNotes()
    }
  }


  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.lawfirmID !== this.props.lawfirmID || prevProps.personID !== this.props.personID || prevProps.counterpartyID !== this.props.counterpartyID || prevProps.matterID !== this.props.matterID || prevProps.clientID !== this.props.clientID) {
      this.props.fetchResetState('all')
      this.getLawyers()
      this.getTycoons()
      // this.getTNotes()
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }


  getTycoons = async () => {

    this.props.fetchResetState('all')
    this.setState({ ...this.state, ...initialState })
    // console.log('In Deleteperson and fetching notes for this person: ', this.props.personID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getTycoonsForClient', IDNumber: this.props.clientID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getTycoonsForMatter', IDNumber: this.props.matterID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.counterpartyID)) {
      fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getTycoonsForCounterparty', IDNumber: this.props.counterpartyID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.personID)) {
      // console.log('Running getLawyerNotes fetch...')
      fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getTycoonCounterpartiesForPerson', IDNumber: this.props.personID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse to getTycoons(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        Tycoons: fetchresponse.payload,
        TycoonsIDs: Array.from(new Set(fetchresponse.payload.map(tycoon => tycoon.tycoon_counterparty_id))),
      })
    }
    else this.props.handleDeleteTycoons()
  }


  getLawyers = async () => {

    this.props.fetchResetState('all')
    this.setState({ ...this.state, ...initialState })
    // console.log('In Deleteperson and fetching notes for this matter: ', this.props.matterID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getLawyersForClient', IDNumber: this.props.clientID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      // console.log('GETTING LAWYERS FOR MATTER')
      fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getLawyersForMatter', IDNumber: this.props.matterID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.counterpartyID)) {
      fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getLawyersForCounterparty', IDNumber: this.props.counterpartyID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.personID)) {
      // console.log('Running getLawyerNotes fetch...')
      fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getLawyerCounterpartiesForPerson', IDNumber: this.props.personID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.lawfirmID)) {
      // console.log('Running getLawyerNotes fetch...')
      fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getLawyersForLawFirm', IDNumber: this.props.lawfirmID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse to getLawyers(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        Lawyers: fetchresponse.payload,
        LawyersIDs: Array.from(new Set(fetchresponse.payload.map(lawyer => lawyer.lawyer_counterparty_matter_id))),
      })
    }
    else this.props.handleDeleteLawyers()
  }

  handleDeleteLawyers = async () => {
    // console.log('In handleDeleteLawyers (inside Component-DeletePerson)...')

    try {
      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deletePerson', { fetchstr: 'deleteLawyerInstances', IDNumber: this.state.LawyersIDs },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteLawyerInstances: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getLawyers()
        this.getTycoons()
        reduxupdate('persons')
        if (this.state.Lawyers.length <= 0) this.props.handleDeleteLawyers()
        if (this.state.Lawyers.length <= 0 && this.state.Tycoons.length <= 0) this.props.handleDeletePerson()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  handleDeleteTycoons = async () => {
    // console.log('In handleDeleteTycoons (inside Component-DeletePerson)...')

    try {
      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deletePerson', { fetchstr: 'deleteTycoonInstances', IDNumber: this.state.TycoonsIDs },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteTycoonInstances: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getLawyers()
        this.getTycoons()
        reduxupdate('persons')
        if (this.state.Tycoons.length <= 0) this.props.handleDeleteTycoons()
        if (this.state.Lawyers.length <= 0 && this.state.Tycoons.length <= 0) this.props.handleDeletePerson()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  render() {
    if (this.state.hasError) return <h1>Something went wrong in Component-DeletePerson</h1>
    else
      return (
        <div>

          {/* This will show a list of all of the Lawyers relating to this person (either just for this matter or more generally) */}
          {!isEmptyNullUndefined(this.state.Lawyers) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Delete All Lawyers for This {!isEmptyNullUndefined(this.props.clientID) ? 'Client' : !isEmptyNullUndefined(this.props.matterID) ? 'Matter' : !isEmptyNullUndefined(this.props.personID) ? 'Person' : 'Law Firm'}</h4>
              <h5 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h5>

              <Button primary onClick={() => this.handleDeleteLawyers()}>Delete {this.state.Lawyers.length > 1 ? 'All Instances of This Person Acting as a Lawyer' : 'This Instance of This Person Acting as a Lawyer'}</Button>
              <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

              <ReactTableMaker passedData={this.state.Lawyers}
                // passedColumns={PrecedentsColumns()}
                passedColumns={DeleteColumns('deletelawyers', this.props.history)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />
              {/* 
              {this.state.Lawyers.map((item, i) => {
                return <LawyerListMaker
                  {...item}
                  number={i}
                  matterID={this.props.matterID}
                  clientID={this.props.clientID}
                  matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                  // note={item.note_number}
                  // handleDeleteTycoon={this.state.handleDeleteTycoon}
                  key={`LLM${i}`}
                />
              })
              } */}
            </Segment>
          }

          {/* This will show a list of all of the 'Tycoons' relating to this person (either just for this matter or more generally) */}
          {isEmptyNullUndefined(this.state.Lawyers) && !isEmptyNullUndefined(this.state.Tycoons) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Delete All Businessmen for This {!isEmptyNullUndefined(this.props.clientID) ? 'Client' : !isEmptyNullUndefined(this.props.matterID) ? 'Matter' : 'Person'}</h4>
              <h5 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h5>

              <Button primary onClick={() => this.handleDeleteTycoons()}>Delete {this.state.Tycoons.length > 1 ? 'All Instances of This Person Acting as a Businessman' : 'This Instance of This Person Acting as a Businessman'}</Button>
              <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

              <ReactTableMaker passedData={this.state.Tycoons}
                // passedColumns={PrecedentsColumns()}
                passedColumns={DeleteColumns('deletetycoons', this.props.history)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />

              {/* {this.state.Tycoons.map((item, i) => {
                return <TycoonListMaker
                  {...item}
                  number={i}
                  matterID={this.props.matterID}
                  clientID={this.props.clientID}
                  matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                  // note={item.note_number}
                  // handleDeleteTycoon={this.state.handleDeleteTycoon}
                  key={`LLM${i}`}
                />
              })
              } */}
            </Segment>
          }

          {/* <DisplayState state={this.state} /> */}

        </div>
      )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeletePerson))


DeletePerson.defaultProps = {
  Persons: [],
  matterID: null,
  clientID: null,
  counterpartyID: null,
  personID: null,
  lawfirmID: null,
  getPersons: () => { },
  handleDeletePerson: () => { },
  handleDeleteLawyers: () => { },
  handleDeleteTycoons: () => { },
  handleCancelDelete: () => { },
}


// const CounterpartyMattersListMaker = (props) => {
//   // console.log('This is the props in LNoteListMaker: ', props)
//   if (!isEmptyNullUndefined(props.counterparty_id))
//     return (

//       <Segment secondary>

//         <h6><b>Counterparty-Matter ID: </b>{props.counterparty_matter_id}</h6>
//         <h6><b>Matter: </b>{props.matter_name}</h6>
//         <h6><b>Role: </b>{props.role}</h6>
//         <h6><b>(For Counterparty): </b>{props.counterparty}</h6>

//       </Segment>
//     )
//   else return null
// }

// const LawyerListMaker = (props) => {
//   // console.log('This is the props in LawyerListMaker: ', props)
//   if (!isEmptyNullUndefined(props.person_id))
//     return (

//       <Segment secondary>

//         <h6><b>Lawyer: </b>{props.person_name}</h6>
//         <h6><b>Email: </b>{props.person_email}</h6>
//         <h6><b>For Law Firm: </b>{props.law_firm}</h6>
//         <h6><b>For Counterparty: </b>{props.counterparty}</h6>
//         <h6><b>For Matter: </b>{props.matter_name}</h6>
//         {!isEmptyNullUndefined(props.client_name) && <h6><b>(For Client): </b>{props.client_name}</h6>}

//         {/* {!isEmptyNullUndefined(props.matterID) && <h6><b>For Matter Number: </b>{props.matter}</h6>} */}
//         {/* <h6><b>(For Counterparty): </b>{props.counterparty}</h6> */}

//       </Segment>
//     )
//   else return null
// }

// const TycoonListMaker = (props) => {
//   // console.log('This is the props in TycoonListMaker: ', props)
//   if (!isEmptyNullUndefined(props.person_id))
//     return (

//       <Segment secondary>

//         <h6><b>Businessman: </b>{props.person_name}</h6>
//         <h6><b>Email: </b>{props.person_email}</h6>
//         <h6><b>For Counterparty: </b>{props.counterparty}</h6>
//         <h6><b>For Matter: </b>{props.matter_name}</h6>
//         {!isEmptyNullUndefined(props.client_name) && <h6><b>(For Client): </b>{props.client_name}</h6>}

//         {/* {!isEmptyNullUndefined(props.matterID) && <h6><b>For Matter Number: </b>{props.matter}</h6>} */}
//         {/* <h6><b>(For Counterparty): </b>{props.counterparty}</h6> */}

//       </Segment>
//     )
//   else return null
// }

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const DeleteColumns = (type, history) => {
  let results = []

  switch (type) {
    case 'deletelawyers':
      results = [
        {
          Header: () => <span style={{ 'float': 'left' }}>Person Name</span>,
          accessor: 'person_name',
          show: true,
          sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/person/${row.original.person_name}?person=${row.original.person_name}&personID=${row.original.person_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: () => <span style={{ 'float': 'left' }}>Counterparty</span>,
          accessor: 'counterparty',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/counterparty/${row.original.counterparty}?counterparty=${row.original.counterparty}&counterpartyID=${row.original.counterparty_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: () => <span style={{ 'float': 'left' }}>(For This Law Firm)</span>,
          accessor: 'law_firm',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/lawfirm/${row.original.law_firm}?lawfirm=${row.original.law_firm}&lawFirmID=${row.original.law_firm_id}`)}
            >
              {cell.value}
            </span>
        },
      ]
      break;
    case 'deletetycoons':
      results = [
    
        {
          Header: () => <span style={{ 'float': 'left' }}>Person Name</span>,
          accessor: 'person_name',
          show: true,
          sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/person/${row.original.person_name}?person=${row.original.person_name}&personID=${row.original.person_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: () => <span style={{ 'float': 'left' }}>(For This Counterparty)</span>,
          accessor: 'counterparty',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/counterparty/${row.original.counterparty}?counterparty=${row.original.counterparty}&counterpartyID=${row.original.counterparty_id}`)}
            >
              {cell.value}
            </span>
        },
      ]
      break;
    default:
    // code block
  }
  return results
}