import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';
import ReactTableMaker from '../helper/ReactTableMaker'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Button, Segment } from 'semantic-ui-react';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

const initialState = {
  CounterpartyMatters: null,
}

class DeleteCounterparty extends Component {

  state = {
    hasError: false, ...initialState
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    if (!isEmptyNullUndefined(this.props.lawfirmID) ||!isEmptyNullUndefined(this.props.counterpartyID) || !isEmptyNullUndefined(this.props.matterID) || !isEmptyNullUndefined(this.props.clientID)) {
      this.getCounterparties()
    }
  }


  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.lawfirmID !== this.props.lawfirmID || prevProps.counterpartyID !== this.props.counterpartyID || prevProps.matterID !== this.props.matterID || prevProps.clientID !== this.props.clientID) {
      this.props.fetchResetState('all')
      this.getCounterparties()
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  getCounterparties = async () => {

    this.props.fetchResetState('all')
    this.setState({ ...this.state, ...initialState })
    // console.log('In DeleteCounterparty and fetching counterparty-matters for this counterparty: ', this.props.counterpartyID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('getCounterparty', { fetchstr: 'getCPartiesForClient', IDNumber: this.props.clientID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      fetchresponse = await szenokPostAPI('getCounterparty', { fetchstr: 'getCPartiesForMatter', IDNumber: this.props.matterID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.counterpartyID)) {
      fetchresponse = await szenokPostAPI('counterpartyInfo', { fetchstr: 'getCounterpartyMattersForCounterparty', counterpartyID: this.props.counterpartyID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.lawfirmID)) {
      fetchresponse = await szenokPostAPI('counterpartyInfo', { fetchstr: 'getCounterpartyMattersForLawFirm', IDNumber: this.props.lawfirmID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse for getCounterparties()...: ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      // console.log('NOW SETTING STASTE FOR GETCOUNTERPAETIES...', fetchresponse.payload)
      this.setState({
        CounterpartyMatters: fetchresponse.payload,
        CounterpartyMatterIDs: Array.from(new Set(fetchresponse.payload.map(cm => cm.counterparty_matter_id))),
      })
    }
    else this.props.handleDeleteCounterparty()
  }

  handleDeleteCounterpartiesMatters = async () => {

    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      if (!isEmptyNullUndefined(this.props.lawfirmID)) fetchresponse = await szenokPostAPI('deleteCounterparty', {fetchstr: 'deleteLawFirmFromCounterpartyMattersgetCounterparty',
        counterpartyMatterID: this.state.CounterpartyMatterIDs.join()
      }, this.props.login.currentToken)
      else fetchresponse = await szenokPostAPI('deleteCounterpartyMatter', {
        counterpartyMatterID: this.state.CounterpartyMatterIDs.join()
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteCounterpartyMatter: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getCounterparties()
        reduxupdate('counterparties')
        if (isEmptyNullUndefined(this.state.CounterpartyMatters)) this.props.handleDeleteCounterparty()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }


  render() {
    if (this.state.hasError) return <h1>Something went wrong in Component-DeleteCounterparty</h1>
    else
      return (
        <div>

          {/* This will show a list of all of the counterparty-matter connections */}
          {!isEmptyNullUndefined(this.state.CounterpartyMatters) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Delete All Counterparties Added for This {!isEmptyNullUndefined(this.props.clientID) ? 'Client' : !isEmptyNullUndefined(this.props.matterID) ? 'Matter' : !isEmptyNullUndefined(this.props.counterpartyID) ? 'Counterparty' : 'Law Firm'}</h4>
              <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h6>

              <Button primary onClick={() => this.handleDeleteCounterpartiesMatters()}>Delete {this.state.CounterpartyMatters.length > 1 ? 'All Counterparties Added' : 'This Counterparty'}</Button>
              <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

              <ReactTableMaker passedData={this.state.CounterpartyMatters}
              // passedColumns={PrecedentsColumns()}
              passedColumns={DeleteColumns('deletecounterpartymatters', this.props.history)}
              handleClick={(rowID, cellID, rowValues) => {
                // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
              }}
              includeSearchBar={false}
              includePaginationFooter={false}
              color='red'
            />
              {/* {this.state.CounterpartyMatters.map((item, i) => {
                return <CounterpartyMattersListMaker
                  {...item}
                  number={i}
                  matterID={this.props.matterID}
                  clientID={this.props.clientID}
                  matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                  // note={item.note_number}
                  // handleDeleteTycoon={this.state.handleDeleteTycoon}
                  key={`CMLM${i}`}
                />
              })
              } */}
            </Segment>
          }

          {/* <DisplayState state={this.state} /> */}

        </div>
      )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteCounterparty))


DeleteCounterparty.defaultProps = {
  Counterparties: [],
  MatterID: null,
  ClientID: null,
  lawfirmID: null,
  getCounterparties: () => { },
  handleDeleteCounterparty: () => { },
  handleCancelDelete: () => { },
}


// const CounterpartyMattersListMaker = (props) => {
//   // console.log('This is the props in LNoteListMaker: ', props)
//   if (!isEmptyNullUndefined(props.counterparty_id))
//     return (

//       <Segment secondary>

//         <h6><b>Counterparty-Matter ID: </b>{props.counterparty_matter_id}</h6>
//         <h6><b>Role: </b>{props.role}</h6>
//         <h6><b>(For Counterparty): </b>{props.counterparty}</h6>
//         <h6><b>(For Matter): </b>{props.matter_name}</h6>
//         {!isEmptyNullUndefined(props.client_name) && <h6><b>(For Client): </b>{props.client_name}</h6>}

//       </Segment>
//     )
//   else return null
// }


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const DeleteColumns = (type, history) => {
  let results = []

  switch (type) {
    case 'deletecounterpartymatters':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: () => <span style={{ 'float': 'left' }}>Counterparty</span>,
          accessor: 'counterparty',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/counterparty/${row.original.counterparty}?counterparty=${row.original.counterparty}&counterpartyID=${row.original.counterparty_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: () => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'law_firm',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/lawfirm/${row.original.law_firm}?lawfirm=${row.original.law_firm}&lawFirmID=${row.original.law_firm_id}`)}
            >
              {cell.value}
            </span>
        },
      ]
      break;
    default:
    // code block
  }
  return results
}