import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ReactTooltip from "react-tooltip"
// import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { Icon,  Divider, Header } from 'semantic-ui-react';

// import { isEmptyNullUndefined } from '../helper/Misc'

import ChartsStackedAreaFiscalYears from '../charts/Charts-stacked-fiscal-years2'
import ChartsPie from '../charts/Charts-Pie'
import ChartsLineIndividual from '../charts/Charts-Line-Individual'
// import sChartsMajorClients from '../charts/cCharts-Major-Clients'
import ChartsMajorClients from '../charts/Charts-Major-Clients2'
import ChartsAClassTTypes from '../charts/Charts-AClass-TTypes'
import ChartsBalanceSheet from '../charts/Charts-Balance-Sheet'
import ChartsCombined from '../charts/Charts-Combined'
import './Component-Charts.css'

import { toIMGcanvas, toPDFcanvas } from '../helper/PDFImage'

// import DisplayState from '../helper/DisplayState';

class Charts extends Component {

  state = {
    activeMenuItem: 'Clients',
    maxViewPercentage: 100,
    viewPercentage: 1,
    maxYears: 5
  }

  async componentDidMount() {
  }


  handleItemClick = (e, { name }) => this.setState({ activeMenuItem: name })
  handleItemClick2 = (name) => this.setState({ activeMenuItem: name })
  handleIconClick = (e, { name }) => {
    if (name === 'plus') { if (this.state.maxViewPercentage < 100) this.setState({ maxViewPercentage: 5 + this.state.maxViewPercentage, viewPercentage: (5 + this.state.maxViewPercentage) / 75 }) }
    if (name === 'minus') { if (this.state.maxViewPercentage > 5) this.setState({ maxViewPercentage: this.state.maxViewPercentage - 5, viewPercentage: (this.state.maxViewPercentage - 5) / 75 }) }
  }

  byOriginating = true

  render() {

    const chartName = () => {
      switch (this.state.activeMenuItem) {
        case 'Clients': return 'Major Clients Chart'
        case 'Stacked': return 'Stacked Invoices Chart'
        case 'Individual': return 'Partner Invoices Chart'
        case 'Pie': return 'Pie Chart'
        case 'Classes/Types': return 'Asset and Transaction Type Chart'
        case 'Combined': return 'Combined Chart'
        case 'BalanceSheet': return 'Balance Sheet'
        default: return 'Chart'
      }
    }

    return (
      <div className='so-charts-overall-container'>
        <h1> <Icon name='chart line' size='large' circular /> Charts</h1>
        <ReactTooltip type='warning' place='bottom' />
        <Divider />

        {chartsMenuBar(this.state.activeMenuItem, this.handleItemClick2)}<br /><br />

        {/* <div className='so-charts-menubar'>
          <Menu tabular size='large' inverted compact pointing stackable >
            <Menu.Item
              name='Clients'
              active={this.state.activeMenuItem === 'Clients'}
              onClick={this.handleItemClick}
              color='blue'
              icon='chess queen'
            />
            <Menu.Item
              name='Stacked'
              active={this.state.activeMenuItem === 'Stacked'}
              onClick={this.handleItemClick}
              color='green'
              icon='chart area'
            />
            <Menu.Item
              name='Individual'
              active={this.state.activeMenuItem === 'Individual'}
              onClick={this.handleItemClick}
              color='purple'
              icon='dollar sign'
            />
            <Menu.Item
              name='Pie'
              active={this.state.activeMenuItem === 'Pie'}
              onClick={this.handleItemClick}
              color='orange'
              icon='users'
            />
            <Menu.Item
              name='Classes/Types'
              active={this.state.activeMenuItem === 'Classes/Types'}
              onClick={this.handleItemClick}
              color='yellow'
              icon='clipboard outline'
            />
            <Menu.Item
              name='Combined'
              active={this.state.activeMenuItem === 'Combined'}
              onClick={this.handleItemClick}
              color='red'
              icon='cogs'
            />
            <Menu.Item
              name='BalanceSheet'
              active={this.state.activeMenuItem === 'BalanceSheet'}
              onClick={this.handleItemClick}
              color='grey'
              icon='tv'
            />
          </Menu>
        </div> */}

        {/* <Grid >
          <Grid.Row style={{ paddingBottom: 0 }} >
            <Grid.Column width={16} > */}
        <div className='so-charts-namebar-container'>
          <div className='so-charts-namebar-name'>
            <Header as='h3' >{chartName()}</Header>
          </div>

          <div className='so-charts-namebar-icons'>
            {/* <span style={{ cursor: 'pointer', float: 'right', paddingTop: 30, paddingBottom: 0, paddingRight: 50 }} > */}

            <Icon.Group style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => toPDFcanvas(this.state.activeMenuItem, 1)} size='large' data-tip="Download chart as PDF">
              <Icon name='file pdf outline' color='grey'  ></Icon>
              <Icon name='arrow circle down' color='green' corner></Icon>
            </Icon.Group>
            <Icon.Group style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => toIMGcanvas(this.state.activeMenuItem, 1)} size='large' data-tip="Download chart as PNG">
              <Icon name='image' color='blue' ></Icon>
              <Icon name='arrow circle down' color='green' corner></Icon>
            </Icon.Group>

            {/* <Icon name='file pdf outline' style={{ cursor: 'pointer' }} size='large' color='grey' onClick={() => toPDFcanvas(this.state.activeMenuItem, this.state.maxViewPercentage / 100)} ></Icon>
          <Icon name='image' style={{ cursor: 'pointer' }} size='large' color='blue' onClick={() => toIMGcanvas(this.state.activeMenuItem, this.state.maxViewPercentage / 100)} ></Icon> */}
            <Icon name='minus' size='small' color='blue' circular onClick={this.handleIconClick} data-tip={`Decrease Chart Size (${parseFloat(100 * this.state.viewPercentage).toFixed(2)}%)`} />
            <Icon name='plus' size='small' color='blue' circular padding={100} onClick={this.handleIconClick} data-tip={`Increase Chart Size (${parseFloat(100 * this.state.viewPercentage).toFixed(2)}%)`} />
            {/* </span> */}
          </div>
        </div>

        {/* </Grid.Column>
          </Grid.Row>
        </Grid> */}
        {/* <div style={{marginTop: '20px'}} /> */}
        <Divider />

        <div className='so-charts-charts-selector'>
          {/* <div className='so-charts-chart-container'> */}
          {this.state.activeMenuItem === 'Clients' && <ChartsMajorClients maxViewPercentage={this.state.maxViewPercentage} maxYears={this.state.maxYears} byOriginating={this.byOriginating} />}
          {this.state.activeMenuItem === 'Stacked' && <ChartsStackedAreaFiscalYears maxViewPercentage={this.state.maxViewPercentage} maxYears={this.state.maxYears} byOriginating={this.byOriginating} />}
          {this.state.activeMenuItem === 'Pie' && <ChartsPie maxViewPercentage={this.state.maxViewPercentage} maxYears={this.state.maxYears} byOriginating={this.byOriginating} />}
          {this.state.activeMenuItem === 'Individual' && <ChartsLineIndividual maxViewPercentage={this.state.maxViewPercentage} maxYears={this.state.maxYears} byOriginating={this.byOriginating} />}
          {this.state.activeMenuItem === 'Classes/Types' && <ChartsAClassTTypes maxViewPercentage={this.state.maxViewPercentage} maxYears={this.state.maxYears} byOriginating={this.byOriginating} />}
          {this.state.activeMenuItem === 'Combined' && <ChartsCombined maxViewPercentage={this.state.maxViewPercentage} maxYears={this.state.maxYears} byOriginating={this.byOriginating} />}
          {this.state.activeMenuItem === 'BalanceSheet' && <ChartsBalanceSheet maxViewPercentage={this.state.maxViewPercentage} maxYears={this.state.maxYears} byOriginating={this.byOriginating} />}
        </div>

        {/* <DisplayState state={this.state} /> */}

      </div>

    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Charts))


const chartsMenuBar = (activeMenuItem, handleItemClick) => {
  return (
    <div className='so-charts-menubar-container'>
      {chartsMenu.map(item => {
        return (
          // <div className={activeMenuItem === item.name ? 'so-charts-menubar-item-active' : ''}>
            <div
              key={`menubar-${item.name}-${activeMenuItem}`}
              className={`so-charts-menubar-item ${activeMenuItem === item.name ? 'active' : null}`}
              style={activeMenuItem === item.name ? { background: item.color } : { background: '1b1c1d' }}
              onClick={() => handleItemClick(item.name)}
            >{<Icon name={item.icon} />}{<span style={{paddingLeft: '3px'}}>{item.title}</span>}</div>
          // </div>
        )
      })}
    </div>
  )
}

const chartsMenu = [
  {
    name: 'Clients',
    title: 'Clients',
    color: '#2185d0',
    icon: 'chess queen',
  },
  {
    name: 'Stacked',
    title: 'Stacked',
    color: '#21ba45',
    icon: 'chart area',
  },
  {
    name: 'Individual',
    title: 'Individual',
    color: '#a333c8',
    icon: 'dollar sign',
  },
  {
    name: 'Pie',
    title: 'Pie',
    color: '#f2711c',
    icon: 'users',
  },
  {
    name: 'Classes/Types',
    title: 'Classes/Types',
    color: '#fbbd08',
    icon: 'clipboard outline',
  },
  {
    name: 'Combined',
    title: 'Combined',
    color: '#db2828',
    icon: 'cogs',
  },
  {
    name: 'BalanceSheet',
    title: 'Balance Sheet',
    color: '#767676',
    icon: 'tv',
  }
]