import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp'
import ReactTableMaker from '../helper/ReactTableMaker'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Button, Segment } from 'semantic-ui-react';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';


class DeleteMatter extends Component {

  state = {
    Matters: [],
    MatterIDs: [],
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    if (!isEmptyNullUndefined(this.props.clientID)) this.getMatters()
  }


  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.clientID !== this.props.clientID) {
      this.props.fetchResetState('all')
      this.getMatters()
    }
  }


  getMatters = async () => {

    this.props.fetchResetState('all')
    // console.log('In DeleteMatters and fetching matters...')
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('matterInfo', { fetchstr: 'getMattersForClient', clientID: this.props.clientID },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for getMatters(): ', fetchresponse)
      if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
        this.setState({
          Matters: fetchresponse.payload,
          MatterIDs: Array.from(new Set(fetchresponse.payload.map(matter => matter.matter_id)))
        })
      }
      else {
        // console.log('GOT TO HERE!!!!###$#')
        this.props.handleDeleteMatter()
        // console.log('CLICKED ON IT!!!')
      }
    }
  }


  handleDeleteMatters = async () => {
    // console.log('Running handleDeleteMatters...')
    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      // console.log('Sending these MatterIDs to deletematter: ', this.state.MatterIDs.join())
      fetchresponse = await szenokPostAPI('deleteMatter', {
        fetchstr: 'deleteMatter',
        matterID: this.state.MatterIDs.join()
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteMatter: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getMatters()
        reduxupdate('matters')
        this.props.handleDeleteMatter()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  render() {
    return (
      <div>
        {!isEmptyNullUndefined(this.state.Matters) &&

          <Segment color='blue' attached='top'>
            <h4>To Proceed, Must First Delete All Matters Created for This Client</h4>
            <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h6>

            <Button primary onClick={() => this.handleDeleteMatters()}>Delete {this.state.Matters.length > 1 ? 'All Matters' : 'This Matter'}</Button>
            <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

            <ReactTableMaker passedData={this.state.Matters}
              // passedColumns={PrecedentsColumns()}
              passedColumns={DeleteColumns('deletematters', this.props.history)}
              handleClick={(rowID, cellID, rowValues) => {
                // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
              }}
              includeSearchBar={false}
              includePaginationFooter={false}
              color='red'
            />

            {/* {this.state.Matters.map((item, i) => {
              return <MattersListMaker
                {...item}
                number={i}
                clientID={this.props.clientID}
                key={`PLM${i}`}
              />
            })
            } */}
          </Segment>}

        {/* <DisplayState state={this.state} /> */}

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteMatter))


DeleteMatter.defaultProps = {
  clientID: null,
  getMatters: () => { },
  handleDeleteMatters: () => { },
  handleCancelDelete: () => { },
}


// const MattersListMaker = (props) => {
//   // console.log('This is the props in MattersListMaker: ', props)
//   if (!isEmptyNullUndefined(props.clientID))
//     return (

//       <Segment secondary>

//         <h6><b>Matter Name: </b>{props.matter_name}</h6>
//         {!isEmptyNullUndefined(props.matter_id) && <h6><b>For Matter Number: </b>{props.matter_id}</h6>}
//         <h6><b>Matter Amount: </b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.matter_deal_amount)}</h6>
//         <h6><b>Matter Date: </b>{props.matter_origination_date}</h6>
//         <h6><b>Transaction Type: </b>{props.matter_transaction_type}</h6>
//         <h6><b>Sub-Transaction Type: </b>{props.matter_sub_transaction_type}</h6>
//         <h6><b>Matter Name: </b>{props.client_name}</h6>
//         {!isEmptyNullUndefined(props.matter_name) && <h6><b>(For Matter): </b>{props.matter_name}</h6>}
//         {!isEmptyNullUndefined(props.client_name) && <h6><b>(For Client): </b>{props.client_name}</h6>}

//       </Segment>
//     )
//   else return null
// }


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const DeleteColumns = (type, history) => {
  let results = []

  switch (type) {
    case 'deletematters':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Origination Date</span>,
          accessor: 'matter_origination_date',
          Filter: '',
          sortType: 'alphanumeric',
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Matter Amount</span>,
          accessor: 'matter_deal_amount',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Asset Class</span>,
          accessor: 'asset_class',
          Filter: '',
          sortType: 'alphanumeric',
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Transaction Type</span>,
          accessor: 'transaction_type',
          Filter: '',
          sortType: 'alphanumeric',
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Sub-Transaction Type</span>,
          accessor: 'sub_transaction_type',
          Filter: '',
          sortType: 'alphanumeric',
        },
      ]
      break;
    default:
    // code block
  }
  return results
}