import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';
import ReactTableMaker from '../helper/ReactTableMaker'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Button, Segment } from 'semantic-ui-react';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

const initialState = {
  LNotes: [],
  LNoteIDs: [],
  NLCIDs: [],
  TNotes: [],
  TNoteIDs: [],
  NTCIDs: [],
  MNotes: [],
  NMIDs: [],
  MNoteIDs: [],
}

class DeleteNotes extends Component {

  state = {
    hasError: false, ...initialState
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.setState({ ...this.state, ...initialState })
    if (!isEmptyNullUndefined(this.props.lawfirmID) || !isEmptyNullUndefined(this.props.counterpartyID) || !isEmptyNullUndefined(this.props.matterID) || !isEmptyNullUndefined(this.props.matterCPartyID) || !isEmptyNullUndefined(this.props.clientID)) {
      await this.getLNotes()
      await this.getTNotes()
      await this.getMNotes()
    }
  }


  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.lawfirmID !== this.props.lawfirmID || prevProps.counterpartyID !== this.props.counterpartyID || prevProps.matterID !== this.props.matterID || prevProps.matterCPartyID !== this.props.matterCPartyID || prevProps.clientID !== this.props.clientID) {
      this.props.fetchResetState('all')
      // console.log(`matterID is now: ${this.props.matterID}`)
      this.setState({ ...this.state, ...initialState })
      await this.getLNotes()
      await this.getTNotes()
      await this.getMNotes()
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }


  getLNotes = async () => {

    this.props.fetchResetState('all')
    // console.log('In DeleteNotes and fetching notes for this counterparty: ', this.props.counterpartyID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('getNote', { fetchstr: 'getLawyerNotesForClient', IDNumber: this.props.clientID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      fetchresponse = await szenokPostAPI('getNote', { fetchstr: 'getLawyerNotesForMatter', IDNumber: this.props.matterID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.counterpartyID)) {
      fetchresponse = await szenokPostAPI('counterpartyInfo', { fetchstr: 'getLawyerNotesForCounterpartyForMatter', counterpartyID: this.props.counterpartyID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterCPartyID)) {
      fetchresponse = await szenokPostAPI('counterpartyInfo', { fetchstr: 'getLawyerNotesForCounterpartyForMatterCParty', matterCPartyID: this.props.matterCPartyID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.lawfirmID)) {
      // console.log('Trying to fetch getLNotesForLawFirm')
      fetchresponse = await szenokPostAPI('lawFirmInfo', { fetchstr: 'getLNotesForLawFirm', IDNumber: this.props.lawfirmID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse for getLNotes(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        LNotes: fetchresponse.payload,
        LNoteIDs: Array.from(new Set(fetchresponse.payload.map(note => note.note_id))).sort(function(a, b){return a-b}),
        NLCIDs: Array.from(new Set(fetchresponse.payload.map(note => note.note_lawyer_counterparty_id))).sort(function(a, b){return a-b})
      })
    }
    else this.props.handleDeleteLNote()
  }

  getTNotes = async () => {

    this.props.fetchResetState('all')
    // console.log('In DeleteNotes and fetching notes for this counterparty: ', this.props.counterpartyID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('getNote', { fetchstr: 'getTycoonNotesForClient', IDNumber: this.props.clientID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      fetchresponse = await szenokPostAPI('getNote', { fetchstr: 'getTycoonNotesForMatter', IDNumber: this.props.matterID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.counterpartyID)) {
      fetchresponse = await szenokPostAPI('counterpartyInfo', { fetchstr: 'getTycoonNotesForCounterpartyForMatter', counterpartyID: this.props.counterpartyID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterCPartyID)) {
      fetchresponse = await szenokPostAPI('counterpartyInfo', { fetchstr: 'getTycoonNotesForCounterpartyForMatterCParty', matterCPartyID: this.props.matterCPartyID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse for getTNotes(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        TNotes: fetchresponse.payload,
        TNoteIDs: Array.from(new Set(fetchresponse.payload.map(note => note.note_id))).sort(function(a, b){return a-b}),
        NTCIDs: Array.from(new Set(fetchresponse.payload.map(note => note.note_tycoon_counterparty_id))).sort(function(a, b){return a-b})
      })
    }
    else this.props.handleDeleteTNote()
  }

  getMNotes = async () => {

    this.props.fetchResetState('all')
    // console.log('In DeleteNotes and fetching notes for this matter: ', this.props.matterID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('getNote', { fetchstr: 'getMatterNotesForClient', IDNumber: this.props.clientID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      fetchresponse = await szenokPostAPI('getNote', { fetchstr: 'getMatterNotesForMatter', IDNumber: this.props.matterID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse inside getMNotes: ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      // console.log('Got to here in getMNotes()')
      this.setState({
        MNotes: fetchresponse.payload,
        MNoteIDs: Array.from(new Set(fetchresponse.payload.map(note => note.note_id))).sort(function(a, b){return a-b}),
        NMIDs: Array.from(new Set(fetchresponse.payload.map(note => note.note_matter_id))).sort(function(a, b){return a-b})
      })
    }
    else this.props.handleDeleteMNote()
  }

  handleDeleteLNotes = async () => {

    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deleteNote', {
        fetchstr: 'deleteNoteLawyerCounterParty',
        IDNumber: this.state.NLCIDs.join()
      }, this.props.login.currentToken)
      fetchresponse = await szenokPostAPI('deleteNote', {
        fetchstr: 'deleteNote',
        IDNumber: this.state.LNoteIDs.join()
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteNote: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.setState({ ...this.state, ...initialState })
        this.getLNotes()
        this.getTNotes()
        this.getMNotes()
        reduxupdate('counterparties')
        if (this.state.LNotes.length <= 0) this.props.handleDeleteLNote()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  handleDeleteTNotes = async () => {

    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await await szenokPostAPI('deleteNote', {
        fetchstr: 'deleteNoteTycoonsCounterParty',
        IDNumber: this.state.NTCIDs.join()
      }, this.props.login.currentToken)
      fetchresponse = await szenokPostAPI('deleteNote', {
        fetchstr: 'deleteNote',
        IDNumber: this.state.TNoteIDs.join()
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteNote: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.setState({ ...this.state, ...initialState })
        this.getLNotes()
        this.getTNotes()
        this.getMNotes()
        reduxupdate('counterparties')
        if (this.state.TNotes.length <= 0) this.props.handleDeleteTNote()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  handleDeleteMNotes = async () => {

    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deleteNote', {
        fetchstr: 'deleteNoteMattersForMatter',
        IDNumber: this.state.NMIDs.join()
      }, this.props.login.currentToken)
      fetchresponse = await szenokPostAPI('deleteNote', {
        fetchstr: 'deleteNote',
        IDNumber: this.state.MNoteIDs.join()
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteNote: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.setState({ ...this.state, ...initialState })
        this.getLNotes()
        this.getTNotes()
        this.getMNotes()
        reduxupdate('counterparties')
        if (this.state.MNotes.length <= 0) this.props.handleDeleteMNote()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  deleteButtonTextMaker = (type) => {
    if (!isEmptyNullUndefined(this.props.clientID)) return type === 'lawyer' ? 'Delete All Law Firm Notes for This Client' : type = 'tycoon' ? 'Delete All Counterparty Notes for This Client' : 'Delete All Matter Notes for This Client'
    if (!isEmptyNullUndefined(this.props.matterID)) return type === 'lawyer' ? 'Delete All Law Firm Notes for This Matter' : type = 'tycoon' ? 'Delete All Counterparty Notes for This Matter' : 'Delete All Matter Notes for This Matter'
    if (!isEmptyNullUndefined(this.props.matterCPartyID)) return type === 'lawyer' ? 'Delete All Law Firm Notes for This Counterparty' : type = 'tycoon' ? 'Delete All Counterparty Notes for This Counterparty' : 'Delete All Matter Notes for This Matter'
    if (!isEmptyNullUndefined(this.props.counterpartyID)) return type === 'lawyer' ? 'Delete All Law Firm Notes for This Counterparty' : type = 'tycoon' ? 'Delete All Counterparty Notes for This Counterparty' : 'Delete All Matter Notes for This Counterparty'
    if (!isEmptyNullUndefined(this.props.lawfirmID)) return type === 'lawyer' ? 'Delete All Law Firm Notes for This Law Firm' : type = 'tycoon' ? 'Delete All Counterparty Notes for This Law Firm' : 'Delete All Matter Notes for This Law Firm'
    if (!isEmptyNullUndefined(this.props.personID)) return type === 'lawyer' ? 'Delete All Law Firm Notes for This Person' : type = 'tycoon' ? 'Delete All Counterparty Notes for This Person' : 'Delete All Matter Notes for This Person'
  }

  render() {
    if (this.state.hasError) return <h1>Something went wrong in Component-DeleteNotes</h1>
    else
      return (
        <div>

          {/* This will show a list of all of the Lawyer notes relating to this counterparty (either just for this matter or more generally) */} 
          {!isEmptyNullUndefined(this.state.LNotes) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Delete All Lawyer Notes Written for This {!isEmptyNullUndefined(this.props.clientID) ? 'Client' : !isEmptyNullUndefined(this.props.matterID) ? 'Matter' : 'Counterparty'}</h4>
              <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h6>

              <Button primary onClick={() => this.handleDeleteLNotes()}>{this.deleteButtonTextMaker('lawyer')}</Button>
              <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

              <ReactTableMaker passedData={this.state.LNotes}
                // passedColumns={PrecedentsColumns()}
                passedColumns={DeleteColumns('deletelawyernotes', this.props.history)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />

              {/* {this.state.LNotes.map((item, i) => {
                return <LNoteListMaker
                  {...item}
                  number={i}
                  matterID={this.props.matterID}
                  clientID={this.props.clientID}
                  lawfirmID={this.props.lawfirmID}
                  matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                  // note={item.note_number}
                  // handleDeleteTycoon={this.state.handleDeleteTycoon}
                  key={`LNM${i}`}
                />
              })
              } */}
            </Segment>
          }

          {/* This will show a list of all of the "Tycoon" notes relating to this counterparty (either just for this matter or more generally) */}
          {isEmptyNullUndefined(this.state.LNotes) && !isEmptyNullUndefined(this.state.TNotes) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Delete All Counterparty Notes Written for This {!isEmptyNullUndefined(this.props.clientID) ? 'Client' : !isEmptyNullUndefined(this.props.matterID) ? 'Matter' : 'Counterparty'}</h4>
              <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h6>

              <Button primary onClick={() => this.handleDeleteTNotes()}>{this.deleteButtonTextMaker('tycoon')}</Button>
              <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

              <ReactTableMaker passedData={this.state.TNotes}
                // passedColumns={PrecedentsColumns()}
                passedColumns={DeleteColumns('deletetycoonnotes', this.props.history)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />

              {/* {this.state.TNotes.map((item, i) => {
                return <TNoteListMaker
                  {...item}
                  number={i}
                  matterID={this.props.matterID}
                  clientID={this.props.clientID}
                  matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                  // note={item.note_number}
                  // handleDeleteTycoon={this.state.handleDeleteTycoon}
                  key={`TNM${i}`}
                />
              })
              } */}
            </Segment>
          }

          {/* This will show a list of all of the "Matter" notes */}
          {isEmptyNullUndefined(this.state.LNotes) && isEmptyNullUndefined(this.state.TNotes) && !isEmptyNullUndefined(this.state.MNotes) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Delete All Notes Written for This {!isEmptyNullUndefined(this.props.clientID) ? 'Client' : !isEmptyNullUndefined(this.props.matterID) ? 'Matter' : 'Counterparty'}</h4>
              <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h6>

              <Button primary onClick={() => this.handleDeleteMNotes()}>{this.deleteButtonTextMaker('matter')}</Button>
              <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

              <ReactTableMaker passedData={this.state.MNotes}
                // passedColumns={PrecedentsColumns()}
                passedColumns={DeleteColumns('deletematternotes', this.props.history)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />
{/* 
              {this.state.MNotes.map((item, i) => {
                return <MNoteListMaker
                  {...item}
                  number={i}
                  matterID={this.props.matterID}
                  clientID={this.props.clientID}
                  matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                  // note={item.note_number}
                  // handleDeleteTycoon={this.state.handleDeleteTycoon}
                  key={`MNM${i}`}
                />
              })
              } */}
            </Segment>
          }

          {/* <DisplayState state={this.state} /> */}

        </div>
      )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteNotes))


DeleteNotes.defaultProps = {
  Notes: [],
  MatterID: null,
  ClientID: null,
  counterpartyID: null,
  lawfirmID: null,
  handleDeleteLNote: () => { },
  handleDeleteTNote: () => { },
  handleDeleteMNote: () => { },
  handleCancelDelete: () => { },
}

// const LNoteListMaker = (props) => {
//   // console.log('This is the props in LNoteListMaker: ', props)
//   if (!isEmptyNullUndefined(props.counterparty_id) || !isEmptyNullUndefined(props.lawfirmID) || !isEmptyNullUndefined(props.matterID))
//     return (

//       <Segment secondary>

//         <h6><b>Note: </b>{props.note}</h6>
//         <h6><b>For Law Firm: </b>{props.law_firm}</h6>
//         {!isEmptyNullUndefined(props.matterID) && <h6><b>For Matter Number: </b>{props.matter_id}</h6>}
//         <h6><b>(For Counterparty): </b>{props.counterparty}</h6>
//         <h6><b>(For Matter): </b>{props.matter_name}</h6>
//         {!isEmptyNullUndefined(props.client_name) && <h6><b>(For Client): </b>{props.client_name}</h6>}

//       </Segment>
//     )
//   else return null
// }

// const TNoteListMaker = (props) => {
//   // console.log('This is the props in TNoteListMaker: ', props)
//   if (!isEmptyNullUndefined(props.counterparty_id))
//     return (

//       <Segment secondary>

//         <h6><b>Note: </b>{props.note}</h6>
//         {!isEmptyNullUndefined(props.matterID) && <h6><b>For Matter Number: </b>{props.matter_id}</h6>}
//         <h6><b>For Counterparty: </b>{props.counterparty}</h6>
//         <h6><b>(For Matter): </b>{props.matter_name}</h6>
//         {!isEmptyNullUndefined(props.client_name) && <h6><b>(For Client): </b>{props.client_name}</h6>}


//       </Segment>
//     )
//   else return null
// }

// const MNoteListMaker = (props) => {
//   // console.log('This is the props in TNoteListMaker: ', props)
//   if (!isEmptyNullUndefined(props.matter_id))
//     return (

//       <Segment secondary>

//         <h6><b>Note: </b>{props.note}</h6>
//         {!isEmptyNullUndefined(props.matterID) && <h6><b>For Matter Number: </b>{props.matter_id}</h6>}
//         <h6><b>(For Matter): </b>{props.matter_name}</h6>
//         {!isEmptyNullUndefined(props.client_name) && <h6><b>(For Client): </b>{props.client_name}</h6>}


//       </Segment>
//     )
//   else return null
// }


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const DeleteColumns = (type, history) => {
  let results = []

  switch (type) {
    case 'deletelawyernotes':
      results = [
    
        // {
        //   Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
        //   accessor: 'client_name',
        //   sortType: 'alphanumeric',
        //   Filter: '',
        //   Cell: ({ cell, row }) =>
        //   <span
        //     onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        //     style={{ 'float': 'left', cursor: 'pointer' }}
        //     onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        //   >
        //     {cell.value}
        //   </span>
        // },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: () => <span style={{ 'float': 'left' }}>Counterparty</span>,
          accessor: 'counterparty',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/counterparty/${row.original.counterparty}?counterparty=${row.original.counterparty}&counterpartyID=${row.original.counterparty_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: () => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'law_firm',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/lawfirm/${row.original.law_firm}?lawfirm=${row.original.law_firm}&lawFirmID=${row.original.law_firm_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note</span>,
          accessor: 'note',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note Date</span>,
          accessor: 'note_date',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
      ]
      break;
    case 'deletetycoonnotes':
      results = [
    
        // {
        //   Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
        //   accessor: 'client_name',
        //   sortType: 'alphanumeric',
        //   Filter: '',
        //   Cell: ({ cell, row }) =>
        //   <span
        //     onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        //     style={{ 'float': 'left', cursor: 'pointer' }}
        //     onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        //   >
        //     {cell.value}
        //   </span>
        // },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: () => <span style={{ 'float': 'left' }}>Counterparty</span>,
          accessor: 'counterparty',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/counterparty/${row.original.counterparty}?counterparty=${row.original.counterparty}&counterpartyID=${row.original.counterparty_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note</span>,
          accessor: 'note',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note Date</span>,
          accessor: 'note_date',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
      ]
      break;
    case 'deletematternotes':
      results = [
    
        // {
        //   Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
        //   accessor: 'client_name',
        //   sortType: 'alphanumeric',
        //   Filter: '',
        //   Cell: ({ cell, row }) =>
        //   <span
        //     onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        //     style={{ 'float': 'left', cursor: 'pointer' }}
        //     onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        //   >
        //     {cell.value}
        //   </span>
        // },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note</span>,
          accessor: 'note',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note Date</span>,
          accessor: 'note_date',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
      ]
      break;
    default:
    // code block
  }
  return results
}