import React from "react";

function SzenokLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      // height="44"
      version="1.1"
      viewBox="0 0 880.667 88"
      // xmlSpace="preserve"
    >
      <defs>
        <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
          <path d="M0 0h378v66H0z"></path>
        </clipPath>
        <clipPath id="clipPath46" clipPathUnits="userSpaceOnUse">
          <path d="M391 0h269.5v65H391z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 0 88)">
        <g>
          <g
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            clipPath="url(#clipPath18)"
            fontStretch="normal"
            fontVariant="normal"
            fontWeight="normal"
            writingMode="lr-tb"
          >
            <text
              style={{ InkscapeFontSpecification: "PoorRichard-Regular" }}
              fill="#f15a29"
              fontFamily="Poor Richard"
              fontSize="52"
              transform="matrix(1 0 0 -1 174.267 25.648)"
            >
              <tspan x="0" y="0">
                &amp;
              </tspan>
            </text>
            <text
              style={{ InkscapeFontSpecification: "AgencyFB-Reg" }}
              fill="#231f20"
              fontFamily="Agency FB"
              fontSize="73"
              transform="matrix(1 0 0 -1 220.475 9.438)"
            >
              <tspan x="0" y="0">
                O
              </tspan>
            </text>
            <text
              style={{ InkscapeFontSpecification: "AgencyFB-Reg" }}
              fill="#231f20"
              fontFamily="Agency FB"
              fontSize="50"
              transform="matrix(1 0 0 -1 252.947 9.438)"
            >
              <tspan
                x="0 19.25 41.200001 63.049999 72.849998 91.699997 100.6 109.5"
                y="0"
              >
                KUN pllc
              </tspan>
            </text>
            <text
              style={{ InkscapeFontSpecification: "AgencyFB-Reg" }}
              fill="#231f20"
              fontFamily="Agency FB"
              fontSize="73"
              transform="matrix(1 0 0 -1 -4.066 9.582)"
            >
              <tspan x="0" y="0">
                S
              </tspan>
            </text>
            <text
              style={{ InkscapeFontSpecification: "AgencyFB-Reg" }}
              fill="#231f20"
              fontFamily="Agency FB"
              fontSize="50"
              transform="matrix(1 0 0 -1 26.445 9.582)"
            >
              <tspan
                x="0 17.200001 35.049999 56.900002 78.150002 96 117.35"
                y="0"
              >
                ZENBERG
              </tspan>
            </text>
          </g>
        </g>
        <g>
          <g
            fill="#231f20"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            clipPath="url(#clipPath46)"
            fontFamily="Agency FB"
            fontStretch="normal"
            fontVariant="normal"
            fontWeight="normal"
            writingMode="lr-tb"
          >
            <text
              style={{ InkscapeFontSpecification: "AgencyFB-Reg" }}
              fontSize="73"
              transform="matrix(1 0 0 -1 583.475 8.438)"
            >
              <tspan x="0" y="0">
                S
              </tspan>
            </text>
            <text
              style={{ InkscapeFontSpecification: "AgencyFB-Reg" }}
              fontSize="50"
              transform="matrix(1 0 0 -1 615.947 8.438)"
            >
              <tspan x="0 9.9499998 27.1" y="0">
                ITE
              </tspan>
            </text>
            <text
              style={{ InkscapeFontSpecification: "AgencyFB-Reg" }}
              fontSize="73"
              transform="matrix(1 0 0 -1 392.934 8.582)"
            >
              <tspan x="0" y="0">
                C
              </tspan>
            </text>
            <text
              style={{ InkscapeFontSpecification: "AgencyFB-Reg" }}
              fontSize="50"
              transform="matrix(1 0 0 -1 423.445 8.582)"
            >
              <tspan
                x="0 22.25 44.099998 61.099998 77.949997 87.900002 109.15 126.3"
                y="0"
              >
                ONFLICTS
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SzenokLogo;