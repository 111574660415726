
export function resetCPInfo(toggle) {
  return {
    type: "RESET_COUNTERPARTY_INFO",
    payload: toggle
  }
}

export function setAddCPInfo(toggle) {
  return {
    type: "CURRENT_ADD_COUNTERPARTY_INFO",
    payload: toggle
  }
}

export function setAddCP(toggle) {
  // console.log('In setAddCP and this is toggle: ', toggle)
  return {
    type: "CURRENT_ADD_COUNTERPARTY",
    payload: toggle
  }
}

export function setAddLawFirm(toggle) {
  // console.log('In setAddLawFirm and this is toggle: ', toggle)
  return {
    type: "CURRENT_ADD_LAWFIRM",
    payload: toggle
  }
}

export function setAddBusinessMan(toggle) {
  // console.log('In setAddBusinessman and this is toggle: ', toggle)
  return {
    type: "CURRENT_ADD_BUSINESSMAN",
    payload: toggle
  }
}

export function setAddLawyer(toggle) {
  // console.log('In setAddLawyer and this is toggle: ', toggle)
  return {
    type: "CURRENT_ADD_LAWYER",
    payload: toggle
  }
}

export function setAddBill(toggle) {
  // console.log('In setAddLawyer and this is toggle: ', toggle)
  return {
    type: "CURRENT_ADD_BILL",
    payload: toggle
  }
}
