import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';
import ReactTableMaker from '../helper/ReactTableMaker'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Button, Segment, Dropdown } from 'semantic-ui-react';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

const initialState = {
  Matters: null,
  MIDs: [],
}

class DeleteAClass extends Component {

  state = {
    hasError: false, ...initialState,
    selectedSwitchAClass: null,
    AClassDropDownArray: [],
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    if (!isEmptyNullUndefined(this.props.AClassID)) {
      this.getAClasses()
    }
    this.setState({ AClassDropDownArray: this.AClassDropDownArray() })
  }


  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.AClassID !== this.props.AClassID) {
      this.props.fetchResetState('all')
      this.getAClasses()
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  getAClasses = async () => {

    this.props.fetchResetState('all')
    this.setState({ ...this.state, ...initialState })
    // console.log('In DeleteAClass and fetching matters for this asset class: ', this.props.AClassID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.AClassID)) {
      fetchresponse = await szenokPostAPI('assetClassInfo', { fetchstr: 'getMattersForAssetClass', IDNumber: this.props.AClassID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse for getAClasses(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        Matters: fetchresponse.payload,
        MIDs: Array.from(new Set(fetchresponse.payload.map(matter => matter.matter_id))),
      })
    }
    else {
      // console.log('GOT TO HERE!!!!###$#')
      this.props.handleDeleteAClass()
      // console.log('CLICKED ON IT!!!')
    }
  }

  handleSwitchAClass = async (specificMatter) => {

    if (isEmptyNullUndefined(specificMatter)) {
      // console.log('No specific matter to deal with so switching them all...')
      try {

        //Clear fetcherrors, info and such
        this.props.fetchResetState('all')

        let fetchresponse = {}
        fetchresponse = await szenokPostAPI('assetClassInfo', {
          fetchstr: 'switchAssetClass',
          matterID: this.state.MIDs.join(),
          switchAClass: this.state.selectedSwitchAClass
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse deleteAClass: ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {

          //Update the matters store
          this.setState({ selectedSwitchAClass: null })

          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          reduxupdate('assetclasses')
          this.getAClasses()
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        let errorObj = []
        errorObj.push(error.toString())

        this.props.fetchErrors(errorObj)
      }
    }
    else {
      // console.log('We got a specific matter to deal with and it is #', specificMatter)
      try {

        //Clear fetcherrors, info and such
        this.props.fetchResetState('all')

        let fetchresponse = {}
        fetchresponse = await szenokPostAPI('assetClassInfo', {
          fetchstr: 'switchAssetClass',
          matterID: specificMatter.matter_id,
          switchAClass: specificMatter.selected
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse deleteAClass: ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {

          //Update the matters store
          // reduxupdate('matters')
          this.setState({ selectedSwitchAClass: null })

          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          // this.getPaymentsForInvoice()
          // this.props.getInvoicesOutstanding()
          reduxupdate('assetclasses')
          this.getAClasses()
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        let errorObj = []
        errorObj.push(error.toString())

        this.props.fetchErrors(errorObj)
      }
    }

  }

  AClassDropDownArray = () => {

    var array = this.props.clients.assetclassarray.reduce((acc, result) => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `EID${result[0]}}`
      obj.text = result[1]
      obj.value = result[0]

      if (obj.value === this.props.AClassID) {
        // console.log(`Not including asset class with ID of ${obj.value} (which is the one called '${this.props.clients.assetclassarray.find(aclass => aclass[0] === obj.value)[1]}')`)
        return acc
      }
      else {
        acc.push(obj)
        return acc
      }
    },
      []
    )
    // console.log('This is array: ', array)
    return array
  }



  render() {
    if (this.state.hasError) return <h1>Something went wrong in Component-DeleteAclass</h1>
    else
      return (
        <div>

          {/* This will show a list of all of the counterparty-matter connections */}
          {!isEmptyNullUndefined(this.state.Matters) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Switch All Matters Using This Asset Class to a Different Asset Class</h4>
              <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Switch' button!)</h6>

              <Button primary disabled={isEmptyNullUndefined(this.state.selectedSwitchAClass)} onClick={() => this.handleSwitchAClass()}>Switch</Button>
              {/* <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button> */}
              <Button color='red' onClick={() => {
                if (isEmptyNullUndefined(this.state.selectedSwitchAClass)) this.props.handleCancelDelete()
                else this.setState({ selectedSwitchAClass: null })
              }
              }>Cancel</Button>
              <br /><br />

              <Dropdown
                placeholder='Select Asset Class'
                name="AClass"
                width={5}
                selection
                search
                label='Asset Class'
                options={this.state.AClassDropDownArray}
                value={this.state.selectedSwitchAClass}
                loading={this.state.success}
                onChange={(e, { name, value }) => {
                  this.setState({ selectedSwitchAClass: value })
                }}
              />

              <ReactTableMaker passedData={this.state.Matters}
                passedColumns={DeleteColumns('deletematters', this.props.history, this.handleSwitchAClass, this.state.AClassDropDownArray)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />
            </Segment>}

          {/* <DisplayState state={this.state} /> */}

        </div>
      )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteAClass))


DeleteAClass.defaultProps = {
  AClassID: null,
  handleDeleteAClass: () => { },
  handleCancelDelete: () => { },
}

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const DeleteColumns = (type, history, handleSwitchAClass, AClassDropDownArray) => {
  let results = []

  switch (type) {
    case 'deletematters':
      results = [
        {
          Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Amount</span>,
          accessor: 'matter_deal_amount',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Origination Date</span>,
          accessor: 'matter_origination_date',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>
            <React.Fragment>
              Switch
              <h6 style={{ color: 'blue' }}>(You can switch each matter individually, as well...)</h6>
            </React.Fragment>
          </span>,
          accessor: 'matter_origination_date2',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) => <Switcher
            AClassDropDownArray={AClassDropDownArray}
            matter_id={row.original.matter_id}
            handleSwitchAClass={handleSwitchAClass}
          />
        },
      ]
      break;
    default:
    // code block
  }
  return results
}


class Switcher extends Component {

  state = {
    hasError: false, ...initialState,
    selected: null,
  }

  render() {

    return (
      <React.Fragment>

        <Button primary disabled={isEmptyNullUndefined(this.state.selected)} size='tiny' onClick={() => this.props.handleSwitchAClass({ selected: this.state.selected, matter_id: this.props.matter_id })}>Switch</Button>
        <Button color='red' size='tiny' onClick={() => this.setState({ selected: null })}>Cancel</Button>
        <Button size="tiny">
          <Dropdown
            placeholder='Select Asset Class'
            name="AClass"
            search
            label='Asset Class'
            options={this.props.AClassDropDownArray}
            value={this.state.selected}
            onChange={(e, { name, value }) => {
              this.setState({ selected: value })
              // console.log(name, value)
            }}
          />
        </Button>
      </React.Fragment>
    )
  }
}
