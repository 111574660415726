import React, { useEffect } from 'react'
import L from "leaflet"
// import '../charts/Charts-Locations-ZoomChoices.css'
import { isEmptyNullUndefined } from "../helper/Misc";

import allStatesGEOJSON from '../charts/locations/statesGEOJSON.json'
import './Component-Maps-Locations-Map.css'

// import { setCurrentZoomLevel, setCurrentLatitude, setCurrentLongitude } from '../../actions/mapActions'
// import store1 from '../../store.js'
import {  useDispatch } from 'react-redux';

const ZoomChoices = ({ leaflet, sendZoomCenterToParent = () => { } }) => {

  const [currentLatLng, setCurrentLatLng] = React.useState({ lat: null, lng: null })
  // const currentZoomLevel = useSelector(state => state.maps.currentZoomLevel)
  // const currentLatitude = useSelector(state => state.maps.currentLatitude)
  // const currentLongitude = useSelector(state => state.maps.currentLongitude)
  const dispatch = useDispatch()

  useEffect(() => {
    //Track mouse movements (for lat/lng at bottom right)
    !isEmptyNullUndefined(leaflet) && leaflet.addEventListener('mousemove', (e) => {
      // console.log('This is e: ', e.latlng.lng)
      setCurrentLatLng({ lat: e.latlng.lat, lng: e.latlng.lng });
    })
    //Stop tracking mouse movements (if move mouse off map)
    !isEmptyNullUndefined(leaflet) && leaflet.addEventListener('mouseout', (e) => {
      // console.log('This is e: ', e.latlng.lng)
      setCurrentLatLng({ lat: null, lng: null });
    })
  }, [leaflet])


  return (
    <div className='zoom-container2'>
      {/* <div className='zoom-control2 zoomButton2 zoom-control-showClusters' onClick={() => setShowClusters(!showClusters)}>Show Clusters</div><br/> */}
      <div
        className='zoom-control2 zoomButton2'
        onClick={() => {
          leaflet.flyTo([39.8097343, -98.5556199], 4, { animate: true, duration: 0.55 })
          // leaflet.on('zoomend', function () {
          //   sendZoomCenterToParent(leaflet.getZoom(), leaflet.getCenter())
          // })

        }}
      >USA</div>
      <div className='zoom-control2 zoomButton2'
        onClick={() => {
          leaflet.flyTo([40.73, -73.93], 10, { animate: true, duration: 0.55 })
          // leaflet.on('zoomend', function () {
          //   sendZoomCenterToParent(leaflet.getZoom(), leaflet.getCenter())
          // })
        }}
      >NYC</div>
      <div className='zoom-control2 zoomButton2' onClick={() => { zoomToState(leaflet, 'New York', sendZoomCenterToParent, dispatch) }}>NY</div>
      <div className='zoom-control2 zoomButton2' onClick={() => { zoomToState(leaflet, 'California', sendZoomCenterToParent, dispatch) }}>CA</div>
      <div className='zoom-control2 zoomButton2' onClick={() => { zoomToState(leaflet, 'Florida', sendZoomCenterToParent, dispatch) }}>FL</div>
      <div className='zoom-control2 zoomButton2' onClick={() => { zoomToState(leaflet, 'Texas', sendZoomCenterToParent, dispatch) }}>TX</div>
      <div className='zoom-latlng2'>
        <div className='zoom-latlng-button2'>
          lat: {!isEmptyNullUndefined(currentLatLng.lat) ? currentLatLng.lat.toFixed(4) : '...'}
        </div>
        <div className='zoom-latlng-button2'>
          lng: {!isEmptyNullUndefined(currentLatLng.lat) ? currentLatLng.lng.toFixed(4) : '...'}
        </div>
      </div>
    </div >
  )
}

export default ZoomChoices

let getBoundingBox = (statename) => {
  let geoJSONData = allStatesGEOJSON.find(state => state.properties.name === statename)
  let geoJsonLayer = L.geoJson(geoJSONData)
  // let bounds = geoJsonLayer.getBounds().toBBoxString()
  let bounds = geoJsonLayer.getBounds()
  // console.log(bounds)
  return bounds
}

let zoomToState = (leaflet, state, sendZoomCenterToParent, dispatch) => {

  let bounds = getBoundingBox(state);
  // console.log(bounds)
  leaflet.flyToBounds([
    [bounds['_northEast'].lat, bounds['_northEast'].lng],
    [bounds['_southWest'].lat, bounds['_southWest'].lng]
  ], { animate: true, duration: 0.55 })

  // leaflet.on('moveend', function () {
  //   let z = leaflet.getZoom()
  //   let c = leaflet.getCenter()
  //   // sendZoomCenterToParent(z, c)
  //   // dispatch(setCurrentLatitude(c.lat))
  //   // dispatch(setCurrentLongitude(c.lng))
  // })

}