import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { isEmptyNullUndefined, Loading } from '../helper/Misc'
import { toIMGcanvas } from '../helper/PDFImage'

import { Checkbox, Segment, Grid } from 'semantic-ui-react';


// import DisplayState from '../helper/DisplayState';
import ReactTableMaker from '../helper/ReactTableMaker'
import ChartsBalanceSheetSVG from './Charts-Balance-Sheet-SVG'

class BalanceSheet extends Component {

  state = {
    TokenReady: false,
    loading: true,
    // currentYear: null,
    // selectedYear: null,
    InvoicesByYear: [],
    showAccrual: true,
    showCash: true,
    Loading: true,
    isMounted: false,
  }

  async componentDidMount() {
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      // this.setState({ currentYear: new Date().getFullYear(), selectedYear: new Date().getFullYear() })
      this.getPartnerBilling()
    }
    this.setState({isMounted : true})
  }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.loading) {
      this.setState({ TokenReady: true })
    }
    if (prevProps.login.currentToken !== this.props.login.currentToken) {
      this.getPartnerBilling()
    }
  }

  getPartnerBilling = async () => {
    // console.log('Launching getPartnerBilling...')

    this.props.fetchResetState('all')

    let fetchresponse = await szenokPostAPI('getCharts', { fetchstr: 'fiscalSales', year: 2020 },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getPartnerGroupBilling: ', fetchresponse)

    this.state.isMounted && !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      InvoicesByYear: fetchresponse.payload,
      Loading: false,
    })
  }


  render() {

    let checkboxStyle = { paddingRight: 30, paddingTop: 10, paddingBottom: 10 }
    let chartStyle = {
      background: "#ebf5fa",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      fontFamily: "'Fira Sans', sans-serif",
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderColor: '#a5a5a5',
      borderRadius: '15px',
      boxShadow: '2px 2px 8px #888888',
      cursor: 'pointer',
    }

    return (

      <React.Fragment>

        {/* <DisplayState state={this.state} /> */}
        {/* <Header as='h3'>Balance Sheet</Header>
        <Divider /> */}

        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Segment compact color='blue'>
                <Checkbox
                  style={checkboxStyle}
                  label='Accrual'
                  // value={this.state}
                  checked={this.state.showAccrual}
                  onChange={() => {
                    this.setState((prevState) => ({ showAccrual: !prevState.showAccrual }))
                    if (!this.state.showCash) this.setState({ showCash: true })
                  }}
                />
                <Checkbox
                  style={checkboxStyle}
                  label='Cash'
                  // value={this.state}
                  checked={this.state.showCash}
                  onChange={() => {
                    this.setState((prevState) => ({ showCash: !prevState.showCash }))
                    if (!this.state.showAccrual) this.setState({ showAccrual: true })
                  }}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={12} />
          </Grid.Row>
          <Grid.Row>

            <Grid.Column width={5}>
              {/* {!isEmptyNullUndefined(this.state.InvoicesByYear) && */}
              <ReactTableMaker passedData={this.state.InvoicesByYear}
                // passedColumns={PrecedentsColumns()}
                passedColumns={InfoColumns('year', this.state.showAccrual, this.state.showCash)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='olive'
                showDownloads={true}
              />
              {/* } */}
            </Grid.Column>
            <Grid.Column width={11}>

              <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
                <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
                  <div id='BalanceSheet' >
                    {this.state.Loading ? <Loading /> :
                      !isEmptyNullUndefined(this.state.InvoicesByYear) &&
                      <svg
                        style={chartStyle}
                        onClick={() => toIMGcanvas('BalanceSheet')}
                        height={600 * this.props.maxViewPercentage / 100} viewBox="0 0 550 395">
                        <ChartsBalanceSheetSVG
                          // selectedYear={this.state.selectedYear}
                          // currentYear={this.state.currentYear}
                          // selectedPartner={this.state.selectedPartner}
                          maxViewPercentage={this.props.maxViewPercentage}
                          maxYears={this.props.maxYears}
                          InvoicesByYear={this.state.InvoicesByYear}
                          showAccrual={this.state.showAccrual}
                          showCash={this.state.showCash}
                          chartStyle={chartStyle}
                        />
                      </svg>

                    }
                  </div>
                </Segment>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BalanceSheet))


BalanceSheet.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  // handleSomething: () => { },
}


const InfoColumns = (type, showAccrual, showCash) => {
  let results = []
  results = [

    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Year</span>,
      accessor: 'year',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'right' }}>Accrual Basis</span>,
      accessor: 'total_invoices',
      show: showAccrual ? true : false,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => <span style={{ 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'right' }}>Cash Basis</span>,
      accessor: 'total_payments',
      show: showCash ? true : false,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => <span style={{ 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
    }
  ]

  return results
}
