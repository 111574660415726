import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, filterSimpleInput } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Grid, Form, Header, Label, Divider, Dropdown, Button, Segment } from 'semantic-ui-react';
import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';
// import { isNullOrUndefined } from 'util';

const initialState = {
  visible: false,
  ClientsMatters: [],
  ExistingMatters: [],
  ExistingPrecedents: [],
  SelectedClient: null,
  SelectedClientName: '',
  SelectedMatter: null,
  SelectedMatterOriginationDate: null,
  Matter: '',
  Invoice2Regex: [],
  Invoice2RegexSpecific: '',
  MatterArray: [],
  Description: null,
  initialValues: {},
  ClientMatterDropDownArray: [],
}

class AddPrecedent extends Component {

  state = initialState

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getClientsMatters()
    if (this.props.update) {
      await this.getSpecificPrecedent()

      await this.getPrecedents(this.state.initialValues.client_id)

      this.setState({ SelectedClient: this.state.initialValues.client_id, SelectedMatter: this.state.initialValues.matter_id })
    }
  }

  getSpecificPrecedent = async () => {
    // console.log('Running getSpecificPrecedent....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('precedentInfo', { fetchstr: 'getPrecedent', precedentID: this.props.match.params.precedentID },
      this.props.login.currentToken)
    console.log('Now printing fetchresponse for getPrecedent (in getSpecificPrecedent): ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { ...fetchresponse.payload },
      SelectedMatterOriginationDate: fetchresponse.payload.matter_origination_date
    })
  }

  getClientsMatters = async () => {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'clientsmattersarray' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for clientsmattersarray (in getClientsMatters): ', fetchresponse)
    this.setState({
      ClientsMatters: fetchresponse.payload.map(matter => {
        var arr = []
        arr[0] = matter.client_id
        arr[1] = matter.client_name
        arr[2] = matter.matter_id
        arr[3] = matter.matter_name
        arr[4] = matter.employee_id
        arr[5] = matter.matter_origination_date
        return arr
      }
      )
    }, () => this.setState({ClientMatterDropDownArray: this.ClientMatterDropDownArray()}))
  }

  getPrecedents = async (client_id) => {

    this.props.fetchResetState('all')
    // console.log('Fetching for this client: ', client_id)
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'precedentsforclient', IDNumber: client_id },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for precedentsforclient (in getPrecedents): ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    this.setState({
      ExistingPrecedents: fetchresponse.payload.map(precedent => {
        var arr = []
        arr[1] = precedent.precedent_type
        arr[2] = precedent.precedent_file
        arr[4] = precedent.precedent_description
        arr[3] = precedent.precedent_date
        arr[5] = precedent.matter_id
        return arr
      }
      )
    })
  }

  getClientName(clientID) {
    let result = this.props.clients.clientsarray.find(row => row[0] === parseInt(clientID))
    // console.log('Result is: ', result)
    let name = ''
    if (result[1].length > 0) name = result[1]
    else name = result[3] + ', ' + result[2]
    return name
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ Invoice2Regex: updatedListGeneralSorted })
    if (updatedListSpecific.length > 0) {
      if (this.props.update && updatedListSpecific[0][1] !== this.state.initialValues.precedent_id) {
        this.setState({ Invoice2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ Invoice2RegexSpecific: '' })
      setStatus({ errors: {} })
    }

  }, 100)

  ClientDropDownArray = () => {
    var array = this.props.clients.clientsarray.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `CID${result[0]}`
      if (result[1].length > 0) obj.text = result[1]
      else obj.text = result[3] + ', ' + result[2]
      obj.value = result[0]
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }
  ClientMatterDropDownArray = () => {
    var array = this.state.ClientsMatters.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `CMID${result[0]},${result[2]}`
      obj.text = result[1] + ' -- ' + result[3]
      obj.value = result[0] + ',' + result[2]// + ',' + result[4]
      // obj.employee = result[4]
      return obj
    }
    )
    // console.log('This is ClientMatterDropDownArray: ', array)
    return array
  }


  render() {

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      ClientMatter: null,
      Type: '',
      File: null,
      Description: null,
      PDate: 'a',
    } : {
        ClientMatter: this.state.initialValues.client_id + ',' + this.state.initialValues.matter_id,
        Type: this.state.initialValues.precedent_type,
        File: this.state.initialValues.precedent_file,
        PDate: new Date(this.state.initialValues.precedent_date),
        Description: this.state.initialValues.precedent_description,
      }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300


    return (
      <React.Fragment>
        {this.props.headingForForm.length <= 0 ?
          <Header as='h1'>Precedent {this.props.update === true ? 'Edit' : 'Add'} Form</Header> :
          <Header as='h3'>{this.props.headingForForm}</Header>
        }
        {this.props.update && !isEmptyNullUndefined(this.state.initialValues) &&
          <Header as='h2'>Editing: {this.state.initialValues.precedent_number}</Header>
        }


        <Formik

          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={AddPrecedentSchema}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              this.setState({ Invoice2Regex: [], Invoice2RegexSpecific: '' })
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editPrecedent', {
                Type: values.Type, File: values.File,
                PDate: values.PDate, Description: values.Description,
                MatterID: this.state.SelectedMatter,
                precedentID: this.props.match.params.precedentID
              }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addPrecedent', {
                Type: values.Type, File: values.File,
                PDate: values.PDate, Description: values.Description,
                MatterID: this.state.SelectedMatter
              }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for addPrecedent: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                //Update the Existing Invoices
                // this.getInvoices()

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                actions.resetForm({ ...initialValues })
                this.setState(initialState)
                await reduxupdate('precedents')
                this.getClientsMatters()
                if (this.props.update) {
                  await this.getSpecificPrecedent()
                  await this.getPrecedents(this.state.initialValues.client_id)
                  this.setState({ SelectedMatter: this.state.initialValues.matter_id })
                }
                this.props.update && this.props.history.goBack()

              }
            }
            catch (error) { this.props.fetchErrors(error.toString()) }
            actions.setSubmitting(false)
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment>
                  <Grid columns={16} padded divided={this.state.ExistingPrecedents.length > 0 ? true : false}>
                    <Grid.Row>
                      <Grid.Column width={this.state.ExistingPrecedents.length > 0 ? 13 : 16}>

                        <Form onSubmit={handleSubmit} autoComplete='off'>

                          {/* First, put in the Client/Matter */}
                          <Form.Group>
                            <Form.Field required width={14} error={(touched.ClientMatter && isEmptyNullUndefined(values.ClientMatter)) || has(status, 'errors.ClientMatter')}>
                              <label>Client/Matter</label>
                              <Dropdown
                                placeholder='Select Client/Matter'
                                name="ClientMatter"
                                width={6}
                                required
                                search
                                label='Client/Matter'
                                error={(touched.ClientMatter && (typeof get(values, 'ClientMatter') === 'undefined' || get(values, 'ClientMatter') === null)) ||
                                  (has(status, 'errors.ClientMatter'))}
                                closeOnBlur
                                selection
                                value={values.ClientMatter}
                                onBlur={() => { setFieldTouched('ClientMatter', true) }}
                                options={this.state.ClientMatterDropDownArray}
                                // value={this.props.fetch.fetching === true ? null : values.TType}
                                loading={this.props.fetch.fetching}
                                onChange={async (e, { name, value }) => {
                                  setFieldValue(name, value)
                                  // setFieldValue('InvoiceNumber', '')
                                  this.setState({
                                    SelectedClient: value.split(',')[0],
                                    SelectedClientName: this.getClientName(value.split(',')[0]),
                                    SelectedMatterOriginationDate: this.state.ClientsMatters.find(matter => matter[2] === parseInt(value.split(',')[1]))[5],
                                    SelectedMatter: parseInt(value.split(',')[1]),
                                    // SelectedEmployeeID: parseInt(value.split(',')[2])
                                  }, () => {
                                    // this.getInvoices(value.split(',')[0])
                                    // setFieldValue('PartnerPick', this.state.SelectedEmployeeID)
                                  }
                                  )
                                }}
                              />
                            </Form.Field>
                          </Form.Group>

                          {this.errorTag(errors, touched, status, 'Client', 'Pick a Client. ', 'Pick a Client. ')}

                          {/* Show the rest of this only if there is a client chosen, first */}
                          {!isEmptyNullUndefined(values.ClientMatter) &&
                            <React.Fragment>

                              {/* Selected Client: {this.state.SelectedClient}, Selected Matter: {this.state.SelectedMatter}
                        <br/>
                        Last Used Invoice Number for this client: {this.state.LastUsedInvoiceNumber}
                        <br /> */}


                              <Form.Group>

                                {/* Add the Precedent Type */}
                                <Form.Field width={7}>
                                  <Form.Input
                                    name="Type" type="text"
                                    label='Precedent Type'
                                    loading={this.props.fetch.fetching}
                                    required
                                    placeholder={'Type of Precedent'}
                                    error={(errors.Type && touched.Type) || (has(status, 'errors.Type'))}
                                    onChange={async (e) => {
                                      await handleChange(e);
                                      this.setState({ Type: e.target.value })
                                      this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, this.state.ExistingPrecedents)
                                    }}
                                    onBlur={handleBlur}
                                    options={this.state.ClientMatterDropDownArray}

                                    value={values.Type} />
                                  {this.errorTag(errors, touched, status, 'Type')}
                                </Form.Field>
                              </Form.Group>

                              {/* List Out Invoices with Same Name
                              <Segment secondary={this.state.Invoice2RegexSpecific.length === 0} color={this.state.Invoice2RegexSpecific.length > 0 ? 'red' : 'blue'}
                                hidden={this.state.Invoice2Regex.length === 0}>
                                {ListMakerSimple(this.state.Invoice2Regex, values, this.state.Invoice2RegexSpecific, 'Invoice')}
                              </Segment> */}

                              {/* Add the File */}
                              <Form.Field width={7}>
                                <Form.Input
                                  name="File" type="text"
                                  label={`File Name (on System)`}
                                  loading={this.props.fetch.fetching}
                                  required
                                  placeholder={`File Name`}
                                  error={(errors.File && touched.File) || (has(status, 'errors.File'))}
                                  onChange={async (e) => {
                                    await handleChange(e);
                                    this.setState({ File: e.target.value })
                                  }}
                                  onBlur={handleBlur}
                                  value={!isEmptyNullUndefined(values.File) ? values.File : ''} />
                                {this.errorTag(errors, touched, status, 'File')}
                              </Form.Field>

                              {/* Precedent Creation Date */}
                              <Form.Field required width={6} error={(touched.PDate && isNaN(values.PDate)) || has(status, 'errors.PDate')}>
                                <label>Precedent Creation Date {!isEmptyNullUndefined(this.state.SelectedMatterOriginationDate) ? `(matter originated on ${this.state.SelectedMatterOriginationDate})` : null}</label>
                                <DatePicker
                                  name="PDate"
                                  onSelect={async (date) => {
                                    //console.log('We have selected this date: ', date)
                                    setFieldValue('PDate', date)
                                    setFieldTouched('PDate', true)
                                    //I cannot console.log the new values.PDate here.  I get the old date.  Can't figure out how to make that work.
                                    // console.log('And this is values: ', values.PDate)
                                  }}
                                  dateFormat='MMMM d, yyyy'
                                  minDate={new Date(!isEmptyNullUndefined(this.state.SelectedMatterOriginationDate) ? this.state.SelectedMatterOriginationDate : '2011,01,01')}
                                  maxDate={new Date()}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  withPortal
                                  loading={this.props.fetch.fetching}
                                  onBlur={() => setFieldTouched('PDate', true)}
                                  todayButton={'Today'}
                                  selected={values.PDate !== 'a' ? values.PDate : null}
                                  placeholderText="Select an origination date"
                                />
                                {this.errorTag(errors, touched, status, 'PDate')}
                              </Form.Field>


                              {/* Add the description */}
                              <Form.Field width={14}>
                                <Form.TextArea
                                  name="Description" type="text"
                                  label={`Description for Precedent`}
                                  loading={this.props.fetch.fetching}
                                  required
                                  placeholder={`Description for Precedent`}
                                  error={(errors.Description && touched.Description) || (has(status, 'errors.Description'))}
                                  onChange={async (e) => {
                                    await handleChange(e);
                                    this.setState({ Description: e.target.value })
                                  }}
                                  onBlur={handleBlur}
                                  value={!isEmptyNullUndefined(values.Description) ? values.Description : ''} />
                                {this.errorTag(errors, touched, status, 'Description')}
                              </Form.Field>

                            </React.Fragment>
                          }

                          <Divider />
                          <Button.Group size='large'>
                            <Button type='button' icon='cancel'
                              onClick={() => {
                                handleReset()
                                let ClientsMatters = this.state.ClientsMatters
                                this.setState(initialState)
                                this.setState({ ClientsMatters: ClientsMatters })
                              }}
                              color='red'
                              content='Cancel'></Button>
                            <Button.Or />
                            <Button color='teal'
                              onClick={() => {
                                setFieldValue('Type', '')
                                setFieldValue('File', null)
                                setFieldValue('PDate', 'a')
                                setFieldValue('Description', null)
                                setFieldTouched('Type', false)
                                setFieldTouched('File', false)
                                setFieldTouched('PDate', false)
                                setFieldTouched('Description', false)
                                // setFieldTouched({})
                                this.setState({ Description: null, Matter: '', Invoice2Regex: [], Invoice2RegexSpecific: '' })
                              }}
                              type='button'
                              disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                              content='Clear Form'>
                            </Button>
                            <Button.Or />
                            <Button color='orange'
                              onClick={() => { setStatus({}); setErrors({}) }}
                              type='button'
                              //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                              content='Clear Error Notifications'>
                            </Button>
                            <Button.Or />
                            <Button type='submit'
                              // disabled={isSubmitting}
                              //Eventually use the below, the one above is just for testing
                              disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                              icon='upload'
                              loading={isSubmitting}
                              floated='right'
                              color={dirty ? 'green' : 'grey'}
                              content={(this.props.update === true ? 'Edit' : 'Add') + ' Precedent'}></Button>
                          </Button.Group>
                          {/* <DisplayState state={this.state} />
                          <DisplayFormikState /> */}
                        </Form>

                      </Grid.Column>
                      {this.state.ExistingPrecedents.length > 0 ?
                        <Grid.Column width={3}>
                          {this.state.ExistingPrecedents.length > 0 ?
                            
                            'There are some other precedents for this.'
                            
                            // <InvoiceList key={this.state.ExistingInvoices}
                            //   ExistingInvoices={this.state.ExistingInvoices}
                            //   SelectedClientName={this.state.SelectedClientName}
                            //   SelectedMatter={this.state.SelectedMatter}
                            //   mattersarray={this.props.clients.mattersarray} /> 
                              
                              
                              :
                            null}
                        </Grid.Column> : null}
                    </Grid.Row>
                  </Grid>
                </Segment>
              </div>
            )}
        </Formik>

      </React.Fragment>
    )
  }
}


const AddPrecedentSchema = Yup.object().shape({
  Type: Yup
    .string()
    .min(3, 'Type must be at least 3 characters.')
    .max(200, 'Type cannot exceed 200 characters.')
    .required('Must have a type for this.'),
  File: Yup
    .string()
    .min(3, 'File identifier must be at least 3 characters.')
    .max(200, 'File identifier cannot exceed 200 characters.')
    .required('Must have a file identifier for this.'),
  Description: Yup
    .string()
    .min(3, 'Description must be at least 3 characters.')
    .max(10000, 'Description cannot exceed 10,000 characters.')
    .required(),
  //Fix:  Can't figure out why I can't get date to valPDate.  Just letting JOI handle it for now.
  // PDate: Yup
  // .date()
  // .required()
  // .typeError('No date selected.'),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(AddPrecedent)

AddPrecedent.defaultProps = {
  getLawyers: () => { },
  getTycoons: () => { },
  getNotes: () => { },
  headingForForm: '',
}


