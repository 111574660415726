import React from 'react'
import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { Segment, Image, Dimmer, Loader } from 'semantic-ui-react'
//const dateFormat = require('dateformat');
// const moment = require('moment')
// const names = require('../helper/ColNames.js')



const Loading = () => (
  <div>
    <Segment>
      <Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>
      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </Segment>
  </div>)

const recreateTables = async (seed, token) => {

  await szenokPostAPI('recreateTables', { test: 'Not much here' },
    token)

  //This part is if we click on the button that also seeds the employees, transaction types, asset classes and law firms.
  if (seed) {
    let seedings = ['seedemployees', 'seedtransactiontypes', 'seedsubtransactiontypes', 'seedassetclasses', 'seedlawfirms', 'seedcounterpartiesfromJSON']
    for (const indseed of seedings) {
      if (true) {
        await szenokPostAPI('seedTable', { actiontype: indseed },
          token)
      }
    }

    setTimeout(() => { reduxupdate('employees') }, 400)
    setTimeout(() => { reduxupdate('transactions') }, 400)
    setTimeout(() => { reduxupdate('transactionsandsubs') }, 400)
    setTimeout(() => { reduxupdate('assetclasses') }, 400)
    setTimeout(() => { reduxupdate('lawfirms') }, 400)
  }
}

// const StringToDate = ({ input }) => {
//   const converteddate = new Date(input)
//   const tweakeddated = moment(converteddate).format('YYYY-MM-DD')
//   return (<> {tweakeddated} </>)
// }

// const ConvertDatesInArray = (input) => {
//   const result = []
//   input.map((item, i) => {

//     for (let [key, value] of Object.entries(item)) {
//       if (typeof names[key] === 'undefined') { }
//       else if (names[key][1] === 'Date') {
//         const converteddate = new Date(value)
//         item[key] = moment(converteddate).format('YYYY-MM-DD')
//         result.push(item)
//         //console.log(input[key]);
//       }
//     }
//     return result
//   })
//   return result
// }

function isEmptyNullUndefined(value) {
  // console.log('In isEmptyNullUndefined and typeof value is: ', typeof value, '.  Also, the value is: ', value)
  return value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
}

const RGB_Linear_Shade = (p, c) => {
  let color = makeRGBString(hexToRgb(c))
  return RGB_Linear_Shade2(p, color)
}


function makeRGBString(hexToRgb) {
  return `rgb(${hexToRgb.r},${hexToRgb.g},${hexToRgb.b})`
}

const RGB_Linear_Shade2 = (percentage, color) => {
  var i = parseInt,
    r = Math.round, [a, b, c, d] = color.split(","),
    P = percentage < 0,
    t = P ? 0 : 255 * percentage,
    NewPercentage = P ? 1 + percentage : 1 - percentage;
  return "rgb" + (d ? "a(" : "(") + r(i(a[3] === "a" ? a.slice(5) : a.slice(4)) * NewPercentage + t) + "," + r(i(b) * NewPercentage + t) + "," + r(i(c) * NewPercentage + t) + (d ? "," + d : ")");
}

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function RgbToHex(rgb) {
  var a = rgb.split("(")[1].split(")")[0]
  a = a.split(",")
  var b = a.map(function (x) {             //For each array element
    x = parseInt(x).toString(16);      //Convert to a base16 string
    return (x.length === 1) ? "0" + x : x;  //Add zero if we get only one character
  })
  b = "#" + b.join("")
  return b
}


function percentageTwoColors(color1, color2, percent) {
  return RgbToHex(pickHex(hexToRgb(color1), hexToRgb(color2), Math.min(1, percent)))
}

function pickHex(color1, color2, weight) {
  var w1 = weight;
  // console.log('Weight is: ', w1)
  var w2 = 1 - w1;
  // console.log('This is color1: ', color1) 
  var rgb = [Math.round(color1.r * w1 + color2.r * w2),
  Math.round(color1.g * w1 + color2.g * w2),
  Math.round(color1.b * w1 + color2.b * w2)];
  let returnValue = `rgb(${rgb.join(',')})`
  // console.log('This is retrunValue: ', returnValue)
  return returnValue
}


function drawArrow(from, to, lineWidth, arrowheadWidth, arrowheadLength) {
  // console.log('Here!')
  var dx = to.x - from.x;
  var dy = to.y - from.y;
  // Calculate the length of the line
  var len = Math.sqrt(dx * dx + dy * dy);
  // console.log('len: ', len)
  // console.log('arrowheadLength: ', arrowheadLength)
  if (len < arrowheadLength) return;

  // The difference between the line width and the arrow width
  var dW = arrowheadWidth - lineWidth;
  // console.log('dw: ', dW)
  // The angle of the line
  var angle = Math.atan2(dy, dx) * 180 / Math.PI;
  // console.log('angle: ', angle)
  // Generate a path describing the arrow. For simplicity we define it as a
  // horizontal line of the right length, and starting at 0,0. Then we rotate
  // and move it into place with a transform attribute.

  var d = ['M', 0, -lineWidth / 2,
    'h', len - arrowheadLength,
    'v', -dW / 2,
    'L', len, 0,
    'L', len - arrowheadLength, arrowheadWidth / 2,
    'v', -dW / 2,
    'H', 0,
    'Z'];
  //  console.log('This is d: ', d)
  var path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("d", d.join(' '));
  path.setAttribute("transform", "translate(" + from.x + "," + from.y + ") rotate(" + angle + ")");
  path.setAttribute("class", "arrow-line");
  return path
}

const CSVIcon = ({
  type = 'green',
  style = {},
  fill = '#fff',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 32 32',
}) => {
  let colorScheme = type === 'green' ? ['#89C429', '#709E21', '#E8E6E6', '#B3DA73'] : ['#ff0000', '#cf3034', '#E8E6E6', '#fdaab3']
  // let colorScheme =  ['#89C429', '#709E21', '#E8E6E6', '#B3DA73' ] 
  return <svg
    width={width}
    style={style}
    height={height}
    // viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    space="preserve"
  >
    <path style={{ fill: colorScheme[0] }} d="M511.344,274.266C511.77,268.231,512,262.143,512,256C512,114.615,397.385,0,256,0S0,114.615,0,256  c0,117.769,79.53,216.949,187.809,246.801L511.344,274.266z" />
    <path style={{ fill: colorScheme[1] }} d="M511.344,274.266L314.991,77.913L119.096,434.087l68.714,68.714C209.522,508.787,232.385,512,256,512  C391.243,512,501.976,407.125,511.344,274.266z" />
    <polygon style={{ fill: ' #FFFFFF' }} points="278.328,333.913 255.711,77.913 119.096,77.913 119.096,311.652 " />
    <polygon style={{ fill: colorScheme[2] }} points="392.904,311.652 392.904,155.826 337.252,133.565 314.991,77.913 255.711,77.913   256.067,333.913 " />
    <polygon style={{ fill: '#FFFFFF' }} points="314.991,155.826 314.991,77.913 392.904,155.826 " />
    <rect x="119.096" y="311.652" style={{ fill: colorScheme[3] }} width="273.809" height="122.435" />
    <g>
      <path style={{ fill: '#FFFFFF' }} d="M227.424,354.863l-7.796,9.233c-3.48-4.238-8.626-6.887-13.32-6.887   c-8.4,0-14.757,6.659-14.757,15.363c0,8.854,6.357,15.589,14.757,15.589c4.466,0,9.612-2.422,13.32-6.206l7.871,8.324   c-5.677,5.827-14.076,9.687-21.871,9.687c-15.969,0-27.849-11.73-27.849-27.245c0-15.287,12.184-26.79,28.305-26.79   C213.954,345.933,222.126,349.413,227.424,354.863z" />
      <path style={{ fill: '#FFFFFF' }} d="M276.772,351.684l-5.071,10.519c-5.6-3.255-12.638-5.524-16.952-5.524   c-3.482,0-5.827,1.287-5.827,3.86c0,9.157,28.001,3.936,28.001,23.082c0,10.595-9.384,16.196-21.19,16.196   c-8.854,0-17.936-3.33-24.218-8.476l5.221-10.368c5.449,4.768,13.623,7.947,19.148,7.947c4.237,0,6.886-1.589,6.886-4.617   c0-9.384-28.001-3.783-28.001-22.552c0-9.763,8.4-15.969,21.116-15.969C263.529,345.781,271.323,348.128,276.772,351.684z" />
      <path style={{ fill: '#FFFFFF' }} d="M301.372,399.362l-20.585-52.975h14.379l13.547,38.975l13.547-38.975h13.848l-20.811,52.975H301.372   z" />
    </g>
  </svg>
}

const CSVIcon2 = ({
  type = 'green',
  style = {},
  fill = '#fff',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 60 83',
}) => {
  let colorScheme = type === 'green' ? ['#89C429', '#709E21', '#E8E6E6', '#B3DA73'] : ['#ff0000', '#cf3034', '#E8E6E6', '#fdaab3']
  // let colorScheme =  ['#89C429', '#709E21', '#E8E6E6', '#B3DA73' ] 
  return <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    // viewBox="0 0 512 512"
    space="preserve"
  >

    <g stroke="black" >
      <path
        fill="#E9E9E0"
        d="M40.09.352H1.925C.861.352 0 1.213 0 2.885v69.797c0 .454.861 1.315 1.924 1.315h52.701c1.063 0 1.924-.861 1.924-1.315V17.419c0-.915-.122-1.21-.338-1.427L40.91.69a1.162 1.162 0 00-.818-.338h0z"
      ></path>
      <path
        fill="#D9D7CA"
        d="M40.768010810017586 0.5502794981002808L40.768010810017586 16.13286018371582 56.350591376423836 16.13286018371582z"
      ></path>
    </g>
    <path
      fill="#C8BDB8"
      d="M14.875 14.904V2.013H.99V72.916h38.18V14.904H14.875zM3.305 8.458h9.256v6.446H3.305V8.458zm0 12.892h9.256v6.446H3.305V21.35zm0 12.892h9.256v6.445H3.305v-6.445zm0 12.891h9.256v6.446H3.305v-6.446zm9.256 19.338H3.305v-6.446h9.256v6.446zm24.296 0H14.875v-6.446h21.982v6.446zm0-12.892H14.875v-6.446h21.982v6.446zm0-12.892H14.875v-6.445h21.982v6.445zM14.875 27.796V21.35h21.982v6.446H14.875z"
    ></path>
    <g stroke="null">
      <path
        fill={colorScheme[0]}
        stroke="null"
        d="M58.036 64.606H6.27c-1.043 0-1.89-1.568-1.89-3.503V23.895h55.545v37.208c0 1.935-.846 3.503-1.89 3.503h0z"
      ></path>
    </g>
    <g fill="#FFF" stroke="null">
      <path d="M21.713 49.908a7.585 7.585 0 01-2.585 1.668c-.964.372-2.001.555-3.113.555-1.225 0-2.35-.221-3.378-.667s-1.928-1.102-2.697-1.974c-.769-.873-1.373-1.965-1.808-3.28-.435-1.317-.653-2.838-.653-4.56s.218-3.24.653-4.545c.435-1.308 1.037-2.396 1.808-3.267a7.688 7.688 0 012.71-1.988c1.038-.456 2.16-.682 3.365-.682a8.61 8.61 0 013.113.555 7.596 7.596 0 012.585 1.668l-2.308 2.058c-.464-.539-.979-.927-1.544-1.167a4.473 4.473 0 00-1.765-.362c-.685 0-1.34.128-1.96.388-.623.26-1.178.7-1.668 1.32-.492.622-.877 1.422-1.153 2.406s-.427 2.186-.446 3.614c.019 1.39.163 2.576.432 3.559.268.982.638 1.783 1.112 2.405s1.01 1.074 1.613 1.363c.602.289 1.236.431 1.905.431s1.294-.122 1.877-.362 1.117-.63 1.599-1.167l2.306 2.032h0z"></path>
      <path d="M38.09 46.376c0 .74-.153 1.46-.46 2.156s-.736 1.307-1.293 1.834-1.243.95-2.058 1.265c-.816.315-1.743.472-2.78.472-.445 0-.903-.025-1.377-.07s-.95-.125-1.432-.235a7.496 7.496 0 01-1.376-.46 4.415 4.415 0 01-1.127-.71l.584-2.391c.258.148.587.293.986.43.399.14.81.27 1.236.39.425.121.853.217 1.28.292.425.073.823.112 1.195.112 1.131 0 1.997-.264 2.6-.793.601-.529.902-1.312.902-2.349 0-.63-.213-1.167-.638-1.613-.427-.445-.96-.848-1.599-1.21s-1.33-.722-2.072-1.084a12.554 12.554 0 01-2.084-1.279 6.673 6.673 0 01-1.613-1.737c-.427-.667-.639-1.5-.639-2.5 0-.908.167-1.715.5-2.419s.783-1.304 1.349-1.794c.565-.49 1.224-.866 1.975-1.126s1.543-.389 2.377-.389c.852 0 1.714.08 2.585.236.87.157 1.574.413 2.113.765-.112.24-.242.504-.389.793-.148.289-.289.555-.417.805-.13.25-.242.46-.333.627a2.57 2.57 0 01-.167.278c-.112-.055-.236-.128-.376-.221s-.34-.185-.598-.279c-.26-.094-.602-.157-1.03-.195-.426-.039-.973-.029-1.64.028-.372.039-.722.143-1.058.32s-.63.392-.89.652-.464.551-.613.877a2.238 2.238 0 00-.221.931c0 .74.211 1.338.638 1.794.425.455.954.852 1.584 1.196.63.343 1.316.677 2.059 1 .74.324 1.431.72 2.072 1.182s1.171 1.043 1.598 1.737c.433.695.647 1.588.647 2.682h0z"></path>
      <path d="M47.042 52.105l-6.367-20.603h3.809l4.755 17.682 5.033-17.682h3.78L51.38 52.105h-4.338 0z"></path>
    </g>

    <path d="m28.48127,79.28108l-5.99902,-6.03201c-0.44769,-0.45061 -1.17348,-0.45061 -1.62112,0c-0.44763,0.45055 -0.44763,1.18116 0,1.63183l6.75201,6.78909c0.23853,0.24012 0.55583,0.34818 0.86812,0.33238c0.31235,0.0158 0.62962,-0.09226 0.86818,-0.33238l6.75198,-6.78909c0.44769,-0.45064 0.44769,-1.18119 0,-1.63183s-1.17346,-0.45061 -1.62103,0l-5.99914,6.03201l0.00001,0z" id="svg_11" fill='red' />
    <path d="m22.43232,71.72862l11.91422,0l0,-2.25798l0.24039,3.95147l-0.24039,3.95147l0,-2.25798l-11.91422,0l0,-3.38697l0,-0.00001z" className="arrow-line" id="svg_12" fill='red' transform="rotate(90 28.50962257385253,73.42210388183595) " />

  </svg>
}

function compareTwoSimpleArrays(arr1, arr2) {
  if(arr1.length !== arr2.length){return false}
  return arr1.every((e, i) => e === arr2[i]);
}

// export { Loading, recreateTables, StringToDate, ConvertDatesInArray, isEmptyNullUndefined, RGB_Linear_Shade, drawArrow, CSVIcon, CSVIcon2, percentageTwoColors, compareTwoSimpleArrays }
export { Loading, recreateTables,  isEmptyNullUndefined, RGB_Linear_Shade, drawArrow, CSVIcon, CSVIcon2, percentageTwoColors, compareTwoSimpleArrays }





