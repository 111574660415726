//This reducer is to keep track of clients, matters and attorneys

export default (state =
  {
    clients: [],
    clientsarray: [],
    employeesarray: [],
    mattersarray: [],
    transactiontypesarray: [],
    transactiontypesandsubsarray: [],
    assetclassarray: [],
    lawfirmsarray: [],
    bills_total: 0,
  },
  action) => {
  switch (action.type) {

    case 'GET_CLIENTS':
      //console.log('Updating clients reducer list with this payload: ', action.payload)
      return {
        ...state,
        clients: action.payload
      }

    case 'GET_CLIENTS_ARRAY':
      //console.log('Updating clientsarray reducer list with this payload: ', action.payload)
      return {
        ...state,
        clientsarray: action.payload
      }

    case 'GET_EMPLOYEES_ARRAY':
      //console.log('Updating employees reducer list with this payload: ', action.payload)
      return {
        ...state,
        employeesarray: action.payload
      }

    case 'GET_MATTERS_ARRAY':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        mattersarray: action.payload
      }

    case 'GET_TRANSACTION_TYPES_ARRAY':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        transactiontypesarray: action.payload
      }

    case 'GET_TRANSACTION_TYPES_AND_SUBS_ARRAY':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        transactiontypesandsubsarray: action.payload
      }

    case 'GET_ASSET_CLASS_ARRAY':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        assetclassarray: action.payload
      }

    case 'GET_LAW_FIRMS_ARRAY':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        lawfirmsarray: action.payload
      }

    case 'GET_COUNTERPARTIES_TOTAL':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        counterparties_total: action.payload
      }

    case 'GET_PERSONS_TOTAL':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        persons_total: action.payload
      }

    case 'GET_INVOICE_TOTAL':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        invoice_total: action.payload
      }

    case 'GET_PAYMENTS_TOTAL':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        payments_total: action.payload
      }

    case 'GET_PRECEDENTS_TOTAL':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        precedents_total: action.payload
      }

    case 'GET_LOCATIONS_TOTAL':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        locations_total: action.payload
      }

    case 'GET_BILLS_TOTAL':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return {
        ...state,
        bills_total: action.payload
      }

    case 'GET_ALL':
      //console.log('Updating matters reducer list with this payload: ', action.payload)
      return action.payload
      

    default:
      return state
  }
} 