
import React, { Component } from 'react'
import { szenokPostAPI } from '../helper/szenokPostAPI'
import Papa from 'papaparse';
// import { szenokBlobAPI, downloadBlob } from '../helper/szenokBlobAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

// import JSZip from 'jszip'

// import Profile from '../layout/Profile'
import { Icon, Button, Dropdown, Grid } from 'semantic-ui-react';
import { isEmptyNullUndefined } from '../helper/Misc';

import { reduxupdate } from '../helper/ReduxHelp'

import ReactTableMaker from '../helper/ReactTableMaker'

class UploadCSV extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selectedTable: null,
      DBTablesDropDownArray: null,
      columnCheck: false
    };

    // this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.getTableSizes()

  }

  getTableSizes = async () => {
    // console.log('In getTableSizes...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getDatabase', { fetchstr: 'tablesSizes' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getTableSizes: ', fetchresponse.payload)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      DBTables: fetchresponse.payload.map(table => table.TableName),
      DBTablesDropDownArray: this.TableDropDownArray(fetchresponse.payload.map(table => table.TableName))
    })
  }

  getFields = async () => {
    // console.log('In getTableSizes...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getDatabase', { fetchstr: 'tableFields', table: this.state.selectedTable },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getTableSizes: ', fetchresponse.payload)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      fields: fetchresponse.payload,
      fieldsColumns: fetchresponse.payload.map(item => item.COLUMN_NAME).slice(1, fetchresponse.payload.length)
    }, () => {
      let columns = this.state.fieldsColumns.map(item => {
        return {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>{item}</span>,
          accessor: item,
          show: true,
          sortType: 'alphanumeric',
          Filter: ''
        }
      })
      this.setState({ TableColumns: columns })
      // console.log('TableColumns: ', columns)
    }
    )
    setTimeout(() => this.testColumns(), 200)
  }

  parseFile = (file) => {
    Papa.parse(file, {
      download: true,
      header: true,
      dynamicTyping: true,
      error: (err) => console.log('Error: ', err),
      complete: (results) => {
        let jsonString = JSON.stringify(results.data)
        let jsonStringFormatted = jsonString.replace(/{/g, "\n\t{").replace(']', '\n]')
        let jsonStringParsed = JSON.parse(jsonStringFormatted)
        let CSVArray = jsonStringParsed.map(item => Object.values(item)).slice(0, jsonStringParsed.length - 1)
        // console.log('This is CSVArray: ', CSVArray)
        // console.log('This is jsonStringParsed: ', jsonStringParsed)
        if (jsonStringParsed.length > 0) {
          this.setState({
            JSONData: jsonStringParsed,
            CSVArray: CSVArray
          }, () => {
            this.setState({ JSONDataColumns: Object.keys(this.state.JSONData[0]) }, () => {
              let columns = this.state.JSONDataColumns.map(item => {
                return {
                  Header: ({ Header }) => <span style={{ 'float': 'left' }}>{item}</span>,
                  accessor: item,
                  show: true,
                  sortType: 'alphanumeric',
                  Filter: ''
                }
              })
              this.setState({ JSONDataColumns2: columns })
              // console.log('Columns: ', columns)
            })
          }
          )
        }
        else this.setState({ JSONData: [], JSONDataColumns: [], JSONDataColumns2: [] })
      }
    })
    setTimeout(() => this.testColumns(), 200)
  }

  TableDropDownArray = (tables) => {
    var array = tables.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `EID${result}`
      obj.text = result
      obj.value = result
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }

  testColumns = () => {
    let check = this.arraysEqual(this.state.fieldsColumns, this.state.JSONDataColumns)
    // console.log('This is check: ', check)
    this.setState({ columnCheck: check })
  }

  arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  render() {

    let setFile = (file) => this.setState({ file: file }, () => { this.parseFile(this.state.file) })

    return (
      <div>
        <h1> <Icon name='cloud upload' size='large' circular />Upload CSV Into Table</h1>




        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <h3>Clicking Button Will Upload CSV Data into Table</h3>
              <p />
              <Button
                color='green'
                disabled={!this.state.columnCheck}
                onClick={async () => {
                  // alert('Eventually, this will upload CSV info into the appropriate table...')

                  await szenokPostAPI('uploadCSV', { actionType: 'uploadToTable', table: this.state.selectedTable, csvarray: this.state.CSVArray },
                    this.props.login.currentToken)

                  setTimeout(() => {
                    reduxupdate('clients')
                    reduxupdate('clientsarray')
                    reduxupdate('employees')
                  }, 400)

                }}>Upload CSV to Database</Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>

              <h3>Pick a Table (into which to insert CSV info)</h3>
              <Dropdown
                placeholder='Select Table'
                name="Table"
                width={6}
                required
                search
                label='Table'
                selection
                value={this.state.selectedTable}
                // onBlur={() => { setFieldTouched('ClientMatter', true) }}
                options={this.state.DBTablesDropDownArray}
                // value={this.state.success === true ? null : values.TType}
                loading={this.state.success}
                onChange={async (e, { name, value }) => {
                  // setFieldValue(name, value)
                  // setFieldValue('InvoiceNumber', '')
                  this.setState({
                    selectedTable: value,
                  }, () => {
                    this.getFields()
                    // this.getInvoices(value.split(',')[0])
                    // setFieldValue('PartnerPick', this.state.SelectedEmployeeID)
                  }
                  )
                }}
              />
            </Grid.Column>

            <Grid.Column width={8}>
              <h3>Drag a CSV File Here</h3>

              <DragDropCSV setFile={setFile} file={this.state.file} />

            </Grid.Column>
          </Grid.Row>
          {!isEmptyNullUndefined(this.state.TableColumns) &&
            <Grid.Row>
              <Grid.Column width={2}><h5>Columns from Table:</h5></Grid.Column>
              <Grid.Column width={14}>
                <ReactTableMaker passedData={[]} passedColumns={this.state.TableColumns}
                  // handleClick={(rowID, cellID, rowValues) => {console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)}}
                  color={this.state.columnCheck ? 'green' : 'blue'}
                  includeSearchBar={false}
                  includePaginationFooter={false}
                />
              </Grid.Column>
            </Grid.Row>
          }
          {!isEmptyNullUndefined(this.state.JSONDataColumns2) &&
            <Grid.Row>
              <Grid.Column width={2}><h5>Columns from CSV:</h5></Grid.Column>
              <Grid.Column width={14}>
                <ReactTableMaker passedData={this.state.JSONData} passedColumns={this.state.JSONDataColumns2}
                  // handleClick={(rowID, cellID, rowValues) => {console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)}}
                  color={this.state.columnCheck ? 'green' : 'red'}
                  includeSearchBar={false}
                // includePaginationFooter={false}
                />
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>








      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default connect(mapStateToProps, mapDispatchToProps)(UploadCSV)


let urls = new WeakMap()

let blobUrl = blob => {
  if (urls.has(blob)) {
    return urls.get(blob)
  } else {
    let url = URL.createObjectURL(blob)
    urls.set(blob, url)
    return url
  }
}



class DragDropCSV extends Component {
  state = { counter: 0, size: '' }

  refresh = () => {
    this.setState(({ counter }) => ({ counter: counter + 1 }))
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, 100)
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.file !== this.props.file) {
  //     this.setState({fileSize: this.props.file.size})
  //   }
  // }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  onDrag = event => {
    event.preventDefault()
  }

  onDrop = event => {
    event.preventDefault()
    let file = event.dataTransfer.files[0]
    // this.setState({ file })
    this.props.setFile(file)
    // var dimensions = sizeOf(file)
    // console.log(dimensions)
    this.setState({ fileSize: file.size })
  }

  render() {
    let { file } = this.props
    let url = file && blobUrl(file)

    return (
      <div onDragOver={this.onDrag} onDrop={this.onDrop} style={DropArea}>
        {isEmptyNullUndefined(this.state.fileSize) && <p>Drop a CSV File!</p>}
        <div style={{ backgroundColor: '#ffffcc' }}>
          <img src={!isEmptyNullUndefined(this.state.fileSize) ? file.type === 'application/vnd.ms-excel' ? window.location.origin + '/images/csv.png' : url : null} alt='' style={{ maxWidth: 100, maxHeight: 100 }} />
        </div>
      </div>
    )
  }
}


DragDropCSV.defaultProps = {
  file: null,
}

const DropArea = {
  background: '#efefef',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'calc(30vw - 80px)',
  height: 'calc(30vh - 80px)',
  border: 'solid 40px transparent',
  transition: 'all 250ms ease-in-out 0s',
  position: 'relative',
}