import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'
import BillModal from '../helper/BillModal'

import { isEmptyNullUndefined } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';



class Bills extends Component {

  state = {
    Bills: [],
    selectedTType: null,
    modalOpen: false,
    billModalOpen: false,
    Row: {},
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getBills()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getBills(), 200)
    }
  }

  getBills = async () => {

    this.props.fetchResetState('all')
    // console.log('Fetching for this client: ', client_id)
    let fetchresponse = await szenokPostAPI('billInfo', { fetchstr: 'getAllBills' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getAllBills: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    this.setState({
      Bills: fetchresponse.payload
    })
  }

  handleDeleteBill = async (bill_id) => {
    // console.log('Running handleDeletePayment...')
    // console.log(`This is payment_id: ${payment_id}`)
    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deleteBill', {
        billID: bill_id
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deletePayment: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Rest of stuff
        this.getBills()
        reduxupdate('bills')
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }

  setBillModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ billModalOpen: truefalse })
    else this.setState({ billModalOpen: !this.state.billModalOpen })
  }

  setRow = (value, callback = () => { }) => {
    if (!isEmptyNullUndefined(value)) this.setState({ Row: value }, () => callback(this.state.Row))
    else this.setState({ Row: {} }, () => callback(this.state.Row))
  }

  clearBill = () => {
    this.setBillModalOpen(false)
    this.setRow({})
  }

  render() {

    return (
      <div>
        <h1> <Icon name='inbox' size='large' circular /> Bills (Third Party)</h1>

        <ReactTableMaker passedData={this.state.Bills} passedColumns={BillsColumns(this.handleDeletePayment, this.props.history, this.setModalOpen, this.setBillModalOpen, this.setRow, !!this.props.login.currentObserver)}
          handleClick={(rowID, cellID, rowValues) => {
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
            this.setState({ selectedTType: rowID })
            // this.getPaymentsForInvoice(rowID)
          }}
          includeSearchBar={false}
        />


        <BillModal
          closeModal={() => {
            this.setBillModalOpen(false)
            // this.setRow()
          }}
          confirmModal={() => {
            this.setBillModalOpen(false)

            // this.gatherCPartyData()

          }}
          isOpen={this.state.billModalOpen}
          // deleteSubject='Bill'
          lawfirm={this.state.Row.law_firm}
          lawfirm_id={this.state.Row.law_firm_id}
          counterparty={this.state.Row.counterparty}
          counterparty_id={this.state.Row.counterparty_id}
          counterparty_matter_id={this.state.Row.counterparty_matter_id}
          getBills={this.getBills}
          update={true}
          billID={this.state.Row.bill_id}
          clearBill={this.clearBill}
        />



        {/* This is the "are you sure" modal for deleting a row */}
        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow({})
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              this.handleDeleteBill(this.state.Row.bill_id)
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Bill'
            deleteObject={'bill number: ' + this.state.Row.bill_id}
          />}

        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bills))

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
function minusFontColor(e) { e.target.style.color = "" }

const BillsColumns = (handleDeletePayment, history, setModalOpen, setBillModalOpen, setRow, observer) => [
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client</span>,
    accessor: 'client_name',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell, row }) =>
      <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        style={{ 'float': 'left', cursor: 'pointer' }}
        onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
      >
        {cell.value}
      </span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
    accessor: 'matter_name',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell, row }) =>
      <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        style={{ 'float': 'left', cursor: 'pointer' }}
        onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
      >
        {cell.value}
      </span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Date</span>,
    accessor: 'matter_origination_date',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Amount</span>,
    accessor: 'matter_deal_amount',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Deal Amount</span>,
    accessor: 'bill_deal_amount',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Type</span>,
    accessor: 'bill_type',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Bill Amount</span>,
    accessor: 'bill_amount',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'color': 'green', 'float': 'left' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Bill Description</span>,
    accessor: 'bill_description',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Asset Class</span>,
    accessor: 'asset_class',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Transaction Type</span>,
    accessor: (d) => `${d.transaction_type} - ${d.sub_transaction_type}`,
    id: 'ttype',
    show: true,
    sortType: 'alphanumeric',
    // Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
  },

  {
    // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
    accessor: 'payment_id',
    // show: true,
    // sortType: 'alphanumeric',
    Filter: '',
    Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
      <Link to={''} onClick={(e) => {
        e.preventDefault()
        // setModalOpen(true)
        setRow(row.original, (value) => {
          // console.log('yo', value);
          setBillModalOpen()
        })
        // handleDeletePayment(row.values.payment_id)
      }}><Icon name='edit' size='small' bordered />Edit</Link></span>
  },
  {
    // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
    accessor: 'payment_id2',
    // show: true,
    // sortType: 'alphanumeric',
    Filter: '',
    Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
      <Link to={''} onClick={(e) => {
        e.preventDefault()
        setModalOpen(true)
        setRow(row.original)
        // handleDeletePayment(row.values.payment_id)
      }}>
        <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
  },

]

