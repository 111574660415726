import React from 'react'
import { List } from 'semantic-ui-react';
import _ from 'lodash'
import { isEmptyNullUndefined } from '../helper/Misc'


//This takes in an array and a search term and returns: (i) the length of the search term, (ii) a sorted array of matched items, and (iii) if there is an exact match, that match.
export const filterCENameInput = async (filterarray, search) => {
  //console.log('This is filterarray: ', filterarray, 'and this is search: ', search)
  if (typeof filterarray.length === 'undefined' || search.length === 0 || search === ', ') {
    return { searchlength: search.length, updatedListGeneralSorted: [], updatedListSpecific: [] }
  }
  else {
    const regexGeneral = new RegExp(_.escapeRegExp(search).toLowerCase(), 'ig')
    const regexSpecific = new RegExp('^' + _.escapeRegExp(search).toLowerCase() + '$', 'ig')
    const updatedListGeneral = filterarray.filter(client => client[1].toLowerCase().match(regexGeneral) || client[2].toLowerCase().match(regexGeneral) || client[3].toLowerCase().match(regexGeneral))
    // console.log('This is updatedListGeneral: ', updatedListGeneral)
    // const updatedListGeneralSorted = updatedListGeneral.sort(clientSortCustom)
    const updatedListSpecific = filterarray.filter(client => client[1].toLowerCase().match(regexSpecific) || (client[3].toLowerCase() + ', ' + client[2].toLowerCase()).match(regexSpecific))
    // console.log('This is updatedListSpecific: ', updatedListSpecific)
    return { searchlength: search.length, updatedListGeneralSorted: updatedListGeneral, updatedListSpecific: updatedListSpecific }
  }
}

//This takes in an array and a search term *array* and returns: (i) the length of the search term, (ii) a sorted array of matched items, and (iii) if there is an exact match, that match.
export const filterCFNameInput = async (filterarray, search) => {
  // console.log('This is filterarray: ', filterarray, 'and this is search: ', search)
  if (typeof filterarray.length === 'undefined' || search[0].length === 0) {
    return { searchlength: search.length, updatedListGeneralSorted: [], updatedListSpecific: [] }
  }
  else {
    const regexGeneral = new RegExp(_.escapeRegExp(search[0]).toLowerCase(), 'ig')
    // const regexSpecific = new RegExp('^' + search + '$', 'ig')
    const updatedListGeneral = filterarray.filter(client => client[1].toLowerCase().match(regexGeneral) || client[2].toLowerCase().match(regexGeneral))
    // console.log('This is updatedListGeneral: ', updatedListGeneral)
    const updatedListGeneralSorted = updatedListGeneral//.sort(clientSortCustom)
    const updatedListSpecific = filterarray.filter(client => client[2].toLowerCase().match('^' + search[0].toLowerCase() + '$', 'ig') && client[3].toLowerCase().match('^' + search[1].toLowerCase() + '$', 'ig'))
    return { searchlength: search.length, updatedListGeneralSorted: updatedListGeneralSorted, updatedListSpecific: updatedListSpecific }
  }
}

//This takes in an array and a search term *array* and returns: (i) the length of the search term, (ii) a sorted array of matched items, and (iii) if there is an exact match, that match.
export const filterCLNameInput = async (filterarray, search) => {
  // console.log('This is filterarray: ', filterarray, 'and this is search: ', search)
  if (typeof filterarray.length === 'undefined' || search[1].length === 0) {
    return { searchlength: search.length, updatedListGeneralSorted: [], updatedListSpecific: [] }
  }
  else {
    const regexGeneral = new RegExp(_.escapeRegExp(search[1]).toLowerCase(), 'ig')
    const updatedListGeneral = filterarray.filter(client => client[1].toLowerCase().match(regexGeneral) || client[3].toLowerCase().match(regexGeneral))
    // console.log('This is updatedListGeneral: ', updatedListGeneral)
    const updatedListGeneralSorted = updatedListGeneral//.sort(clientSortCustom)
    const updatedListSpecific = filterarray.filter(client => client[2].toLowerCase().match('^' + search[0].toLowerCase() + '$', 'ig') && client[3].toLowerCase().match('^' + search[1].toLowerCase() + '$', 'ig'))
    return { searchlength: search.length, updatedListGeneralSorted: updatedListGeneralSorted, updatedListSpecific: updatedListSpecific }
  }
}

//This takes in an array and a search term and returns: (i) the length of the search term, (ii) a sorted array of matched items, and (iii) if there is an exact match, that match.
export const filterPNameInput = async (filterarray, search) => {
  //console.log('This is filterarray: ', filterarray, 'and this is search: ', search)
  if (typeof filterarray.length === 'undefined' || search.length === 0 || search === ', ') {
    return { searchlength: search.length, updatedListGeneralSorted: [], updatedListSpecific: [] }
  }
  else {
    const regexGeneral = new RegExp(_.escapeRegExp(search).toLowerCase(), 'ig')
    const regexSpecific = new RegExp('^' + _.escapeRegExp(search).toLowerCase() + '$', 'ig')
    const updatedListGeneral = filterarray.filter(client => client[1].toLowerCase().match(regexGeneral) || client[2].toLowerCase().match(regexGeneral))
    // console.log('This is updatedListGeneral: ', updatedListGeneral)
    // const updatedListGeneralSorted = updatedListGeneral.sort(clientSortCustom)
    const updatedListSpecific = filterarray.filter(client => client[1].toLowerCase().match(regexSpecific) || (client[3].toLowerCase() + ', ' + client[2].toLowerCase()).match(regexSpecific))
    return { searchlength: search.length, updatedListGeneralSorted: updatedListGeneral, updatedListSpecific: updatedListSpecific }
  }
}

//This takes in an array and a search term and returns: (i) the length of the search term, (ii) a sorted array of matched items, and (iii) if there is an exact match, that match.
export const filterSimpleInput = async (filterarray, search) => {
  // console.log('This is filterarray: ', filterarray, 'and this is search: ', search)
  if (typeof filterarray.length === 'undefined' || search.length === 0 || search === ', ') {
    return { searchlength: search.length, updatedListGeneralSorted: [], updatedListSpecific: [] }
  }
  else {
    const regexGeneral = new RegExp(_.escapeRegExp(search).toLowerCase(), 'ig')
    const regexSpecific = new RegExp('^' + _.escapeRegExp(search).toLowerCase() + '$', 'ig')
    const updatedListGeneral = filterarray.filter(item => item[1].toLowerCase().match(regexGeneral))
    // console.log('This is updatedListGeneral: ', updatedListGeneral)
    // const updatedListGeneralSorted = updatedListGeneral.sort(clientSortCustom)
    const updatedListSpecific = filterarray.filter(item => item[1].toLowerCase().match(regexSpecific))
    // console.log('This is updatedListSpecific: ', updatedListSpecific)
    return { searchlength: search.length, updatedListGeneralSorted: updatedListGeneral, updatedListSpecific: updatedListSpecific }
  }
}

//This takes in an array and a search term and returns: (i) the length of the search term, (ii) a sorted array of matched items, and (iii) if there is an exact match, that match.
export const filterSimpleInputOn2 = async (filterarray, search) => {
  // console.log('This is filterarray: ', filterarray, 'and this is search: ', search)
  if (typeof filterarray.length === 'undefined' || search.length === 0 || search === ', ') {
    return { searchlength: search.length, updatedListGeneralSorted: [], updatedListSpecific: [] }
  }
  else {
    const regexGeneral = new RegExp(_.escapeRegExp(search).toLowerCase(), 'ig')
    const regexSpecific = new RegExp('^' + _.escapeRegExp(search).toLowerCase() + '$', 'ig')
    const updatedListGeneral = filterarray.filter(item => item[2].toLowerCase().match(regexGeneral))
    // console.log('This is updatedListGeneral: ', updatedListGeneral)
    // const updatedListGeneralSorted = updatedListGeneral.sort(clientSortCustom)
    const updatedListSpecific = filterarray.filter(item => item[2].toLowerCase().match(regexSpecific))
    return { searchlength: search.length, updatedListGeneralSorted: updatedListGeneral, updatedListSpecific: updatedListSpecific }
  }
}

//This takes in an array and a search term and returns: (i) the length of the search term, (ii) a sorted array of matched items, and (iii) if there is an exact match, that match.
export const filterSimpleInputOn3 = async (filterarray, search) => {
  // console.log('This is filterarray: ', filterarray, 'and this is search: ', search)
  if (typeof filterarray.length === 'undefined' || search.length === 0 || search === ', ') {
    return { searchlength: search.length, updatedListGeneralSorted: [], updatedListSpecific: [] }
  }
  else {
    const regexGeneral = new RegExp(_.escapeRegExp(search).toLowerCase(), 'ig')
    const regexSpecific = new RegExp('^' + _.escapeRegExp(search).toLowerCase() + '$', 'ig')
    const updatedListGeneral = filterarray.filter(item => item[3].toLowerCase().match(regexGeneral))
    // console.log('This is updatedListGeneral: ', updatedListGeneral)
    // const updatedListGeneralSorted = updatedListGeneral.sort(clientSortCustom)
    const updatedListSpecific = filterarray.filter(item => item[3].toLowerCase().match(regexSpecific))
    // console.log('This is updatedListSpecific: ', updatedListSpecific)
    return { searchlength: search.length, updatedListGeneralSorted: updatedListGeneral, updatedListSpecific: updatedListSpecific }
  }
}

//This is a custom sorting algorithm for a three-column array.
//I think I don't need this here anymore because I sort the array when I fetch it from the DB in routes-redux.  Leaving it here just in case I do need it later.
export const clientSortCustom = (a, b) => {
  var ln1 = a[3].toLowerCase();
  var ln2 = b[3].toLowerCase();

  var c1 = a[1].toLowerCase();
  var c2 = b[1].toLowerCase();

  if (c1.length === 0 && c2.length === 0) {
    if (ln1 < ln2) return -1;
    if (ln1 > ln2) return 1;
  }
  if (c1.length === 0 && c2.length > 0) {
    if (ln1 < c2) return -1
    if (ln1 > c2) return 1
  }
  if (c1.length > 0 && c2.length === 0) {
    if (c1 < ln2) return -1;
    if (c1 > ln2) return 1;
  }
  if (c1.length > 0 && c2.length > 0) {
    if (c1 < c2) return -1;
    if (c1 > c2) return 1;
  }
  return 0;
}

//This spits out a list of matching items (and if any is a perfect match, it appends the matchedspan constant)
export const ListMaker = (props, values, matched, type) => {
  const matchedspan = <span style={{ fontWeight: 'bold', color: 'blue' }}>(This name already used!)</span>
  // console.log('This is props: ', props)
  return (
    type === 'entity' ?
      <List>
        {props.map((item) =>
          <List.Item key={item[0]}>
            {item[1].length > 0 ?
              getHighlightedText(`CE${item[0]}`, item[1], values.CEName) :
              getHighlightedText(`CN${item[0]}`, item[3] + ', ' + item[2], values.CEName)}
            {matched.length > 0 && matched[0][1] === item[1] && matched[0][2] === item[2] && matched[0][3] === item[3] ? matchedspan : null}
          </List.Item>
        )}
      </List> :
      <List>
        {props.map((item) =>
          <List.Item key={item[0]}>
            {/* {console.log('Item length: ', item[1])} */}
            {item[3].length > 0 && getHighlightedText(`CL${item[0]}`, `${item[3]},`, values.CLName)}
            {item[1].length <= 0 && item[2].length > 0 && getHighlightedText(`CF${item[0]}`, item[2], values.CFName)}
            {item[1].length > 0 && getHighlightedText(`CE${item[0]}`, item[1], values.CFName)}
            {matched.length > 0 && matched[0][1] === item[1] && matched[0][2] === item[2] && matched[0][3] === item[3] ?
              matchedspan : null}
          </List.Item>
        )}
      </List>

  )
}


//This spits out a list of matching items (and if any is a perfect match, it appends the matchedspan constant)
export const ListMakerSimple = (props, values, matched, type) => {
  const matchedspan = <span style={{ fontWeight: 'bold', color: 'blue' }}>(This name already used!)</span>
  // console.log('This is props: ', props)
  return (
    <List>
      {props.map((item) =>
        <List.Item key={item[0]}>
          {getHighlightedText(`CE${item[0]}`, item[1], values[type])}
          {matched.length > 0 && matched[0][1] === item[1] ? matchedspan : null}
        </List.Item>
      )}
    </List>
  )
}

//This spits out a list of matching items (and if any is a perfect match, it appends the matchedspan constant)
//This version can take an action onClick
export const ListMakerSimpleClickable = (props, values, matched, type, action) => {
  const matchedspan = <span style={{ fontWeight: 'bold', color: 'blue' }}>(This name already used!)</span>
  // console.log('This is props: ', props)
  return (
    <List selection>
      {props.map((item) =>
        <List.Item key={item[0]} onClick={() => action(item[0])}>
          {getHighlightedText(`CE${item[0]}`, item[1], values[type])}
          {matched.length > 0 && matched[0][1] === item[1] ? matchedspan : null}
        </List.Item>
      )}
    </List>
  )
}

//This spits out a list of matching items (and if any is a perfect match, it appends the matchedspan constant)
//This version can take an action onClick
export const ListMakerSimpleClickableNameEmail = (props, values, matched, type, action) => {
  const matchedspan = <span style={{ fontWeight: 'bold', color: 'blue' }}>(This name already used!)</span>
  // console.log('This is props: ', props)
  return (
    <List selection>
      {props.map((item) =>
        <List.Item key={item[0]} onClick={() => action(item[0])}>
          {getHighlightedText(`CE${item[0]}`, item[1]+' -- '+item[2], values[type])}
          {matched.length > 0 && matched[0][2] === item[2] ? matchedspan : null}
        </List.Item>
      )}
    </List>
  )
}

//This takes in a chunk of text and bolds the 'highlight' string portion of that text
export const getHighlightedText = (key, text, highlight) => {
  // Split on highlight term and include term into parts, ignore case
  // console.log(`This is text ${text} and this is highlight ${highlight} and this is key ${key}`)
  if (isEmptyNullUndefined(highlight)) return text
  else {
    let parts = text.split(new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi'));
    return <span key={'span' + key}>
      {
        parts.map((part, i) =>
          <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
            {part}
          </span>)
      }
    </span>
  }
}

//This takes in a chunk of text and bolds the 'highlight' string portion of that text
export const getHighlightedTextRed = (key, text, highlight) => {
  // Split on highlight term and include term into parts, ignore case
  // console.log(`This is text ${text} and this is highlight ${highlight} and this is key ${key}`)
  if (isEmptyNullUndefined(highlight)) return text
  else {
    let parts = text.split(new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi'));
    return <span key={'span' + key}>
      {
        parts.map((part, i) =>
          <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { color: 'red' } : {}}>
            {part}
          </span>)
      }
    </span>
  }
}