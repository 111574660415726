import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon, Image } from 'semantic-ui-react';

import DeleteNotes from './Component-DeleteNotes'
import DeletePerson from './Component-DeletePerson'
import DeleteInvoice from './Component-DeleteInvoice'
import DeletePayment from './Component-DeletePayment'
import DeleteCounterparty from './Component-DeleteCounterparty'
import DeletePrecedent from './Component-DeletePrecedent'
import DeleteMatter from './Component-DeleteMatter'
// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'

import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'
import { isEmptyNullUndefined } from '../helper/Misc'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';
// import { counterpartiesTotalAction } from '../../actions/clientActions';

let initialDeleteClient = {
  LNotesExist: true,
  LawyersExist: true,
  TNotesExist: true,
  TycoonsExist: true,
  MNotesExist: true,
  PaymentsExist: true,
  InvoicesExist: true,
  CounterpartiesExist: true,
  PrecedentsExist: true,
  MattersExist: true,
}

class Clients extends Component {

  state = {
    FilteredSearchData: [],
    Data: [],
    Message: '',
    TokenReady: false,
    Loaded: false,
    selectedClient: null,
    Clients: [],
    DeleteClient: initialDeleteClient,
    modalOpen: false,
    Row: {},
  }

  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      this.getClients()
      this.props.fetchResetState('all')
    }
  }

  componentDidUpdate(prevProps) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    //This on
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true, FilteredSearchData: this.state.Data })
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getClients(), 200)
    }
  }

  getClients = async () => {
    // console.log('Launching getClients...')

    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'clients' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getClients(): ', fetchresponse)

    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ Clients: [], selectedClient: null })

    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Clients: fetchresponse.payload.map(item => {
        var arr = {}
        arr.client_id = item.client_id
        arr.client_name = item.client_name
        arr.origination_date = item.origination_date
        arr.employee_id = item.employee_id
        arr.employee_name = this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[2]
        arr.engagement_letter = item.engagement_letter
        return arr
      }
      )
    })
  }

  setStateClient = (client_id, callback) => {
    this.setState({ DeleteClient: initialDeleteClient })
    this.setState({ selectedClient: client_id }, () => { if (callback) callback() })
    // if (callback) callback()
  }

  DeleteClient = async () => {
    // console.log('Running DeleteClient on this client: ', this.state.selectedClient)
    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    if (!this.predicatesExist() && !isEmptyNullUndefined(this.state.selectedClient)) {
      // console.log('In DeleteClient and no predicates exist...')
      try {
        let fetchresponse = {}
        fetchresponse = await szenokPostAPI('deleteClient', {
          fetchstr: 'deleteClient',
          clientID: this.state.selectedClient
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for DeleteClient(): ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {

          //Update the clients store
          reduxupdate('clients')

          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          this.getClients()
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        //FIX:  I don't know if i need this always, but if I don't do this push stuff, sometimes the entire
        //string will come out as an array of characters, instead.  Need to figure out a way to check
        //if I have a proper string or not.
        let errorObj = []
        errorObj.push(error.toString())
        this.props.fetchErrors(errorObj)
      }
    }
  }

  handleDeleteLNote = () => {
    this.setState({ DeleteClient: { ...this.state.DeleteClient, LNotesExist: false } })
    this.DeleteClient()
  }

  handleDeleteLawyers = () => {
    this.setState({ DeleteClient: { ...this.state.DeleteClient, LawyersExist: false } })
    this.DeleteClient()
  }

  handleDeleteTNote = () => {
    this.setState({ DeleteClient: { ...this.state.DeleteClient, TNotesExist: false } })
    this.DeleteClient()
  }

  handleDeleteTycoons = () => {
    this.setState({ DeleteClient: { ...this.state.DeleteClient, TycoonsExist: false } })
    this.DeleteClient()
  }

  handleDeleteMNote = () => {
    this.setState({ DeleteClient: { ...this.state.DeleteClient, MNotesExist: false } })
    this.DeleteClient()
  }

  handleDeletePayment = () => {
    this.setState({ DeleteClient: { ...this.state.DeleteClient, PaymentsExist: false } })
    this.DeleteClient()
  }

  handleDeleteInvoice = () => {
    this.setState({ DeleteClient: { ...this.state.DeleteClient, InvoicesExist: false } })
    this.DeleteClient()
  }

  handleDeleteCounterparty = () => {
    this.setState({ DeleteClient: { ...this.state.DeleteClient, CounterpartiesExist: false } })
    this.DeleteClient()
  }

  handleDeletePrecedent = () => {
    this.setState({ DeleteClient: { ...this.state.DeleteClient, PrecedentsExist: false } })
    this.DeleteClient()
  }

  handleDeleteMatter = () => {
    this.setState({ DeleteClient: { ...this.state.DeleteClient, MattersExist: false } })
    this.DeleteClient()
  }

  handleCancelDelete = () => {
    this.setStateClient(null)
  }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }
  setRow = (value) => {
    if (!isEmptyNullUndefined(value)) this.setState({ Row: { client_id: value.values.client_id, client_name: value.values.client_name } })
    else this.setState({ Row: {} })
  }

  predicatesExist = (type) => {
    let notesExist = this.state.DeleteClient.MNotesExist || this.state.DeleteClient.LNotesExist || this.state.DeleteClient.TNotesExist
    let personsExist = this.state.DeleteClient.LawyersExist || this.state.DeleteClient.TycoonsExist
    let paymentsExist = this.state.DeleteClient.PaymentsExist
    let invoicesExist = this.state.DeleteClient.InvoicesExist
    let counterpartiesExist = this.state.DeleteClient.CounterpartiesExist
    let precedentsExist = this.state.DeleteClient.PrecedentsExist
    let mattersExist = this.state.DeleteClient.MattersExist
    let predicatesExist = notesExist || personsExist || paymentsExist || invoicesExist || counterpartiesExist || precedentsExist || mattersExist
    // console.log('Returning from predicatesExist: ', predicatesExist.toString())

    switch (type) {
      case 'notes':
        return notesExist
      case 'persons':
        return personsExist
      case 'personsPredicates':
        return notesExist
      case 'payments':
        return paymentsExist
      case 'paymentsPredicates':
        return notesExist || personsExist
      case 'invoices':
        return invoicesExist
      case 'invoicesPredicates':
        return notesExist || personsExist || paymentsExist
      case 'counterparties':
        return counterpartiesExist
      case 'counterpartiesPredicates':
        return notesExist || personsExist || paymentsExist || invoicesExist
      case 'precedents':
        return precedentsExist
      case 'precedentsPredicates':
        return notesExist || personsExist || paymentsExist || invoicesExist || counterpartiesExist 
      case 'matters':
        return mattersExist
      case 'mattersPredicates':
        return notesExist || personsExist || paymentsExist || invoicesExist || counterpartiesExist || precedentsExist
      default:
        return predicatesExist
    }
  }

  render() {

    return (
      <div>
        <h1> <Icon name='chess queen' size='large' circular /> Clients</h1>

        {this.predicatesExist('notes') && !isEmptyNullUndefined(this.state.selectedClient) &&
          <DeleteNotes clientID={this.state.selectedClient}
            handleDeleteLNote={this.handleDeleteLNote} handleDeleteTNote={this.handleDeleteTNote} handleDeleteMNote={this.handleDeleteMNote}
            handleCancelDelete={this.handleCancelDelete}
          />
        }

        {this.predicatesExist('persons') && !this.predicatesExist('personsPredicates') && !isEmptyNullUndefined(this.state.selectedClient) &&
          <DeletePerson clientID={this.state.selectedClient}
            // getCounterparties={this.getCounterparties}
            handleDeleteLawyers={this.handleDeleteLawyers} handleDeleteTycoons={this.handleDeleteTycoons}
            handleCancelDelete={this.handleCancelDelete} />
        }

        {this.predicatesExist('payments') && !this.predicatesExist('paymentsPredicates') && (!isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeletePayment clientID={this.state.selectedClient}
            handleDeletePayment={this.handleDeletePayment}
            handleCancelDelete={this.handleCancelDelete} />
        }

        {this.predicatesExist('invoices') && !this.predicatesExist('invoicesPredicates') &&
          (!isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeleteInvoice clientID={this.state.selectedClient}
            handleDeleteInvoice={this.handleDeleteInvoice}
            handleCancelDelete={this.handleCancelDelete} />
        }

        {this.predicatesExist('counterparties') && !this.predicatesExist('counterpartiesPredicates') && !isEmptyNullUndefined(this.state.selectedClient) &&
          <DeleteCounterparty clientID={this.state.selectedClient}
            handleDeleteCounterparty={this.handleDeleteCounterparty}
            handleCancelDelete={this.handleCancelDelete} />
        }

        {this.predicatesExist('precedents') && !this.predicatesExist('precedentsPredicates') && (!isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeletePrecedent clientID={this.state.selectedClient}
            handleDeletePrecedent={this.handleDeletePrecedent}
            handleCancelDelete={this.handleCancelDelete} />
        }

        {this.predicatesExist('matters') && !this.predicatesExist('mattersPredicates') && !isEmptyNullUndefined(this.state.selectedClient) &&
          <DeleteMatter clientID={this.state.selectedClient}
            handleDeleteMatter={this.handleDeleteMatter}
            handleCancelDelete={this.handleCancelDelete} />
        }

<div style={{
                overflowY: 'auto',
                overflowX: 'auto',}}>

        <ReactTableMaker passedData={this.state.Clients} passedColumns={ClientsColumns(this.props.clients.employeesarray, this.props.history, this.setModalOpen, this.setRow, !!this.props.login.currentObserver)}
          handleClick={(rowID, cellID, rowValues) => {
            // cellID !== 5 && cellID !== 6 && this.props.history.push(`/client/${rowID}?cname=${this.state.Clients.find(o => o.client_id === rowID)['client_id']}`)
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
            // this.setState({ selectedTType: rowID })
            // this.getPaymentsForInvoice(rowID) 
          }}
          includeSearchBar={false}
          />

          </div>
        {/* This is the "are you sure" modal for deleting a row */}
        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              this.setStateClient(this.state.Row.client_id,
                this.DeleteClient
              )
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Client'
            deleteObject={this.state.Row.client_name}
          />}


        {/* <DisplayState state={this.state} /> */}

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Clients))

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
function minusFontColor(e) { e.target.style.color = "" }

let ClientsColumns = (employees, history, setModalOpen, setRow, observer) => {
  return [
    // {
    //   Header: () => <span style={{ 'float': 'left' }}>Client ID</span>,
    //   accessor: 'client_id',
    //   show: true,
    //   width: 0,
    //   sortType: 'alphanumeric',
    //   Filter: 'defaultColumnFilterLeft'
    // },
    {
      Header: () => <span style={{ 'float': 'left' }}>Employee ID</span>,
      accessor: 'employee_id',
      show: true,
      width: 0,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Origination Date</span>,
      accessor: 'origination_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Originating Partner</span>,
      accessor: 'employee_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) => <span style={{ 'float': 'left' }}>
        {/* <Image src={window.location.origin + '/images/' + employees.find(employee => employee[0] === row.values['employee_id'])[4]} verticalAlign='bottom' width='20px' /> */}
        <Image src={employees.find(employee => employee[0] === row.values['employee_id'])[4]} verticalAlign='bottom' width='20px' />
        {/* {employees.find(employee => employee[0] === cell.value)[4]} */}
        {/* {employees[1][4]} */}
        {' '}{cell.value}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Engagement{<br />}Letter{<br />}Obtained</span>,
      accessor: 'engagement_letter',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => <div style={{ textAlign: 'center' }}><span style={{ display: 'inline-block' }}>{cell.value ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}</span></div>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
      accessor: 'client_id',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
        <Link to={`editclient/${cell.value}`}><Icon name='edit' />Edit</Link></span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'client_id2',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
      !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
          <Link to={''} onClick={(e) => {
            // console.log('Hitting delete button')
            e.preventDefault()
            !observer && setModalOpen(true)
            !observer && setRow(row)
            // setStateClient(row.values.client_id)
            // DeleteClient()
          }}>
            <Icon name='delete' color='red' size='small' bordered />Delete
          </Link>
        </span>
    },
  ]
}
