import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import * as mapActions from '../../actions/mapActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { isEmptyNullUndefined } from '../helper/Misc'

import ReactTableMaker from '../helper/ReactTableMaker'
import ChartsMattersOverTime from '../charts/Charts-MattersOverTime'
import LocationsInfo from './Component-LocationsInfo'

import { Segment, Loader, Dimmer, Image, Icon } from 'semantic-ui-react';
import './Component-Dashboard.css'

// import { level } from 'winston';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

const pageSize = 5

class ClientDashboard extends Component {

  state = {
    LawFirmsForClient: [],
    CounterpartiesForClient: [],
    LawyersForClient: [],
    TycoonsForClient: [],
    Loading: false,
    LoadingCounterparties: false,
    LoadingLawyers: false,
    LoadingTycoons: false,
    LoadingMOTChart: false,
  }

  async componentDidMount() {
    this.update()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.EmployeeID !== this.props.EmployeeID) {
      this.update()
    }
  }

  update = async () => {
    this.props.fetchResetState('all')
    this.setState({
      Loading: true,
      LoadingCounterparties: true,
      LoadingLawyers: true,
      LoadingTycoons: true,
      LoadingMOTChart: false,
    })
    this.getClientLawFirms()
    this.getClientCounterparties()
    this.getClientLawyers()
    this.getClientTycoons()
  }

  checkLoading = () => {
    !this.state.LoadingLawFirms && !this.state.LoadingCounterparties && !this.state.LoadingLawyers && !this.state.LoadingTycoons && !this.state.LoadingMOTChart && this.setState({ Loading: false })
  }

  setLoadingMattersOverTimeChart = (bool) => { this.setState({ LoadingMOTChart: bool }, () => this.checkLoading()) }

  getClientLawFirms = async () => {
    // console.log('Running getClientLawFirms....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('clientInfo', { fetchstr: 'getClientLawFirms', clientID: this.props.clientID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getClientLawFirms: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      LawFirmsForClient: fetchresponse.payload, LoadingLawFirms: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ LawFirmsForClient: [], LoadingLawFirms: false }, () => this.checkLoading())
  }

  getClientCounterparties = async () => {
    // console.log('Running getClientCounterparties....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('clientInfo', { fetchstr: 'getClientCounterparties', clientID: this.props.clientID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getClientCounterparties: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      CounterpartiesForClient: fetchresponse.payload, LoadingCounterparties: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ CounterpartiesForClient: [], LoadingCounterparties: false }, () => this.checkLoading())
  }

  getClientLawyers = async () => {
    // console.log('Running getClientLawyers....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('clientInfo', { fetchstr: 'getClientLawyers', clientID: this.props.clientID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getClientLawyers: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      LawyersForClient: fetchresponse.payload, LoadingLawyers: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ LawyersForClient: [], LoadingLawyers: false }, () => this.checkLoading())
  }

  getClientTycoons = async () => {
    // console.log('Running getClientTycoons....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('clientInfo', { fetchstr: 'getClientTycoons', clientID: this.props.clientID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getClientTycoons: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      TycoonsForClient: fetchresponse.payload, LoadingTycoons: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ TycoonsForClient: [], LoadingTycoons: false }, () => this.checkLoading())
  }

  render() {

    return (
      <React.Fragment>
        {this.state.Loading ?
          <Segment size='massive'>
            <Dimmer active>
              <Loader content='Loading' />
            </Dimmer>

            {/* //Fix:  I need to do this for now because webpack doesn't handle images well, but eventually replace
        //this with a weblink as in the commented bit below.  */}
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            {/* <Image src={`./images/${props.login.currentIcon}`} verticalAlign='bottom' width='50px' circular  /> */}
          </Segment>
          :
          <React.Fragment>

            <div className='dashboard-header'>
              <h3>Activity:</h3>
            </div>
            <div className='dashboard-container'>
              <div className='dashboard-item1'>
                <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
                  <h4><Icon name='chart line' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/charts`)} />Activity:</h4>
                  <ChartsMattersOverTime matters={this.props.matters} ClientInfo={this.props.ClientInfo} setLoadingMattersOverTimeChart={this.setLoadingMattersOverTimeChart} />
                </Segment>
              </div>
            </div>
            <div className='dashboard-header'>
              <h3>Law Firm/Lawyer Information:</h3>
            </div>
            <div className='dashboard-container'>
              <div className='dashboard-item1'>
                {!isEmptyNullUndefined(this.state.LawFirmsForClient) ?
                  <React.Fragment>
                    <Segment compact raised style={{ backgroundColor: '#f4eaff' }}>
                      <h4><Icon name='balance scale' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/matters`)} />Most Frequent Law Firms Faced:</h4>
                      <ReactTableMaker passedData={this.state.LawFirmsForClient} passedColumns={LawFirmsColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                        includeSearchBar={false}
                        includeDensePaginationFooter={true}
                        color='purple'
                        passedPageSize={pageSize}
                      />
                    </Segment>
                  </React.Fragment>
                  :
                  <h4>No law firms to show...</h4>
                }
              </div>
              <div className='dashboard-item1'>
                {!isEmptyNullUndefined(this.state.CounterpartiesForClient) ?
                  <React.Fragment>
                    <Segment compact raised style={{ backgroundColor: '#ffece3' }}>
                      <h4><Icon name='address book outline' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/counterparties`)} />Most Frequent Counterparties Faced:</h4>
                      <ReactTableMaker passedData={this.state.CounterpartiesForClient} passedColumns={CounterpartiesColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                        includeSearchBar={false}
                        includeDensePaginationFooter={true}
                        color='orange'
                        passedPageSize={pageSize}
                      />
                    </Segment>
                  </React.Fragment>
                  :
                  <h4>No counterparties to show...</h4>
                }
              </div>
              <div className='dashboard-item2-0'>
                {!isEmptyNullUndefined(this.state.LawyersForClient) ?
                  <React.Fragment>
                    <Segment compact raised style={{ backgroundColor: '#ecfff9' }}>
                      <h4><Icon name='male' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/persons`)} />Most Frequent Lawyers Faced:</h4>
                      <ReactTableMaker passedData={this.state.LawyersForClient} passedColumns={PersonsColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                        includeSearchBar={false}
                        includeDensePaginationFooter={true}
                        color='teal'
                        passedPageSize={pageSize}
                      />
                    </Segment>
                  </React.Fragment>
                  :
                  <h4>No lawyers to show...</h4>
                }
              </div>
              <div className='dashboard-item2-0'>
                {!isEmptyNullUndefined(this.state.TycoonsForClient) ?
                  <React.Fragment>
                    <Segment compact raised style={{ backgroundColor: '#ffffdd' }}>
                      <h4><Icon name='male' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/persons`)} />Most Frequent Businessmen Faced:</h4>
                      <ReactTableMaker passedData={this.state.TycoonsForClient} passedColumns={PersonsColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                        includeSearchBar={false}
                        includeDensePaginationFooter={true}
                        color='yellow'
                        passedPageSize={pageSize}
                      />
                    </Segment>
                  </React.Fragment>
                  :
                  <h4>No businessmen to show...</h4>
                }
              </div>
            </div>

            {/* LOCATIONS STUFF */}
            <div className='dashboard-header'>
              <h3>Locations:</h3>
            </div>
            <div className='dashboard-container'>
              <div className='xdashboard-item1'>
                <Segment compact raised style={{ backgroundColor: '#ffd9d9' }}>
                  <h4><Icon name='map signs' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/maps`)} />Locations:</h4>
                  <LocationsInfo Locations={this.props.Locations}
                    showClient={false} showMatter={true} showCoords={true} noFilters={true}
                    handleIndividualCenter={(lat, lng) => {
                      this.props.setCurrentLatitude(lat)
                      this.props.setCurrentLongitude(lng)
                      this.props.setCurrentZoomLevel(15)
                      this.props.history.push(`/maps/specific?lat=${lat}&lng=${lng}`)
                      // console.log('Clicking on ', lat, ' and: ', lng)
                    }
                    }
                  />
                </Segment>
              </div>
            </div>
          </React.Fragment>
        }

        {/* <DisplayState state={this.state} /> */}

      </React.Fragment >
    )
  }
}
const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions, mapActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientDashboard))

ClientDashboard.defaultProps = {
  clientID: null,
  Locations: [],
}


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
// function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
// function minusFontColor(e) { e.target.style.color = "" }

let CounterpartiesColumns = (employees, history, showFinances) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'counterparty',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => {
            // console.log('Yo, this is the row: ', row)
            history.push(`/counterparty/${cell.value}?counterparty=${cell.value}&counterpartyID=${row.original.counterparty_id}`)
          }}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'right' }}>Number<br />Matters</span>,
      accessor: 'deals_involving_firm',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{cell.value}</span>
    },
  ]
}

let LawFirmsColumns = (employees, history, showFinances) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
      accessor: 'law_firm',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row, rows }) => !isEmptyNullUndefined(rows) &&
        <React.Fragment>

          <span
            onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
            style={{ 'float': 'left', cursor: 'pointer' }}
            onClick={() => history.push(`/lawfirm/${cell.value}?lawfirm=${cell.value}&lawFirmID=${row.original.law_firm_id}`)}
          // onClick={() => console.log('Yo, this is the value: ', row)}
          >
            {cell.value}
          </span>
          {/* {!isEmptyNullUndefined(rows[row.index]) && <span style={{ paddingLeft: '15px' }}>{rows[row.index].original.counterparties_advised > 0 ? ` (Advise ${rows[row.index].original.counterparties_advised} counterparties)` : null}</span>}
          {!isEmptyNullUndefined(rows[row.index]) && <span style={{ paddingLeft: '15px' }}>{rows[row.index].original.notes_for_law_firm > 0 ? ` (${rows[row.index].original.notes_for_law_firm} notes entered)` : null}</span>} */}
        </React.Fragment>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'right' }}>Number<br />Matters</span>,
      accessor: 'deals_involving_firm',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{cell.value}</span>
    },
  ]
}

let PersonsColumns = (employees, history, showFinances) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Person Name</span>,
      accessor: 'person_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/person/${cell.value}?person=${cell.value}&personID=${row.original.person_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Person Email</span>,
      accessor: 'person_email',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/person/${row.original.person_name}?person=${row.original.person_name}&personID=${row.original.person_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'right' }}>Number<br />Matters</span>,
      accessor: 'person_count',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{cell.value}</span>
    },
  ]
}
