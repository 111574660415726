import React, { Component } from 'react'
import { Route, NavLink, withRouter } from "react-router-dom";
import queryString from 'query-string'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

// import JSZip from 'jszip'

// import Profile from '../layout/Profile'
import { Icon, Label, Button } from 'semantic-ui-react';
import { isEmptyNullUndefined, recreateTables } from '../helper/Misc';
import './Content.css'


class SideMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      AdminLock: true
    };

    // this.getData = this.getData.bind(this);
  }
  async componentDidMount() {
  }


  render() {

    return (
      <React.Fragment>
        {/* <div className='large-container'> */}
        <div className='so-menu-container'>
          <div className='so-menu-item so-menu-top'>Site menu</div>
          {menuItemCreater('drivers license', 'Dashboard', '/', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, '', true, true)}
          {menuItemCreater('chess queen', 'Clients', '/clients', this.props.history, isEmptyNullUndefined(this.props.clients.clientsarray) ? 0 : this.props.clients.clientsarray.length, this.props.login.currentObserver, this.props.location.pathname, '/addclient', false, false, ['client'])}
          {subMenuItemCreater('/client/:clientID', 'Client: ' + queryString.parse(this.props.location.search).cname)}
          {menuItemCreater('chess bishop', 'Matters', '/matters', this.props.history, isEmptyNullUndefined(this.props.clients.mattersarray) ? 0 : this.props.clients.mattersarray.length, this.props.login.currentObserver, this.props.location.pathname, '/addmatter', false, false, ['matter'])}
          {subMenuItemCreater('/matter/:matterID', 'Matter: ' + queryString.parse(this.props.location.search).matter)}
          {menuItemCreater('building outline', 'Transaction Types', '/transactiontypes', this.props.history, isEmptyNullUndefined(this.props.clients.transactiontypesandsubsarray) ? 0 : this.props.clients.transactiontypesandsubsarray.length, this.props.login.currentObserver, this.props.location.pathname, '/addttype', false, false)}
          {subMenuItemCreater('/transactiontype/:ttypeID', 'Transaction Type: ' + queryString.parse(this.props.location.search).ttype)}
          {menuItemCreater('clipboard outline', 'Asset Classes', '/assetclasses', this.props.history, isEmptyNullUndefined(this.props.clients.assetclassarray) ? 0 : this.props.clients.assetclassarray.length, this.props.login.currentObserver, this.props.location.pathname, '/addassetclass', false, false)}
          {subMenuItemCreater('/assetclass/:aClassID', 'Asset Class: ' + queryString.parse(this.props.location.search).aclass)}
          {menuItemCreater('balance scale', 'Law Firms', '/lawfirms', this.props.history, isEmptyNullUndefined(this.props.clients.lawfirmsarray) ? 0 : this.props.clients.lawfirmsarray.length, this.props.login.currentObserver, this.props.location.pathname, '/addlawfirm', false, false)}
          {subMenuItemCreater('/lawfirm/:lawFirmID', 'Law Firm: ' + queryString.parse(this.props.location.search).lawfirm)}
          {menuItemCreater('address book outline', 'Counterparties', '/counterparties', this.props.history, isEmptyNullUndefined(this.props.clients.counterparties_total) ? 0 : this.props.clients.counterparties_total, this.props.login.currentObserver, this.props.location.pathname, '/addcounterparty', false, false)}
          {subMenuItemCreater('/counterparty/:counterpartyID', 'Counterparty: ' + queryString.parse(this.props.location.search).counterparty)}
          {menuItemCreater('male', 'Persons', '/persons', this.props.history, isEmptyNullUndefined(this.props.clients.persons_total) ? 0 : this.props.clients.persons_total, this.props.login.currentObserver, this.props.location.pathname, '/addperson', false, false)}
          {subMenuItemCreater('/person/:personID', 'Person: ' + queryString.parse(this.props.location.search).person)}
          {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
            <React.Fragment>
              {menuItemCreater('dollar sign', 'Invoices', '/invoices', this.props.history, isEmptyNullUndefined(this.props.clients.invoice_total) ? 0 : this.props.clients.invoice_total, this.props.login.currentObserver, this.props.location.pathname, '/addinvoice', false, false)}
              {subMenuItemCreater('/invoice/:invoiceID', 'Invoice No: ' + queryString.parse(this.props.location.search).invoice)}
              {menuItemCreater('money bill alternate outline', 'Payments', '/payments', this.props.history, isEmptyNullUndefined(this.props.clients.payments_total) ? 0 : this.props.clients.payments_total, this.props.login.currentObserver, this.props.location.pathname, '/addpayment', false, false)}
              {subMenuItemCreater('/payment/:paymentID', 'Payment No: ' + queryString.parse(this.props.location.search).payment)}
            </React.Fragment>
          }
          {menuItemCreater('eye', 'Precedents', '/precedents', this.props.history, isEmptyNullUndefined(this.props.clients.precedents_total) ? 0 : this.props.clients.precedents_total, this.props.login.currentObserver, this.props.location.pathname, '/addprecedent', false, false)}
          {subMenuItemCreater('/precedent/:precedentID', 'Precedent: ' + queryString.parse(this.props.location.search).precedent)}
          {menuItemCreater('map signs', 'Locations', '/maps', this.props.history, isEmptyNullUndefined(this.props.clients.locations_total) ? 0 : this.props.clients.locations_total, this.props.login.currentObserver, this.props.location.pathname, '', false, true)}
          {menuItemCreater('inbox', 'Bills (Third Party)', '/bills', this.props.history, isEmptyNullUndefined(this.props.clients.bills_total) ? 0 : this.props.clients.bills_total, this.props.login.currentObserver, this.props.location.pathname, '', false, true)}
          {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowCharts === 1) &&
            menuItemCreater('chart line', 'Charts', '/charts', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, '', true, true)
          }
          {this.props.test === '/test' && menuItemCreater('question circle outline', 'Test', '/test', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, '', true, true)}
          {menuItemCreater('search', 'Search', '/search', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, '', true, true)}
          {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentLevel === 0) &&
            <React.Fragment>
              <div className='so-menu-admin'>
                <div className='so-menu-item' onClick={() => this.setState({ AdminLock: !this.state.AdminLock })}>
                  Admin Items <span className='so-menu-item so-menu-admin-icon'><Icon name={this.state.AdminLock ? 'lock' : 'unlock alternate'} size='large' color='black' circular inverted /></span>
                </div>
              </div>
              {!this.state.AdminLock &&
                <React.Fragment>
                  {menuItemCreater('adn', 'Fake Data', '/fakedata', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, '', true, true, [], true)}
                  {menuItemCreater('user secret', 'Employees', '/employees', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, 'addemployee', true, false, [], true)}
                  {menuItemCreater('picture', 'Pictures', '/pictures', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, '', true, true, [], true)}
                  {menuItemCreater('database', 'Database', '/database', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, '', true, true, [], true)}
                  {menuItemCreater('th', 'Logs', '/logs', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, '', true, true, [], true)}
                  {menuItemCreater('cloud upload', 'CSV Upload', '/upload', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, '', true, true, [], true)}
                  {menuItemCreater('cloud download', 'Table Download', '/downloadTable', this.props.history, 0, this.props.login.currentObserver, this.props.location.pathname, '', true, true, [], true)}
                  {this.props.loading &&
                    <Button onClick={() => recreateTables(true, this.props.login.currentToken)}>Force Recreate Table (DANGER)</Button>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
        </div>
        {/* <div className='large-item'>Yo</div> */}
        {/* </div> */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideMenu))

SideMenu.defaultProps = {
  loading: true,
  test: '',
}

let menuItemCreater = (icon_name = '', heading = '', navlink = '', history, counter = 0, currentObserver, pathname, pushToLocation = '', noCounter = false, noAdd = false, pathNamesArray = [], adminWarning = false) => {
  // console.log('navlink is: ', navlink)
  // console.log('pathname is: ', pathname)
  return (
    <React.Fragment>
      <NavLink to={navlink} exact={true} activeClassName="so-menu-item-active"
        isActive={() => {
          if (pathChecker(pathname, navlink)) return true
          else return false
          // if (isEmptyNullUndefined(pathNamesArray)) return navlink === pathname ? true : false
          // else if (pathname.includes(pathNamesArray[0])) return true
          // else return false
        }}>
        <div className='so-menu-item' >
          <div className='so-menu-heading'>
            <div className='so-menu-icon'>
              <Icon name={icon_name} size='small' circular />
            </div>
            {adminWarning && <span style={{ color: 'red', paddingRight: '3px' }}>Admin Only:</span>}{heading}
          </div>
          <div className='so-menu-counter'>
            {!noCounter && <Label color='teal' size='tiny'>{counter}</Label>}
            {!noAdd && <div className='so-menu-addbutton'>
              <div className={`so-menu-addButton2 ${pathname === pushToLocation ? 'active' : ''}`} onClick={(event) => { event.preventDefault(); !currentObserver && history.push(pushToLocation) }}>
                {/* <div className={`so-menu-addButton2 ${pathChecker(pathname, pushToLocation) ? 'active' : ''}`} onClick={(event) => { event.preventDefault(); !currentObserver && history.push(pushToLocation) }}> */}
                <span className='so-menu-addButton2-plus'>+</span>
                  Add
                  </div>
            </div>}
          </div>
        </div>
      </NavLink>
    </React.Fragment>
  )
}

let subMenuItemCreater = (path = '', text = '') => {
  return (
    <Route path={path} render={(props) =>
      <div className='so-menu-item so-submenu-item' >{text}</div>
    } />
  )
}

let pathChecker = (pathname = [], navlink = '') => {
  // console.log(`This is pathname: ${pathname} and this is navlink: ${navlink}`)
  switch (navlink) {
    case '/':
      if (pathname === '/') return true
      else return false
    case '/clients':
      if (pathname.substring(0, '/client/'.length) === ('/client/') || pathname === '/clients' || pathname === '/addclient') return true
      else return false
    case '/matters':
      if (pathname.substring(0, '/matter/'.length) === ('/matter/') || pathname === '/matters' || pathname === '/addmatter') return true
      else return false
    case '/transactiontypes':
      if (pathname.substring(0, '/transactiontype/'.length) === ('/transactiontype/') || pathname === '/transactiontypes' || pathname === '/addttype') return true
      else return false
    case '/assetclasses':
      if (pathname.substring(0, '/assetclass/'.length) === ('/assetclass/') || pathname === '/assetclasses' || pathname === '/addassetclass') return true
      else return false
    case '/lawfirms':
      if (pathname.substring(0, '/lawfirm/'.length) === ('/lawfirm/') || pathname === '/lawfirms' || pathname === '/addlawfirm') return true
      else return false
    case '/counterparties':
      if (pathname.substring(0, '/counterparty/'.length) === ('/counterparty/') || pathname === '/counterparties' || pathname === '/addcounterparty') return true
      else return false
    case '/persons':
      if (pathname.substring(0, '/person/'.length) === ('/person/') || pathname === '/persons' || pathname === '/addperson') return true
      else return false
    case '/invoices':
      if (pathname.substring(0, '/invoice/'.length) === ('/invoice/') || pathname === '/invoices' || pathname === '/addinvoice') return true
      else return false
    case '/payments':
      if (pathname.substring(0, '/payment/'.length) === ('/payment/') || pathname === '/payments' || pathname === '/addpayment') return true
      else return false
    case '/precedents':
      if (pathname.substring(0, '/precedent/'.length) === ('/precedent/') || pathname === '/precedents' || pathname === '/addprecedent') return true
      else return false
    case '/maps':
      if (pathname.substring(0, '/map/'.length) === ('/map/') || pathname === '/maps' || pathname === '/addmap') return true
      else return false
    case '/charts':
      if (pathname.substring(0, '/chart/'.length) === ('/chart/') || pathname === '/charts' || pathname === '/addchart') return true
      else return false
    case '/test':
      if (pathname.substring(0, '/test/'.length) === ('/test/') || pathname === '/test' || pathname === '/test') return true
      else return false
    case '/search':
      if (pathname.substring(0, '/search/'.length) === ('/search/') || pathname === '/search' || pathname === '/addsearch') return true
      else return false
    case '/fakedata':
      if (pathname.substring(0, '/fakedata/'.length) === ('/fakedata/') || pathname === '/fakedata') return true
      else return false
    case '/employees':
      if (pathname.substring(0, '/employee/'.length) === ('/employee/') || pathname === '/employees' || pathname === '/addemployee') return true
      else return false
    case '/pictures':
      if (pathname.substring(0, '/picture/'.length) === ('/picture/') || pathname === '/pictures') return true
      else return false
    case '/database':
      if (pathname.substring(0, '/database/'.length) === ('/database/') || pathname === '/database') return true
      else return false
    case '/logs':
      if (pathname.substring(0, '/log/'.length) === ('/log/') || pathname === '/logs') return true
      else return false
    case '/upload':
      if (pathname.substring(0, '/upload/'.length) === ('/upload/') || pathname === '/upload') return true
      else return false
    default:
      return false
  }
}