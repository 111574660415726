import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { Loading } from '../helper/Misc'
import { toIMGcanvas } from '../helper/PDFImage'
import ChartsLineIndividualSVG from './Charts-Line-Individual-SVG'
import { YearDropDown, EmployeeDropDown, LineIndividualGetBilling } from './Charts-AClass-Functions'

import { Grid, Checkbox, Segment } from 'semantic-ui-react';


// import DisplayState from '../helper/DisplayState';

class ChartsLineIndividual extends Component {

  state = {
    TokenReady: false,
    loading: true,
    showbars: false,
    showbarsscatter: false,
    showpriortotal: true,
    showcumline: true,
    showcumscatter: false,
    showByInvoicingPartner: !this.props.byOriginating,
    PartnerBilling: null,
    currentYear: new Date().getFullYear(),
    selectedYear: new Date().getFullYear(),
    selectedPartner: 1,
    fetchObject: {},
    Loading: true
  }

  async componentDidMount() {
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      LineIndividualGetBilling(this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ fetchObject: value, Loading: false }))
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.loading) {
      this.setState({ TokenReady: true })
    }
  }

  render() {

    let checkboxStyle = { paddingRight: 30, paddingTop: 10, paddingBottom: 10 }

    let chartStyle = {
      background: "#ebf5fa",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      fontFamily: "'Fira Sans', sans-serif",
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderColor: '#a5a5a5',
      borderRadius: '15px',
      boxShadow: '2px 2px 8px #888888',
      cursor: 'pointer',
    }

    return (

      <React.Fragment>


        {/* <DisplayState state={this.state} /> */}
        {/* <Header as='h3'>Partner Invoices Chart</Header>
        <Divider /> */}

        <Grid>

          <Grid.Column width={4}>
            <YearDropDown year={this.state.selectedYear} maxYears={this.props.maxYears}
              currentYear={this.state.currentYear} includeAll={true} callback={(value) => this.setState({ selectedYear: value })} />
          </Grid.Column>
          <Grid.Column width={4}>
            <EmployeeDropDown selectedPartner={this.state.selectedPartner} includeAll={false} callback={(value) => this.setState({ selectedPartner: value })} />
          </Grid.Column>
          <Grid.Column width={8} />

          <Grid.Row>
            <Grid.Column width={14} >
              <Segment compact color='blue'>
                <Checkbox
                  style={checkboxStyle}
                  label='Invoice Bars'
                  // value={this.state}
                  checked={this.state.showbars}
                  onChange={() => this.setState((prevState) => ({ showbars: !prevState.showbars }))}
                />
                <Checkbox
                  style={checkboxStyle}
                  label='Invoice Scatter Points'
                  // value={this.state}
                  checked={this.state.showbarsscatter}
                  onChange={() => this.setState((prevState) => ({ showbarsscatter: !prevState.showbarsscatter }))}
                />
                <Checkbox
                  style={checkboxStyle}
                  label='Prior Year Total'
                  // value={this.state}
                  checked={this.state.showpriortotal}
                  onChange={() => this.setState((prevState) => ({ showpriortotal: !prevState.showpriortotal }))}
                />
                <Checkbox
                  style={checkboxStyle}
                  label='Cumulative Scatter Points'
                  // value={this.state}
                  checked={this.state.showcumscatter}
                  onChange={() => this.setState((prevState) => ({ showcumscatter: !prevState.showcumscatter }))}
                />
                <Checkbox
                  style={{ paddingRight: 30, paddingTop: 10, paddingBottom: 10 }}
                  label='Cumulative Line'
                  // value={this.state}
                  checked={this.state.showcumline}
                  onChange={() => this.setState((prevState) => ({ showcumline: !prevState.showcumline }))}
                />
                <br />
                <Checkbox
                  toggle
                  // style={checkboxStyle}
                  label={this.state.showByInvoicingPartner ? 'By Invoicing Partner' : 'By Originating Partner'}
                  // value={this.state}
                  checked={this.state.showByInvoicingPartner}
                  onChange={() => this.setState((prevState) => ({ showByInvoicingPartner: !prevState.showByInvoicingPartner }))}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>

        </Grid>

        <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
          <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
            <div id='Individual'>
              {this.state.Loading ? <Loading /> :
                <svg
                  style={chartStyle}
                  onClick={() => toIMGcanvas('Individual')}
                  height={600 * this.props.maxViewPercentage / 100} viewBox="0 0 600 350">

                  <ChartsLineIndividualSVG
                    selectedYear={this.state.selectedYear}
                    currentYear={this.state.currentYear}
                    selectedPartner={this.state.selectedPartner}
                    maxViewPercentage={this.props.maxViewPercentage}
                    maxYears={this.props.maxYears}
                    fetchObject={this.state.fetchObject}
                    showbars={this.state.showbars}
                    showbarsscatter={this.state.showbarsscatter}
                    showpriortotal={this.state.showpriortotal}
                    showcumline={this.state.showcumline}
                    showcumscatter={this.state.showcumscatter}
                    byInvoicingEmployee={this.state.showByInvoicingPartner}
                  />
                </svg>
              }
            </div>
          </Segment>
        </div>
      </React.Fragment>

    )
  }
}


const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsLineIndividual))

ChartsLineIndividual.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  byOriginating: true,
  // handleSomething: () => { },
}
