import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, filterSimpleInput, ListMakerSimple } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'
import NumberFormat from 'react-number-format'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Grid, Form, Header, Label, Divider, Dropdown, Button, Segment, List } from 'semantic-ui-react';
import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';

import Invoices from './Component-Invoices';

import config from '../../JO-CONFIG'

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';
// import { isNullOrUndefined } from 'util';

const initialState = {
  visible: false,
  ClientsMatters: [],
  ExistingMatters: [],
  ExistingInvoices: [],
  SelectedClient: null,
  SelectedClientName: '',
  SelectedMatterOriginationDate: null,
  SelectedMatter: null,
  LastUsedInvoiceNumber: '',
  Matter: '',
  Invoice2Regex: [],
  Invoice2RegexSpecific: '',
  MatterArray: [],
  Description: null,
  initialValues: {},
  ClientMatterDropDownArray: []
}

class AddInvoice extends Component {

  state = initialState

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getClientsMatters()
    if (this.props.update) {
      await this.getSpecificInvoice()

      await this.getInvoices(this.state.initialValues.client_id)

      this.setState({ SelectedClient: this.state.initialValues.client_id, SelectedMatter: this.state.initialValues.matter_id, SelectedEmployeeID: this.state.initialValues.invoicing_employee_id })
    }
  }


  getSpecificInvoice = async () => {
    // console.log('Running getSpecificInvoice....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('invoiceInfo', { fetchstr: 'getInvoice', invoiceID: this.props.match.params.invoiceID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getInvoice: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { ...fetchresponse.payload },
      SelectedMatterOriginationDate: fetchresponse.payload.matter_origination_date
    })
  }

  getClientsMatters = async () => {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'clientsmattersarray' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for clientsmattersarray: ', fetchresponse)
    this.setState({
      ClientsMatters: fetchresponse.payload.map(matter => {
        var arr = []
        arr[0] = matter.client_id
        arr[1] = matter.client_name
        arr[2] = matter.matter_id
        arr[3] = matter.matter_name
        arr[4] = matter.employee_id
        arr[5] = matter.matter_origination_date
        return arr
      }
      )
    }, () => this.setState({ ClientMatterDropDownArray: this.ClientMatterDropDownArray() }))
  }

  getInvoices = async (client_id) => {

    this.props.fetchResetState('all')
    // console.log('Fetching for this client: ', client_id)
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'invoicesforclient', IDNumber: client_id },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for invoicesforclient: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    this.setState({
      ExistingInvoices: fetchresponse.payload.map(invoice => {
        var arr = []
        arr[0] = invoice.invoice_id
        arr[1] = invoice.invoice_number
        arr[2] = invoice.invoice_amount
        arr[3] = invoice.invoice_date
        arr[4] = invoice.invoice_description
        arr[5] = invoice.matter_id
        return arr
      }
      )
    })

    fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'lastinvoice', IDNumber: this.state.SelectedClient },
      this.props.login.currentToken)

    if (!fetchresponse.payload.length <= 0)
      this.setState({
        LastUsedInvoiceNumber: fetchresponse.payload[0].invoice_number
      })
    else {
      this.setState({
        LastUsedInvoiceNumber: ''
      })
    }

  }

  createInvoiceNumber(clientID) {
    try {
      // console.log('This is clientID: ', clientID)
      let invoiceNumber = ''
      let clientName = this.props.clients.clientsarray.find(row => row[0] === parseInt(clientID))
      // console.log('this is clientName: ', clientName[1].concat(clientName[2], clientName[3]))
      if (isEmptyNullUndefined(this.state.LastUsedInvoiceNumber)) {
        invoiceNumber = this.createInvoiceName(clientName[1].concat(clientName[2], clientName[3])) + '1001'
      }
      else {
        let prefix = this.state.LastUsedInvoiceNumber.slice(0, 8)
        let suffix = !isNaN(this.state.LastUsedInvoiceNumber.slice(-4)) ?
          (parseInt(this.state.LastUsedInvoiceNumber.slice(-4)) + 1).toString() :
          '1002'
        invoiceNumber = prefix + suffix
      }
      return invoiceNumber
    }
    catch {
      return 'Client1001'
    }
  }

  createInvoiceName(clientName) {
    return clientName.replace(/[^a-zA-Z]/g, "").slice(0, 8)
  }

  getClientName(clientID) {
    let result = this.props.clients.clientsarray.find(row => row[0] === parseInt(clientID))
    // console.log('Result is: ', result)
    let name = ''
    if (result[1].length > 0) name = result[1]
    else name = result[3] + ', ' + result[2]
    return name
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ Invoice2Regex: updatedListGeneralSorted })
    if (updatedListSpecific.length > 0) {
      if (this.props.update && updatedListSpecific[0][1] !== this.state.initialValues.invoice_number) {
        this.setState({ Invoice2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
      else if (this.props.update && updatedListSpecific[0][1] === this.state.initialValues.invoice_number) {
        this.setState({ Invoice2RegexSpecific: '' })
        setStatus({ errors: {} })
      }
      if (!this.props.update) {
        this.setState({ Invoice2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ Invoice2RegexSpecific: '' })
      setStatus({ errors: {} })
    }

  }, 100)

  ClientDropDownArray = () => {
    var array = this.props.clients.clientsarray.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `CID${result[0]}`
      if (result[1].length > 0) obj.text = result[1]
      else obj.text = result[3] + ', ' + result[2]
      obj.value = result[0]
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }
  ClientMatterDropDownArray = () => {
    var array = this.state.ClientsMatters.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `CMID${result[0]},${result[2]}`
      obj.text = result[1] + ' -- ' + result[3]
      obj.value = result[0] + ',' + result[2] // + ',' + result[4]
      // obj.employee = result[4]
      return obj
    }
    )
    // console.log('This is ClientMatterDropDownArray: ', array)
    return array
  }

  TTypeDropDownArray = () => {
    var array = this.props.clients.transactiontypesandsubsarray.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `EID${result[0]},${result[1]}`
      obj.text = result[2] + ' -- ' + result[3]
      obj.value = result[0] + ',' + result[1]
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }

  AClassDropDownArray = () => {
    var array = this.props.clients.assetclassarray.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `EID${result[0]}}`
      obj.text = result[1]
      obj.value = result[0]
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }

  employeeDropDownArray = () => {
    var array = this.props.clients.employeesarray
      .reduce((acc, result) => {
        //Reduce array to only those whose level is equal to or below BILLING_PARTNER_LEVEL
        // console.log('This is result: ', result)
        if (result[5] <= config.LEVELS.BILLING_PARTNER_LEVEL) {
          acc.push(result)
        }
        return acc
      }, []
      )
      .map(result => {
        var obj = {}
        obj.key = `EID${result[0]}`
        obj.text = result[2] + ', ' + result[1]
        obj.value = result[0]
        // obj.image = window.location.origin + '/images/' + result[4]
        obj.image = result[4]
        return obj
      })
    return array
  }

  render() {

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      ClientMatter: null,
      InvoiceNumber: '',
      InvoiceAmount: null,
      PartnerPick: null,
      // PartnerPick: this.state.SelectedEmployeeID,
      IDate: 'a',
      Description: null,
    } : {
        ClientMatter: this.state.initialValues.client_id + ',' + this.state.initialValues.matter_id, //+ ',' + this.state.initialValues.invoicing_employee_id,
        InvoiceNumber: this.state.initialValues.invoice_number,
        InvoiceAmount: this.state.initialValues.invoice_amount,
        PartnerPick: this.state.initialValues.invoicing_employee_id,
        IDate: new Date(this.state.initialValues.invoice_date),
        Description: this.state.initialValues.invoice_description,
      }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300


    return (
      <React.Fragment>
        {this.props.headingForForm.length <= 0 ?
          <Header as='h1'>Invoice {this.props.update === true ? 'Edit' : 'Add'} Form</Header> :
          <Header as='h3'>{this.props.headingForForm}</Header>
        }
        {this.props.update && !isEmptyNullUndefined(this.state.initialValues) &&
          <Header as='h2'>Editing: {this.state.initialValues.invoice_number}</Header>
        }


        <Formik

          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={AddInvoiceSchema}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              this.setState({ Invoice2Regex: [], Invoice2RegexSpecific: '' })
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editInvoice', {
                InvoiceNumber: values.InvoiceNumber, InvoiceAmount: values.InvoiceAmount,
                IDate: values.IDate, Description: values.Description,
                MatterID: this.state.SelectedMatter, EmployeeID: values.PartnerPick,
                invoiceID: this.props.match.params.invoiceID
              }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addInvoice', {
                InvoiceNumber: values.InvoiceNumber, InvoiceAmount: values.InvoiceAmount,
                IDate: values.IDate, Description: values.Description,
                MatterID: this.state.SelectedMatter, EmployeeID: values.PartnerPick
              }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for addInvoice: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                //Update the Existing Invoices
                // this.getInvoices()

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                actions.resetForm({ ...initialValues })
                this.setState(initialState)
                await reduxupdate('invoices')
                this.getClientsMatters()
                if (this.props.update) {
                  await this.getSpecificInvoice()
                  await this.getInvoices(this.state.initialValues.client_id)
                  this.setState({ SelectedMatter: this.state.initialValues.matter_id })
                }
                this.props.update && this.props.history.goBack()

              }
            }
            catch (error) { this.props.fetchErrors(error.toString()) }
            actions.setSubmitting(false)
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment>
                  <Grid columns={16} padded divided={this.state.ExistingInvoices.length > 0 ? true : false}>
                    <Grid.Row>
                      <Grid.Column width={this.state.ExistingInvoices.length > 0 ? 13 : 16}>

                        <Form onSubmit={handleSubmit} noValidate autoComplete='off'>

                          {/* First, put in the Client/Matter */}
                          <Form.Group>
                            <Form.Field required width={14} error={(touched.ClientMatter && isEmptyNullUndefined(values.ClientMatter)) || has(status, 'errors.ClientMatter')}>
                              <label>Client/Matter</label>
                              <Dropdown
                                placeholder='Select Client/Matter'
                                name="ClientMatter"
                                width={6}
                                required
                                search
                                label='Client/Matter'
                                error={(touched.ClientMatter && (typeof get(values, 'ClientMatter') === 'undefined' || get(values, 'ClientMatter') === null)) ||
                                  (has(status, 'errors.ClientMatter'))}
                                closeOnBlur
                                selection
                                value={values.ClientMatter}
                                onBlur={() => { setFieldTouched('ClientMatter', true) }}
                                options={this.state.ClientMatterDropDownArray}
                                // value={this.props.fetch.fetching === true ? null : values.TType}
                                loading={isSubmitting}
                                onChange={async (e, { name, value }) => {
                                  setFieldValue(name, value)
                                  setFieldValue('InvoiceNumber', '')
                                  this.setState({
                                    SelectedClient: value.split(',')[0],
                                    SelectedMatterOriginationDate: this.state.ClientsMatters.find(matter => matter[2] === parseInt(value.split(',')[1]))[5],
                                    SelectedClientName: this.getClientName(value.split(',')[0]),
                                    SelectedMatter: parseInt(value.split(',')[1]),
                                    SelectedEmployeeID: this.state.ClientsMatters.find(row => row[0] === parseInt(value.split(',')[0]))[4]
                                  }, () => {
                                    this.getInvoices(value.split(',')[0])
                                    setFieldValue('PartnerPick', this.state.SelectedEmployeeID)
                                  }
                                  )
                                }}
                              />
                            </Form.Field>
                          </Form.Group>

                          {this.errorTag(errors, touched, status, 'Client', 'Pick a Client. ', 'Pick a Client. ')}

                          {/* Show the rest of this only if there is a client chosen, first */}
                          {!isEmptyNullUndefined(values.ClientMatter) &&
                            <React.Fragment>

                              {/* Selected Client: {this.state.SelectedClient}, Selected Matter: {this.state.SelectedMatter}
                        <br/>
                        Last Used Invoice Number for this client: {this.state.LastUsedInvoiceNumber}
                        <br /> */}


                              <Form.Group>

                                {/* Add the Invoice Number */}
                                <Form.Field width={5}>
                                  <Form.Input
                                    name="InvoiceNumber" type="text"
                                    label='Invoice Number'
                                    loading={isSubmitting}
                                    required
                                    placeholder={this.createInvoiceNumber(values.ClientMatter.split(',')[0])}
                                    error={(errors.InvoiceNumber && touched.InvoiceNumber) || (has(status, 'errors.InvoiceNumber'))}
                                    onChange={async (e) => {
                                      await handleChange(e);
                                      this.setState({ InvoiceNumber: e.target.value })
                                      this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, this.state.ExistingInvoices)
                                    }}
                                    onBlur={(e) => {
                                      handleBlur(e)
                                      if (isEmptyNullUndefined(values.InvoiceNumber)) { values.InvoiceNumber = this.createInvoiceNumber(values.ClientMatter.split(',')[0]) }
                                      setFieldTouched('InvoiceNumber', true)
                                    }}
                                    options={this.state.ClientMatterDropDownArray}

                                    value={values.InvoiceNumber} />
                                  {this.errorTag(errors, touched, status, 'InvoiceNumber')}
                                </Form.Field>
                              </Form.Group>

                              {/* List Out Invoices with Same Name */}
                              <Segment secondary={this.state.Invoice2RegexSpecific.length === 0} color={this.state.Invoice2RegexSpecific.length > 0 ? 'red' : 'blue'}
                                hidden={this.state.Invoice2Regex.length === 0}>
                                {ListMakerSimple(this.state.Invoice2Regex, values, this.state.Invoice2RegexSpecific, 'Invoice')}
                              </Segment>

                              {/* Invoice Creation Date */}
                              <Form.Field required width={7} error={(touched.IDate && isNaN(values.IDate)) || has(status, 'errors.IDate')}>
                                <label>Invoice Creation Date {!isEmptyNullUndefined(this.state.SelectedMatterOriginationDate) ? `(matter originated on ${this.state.SelectedMatterOriginationDate})` : null}</label>
                                <DatePicker
                                  name="IDate"
                                  onSelect={async (date) => {
                                    //console.log('We have selected this date: ', date)
                                    setFieldValue('IDate', date)
                                    setFieldTouched('IDate', true)
                                    //I cannot console.log the new values.IDate here.  I get the old date.  Can't figure out how to make that work.
                                    // console.log('And this is values: ', values.IDate)
                                  }}
                                  dateFormat='MMMM d, yyyy'
                                  minDate={new Date(!isEmptyNullUndefined(this.state.SelectedMatterOriginationDate) ? this.state.SelectedMatterOriginationDate : '2011,01,01')}
                                  maxDate={new Date()}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  withPortal
                                  loading={isSubmitting}
                                  onBlur={() => setFieldTouched('IDate', true)}
                                  todayButton={'Today'}
                                  selected={values.IDate !== 'a' ? values.IDate : null}
                                  placeholderText="Select an origination date"
                                />
                                {this.errorTag(errors, touched, status, 'IDate')}
                              </Form.Field>



                              {/* Invoicing Partner */}
                              <Form.Select
                                placeholder='Select Partner'
                                name="PartnerPick"
                                width={6}
                                required
                                label='Invoicing Partner (typically originating partner)'
                                error={(touched.PartnerPick && (typeof get(values, 'PartnerPick') === 'undefined' || get(values, 'PartnerPick') === null)) ||
                                  (has(status, 'errors.PartnerPick'))}
                                closeOnBlur
                                selection
                                onBlur={() => setFieldTouched('PartnerPick', true)}
                                options={this.employeeDropDownArray()}
                                value={values.PartnerPick}
                                loading={isSubmitting}
                                onChange={(e, { name, value }) => {
                                  setFieldValue(name, value)
                                  //console.log(name, value)
                                }}
                              />

                              {this.errorTag(errors, touched, status, 'PartnerPick')}


                              {/* Amount */}
                              <Form.Field required width={6} error={has(status, 'errors.InvoiceAmount')}>
                                <label>Insert Amount of Invoice (in $)</label>

                                <NumberFormat
                                  name="InvoiceAmount"
                                  label='Invoice Amount'
                                  thousandSeparator={true}
                                  prefix={'$'}
                                  onBlur={() => setFieldTouched('InvoiceAmount', true)}
                                  placeholder='Amount billed...'
                                  value={values.InvoiceAmount}
                                  loading={isSubmitting}
                                  onValueChange={(e) => {
                                    // console.log('This is the value: ', e.floatValue)
                                    setFieldValue('InvoiceAmount', isEmptyNullUndefined(e.floatValue) ? null : e.floatValue)
                                  }} />
                                {this.errorTag(errors, touched, status, 'InvoiceAmount')}
                              </Form.Field>


                              {/* Add the description */}
                              <Form.Field width={14}>
                                <Form.TextArea
                                  name="Description" type="text"
                                  label={`Description for Invoice`}
                                  loading={isSubmitting}
                                  required
                                  placeholder={`Description for Invoice`}
                                  error={(errors.Description && touched.Description) || (has(status, 'errors.Description'))}
                                  onChange={async (e) => {
                                    await handleChange(e);
                                    this.setState({ Description: e.target.value })
                                  }}
                                  onBlur={handleBlur}
                                  value={!isEmptyNullUndefined(values.Description) ? values.Description : ''} />
                                {this.errorTag(errors, touched, status, 'Description')}
                              </Form.Field>

                            </React.Fragment>
                          }

                          <Divider />
                          <Button.Group size='large'>
                            <Button type='button' icon='cancel'
                              onClick={() => {
                                handleReset()
                                let ClientsMatters = this.state.ClientsMatters
                                this.setState(initialState)
                                this.setState({ ClientsMatters: ClientsMatters })
                              }}
                              color='red'
                              content='Cancel'></Button>
                            <Button.Or />
                            <Button color='teal'
                              onClick={() => {
                                setFieldValue('InvoiceNumber', '')
                                setFieldValue('InvoiceAmount', null)
                                setFieldValue('PartnerPick', null)
                                setFieldValue('IDate', 'a')
                                setFieldValue('Description', null)
                                setFieldTouched('InvoiceNumber', false)
                                setFieldTouched('InvoiceAmount', false)
                                setFieldTouched('PartnerPick', false)
                                setFieldTouched('IDate', false)
                                setFieldTouched('Description', false)
                                // setFieldTouched({})
                                this.setState({ Description: null, Matter: '', Invoice2Regex: [], Invoice2RegexSpecific: '' })
                              }}
                              type='button'
                              disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                              content='Clear Form'>
                            </Button>
                            <Button.Or />
                            <Button color='orange'
                              onClick={() => { setStatus({}); setErrors({}) }}
                              type='button'
                              //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                              content='Clear Error Notifications'>
                            </Button>
                            <Button.Or />
                            <Button type='submit'
                              // disabled={isSubmitting}
                              //Eventually use the below, the one above is just for testing
                              disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                              icon='upload'
                              loading={isSubmitting}
                              floated='right'
                              color={dirty ? 'green' : 'grey'}
                              content={(this.props.update === true ? 'Edit' : 'Add') + ' Invoice'}></Button>
                          </Button.Group>
                          {/* <DisplayState state={this.state} /> */}
                          {/* <DisplayFormikState /> */}
                        </Form>

                      </Grid.Column>
                      {this.state.ExistingInvoices.length > 0 ?
                        <Grid.Column width={3}>
                          {this.state.ExistingInvoices.length > 0 ?
                            <InvoiceList key={this.state.ExistingInvoices}
                              ExistingInvoices={this.state.ExistingInvoices}
                              SelectedClientName={this.state.SelectedClientName}
                              SelectedMatter={this.state.SelectedMatter}
                              mattersarray={this.props.clients.mattersarray} /> :
                            null}
                        </Grid.Column> : null}
                    </Grid.Row>
                    {this.state.ExistingInvoices.length > 0 ?
                      <Grid.Row>
                        <Grid.Column width={16}>

                          Existing Invoices for {this.state.SelectedClientName}
                          <br />
                          <span style={{ 'fontStyle': 'italic' }}>
                            (shaded invoices for  {' '}
                            <span style={{ 'color': 'red' }}>
                              {!isEmptyNullUndefined(this.state.SelectedMatter) && this.props.clients.mattersarray.find(row => row[0] === this.state.SelectedMatter)[1]}
                            </span>
                          ):
                        </span>
                          {!isEmptyNullUndefined(this.state.SelectedClient) && <Invoices clientID={this.state.SelectedClient} forAddInvoicesMatter={this.state.SelectedMatter} />}

                        </Grid.Column>
                      </Grid.Row>
                      : null}
                  </Grid>
                </Segment>
              </div>
            )}
        </Formik>


      </React.Fragment>
    )
  }
}

class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showInvoiceDetails: false, selectedInvoice: null };
  }


  ReversedExistingInvoices = [...this.props.ExistingInvoices].reverse()


  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  showInvoiceDetailsToggle = (option) => {
    // console.log('Clicking showInvoiceDetailsToggle and this option: ', option)
    switch (option) {
      case false:
        this.setState({ showInvoiceDetails: false })
        break
      case true:
        this.setState({ showInvoiceDetails: true })
        break
      default:
        this.setState(prevState => ({ showInvoiceDetails: !prevState.showInvoiceDetails }))
    }
  }

  render() {

    return (
      <React.Fragment>
        Existing Invoices for {this.props.SelectedClientName}
        {this.ReversedExistingInvoices.length > 10 ? <React.Fragment><br/>(last 10 invoices)</React.Fragment>: null}
        <br />
        <span style={{ 'fontStyle': 'italic' }}>
          (starred invoices for  {' '}
          <span style={{ 'color': 'red' }}>
            {!isEmptyNullUndefined(this.props.SelectedMatter) && this.props.mattersarray.find(row => row[0] === this.props.SelectedMatter)[1]}
          </span>
          ):
        </span>
        <br />
        <List selection>
          {this.ReversedExistingInvoices.slice(-10).map((invoice, index) => {
            let amount = this.formatter.format(invoice[2])
            return (
              <List.Item key={index}

                onClick={() => {
                  // console.log('Clicked on this one: ', invoice[0])
                  this.setState({ selectedInvoice: invoice[0] })
                  if (this.state.selectedInvoice === invoice[0]) this.showInvoiceDetailsToggle()
                  else this.showInvoiceDetailsToggle(true)
                }}>
                {this.props.SelectedMatter === invoice[5] ?
                  <span style={{ 'color': 'red' }}>
                    <List.Content>
                      <List.Icon name={this.state.showInvoiceDetails && this.state.selectedInvoice === invoice[0] ? 'minus' : 'plus'} size='small' />
                      {invoice[1]}*
                   </List.Content>
                  </span> :
                  <List.Content>
                    <List.Icon name={this.state.showInvoiceDetails && this.state.selectedInvoice === invoice[0] ? 'minus' : 'plus'} size='small' />
                    {invoice[1]}
                  </List.Content>
                }

                {this.state.showInvoiceDetails && this.state.selectedInvoice === invoice[0] ?
                  <List bulleted>
                    <List.Item>{invoice[3]}</List.Item>
                    <List.Item>{amount}</List.Item>
                    <List.Item>{invoice[4]}</List.Item>
                  </List> :
                  null
                }
              </List.Item>)
          }
          )
          }
        </List>
      </React.Fragment>
    )
  }
}


const AddInvoiceSchema = Yup.object().shape({
  InvoiceNumber: Yup
    .string()
    .min(4, 'Invoice Number must be at least 4 characters.')
    .max(12, 'Invoice Number cannot exceed 12 characters.')
    .required('Must have an Invoice Number.'),
  //Fix:  Can't figure out why I can't get date to validate.  Just letting JOI handle it for now.
  // IDate: Yup
  // .date()
  // .required()
  // .typeError('No date selected.'),
  InvoiceAmount: Yup
    .number()
    .nullable()
    .min(0)
    .max(10000000000)
    .required(),
  PartnerPick: Yup
    .number()
    .required()
    .typeError('Must pick an invoicing partner.'),
  Description: Yup
    .string()
    .min(3, 'Description must be at least 3 characters.')
    .max(10000, 'Description cannot exceed 10,000 characters.')
    .required(),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(AddInvoice)

AddInvoice.defaultProps = {
  getLawyers: () => { },
  getTycoons: () => { },
  getNotes: () => { },
  headingForForm: '',
}


