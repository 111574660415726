
const yellow200 = "#FFF59D";
const deepOrange600 = "#F4511E";
const lime300 = "#DCE775";
const lightGreen500 = "#8BC34A";
const teal700 = "#00796B";
const cyan900 = "#006064";
const pinkish = "#ffc6d9";
const greyish = "#d2d2d2";
const blueish = "#19c5dc";
const greenish = "#42b76b";
const brightgreen = "#86eb2b";
const purpleish = "#c2b4e2";
const default1 = "#c3c3c3";
const colorScheme = [
  yellow200,
  deepOrange600,
  cyan900,
  lime300,
  lightGreen500,
  teal700,
  pinkish,
  greyish,
  blueish,
  greenish,
  brightgreen,
  purpleish,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
  default1,
]

module.exports = colorScheme