import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { isEmptyNullUndefined } from '../helper/Misc'

import ReactTableMaker from '../helper/ReactTableMaker'

import { Segment, Checkbox, Loader, Dimmer, Image, Icon } from 'semantic-ui-react';
import './Component-Dashboard.css'
// import { level } from 'winston';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

const pageSize = 5

class Dashboard extends Component {

  state = {
    ClientsForEmployee: [],
    MattersForEmployee: [],
    InvoicesForEmployee: [],
    PaymentsForEmployee: [],
    LawFirmsForEmployee: [],
    CounterpartiesForEmployee: [],
    LawyersForEmployee: [],
    TycoonsForEmployee: [],
    Loading: false,
    LoadingClients: false,
    LoadingMatters: false,
    LoadingInvoices: false,
    LoadingPayments: false,
    LoadingLawFirms: false,
    LoadingCounterparties: false,
    LoadingLawyers: false,
    LoadingTycoons: false,
    Originating: true
  }

  async componentDidMount() {
    this.update()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.EmployeeID !== this.props.EmployeeID) {
      this.update()
    }
    if (prevState.Originating !== this.state.Originating) {
      this.getEmployeeInvoices()
      this.getEmployeePayments()
    }
  }

  update = async () => {
    this.props.fetchResetState('all')
    this.setState({
      Loading: true,
      LoadingClients: true,
      LoadingMatters: true,
      LoadingInvoices: this.props.login.currentShowFinances ? false : true,
      LoadingPayments: this.props.login.currentShowFinances ? false : true,
      LoadingLawFirms: true,
      LoadingCounterparties: true,
      LoadingLawyers: true,
      LoadingTycoons: true,
    })
    this.getDashboardInfo()

    //Can't use the below because of the cursed max limit on simultaneous connections in Heroku's DB (even with timeout hack)
    // this.getEmployeeClients()
    // this.getEmployeeMatters()
    // this.getEmployeeInvoices()
    // setTimeout(this.getEmployeePayments(), 1800)
    // setTimeout(this.getEmployeeCounterparties(), 800)
    // setTimeout(this.getEmployeeLawFirms(), 800)
    // setTimeout(this.getEmployeeLawyers(), 800)
    // setTimeout(this.getEmployeeTycoons(), 800)



  }

  checkLoading = () => {
    !this.state.LoadingClients && !this.state.LoadingMatters && !this.state.LoadingInvoices && !this.state.LoadingPayments && !this.state.LoadingLawFirms
      && !this.state.LoadingCounterparties && !this.state.LoadingLawyers && !this.state.LoadingTycoons && this.setState({ Loading: false })
  }

  getDashboardInfo = async () => {
    // console.log('Running getEmployeeClients....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('dashboardFetchAll', { fetchstr: 'none', EmployeeID: this.props.EmployeeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getDashboardInfo: ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {

      //ClientsForEmployee
      !isEmptyNullUndefined(fetchresponse.payload.ClientsForEmployee) && this.setState({
        ClientsForEmployee: fetchresponse.payload.ClientsForEmployee,
        LoadingClients: false
      }, () => this.checkLoading())
      isEmptyNullUndefined(fetchresponse.payload.ClientsForEmployee) && this.setState({ ClientsForEmployee: [], LoadingClients: false }, () => this.checkLoading())

      //MattersForEmployee
      !isEmptyNullUndefined(fetchresponse.payload.MattersForEmployee) && this.setState({
        MattersForEmployee: fetchresponse.payload.MattersForEmployee,
        LoadingMatters: false
      }, () => this.checkLoading())
      isEmptyNullUndefined(fetchresponse.payload.MattersForEmployee) && this.setState({ MattersForEmployee: [], LoadingMatters: false }, () => this.checkLoading())

      //InvoicesForEmployee
      !isEmptyNullUndefined(fetchresponse.payload.InvoicesForEmployee) && this.setState({
        InvoicesForEmployee: fetchresponse.payload.InvoicesForEmployee, LoadingInvoices: false
      }, () => this.checkLoading())
      isEmptyNullUndefined(fetchresponse.payload.InvoicesForEmployee) && this.setState({ InvoicesForEmployee: [], LoadingInvoices: false }, () => this.checkLoading())

      //PaymentsForEmployee
      !isEmptyNullUndefined(fetchresponse.payload.PaymentsForEmployee) && this.setState({
        PaymentsForEmployee: fetchresponse.payload.PaymentsForEmployee, LoadingPayments: false
      }, () => this.checkLoading())
      isEmptyNullUndefined(fetchresponse.payload.PaymentsForEmployee) && this.setState({ PaymentsForEmployee: [], LoadingPayments: false }, () => this.checkLoading())

      //LawFirmsForEmployee
      !isEmptyNullUndefined(fetchresponse.payload.LawFirmsForEmployee) && this.setState({
        LawFirmsForEmployee: fetchresponse.payload.LawFirmsForEmployee, LoadingLawFirms: false
      }, () => this.checkLoading())
      isEmptyNullUndefined(fetchresponse.payload.LawFirmsForEmployee) && this.setState({ LawFirmsForEmployee: [], LoadingLawFirms: false }, () => this.checkLoading())

      //CounterpartiesForEmployee
      !isEmptyNullUndefined(fetchresponse.payload.CounterpartiesForEmployee) && this.setState({
        CounterpartiesForEmployee: fetchresponse.payload.CounterpartiesForEmployee, LoadingCounterparties: false
      }, () => this.checkLoading())
      isEmptyNullUndefined(fetchresponse.payload.CounterpartiesForEmployee) && this.setState({ CounterpartiesForEmployee: [], LoadingCounterparties: false }, () => this.checkLoading())

      //LawyersForEmployee
      !isEmptyNullUndefined(fetchresponse.payload.LawyersForEmployee) && this.setState({
        LawyersForEmployee: fetchresponse.payload.LawyersForEmployee, LoadingLawyers: false
      }, () => this.checkLoading())
      isEmptyNullUndefined(fetchresponse.payload.LawyersForEmployee) && this.setState({ LawyersForEmployee: [], LoadingLawyers: false }, () => this.checkLoading())

      //TycoonsForEmployee
      !isEmptyNullUndefined(fetchresponse.payload.TycoonsForEmployee) && this.setState({
        TycoonsForEmployee: fetchresponse.payload.TycoonsForEmployee, LoadingTycoons: false
      }, () => this.checkLoading())
      isEmptyNullUndefined(fetchresponse.payload.TycoonsForEmployee) && this.setState({ TycoonsForEmployee: [], LoadingTycoons: false }, () => this.checkLoading())
    }
  }

  getEmployeeClients = async () => {
    // console.log('Running getEmployeeClients....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getEmployee', { fetchstr: 'getEmployeeClients', EmployeeID: this.props.EmployeeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getEmployeeClients: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      ClientsForEmployee: fetchresponse.payload,
      LoadingClients: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ ClientsForEmployee: [], LoadingClients: false }, () => this.checkLoading())
  }

  getEmployeeMatters = async () => {
    // console.log('Running getEmployeeClients....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getEmployee', { fetchstr: 'getEmployeeMatters', EmployeeID: this.props.EmployeeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getEmployeeMatters: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      MattersForEmployee: fetchresponse.payload,
      LoadingMatters: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ MattersForEmployee: [], LoadingMatters: false }, () => this.checkLoading())
  }

  getEmployeeInvoices = async () => {
    // console.log('Running getEmployeeClients....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getEmployee', { fetchstr: this.state.Originating ? 'getEmployeeInvoicesOriginating' : 'getEmployeeInvoicesInvoicing', EmployeeID: this.props.EmployeeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getEmployeeMatters: ', fetchresponse.payload)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      InvoicesForEmployee: fetchresponse.payload, LoadingInvoices: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ InvoicesForEmployee: [], LoadingInvoices: false }, () => this.checkLoading())
  }

  getEmployeePayments = async () => {
    // console.log('Running getEmployeeClients....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getEmployee', { fetchstr: this.state.Originating ? 'getEmployeePaymentsOriginating' : 'getEmployeePaymentsInvoicing', EmployeeID: this.props.EmployeeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getEmployeeMatters: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      PaymentsForEmployee: fetchresponse.payload, LoadingPayments: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ PaymentsForEmployee: [], LoadingPayments: false }, () => this.checkLoading())
  }

  getEmployeeLawFirms = async () => {
    // console.log('Running getEmployeeLawFirms....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getEmployee', { fetchstr: 'getEmployeeLawFirms', EmployeeID: this.props.EmployeeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getEmployeeLawFirms: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      LawFirmsForEmployee: fetchresponse.payload, LoadingLawFirms: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ LawFirmsForEmployee: [], LoadingLawFirms: false }, () => this.checkLoading())
  }

  getEmployeeCounterparties = async () => {
    // console.log('Running getEmployeeLawFirms....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getEmployee', { fetchstr: 'getEmployeeCounterparties', EmployeeID: this.props.EmployeeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getEmployeeLawFirms: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      CounterpartiesForEmployee: fetchresponse.payload, LoadingCounterparties: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ CounterpartiesForEmployee: [], LoadingCounterparties: false }, () => this.checkLoading())
  }

  getEmployeeLawyers = async () => {
    // console.log('Running getEmployeeLawyers....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getEmployee', { fetchstr: 'getEmployeeLawyers', EmployeeID: this.props.EmployeeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getEmployeeLawyers: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      LawyersForEmployee: fetchresponse.payload, LoadingLawyers: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ LawyersForEmployee: [], LoadingLawyers: false }, () => this.checkLoading())
  }

  getEmployeeTycoons = async () => {
    // console.log('Running getEmployeeTycoons....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getEmployee', { fetchstr: 'getEmployeeTycoons', EmployeeID: this.props.EmployeeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getEmployeeTycoons: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      TycoonsForEmployee: fetchresponse.payload, LoadingTycoons: false
    }, () => this.checkLoading())
    isEmptyNullUndefined(fetchresponse.payload) && this.setState({ TycoonsForEmployee: [], LoadingTycoons: false }, () => this.checkLoading())
  }

  render() {

    let checkboxStyle = { paddingRight: 30, paddingTop: 10, paddingBottom: 10 }

    return (
      <React.Fragment>
        {this.state.Loading ?
          <Segment size='massive'>
            <Dimmer active>
              <Loader content='Loading' />
            </Dimmer>

            {/* //Fix:  I need to do this for now because webpack doesn't handle images well, but eventually replace
        //this with a weblink as in the commented bit below.  */}
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            <Image src={window.location.origin + '/images/short-paragraph.png'} />
            {/* <Image src={`./images/${props.login.currentIcon}`} verticalAlign='bottom' width='50px' circular  /> */}
          </Segment>
          :
          <React.Fragment>

            {/* CLIENT-MATTER SECTION */}
            <div className='dashboard-header'>
              <h3>Client/Matter Information:</h3>
            </div>
            <div className='dashboard-container'>
              <div className='dashboard-item1'>
                {!isEmptyNullUndefined(this.state.ClientsForEmployee) ?
                  <Segment compact raised style={{ backgroundColor: '#e8f3ff' }}>
                    <React.Fragment>
                      <h4>
                        <Icon name='chess queen' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/clients`)} />
                        {this.state.ClientsForEmployee.length > pageSize ? '5 ' : null}
                        Most Recent Clients:
                        </h4>
                      <ReactTableMaker passedData={this.state.ClientsForEmployee} passedColumns={ClientsColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                        includeSearchBar={false}
                        includeDensePaginationFooter={true}
                        color='blue'
                        passedPageSize={pageSize}
                      />
                    </React.Fragment>
                  </Segment>
                  :
                  <h4>No clients to show...</h4>
                }
              </div>
              <div className='dashboard-item2'>
                {!isEmptyNullUndefined(this.state.MattersForEmployee) ?
                  <React.Fragment>
                    <Segment compact raised style={{ backgroundColor: '#ebebeb' }}>
                      <h4><Icon name='chess bishop' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/matters`)} />{this.state.MattersForEmployee.length > pageSize ? '5 ' : null}Most Recent Matters:</h4>
                      <ReactTableMaker passedData={this.state.MattersForEmployee} passedColumns={MattersColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                        includeSearchBar={false}
                        includeDensePaginationFooter={true}
                        color='grey'
                        passedPageSize={pageSize}
                      />
                    </Segment>
                  </React.Fragment>
                  :
                  <h4>No matters to show...</h4>
                }
              </div>
            </div>

            {/* LAW FIRM/LAWYER SECTION */}
            <div className='dashboard-header'>
              <h3>Law Firm/Lawyer Information:</h3>
            </div>
            <div className='dashboard-container'>
              <div className='dashboard-item1'>
                {!isEmptyNullUndefined(this.state.LawFirmsForEmployee) ?
                  <React.Fragment>
                    <Segment compact raised style={{ backgroundColor: '#f4eaff' }}>
                      <h4><Icon name='balance scale' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/matters`)} />{this.state.LawFirmsForEmployee.length > pageSize ? '5 ' : null}Most Frequent Law Firms Faced:</h4>
                      <ReactTableMaker passedData={this.state.LawFirmsForEmployee} passedColumns={LawFirmsColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                        includeSearchBar={false}
                        includeDensePaginationFooter={true}
                        color='purple'
                        passedPageSize={pageSize}
                      />
                    </Segment>
                  </React.Fragment>
                  :
                  <h4>No law firms to show...</h4>
                }
              </div>
              <div className='dashboard-item1'>
                {!isEmptyNullUndefined(this.state.CounterpartiesForEmployee) ?
                  <React.Fragment>
                    <Segment compact raised style={{ backgroundColor: '#ffece3' }}>
                      <h4><Icon name='address book outline' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/counterparties`)} />{this.state.CounterpartiesForEmployee.length > pageSize ? '5 ' : null}Most Frequent Counterparties Faced:</h4>
                      <ReactTableMaker passedData={this.state.CounterpartiesForEmployee} passedColumns={CounterpartiesColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                        includeSearchBar={false}
                        includeDensePaginationFooter={true}
                        color='orange'
                        passedPageSize={pageSize}
                      />
                    </Segment>
                  </React.Fragment>
                  :
                  <h4>No counterparties to show...</h4>
                }
              </div>
              <div className='dashboard-item2-0'>
                {!isEmptyNullUndefined(this.state.LawyersForEmployee) ?
                  <React.Fragment>
                    <Segment compact raised style={{ backgroundColor: '#ecfff9' }}>
                      <h4><Icon name='male' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/persons`)} />{this.state.LawyersForEmployee.length > pageSize ? '5 ' : null}Most Frequent Lawyers Faced:</h4>
                      <ReactTableMaker passedData={this.state.LawyersForEmployee} passedColumns={PersonsColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                        includeSearchBar={false}
                        includeDensePaginationFooter={true}
                        color='teal'
                        passedPageSize={pageSize}
                      />
                    </Segment>
                  </React.Fragment>
                  :
                  <h4>No lawyers to show...</h4>
                }
              </div>
              <div className='dashboard-item2-0'>
                {!isEmptyNullUndefined(this.state.TycoonsForEmployee) ?
                  <React.Fragment>
                    <Segment compact raised style={{ backgroundColor: '#ffffdd' }}>
                      <h4><Icon name='male' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/persons`)} />{this.state.TycoonsForEmployee.length > pageSize ? '5 ' : null}Most Frequent Businessmen Faced:</h4>
                      <ReactTableMaker passedData={this.state.TycoonsForEmployee} passedColumns={PersonsColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                        includeSearchBar={false}
                        includeDensePaginationFooter={true}
                        color='yellow'
                        passedPageSize={pageSize}
                      />
                    </Segment>
                  </React.Fragment>
                  :
                  <h4>No businessmen to show...</h4>
                }
              </div>
            </div>

            {/* FINANCING STUFF */}
            {this.props.login.currentShowFinances &&
              <React.Fragment>
                <div className='dashboard-header'>
                  <h3>Financial Information:</h3>
                  <Checkbox
                    toggle
                    style={checkboxStyle}
                    label={this.state.Originating ? 'Originating Basis' : 'Invoicing Basis'}
                    // value={this.state}
                    checked={this.state.Originating}
                    onChange={() => { this.setState((prevState) => ({ Originating: !prevState.Originating, Loading: true, LoadingInvoices: true, LoadingPayments: true })) }}
                  />
                </div>
                <div className='dashboard-container'>
                  <div className='dashboard-item2-0'>
                    {!isEmptyNullUndefined(this.state.InvoicesForEmployee) ?
                      <React.Fragment>
                        <Segment compact raised style={{ backgroundColor: '#e8ffe8' }}>
                          <h4><Icon name='dollar sign' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/invoices`)} />{this.state.InvoicesForEmployee.length > pageSize ? '5 ' : null}Most Recent Invoices:</h4>
                          <ReactTableMaker passedData={this.state.InvoicesForEmployee} passedColumns={InvoicesColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                            includeSearchBar={false}
                            includeDensePaginationFooter={true}
                            color='green'
                            passedPageSize={pageSize}
                          />
                        </Segment>
                      </React.Fragment>
                      :
                      <h4>No invoices to show...</h4>
                    }
                  </div>
                  <div className='dashboard-item2'>
                    {!isEmptyNullUndefined(this.state.PaymentsForEmployee) ?
                      <React.Fragment>
                        <Segment compact raised style={{ backgroundColor: '#ffe8e9' }}>
                          <h4><Icon name='money bill alternate outline' size='small' style={{ cursor: 'pointer', float: 'right' }} circular onClick={() => this.props.history.push(`/payments`)} />{this.state.PaymentsForEmployee.length > pageSize ? '5 ' : null}Most Recent Payments:</h4>
                          <ReactTableMaker passedData={this.state.PaymentsForEmployee} passedColumns={PaymentsColumns(this.props.clients.employeesarray, this.props.history, this.props.login.currentShowFinances)}
                            includeSearchBar={false}
                            includeDensePaginationFooter={true}
                            color='red'
                            passedPageSize={pageSize}
                          />
                        </Segment>
                      </React.Fragment>
                      :
                      <h4>No payments to show...</h4>
                    }
                  </div>
                </div>
              </React.Fragment>

            }

          </React.Fragment>

        }

        {/* <DisplayState state={this.state} /> */}

      </React.Fragment >
    )
  }
}
const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))

Dashboard.defaultProps = {
  EmployeeID: null,
}


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
// function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
// function minusFontColor(e) { e.target.style.color = "" }

let ClientsColumns = (employees, history, showFinances) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Origination Date</span>,
      accessor: 'origination_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },

  ]
}

let MattersColumns = (employees, history, showFinances) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
      accessor: 'matter_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row, rows }) => !isEmptyNullUndefined(rows) &&
        <React.Fragment>
          <span
            onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
            style={{ 'float': 'left', cursor: 'pointer' }}
            onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
          >
            {cell.value}
          </span>
          {!isEmptyNullUndefined(rows[row.index]) && <span style={{ 'float': 'right', fontSize: 'x-small', paddingLeft: '15px' }}>{rows[row.index].original.matter_notes_total > 0 ? ` (${rows[row.index].original.matter_notes_total} notes for matter)` : null}</span>}
        </React.Fragment>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer', color: 'grey' }}
          onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        >
          ({cell.value})
      </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter<br />Origination Date</span>,
      accessor: 'matter_origination_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },
  ]
}

let InvoicesColumns = (employees, history, showFinances) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Number</span>,
      accessor: 'invoice_number',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/invoice/${row.original.invoice_id}?invoice=${row.original.invoice_number}&invoiceID=${row.original.invoice_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Date</span>,
      accessor: 'invoice_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'right' }}>Invoice Amount</span>,
      accessor: 'invoice_amount',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'color': 'green', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
    },
  ]

}

let PaymentsColumns = (employees, history, showFinances) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Payment Number</span>,
      accessor: 'payment_number',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/payment/${row.original.payment_id}?payment=${row.original.payment_number}&paymentID=${row.original.payment_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'right' }}>Payment Amount</span>,
      accessor: 'payment_amount',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'color': 'red', 'float': 'right' }}>({new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)})</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Payment Date</span>,
      accessor: 'payment_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>(For Invoice Number)</span>,
      accessor: 'invoice_number',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/invoice/${row.original.invoice_id}?invoice=${row.original.invoice_number}&invoiceID=${row.original.invoice_id}`)}
        >
          {cell.value}
        </span>
    },
  ]
}

let CounterpartiesColumns = (employees, history, showFinances) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'counterparty',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => {
            // console.log('Yo, this is the row: ', row)
            history.push(`/counterparty/${cell.value}?counterparty=${cell.value}&counterpartyID=${row.original.counterparty_id}`)
          }}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'right' }}>Number<br />Matters</span>,
      accessor: 'deals_involving_firm',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{cell.value}</span>
    },
  ]
}

let LawFirmsColumns = (employees, history, showFinances) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
      accessor: 'law_firm',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row, rows }) => !isEmptyNullUndefined(rows) &&
        <React.Fragment>

          <span
            onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
            style={{ 'float': 'left', cursor: 'pointer' }}
            onClick={() => history.push(`/lawfirm/${cell.value}?lawfirm=${cell.value}&lawFirmID=${row.original.law_firm_id}`)}
          // onClick={() => console.log('Yo, this is the value: ', row)}
          >
            {cell.value}
          </span>
          {/* {!isEmptyNullUndefined(rows[row.index]) && <span style={{ paddingLeft: '15px' }}>{rows[row.index].original.counterparties_advised > 0 ? ` (Advise ${rows[row.index].original.counterparties_advised} counterparties)` : null}</span>}
          {!isEmptyNullUndefined(rows[row.index]) && <span style={{ paddingLeft: '15px' }}>{rows[row.index].original.notes_for_law_firm > 0 ? ` (${rows[row.index].original.notes_for_law_firm} notes entered)` : null}</span>} */}
        </React.Fragment>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'right' }}>Number<br />Matters</span>,
      accessor: 'deals_involving_firm',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{cell.value}</span>
    },
  ]
}

let PersonsColumns = (employees, history, showFinances) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Person Name</span>,
      accessor: 'person_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/person/${cell.value}?person=${cell.value}&personID=${row.original.person_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Person Email</span>,
      accessor: 'person_email',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/person/${row.original.person_name}?person=${row.original.person_name}&personID=${row.original.person_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'right' }}>Number<br />Matters</span>,
      accessor: 'person_count',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{cell.value}</span>
    },
  ]
}
