import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { isEmptyNullUndefined, drawArrow } from '../helper/Misc'

// import { Divider, Dropdown, Header, Grid, Checkbox, Segment } from 'semantic-ui-react';


// import DisplayState from '../helper/DisplayState';

import { VictoryBar, VictoryAxis, VictoryTooltip, VictoryScatter, VictoryLabel, VictoryArea } from 'victory'

import colorScheme from './colorScheme'


let generateQuarters = (beginDate, endDate) => {
  let months = [0, 1, 4, 7, 10]
  // console.log(`This is beginDate: ${beginDate}, and this is endDate: ${endDate}`)
  let getQuarter = (date) => Math.ceil((date.getMonth() + 1) / 3)

  let beginYear = beginDate.getFullYear()
  let endYear = endDate.getFullYear()
  let beginQuarter = getQuarter(beginDate)
  let endQuarter = getQuarter(endDate)
  // console.log(`beginYear: ${beginYear};  endYear: ${endYear}; beginQuarter: ${beginQuarter}; endQuarter: ${endQuarter}`)

  let quarterlist = []
  while (beginYear !== endYear || beginQuarter !== endQuarter) {
    quarterlist.push(new Date(beginYear, months[beginQuarter] - 1, 1));
    beginQuarter++;
    if (beginQuarter > 4) {
      beginQuarter = 1;
      beginYear++;
    }
    if (beginYear === endYear && beginQuarter === endQuarter)
      quarterlist.push(new Date(beginYear, months[beginQuarter] - 1, 1));
    // console.log(`beginYear: ${beginYear};  endYear: ${endYear}; beginQuarter: ${beginQuarter}; endQuarter: ${endQuarter}`)
  }

  // quarterlist.push(new Date(`${endYear}-12-31`))
  return quarterlist
}

class ChartsLineIndividualSVG extends Component {

  state = {
    TokenReady: false,
    loading: true,
    employees: [],
    selectedBarPoints: [],
    TotalInvoices: [],
  }

  today = new Date()
  finalDate = this.today.setMonth(this.today.getMonth() + 1)


  async componentDidMount() {
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      !isEmptyNullUndefined(this.props.fetchObject) && this.reducePartners()
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.loading) {
      this.setState({ TokenReady: true })
    }
    if (prevProps.fetchObject !== this.props.fetchObject || prevProps.selectedYear !== this.props.selectedYear || prevProps.selectedPartner !== this.props.selectedPartner  || prevProps.byInvoicingEmployee !== this.props.byInvoicingEmployee) {
      this.reducePartners()
    }
  }


  reducePartners = async () => {
    // console.log('Launching reducePartners...')
    !isEmptyNullUndefined(this.props.fetchObject) && this.setState({
      employees: this.createEmployees(JSON.parse(JSON.stringify(this.props.fetchObject))),
      selectedBarPoints: this.createBarPointsForPartner(JSON.parse(JSON.stringify(this.props.fetchObject)))
    }, () => this.setState({
      TotalInvoices: this.state.employees.reduce((acc, result) => {
        return acc + result.invoices[result.invoices.length - 1].y
      }, 0
      )
    }))
    this.setState({ loading: false })
  }

  //NOTE!! THIS FUNCTION IS RUNNING OFF OF THE INVOICING_EMPLOYEE_ID, NOT THE EMPLOYEE_ID

  createPartnerInvoices = (partnerID = null, array = null) => {
    let cumul = 0
    let priorcumul = 0
    // console.log('In createPartnerInvoices and this is partnerID: ', partnerID)
    // console.log('In createPartnerInvoices and this is array: ', array)
    let returnArray = array.reduce((acc, item, i, array) => {
      // if (item.invoice_date > new Date(`${this.props.selectedYear}-01-02`) && item.invoice_date < new Date(`${this.props.selectedYear}-12-31`)) {
      // if (item.invoice_date > new Date(`2014-01-02`) && item.invoice_date < new Date(`2020-12-31`)) {
      let arr = {}
      if (partnerID === item[this.props.byInvoicingEmployee ? 'invoicing_employee_id' : 'employee_id'] &&
        (this.props.selectedYear === 0 || (new Date(item.date) > new Date(`${this.props.selectedYear - 1}-01-01`) && new Date(item.date) < new Date(`${this.props.selectedYear - 1}-12-31`)))
      ) {
        priorcumul += parseFloat(item.amount)
        // console.log('We got a hit on this amount: ', item.amount)
      }
      if (this.props.selectedYear === 0 || (new Date(item.date) > new Date(`${this.props.selectedYear}-01-01`) && new Date(item.date) < new Date(`${this.props.selectedYear}-12-31`))) {
        if (partnerID === item[this.props.byInvoicingEmployee ? 'invoicing_employee_id' : 'employee_id']) {
          // console.log('This is item: ', (item))
          cumul += parseFloat(item.amount)
          arr.y = cumul
          arr.x = new Date(Date.parse(item.date))
          arr.invoicing_employee_id = item[this.props.byInvoicingEmployee ? 'invoicing_employee_id' : 'employee_id']
          arr.invoice_number = item.invoice_number
          arr.client = item.client_name
          acc.push(arr)
        }
      }
      // console.log('This is acc: ', acc)
      return acc
    }, [])

    //Get earliest date of all invoices

    //Now add a January 1 point
    let arr = {}
    arr.y = 0
    // console.log('This is returnArray at this point: ', returnArray)
    arr.x = this.props.selectedYear !== 0 ? new Date(`${this.props.selectedYear}-01-01`) : new Date(array[0].date)
    arr.invoicing_employee_id = partnerID
    arr.client = array[0].client_name
    arr.invoice_number = ''
    returnArray.unshift(arr)

    if (this.props.selectedYear !== this.props.currentYear && this.props.selectedYear !== 0) {
      //Now add a December 31 point if we're not in the current year
      let arr1 = {}
      arr1.y = returnArray[returnArray.length - 1].y
      arr1.x = new Date(`${this.props.selectedYear}-12-31`)
      arr1.invoicing_employee_id = partnerID
      arr1.client = array[0].client_name
      arr1.invoice_number = ''
      returnArray.push(arr1)
    }

    // console.log('This is createPartnerInvoices returnArray: ', returnArray)
    return [returnArray, priorcumul]
  }

  daysIntoYear = (date) => {
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
  }
  daysBetweenDates = (date1, date2) => {
    // get total seconds between two dates
    var res = Math.abs(date1 - date2) / 1000;
    var days = Math.floor(res / 86400)
    return days
  }
  createEmployees = (payload) => {
    // console.log('In createEmployees..')
    let employees = []
    employees = this.props.clients.employeesarray.map((item, i) => {
      let invoices = this.createPartnerInvoices(item[0], payload)
      // console.log(invoices)
      var employee = {}
      employee.id = item[0]
      employee.name = item[1] + ' ' + item[2]
      employee.color = colorScheme[i]
      employee.invoices = invoices[0]
      employee.priorTotal = invoices[1]
      employee.quarters = generateQuarters(employee.invoices[0].x, new Date(this.finalDate))
      employee.total = employee.invoices[employee.invoices.length - 1].y
      let lastDate = employee.quarters[employee.quarters.length - 1]
      let lastInvoiceDate = this.daysBetweenDates(employee.invoices[employee.invoices.length - 1].x, lastDate)
      let firstInvoiceDate = this.daysBetweenDates(employee.invoices[0].x, lastDate)
      let percentage = employee.total === 0 ? 1 : 1 - lastInvoiceDate / firstInvoiceDate
      // console.log(`For ${employee.name} the first date is: ${firstInvoiceDate} and the last date is ${lastInvoiceDate} and percentage is ${percentage}`)
      employee.yearPercentage = this.props.selectedYear !== 0 ? this.daysIntoYear(new Date(employee.invoices[employee.invoices.length - 1].x)) / 365 : percentage
      return employee
    }
    )
    return employees
  }

  //This takes out any employee that invoiced nothing
  reducedEmployees = (earray) => {
    let reducedEmployees = earray.reduce((acc, result) => {
      if (result.invoices[result.invoices.length - 1].y > 0) {
        acc.push(result)
      }
      return acc
    }, []
    )
    return reducedEmployees
  }

  createBarPointsForPartner = (array) => {
    let returnArray = array.reduce((acc, result, i) => {
      // console.log('This is result: ', result)
      if (
        (this.props.selectedPartner === 0 || result[this.props.byInvoicingEmployee ? 'invoicing_employee_id' : 'employee_id'] === this.props.selectedPartner)
        &&
        (this.props.selectedYear === 0 || (new Date(result.date) > new Date(`${this.props.selectedYear}-01-01`) && new Date(result.date) < new Date(`${this.props.selectedYear}-12-31`)))
      ) {
        let obj = {}
        obj.key = `BGA${i}`
        obj.x = result.date
        obj.y = result.amount
        obj.invoice_number = result.invoice_number
        acc.push(obj)
        // console.log('This is acc: ', acc)
      }
      return acc
    }, []
    )
    return returnArray
  }

  render() {

    // let checkboxStyle = { paddingRight: 30, paddingTop: 10, paddingBottom: 10 }
    // let xTickValues2 = [new Date(`${this.props.selectedYear}-01-02`), new Date(`${this.props.selectedYear}-03-31`), new Date(`${this.props.selectedYear}-06-31`), new Date(`${this.props.selectedYear}-09-31`), new Date(`${this.props.selectedYear}-12-31`)]
    let xTickValues = this.props.selectedYear !== 0 ?
      [new Date(`${this.props.selectedYear}-01-02`), new Date(`${this.props.selectedYear}-03-31`), new Date(`${this.props.selectedYear}-06-31`), new Date(`${this.props.selectedYear}-09-31`), new Date(`${this.props.selectedYear}-12-31`)]
      :
      !isEmptyNullUndefined(this.state.employees) ? this.state.employees[this.props.selectedPartner - 1].quarters : []

    const BLUE_COLOR = "#00a3de"
    const RED_COLOR = "#7c270b"


    let getPath = (from, to, lineWidth, arrowheadWidth, arrowheadLength) => {
      let path = drawArrow(from, to, lineWidth, arrowheadWidth, arrowheadLength)
      let pathd = path.getAttribute('d')
      let pathtransform = path.getAttribute('transform')
      let pathclass = path.getAttribute('class')
      return <path d={pathd} transform={pathtransform} className={pathclass} />
    }
    let renderSVG = (from, to, lineWidth, arrowheadWidth, arrowheadLength) => {
      // console.log(from, to)
      return (
        <svg id="test" className={'arrow-line'} stroke='black' fill={RED_COLOR}>
          {getPath(from, to, lineWidth, arrowheadWidth, arrowheadLength)}
        </svg>
      )
    }

    return (

      <svg
        viewBox="-25 -25 600 350">

        <g width="0" height="0">
          <defs>
            <linearGradient id="gradient1"
              x1="0%" y1="0%" x2="0%" y2="100%"
            >
              <stop offset="0%" stopColor="orange" />
              <stop offset="50%" stopColor="red" />
              <stop offset="100%" stopColor="#990000" />
            </linearGradient>
            <linearGradient id="gradient2"
              x1="0%" y1="0%" x2="0%" y2="100%"
            >
              {/* <stop offset="0%" stopColor="#b5ffcf" /> */}
              <stop offset="0%" stopColor="#e4e4e4" />
              <stop offset="100%" stopColor="white" />
            </linearGradient>
          </defs>
        </g>

        <VictoryLabel x='40%' y={20} style={{
          textAnchor: "middle",
          verticalAnchor: "end",
          fill: "#000000",
          fontFamily: "inherit",
          fontSize: "12px",
          fontWeight: "bold"
        }}
          text={`Invoices Sent by Partner ${!isEmptyNullUndefined(this.state.employees) ? '(' + this.state.employees[this.props.selectedPartner - 1].name + ')' : ''}`}
        />

        {(isEmptyNullUndefined(this.state.employees) || this.state.employees[this.props.selectedPartner - 1].total <= 0) &&
          <VictoryLabel
            text={`(No Invoices Billed Yet in ${this.props.selectedYear})`}
            style={{
              fill: '#404040',
              fontFamily: "inherit",
              fontSize: 8,
              // fontStyle: "italic"
            }}
            x={200} y={45}
            textAnchor="middle"
          />}

        <VictoryLabel
          x={45} y={30} style={{
            textAnchor: "end",
            fill: BLUE_COLOR,
            fontFamily: "inherit",
            fontSize: 8,
            fontStyle: "italic"
          }}
          text={"Invoices Sent"}
        />
        {this.state.TotalInvoices > 10000 &&
          <VictoryLabel x={45} y={41}
            style={{
              textAnchor: "end",
              fill: BLUE_COLOR,
              fontFamily: "inherit",
              fontSize: 7,
              fontStyle: "italic"
            }}
            text={"(in thousands)"}
          />}

        {/* <VictoryLabel x={400} y={35}
                    style={{
                      fill: 'grey',
                      fontFamily: "inherit",
                      fontSize: 5,
                      fontStyle: "italic"
                    }}
                    text={"Total"}
                  /> */}

        {/* X Axis */}
        {!isEmptyNullUndefined(this.state.employees) &&
          <VictoryAxis
            standalone={false}
            style={{

              axis: { stroke: "black", strokeWidth: 1 },
              ticks: {
                size: 5,
                stroke: "black",
                strokeWidth: 1
              },
              // tickLabels: { fontSize: 6, padding: 1 },

              axisLabel: { fontSize: 15, padding: 20 },
              tickLabels: { fontSize: this.props.selectedYear !== 0 ? 8 : 6, padding: this.props.selectedYear !== 0 ? 5 : 1 }
            }}
            // label='Year'
            // tickCount={3}
            tickValues={xTickValues}
            // animate={{ duration: 500 }}
            tickFormat={(q) => this.props.selectedYear !== 0 ? q.toLocaleString('en-us', { month: 'short', year: 'numeric' }) : q.getMonth() === 0 ? 'Q1' : q.getMonth() === 3 ? 'Q2' : q.getMonth() === 6 ? 'Q3' : q.getMonth() === 9 ? 'Q4' : null}
          // tickFormat={date => date.toLocaleString('en-us', { month: 'short', year: 'numeric' })}
          />
        }
        {/* Years */}
        {!isEmptyNullUndefined(this.state.employees) &&
          < VictoryAxis
            scale="time"
            standalone={false}
            style={{
              axis: { stroke: "black", strokeWidth: 1 },
              tickLabels: { fontSize: 8, padding: 12 }
            }}
            tickValues={this.props.selectedYear !== 0 ? this.state.quarters : xTickValues}
            tickFormat={(q) => this.props.selectedYear !== 0 ? null : q.getMonth() === 0 ? q.getFullYear() : ''}
          />
        }

        {this.props.showpriortotal && !isEmptyNullUndefined(this.state.employees) && !isEmptyNullUndefined(this.state.employees[this.props.selectedPartner - 1].priorTotal) && this.state.employees[this.props.selectedPartner - 1].priorTotal > 0 &&


          // This is the prior year's total 
          <VictoryArea
            standalone={false}
            animate={{
              duration: 500,
              onLoad: { duration: 10 }
            }}
            domain={{ x: [xTickValues[0], xTickValues[xTickValues.length - 1]], y: [0, this.state.employees[this.props.selectedPartner - 1].total * 1.1] }}
            style={{ data: { stroke: "#00ff00", strokeWidth: 1, strokeDasharray: '4', fill: 'url(#gradient2)' } }}
            data={this.state.employees[this.props.selectedPartner - 1].invoices.map(item => { return { x: item.x, y: this.state.employees[this.props.selectedPartner - 1].priorTotal } })}
            labels={({ datum }) => `Prior Year Total: ${this.state.employees[this.props.selectedPartner - 1].priorTotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}`}
            labelComponent={<VictoryTooltip
              renderInPortal={false}
              standalone={false}
              constrainToVisibleArea
              style={{ fill: 'black', fontSize: 8 }}
              flyoutStyle={{
                stroke: "tomato",
                strokeWidth: 1,
                fill: "#e3ffeb"
              }}
            />}
          />
        }

        {/* Y Axis */}
        <VictoryAxis
          standalone={false}
          dependentAxis
          domain={{ y: [0, isEmptyNullUndefined(this.state.employees) ? 1 : !isEmptyNullUndefined(this.state.employees[this.props.selectedPartner - 1].invoices) && this.state.employees[this.props.selectedPartner - 1].total > 0 ? this.state.employees[this.props.selectedPartner - 1].total * 1.1 : 1] }}

          // tickFormat specifies how ticks should be displayed
          tickFormat={(x) => !isEmptyNullUndefined(this.state.employees) && this.state.employees[this.props.selectedPartner - 1].total > 10000 ? '$' + new Intl.NumberFormat().format(x / 1000) : '$' + new Intl.NumberFormat().format(x)}
          // label="Amount"
          style={{
            // axis: { stroke: "#756f6a" },
            // axisLabel: {fontSize: 20, padding: 30},
            grid: { stroke: ({ tick }) => tick === 0 ? "#000000" : tick > 0.5 ? "6d65a0" : "grey" },
            ticks: { stroke: "grey", size: 2 },
            tickLabels: {
              fill: BLUE_COLOR,
              fontFamily: "inherit",
              fontSize: 8
            }
          }}
        // animate={{ duration: 500 }}
        />


        {this.props.showcumline && !isEmptyNullUndefined(this.state.employees) && !isEmptyNullUndefined(this.state.employees[this.props.selectedPartner - 1].invoices) && this.state.employees[this.props.selectedPartner - 1].total > 0 &&
          // This is the main area line
          <VictoryArea
            standalone={false}
            domain={{ x: [xTickValues[0], xTickValues[xTickValues.length - 1]], y: [0, this.state.employees[this.props.selectedPartner - 1].total * 1.1] }}
            animate={{
              duration: 500,
              onLoad: { duration: 10 }
            }}
            name='redPoints'
            interpolation="monotoneY"

            // standalone={false}
            // animate={{ duration: 500 }}
            // style={{
            //   data: { stroke: "#c43a31" },
            //   parent: { border: "1px solid #ccc" }
            // }}
            data={!isEmptyNullUndefined(this.props.selectedPartner) && !isEmptyNullUndefined(this.state.employees) ? this.state.employees[this.props.selectedPartner - 1].invoices : null}
            // interpolation="stepAfter"
            scale={{ x: "time", y: "linear" }}
            style={{ data: { stroke: "#00a3de", strokeWidth: 4.5, strokeLinecap: "round", fill: "url(#gradient1)" } }}
          // labels={({ data, index, datum }) => parseInt(index) === data.length - 1 ? datum.y.toLocaleString('en-US', {
          //   style: 'currency',
          //   currency: 'USD',
          // }) + '\n' + this.state.employees[this.props.selectedPartner - 1].name : null}
          // labelComponent={<VictoryLabel style={{ fill: 'black', fontSize: 5, stroke: this.state.employees[this.props.selectedPartner - 1].color, strokeWidth: .2, strokeOpacity: 0.8 }} dx={15} dy={10} renderInPortal />}
          />

        }

        {/* Now add the arrow and the total */}
        {!isEmptyNullUndefined(this.state.employees) &&
          renderSVG({ x: 54 + this.state.employees[this.props.selectedPartner - 1].yearPercentage * 351, y: 71 }, { x: 423, y: 71 }, 4, 7, 7)
        }

        {!isEmptyNullUndefined(this.state.employees) &&
          <g >
            <VictoryLabel
              x={425} y={80}
              style={{
                fill: RED_COLOR,
                textAnchor: 'start',
                fontFamily: "inherit",
                fontSize: 8,
                fontStyle: "italic"
              }}
              text={`Total (by ${this.props.byInvoicingEmployee ? 'invoicing' : 'originating'} partner): 
            \n` + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.employees[this.props.selectedPartner - 1].total)
                + '\n' + this.state.employees[this.props.selectedPartner - 1].name
              }
            />
            <image x={425} y={90} height={13} href={this.props.clients.employeesarray.find(employeex => employeex[0] === this.state.employees[this.props.selectedPartner - 1].id)[4]} />
          </g>
        }

        {this.props.showpriortotal && !isEmptyNullUndefined(this.state.employees) && !isEmptyNullUndefined(this.state.employees[this.props.selectedPartner - 1].priorTotal) && this.state.employees[this.props.selectedPartner - 1].priorTotal > 0 &&

          <VictoryScatter
            standalone={false}
            animate={{
              duration: 500,
              onLoad: { duration: 10 }
            }}
            domain={{ x: [xTickValues[0], xTickValues[xTickValues.length - 1]], y: [0, this.state.employees[this.props.selectedPartner - 1].total * 1.1] }}
            size={({ active }) => {
              return active ? 5 : 2;
            }}
            style={{
              data: {
                fill: "#00ff00",
                stroke: "#00ff00",
                fillOpacity: 1,
                strokeWidth: 1
              }
            }}
            data={[{
              x: this.state.employees[this.props.selectedPartner - 1].invoices[this.state.employees[this.props.selectedPartner - 1].invoices.length - 1].x,
              y: this.state.employees[this.props.selectedPartner - 1].priorTotal
            }]}
            labels={({ datum }) => `Prior Year Total: ${datum.y.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}`}
            labelComponent={<VictoryTooltip
              renderInPortal={false}
              constrainToVisibleArea
              style={{ fill: 'black', fontSize: 8 }}
              flyoutStyle={{
                stroke: "tomato",
                strokeWidth: 1,
                fill: "#e3ffeb"
              }}
            />}
          />
        }

        {this.props.showcumscatter && !isEmptyNullUndefined(this.state.employees) && !isEmptyNullUndefined(this.state.employees[this.props.selectedPartner - 1].invoices) && this.state.employees[this.props.selectedPartner - 1].total > 0 &&
          <VictoryScatter
            standalone={false}
            renderInPortal={false}
            animate={{
              duration: 500,
              onLoad: { duration: 10 }
            }}
            domain={{ x: [xTickValues[0], xTickValues[xTickValues.length - 1]], y: [0, this.state.employees[this.props.selectedPartner - 1].total * 1.1] }}
            size={({ active }) => {
              return active ? 5 : 2;
            }}
            style={{
              data: {
                fill: "blue", stroke: "blue",
                fillOpacity: 1,
                strokeWidth: 1
              }
            }}
            data={this.state.employees[this.props.selectedPartner - 1].invoices}
            labels={({ datum }) => `${!isEmptyNullUndefined(datum.invoice_number) ? datum.invoice_number + ', ' : ''}${datum.y.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}`}
            labelComponent={<VictoryTooltip
              constrainToVisibleArea
              renderInPortal={false}
              style={{ fill: 'black', fontSize: 8 }}
              flyoutStyle={{
                stroke: "tomato",
                strokeWidth: 1,
                fill: "#ffffe3"
              }}
            />}
          />


        }


        {!isEmptyNullUndefined(this.state.employees) && this.props.showbarsscatter &&
          <VictoryScatter
            standalone={false}
            renderInPortal={false}
            animate={{
              duration: 500,
              onLoad: { duration: 10 }
            }}
            domain={{ x: [xTickValues[0], xTickValues[xTickValues.length - 1]], y: [0, !isEmptyNullUndefined(this.state.employees[this.props.selectedPartner - 1].invoices) && this.state.employees[this.props.selectedPartner - 1].total > 0 ? this.state.employees[this.props.selectedPartner - 1].total * 1.1 : 1] }}
            data={this.state.selectedBarPoints.map(item => { return { x: new Date(Date.parse(item.x)), y: item.y, invoice_number: item.invoice_number } })}
            // domain={{ x: [domainStart, domainEnd] }}
            style={{
              data: {
                fill: "#efefef",
                stroke: "#ffffff",
                fillOpacity: 0.7,
                strokeWidth: .5
              }
            }}
            barWidth={2}
            labels={({ datum }) => `${!isEmptyNullUndefined(datum.invoice_number) ? datum.invoice_number + ', ' : ''}${datum.y.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}`}
            labelComponent={<VictoryTooltip
              constrainToVisibleArea
              renderInPortal={false}
              style={{ fill: 'black', fontSize: 8 }}
              flyoutStyle={{
                stroke: "tomato",
                strokeWidth: 1,
                fill: "#ffffe3"
              }}
            />}
          />}

        {!isEmptyNullUndefined(this.state.employees) && this.props.showbars &&
          <VictoryBar
            standalone={false}
            renderInPortal={false}
            animate={{
              duration: 500,
              onLoad: { duration: 10 }
            }}
            domain={{ x: [xTickValues[0], xTickValues[xTickValues.length - 1]], y: [0, !isEmptyNullUndefined(this.state.employees[this.props.selectedPartner - 1].invoices) && this.state.employees[this.props.selectedPartner - 1].total > 0 ? this.state.employees[this.props.selectedPartner - 1].total * 1.1 : 1] }}
            data={this.state.selectedBarPoints.map(item => { return { x: new Date(Date.parse(item.x)), y: item.y, invoice_number: item.invoice_number } })}
            // domain={{ x: [domainStart, domainEnd] }}
            style={{
              data: {
                fill: "#efefef",
                stroke: "#000000",
                fillOpacity: 1,
                strokeWidth: 1
              }
            }}
            barWidth={2}
            labels={({ datum }) => `${!isEmptyNullUndefined(datum.invoice_number) ? datum.invoice_number + ', ' : ''}${datum.y.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}`}
            labelComponent={<VictoryTooltip
              constrainToVisibleArea
              renderInPortal={false}
              style={{ fill: 'black', fontSize: 8 }}
              flyoutStyle={{
                stroke: "tomato",
                strokeWidth: 1,
                fill: "#ffffe3"
              }}
            />}
          />
        }

      </svg>

    )
  }
}


const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsLineIndividualSVG))

ChartsLineIndividualSVG.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  fetchObject: [],
  showbars: false,
  showbarsscatter: false,
  showpriortotal: true,
  showcumline: true,
  showcumscatter: false,
  currentYear: new Date().getFullYear(),
  selectedYear: new Date().getFullYear(),
  selectedPartner: 1,
  byInvoicingEmployee: false,
  // handleSomething: () => { },
}