
import React, { Component } from 'react'
import { szenokPostAPI } from '../helper/szenokPostAPI'
import { szenokBlobAPI, downloadBlob } from '../helper/szenokBlobAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

// import JSZip from 'jszip'

// import Profile from '../layout/Profile'
import { Icon, Button } from 'semantic-ui-react';
import { isEmptyNullUndefined } from '../helper/Misc';

import ReactTableMaker from '../helper/ReactTableMaker'

class Database extends Component {

  state = {
    DBName: '',
    DBSize: '',
    Downloading: false
  }

  async componentDidMount() {
    this.getDBSize()
    this.getTableSizes()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      // setTimeout(() => this.getImages(), 200)
    }
  }


  getDBSize = async () => {
    // console.log('In getDBSize...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getDatabase', { fetchstr: 'databaseSize' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getDBSize: ', fetchresponse.payload)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      DBName: fetchresponse.payload[0].Database,
      DBSize: fetchresponse.payload[0].KB
    })
  }

  getTableSizes = async () => {
    // console.log('In getTableSizes...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getDatabase', { fetchstr: 'tablesSizes' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getTableSizes: ', fetchresponse.payload)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      DBTables: fetchresponse.payload
    })
  }

  getTableDump = async () => {
    // console.log('In getTableDump...')
    this.setState({ Downloading: true })
    this.props.fetchResetState('all')
    let fetchresponse = await szenokBlobAPI('dumpDB', { fetchstr: 'getTableDumpBlob' },
      this.props.login.currentToken)
    const blob = await fetchresponse.blob()
    var d = new Date()
    await downloadBlob(blob, 'SzenokSqlDump-' + d.getDate() + '-' + d.getMonth() + 1 + '-' + d.getFullYear() + '.zip')
    this.setState({ Downloading: false })
  }

  getSchemaDump = async () => {
    // console.log('In getTableDump...')
    this.setState({ Downloading: true })
    this.props.fetchResetState('all')
    let fetchresponse = await szenokBlobAPI('dumpDB', { fetchstr: 'getSchemeDumpBlob' },
      this.props.login.currentToken)
    const blob = await fetchresponse.blob()
    var d = new Date()
    await downloadBlob(blob, 'SzenokSchemaDump-' + d.getDate() + '-' + d.getMonth() + 1 + '-' + d.getFullYear() + '.zip')
    this.setState({ Downloading: false })
  }


  render() {

    return (
      <div>
        <h1> <Icon name='database' size='large' circular />Database</h1>

        <Button loading={this.state.Downloading} color='blue' onClick={async () => {
          this.getTableDump()
          // alert('Need Shiran help on dumping the mysql file')
        }}>Download Database</Button>
        <p />
        <Button loading={this.state.Downloading} color='green' onClick={async () => {
          this.getSchemaDump()
          // alert('Need Shiran help on dumping the mysql file')
        }}>Download Database Schema</Button>

        <p />
        <h4>Database:  {this.state.DBName}<br /></h4>
        <h4>Database Size:  {new Intl.NumberFormat().format(this.state.DBSize)} (KB) <br /></h4>
        <p />
        <ReactTableMaker passedData={this.state.DBTables} passedColumns={DBTablesColumns(this.props.history)}
          // handleClick={(rowID, cellID, rowValues) => {console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)}}
          includeSearchBar={false}
        // includePaginationFooter={false}
        />

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default connect(mapStateToProps, mapDispatchToProps)(Database)


let DBTablesColumns = (history) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Table Name</span>,
      accessor: 'TableName',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Rows Count</span>,
      accessor: 'RowsCount',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'color': 'green', 'float': 'right' }}>{new Intl.NumberFormat().format(cell.value)}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Table Size (KB)</span>,
      accessor: 'TableSize',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'color': 'green', 'float': 'right' }}>{new Intl.NumberFormat().format(cell.value)}</span>
    }
  ]
}
