import React, {useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import { isEmptyNullUndefined } from "../helper/Misc";
import { Map, Marker, Tooltip, TileLayer, ZoomControl } from 'react-leaflet'
import L from 'leaflet'
// import GoogleGeolocateBar from '../charts/Delete/Charts-Google-Geolocate-Bar'
import GoogleGeolocateBar from './Component-Maps-Locations-Geolocation-Bar'

import AddLocation from '../charts/locations/Charts-Locations-AddLocation'
// import AddLocation from './Component-Maps-Location-AddLocation'
import { Icon as ReactIcon } from 'semantic-ui-react';
// import './Charts-Leaflet-Map.css'
import 'leaflet/dist/leaflet.css'
// import '../charts/Charts-Leaflet-Map-Custom.css'
// import '../charts/locations/Charts-Locations-AddLocation.css'
// import '../charts/Charts-Google-Geolocate-Bar.css'

const LocationMap = ({
  Facilities = [],
  ClientsMatters = [],
  Height = '100vh',
  Width = '100%',
  history,
  GOOGLEAPI,
  handleSelectedLocation = () => { }
}) => {

  // const [bounds, setBounds] = useState(null);
  // const [zoom, setZoom] = useState(13);
  const mapRef = useRef();
  const [address, setAddress] = React.useState('')
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null
  })
  const [draggedCoordinates, setDraggedCoordinates] = React.useState({
    lat: null,
    lng: null
  })

  // get map bounds
  // function updateMap() {
  //   // const b = mapRef.current.leafletElement.getBounds();
  //   // setBounds([
  //   //   b.getSouthWest().lng,
  //   //   b.getSouthWest().lat,
  //   //   b.getNorthEast().lng,
  //   //   b.getNorthEast().lat
  //   // ]);
  //   // setZoom(mapRef.current.leafletElement.getZoom());
  // }

  // React.useEffect(() => {
  //   updateMap();
  // }, []);


  //Set Address if receive info from geolocating child component
  const handleAddressReceipt = (address) => {
    // console.log('Here in handleAddressReceipt with this address: ', address)
   !isEmptyNullUndefined(address) && setAddress(address)
  }

  //Set Coordinates if receive info from geolocating child component
  const handleCoordinatesReceipt = (coordinates) => {
    // console.log('Here in handleCoordinatesReceipt with this address: ', coordinates)
    !isEmptyNullUndefined(coordinates.lat) && setCoordinates(coordinates)
  }

  React.useEffect(() => {
    !isEmptyNullUndefined(coordinates.lat) && mapRef.current.leafletElement.setView([coordinates.lat, coordinates.lng], 17, {
      animate: true
    })
  }, [coordinates]);

  React.useEffect(() => {
    // console.log('Trying to change coordinates because of draggedCoordinates')
    !isEmptyNullUndefined(draggedCoordinates.lat) && setCoordinates(draggedCoordinates)
  }, [draggedCoordinates]);



  return (
    <div style={{ height: Height, width: Width, position: 'relative' }} className='parent-div2'>
      {/* <div style={{position: 'absolute'}}> */}
      {/* Need to add this to the index.html to make this work: <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places"></script> */}
      <Map
        center={[40.73, -73.93]}
        zoom={10}
        // onMoveEnd={updateMap}
        ref={mapRef}
        zoomControl={false}
        // onClick={handleClick}
        attributionControl={false}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          attribution=''
        />
        <ZoomControl position='topright' />
        {!isEmptyNullUndefined(coordinates.lat) &&
          <Marker
            position={[coordinates.lat, coordinates.lng]}
            icon={L.divIcon({ className: "custom icon", iconAnchor: [30, 48], tooltipAnchor: [30, -52], html: ReactDOMServer.renderToString(<ReactIcon name='map pin' color='green' size='huge' />) })}
            onClick={() => {
              // console.log('This is the facility: ', Facilities.find(facility => facility.location_id === cluster.properties.facilityID).matter_name)
            }}
            draggable={true}
            ondragend={(e) => {
              let newcoords = e.target.getLatLng()
              // console.log(`This is new position: [${newcoords.lat}, ${newcoords.lng}]`)
              setDraggedCoordinates({ lat: newcoords.lat, lng: newcoords.lng })
              // setCoordinates([newcoords.lat, newcoords.lng])
            }}
          >
            <Tooltip direction='top' opacity={1} >{
              <div>
                <span style={{ color: '#ff8040' }}>Address:  </span>{address}<br />
                <span style={{ color: '#ff8040' }}>Latitude:  </span>{coordinates.lat}<br />
                <span style={{ color: '#ff8040' }}>Longitude:  </span>{coordinates.lng}<br />
                (Drag icon if not correct location)
              </div>
            }</Tooltip>
          </Marker>
        }
      </Map>
      <GoogleGeolocateBar
        GOOGLEAPI={GOOGLEAPI}
        sendAddressToParent={handleAddressReceipt}
        sendCoordinatesToParent={handleCoordinatesReceipt}
        draggedCoordinates={draggedCoordinates} />
      <AddLocation
        ClientsMatters={ClientsMatters}
        address={address}
        coordinates={coordinates}
        sendAddressToParent={handleAddressReceipt}
        sendCoordinatesToParent={handleCoordinatesReceipt}
        matterSelector={false}
        sendSelectedLocationToParent={handleSelectedLocation}
      />
      {/* </div> */}
    </div>
  )
}

export default LocationMap
