import React, { Component } from 'react'
import { withOktaAuth } from '@okta/okta-react';
import { Grid, Header, Loader, Icon } from 'semantic-ui-react'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import { connect } from 'react-redux';

// import { isEmptyNullUndefined } from '../helper/Misc'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState'
// import DisplayClients from '../helper/DisplayClients'

class ContentLogin extends Component {
  render() {

    const contentLoginContent =


      !this.props.Logging_authenticated ?
        <div>
          <Header as='h3' block color='blue' inverted
            content='User Not Recognized'
            subheader='Please Sign In' />
        </div>
        :
        !this.props.Logging_userTested ?
          <div>
            <Header as='h3' block color='blue' inverted
              content='Checking User'
              subheader='Please Wait...' />
          </div>
          :
          this.props.Logging_authenticated && this.props.Logging_userTested && !this.props.Logging_userIdentified ?
            <div>
              <Header as='h3' block color='blue' inverted
                content='User Not Recognized'
                subheader='Please Sign In' />
            </div>
            :
            this.props.Logging_userIdentified && this.props.Logging_tokenRetrieved && !this.props.Logging_userSet?
              <div>
                <Header as='h3' block color='blue' inverted
                  content='Checking Login Info'
                  subheader='One Moment...' />
              </div>
              :
              this.props.Logging_userSet && this.props.Logging_DBUserExists?
                <div>
                  <Header as='h3' block color='blue' inverted
                    content='Logging In'
                    subheader='Hold on...' />
                  <Loader />
                </div>
                :
              !this.props.Logging_CheckedDB?
                <div>
                  <Header as='h3' block color='blue' inverted
                    content='Checking database for user.'
                    subheader='One second...' />
                  <Loader />
                </div>
                :
              !this.props.Logging_DBUserExists?
                <div>
                  <Header as='h3' block color='blue' inverted
                    content='Sorry, user does not exist in database.'
                    subheader='Contact admin...' />
                  <Loader />
                </div>
                :
                null




    return (
      <React.Fragment>
        <Grid.Row>
          <Grid.Column width={2}>
            {/* <DisplayState state={this.state} /> */}
            {/* <DisplayClients /> */}
          </Grid.Column>
          <Grid.Column width={14}>
            {contentLoginContent}
            {/* All this stuff below is just to puzzle out logged in situation */}
            {this.props.Logging_authenticated ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}
            {process.env.NODE_ENV === 'production' ? ' - Authenticated' : ' - Logging_authenticated'}<br />
            {this.props.Logging_userTested ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}
            {process.env.NODE_ENV === 'production' ? 'User Tested' : ' - Logging_userTested'}<br />
            {this.props.Logging_userIdentified ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}
            {process.env.NODE_ENV === 'production' ? 'User Identified' : ' - Logging_userIdentified'}<br />
            {this.props.Logging_tokenRetrieved ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}
            {process.env.NODE_ENV === 'production' ? 'Token Retrieved' : ' - Logging_tokenRetrieved'}<br />
            {this.props.Logging_userSet ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}
            {process.env.NODE_ENV === 'production' ? 'User Set' : ' - Logging_userSet'}<br />
            {this.props.Logging_loggedIn ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}
            {process.env.NODE_ENV === 'production' ? 'Logged In' : ' - Logging_loggedIn'}<br />
            {this.props.Logging_DBUserExists ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}
            {process.env.NODE_ENV === 'production' ? 'User Exists in Database' : ' - Logging_DBUserExists'}<br />
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(loginActions, dispatch) }

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(ContentLogin))