import React, {useEffect, useRef} from 'react'
import { Modal } from 'semantic-ui-react';
import AddCounterparty from '../pages/Component-AddCounterparty'
import AddLawFirms from '../pages/Component-AddLawFirm'
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux"

import { setAddCP, setAddLawFirm } from '../../actions/matterInfoActions'


export default function ModalAddCP(props) {

  const {
    type = 'counterparty',
  } = props

  const dispatch = useDispatch()

  // Here's how we'll keep track of our component's mounted state
  const componentIsMounted = useRef(true)
  useEffect(() => {
    return () => {
      // console.log('Unmounting component...')
      componentIsMounted.current = false
    }
  }, []) // Using an empty dependency array ensures this only runs on unmount

  let closeCallback = (toggle) => {
    switch (type) {
      case "counterparty":
        return dispatch(setAddCP(toggle))
      case "law firm":
        return dispatch(setAddLawFirm(toggle))
      default:
        return dispatch(setAddCP(toggle))
    }
  }

  const modalOpen = useSelector(state => {
    switch (type) {
      case "counterparty":
        return state.matterInfo.addCPInfoObject.showAddCP
      case "law firm":
        return state.matterInfo.addCPInfoObject.showAddLawFirm
      default:
        return state.matterInfo.addCPInfoObject.showAddCP
    }
  }
  )
  // // console.log('Here in COnfirmModal and this is modalOpen: ', modalOpen)

  // React.useEffect(
  //   () => { console.log(props) }, [props]
  // )

  return (
    componentIsMounted.current &&
    <Modal
      onClose={() => componentIsMounted.current? closeCallback(false): null}
      open={modalOpen}
      size='large'
    // centered={true}
    >
      <Modal.Content>
        {type === "counterparty" && componentIsMounted.current &&
          <AddCounterparty
            closeCallback={closeCallback}
          />}
        {type === "law firm" && componentIsMounted.current &&
          <AddLawFirms
            fromAddCPInfo={true}
            closeCallback={closeCallback}
          />}
      </Modal.Content>
    </Modal>
  )
}
