import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, filterSimpleInput } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Header, Label, Divider, Dropdown, Button, Segment, Checkbox } from 'semantic-ui-react';
import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';
// import { level } from 'winston';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';

const initialState = {
  visible: false,
  ExistingEmployees: [],
  ExistingMatters: [],
  ExistingPrecedents: [],
  SelectedClient: null,
  SelectedClientName: '',
  SelectedMatter: null,
  Matter: '',
  Invoice2Regex: [],
  Invoice2RegexSpecific: '',
  MatterArray: [],
  Description: null,
  initialValues: {},
  ClientMatterDropDownArray: [],
  selectedImage: {},
}

class AddEmployee extends Component {

  state = initialState

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getEmployees()
    this.getImages()
    if (this.props.update) {
      await this.getSpecificEmployee()

      this.setState({ SelectedEmployee: this.state.initialValues.employee_id })
    }
  }

  getImages = async () => {
    // console.log('In getImages...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getImages', { fetchstr: 'allCloudinaryImages' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getImages: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      // Logs: fetchresponse.payload,
      ImagesArray: fetchresponse.payload
    }, () => this.setState({ ImagesDropdown: this.ImagesDropdown() }))
  }

  getSpecificEmployee = async () => {
    // console.log('Running getSpecificPrecedent....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getEmployee', { fetchstr: 'getEmployeeByID', EmployeeID: this.props.match.params.employeeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getPrecedent (in getSpecificPrecedent): ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { ...fetchresponse.payload, image: fetchresponse.payload.e_picture.substring(67, fetchresponse.payload.e_picture.length - 4) },
      selectedImage: { name: fetchresponse.payload.e_picture.substring(67, fetchresponse.payload.e_picture.length - 4), url: fetchresponse.payload.e_picture }
    })
  }


  getEmployees = async () => {

    this.props.fetchResetState('all')
    // console.log('Fetching for this client: ', client_id)
    let fetchresponse = await szenokPostAPI('getEmployees', { fetchstr: 'none' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for precedentsforclient (in getPrecedents): ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    this.setState({
      ExistingEmployees: fetchresponse.payload.map(employee => employee)
    })
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ Invoice2Regex: updatedListGeneralSorted })
    if (updatedListSpecific.length > 0) {
      if (this.props.update && updatedListSpecific[0][1] !== this.state.initialValues.precedent_id) {
        this.setState({ Invoice2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ Invoice2RegexSpecific: '' })
      setStatus({ errors: {} })
    }

  }, 100)

  LevelDropdown = () => {
    let returnarray = []
    let obj = {}
    for (let i = 0; i < 6; i++) {
      obj = { key: i + 'Option', text: i, value: i }
      returnarray.push(obj)
    }
    return returnarray
  }

  ImagesDropdown = () => {
    let returnArray = this.state.ImagesArray.map((image, index) => {
      let obj = {}
      obj.key = `IDA${index}`
      obj.text = image.name
      obj.value = image.name
      // obj.image = window.location.origin + '/images/' + image.name
      obj.image = image.url
      return obj
    })
    // for (let i = 0; i < this.state.ImagesArray; i++) {
    //   var obj = {}
    //   obj.key = `IDA${i}`
    //   obj.text = `${this.state.ImagesArray[i].name}`
    //   obj.value = i + 1
    //   returnArray.push(obj)
    // }
    // console.log('This is returnArray: ', returnArray)
    return returnArray
  }

  render() {

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      EFName: '',
      ELName: '',
      SDate: 'a',
      Picture: '',
      Level: 5,
      EEmail: '',
      Guest: false,
      Observer: false,
      ShowCharts: true,
      ShowFinances: true,
    } : {
        EFName: this.state.initialValues.efname,
        ELName: this.state.initialValues.elname,
        SDate: new Date(this.state.initialValues.e_start_date),
        Picture: this.state.initialValues.image,
        Level: this.state.initialValues.e_level,
        EEmail: this.state.initialValues.e_email,
        Guest: !!this.state.initialValues.guest,
        Observer: !!this.state.initialValues.observer,
        ShowCharts: !!this.state.initialValues.show_charts,
        ShowFinances: !!this.state.initialValues.show_finances,
      }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300


    return (
      <React.Fragment>
        {this.props.headingForForm.length <= 0 ?
          <Header as='h1'>Employee {this.props.update === true ? 'Edit' : 'Add'} Form</Header> :
          <Header as='h3'>{this.props.headingForForm}</Header>
        }
        {this.props.update && !isEmptyNullUndefined(this.state.initialValues) &&
          <Header as='h2'>Editing: {this.state.initialValues.employee_id}</Header>
        }


        <Formik

          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={AddEmployeeSchema}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              this.setState({ Invoice2Regex: [], Invoice2RegexSpecific: '' })
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editEmployee', {
                EFName: values.EFName, ELName: values.ELName,
                SDate: values.SDate, Picture: this.state.selectedImage.url,
                Level: values.Level,
                EEmail: values.EEmail,
                EmployeeID: this.props.match.params.employeeID,
                Guest: values.Guest,
                Observer: values.Observer,
                ShowCharts: values.ShowCharts,
                ShowFinances: values.ShowFinances
              }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addEmployee', {
                EFName: values.EFName, ELName: values.ELName,
                SDate: values.SDate, Picture: this.state.selectedImage.url,
                Level: values.Level,
                EEmail: values.EEmail,
                Guest: values.Guest,
                Observer: values.Observer,
                ShowCharts: values.ShowCharts,
                ShowFinances: values.ShowFinances
              }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for addPrecedent: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                //Update the Existing Invoices
                // this.getInvoices()

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                actions.resetForm({ ...initialValues })
                this.setState(initialState)
                await reduxupdate('employees')
                if (this.props.update) {
                  await this.getSpecificEmployee()
                  await this.getEmployees()
                  this.setState({ SelectedEmployee: this.state.initialValues.employee_id })
                }
                this.props.history.push(`/employees`)

              }
            }
            catch (error) { this.props.fetchErrors(error.toString()) }
            actions.setSubmitting(false)
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment>

                  <Form onSubmit={handleSubmit} autoComplete='off'>

                    {/* First, put in the Client/Matter */}
                    {/* <span style={{ fontWeight: 'bold', fontSize: 13 }}>Level (0 = admin, 1 = full partner, 2 = junior partner, 3 = staff, etc.)</span>
                    <span style={{ fontWeight: 'bold', color: 'red', fontSize: 12 }}>*</span> */}

                    <table>
                      <tr>
                        <th>Level</th>
                        <th></th>
                        <th>Use <span style={{ fontWeight: 'bold', color: 'red', fontSize: 12 }}>*</span></th>
                      </tr>
                      <tr>
                        <td>0</td>
                        <td>=</td>
                        <td>Admin</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>=</td>
                        <td>Full Permission</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>=</td>
                        <td>Partner Permission</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>=</td>
                        <td>Associate Permission</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>=</td>
                        <td>Guest/Other</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>=</td>
                        <td>Any logged in user</td>
                      </tr>
                    </table>
                    <Form.Group>
                      <Form.Field required width={1} error={(touched.Level && isEmptyNullUndefined(values.Level)) || has(status, 'errors.Level')}>
                        <Dropdown
                          placeholder='Select Level'
                          name="Level"
                          required
                          // search
                          compact
                          label='Level'
                          error={(touched.Level && (typeof get(values, 'Level') === 'undefined' || get(values, 'Level') === null)) ||
                            (has(status, 'errors.Level'))}
                          closeOnBlur
                          selection
                          value={values.Level}
                          onBlur={() => { setFieldTouched('Level', true) }}
                          options={this.LevelDropdown()}
                          loading={this.state.success}
                          onChange={async (e, { name, value }) => {
                            setFieldValue(name, value)
                          }}
                        />
                      </Form.Field>
                    </Form.Group>

                    {this.errorTag(errors, touched, status, 'Client', 'Pick a Client. ', 'Pick a Client. ')}

                    {/* Show the rest of this only if we have downloaded ExistingEmployees */}
                    {!isEmptyNullUndefined(this.state.ExistingEmployees) &&
                      <React.Fragment>

                        <Form.Group>

                          {/* Add the First Name */}
                          <Form.Field width={7}>
                            <Form.Input
                              name="EFName" type="text"
                              label='First Name'
                              loading={this.state.success}
                              required
                              placeholder={'First Name'}
                              error={(errors.EFName && touched.EFName) || (has(status, 'errors.EFName'))}
                              onChange={async (e) => {
                                await handleChange(e);
                                this.setState({ EFName: e.target.value })
                                this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, this.state.ExistingPrecedents)
                              }}
                              onBlur={handleBlur}
                              options={this.state.ClientMatterDropDownArray}

                              value={values.EFName} />
                            {this.errorTag(errors, touched, status, 'EFName')}
                          </Form.Field>

                          {/* Add the Last Name */}
                          <Form.Field width={7}>
                            <Form.Input
                              name="ELName" type="text"
                              label='Last Name'
                              loading={this.state.success}
                              required
                              placeholder={'Last Name'}
                              error={(errors.ELName && touched.ELName) || (has(status, 'errors.ELName'))}
                              onChange={async (e) => {
                                await handleChange(e);
                                this.setState({ ELName: e.target.value })
                                this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, this.state.ExistingPrecedents)
                              }}
                              onBlur={handleBlur}
                              options={this.state.ClientMatterDropDownArray}

                              value={values.ELName} />
                            {this.errorTag(errors, touched, status, 'ELName')}
                          </Form.Field>
                        </Form.Group>


                        <Form.Group>
                          {/* Start Date */}
                          <Form.Field required width={6} error={(touched.SDate && isNaN(values.SDate)) || has(status, 'errors.SDate')}>
                            <label>Start Date</label>
                            <DatePicker
                              name="SDate"
                              onSelect={async (date) => {
                                //console.log('We have selected this date: ', date)
                                setFieldValue('SDate', date)
                                setFieldTouched('SDate', true)
                                //I cannot console.log the new values.SDate here.  I get the old date.  Can't figure out how to make that work.
                                // console.log('And this is values: ', values.SDate)
                              }}
                              dateFormat='MMMM d, yyyy'
                              minDate={new Date(2011, 0, 1)}
                              maxDate={new Date()}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              withPortal
                              loading={this.state.success}
                              onBlur={() => setFieldTouched('SDate', true)}
                              todayButton={'Today'}
                              selected={values.SDate !== 'a' ? values.SDate : null}
                              placeholderText="Select an origination date"
                            />
                            {this.errorTag(errors, touched, status, 'SDate')}
                          </Form.Field>
                        </Form.Group>

                        <Form.Group>
                          {/* Add the Picture Filename */}
                          <Form.Field width={7}>
                            <label>Select Picture</label>
                            <Dropdown
                              placeholder='Select Picture'
                              name="Picture"
                              width={6}
                              required
                              search
                              label='Picture'
                              closeOnBlur
                              selection={true}
                              value={this.state.selectedImage.name}
                              options={this.state.ImagesDropdown || []}
                              onChange={async (e, { name, value }) => {
                                // console.log('value is: ', value)
                                setFieldValue(name, value)
                                this.setState({
                                  selectedImage: this.state.ImagesArray.find(image => image.name === value)
                                })

                              }}
                            />

                          </Form.Field>
                        </Form.Group>


                        {/* Add the Employee Email */}
                        <Form.Field width={7}>
                          <Form.Input
                            name="EEmail" type="text"
                            label={`Email Address`}
                            loading={this.state.success}
                            required
                            placeholder={`Email Address`}
                            error={(errors.EEmail && touched.EEmail) || (has(status, 'errors.EEmail'))}
                            onChange={async (e) => {
                              await handleChange(e);
                              this.setState({ EEmail: e.target.value })
                            }}
                            onBlur={handleBlur}
                            value={!isEmptyNullUndefined(values.EEmail) ? values.EEmail : ''} />
                          {this.errorTag(errors, touched, status, 'EEmail')}
                        </Form.Field>

                        {/* Guest Checkbox */}
                        <Form.Field required width={6} error={(touched.Guest && isNaN(values.Guest)) || has(status, 'errors.Guest')}>
                          {/* <Button onClick={e=> this.onClickButton(e, status)}>click here</Button> */}
                          <label>Guest?</label>

                          <Checkbox
                            name="Guest"
                            onChange={() => { setFieldValue('Guest', !values.Guest) }}
                            checked={values.Guest}
                            onBlur={() => setFieldTouched('Guest', true)}
                            selected={values.CDate !== 'a' ? values.Guest : null}
                          />

                        </Form.Field>

                        {this.errorTag(errors, touched, status, 'Guest', 'Pick a Value. ', 'Pick a Value. ')}


                        {/* Observer Checkbox */}
                        <Form.Field required width={6} error={(touched.Observer && isNaN(values.Observer)) || has(status, 'errors.Observer')}>
                          {/* <Button onClick={e=> this.onClickButton(e, status)}>click here</Button> */}
                          <label>Observer?</label>

                          <Checkbox
                            name="Observer"
                            onChange={() => { setFieldValue('Observer', !values.Observer) }}
                            checked={values.Observer}
                            onBlur={() => setFieldTouched('Observer', true)}
                            selected={values.CDate !== 'a' ? values.Observer : null}
                          />

                        </Form.Field>

                        {this.errorTag(errors, touched, status, 'Observer', 'Pick a Value. ', 'Pick a Value. ')}

                        {/* Show Charts Checkbox */}
                        <Form.Field required width={6} error={(touched.ShowCharts && isNaN(values.ShowCharts)) || has(status, 'errors.ShowCharts')}>
                          {/* <Button onClick={e=> this.onClickButton(e, status)}>click here</Button> */}
                          <label>Show Charts?</label>

                          <Checkbox
                            name="ShowCharts"
                            onChange={() => { setFieldValue('ShowCharts', !values.ShowCharts) }}
                            checked={values.ShowCharts}
                            onBlur={() => setFieldTouched('ShowCharts', true)}
                            selected={values.CDate !== 'a' ? values.ShowCharts : null}
                          />

                        </Form.Field>

                        {this.errorTag(errors, touched, status, 'ShowCharts', 'Pick a Value. ', 'Pick a Value. ')}

                        {/* Show Finances Checkbox */}
                        <Form.Field required width={6} error={(touched.ShowFinances && isNaN(values.ShowFinances)) || has(status, 'errors.ShowFinances')}>
                          {/* <Button onClick={e=> this.onClickButton(e, status)}>click here</Button> */}
                          <label>Show Finances?</label>

                          <Checkbox
                            name="ShowFinances"
                            onChange={() => { setFieldValue('ShowFinances', !values.ShowFinances) }}
                            checked={values.ShowFinances}
                            onBlur={() => setFieldTouched('ShowFinances', true)}
                            selected={values.CDate !== 'a' ? values.ShowFinances : null}
                          />

                        </Form.Field>

                        {this.errorTag(errors, touched, status, 'ShowFinances', 'Pick a Value. ', 'Pick a Value. ')}

                      </React.Fragment>
                    }

                    <Divider />
                    <Button.Group size='large'>
                      <Button type='button' icon='cancel'
                        onClick={() => {
                          handleReset()
                          let ClientsMatters = this.state.ClientsMatters
                          this.setState(initialState)
                          this.setState({ ClientsMatters: ClientsMatters })
                        }}
                        color='red'
                        content='Cancel'></Button>
                      <Button.Or />
                      <Button color='teal'
                        onClick={() => {
                          setFieldValue('EFName', '')
                          setFieldValue('ELName', '')
                          setFieldValue('SDate', 'a')
                          setFieldValue('Picture', '')
                          setFieldValue('Level', 5)
                          setFieldValue('EEmail', '')
                          // setFieldTouched({})
                          this.setState({ Description: null, Matter: '', Invoice2Regex: [], Invoice2RegexSpecific: '' })
                        }}
                        type='button'
                        disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Form'>
                      </Button>
                      <Button.Or />
                      <Button color='orange'
                        onClick={() => { setStatus({}); setErrors({}) }}
                        type='button'
                        //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Error Notifications'>
                      </Button>
                      <Button.Or />
                      <Button type='submit'
                        // disabled={isSubmitting}
                        //Eventually use the below, the one above is just for testing
                        disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                        icon='upload'
                        loading={isSubmitting}
                        floated='right'
                        color={dirty ? 'green' : 'grey'}
                        content={(this.props.update === true ? 'Edit' : 'Add') + ' Employee'}></Button>
                    </Button.Group>
                    {/* <DisplayState state={this.state} /> */}
                    {/* <DisplayFormikState /> */}
                  </Form>


                </Segment>
              </div>
            )}
        </Formik>

      </React.Fragment >
    )
  }
}


const AddEmployeeSchema = Yup.object().shape({
  EFName: Yup
    .string()
    .min(3, 'First Name must be at least 3 characters.')
    .max(50, 'First Name cannot exceed 50 characters.')
    .required('Must have a First Name for this.'),
  ELName: Yup
    .string()
    .min(3, 'Last Name must be at least 3 characters.')
    .max(50, 'Last Name cannot exceed 50 characters.')
    .required('Must have a Last Name for this.'),
  SDate: Yup
    .date()
    .required()
    .typeError('No date selected.'),
  Picture: Yup
    .string()
    .min(3, 'Picture must be at least 3 characters.')
    .max(500, 'Picture cannot exceed 50 characters.')
    .required('Must have a Picture for this.'),
  Level: Yup
    .number()
    .required()
    .typeError('Must pick a partner level.'),
  EEmail: Yup
    .string()
    .email('Email must be a valid email.')
    .optional(),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddEmployee))

AddEmployee.defaultProps = {
  get: () => { },
  headingForForm: '',
}


