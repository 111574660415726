//This reducer is to keep track of fetching and errors

const initialstate = {
  fetching: false,
  fetchsuccess: false,
  fetchsuccessmessage: '',
  fetcherrors: [],
  fetchinfo: [],
}

export default (state =
  initialstate,

  action) => {
  switch (action.type) {

    case 'FETCH_START':
      // console.log('Fetching something....')
      return {
        ...state,
        fetching: true,
      }

    case 'FETCH_END':
      // console.log('Finished the fetch....')
      return {
        ...state,
        fetching: false,
      }

    case 'FETCH_SUCCESS_STATUS':
      // console.log('Inside Fetch_Success_Status reducer....')
      // console.log('This is the payload: ', action.payload)
      return {
        ...state,
        fetchsuccess: action.payload,
      }

    case 'FETCH_SUCCESS_MESSAGE':
      // console.log('Inside Fetch_Success_Message reducer....')
      // console.log('This is the payload: ', action.payload)
      if (action.payload.length > 0) {
        return {
          ...state,
          fetchsuccessmessage: action.payload,
          // fetcherrors: Object.assign({}, state.fetcherrors, action.payload),
        }
      }
      else { return { ...state } }

    case 'FETCH_ERRORS':
      // console.log('Inside Fetch_Errors reducer....')
      // console.log('This is the payload: ', action.payload)
      if (action.payload.length > 0) {
        return {
          ...state,
          fetcherrors: [...state.fetcherrors, ...action.payload],
          // fetcherrors: Object.assign({}, state.fetcherrors, action.payload),
        }
      }
      else { return { ...state } }

    case 'FETCH_INFO':
      // console.log('Inside Fetch_Info reducer....')
      // console.log('This is the payload: ', action.payload)
      if (action.payload.length > 0) {
        return {
          ...state,
          fetchinfo: [...state.fetchinfo, ...action.payload],
          // fetchinfo: Object.assign({}, state.fetchinfo, action.payload),
        }
      }
      else { return { ...state } }

    case 'FETCH_RESET':
      // console.log('Finished the fetch.... This is the payload: ', action.payload)
      switch (action.payload) {

        case 'errors':
          return {
            ...state,
            fetcherrors: []
          }
        case 'info':
          return {
            ...state,
            fetchinfo: []
          }
        case 'message':
          return {
            ...state,
            fetchsuccess: false,
            fetchsuccessmessage: ''
          }
        case 'all':
          return initialstate
        default:
          return { ...state }

      }

    default:
      return state
  }
} 