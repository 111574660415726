import React, { Component } from 'react'
// import { HashLink as Link } from 'react-router-hash-link'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import {  Icon, Image } from 'semantic-ui-react';

// import AddCounterparty from './Component-AddCounterparty';
// import CounterpartyInfo from './Component-CounterpartyInfo';
// import AddLawFirms from './Component-AddLawFirm';
// import AddPerson from './Component-AddPerson';
// import AddNote from './Component-AddNote';
import ReactTableMaker from '../helper/ReactTableMaker'


import queryString from 'query-string'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

// import ErrorMessage from '../helper/MessageBar'
// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
import { isEmptyNullUndefined } from '../helper/Misc';

class AssetClassInfo extends Component {

  state = {
    TokenReady: false,
    Loaded: false,
    querystr: {},
    MatterInfo: {
      // Counterparties: [],
    },
  }


  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
    }
    const querystr = queryString.parse(this.props.location.search)
    // console.log('Logging querystr: ', querystr.aClassID)
    this.setState({ querystr: querystr })
    // this.getAClassInfo()
  }


  // //This is to get rid of the unmounted component no op (as well as that signal: this.abortController stuff below))
  // abortController = new AbortController()
  // componentWillUnmount() {
  //   this.abortController.abort()
  // }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true })
    }
    if (prevState.querystr !== this.state.querystr) {
      // console.log('Here is aClassID.', this.state.querystr.aClassID)
      this.getAClassInfo()
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getAClassInfo(), 200)
    }
  }

  getAClassInfo = async () => {
    //  console.log('In getAClasses...', this.state.querystr.aClassID)
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('assetClassInfo', {
      fetchstr: 'getMattersForAssetClass',
      IDNumber: this.state.querystr.aClassID
    },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getAClasses: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      MatterInfo: fetchresponse.payload.map(item => {return {
        ...item,
        employee_name: this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[2]
       }}
      )
    })
  }



  render() {
    return (
      <div>
        <h1 style={{ float: 'left' }}>Asset Class Information For:</h1>
        <h2 style={{ marginLeft: '320px' }}>
          <Icon name='clipboard outline' size='large' />
          <span style={{ color: '#9ca7b8' }}>
            {this.state.querystr.aclass} (ID: {this.state.querystr.aClassID})
        </span>
        </h2>

        {!isEmptyNullUndefined(this.state.MatterInfo) &&
          <ReactTableMaker passedData={this.state.MatterInfo} passedColumns={AssetClassMattersColumns(this.props.history, this.props.clients.employeesarray)}
            handleClick={(rowID, cellID, rowValues) => {
              // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
              // this.setState({ selectedAClass: rowID })
              // this.getPaymentsForInvoice(rowID)
            }}
            includeSearchBar={false}
          />
        }

        {/* <DisplayState state={this.state} /> */}

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(AssetClassInfo)


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

let AssetClassMattersColumns = (history, employees) => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
      <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        style={{ 'float': 'left', cursor: 'pointer' }}
        onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
      >
        {cell.value}
      </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Originating Partner</span>,
      accessor: 'employee_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) => <span style={{ 'float': 'left' }}>
        {/* <Image src={window.location.origin + '/images/' + employees.find(employee => employee[0] === row.original['employee_id'])[4]} verticalAlign='bottom' width='20px' /> */}
        <Image src={employees.find(employee => employee[0] === row.original['employee_id'])[4]} verticalAlign='bottom' width='20px' />
        {/* {employees.find(employee => employee[0] === cell.value)[4]} */}
        {/* {employees[1][4]} */}
        {' '}{cell.value}</span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Matter Name</span>,
      accessor: 'matter_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Matter Origination Date</span>,
      accessor: 'matter_origination_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Matter Amount</span>,
      accessor: 'matter_deal_amount',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell }) => <span style={{ 'color': cell.value < 0 ? 'red' : 'black', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
    },

  ]
}
