import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// import { HashLink as Link } from 'react-router-hash-link'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon, Table } from 'semantic-ui-react';

import ReactTableMaker from '../helper/ReactTableMaker'


import queryString from 'query-string'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

class InvoiceInfo extends Component {

  state = {
    TokenReady: false,
    Loaded: false,
    querystr: {},
    InvoiceInfo: {},
    PaymentsForInvoice: [],
    InvoiceInfoArray: [],
  }


  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
    }
    const querystr = queryString.parse(this.props.location.search)
    // console.log('Logging querystr: ', querystr)
    this.setState({ querystr: querystr })
  }


  // //This is to get rid of the unmounted component no op (as well as that signal: this.abortController stuff below))
  // abortController = new AbortController()
  // componentWillUnmount() {
  //   this.abortController.abort()
  // }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true })
    }
    if (prevState.querystr !== this.state.querystr) {
      this.getInvoice()
      this.getPaymentForInvoice()
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => {
        this.getInvoice()
        this.getPaymentForInvoice()
      }, 200)
    }
  }

  getInvoice = async () => {

    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('invoiceInfo', {
      fetchstr: 'getInvoiceWithEmployees',
      invoiceID: this.state.querystr.invoiceID
    },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getInvoiceWithEmployees: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      InvoiceInfo: fetchresponse.payload
    }, () => this.setState({ InvoiceInfoArray: this.createInvoiceInfo() })
    )

  }


  getPaymentForInvoice = async () => {
    //  console.log('In getLawFirmInfo...', this.state.querystr.lawFirmID)
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getInvoicePayments', {
      fetchstr: 'getPaymentsForInvoice',
      invoiceID: this.state.querystr.invoiceID
    },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getPaymentsForInvoice: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      PaymentsForInvoice: fetchresponse.payload.map(item => {
        return {
          ...item,
          // employee_name: this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[2]
        }
      })
    }
    )
  }

  createInvoiceInfo = () => {
    let infoarray = [...Array(6)].map(x => Array(3).fill(0))
    infoarray[0][0] = 'Invoice Amount:'
    infoarray[0][1] = this.state.InvoiceInfo.invoice_amount
    infoarray[0][2] = 'invoice_amount'
    infoarray[0][3] = this.state.InvoiceInfo.invoice_id
    infoarray[1][0] = 'Invoice Date:'
    infoarray[1][1] = this.state.InvoiceInfo.invoice_date
    infoarray[1][2] = 'invoice_date'
    infoarray[1][3] = this.state.InvoiceInfo.invoice_id
    infoarray[2][0] = 'Invoice Description:'
    infoarray[2][1] = this.state.InvoiceInfo.invoice_description
    infoarray[2][2] = 'invoice_description'
    infoarray[2][3] = this.state.InvoiceInfo.invoice_id
    infoarray[3][0] = 'Matter:'
    infoarray[3][1] = this.state.InvoiceInfo.matter_name
    infoarray[3][2] = 'matter_name'
    infoarray[3][3] = this.state.InvoiceInfo.matter_id
    infoarray[4][0] = 'Originating Partner:'
    infoarray[4][1] = this.state.InvoiceInfo.originating_employee
    infoarray[4][2] = 'originating_employee'
    infoarray[4][3] = this.state.InvoiceInfo.invoice_id
    infoarray[5][0] = 'Invoicing Partner:'
    infoarray[5][1] = this.state.InvoiceInfo.invoicing_employee
    infoarray[5][2] = 'invoicing_employee'
    infoarray[5][3] = this.state.InvoiceInfo.invoice_id

    return infoarray
  }

  render() {
    return (
      <div>
        <h1 style={{ float: 'left' }}>Invoice Information For Invoice:</h1>
        <h2 style={{ marginLeft: '320px' }}>
          <Icon name='dollar sign' size='large' />
          <span style={{ color: '#04337d' }}>
            {this.state.InvoiceInfo.invoice_number} (ID: {this.state.querystr.invoiceID})
        </span>
        </h2>



        <h2>Invoice Information:</h2>

        {!isEmptyNullUndefined(this.state.InvoiceInfoArray) &&
          <Table basic='very' celled collapsing striped>
            <Table.Body >
              {this.state.InvoiceInfoArray.map((item, i) => {
                return <InvoiceInfoMaker rowdata={item}
                  number={i}
                  invoiceID={this.state.querystr.invoiceID}
                  // handleAClick={this.props.handleClick}
                  key={i}
                  history={this.props.history}
                />
              })}
            </Table.Body>
          </Table>
        }

        {isEmptyNullUndefined(this.state.PaymentsForInvoice) &&
          <h3>No payments to show for this invoice.</h3>
        }

        {/* This will show all clients/matters and law firms that this firm was involved in */}
        {!isEmptyNullUndefined(this.state.PaymentsForInvoice) &&
          <React.Fragment>
            <h4 >Payments for This Invoice</h4>
            <ReactTableMaker passedData={this.state.PaymentsForInvoice} passedColumns={PaymentColumns(this.props.history)}
              handleClick={(rowID, cellID, rowValues) => {
                // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
              }}
              includeSearchBar={false}
            />
          </React.Fragment>
        }

        {/* <DisplayState state={this.state} /> */}

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(InvoiceInfo)


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

let PaymentColumns = (history) => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Payment Number</span>,
      accessor: 'payment_number',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
      <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        style={{ 'float': 'left', cursor: 'pointer' }}
        onClick={() => history.push(`/payment/${row.original.payment_id}?payment=${row.original.payment_number}&paymentID=${row.original.payment_id}`)}
      >
        {cell.value}
      </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Payment Date</span>,
      accessor: 'payment_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: ''
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Payment Amount</span>,
      accessor: 'payment_amount',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => <span style={{ 'color': 'red', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Payment Description</span>,
      accessor: 'payment_description',
      show: true,
      sortType: 'alphanumeric',
      Filter: ''
    },
  ]
}

const InvoiceInfoMaker = (props) => {
  // console.log('In RowMaker and here are props: ', props.rowdata[2])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  // function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
  function plusFontColor(e) { e.target.style.color = "#ff8000" }
  // function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
  function minusFontColor(e) { e.target.style.color = "" }

  return (
    <Table.Row key={props.rowdata[2] + props.number}>
      <Table.Cell>
        {props.rowdata[0]}
      </Table.Cell >
      <Table.Cell>
        <React.Fragment>
          {props.rowdata[2] === 'matter_name' ?
            <span
              style={{ 'float': 'left', cursor: 'pointer' }}
              onMouseOver={plusFontColor} onMouseLeave={minusFontColor}
              onClick={() => props.history.push(`/matter/${props.rowdata[3]}?matter=${props.rowdata[1]}&matterID=${props.rowdata[3]}`)}
            >
              {props.rowdata[1]}
            </span> : 
            props.rowdata[2] === 'invoice_amount' ?
              formatter.format(props.rowdata[1])
              :
            props.rowdata[1]}
        </React.Fragment>
      </Table.Cell>
      <Table.Cell>
        <Link to={`/editinvoice/${props.invoiceID}`}><Icon name='edit' />Edit</Link>
      </Table.Cell>
    </Table.Row >
  )
}
