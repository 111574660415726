const CLIENT_ID = process.env.CLIENT_ID || '0oafqmiedufGu1IZY356';
// const ISSUER = process.env.ISSUER || 'https://dev-761499.okta.com/oauth2/default';
const ISSUER = 'https://dev-761499.okta.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

const CALLBACK_PATH = process.env.REACT_APP_AUTH_REDIRECT || 'https://so2020dev.herokuapp.com/implicit/callback' 

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: 'http://localhost:3000/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  heroku: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: CALLBACK_PATH,
    // redirectUri: 'https://so2020app.herokuapp.com/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
}
