import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';

import config from './App-Config'
import Home from './components/layout/Home'

// const CALLBACK_PATH = process.env.NODE_ENV === 'production' ? 'https://so2020app.herokuapp.com/implicit/callback' :  '/implicit/callback'
const CALLBACK_PATH2 = '/implicit/callback'

const App = () => (
  <div className='so-React-Container'>
    <Router>
      <Security {...(process.env.NODE_ENV === 'production' ? config.heroku : config.oidc)}>
        <Route path="/" component={Home} />
        <Route path={CALLBACK_PATH2} component={LoginCallback} />
        {/* Fix: Do I need to put some SecureRoutes in here? */}
      </Security>
    </Router>
  </div>
);
export default App


