import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, filterSimpleInput, ListMakerSimple } from '../helper/FormHelper'
import LocationContainer from './Component-AddMatter-Location-Container'
import { isEmptyNullUndefined } from '../helper/Misc'
import NumberFormat from 'react-number-format'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Header, Label, Divider, Dropdown, Button, Segment, Icon } from 'semantic-ui-react';
import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';
// import { isNullOrUndefined } from 'util';

// import 'leaflet/dist/leaflet.css'
// import '../charts/Charts-Leaflet-Map-Custom.css'
// import '../charts/Charts-Google-Geolocate-Bar.css'

const initialState = {
  visible: false,
  Clients: [],
  ClientDropDownArray: [],
  SelectedClient: {
    client_id: null,
    origination_date: null
  },
  ExistingMatters: [],
  Matter: '',
  Matter2Regex: [],
  Matter2RegexSpecific: '',
  MatterArray: [],
  statesArray: [],
  initialLocations: [],
  locations: [],
  locationsMounted: false,
  chosenStates: [33],
  initialValues: {},
  isMounted: false,
}

class AddMatter extends Component {

  constructor(props) {
    super(props);
    this.formikRef = React.createRef();
  }

  state = initialState

  async componentDidMount() {
    this.initialMatters()
    this.setState({ isMounted: true })
    this.setState({ isMounted: true })
  }

  componentWillUnmount() {
    this.setState({ isMounted: false })
  }

  initialMatters = async () => {
    this.props.fetchResetState('all')
    this.getClients()
    if (this.props.update) this.getSpecificMatter()
    //The below will download the states
    let fetchresponse = await szenokPostAPI('getStates', { fetchstr: 'getAllStates' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse: ', fetchresponse)
    this.state.isMounted && this.setState({ statesArray: fetchresponse.payload.map(item => { return { value: parseInt(item.state_id), text: item.state_name, abbr: item.state_abbr } }) })
  }

  getClients = async () => {
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('clientInfo', { fetchstr: 'allClients2' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getMatter: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.state.isMounted && this.setState({
      Clients: { ...fetchresponse.payload },
      ClientDropDownArray: fetchresponse.payload.map(result => {
        // console.log('This is result: ', result)
        var obj = {}
        obj.key = `CID${result.client_id}`
        obj.text = result.client_name
        obj.value = result.client_id
        obj.origination_date = result.origination_date
        return obj
      }
      )
    })
  }

  getSpecificMatter = async () => {
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('matterInfo', { fetchstr: 'getMatter', matterID: this.props.match.params.matterID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getMatter: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && !isEmptyNullUndefined(this.state.ClientDropDownArray) && this.state.isMounted && this.setState({
      initialValues: { ...fetchresponse.payload },
      SelectedClient: { client_id: fetchresponse.payload.client_id, origination_date: this.state.ClientDropDownArray.find(client => client.value === fetchresponse.payload.client_id).origination_date }
    })

    //Set ExistingMatters to the other matters for the loaded client
    if (this.state.isMounted) {
      fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'mattersforclient', IDNumber: this.state.initialValues.client_id },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for mattersforclient: ', fetchresponse)
      this.state.isMounted && this.setState({
        ExistingMatters: fetchresponse.payload.map(matter => {
          var arr = []
          arr[0] = matter.ID
          arr[1] = matter['Matter Name']
          return arr
        }
        )
      })
    }

    //Get Locations for the Selected Matter
    if (this.props.update) {
      fetchresponse = await szenokPostAPI('getStates', { fetchstr: 'locationsForSpecificMatter', IDNumber: this.state.initialValues.matter_id },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for mattersforclient: ', fetchresponse)
      this.state.isMounted && this.setState({
        locationsMounted: true,
        initialLocations: fetchresponse.payload.map(location => { return { coordinates: { lat: location.location.x, lng: location.location.y }, address: location.address, state: location.state_id, geoID: location.geoID } })
      })
    }
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ Matter2Regex: updatedListGeneralSorted })
    if (updatedListSpecific.length > 0) {
      // console.log('This is updatedListSpecific: ', updatedListSpecific)
      if (this.props.update && updatedListSpecific[0][1] !== this.state.initialValues.matter_name) {
        this.setState({ Matter2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
      else if (this.props.update && updatedListSpecific[0][1] === this.state.initialValues.matter_name) {
        this.setState({ Matter2RegexSpecific: '' })
        setStatus({ errors: {} })
      }
      if (!this.props.update) {
        this.setState({ Matter2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ Matter2RegexSpecific: '' })
      setStatus({ errors: {} })
    }

  }, 100)

  // ClientDropDownArray = () => {
  //   var array = this.props.clients.clientsarray.map(result => {
  //     // console.log('This is result: ', result)
  //     var obj = {}
  //     obj.key = `CID${result[0]}`
  //     if (result[1].length > 0) obj.text = result[1]
  //     else obj.text = result[3] + ', ' + result[2]
  //     obj.value = result[0]
  //     return obj
  //   }
  //   )
  //   // console.log('This is array: ', array)
  //   return array
  // }

  TTypeDropDownArray = () => {
    var array = this.props.clients.transactiontypesandsubsarray.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `EID${result[0]},${result[1]}`
      obj.text = result[2] + ' -- ' + result[3]
      obj.value = result[0] + ',' + result[1]
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }

  AClassDropDownArray = () => {
    var array = this.props.clients.assetclassarray.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `EID${result[0]}}`
      obj.text = result[1]
      obj.value = result[0]
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }

  handleLocations = (locations) => {
    // console.log('Received locations here: ', locations)

    //Pull out the states and populate state.chosenStates2
    let chosenStates = Array.from(new Set(locations.map(location => this.state.statesArray.find(state => state.abbr === location.state).value)))
    // console.log('chosenStates is: ', chosenStates)
    this.setState({ chosenStates2: chosenStates })

    // //Pull out the coordinates and populate state.locations
    let locationsForState = locations.map(location => {
      return {
        state: this.state.statesArray.find(state => state.abbr === location.state).value,
        coordinates: location.coordinates,
        address: location.address,
        geoID: location.geoID,
      }
    })
    // console.log('locationsForState is: ', locationsForState)
    this.setState({ locations: locationsForState }, () => {
      this.formikRef.current.setFieldValue('geoIDsString', this.state.locations.map(location => location.geoID).join())
    })
  }

  render() {

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      Client: null,
      Matter: '',
      MDate: 'a',
      CDate: null,
      TType: '',
      AClass: null,
      DealAmount: null,
      geoIDsString: '',
      // StatesArray: this.state.chosenStates,
    } : {
        Client: this.state.initialValues.client_id,
        Matter: this.state.initialValues.matter_name,
        MDate: new Date(this.state.initialValues.matter_origination_date),
        CDate: !isEmptyNullUndefined(new Date(this.state.initialValues.closing_date)) ? new Date(this.state.initialValues.closing_date) : null,
        TType: this.state.initialValues.transaction_type_id + ',' + this.state.initialValues.sub_transaction_type_id,
        SubTType: this.state.initialValues.sub_transaction_type_id,
        AClass: this.state.initialValues.asset_class_id,
        DealAmount: this.state.initialValues.matter_deal_amount,
        geoIDsString: this.state.initialLocations.map(location => location.geoID).join(),
        // StatesArray: this.state.initialValues.statesIDs.split(',').map(item => parseInt(item)),
      }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300


    return (
      <div>
        <Header as='h1'>Matter {this.props.update === true ? 'Edit' : 'Add'} Form</Header>
        {this.props.update && !isEmptyNullUndefined(this.state.initialValues) &&
          <Header as='h2'>Editing: {this.state.initialValues.matter_name}</Header>
        }

        <Formik
          innerRef={this.formikRef}
          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={AddMatterSchema}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              this.state.isMounted && this.setState({ Matter2Regex: [], Matter2RegexSpecific: '' })
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editMatter', {
                ...values, TType: values.TType.split(',')[0],
                SubTType: values.TType.split(',')[1], matterID: this.props.match.params.matterID,
                Locations: this.state.locations
                // StatesArray: values.StatesArray
              }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addMatter', {
                Matter: values.Matter, Client: values.Client, MDate: values.MDate, CDate: values.CDate, TType: values.TType.split(',')[0],
                SubTType: values.TType.split(',')[1], AClass: values.AClass, DealAmount: values.DealAmount,
                Locations: this.state.locations
                // StatesArray: values.StatesArray
              }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for edit/addMatter: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                //Update the matters store
                reduxupdate('matters')
                reduxupdate('locations')
                // if (this.props.update) await this.getSpecificMatter()

                //Rest of stuff
                if (this.props.update) this.props.history.goBack()
                else {
                  this.state.isMounted && this.setState(prevState => ({ visible: !prevState.visible }))
                  actions.resetForm({ ...initialValues })
                  this.state.isMounted && this.setState({ ...initialState, isMounted: true }, () => this.initialMatters())
                  // this.initialMatters()
                }
              }
            }
            catch (error) { this.props.fetchErrors(error.toString()) }
            actions.setSubmitting(false)

          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment color='grey' raised>
                  <Form onSubmit={handleSubmit} noValidate autoComplete='off'>

                    {/* First, put in the Client */}
                    <Form.Group>
                      <Form.Field required width={12} error={(touched.Client && isEmptyNullUndefined(values.Client)) || has(status, 'errors.Client')}>
                        <label>Client</label>
                        <Dropdown
                          placeholder='Select Client'
                          name="Client"
                          width={6}
                          required
                          search
                          label='Client'
                          error={(touched.Client && (typeof get(values, 'Client') === 'undefined' || get(values, 'Client') === null)) ||
                            (has(status, 'errors.Client'))}
                          closeOnBlur
                          selection
                          value={values.Client}
                          onBlur={() => setFieldTouched('Client', true)}
                          options={this.state.ClientDropDownArray}
                          // value={this.state.success === true ? null : values.TType}
                          loading={this.props.fetch.fetching}
                          onChange={async (e, { name, value }) => {
                            setFieldValue(name, value)
                            // console.log('This is the value: ', value)
                            if (this.state.isMounted) {
                              let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'mattersforclient', IDNumber: value },
                                this.props.login.currentToken)
                              // console.log('Now printing fetchresponse for tablesFetch: ', fetchresponse)
                              //Set ExistingMatters to the other matters for the loaded client
                              this.state.isMounted && this.setState({
                                ExistingMatters: fetchresponse.payload.map(matter => {
                                  var arr = []
                                  arr[0] = matter.ID
                                  arr[1] = matter['Matter Name']
                                  return arr
                                }
                                ),
                                SelectedClient: { client_id: value, origination_date: this.state.ClientDropDownArray.find(client => client.value === value).origination_date }
                              })
                            }
                          }}
                        />
                      </Form.Field>
                    </Form.Group>

                    {this.errorTag(errors, touched, status, 'Client', 'Pick a Client. ', 'Pick a Client. ')}

                    {/* Show the rest of this only if there is a client chosen, first */}
                    {!isEmptyNullUndefined(values.Client) &&
                      <React.Fragment>

                        <Form.Group>

                          {/* Add the Matter Name and check uniqueness */}
                          <Form.Field width={5}>
                            <Form.Input
                              name="Matter" type="text"
                              label='Matter Name'
                              loading={this.props.fetch.fetching}
                              required
                              placeholder='Matter'
                              error={(errors.Matter && touched.Matter) || (has(status, 'errors.Matter'))}
                              onChange={async (e) => {
                                await handleChange(e);
                                this.state.isMounted && this.setState({ Matter: e.target.value })
                                this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, this.state.ExistingMatters, { Matter: 'Name already used' })
                              }}
                              onBlur={handleBlur}
                              value={values.Matter} />
                            {this.errorTag(errors, touched, status, 'Matter')}
                          </Form.Field>
                        </Form.Group>

                        <Form.Field required width={6} error={(touched.MDate && isNaN(values.MDate)) || has(status, 'errors.MDate')}>
                          <label>Matter Creation Date {!isEmptyNullUndefined(this.state.SelectedClient) ? `(client originated on ${this.state.SelectedClient.origination_date})` : null}</label>

                          {/* Matter Creation Date */}
                          <DatePicker
                            name="MDate"
                            onChange={async (dateString) => {
                              await setFieldValue('MDate', dateString)
                            }}
                            dateFormat='MMMM d, yyyy'
                            minDate={new Date(!isEmptyNullUndefined(this.state.SelectedClient) ? this.state.SelectedClient.origination_date : '2011,01,01')}
                            maxDate={new Date()}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            withPortal
                            loading={this.props.fetch.fetchsuccess}
                            onBlur={() => setFieldTouched('MDate', true)}
                            todayButton={'Today'}
                            selected={values.MDate !== 'a' ? values.MDate : null}
                            placeholderText="Select an origination date"
                          />
                        </Form.Field>

                        {this.errorTag(errors, touched, status, 'MDate', 'Pick a Date. ', 'Pick a Date. ')}

                        <Form.Field width={6} error={(touched.CDate && isNaN(values.CDate)) || has(status, 'errors.CDate')}>
                          <label>Matter Closing Date
                            {isValidDate(values.MDate) ? ` (Matter originated on ${values.MDate.toLocaleString('sv-SE', { month: '2-digit', year: 'numeric', day: '2-digit' })})` :
                              !isEmptyNullUndefined(this.state.SelectedClient) ? ` (client originated on ${this.state.SelectedClient.origination_date})` : null}
                            {/* !isEmptyNullUndefined(this.state.SelectedClient) ? `(client originated on ${this.state.SelectedClient.origination_date})` : null} */}
                          </label>
                          <label>(not required -- if empty, denotes deal did not close)
                            <Icon name='undo alternate' color='blue' style={{cursor: 'pointer', paddingLeft: '10px'}} size='small' onClick={() => setFieldValue('CDate', null)}>reset</Icon></label>

                          {/* Matter Closing Date */}
                          <DatePicker
                            name="CDate"
                            onChange={async (dateString) => {
                              await setFieldValue('CDate', dateString)
                            }}
                            dateFormat='MMMM d, yyyy'
                            minDate={new Date(isValidDate(values.MDate) ? values.MDate : !isEmptyNullUndefined(this.state.SelectedClient) ? this.state.SelectedClient.origination_date : '2011,01,01')}
                            maxDate={new Date()}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            withPortal
                            loading={this.props.fetch.fetchsuccess}
                            onBlur={() => setFieldTouched('CDate', true)}
                            todayButton={'Today'}
                            selected={values.CDate !== 'a' ? values.CDate : null}
                            placeholderText="Select a closing date (currently open)"
                          />
                        </Form.Field>

                        {this.errorTag(errors, touched, status, 'CDate', 'Pick a Date. ', 'Pick a Date. ')}

                        {/* Transaction Type */}
                        <Form.Field required width={6} error={(touched.TType && isEmptyNullUndefined(values.TType)) || has(status, 'errors.TType')}>
                          <label>Select a Transaction Type (and subtype)</label>

                          <Dropdown
                            placeholder='Select Transaction Type'
                            name="TType"
                            width={2}
                            required
                            search
                            label='Transaction Type'
                            error={(touched.TType && (typeof get(values, 'TType') === 'undefined' || get(values, 'TType') === null)) ||
                              (has(status, 'errors.TType'))}
                            closeOnBlur
                            selection
                            onBlur={() => setFieldTouched('TType', true)}
                            options={this.TTypeDropDownArray()}
                            value={values.TType}
                            loading={this.props.fetch.fetching}
                            onChange={(e, { name, value }) => {
                              setFieldValue(name, value)
                              //console.log(name, value)
                            }}
                          />
                          {this.errorTag(errors, touched, status, 'TType')}
                        </Form.Field>

                        {/* Asset Class */}
                        <Form.Field required width={6} error={(touched.AClass && isEmptyNullUndefined(values.AClass)) || has(status, 'errors.AClass')}>
                          <label>Select an Asset Class</label>

                          <Dropdown
                            placeholder='Select Asset Class'
                            name="AClass"
                            width={2}
                            required
                            search
                            label='Asset Class'
                            error={(touched.AClass && (typeof get(values, 'AClass') === 'undefined' || get(values, 'AClass') === null)) ||
                              (has(status, 'errors.AClass'))}
                            closeOnBlur
                            selection
                            onBlur={() => setFieldTouched('AClass', true)}
                            options={this.AClassDropDownArray()}
                            value={values.AClass}
                            loading={this.props.fetch.fetching}
                            onChange={(e, { name, value }) => {
                              setFieldValue(name, value)
                              //console.log(name, value)
                            }}
                          />
                          {this.errorTag(errors, touched, status, 'AClass')}
                        </Form.Field>

                        {/* Amount */}
                        <Form.Field width={6} error={has(status, 'errors.DealAmount')}>
                          <label>Insert Value of Deal (in $)</label>

                          <NumberFormat
                            name="DealAmount"
                            label='Deal Amount'
                            thousandSeparator={true}
                            prefix={'$'}
                            onBlur={() => setFieldTouched('DealAmount', true)}
                            placeholder='Value of deal...'
                            value={values.DealAmount}
                            onValueChange={(e) => {
                              // console.log('This is the value: ', e.floatValue)
                              setFieldValue('DealAmount', isEmptyNullUndefined(e.floatValue) ? null : e.floatValue)
                            }} />
                          {this.errorTag(errors, touched, status, 'DealAmount')}
                        </Form.Field>

                        {/* States */}
                        {/* <Form.Field required width={6} error={(touched.StatesArray && isEmptyNullUndefined(values.StatesArray)) || has(status, 'errors.StatesArray')}>
                          <label>Select States Applicable to Deal</label>

                          <Dropdown
                            placeholder='Select States'
                            name="StatesArray"
                            width={2}
                            required
                            search
                            label='States'
                            multiple
                            error={(touched.StatesArray && (typeof get(values, 'StatesArray') === 'undefined' || get(values, 'StatesArray') === null)) ||
                              (has(status, 'errors.StatesArray'))}
                            closeOnBlur
                            selection
                            onBlur={() => setFieldTouched('StatesArray', true)}
                            options={this.state.statesArray}
                            value={values.StatesArray}
                            loading={this.props.fetch.fetching}
                            onChange={(e, { name, value }) => {
                              setFieldValue(name, value)
                              // this.setState({ chosenStates: value })
                              //console.log(name, value)
                            }}
                          />
                          {this.errorTag(errors, touched, status, 'StatesArray')}
                        </Form.Field> */}

                        {((this.props.update && this.state.locationsMounted) || !this.props.update) &&
                          <div>
                            <LocationContainer
                              Height={200}
                              token={this.props.login.currentToken}
                              sendLocationsToParent={this.handleLocations}
                              locationsForEditMatter={this.state.initialLocations.map(location => {
                                return {
                                  state: !isEmptyNullUndefined(this.state.statesArray) ? this.state.statesArray.find(state => state.value === location.state).abbr : 33,
                                  coordinates: location.coordinates,
                                  address: location.address,
                                  geoID: location.geoID,
                                }
                              })}
                            />
                          </div>}
                      </React.Fragment>
                    }

                    <Segment secondary={this.state.Matter2RegexSpecific.length === 0} color={this.state.Matter2RegexSpecific.length > 0 ? 'red' : 'blue'}
                      hidden={this.state.Matter2Regex.length === 0}>
                      {ListMakerSimple(this.state.Matter2Regex, values, this.state.Matter2RegexSpecific, 'Matter')}
                    </Segment>



                    <Divider />
                    <Button.Group size='large'>
                      <Button type='button' icon='cancel'
                        onClick={() => {
                          handleReset();
                          this.state.isMounted && this.setState({ Matter: '', Matter2Regex: [], Matter2RegexSpecific: '' })
                        }}
                        color='red'
                        content='Cancel'></Button>
                      <Button.Or />
                      <Button color='teal'
                        onClick={() => { this.state.isMounted && this.setState({ Matter: '', Matter2Regex: [], Matter2RegexSpecific: '' }); handleReset() }}
                        type='button'
                        disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Form'>
                      </Button>
                      <Button.Or />
                      <Button color='orange'
                        onClick={() => { setStatus({}) }}
                        type='button'
                        //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Error Notifications'>
                      </Button>
                      <Button.Or />
                      <Button type='submit'
                        // disabled={isSubmitting}
                        //Eventually use the below, the one above is just for testing
                        disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                        icon='upload'
                        loading={isSubmitting}
                        floated='right'
                        color={dirty ? 'green' : 'grey'}
                        content={(this.props.update === true ? 'Edit' : 'Add') + ' Matter'}></Button>
                    </Button.Group>
                    {/* <DisplayState state={this.state} /> */}
                    {/* <DisplayFormikState /> */}
                  </Form>
                </Segment>
              </div>
            )}
        </Formik>

      </div>
    )
  }
}


const AddMatterSchema = Yup.object().shape({
  Matter: Yup
    .string()
    .min(3, 'Matter name must be at least 3 characters.')
    .max(255, 'Matter name cannot exceed 255 characters.')
    .required('Must have a matter name.'),
  Client: Yup
    .number()
    .required()
    .typeError('Must pick a client.'),
  MDate: Yup
    .date()
    .required()
    .typeError('No origination date selected.'),
  CDate: Yup
    .date()
    .optional()
    .nullable().default(null),
  // .typeError('No closing date selected.'),
  TType: Yup
    .string()
    .required('Must have a transaction type.'),
  AClass: Yup
    .number()
    .required()
    .typeError('Must pick an asset class.'),
  DealAmount: Yup
    .number()
    .nullable()
    .min(0)
    .max(10000000000)
    .optional(),
  // StatesArray: Yup
  //   .array()
  //   .of(Yup.number().required('Must pick at least one state'))
  //   .strict()
  //   .required('Must pick at least one state.'),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(AddMatter)




function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}