import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Button, Segment, Table, Icon, List } from 'semantic-ui-react';

import AddNote from './Component-AddNote';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

class MattersInfoNotes extends Component {

  state = {
    selectedClient: null,
    noteType: '',
  }

  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
    }
  }

  componentDidUpdate() {
    //If we did not have a token initially, see if it's come in and then run fetch.
    //This on
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true, FilteredSearchData: this.state.Data })
    }
  }

  selectNoteType = (type) => {
    this.setState({ noteType: type })
  }

  render() {

    //I ended up not using this because it doesn't lodge the current page into history and so back doesn't go back correctly.
    // if(this.state.selectedClient !== null) {
    //   // If we click on a row, then React-Router redirects to the /client route
    //   // The param will be the client ID and the query string will be the 'Client Name' from the Data state that holds that ID number.
    //   // return (<Redirect to={`/client/${this.state.selectedClient}?matter=${this.state.Data.find(o => o.ID === this.state.selectedClient)['Client Name']}`} />)
    // }
    // console.log('In MattersInfoNotes and these are the props: ', this.props)

    return (

      <Segment color='orange'>
        <h2 id='Notes'><Icon name='comment alternate outline' size='large' />Notes:</h2>

        <Table basic='very' celled collapsing striped >
          <Table.Body >

            {/* Add General Notes for Matter */}
            <MatterNoteMaker rowdata={this.props.MatterNotes}
              MatterNotes={this.props.MatterNotes}
              showAddLawyerFormToggle={this.props.showAddLawyerFormToggle}
              showAddLawyerForm={this.props.showAddLawyerForm}
              showAddTycoonFormToggle={this.props.showAddTycoonFormToggle}
              showAddTycoonForm={this.props.showAddTycoonForm}
              showAddNoteFormToggle={this.props.showAddNoteFormToggle}
              showAddNoteForm={this.props.showAddNoteForm}
              selectCParty={this.props.selectCParty}
              selectedCParty={this.props.selectedCParty}
              selectedCPartyMatterID={this.props.selectedCPartyMatterID}
              selectedLawFirmID={this.props.selectedLawFirmID}
              getLawyers={this.props.getLawyers}
              getTycoons={this.props.getTycoons}
              getNotes={this.props.getNotes}
              selectNoteType={this.selectNoteType}
              noteType={this.state.noteType}
              employees={this.props.clients.employeesarray}
              Matter={this.props.Matter}
              MatterID={this.props.MatterID}
              handleDeleteNote={this.props.handleDeleteNote}
              currentObserver={!!this.props.login.currentObserver}
            // NotesLength={this.props.NotesLength}
            />


            {/* Add Notes for Counterparties and Law Firms */}
            {this.props.Counterparties.length === 0 ? null :
              this.props.Counterparties.map((item, i) => {
                return <NoteInfoMaker rowdata={item}
                  lawfirm={this.props.clients.lawfirmsarray.find(o => o[0] === item.law_firm_id)[1]}
                  tycoon={this.props.Counterparties.find(o => o.counterparty_id === item.counterparty_id).counterparty}
                  LawFirmID={item.law_firm_id}
                  lawyers={this.props.Lawyers.filter(o => o.law_firm_id === item.law_firm_id)}
                  tycoons={this.props.Tycoons.filter(o => o.counterparty_matter_id === item.counterparty_matter_id)}
                  lawyernotes={this.props.LawyerNotes.filter(o => o.counterparty_matter_id === item.counterparty_matter_id)}
                  tycoonnotes={this.props.TycoonNotes.filter(o => o.counterparty_matter_id === item.counterparty_matter_id)}
                  number={i}
                  showAddLawyerFormToggle={this.props.showAddLawyerFormToggle}
                  showAddLawyerForm={this.props.showAddLawyerForm}
                  showAddTycoonFormToggle={this.props.showAddTycoonFormToggle}
                  showAddTycoonForm={this.props.showAddTycoonForm}
                  showAddNoteFormToggle={this.props.showAddNoteFormToggle}
                  showAddNoteForm={this.props.showAddNoteForm}
                  selectCParty={this.props.selectCParty}
                  selectedCParty={this.props.selectedCParty}
                  selectedCPartyMatterID={this.props.selectedCPartyMatterID}
                  selectedLawFirmID={this.props.selectedLawFirmID}
                  getLawyers={this.props.getLawyers}
                  getTycoons={this.props.getTycoons}
                  getNotes={this.props.getNotes}
                  selectNoteType={this.selectNoteType}
                  noteType={this.state.noteType}
                  employees={this.props.clients.employeesarray}
                  Matter={this.props.Matter}
                  history={this.props.history}
                  key={i}
                  // NotesLength={this.props.NotesLength}
                  handleDeleteNote={this.props.handleDeleteNote}
                  currentObserver={!!this.props.login.currentObserver}
                />
              })}


          </Table.Body>
        </Table>

      </Segment>


    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MattersInfoNotes))

class MatterNoteMaker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAddNoteForm: false };
  }

  render() {
    // console.log('These are props: ', this.props)

    return (
      <React.Fragment>

        {/* Add a general Matter Notes Row */}
        <Table.Row key={`NIMM${this.props.rowdata.note_id}`}>
          <Table.Cell width={3}>
            <Icon name='chess bishop' size='large' />
            {this.props.Matter} (General Notes)
            <p />
            <Button disabled={this.props.currentObserver} compact size='mini' icon color='green' basic labelPosition='left'
              onClick={() => {
                this.props.showAddNoteFormToggle(true)
                // this.props.selectCParty(this.props.rowdata.counterparty_id, this.props.rowdata.counterparty_matter_id)
                this.props.showAddNoteFormToggle(true)
                this.props.selectNoteType('addMatterNote')
                // console.log('This is the id: ', this.props.rowdata.counterparty_matter_id, 'and this is props.selectedCparty: ', this.props.selectedCParty)
              }}
            >
              <Icon name='plus' size='small' />
              Add a note
              </Button>
          </Table.Cell>
          <Table.Cell >
            <React.Fragment>

              {/* Add a matter note */}
              {this.props.showAddNoteForm && this.props.noteType === 'addMatterNote' &&
                <Segment>
                  <AddNote
                    showAddNoteFormToggle={this.props.showAddNoteFormToggle}
                    showAddNoteForm={this.props.showAddNoteForm}
                    // selectedCPartyMatterID={this.props.selectedCPartyMatterID}
                    // selectedCParty={this.props.selectedCParty}
                    // selectedLawFirmID={this.props.selectedLawFirmID}
                    getNotes={this.props.getNotes}
                    noteType={this.props.noteType}
                    MatterID={this.props.MatterID}
                    typeOfNote='Matter'
                  />
                </Segment>
              }

              {/* List all of the notes for this matter */}
              {this.props.MatterNotes.length <= 0 ?
                'No notes entered yet...' :
                this.props.MatterNotes.map((item, i) => {
                  return <NoteListMaker
                    note={item.note}
                    note_id={item.note_id}
                    noteDate={item.note_date}
                    employee={this.props.employees.find(o => o[0] === item.employee_id)}
                    number={i}
                    handleDeleteNote={this.props.handleDeleteNote}
                    // handleAClick={this.props.handleClick}
                    entity={this.props.Matter}
                    key={`NLM${i}`}
                    // NotesLength={this.props.NotesLength}
                    type='matterNote'
                    currentObserver={!!this.props.currentObserver}
                  />
                })
              }
            </React.Fragment>
          </Table.Cell>

          {/* <Table.Cell ><Button
            color='blue'
            size='small'
            basic
            onClick={async () => {
              console.log(`Clicked on ${this.props.rowdata.matter_id}.`)
            }}
            content='Update' />
          </Table.Cell> */}

        </Table.Row>
      </React.Fragment>
    )
  }
}


class NoteInfoMaker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAddNoteForm: false };
  }

  render() {
    // console.log('These are props in NoteInfoMaker: ', this.props)

    function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
    function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

    return (
      <React.Fragment>

        {/* Add a Counterparty Notes Row */}
        <Table.Row key={`NIM${this.props.rowdata.counterparty_id}`}>
          <Table.Cell width={3}>
            <Icon name='address book outline' size='large' />
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{  cursor: 'pointer' }}
              onClick={() => {
                // console.log('Yo, this is the row: ', row)
                this.props.history.push(`/counterparty/${this.props.rowdata.counterparty}?counterparty=${this.props.rowdata.counterparty}&counterpartyID=${this.props.rowdata.counterparty_id}`)
              }}
            >
              {this.props.rowdata.counterparty}
        </span>

            <p />
            <Button disabled={this.props.currentObserver} compact size='mini' icon color='green' basic labelPosition='left'
              onClick={() => {
                this.props.showAddNoteFormToggle(true)
                this.props.selectCParty(this.props.rowdata.counterparty_id, this.props.rowdata.counterparty_matter_id)
                this.props.showAddNoteFormToggle(true)
                this.props.selectNoteType('addTycoonNote')
                // console.log('This is the id: ', this.props.rowdata.counterparty_matter_id, 'and this is props.selectedCparty: ', this.props.selectedCParty)
              }}
            >
              <Icon name='plus' size='small' />
              Add a note
              </Button>
          </Table.Cell>
          <Table.Cell >
            <React.Fragment>

              {/* Add a counterparty (tycoon) note */}
              {this.props.showAddNoteForm && this.props.rowdata.counterparty_matter_id === this.props.selectedCPartyMatterID && this.props.noteType === 'addTycoonNote' &&
                <Segment>
                  <AddNote
                    showAddNoteFormToggle={this.props.showAddNoteFormToggle}
                    showAddNoteForm={this.props.showAddNoteForm}
                    selectedCPartyMatterID={this.props.selectedCPartyMatterID}
                    selectedCParty={this.props.selectedCParty}
                    selectedLawFirmID={this.props.selectedLawFirmID}
                    getNotes={this.props.getNotes}
                    noteType={this.props.noteType}
                    typeOfNote='Counterparty'
                  />
                </Segment>
              }

              {/* List all of the notes for this counterparty */}
              {this.props.tycoonnotes.length <= 0 ?
                'No notes entered yet...' :
                this.props.tycoonnotes.map((item, i) => {
                  return <NoteListMaker
                    note={item.note}
                    note_id={item.note_id}
                    noteDate={item.note_date}
                    employee={this.props.employees.find(o => o[0] === item.employee_id)}
                    number={i}
                    handleDeleteNote={this.props.handleDeleteNote}
                    // handleAClick={this.props.handleClick}
                    entity={this.props.tycoon}
                    key={`NLM${i}`}
                    // NotesLength={this.props.NotesLength}
                    type='tycoonNote'
                    currentObserver={!!this.props.currentObserver}
                  />
                })
              }
            </React.Fragment>
          </Table.Cell>

          {/* <Table.Cell ><Button
            color='blue'
            size='small'
            basic
            onClick={async () => {
              console.log(`Clicked on ${this.props.rowdata.counterparty_id}.`)
            }}
            content='Update' />
          </Table.Cell> */}
        </Table.Row>

        {/* Add a Law Firm Notes Row */}

        <Table.Row>
          <Table.Cell width={3}>
            <Icon name='balance scale' size='large' />
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{  cursor: 'pointer' }}
              onClick={() => {
                // console.log('Yo, this is the row: ', row)
                this.props.history.push(`/lawfirm/${this.props.lawfirm}?lawfirm=${this.props.lawfirm}&lawFirmID=${this.props.LawFirmID}`)}
              }
            >
       {this.props.lawfirm}
        </span>
           
            <p />
            <Button disabled={!!this.props.currentObserver} compact size='mini' icon color='green' basic labelPosition='left'
              onClick={() => {
                this.props.showAddNoteFormToggle(true)
                this.props.selectCParty(this.props.rowdata.counterparty_id, this.props.rowdata.counterparty_matter_id)
                this.props.showAddNoteFormToggle(true)
                this.props.selectNoteType('addLawyerNote')
                // console.log('This is the id: ', this.props.rowdata.counterparty_matter_id, 'and this is props.selectedCparty: ', this.props.selectedCParty)
              }}
            >
              <Icon name='plus' size='small' />
              Add a note
                </Button>
          </Table.Cell>
          <Table.Cell>
            <React.Fragment>

              {/* Add a law firm note */}
              {this.props.showAddNoteForm && this.props.rowdata.counterparty_matter_id === this.props.selectedCPartyMatterID && this.props.noteType === 'addLawyerNote' &&
                <Segment>
                  <AddNote
                    showAddNoteFormToggle={this.props.showAddNoteFormToggle}
                    showAddNoteForm={this.props.showAddNoteForm}
                    selectedCPartyMatterID={this.props.selectedCPartyMatterID}
                    selectedCParty={this.props.selectedCParty}
                    selectedLawFirmID={this.props.selectedLawFirmID}
                    getNotes={this.props.getNotes}
                    noteType={this.props.noteType}
                    typeOfNote='Law Firm'
                  />
                </Segment>
              }

              {/* List all of the notes for this law firm */}
              {this.props.lawyernotes.length <= 0 ?
                'No notes entered yet...' :
                this.props.lawyernotes.map((item, i) => {
                  return <NoteListMaker
                    note={item.note}
                    note_id={item.note_id}
                    noteDate={item.note_date}
                    employee={this.props.employees.find(o => o[0] === item.employee_id)}
                    number={i}
                    handleDeleteNote={this.props.handleDeleteNote}
                    // handleAClick={this.props.handleClick}
                    entity={this.props.lawfirm}
                    key={`NLM${i}`}
                    // NotesLength={this.props.NotesLength}
                    type='lawyerNote'
                    currentObserver={!!this.props.currentObserver}
                  />
                })
              }
              <NoteListMaker />
            </React.Fragment>
          </Table.Cell>
          {/* <Table.Cell>
            <Button
              color='blue'
              size='small'
              basic
              onClick={async () => {
                console.log(`Clicked on ${this.props.rowdata.counterparty_id}.`)
              }}
              content='Update' />
          </Table.Cell> */}
        </Table.Row>
      </React.Fragment>
    )
  }
}

const NoteListMaker = (props) => {
  // console.log('This is the props in NoteListMaker: ', props)


  let IconName = props.type === 'tycoonNote' ? 'comment' : 'comment outline'
  if (!isEmptyNullUndefined(props))
    return (
      // let employee = props.employee[2].concat(', ', props.employee[1].charAt(0))
      <List>
        <Icon name={IconName} size='small' />
        <u>({props.noteDate}) {props.employee[2].concat(', ', props.employee[1].charAt(0))}.</u><br />
        {!props.currentObserver && <Icon name='delete' color='red' size='tiny' bordered link onClick={() => props.handleDeleteNote(props.note_id, props.type)} />}
        {props.note}
      </List>
    )
  else return null
}