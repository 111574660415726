import React from "react";

function SzenokTinyLogo(width=82.667, height=82.667) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 82 82'>
      <defs>
        <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
          <path d="M0 0h62v59.053H0V0z"></path>
        </clipPath>
      </defs>
      {/* <g transform='scale(.2,.2)' > */}
      <g transform="matrix(1.33333 0 0 -1.33333 0 82.667)">
        <g>
          <g
            fillRule="nonzero"
            clipPath="url(#clipPath18)"
            fontWeight="normal"
          >
            <text
              fill="#231f20"
              fontFamily="'Agency FB'"
              fontSize="73"
              transform="matrix(1 0 0 -1 33.5 2.877)"
            >
              <tspan x="0" y="0">
                O
              </tspan>
              <tspan x="-37.595" y="0.219">
                S
              </tspan>
            </text>
            <text
              fill="#f15a29"
              fontFamily="'Poor Richard'"
              fontSize="52"
              transform="matrix(1 0 0 -1 10 2.553)"
            >
              <tspan x="0" y="0">
                &amp;
              </tspan>
            </text>
          </g>
        </g>
      </g>
      {/* </g> */}
    </svg>
  );
}

export default SzenokTinyLogo;