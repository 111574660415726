import React, { Component } from 'react'
// import { HashLink as Link } from 'react-router-hash-link'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

// import AddCounterparty from './Component-AddCounterparty';
// import CounterpartyInfo from './Component-CounterpartyInfo';
// import AddLawFirms from './Component-AddLawFirm';
// import AddPerson from './Component-AddPerson';
// import AddNote from './Component-AddNote';
import ReactTableMaker from '../helper/ReactTableMaker'


import queryString from 'query-string'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

// import ErrorMessage from '../helper/MessageBar'
// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
import { isEmptyNullUndefined } from '../helper/Misc';

class LawFirmInfo extends Component {

  state = {
    TokenReady: false,
    Loaded: false,
    querystr: {},
    lawFirmName: '',
    MatterInfo: [],
    LawyersForLawFirm: [],
    NotesForLawFirm: [],
  }


  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
    }
    const querystr = queryString.parse(this.props.location.search)
    // console.log('Logging querystr: ', querystr)
    this.setState({ querystr: querystr })
  }


  // //This is to get rid of the unmounted component no op (as well as that signal: this.abortController stuff below))
  // abortController = new AbortController()
  // componentWillUnmount() {
  //   this.abortController.abort()
  // }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true })
    }
    if (prevState.querystr !== this.state.querystr) {
      this.getLawFirmInfo()
      this.getLawFirmPeople()
      this.getLawFirmNotes()
      if (!isEmptyNullUndefined(this.state.querystr.lawFirmID)) {
        this.setState({
          lawFirmName: this.props.clients.lawfirmsarray.find(lawfirm => lawfirm[0] === parseInt(this.state.querystr.lawFirmID))[1]
        })
      }
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => {
        this.getLawFirmInfo()
        this.getLawFirmPeople()
        this.getLawFirmNotes()
        if (!isEmptyNullUndefined(this.state.querystr.lawFirmID)) {
          this.setState({
            lawFirmName: this.props.clients.lawfirmsarray.find(lawfirm => lawfirm[0] === parseInt(this.state.querystr.lawFirmID))[1]
          })
        }
      }, 200)
    }
  }

  getLawFirmInfo = async () => {
    //  console.log('In getLawFirmInfo...', this.state.querystr.lawFirmID)
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('lawFirmInfo', {
      fetchstr: 'getMattersForLawFirm',
      IDNumber: this.state.querystr.lawFirmID
    },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getLawFirmInfo: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      MatterInfo: fetchresponse.payload.map(item => {
        return {
          ...item,
          // employee_name: this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[2]
        }
      })
    })
  }

  getLawFirmPeople = async () => {
    //  console.log('In getLawFirmInfo...', this.state.querystr.lawFirmID)
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('lawFirmInfo', {
      fetchstr: 'getLawyersForLawFirm',
      IDNumber: this.state.querystr.lawFirmID
    },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getLawFirmPeople: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      LawyersForLawFirm: fetchresponse.payload.map(item => {
        return {
          ...item,
          // employee_name: this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[2]
        }
      })
    })
  }

  getLawFirmNotes = async () => {
    //  console.log('In getLawFirmInfo...', this.state.querystr.lawFirmID)
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getNote', {
      fetchstr: 'getLawyerNotesForLawFirm',
      IDNumber: this.state.querystr.lawFirmID
    },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getLawFirmPeople: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      NotesForLawFirm: fetchresponse.payload.map(item => {
        return {
          ...item,
          // employee_name: this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[2]
        }
      })
    })
  }



  render() {
    return (
      <div>
        <h1 style={{ float: 'left' }}>Law Firm Information For: </h1>
        <h2 style={{ marginLeft: '330px' }}>
          <Icon name='balance scale' size='large' />
          <span style={{ color: '#9ca7b8' }}>
           {'  '} {this.state.lawFirmName} (ID: {this.state.querystr.lawFirmID})
        </span>
        </h2>

        {isEmptyNullUndefined(this.state.MatterInfo) && isEmptyNullUndefined(this.state.LawyersForLawFirm) &&
          <h3>No matters or lawyers to show for this law firm.</h3>
        }

        {/* This will show all matters that this firm was involved in */}
        {!isEmptyNullUndefined(this.state.MatterInfo) &&
          <React.Fragment>
            <h4>Matters for This Law Firm</h4>
            <ReactTableMaker passedData={this.state.MatterInfo} passedColumns={LawFirmMattersColumns(this.props.history, this.props.clients.employeesarray)}
              handleClick={(rowID, cellID, rowValues) => {
                // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                // this.setState({ selectedLawFirm: rowID })
                // this.getPaymentsForInvoice(rowID)
              }}
              includeSearchBar={false}
            />
          </React.Fragment>
        }

        {/* This will show all lawyers involved with this firm */}
        {!isEmptyNullUndefined(this.state.LawyersForLawFirm) &&
          <React.Fragment>
            <h4 >Lawyers Involved With This Law Firm</h4>
            <ReactTableMaker passedData={this.state.LawyersForLawFirm} passedColumns={LawFirmLawyersColumns(this.props.history, this.props.clients.employeesarray)}
              handleClick={(rowID, cellID, rowValues) => {
                // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                // this.setState({ selectedLawFirm: rowID })
                // this.getPaymentsForInvoice(rowID)
              }}
              includeSearchBar={false}
            />
          </React.Fragment>
        }

        {/* This will show all notes for this firm */}
        {!isEmptyNullUndefined(this.state.NotesForLawFirm) &&
          <React.Fragment>
            <h4 >Notes For This Law Firm</h4>
            <ReactTableMaker passedData={this.state.NotesForLawFirm} passedColumns={LawFirmNotesColumns(this.props.history, this.props.clients.employeesarray)}
              handleClick={(rowID, cellID, rowValues) => {
                // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                // this.setState({ selectedLawFirm: rowID })
                // this.getPaymentsForInvoice(rowID)
              }}
              includeSearchBar={false}
            />
          </React.Fragment>
        }

        {/* <DisplayState state={this.state} /> */}

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(LawFirmInfo)


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

let LawFirmMattersColumns = (history, employees) => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        >
          {cell.value}
        </span>
    },
    // {
    //   Header: ({ Header }) => <span style={{ 'float': 'left' }}>Originating Partner</span>,
    //   accessor: 'employee_name',
    //   show: true,
    //   sortType: 'alphanumeric',
    //   Filter: 'defaultColumnFilterLeft',
    //   Cell: ({ cell, row }) => <span style={{ 'float': 'left' }}>
    //     <Image src={window.location.origin + '/images/' + employees.find(employee => employee[0] === row.original['employee_id'])[4]} verticalAlign='bottom' width='20px' />
    //     {/* {employees.find(employee => employee[0] === cell.value)[4]} */}
    //     {/* {employees[1][4]} */}
    //     {' '}{cell.value}</span>
    // },
    {
      Header: () => <span style={{ 'float': 'left' }}>Matter Name</span>,
      accessor: 'matter_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Counterparty Represented</span>,
      accessor: 'counterparty',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
      <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        style={{ 'float': 'left', cursor: 'pointer' }}
        onClick={() => history.push(`/counterparty/${row.original.counterparty}?counterparty=${row.original.counterparty}&counterpartyID=${row.original.counterparty_id}`)}
      >
        {cell.value}
      </span>
    },

  ]
}

let LawFirmLawyersColumns = (history, employees) => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Lawyer Name</span>,
      accessor: 'person_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/person/${row.original.person_name}?person=${row.original.person_name}&personID=${row.original.person_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Lawyer Email</span>,
      accessor: 'person_email',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/person/${row.original.person_id}?person_name=${row.original.person_name}`)}
        >
          {cell.value}
        </span>
    },
    // {
    //   Header: ({ Header }) => <span style={{ 'float': 'left' }}>Originating Partner</span>,
    //   accessor: 'employee_name',
    //   show: true,
    //   sortType: 'alphanumeric',
    //   Filter: 'defaultColumnFilterLeft',
    //   Cell: ({ cell, row }) => <span style={{ 'float': 'left' }}>
    //     <Image src={window.location.origin + '/images/' + employees.find(employee => employee[0] === row.original['employee_id'])[4]} verticalAlign='bottom' width='20px' />
    //     {/* {employees.find(employee => employee[0] === cell.value)[4]} */}
    //     {/* {employees[1][4]} */}
    //     {' '}{cell.value}</span>
    // },
    {
      Header: () => <span style={{ 'float': 'left' }}>Matter</span>,
      accessor: 'matter_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
        >
          {cell.value}
        </span>
    },

  ]
}

let LawFirmNotesColumns = (history, employees) => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        >
          {cell.value}
        </span>
    },
    // {
    //   Header: ({ Header }) => <span style={{ 'float': 'left' }}>Originating Partner</span>,
    //   accessor: 'employee_name',
    //   show: true,
    //   sortType: 'alphanumeric',
    //   Filter: 'defaultColumnFilterLeft',
    //   Cell: ({ cell, row }) => <span style={{ 'float': 'left' }}>
    //     <Image src={window.location.origin + '/images/' + employees.find(employee => employee[0] === row.original['employee_id'])[4]} verticalAlign='bottom' width='20px' />
    //     {/* {employees.find(employee => employee[0] === cell.value)[4]} */}
    //     {/* {employees[1][4]} */}
    //     {' '}{cell.value}</span>
    // },
    {
      Header: () => <span style={{ 'float': 'left' }}>Matter Name</span>,
      accessor: 'matter_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Note</span>,
      accessor: 'note',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },

  ]
}
