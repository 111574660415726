import React, { Component } from 'react';
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
  Annotation
} from "react-simple-maps"

import topoJSON from './us.json'
import allStates from "./allstates.json"
import { isEmptyNullUndefined, RGB_Linear_Shade, percentageTwoColors, compareTwoSimpleArrays } from '../helper/Misc';
// const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json"

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21]
};

class Map extends Component {

  state = {
    ReducedMatters: [],
    mapConfigArray: [],
  }

  componentDidMount() {
    !isEmptyNullUndefined(this.props.Matters) && this.setState({ ReducedMatters: this.reduceStates() })
  }


  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedYear !== this.props.selectedYear || prevProps.selectedPartner !== this.props.selectedPartner || prevProps.selectedClient !== this.props.selectedClient || prevProps.States !== this.props.States) {
      this.setState({ ReducedMatters: this.reduceStates() })
      // console.log('Running reducedMatters from didUpdate...')
    }
    if (prevProps.Matters !== this.props.Matters) {
      // console.log('Matters just changed to: ', this.props.Matters)
      if (!compareTwoSimpleArrays(prevProps.Matters, this.props.Matters)) this.setState({ ReducedMatters: this.reduceStates() })
    }
    if (prevState.ReducedMatters !== this.state.ReducedMatters) {
      // console.log('Running countStates from didUpdate...')
      this.setState({ arrayOfStateInts: this.countStates() })
    }
    if (prevState.arrayOfStateInts !== this.state.arrayOfStateInts) {
      // console.log('Running createMapConfigArray from didUpdate...')
      this.setState({ mapConfigArray: this.createMapConfigArray() })
    }

  }

  reduceStates = () => {
    let array = JSON.parse(JSON.stringify(this.props.Matters)) || []
    // console.log('In reduceStates and this is array: ', array)
    let returnArray = array.reduce((acc, item, i, array) => {
      if (this.props.selectedYear === 0 || (new Date(item.origination_date) >= new Date(`${this.props.selectedYear}-01-01`) && new Date(item.origination_date) <= new Date(`${this.props.selectedYear}-12-31`))) {
        if (this.props.selectedPartner === 0 || this.props.selectedPartner === item.originating_employee) {
          if (this.props.selectedClient === 0 || this.props.selectedClient === item.client_id) {
            // console.log('This is item: ', item)
            acc.push(item)
          }
        }
      }
      return acc
    }, [])
    // console.log('This is reduceStates returnArray: ', returnArray)
    return returnArray
  }

  countStates = () => {
    if (isEmptyNullUndefined(this.state.ReducedMatters)) return []
    let array = JSON.parse(JSON.stringify(this.state.ReducedMatters)) || []
    // console.log('In countStates and this is array: ', array)
    let returnArray = array.reduce((acc, item, i, array) => {
      if (!isEmptyNullUndefined(item.statesIDs)) {
        // console.log(item.statesIDs.split(','))
        let arrayOfStateInts = item.statesIDs.split(',').map(item => parseInt(item))
        // console.log('This is item: ', arrayOfStateInts)
        arrayOfStateInts.map(item => acc.push(item))
      }
      return acc
    }, [])
    // console.log('This is countStates returnArray: ', returnArray)
    return returnArray
  }

  createMapConfigArray = () => {
    // console.log('In createMapConfigArray...')
    if (isEmptyNullUndefined(this.props.States)) return []
    let array = JSON.parse(JSON.stringify(this.props.States)) || []
    // console.log('This is array up here: ', array)
    //Go through arrayOfStateInts and add one to the amount for each one

    //If there are no states, return an empty-ish array
    if (this.state.arrayOfStateInts.length === 0) {
      let temparray = this.props.States.map(state => {
        return {
          ...state,
          percentage: 0,
          color: '#d0d0d0'
        }
      })
      return temparray
    }
    //Otherwise...
    for (let i = 0; i < this.state.arrayOfStateInts.length; i++) {
      // console.log(`This is i: ${i} and this is state.arrayofstateints[i]: ${this.state.arrayOfStateInts[i]}`)
      let found = array.find(item => item.state_id === this.state.arrayOfStateInts[i])
      // if(found.state_id ===33) console.log('This is found: ', found)
      // console.log(`This is found: `, found)
      found.amount = found.amount + 1
    }

    //Get total amounts (should be the same as arrayOfInts.length)
    let totalAmount = array.reduce((acc, item) => {
      acc = acc + item.amount
      return acc
    }, 0)
    // console.log('This is totalAmount: ', totalAmount)

    //Add percentages
    array = array.map(item => {
      let percentage = item.amount / totalAmount
      // console.log('percentage is: ', percentage)
      let color = percentageTwoColors('#660000', '#ff1a1a', percentage * 2) // The higher the percentage, the closer to the first color
      // console.log('Color is: ', color)
      return ({
        ...item,
        percentage: percentage,
        color: percentage === 0 ? '#d0d0d0' : color
      })
    })
    // console.log('In reducedStates and this is tweakedArray: ', array)
    // let totalPercentage = array.reduce((acc, item) => {
    //   return acc + item.percentage
    //   // return acc
    // },0)
    // console.log(totalPercentage)
    return array
  }


  defaultColor = '#d0d0d0'
  defaultStroke = "#607D8B"
  colorGetter = (id) => {
    if (isEmptyNullUndefined(this.state.mapConfigArray)) return this.defaultColor
    let found = this.state.mapConfigArray.find(item => item.topoID === id)
    if (isEmptyNullUndefined(found)) return this.defaultColor
    else return found.color
  }
  fontColorGetter = (id) => {
    let found = !isEmptyNullUndefined(this.state.mapConfigArray) ? this.state.mapConfigArray.find(item => id === item.state_abbr).amount : 0
    if (found > 0) return `#fff`
    else return '#000'
  }
  numberGetter = (id) => {
    let found = !isEmptyNullUndefined(this.state.mapConfigArray) ? this.state.mapConfigArray.find(item => id === item.state_abbr).amount : 0
    if (found > 0) return `(${found})`
    else return ''
  }

  centroids = []

  zoomGetter = (id) => {
    // console.log('In RSM-Map and zoomgetting')
  }

  render() {
    // console.log(topoJSON)
    // console.log('This is mapConfigArray: ', this.props.mapConfigArray)
    return (
      // <div className="map-container">
      // <svg 
      // height={this.props.chartHeight}
      // viewBox={`0 0 ${this.props.chartWidth} ${this.props.chartHeight}`}
      // >
      <ComposableMap
        projection="geoAlbersUsa"
        width={800}
        height={600}
        style={{
          width: '100%',
          height: "100%",
        }}
      >
        <ZoomableGroup zoom={1} maxZoom={this.props.zoomable ? 200 : 1} minZoom={1} >
          <Geographies geography={topoJSON}>
            {({ geographies }) => (
              <>
                {geographies.map(geo => {
                  // if(parseInt(geo.id) === 36)
                  return (
                    <Geography
                      key={geo.rsmKey}
                      // stroke={"#FFF"}
                      geography={geo}
                      onClick={() => this.props.mapHandler(parseInt(geo.id))}
                      style={{
                        default: {
                          fill: this.colorGetter(parseInt(geo.id)),
                          //  fill: "#ECEFF1",
                          stroke: this.defaultStroke,
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                        hover: {
                          fill: "#006da2",
                          stroke: RGB_Linear_Shade(-.8, this.defaultStroke),
                          strokeWidth: 1.5,
                          outline: "none",
                        },
                        pressed: {
                          fill: "#FF5722",
                          stroke: RGB_Linear_Shade(.8, this.defaultStroke),
                          strokeWidth: 2,
                          outline: "none",
                        }
                      }}
                    />
                  )
                })}
                {!this.props.simpleMap && geographies.map((geo, i) => {
                  const cur = allStates.find(s => s.val === geo.id)
                  const centroid = geoCentroid(geo);
                  if (centroid[0] > -160 && centroid[0] < -67 && this.centroids.length < geographies.length) {

                    if (Object.keys(offsets).indexOf(cur.id) === -1) this.centroids.push({ id: cur.id, coords: centroid })
                    else this.centroids.push({ id: cur.id, coords: centroid, offsets: [offsets[cur.id][0], offsets[cur.id][1]] })
                  }
                  // console.log(geographies)
                  // if (i === geographies.length-1) console.log('This is centroids: ', Array.from(new Set(this.centroids)))
                  // console.log(centroid)
                  return (
                    <g key={geo.rsmKey + "-name"}>
                      {cur &&
                        centroid[0] > -160 &&
                        centroid[0] < -67 &&
                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                          <Marker coordinates={centroid}>
                            <text y="2" fontSize={14} style={{ fill: this.fontColorGetter(cur.id) }} textAnchor="middle">
                              {cur.id}
                            </text>
                          </Marker>
                        ) : (
                            <Annotation
                              subject={centroid}
                              dx={offsets[cur.id][0]}
                              dy={offsets[cur.id][1]}
                            >
                              <text x={4} fontSize={14} alignmentBaseline="middle">
                                {cur.id}
                              </text>
                            </Annotation>
                          ))}
                    </g>
                  );
                })}

                {/* {geographies.map(geo => {
                const centroid = geoCentroid(geo);
                let x = centroid[0]
                let y = centroid[1] 
                let yadd = y + 20
                let location = [20, 20]
                console.log(`This is x: ${x+20} and this is y: ${y}`)
                const cur = allStates.find(s => s.val === geo.id);
                return (
                  <g key={geo.rsmKey + "-name"}>
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                        <Marker marker={{ coordinates: [20, 20] }}>
                        <circle r={8} fill="#F53" />
                      </Marker>
                      ) : (
                          <Annotation
                            subject={centroid}
                            dx={offsets[cur.id][0]}
                            dy={offsets[cur.id][1]}
                          >
                            <text x={4} fontSize={14} alignmentBaseline="middle">
                              {cur.id}
                            </text>
                          </Annotation>
                        ))}
                  </g>
                );
              })} */}
              </>
            )}
          </Geographies>
          {!this.props.simpleMap && this.centroids.map((item, i) => {
            // console.log('This is item: ', item)
            let x = item.coords[0] //- .8
            let y = item.coords[1] //- .8
            let amount = this.numberGetter(item.id)
            // console.log(amount)
            if (false || amount <= 0) return null
            else
              return (
                <Marker coordinates={[x, y]} key={'Marker' + item.id + i}>
                  <text x={-10} y={15} fontSize={14} style={{ fill: '#ffff00' }} alignmentBaseline="left">
                    {amount}
                  </text>
                  {/* </span> */}
                </Marker>
              )
          }
          )}
          {/* Add dots */}
          {this.props.allowDots && this.props.showDots && this.props.Locations.map((item, i) => {
            if (item.location.y > -122 && item.location.y < -70 && item.location.x > 22.4 && item.location.x < 49)
              return (
                <Marker coordinates={[item.location.y, item.location.x]} key={`coordmarker${i}`} >
                  <circle r={3} fill="#00e600" />
                </Marker>
              )
            else return null
          })}
        </ZoomableGroup>
      </ComposableMap>
      // </svg>
      // </div>
    )
  }
}
export default Map

Map.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  byOriginating: true,
  currentYear: new Date().getFullYear(),
  selectedYear: new Date().getFullYear(),
  selectedPartner: 1,
  Matters: [],
  States: [],
  Locations: [],
  chartWidth: 500,
  chartHeight: 500,
  simpleMap: false,
  zoomable: false,
  showDots: true,
  allowDots: true,
  mapHandler: () => { },
}
