import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import LeafletMap from './Component-Maps-Locations-Map'
import MapsSizer from './Component-Maps-Sizer'
import LocationsInfo from './Component-LocationsInfo'
// import TempMap from './Component-Maps-Delete'
import { isEmptyNullUndefined } from '../helper/Misc';
import { Segment,  Checkbox, Image, Icon, Table } from 'semantic-ui-react';
import './Component-Maps-Locations-Container.css'

class MapsLocations extends Component {

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  state = {
    ReducedFacilities: [],
    center: this.props.center,
    zoom: this.props.zoom,
    showClusters: true,
    partnerColors: [],
    individualCenter: [],
  }

  async componentDidMount() {
    this.setState({ ReducedFacilities: this.reduceFacilities() })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.Facilities !== this.props.Facilities || prevProps.selectedYear !== this.props.selectedYear || prevProps.selectedPartner !== this.props.selectedPartner || prevProps.selectedClient !== this.props.selectedClient) {
      this.setState({ ReducedFacilities: this.reduceFacilities() })
    }
  }

  reduceFacilities = () => {
    let array = JSON.parse(JSON.stringify(this.props.Facilities)) || []
    if (isEmptyNullUndefined(array)) { this.setState({ partnerColors: [] }); return [] }
    // console.log('In reduceFacilities and this is array: ', array)
    let returnArray = array.reduce((acc, item, i, array) => {
      if (this.props.selectedYear === 0 || (new Date(item.origination_date) >= new Date(`${this.props.selectedYear}-01-01`) && new Date(item.origination_date) <= new Date(`${this.props.selectedYear}-12-31`))) {
        if (this.props.selectedPartner === 0 || this.props.selectedPartner === item.originating_employee_id) {
          if (this.props.selectedClient === 0 || this.props.selectedClient === item.client_id) {
            // console.log('This is item: ', item)
            acc.push(item)
          }
        }
      }
      return this.reducePartners(acc)
    }, [])
    // console.log('This is reduceFacilities returnArray: ', returnArray)
    return returnArray
  }

  colorArray = ['violet', 'blue', 'green', 'brown', 'red', 'black', 'green', 'green', 'green', 'green', 'green', 'green', 'green', 'green', 'green', 'green']

  reducePartners = (array) => {
    if (isEmptyNullUndefined(array)) { this.setState({ partnerColors: [] }); return array }
    // console.log('In reducePartners and this is array: ', array)
    let count = 1
    let returnArray = array.reduce((acc, current, i, array) => {
      // console.log('This is current: ', current)
      let found = acc.find(facility => facility.originating_employee_id === current.originating_employee_id)
      if (!found) {
        acc.push({ originating_employee_id: current.originating_employee_id, originating_employee: current.originating_employee, color: this.colorArray[count] })
        count++
      }
      return acc
    }, [])
    returnArray = returnArray.sort((a, b) => (a.originating_employee > b.originating_employee) ? 1 : -1)
    // console.log('This is reducePartners returnArray: ', returnArray)
    this.setState({ partnerColors: returnArray })

    array = array.map(facility => ({ ...facility, color: returnArray.find(color => color.originating_employee_id === facility.originating_employee_id).color }))
    return array
  }

  handleApiLoaded = (map, maps) => {
    this.mapRef.current = map
  }
  // handleChange = (zoom, center) => {
  //   !isEmptyNullUndefined(zoom) && !isEmptyNullUndefined(center) &&
  //     console.log(zoom)
  //   console.log(center)
  //   this.setState({
  //     zoom: zoom,
  //     center: center
  //   })
  // }

  handleMapClick = ({ x, y, lat, lng, event }, e, force) => {
    // console.log('==> handleMapClick(..) : ' + lat + ',' + lng + ' (x=' + x + ',y=' + y + ')');
  }

  handleIndividualCenter = (lat, lng) => {
    this.setState({ individualCenter: [lat, lng] })
  }


  render() {

    let mapHeight = 600 * this.props.viewPercentage
    let mapWidth = 800 * this.props.viewPercentage
    let checkboxStyle = { paddingRight: 30, paddingTop: 10, paddingBottom: 10 }

    return (
      <div>
        <div className='leafmapcontainerparent'>
          <div className='leafmapcontainer'>

            <Segment raised compact
              style={{ backgroundColor: '#ffffe8' }}>
              <div className='leafmapcontainerinner' style={{ width: mapWidth, height: mapHeight }}>
                {this.props.GoogleAPILoaded && !isEmptyNullUndefined(this.props.Facilities) && this.state.showClusters &&
                  <LeafletMap
                    GOOGLEAPI={this.props.GOOGLEAPI}
                    Facilities={this.state.ReducedFacilities}
                    history={this.props.history}
                    Height={mapHeight  }
                    Width={mapWidth  }
                    clusterSize={75}
                    center={this.props.center}
                    zoomProp={this.props.zoom}
                    sendZoomCenterToParent={this.props.sendZoomCenterToParent}
                    individualCenter={this.state.individualCenter}
                  />}
                {this.props.GoogleAPILoaded && !isEmptyNullUndefined(this.props.Facilities) && !this.state.showClusters &&
                  <LeafletMap
                    GOOGLEAPI={this.props.GOOGLEAPI}
                    Facilities={this.state.ReducedFacilities}
                    history={this.props.history}
                    Height={mapHeight}
                    Width={mapWidth }
                    clusterSize={1}
                    center={this.props.center}
                    zoomProp={this.props.zoom}
                    sendZoomCenterToParent={this.props.sendZoomCenterToParent}
                    individualCenter={this.state.individualCenter}
                  />}
              </div >
            </Segment >
          </div>
          <div className='leafmaplegend'>
            <MapsSizer handleIconClick={this.props.handleIconClick} viewPercentage={this.props.viewPercentage} />
            <Segment color='green'>
              <h5>Clusters:</h5>
              <Checkbox
                toggle
                style={checkboxStyle}
                label={this.state.showClusters ? 'Show Clusters' : 'Hide Clusters'}
                checked={this.state.Originating}
                onChange={() => { this.setState((prevState) => ({ showClusters: !prevState.showClusters })) }}
              />
            </Segment>
            {!isEmptyNullUndefined(this.state.partnerColors) &&
              <Segment color='blue'>
                <h5>Legend:</h5>

                <Table>
                  <Table.Body>
                    {this.state.partnerColors.map((color, i) => {
                      return (
                        <Table.Row key={`leg${color.originating_employee_id}`}>
                          <Table.Cell>
                            <Image src={this.props.employeesarray.find(employee => employee[0] === color.originating_employee_id)[4]} verticalAlign='bottom' width='20px' />
                          </Table.Cell>
                          <Table.Cell>
                            {color.originating_employee}
                          </Table.Cell>
                          <Table.Cell>
                            <Icon name='map pin' color={color.color} />
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
                <Table attached><Table.Body><Table.Row><Table.Cell>Note: Unclosed deals show faded pin.</Table.Cell></Table.Row></Table.Body></Table>
              </Segment>
            }
          </div>
        </div >
        <h3>Locations Table</h3>
        <LocationsInfo Locations={this.state.ReducedFacilities} handleIndividualCenter={this.handleIndividualCenter} addressOnly={false}/>
      </div>
    )
  }
}
export default withRouter(MapsLocations)

MapsLocations.defaultProps = {
  maxViewPercentage: 75,
  viewPercentage: 1,
  maxYears: 5,
  byOriginating: true,
  currentYear: new Date().getFullYear(),
  selectedYear: new Date().getFullYear(),
  selectedPartner: 1,
  Matters: [],
  States: [],
  chartWidth: 500,
  chartHeight: 500,
  GOOGLEAPI: '',
  GoogleAPILoaded: false,
  Facilities: [],
  sendZoomCenterToParent: () => { },
  mapHandler: () => { },
  handleIconClick: () => { },
  employeesarray: [],
  zoom: 10,
  center: { lat: 40.73, lng: -73.93 },
}
