import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { isEmptyNullUndefined, RGB_Linear_Shade } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';

import { VictoryAxis, VictoryLabel, VictoryLine, VictoryLegend, VictoryBar } from 'victory'


class BalanceSheetSVG extends Component {

  state = {
    TokenReady: false,
    // currentYear: null,
    // selectedYear: null,
    Invoices: [],
    Payments: [],
    maxAccrualValue: 1,
    maxCashValue: 1,
    maxY: 1,
    minX: 0,
    maxX: 1
  }


  async componentDidMount() {
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      if (!isEmptyNullUndefined(this.props.InvoicesByYear)) this.processInvoices(this.props.InvoicesByYear)
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady) {
      this.setState({ TokenReady: true })
    }
    if (prevState.TokenReady !== this.state.TokenReady) {
      // this.getPartnerBilling()
    }
    if (prevProps.InvoicesByYear !== this.props.InvoicesByYear) {
      if (!isEmptyNullUndefined(this.props.InvoicesByYear)) this.processInvoices(this.props.InvoicesByYear)
    }
    if (prevProps.showAccrual !== this.props.showAccrual || prevProps.showCash !== this.props.showCash) {
      // if (!isEmptyNullUndefined(this.props.InvoicesByYear)) this.processInvoices(this.props.InvoicesByYear)
    }
  }

  processInvoices = (invoices) => {
    let totalInvoices = invoices.map(item => {
      let obj = {}
      obj.y = parseFloat(item.total_invoices)
      obj.x = parseFloat(item.year)
      return obj
    }).sort((a, b) => {
      if (a.x < b.x) return -1
      if (a.x > b.x) return 1
      return 0
    })
    let maxTotalInvoices = Math.max(...totalInvoices.map(item => item.y))
    let totalPayments = invoices.map(item => {
      let obj = {}
      obj.y = parseFloat(item.total_payments)
      obj.x = parseFloat(item.year)
      return obj
    }).sort((a, b) => {
      if (a.x < b.x) return -1
      if (a.x > b.x) return 1
      return 0
    })
    let maxTotalPayments = Math.max(...totalPayments.map(item => item.y))
    let minX = Math.min(totalInvoices[0].x, totalPayments[0].x)
    let maxX = Math.max(totalInvoices[totalInvoices.length - 1].x, totalPayments[totalPayments.length - 1].x)

    this.setState({
      Invoices: totalInvoices,
      Payments: totalPayments,
      maxAccrualValue: maxTotalInvoices,
      maxCashValue: maxTotalPayments,
      maxY:
        this.props.showAccrual ?
          this.props.showCash ?
            Math.max(maxTotalInvoices, maxTotalPayments) :
            maxTotalInvoices :
          this.props.showCash ?
            maxTotalPayments :
            1,
      minX: minX,
      maxX: maxX
    })
  }

  render() {

    const BLUE_COLOR = "#00a3de"
    const xdomainPadding = { x: [0,0] }
    const ydomainPadding = { y: [0, 10] }
    const domainPadding = { x: xdomainPadding.x, y: ydomainPadding.y }

    return (

      <svg
        viewBox="-35 -20 480 320"
      >

        {this.props.showAccrual && this.props.showCash &&
          <VictoryLegend
            standalone={false}
            x={60}
            y={275}
            groupComponent={<g style={{ background: 'green' }} />}
            width={5}
            orientation="horizontal"
            gutter={20}
            rowGutter={1}
            padding={0}
            style={{
              border: { stroke: 'black' },
              margin: '0px',
              padding: '0px',
              data: { size: 4 },
              // title: { fontSize: 8 },
              labels: { fontSize: 8 },
              parent: { border: "10px solid #ccc", background: '#ff0080' }
            }}
            data={[{ name: 'Accrual', symbol: { fill: '#c43a31', type: 'square' } }, { name: 'Cash', symbol: { fill: '#0080ff', type: 'square' } }]}
          />
        }

        <VictoryLabel

          text={`Invoice Totals By Year`}
          x='42%' y={20} style={{
            textAnchor: "middle",
            verticalAnchor: "end",
            fill: "#000000",
            fontFamily: "inherit",
            fontSize: "12px",
            fontWeight: "bold"
          }}
        />

        <VictoryLabel
          x={45} y={30} style={{
            textAnchor: "end",
            fill: BLUE_COLOR, 
            fontFamily: "inherit",
            fontSize: 8,
            fontStyle: "italic"
          }}
          text={`Total ${this.props.showAccrual && this.props.showCash ? 'Accrual / Cash' : this.props.showAccrual ? 'Accrued' : 'Cash Receipts'}`}
        />

        {!isEmptyNullUndefined(this.state.Invoices) &&
          <VictoryBar
            standalone={false}
            data={this.state.Invoices.map((item, index) => { return { x: item.x, y: this.state.maxY, color: Math.abs(index % 2) === 1 ? RGB_Linear_Shade(-.05, this.props.chartStyle.background) : RGB_Linear_Shade(.6, this.props.chartStyle.background) } }).slice(0,-1)}
            domain={{ x: [this.state.minX, this.state.maxX], y: [0, this.state.maxY] }}
            alignment="start"
            domainPadding={domainPadding}
            barRatio={1.5}
            style={{ data: {fill: ({datum}) => datum.color } }}
          />
        }

        {/* Y Axis */}
        {!isEmptyNullUndefined(this.props.InvoicesByYear) &&
          <VictoryAxis
            dependentAxis
            standalone={false}
            // tickFormat specifies how ticks should be displayed
            tickFormat={(x) => this.props.InvoicesByYear.length > 0 && '$' + new Intl.NumberFormat().format(x)}
            // label="Amount"
            style={{
              // axis: { stroke: "#756f6a" },
              // axisLabel: {fontSize: 20, padding: 30},
              grid: { stroke: ({ tick }) => tick === 0 ? "#000000" : tick > 0.5 ? "6d65a0" : "grey" },
              ticks: { stroke: "grey", size: 2 },
              tickLabels: {
                fill: BLUE_COLOR,
                fontFamily: "inherit",
                fontSize: 8
              }
            }}
            domain={[0, this.state.maxY]}
            domainPadding={ydomainPadding}
          // animate={{ duration: 500 }}
          />
        }

        {/* X Axis */}
        {!isEmptyNullUndefined(this.props.InvoicesByYear) &&
          <VictoryAxis
            standalone={false}
            style={{
              axisLabel: { fontSize: 15, padding: 20 },
              tickLabels: { fontSize: 8, padding: 5 },
              ticks: {
                size: 5,
                stroke: "black",
                strokeWidth: 1
              },
            }}
            // label='Year'
            // tickCount={3}
            // tickValues={xTickValues}
            // animate={{ duration: 500 }}
            domain={[this.state.minX, this.state.maxX]}
            domainPadding={xdomainPadding}
            tickFormat={date => date}
          />
        }

        {!isEmptyNullUndefined(this.props.InvoicesByYear) && this.props.showAccrual &&
          <VictoryLine
            name='redLine'
            renderInPortal={false}
            standalone={false}
            domain={{ x: [this.state.minX, this.state.maxX], y: [0, this.state.maxY] }}
            domainPadding={domainPadding}
            data={this.state.Invoices}
            // x={(data) => Number.isNaN(data.year) ? data.year : 1}
            // y={(data) => Number.isNaN(data.total_invoices) ? data.total_invoices : 1}
            // interpolation="stepAfter"
            scale={{ x: "time", y: "linear" }}
            style={{ data: { stroke: '#c43a31', strokeWidth: 2, strokeLinecap: "round" } }}
          // labels={({ data, index, datum }) => parseInt(index) === data.length - 1 ? datum.total_invoices.toLocaleString('en-US', {
          //   style: 'currency',
          //   currency: 'USD',
          // }) : null}
          // labelComponent={<VictoryLabel style={{ fill: 'black', stroke: '#c43a31', fontSize: 5, strokeWidth: .2, strokeOpacity: 0.8 }} dx={15} dy={10} renderInPortal={false} />}
          />
        }

        {!isEmptyNullUndefined(this.props.InvoicesByYear) && this.props.showCash &&
          <VictoryLine
            name='blueLine'
            renderInPortal={false}
            standalone={false}
            data={this.state.Payments}
            // x={(data) => parseInt(data.year)}
            // y={(data) => parseFloat(data.total_payments)}
            domain={{ x: [this.state.minX, this.state.maxX], y: [0, this.state.maxY] }}
            domainPadding={domainPadding}
            // interpolation="monotoneY"
            scale={{ x: "time", y: "linear" }}
            style={{ data: { stroke: '#0080ff', strokeWidth: 2, strokeLinecap: "round" } }}
          // labels={({ data, index, datum }) => parseInt(index) === data.length - 1 ? datum.total_payments.toLocaleString('en-US', {
          //   style: 'currency',
          //   currency: 'USD',
          // }) : null}
          // labelComponent={<VictoryLabel style={{ fill: 'black', stroke: '#0080ff', fontSize: 5, strokeWidth: .2, strokeOpacity: 0.8 }} dx={15} dy={10} renderInPortal={false} />}
          />
        }

        {/* </VictoryChart> */}
      </svg>




    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BalanceSheetSVG))


BalanceSheetSVG.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  // handleSomething: () => { },
}

