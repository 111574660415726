import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { isEmptyNullUndefined, RGB_Linear_Shade } from '../helper/Misc'

import config from '../../JO-CONFIG'

// import DisplayState from '../helper/DisplayState';

import { VictoryBar, VictoryAxis, VictoryTooltip, VictoryLabel, VictoryLegend } from 'victory'

import colorScheme from './colorScheme'

class ChartsMajorClientsSVG extends Component {

  state = {
    TokenReady: false,
    loading: true,
    maxClientAmount: 1,
    pictureDelay: false,
    MajorClientsForSelectedYear: [],
    TotalInvoices: 0,
  }


  async componentDidMount() {
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      // this.setState({ currentYear: new Date().getFullYear(), selectedYear: new Date().getFullYear() })
      this.processMajorClients()
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.loading) {
      this.setState({ TokenReady: true })
    }
    if (prevState.TokenReady !== this.state.TokenReady) {
      // this.processMajorClients()
    }
    if (prevProps.MajorClients !== this.props.MajorClients || prevProps.selectedYear !== this.props.selectedYear || prevProps.selectedPartner !== this.props.selectedPartner || prevProps.byInvoicingEmployee !== this.props.byInvoicingEmployee) {
      this.processMajorClients()
    }
  }
  //This limits major clients to just selected year
  reducedMajorClients = (earray) => {
    let count = 0
    // console.log('earray: ', earray)
    let reducedMajorClients //= this.combineClients(earray)
    if (this.props.selectedYear === 0 ) {reducedMajorClients = this.combineClients(earray)
    }
    else reducedMajorClients = earray
    // console.log('reducedMajorClients: ', reducedMajorClients)
    reducedMajorClients = reducedMajorClients.reduce((acc, item, index) => {
      if ((item.year === this.props.selectedYear) || (this.props.selectedYear === 0)) {
        if (this.props.selectedPartner === 0 || this.props.selectedPartner === item[this.props.byInvoicingEmployee ? 'invoicing_employee_id' : 'employee_id']) {
          // console.log("item is: ", item)
          let obj = {}
          obj.x = item.client_name
          obj.y = item.amount
          obj.employee = !this.props.byInvoicingEmployee ? item.employee : this.props.clients.employeesarray.find(employee => employee[0] === item.invoicing_employee_id)[1]  + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === item.invoicing_employee_id)[2]
          obj.e_picture =  !this.props.byInvoicingEmployee ? item.e_picture : this.props.clients.employeesarray.find(employee => employee[0] === item.invoicing_employee_id)[4]
          obj.employee_id = item[this.props.byInvoicingEmployee ? 'invoicing_employee_id' : 'employee_id']
          obj.invoicing_employee_id = item.invoicing_employee_id
          // obj.number = count + 1
          // count++
          acc.push(obj)
        }
      }
      return acc
    }, []
    )
    // console.log('This is reducedMajorClients before slice: ', reducedMajorClients)
    reducedMajorClients = reducedMajorClients.slice(0, 10)
    // console.log('This is reducedMajorClients after slice: ', reducedMajorClients)
    if (this.props.selectedYear === 0 || true) reducedMajorClients = reducedMajorClients.sort((a, b) => {
      if (a.y > b.y) return -1
      if (a.y < b.y) return 1
      return 0
    })
    reducedMajorClients = reducedMajorClients.map(item => {
      var o = Object.assign({}, item);
      o.number = count + 1
      count++;
      return o;
    })
    // console.log('This is reducedMajorClients after sort: ', reducedMajorClients)
    // console.log(...Array(reducedMajorClients.length + 1).keys())
    return reducedMajorClients
  }

  combineClients = (clients) => {
    let returnArray = clients.reduce((accumulator, cur) => {
      //See whether there is already a same client in the accumulator
      let found 
      found = accumulator.find(item => (item.client_name === cur.client_name) && (item.employee_id === cur.employee_id))
      // found = accumulator.find(item => item.year === cur.year && item.employee_id === cur.employee_id && item.client_name === cur.client_name)
      //If we find a duplicate date, then add the current.amount to the found.amount
      if (found) {
        // console.log('Found this: ', found)
        found.amount += cur.amount
      }
      //Otherwise, since it's not already in the accumulator, then push this unique current into accumulator
      else accumulator.push(cur)
      // console.log('This is accumulator: ', accumulator)
      return accumulator;
    }, [])
    // console.log('This is returnArray in combineClients: ', returnArray)
    return returnArray
  }

  processMajorClients = async () => {
    // console.log('Running processMajorClients on these clients: ', this.props.MajorClients)
    let newMCs = !isEmptyNullUndefined(this.props.MajorClients) ? JSON.parse(JSON.stringify(this.props.MajorClients)) : []
    // console.log('This is newMCs: ', newMCs)
    this.props.isMounted && !isEmptyNullUndefined(newMCs) && this.setState({
      pictureDelay: true,
      MajorClientsForSelectedYear: this.reducedMajorClients(newMCs)
    }, () => {
      this.state.MajorClientsForSelectedYear.length > 0 && this.setState({
        maxClientAmount: this.state.MajorClientsForSelectedYear.map(item => item.y).reduce(function (a, b) {
          return Math.max(a, b);
        }),
        TotalInvoices: this.state.MajorClientsForSelectedYear.reduce((acc, item) => {
          acc += item.y
          return acc
        }, 0)
      })
    }
    )
    this.props.isMounted && this.setState({ loading: false })
    setTimeout(() => { this.props.isMounted && this.setState({ pictureDelay: false }) }, 500)

  }

  render() {

    const BLUE_COLOR = "#00a3de"
    const RED_COLOR = "#7c270b"

    return (


      <svg
        viewBox="-25 -25 600 350"
        
      >

        <g width="0" height="0">
          <defs >
            {colorScheme.map((color, index) =>
              <linearGradient key={`Gradient${index}`} id={`gradient${index}`}
                x1="0%" y1="0%" x2="00%" y2="100%"
              >
                <stop offset="0%" stopColor={color} />
                <stop offset="50%" stopColor={color} />
                <stop offset="80%" stopColor={RGB_Linear_Shade(.7, color)} />
                {/* <stop offset="90%" stopColor={'#ffffff'} /> */}
              </linearGradient>
            )}
          </defs>
        </g>

        {/* <VictoryChart
                animate={{ duration: 500 }}
                containerComponent={<VictoryVoronoiContainer />}
                domainPadding={{ x: 25, y: [0, 20] }}
                style={{ parent: { maxWidth: this.props.maxViewPercentage + '%', border: "1px solid #ccc", backgroundColor: '#f2f7f9' } }}> */}

        <VictoryLabel x='40%' y={20} style={{
          textAnchor: "middle",
          verticalAnchor: "end",
          fill: "#000000",
          fontFamily: "inherit",
          fontSize: "12px",
          fontWeight: "bold"
        }}
          text={`${this.state.MajorClientsForSelectedYear.length > 0 ? 'Top ' + this.state.MajorClientsForSelectedYear.length : ''} Major Clients for (${this.props.selectedYear === 0 ? 'All Years' : this.props.selectedYear}) \
          ${this.props.selectedPartner !== 0 ? `(${this.props.clients.employeesarray.find(employee => employee[0] === this.props.selectedPartner)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === this.props.selectedPartner)[2]})` : ''}`}
        />
        <VictoryLabel x='40%' y={32} style={{
          textAnchor: "middle",
          verticalAnchor: "end",
          fill: "#000000",
          fontFamily: "inherit",
          fontSize: "10px",
          fontStyle: "italic"
        }}
          text={`${this.props.byInvoicingEmployee  ? '(by invoicing partner)' : '(by originating partner)'}`}
        />


        {isEmptyNullUndefined(this.state.MajorClientsForSelectedYear) &&
          <VictoryLabel
            text={`(No Invoices Billed Yet ${this.props.selectedYear === 0 ? '' : 'in ' + this.props.selectedYear})`}
            style={{
              fill: '#404040',
              fontFamily: "inherit",
              fontSize: 8,
              // fontStyle: "italic"
            }}
            x={200} y={45}
            textAnchor="middle"
          />}

        <VictoryLabel
          x={45} y={30} style={{
            textAnchor: "end",
            fill: BLUE_COLOR,
            fontFamily: "inherit",
            fontSize: 8,
            fontStyle: "italic"
          }}
          text={"Invoices Sent"}
        />
        {this.state.maxClientAmount > 10000 &&
          <VictoryLabel x={45} y={41}
            style={{
              textAnchor: "end",
              fill: BLUE_COLOR,
              fontFamily: "inherit",
              fontSize: 7,
              fontStyle: "italic"
            }}
            text={"(in thousands)"}
          />}

        <VictoryLabel x={425} y={295} style={{
          fill: RED_COLOR,
          textAnchor: 'start',
          fontFamily: "inherit",
          fontSize: 8,
          fontStyle: "italic"
        }}
          text={`Total Invoiced:\n${!isEmptyNullUndefined(this.state.TotalInvoices) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.TotalInvoices) : null}`}
        />


        {/* {!isEmptyNullUndefined(this.state.MajorClientsForSelectedYear) && //!isEmptyNullUndefined(this.state.MajorClientsForSelectedYear[0].y) && //false && */}

          {/* Y Axis */}
          <VictoryAxis
            standalone={false}
            dependentAxis
            domain={[0, this.state.maxClientAmount]}
            // tickFormat specifies how ticks should be displayed
            tickFormat={(x) => this.state.MajorClientsForSelectedYear.length > 0 && this.state.MajorClientsForSelectedYear[0].y > 10000 ? '$' + new Intl.NumberFormat().format(x / 1000) : '$' + new Intl.NumberFormat().format(x)}
            // label="Amount"
            style={{
              grid: {
                stroke: ({ tick }) =>
                  tick === 0 ? "#000000" : "#ffffff",
                strokeWidth: 2
              },
              axis: { stroke: '#000000', strokeWidth: 1 },
              ticks: { strokeWidth: 0 },
              tickLabels: {
                fill: BLUE_COLOR,
                fontFamily: "inherit",
                fontSize: 8
              }
            }}
          // animate={{ duration: 500 }}
          />
        {/* } */}

        {/* X Axis */}
        <VictoryAxis
          standalone={false}
          style={{
            axisLabel: { fontSize: 15, padding: 20 },
            tickLabels: { fontSize: 7, padding: 10, angle: -20 }
          }}
          domain={[0, this.state.MajorClientsForSelectedYear.length > 0 ? this.state.MajorClientsForSelectedYear.length : 1]}
          tickValues={[...Array(this.state.MajorClientsForSelectedYear.length + 1).keys()]}
          tickFormat={(q) => {
            // console.log(`'This is length: ${this.state.MajorClientsForSelectedYear.length} and this is q: ${q}`)
            return q === 0 ? null : q <= this.state.MajorClientsForSelectedYear.length ? this.state.MajorClientsForSelectedYear[q - 1].x : null
          }}
        // return q }}
        // tickCount={3}
        // tickValues={xTickValues}
        // animate={{ duration: 500 }}
        />

        {this.props.selectedPartner === 0 &&

          <VictoryLegend
            standalone={false}
            x={60}
            y={285}
            groupComponent={<g style={{ background: 'green' }} />}

            // height={5}
            width={5}
            // title="Invoices"
            // centerTitle
            orientation="horizontal"
            gutter={20}
            rowGutter={1}
            // itemsPerRow={2}
            // borderPadding={{ top: 5, bottom: 0 }}
            padding={0}
            style={{
              // backgroundColor: 'blue',
              border: { stroke: 'black' },
              margin: '0px',
              padding: '0px',
              // fill: 'red',
              // border: { stroke: "black" }, 
              data: { size: 2 },
              title: { fontSize: 8 },
              labels: { fontSize: 5 },
              parent: { border: "10px solid #ccc", background: '#ff0080' }
            }}
            // colorScale={this.state.employees.map(item => item.color)}
            // labelComponent={<VictoryLabel lineHeight={0} style={[{ fontSize: 7 }]}/>}
            data={this.props.clients.employeesarray
              .reduce((acc, result) => {
                //Reduce array to only those whose level is equal to or below ORIGINATING_PARTNER_LEVEL
                // console.log('This is result: ', result)
                // if (this.props.clients.employeesarray.find(employee => employee[0] === result.id)[5] <= config.LEVELS.ORIGINATING_PARTNER_LEVEL) {
                if (result[5] <= config.LEVELS.ORIGINATING_PARTNER_LEVEL) {
                  acc.push(result)
                }
                return acc
              }, []
              )
              .map(item => {
                let obj = {}
                obj.name = item[1] + ' ' + item[2]
                // console.log(item)
                // console.log(`Colorscheme for i ${item} is ${colorScheme[item[0]-1]}`)
                // console.log(colorScheme.length, item[0] -1)
                obj.symbol = { fill: colorScheme.length>= item[0] -1 ? colorScheme[item[0] - 1] : '#c3c3c3', type: 'square' }
                return obj
              })}
          />
        }


        {!isEmptyNullUndefined(this.state.MajorClientsForSelectedYear) &&
          <VictoryBar
            standalone={false}
            animate={{
              duration: 500,
              onLoad: { duration: 10 }
            }}
            data={this.state.MajorClientsForSelectedYear}
            x={(data) => data.number}
            y={(data) => data.y}
            domain={{ x: [0, this.state.MajorClientsForSelectedYear.length], y: [0, this.state.maxClientAmount] }}
            style={{
              data: {
                fill: ({ datum }) => `url(#gradient${datum.employee_id - 1})`,
                stroke: "#000000",
                // fillOpacity: 0.6,
                strokeWidth: 1
              }
            }}
            barWidth={this.state.MajorClientsForSelectedYear.length > 3 ? 12 : 18}
            labels={({ datum }) => `${!isEmptyNullUndefined(datum.employee) ? `${datum.x}\nOriginating Partner:\n${datum.employee}\n${datum.y.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}` : ''}`}
            labelComponent={<VictoryTooltip
              constrainToVisibleArea
              renderInPortal={false}
              style={{ fill: 'black', fontSize: 8 }}
              flyoutStyle={{
                stroke: "tomato",
                strokeWidth: 1,
                fill: "#ffffe3"
              }}
            />}
          />
        }

        {!this.state.pictureDelay && this.state.MajorClientsForSelectedYear.map((employee, index) =>
          <g key={'PartnerPicID-' + employee.employee_id + index}>
            {/* {renderSVG({ x: 400 - index * 350/this.state.MajorClientsForSelectedYear.length, y: 260 },
                    { x: 400 - index * 350/this.state.MajorClientsForSelectedYear.length, y: 240 }, 3, 7, 6, RED_COLOR)} */}
            <image style={{ textAlign: 'baseline' }}
              x={395 - (this.state.MajorClientsForSelectedYear.length - index - 1) * 350 / this.state.MajorClientsForSelectedYear.length - 3 * (1 / this.state.MajorClientsForSelectedYear.length)}
              y={235} width={15 - .6 * this.state.MajorClientsForSelectedYear.length} href={employee.e_picture} />
          </g>
        )}


        {/* </VictoryChart> */}
      </svg>

    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsMajorClientsSVG))


ChartsMajorClientsSVG.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  currentYear: new Date().getFullYear(),
  selectedYear: new Date().getFullYear(),
  selectedPartner: 0,
  MajorClients: [],
  isMounted: false,
  // handleSomething: () => { },
}