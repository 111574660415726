import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

import DeleteCounterparty from './Component-DeleteCounterparty'
import DeleteNotes from './Component-DeleteNotes'
import DeletePerson from './Component-DeletePerson'

// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'

import { isEmptyNullUndefined } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';

let initialDeleteCounterparty = {
  LNotesExist: true,
  TNotesExist: true,
  LawyersExist: true,
  TycoonsExist: true,
  CounterpartiesExist: true,
}

class Counterparties extends Component {

  state = {
    Counterparties: [],
    selectedCParty: null,
    DeleteCounterparty: initialDeleteCounterparty,
    modalOpen: false,
    Row: {},
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getCounterparties()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getCounterparties(), 200)
    }
  }


  getCounterparties = async () => {
    // console.log('In getCounterparties...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getCounterparties', { fetchstr: 'nothing' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getCounterparties: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    if (!isEmptyNullUndefined(fetchresponse.payload)) this.setState({
      Counterparties: fetchresponse.payload
    })
    else this.setState({ Counterparties: [] })
  }

  setStateCParty = (counterparty_id, callback) => {
    this.setState({ DeleteCounterparty: initialDeleteCounterparty })
    this.setState({ selectedCParty: counterparty_id }, () => {if (callback) callback()})
    // if (callback) callback()
  }

  DeleteCounterparty = async () => {
    // console.log('Running handleDeleteCounterparty on this counterparty: ', this.state.selectedCParty)

    if (!this.state.DeleteCounterparty.CounterpartiesExist && !this.state.DeleteCounterparty.LNotesExist && !this.state.DeleteCounterparty.TNotesExist && !this.state.DeleteCounterparty.LawyersExist && !this.state.DeleteCounterparty.TycoonsExist) {

      // console.log(`This is counterparty_id: ${this.state.selectedCParty}`)

      // Find any counterparty-matter links  for the counterparty (and if any then break)
      // fetchresponse = await szenokPostAPI('counterpartyInfo', { fetchstr: 'getCounterpartyMattersForCounterparty', counterpartyID: this.state.selectedCParty },
      // this.props.login.currentToken)
      // paymentsExist = !isEmptyNullUndefined(fetchresponse.payload)
      // if (paymentsExist) { console.log('There is a problem.  paymentsExist is: ', paymentsExist); return }

      try {

        //Clear fetcherrors, info and such
        this.props.fetchResetState('all')

        let fetchresponse = {}
        fetchresponse = await szenokPostAPI('deleteCounterparty', {
          counterpartyID: this.state.selectedCParty
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteCounterparty: ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {

          //Update the matters store
          // reduxupdate('matters')

          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          this.getCounterparties()
          reduxupdate('counterparties')
          this.setState({ selectedCParty: null })
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        this.props.fetchErrors(error.toString())
      }
    }
    // else console.log('There is a problem inside Component-Counterparties in DeleteCounterparty.  For some reason, all of the toggles have not been switched.')
  }

  handleDeleteLNote = () => {
    this.setState({ DeleteCounterparty: { ...this.state.DeleteCounterparty, LNotesExist: false } })
    this.DeleteCounterparty()
  }

  handleDeleteTNote = () => {
    this.setState({ DeleteCounterparty: { ...this.state.DeleteCounterparty, TNotesExist: false } })
    this.DeleteCounterparty()
  }

  handleDeleteLawyers = () => {
    // console.log('In handleDeleteLawyers...')
    this.setState({ DeleteCounterparty: { ...this.state.DeleteCounterparty, LawyersExist: false } })
    this.DeleteCounterparty()
  }

  handleDeleteTycoons = () => {
    // console.log('In handleDeleteTycoons...')
    this.setState({ DeleteCounterparty: { ...this.state.DeleteCounterparty, TycoonsExist: false } })
    this.DeleteCounterparty()
  }

  handleDeleteCounterparty = () => {
    // console.log('In handleDeleteTycoons...')
    this.setState({ DeleteCounterparty: { ...this.state.DeleteCounterparty, CounterpartiesExist: false } })
    this.DeleteCounterparty()
  }

  handleCancelDelete = () => { this.setState({ selectedCParty: null }) }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }
  setRow = (value) => {
    if (!isEmptyNullUndefined(value)) this.setState({ Row: { counterparty_id: value.values.counterparty_id, counterparty: value.values.counterparty } })
    else this.setState({ Row: {} })
  }

  predicatesExist = () => {
    let notesExist = this.state.DeleteCounterparty.LNotesExist || this.state.DeleteCounterparty.TNotesExist
    let personsExist = this.state.DeleteCounterparty.LawyersExist || this.state.DeleteCounterparty.TycoonsExist
    let predicatesExist = notesExist || personsExist
    // console.log('Returning from predicatesExist: ', predicatesExist.toString())
    return predicatesExist
  }

  render() {


    return (
      <div>
        <h1> <Icon name='address book outline' size='large' circular /> Counterparties</h1>

        {!this.predicatesExist() && (!isEmptyNullUndefined(this.state.selectedCParty) || !isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeleteCounterparty counterpartyID={this.state.selectedCParty} matterID={this.state.selectedMatter}
            clientID={this.state.selectedClient}
            handleDeleteCounterparty={this.handleDeleteCounterparty}
          />
          // getCounterparties={this.getCounterparties}
          // handleDeleteCounterparty={this.handleDeleteCounterparty} handleCancelDelete={this.handleCancelDelete} />
        }

        {(this.state.DeleteCounterparty.LNotesExist || this.state.DeleteCounterparty.TNotesExist) && (!isEmptyNullUndefined(this.state.selectedCParty) || !isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeleteNotes counterpartyID={this.state.selectedCParty} matterID={this.state.selectedMatter}
            clientID={this.state.selectedClient}
            handleDeleteLNote={this.handleDeleteLNote} handleDeleteTNote={this.handleDeleteTNote}
            handleCancelDelete={this.handleCancelDelete}
          />
          // getCounterparties={this.getCounterparties}
          // handleDeleteCounterparty={this.handleDeleteCounterparty} handleCancelDelete={this.handleCancelDelete} />
        }

        {(!isEmptyNullUndefined(this.state.selectedCParty) || !isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeletePerson personID={this.state.selectedPerson} matterID={this.state.selectedMatter} counterpartyID={this.state.selectedCParty}
            clientID={this.state.selectedClient}
            // getCounterparties={this.getCounterparties}
            handleDeleteLawyers={this.handleDeleteLawyers} handleDeleteTycoons={this.handleDeleteTycoons}
            handleCancelDelete={this.handleCancelDelete} />
        }

        <ReactTableMaker passedData={this.state.Counterparties}
          passedColumns={CounterpartiesColumns(this.setStateCParty, this.DeleteCounterparty, this.props.history, this.setModalOpen, this.setRow, !!this.props.login.currentObserver)}
          handleClick={(rowID, cellID, rowValues) => {
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
          }}
          includeSearchBar={false}
          includePaginationFooter={true}
        />

        {/* This is the "are you sure" modal for deleting a row */}
        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              this.setStateCParty(this.state.Row.counterparty_id,
                this.DeleteCounterparty
              )
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Counterparty'
            deleteObject={this.state.Row.counterparty}
          />}

        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
function minusFontColor(e) { e.target.style.color = "" }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Counterparties))

let CounterpartiesColumns = (setStateCParty, DeleteCounterparty, history, setModalOpen, setRow, observer) => {
  return [
    // {
    //   Header: () => <span style={{ 'float': 'left' }}>Counterparty ID</span>,
    //   accessor: 'counterparty_id',
    //   show: true,
    //   width: 0,
    //   sortType: 'alphanumeric',
    //   Filter: 'defaultColumnFilterLeft'
    // },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'counterparty',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => {
            // console.log('Yo, this is the row: ', row)
            history.push(`/counterparty/${cell.value}?counterparty=${cell.value}&counterpartyID=${row.values.counterparty_id}`)}}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matters{<br/>}Involved</span>,
      accessor: 'matters_involved',
      Filter: '',
      Cell: ({ cell }) => <span >{cell.value>0 ? cell.value : '-'}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Businesspersons{<br/>}Involved</span>,
      accessor: 'tycoons_involved',
      Filter: '',
      Cell: ({ cell }) => <span >{cell.value>0 ? cell.value : '-'}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Notes{<br/>}Entered</span>,
      accessor: 'notes_for_counterparty',
      Filter: '',
      Cell: ({ cell }) => <span >{cell.value>0 ? cell.value : '-'}</span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'counterparty_id',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
        <Link to={`editcounterparty/${cell.value}`}><Icon name='edit' />Edit</Link></span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'counterparty_id2',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor} >
        <Link to={''} onClick={(e) => {
          e.preventDefault()
          setModalOpen(true)
          setRow(row)
          // setStateCParty(row.values.counterparty_id)
          // DeleteCounterparty()
        }}>
          <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
    },
  ]
}

