import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { isEmptyNullUndefined, Loading } from '../helper/Misc'
import { toIMGcanvas } from '../helper/PDFImage'

// import { Divider, Dropdown, Header, Grid, Checkbox } from 'semantic-ui-react';


// import DisplayState from '../helper/DisplayState';

import { VictoryBar, VictoryAxis, VictoryLine, VictoryLabel, VictoryTooltip } from 'victory'

let bufferMonths = 4

class ChartsMattersOverTime extends Component {

  state = {
    // TokenReady: false,
    loading: true,
    currentYear: null,
    selectedYear: null,
    fetchObject: {},
    chartMinAmount: 0,
    chartMaxAmount: 10,
    chartMinDate: new Date(),
    chartMaxDate: new Date(),
    chartStartDate: new Date(),
    tempMatters: [],
    plotMatters: [],
    isMounted: false,
    mattersMounted: false,
    invoicesMounted: false,
    noMatters: true,
  }


  componentDidMount() {
    this.setState({ isMounted: true })
    this.props.setLoadingMattersOverTimeChart(true)
    !isEmptyNullUndefined(this.props.ClientInfo.client_id) && this.getInvoicesOutstanding()
    !isEmptyNullUndefined(this.props.matters) && this.setState({
      tempMatters: this.props.matters.map(matter => { return { date: new Date(matter.matter_origination_date), amount: 1, matter_name: matter.matter_name } }),
      noMatters: false
    })
    isEmptyNullUndefined(this.props.matters) && this.setState({ mattersMounted: true })


  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.ClientInfo !== this.props.ClientInfo) {
      !isEmptyNullUndefined(this.props.ClientInfo.client_id) && this.getInvoicesOutstanding()
    }
    if (prevState.mattersMounted !== this.state.mattersMounted || prevState.invoicesMounted !== this.state.invoicesMounted) {
      if (!this.state.mattersMounted || !this.state.invoicesMounted) this.props.setLoadingMattersOverTimeChart(true)
      else this.props.setLoadingMattersOverTimeChart(false)
    }
    if (prevProps.matters !== this.props.matters) {
      this.state.isMounted && this.setState({ tempMatters: this.props.matters.map(matter => { return { date: new Date(matter.matter_origination_date), amount: 1, matter_name: matter.matter_name } }) })
    }
    if (prevState.tempMatters !== this.state.tempMatters) {
      this.state.isMounted && this.setState({ plotMatters: this.sumDates(this.quarterifyDates(this.state.tempMatters)) },
        () => {
          this.getDates()
          this.state.isMounted && this.setState({
            chartMinAmount: this.getMinAmount(this.state.plotMatters),
            chartMaxAmount: this.getMaxAmount(this.state.plotMatters) + 1,

          }, () => this.setState({
            quarters: this.generateQuarters(this.state.chartStartDate, this.state.chartMaxDate),
            mattersMounted: true
          }))
        }
      )
    }
  }

  componentWillUnmount() {
    this.setState({ isMounted: false })
    this.props.setLoadingMattersOverTimeChart(false)
  }

  getDates = () => {
    let tempState = Object.assign({}, this.state)
    // console.log('This is tempState: ', tempState)
    let mindate = this.getMinDate(tempState)
    // let startDate = this.getStartDate(mindate)
    this.state.isMounted && this.setState({ chartMinDate: mindate, chartStartDate: this.quarterifyDates([{ date: new Date(this.props.ClientInfo.origination_date), amount: 1 }])[0].date })
  }

  getMinAmount = (array) => {
    return !isEmptyNullUndefined(array) ? array.reduce((min, p) => p.amount < min ? p.amount : min, array[0].amount) : null
  }

  getMaxAmount = (array) => {
    return !isEmptyNullUndefined(array) ? array.reduce((max, p) => p.amount > max ? p.amount : max, array[0].amount) : null
  }
  getMaxDate = () => {
    let date = !isEmptyNullUndefined(this.state.plotMatters) ? this.state.plotMatters.reduce((max, p) => p.date > max ? p.date : max, this.state.plotMatters[0].date) : null
    return !isEmptyNullUndefined(this.state.plotMatters) ? date : null
  }

  getMinDate = (tempState) => {
    let mindate = tempState.plotMatters.reduce((min, p) => p.date < min ? p.date : min, tempState.plotMatters[0].date)
    return mindate
  }

  getStartDate = (mindate) => {
    let startdate = new Date(mindate)
    startdate.setMonth(startdate.getMonth() - bufferMonths)
    return startdate
  }

  quarterifyDates = (matters) => {
    // console.log('This is matters in quarterifyDates: ', matters)
    let returnArray = matters.reduce((acc, item, i, matters) => {
      let months = [0, 1, 4, 7, 10]
      let obj = {}
      let quarter = Math.ceil((item.date.getMonth() + 1) / 3)
      // obj.date = item.date
      // obj.quarter = quarter
      obj.date = new Date(item.date.getFullYear(), months[quarter] - 1, 1)
      obj.amount = item.amount
      obj.matter_name = item.matter_name
      acc.push(obj)
      return acc
    }, [])
    // console.log('This is quartifyarray: ', returnArray)
    return returnArray
  }

  sumDates = (matters) => {
    //Start off by adding a namesArray property to each matter and filling it in with its own matter_name
    matters = matters.map(item => {
      item.namesArray = [item.matter_name]
      return item
    })
    let returnArray = matters.reduce((accumulator, cur) => {
      //Get the date of the current item
      let date = cur.date

      //See whether there is already a same date in the accumulator
      let found = accumulator.find((item) => item.date.getTime() === date.getTime());

      //If we find a duplicate date, then add the current.amount to the found.amount (and also push the matter_name into its matter name array)
      if (found) {
        found.amount += cur.amount
        found.namesArray.push(cur.matter_name)
      }

      //Otherwise, since it's not already in the accumulator, then push this unique current into accumulator
      else accumulator.push(cur)
      // console.log('This is namesArray: ', namesArray)
      return accumulator;
    }, [])
    // console.log('This is returnArray: ', returnArray)
    return returnArray
  }

  generateQuarters = (beginDate, endDate) => {
    let months = [0, 1, 4, 7, 10]

    let getQuarter = (date) => Math.ceil((date.getMonth() + 1) / 3)

    let beginYear = beginDate.getFullYear()
    let endYear = endDate.getFullYear()
    let beginQuarter = getQuarter(beginDate)
    let endQuarter = getQuarter(endDate)
    // console.log(`beginYear: ${beginYear};  endYear: ${endYear}; beginQuarter: ${beginQuarter}; endQuarter: ${endQuarter}`)

    let quarterlist = []
    while (beginYear !== endYear || beginQuarter !== endQuarter) {
      quarterlist.push(new Date(beginYear, months[beginQuarter] - 1, 1));
      beginQuarter++;
      if (beginQuarter > 4) {
        beginQuarter = 1;
        beginYear++;
      }
      if (beginYear === endYear && beginQuarter === endQuarter)
        quarterlist.push(new Date(beginYear, months[beginQuarter] - 1, 1));
      // console.log(`beginYear: ${beginYear};  endYear: ${endYear}; beginQuarter: ${beginQuarter}; endQuarter: ${endQuarter}`)
    }

    return quarterlist
  }

  getInvoicesOutstanding = async () => {
    // console.log(this.props.ClientInfo.client_id)
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('invoiceInfo', { fetchstr: 'getInvoicesForClient', clientID: this.props.ClientInfo.client_id },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getInvoicesForClient: ', fetchresponse)
    let Invoices = this.sumDates(this.quarterifyDates(fetchresponse.payload.map(item => { return { date: new Date(item.invoice_date), amount: item.invoice_amount, matter_name: item.matter_name } })))
    let SortedInvoices = Invoices.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.date) - new Date(b.date);
    })
    let cumul = 0
    let cumulativeInvoices = SortedInvoices.reduce((accumulator, current, index, array) => {


      // console.log(cumul, current.amount)
      cumul = cumul + current.amount
      // console.log(cumul)
      let obj = {}
      obj.date = current.date
      obj.amount = cumul
      // console.log(accumulator, obj)
      accumulator.push(obj)
      // if(index === array.length-1) accumulator.push({date: this.state.chartStartDate, amount: accumulator[accumulator.length-1].amount})
      return accumulator
    }, [])
    // console.log(cumulativeInvoices)
    if (!isEmptyNullUndefined(cumulativeInvoices)) {

      // if (cumulativeInvoices[0].date.getTime() >= this.state.chartMinDate.getTime()) cumulativeInvoices.unshift({ date: this.state.chartStartDate, amount: 0 })
      cumulativeInvoices.unshift({ date: this.state.chartStartDate, amount: 0 })
      if (cumulativeInvoices[cumulativeInvoices.length - 1].date.getTime() < this.state.chartMaxDate.getTime()) cumulativeInvoices.push({ date: this.state.chartMaxDate, amount: cumulativeInvoices[cumulativeInvoices.length - 1].amount })
    }

    this.state.isMounted && this.setState({
      Invoices: cumulativeInvoices
    }, () => { this.setState({ invoicesMounted: true }) })
  }

  render() {

    let yTickValues = () => {
      let tickArray = []
      for (let i = 1; i <= this.state.chartMaxAmount; i++) {
        tickArray.push(i)
      }
      return tickArray
    }

    const BLUE_COLOR = "#00a3de"
    const RED_COLOR = "#7c270b"

    return (

      <React.Fragment>
        {/* <div style={{ width: 800 }} > */}
        {!this.state.mattersMounted || !this.state.invoicesMounted ? <Loading /> :
          this.state.noMatters ? <h4>No activity to show...</h4> :
            <div id='ChartMattersOverTime' >
              {!isEmptyNullUndefined(this.state.plotMatters) &&

                //Replced VictoryChart with the <svg> to enable multiple dependent axis
                // <VictoryChart
                //   scale={{ x: "time" }}
                //   theme={VictoryTheme.material}
                //   style={{ parent: { maxWidth: this.props.maxViewPercentage + '%', border: "1px solid #ccc" } }}
                // // domdomainPadding={20}
                // // domain={{
                // //   x: [ this.state.chartStartDate, new Date()],
                // //   y: [0, this.state.chartMaxAmount + 1]}}
                // // padding={75}
                // >

                <svg style={{
                  background: "#ebf5fa",
                  boxSizing: "border-box",
                  display: "inline",
                  padding: 0,
                  fontFamily: "'Fira Sans', sans-serif",
                  borderStyle: 'solid',
                  borderWidth: 'thin',
                  borderColor: '#a5a5a5',
                  borderRadius: '15px',
                  boxShadow: '2px 2px 8px #888888',
                  cursor: 'pointer',
                }}
                  onClick={() => toIMGcanvas('ChartMattersOverTime')}
                  height="350" viewBox="0 00 450 300">

                  <VictoryLabel x='50%' y={20} style={{
                    textAnchor: "middle",
                    verticalAnchor: "end",
                    fill: "#000000",
                    fontFamily: "inherit",
                    fontSize: "12px",
                    fontWeight: "bold"
                  }}
                    text="Client Activity"
                  />

                  <VictoryLabel x='50%' y={32} style={{
                    textAnchor: "middle",
                    verticalAnchor: "end",
                    fill: "#000000",
                    fontFamily: "inherit",
                    fontSize: "8px",
                    fontStyle: "italic"
                  }}
                    text={'(' + this.props.ClientInfo.client_name + ')'}
                  />
                  {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
                    <VictoryLabel x={425} y={30} style={{
                      textAnchor: "end",
                      fill: BLUE_COLOR,
                      fontFamily: "inherit",
                      fontSize: 8,
                      fontStyle: "italic"
                    }}
                      text={"Invoices\n(in $1,000)"}
                    />
                  }

                  {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
                    <VictoryLabel x={425} y={285} style={{
                      textAnchor: "end",
                      fill: BLUE_COLOR,
                      fontFamily: "inherit",
                      fontSize: 8,
                      // fontStyle: "italic"
                    }}
                      text={`Total Invoiced: ${!isEmptyNullUndefined(this.state.Invoices) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.Invoices[this.state.Invoices.length - 1].amount) : null}`}
                    />
                  }

                  <VictoryLabel x={25} y={30} style={{
                    fill: RED_COLOR,
                    fontFamily: "inherit",
                    fontSize: 8,
                    fontStyle: "italic"
                  }}
                    text={"Matters Originated\n(per quarter)"}
                  />

                  <VictoryLabel x={25} y={285} style={{
                    fill: RED_COLOR,
                    textAnchor: 'start',
                    fontFamily: "inherit",
                    fontSize: 8,
                    fontStyle: "italic"
                  }}
                    text={"Client Origination Date: " + this.props.ClientInfo.origination_date}
                  />

                  <g transform={"translate(0, 0)"}>
                    {/* X Axis */}
                    {/* Quarters */}
                    <VictoryAxis
                      scale="time"
                      standalone={false}
                      style={{
                        axis: { stroke: "black", strokeWidth: 1 },
                        ticks: {
                          size: 5,
                          stroke: "black",
                          strokeWidth: 1
                        },
                        tickLabels: { fontSize: 6, padding: 1 },
                      }}
                      tickValues={this.state.quarters}
                      tickFormat={(q) => q.getMonth() === 0 ? 'Q1' : q.getMonth() === 3 ? 'Q2' : q.getMonth() === 6 ? 'Q3' : 'Q4'}
                    />
                    {/* Years */}
                    <VictoryAxis
                      scale="time"
                      standalone={false}
                      style={{
                        axis: { stroke: "black", strokeWidth: 1 },
                        tickLabels: { fontSize: 8, padding: 12 }
                      }}
                      tickValues={this.state.quarters}
                      tickFormat={(q) => q.getMonth() === 0 ? q.getFullYear() : ''}
                    />

                    {/* DATASET ONE */}
                    {/* Y Axis */}
                    <VictoryAxis
                      dependentAxis
                      orientation="left"
                      standalone={false}
                      style={{
                        // axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 8, padding: 20 },
                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "6d65a0" : "grey" },
                        // ticks: { stroke: "grey", size: 3 },
                        tickLabels: {
                          fontSize: 8,
                          padding: 5,
                          fill: RED_COLOR,
                          fontFamily: "inherit",
                        }
                      }}
                      // label='Matters Originated'
                      tickValues={yTickValues()}
                      domain={[0, this.state.chartMaxAmount]}
                    />
                    <VictoryBar
                      // domdomainPadding={20}
                      standalone={false}
                      data={this.state.plotMatters}
                      x={(data) => data.date}
                      y={(data) => data.amount}
                      // y="amount"
                      // domain={{
                      //   x: [ this.state.chartMinDate, this.state.chartMaxDate],
                      //   y: [0, this.state.chartMaxAmount + 1]}}
                      style={{ data: { fill: RED_COLOR } }}
                      alignment="start"
                      domain={{ x: [this.state.chartStartDate, new Date()], y: [0, this.state.chartMaxAmount] }}
                      barWidth={({ index }) => 8}
                      scale={{ x: "time", y: "linear" }}

                      labels={({ datum }) => `${!isEmptyNullUndefined(datum.namesArray) ? datum.namesArray.join(',\n') : ''}`}
                      labelComponent={<VictoryTooltip
                        constrainToVisibleArea
                        renderInPortal={false}
                        style={{ fill: 'black', fontSize: 8 }}
                        flyoutStyle={{
                          stroke: "tomato",
                          strokeWidth: 1,
                          fill: "#ffffe3"
                        }}
                      />}

                      
                    />
                    {/* DATASET TWO */}
                    {/* Y Axis */}
                    {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
                      <VictoryAxis
                        dependentAxis
                        orientation="right"
                        standalone={false}
                        // style={{
                        //   // axis: { stroke: "#756f6a" },
                        //   axisLabel: { fontSize: 10, padding: 20 },
                        //   // grid: { stroke: ({ tick }) => tick > 0.5 ? "6d65a0" : "grey" },
                        //   // ticks: { stroke: "grey", size: 3 },
                        //   tickLabels: { fontSize: 6, padding: 2 }
                        // }}
                        style={{
                          grid: {
                            stroke: ({ tick }) =>
                              tick === 0 ? "#000000" : "#ffffff",
                            strokeWidth: 2
                          },
                          axis: { stroke: '#000000', strokeWidth: 1 },
                          ticks: { strokeWidth: 0 },
                          tickLabels: {
                            fill: BLUE_COLOR,
                            fontFamily: "inherit",
                            fontSize: 8
                          }
                        }}
                        tickFormat={(q) => '$' + new Intl.NumberFormat('en-US', { currency: 'USD', style: 'decimal' }).format(q / 1000) + 'k'}
                        // label='Invoices'
                        domain={[0, this.getMaxAmount(this.state.Invoices)]}
                      // tickValues={yTickValues()}
                      />}
                    {!isEmptyNullUndefined(this.state.Invoices) && (this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
                      <VictoryLine
                        // domdomainPadding={20}
                        standalone={false}
                        data={this.state.Invoices}
                        x={(data) => data.date}
                        y={(data) => data.amount}
                        interpolation="monotoneX"
                        scale={{ x: "time", y: "linear" }}
                        style={{
                          data: { stroke: BLUE_COLOR, strokeWidth: 3.5 }
                        }}
                        // domain={{
                        //   x: [ this.state.chartMinDate, this.state.chartMaxDate],
                        //   y: [0, this.state.chartMaxAmount + 1]}}
                        // style={{ data: { fill: "#0268f2" } }}
                        // alignment="start"
                        domain={{ x: [this.state.chartStartDate, new Date()], y: [0, this.getMaxAmount(this.state.Invoices)] }}
                      // x="date"
                      // y="amount"
                      // barWidth={({ index }) => 8}
                      />
                    }

                    {/* </VictoryChart> */}
                  </g>
                </svg>
              }
            </div>
        }
      </React.Fragment>


    )
  }
}



const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsMattersOverTime))

ChartsMattersOverTime.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  matters: [],
  ClientInfo: {
    client_id: null,
    client_name: ''
  },
  setLoadingMattersOverTimeChart: () => { }

  // handleSomething: () => { },
}