import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, filterSimpleInput, filterSimpleInputOn2, ListMakerSimpleClickableNameEmail } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'
// import NumberFormat from 'react-number-format'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Header, Label, Divider, Button, Segment } from 'semantic-ui-react';
// import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';

// import queryString from 'query-string'

const initialState = {
  visible: false,
  ExistingPersons: [],
  PName: null,
  PEmail: null,
  showAddPersonForm: false,
  PName2Regex: [],
  PName2RegexSpecific: '',
  PNameArray: [],
  initialValues: {},

}

class AddPerson extends Component {

  state = initialState

  componentDidMount() {
    this.props.fetchResetState('all')
    this.getPersons()
    if (this.props.update) this.getSpecificPerson()
    // console.log('These are the props on loading AddPerson: ', this.props)
  }

  getSpecificPerson = async () => {
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getSpecificPerson', IDNumber: this.props.match.params.personID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getPerson: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { PName: fetchresponse.payload[0].person_name, PEmail: fetchresponse.payload[0].person_email }
    })
  }

  async getPersons() {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'persons' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse.payload: ', fetchresponse.payload)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {
      this.setState({ ExistingPersons: fetchresponse.payload.map(row => [row.person_id, row.person_name, row.person_email]) })
    }
    else this.setState({ ExistingPersons: [] })
  }

  // showAddPersonFormToggle = () => {
  //   this.setState(prevState => ({ showAddPersonForm: !prevState.showAddPersonForm }))
  // }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilterPName = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted } = await filterfunction(filterarray, e)
    this.setState({ PName2Regex: updatedListGeneralSorted })
    // if (updatedListSpecific.length > 0) {
    //   this.setState({ PName2RegexSpecific: updatedListSpecific })
    //   setStatus({ errors: errObj })
    // }
    // else {
    //   this.setState({ PName2RegexSpecific: '' })
    //   setStatus({ errors: {} })
    // }

  }, 100)

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ PName2Regex: updatedListGeneralSorted })
    // console.log(updatedListSpecific)
    if (updatedListSpecific.length > 0) {
      if (this.props.update && updatedListSpecific[0][2] !== this.state.initialValues.PEmail) {
        this.setState({ PName2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
      else if (this.props.update && updatedListSpecific[0][2] === this.state.initialValues.PEmail) {
        this.setState({ PName2RegexSpecific: '' })
        setStatus({ errors: {} })
      }
      if (!this.props.update) {
        this.setState({ PName2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ PName2RegexSpecific: '' })
      setStatus({ errors: {} })
    }

  }, 100)

  addPersonAction = async (props, personID, setStatus) => {
    // event.preventDefault()
    let type = this.props.showAddLawyerForm ? 'addLawyerInfo' : 'addTycoonInfo'
    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      // console.log('Sending out these event: ', event)
      // console.log('Sending out these type: ', type)
      // console.log('Sending out these values: ', values)
      // console.log('Sending out these actions: ', actions)
      // console.log('Sending out these props: ', props)
      // console.log('This is this.props: ', this.props.lawFirmID)
      // console.log('This is the matter: ', queryString.parse(this.props.location.search))
      // this.setState({ CParty2Regex: [], CParty2RegexSpecific: '' })

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI(type, {
        CpartyMatterID: this.props.selectedCPartyMatterID,
        PersonID: personID,
        LawFirmID: this.props.LawFirmID
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for something: ', fetchresponse)
      // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

      if (fetchresponse.validationerrors !== null) {
        setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
      }

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

        // console.log('Successfully added a counterparty...')
      }
      this.props.showAddLawyerForm ? this.props.getLawyers() : this.props.getTycoons()
      // this.props.showCPartyFormToggle(false)
      // this.props.showAddLawyerFormToggle(false)
      // this.props.showAddTycoonFormToggle(false)
      if (this.props.fromAddCPInfo) {
        reduxupdate('persons')
        this.props.closeCallback(false)
      }
    }
    catch (error) {
      console.log('In catch in Component-AddPerson and this is the error: ', error)
      let obj = []
      let str = 'Error in /Component-AddPerson ' + error.name === undefined ? error : `${error.name}: ${error.message}`
      obj.push(str)
      props.fetchErrors(obj)
    }
    // actions.setSubmitting(false)
  }

  render() {

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      PName: null,
      PEmail: null,
    } :
      {
        ...this.state.initialValues
      }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300


    return (
      <div>
        {this.props.update === true ?
          <Header as='h1'>Person Edit Form</Header> :
          <Header as='h3'>{this.props.headingForForm}</Header>
        }
        {this.props.update &&
          <Header as='h2'>Editing: {this.state.initialValues.PName}</Header>
        }


        <Formik

          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          addPersonAction={this.addPersonAction}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={PersonSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              // console.log('This is the matter: ', queryString.parse(this.props.location.search))
              // this.setState({ CParty2Regex: [], CParty2RegexSpecific: '' })
              let type = this.props.showAddLawyerForm ? 'addLawyerInfo' : 'addTycoonInfo'
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editPerson', { PName: values.PName, PEmail: values.PEmail, PersonID: this.props.match.params.personID }, this.props.login.currentToken)
              else if (this.props.cleanadd) fetchresponse = await szenokPostAPI('addPersonClean', { PName: values.PName, PEmail: values.PEmail }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addPerson', {
                PName: values.PName,
                PEmail: values.PEmail,
                CpartyMatterID: this.props.selectedCPartyMatterID,
                LawFirmID: this.props.LawFirmID,
                Type: type
              }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for add/editperson: ', fetchresponse)

              actions.setSubmitting(false)

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Now we've added the person so add to the lawyers_counterparty_matter
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                // console.log('This is the ID maybe?: ', fetchresponse.insertId)
                // let newPersonIDfetch = await szenokPostAPI('tablesFetchCustom', { QUERY: 'SELECT LAST_INSERT_ID()' }, this.props.login.currentToken)
                // console.log('This is newPersonID: ', newPersonID)
                // let newPersonID = newPersonIDfetch.payload[0]['LAST_INSERT_ID()']
                // console.log('This is newPersonID: ', newPersonID)
                // fetchresponse = await szenokPostAPI('addLawyerInfo', {
                //   CpartyMatterID: this.props.selectedCPartyMatterID,
                //   PersonID: personID,
                //   LawFirmID: this.props.LawFirmID
                // }, this.props.login.currentToken)


                // let type = this.props.showAddLawyerForm ? 'addLawyerInfo' : 'addTycoonInfo'

                // let fetchresponse = {}
                // fetchresponse = await szenokPostAPI(type, {
                //   CpartyMatterID: this.props.selectedCPartyMatterID,
                //   PersonID: personID,
                //   LawFirmID: this.props.LawFirmID
                // }, this.props.login.currentToken)

              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                // console.log('Successfully added a counterparty...')

                // //Update the counterparty store
                // reduxupdate('counterparty')

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                actions.resetForm({ ...initialValues })
                this.setState(initialState)
                if (this.props.update) {
                  reduxupdate('persons')
                  await this.getSpecificPerson()
                  actions.setSubmitting(false)
                  this.props.history.goBack()
                }

                else if (this.props.fromAddCPInfo) {
                  reduxupdate('persons')
                  this.props.closeCallback(false)
                }

                else if (this.props.cleanadd) {
                  reduxupdate('persons')
                  this.props.history.goBack()
                }

                else {
                  reduxupdate('persons')
                  this.props.getLawyers()
                  this.props.getTycoons()
                  // this.props.showCPartyFormToggle(false)
                  // this.props.showAddLawyerFormToggle(false)
                  // this.props.showAddTycoonFormToggle(false)
                }
              }
            }
            catch (error) {
              console.log('In catch in Component-AddPerson and this is the error: ', error)
              let obj = []
              let str = 'Error in /Component-AddPerson ' + error.name === undefined ? error : `${error.name}: ${error.message}`
              obj.push(str)
              this.props.fetchErrors(obj)
              actions.setSubmitting(false)
            }
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment basic>
                  <Form onSubmit={handleSubmit} noValidate autoComplete='off'>
                    <Label>Only email addresses must be unique. (If you do not enter an email addresses
                    for a person, the database will treat that person as another
                    person with the same name and will not connect him/her with any other entries.)
                      </Label>
                    <Form.Group>
                      {/* Add the full name but don't care about uniqueness */}
                      <Form.Field width={8}>
                        <Form.Input
                          name="PName" type="text"
                          label={`Full name (First Name _ Last Name) for ${this.props.typeOfPerson}`}
                          loading={this.props.fetch.fetching}
                          required
                          placeholder={`Full Name of ${this.props.typeOfPerson}`}
                          error={(errors.PName && touched.PName) || (has(status, 'errors.PName'))}
                          onChange={async (e) => {
                            await handleChange(e);
                            this.setState({ PName: e.target.value })
                            this.debouncedFilterPName(e.target.value, filterSimpleInput, setStatus, this.state.ExistingPersons, { PName: 'Name already used' })
                          }}
                          onBlur={handleBlur}
                          value={!isEmptyNullUndefined(values.PName) ? values.PName : ''} />
                        {this.errorTag(errors, touched, status, 'PName')}
                      </Form.Field>

                      {/* Add email and check for uniqueness */}
                      <Form.Field width={8}>
                        <Form.Input
                          name="PEmail" type="text"
                          label={`Email for ${this.props.typeOfPerson}`}
                          loading={this.props.fetch.fetching}
                          required
                          placeholder={`Email for ${this.props.typeOfPerson}`}
                          error={(errors.PEmail && touched.PEmail) || (has(status, 'errors.PEmail'))}
                          onChange={async (e) => {
                            await handleChange(e);
                            this.setState({ PEmail: e.target.value })
                            this.debouncedFilter(e.target.value, filterSimpleInputOn2, setStatus, this.state.ExistingPersons, { PEmail: 'Email address already used' })
                          }}
                          onBlur={handleBlur}
                          value={!isEmptyNullUndefined(values.PEmail) ? values.PEmail : ''} />
                        {this.errorTag(errors, touched, status, 'PEmail')}
                      </Form.Field>
                    </Form.Group>

                    <Segment secondary={this.state.PName2RegexSpecific.length === 0} color={this.state.PName2RegexSpecific.length > 0 ? 'red' : 'blue'}
                      hidden={this.state.PName2Regex.length === 0}>
                      {!isEmptyNullUndefined(this.props.selectedCPartyMatterID) && <Label>Clicking on an entry below will select that person.</Label>}
                      {ListMakerSimpleClickableNameEmail(this.state.PName2Regex, values,
                        this.state.PName2RegexSpecific, 'PName', (item) => {

                          // console.log('Clicked: ' + item)
                          let found = this.state.ExistingPersons.find(row => row[0] === item)
                          // console.log('This is found: ', found)
                          setFieldValue('PName', found[1])
                          !isEmptyNullUndefined(this.props.selectedCPartyMatterID) && this.addPersonAction(this.props, found[0], setStatus)
                        })}
                    </Segment>

                    <Divider />
                    <Button.Group size={this.props.typeOfPerson === 'Person' ? 'large' : 'small'}>
                      <Button type='button' icon='cancel'
                        onClick={() => {
                          handleReset();
                          this.setState({ PName3: '', PEmail: '' })
                          // this.props.showCPartyFormToggle(false)
                          // this.props.showAddLawyerFormToggle(false)
                          // this.props.showAddTycoonFormToggle(false)
                          // this.props.showAddCounterpartyFormToggle(false)
                          // this.props.showCounterpartyInfoFormToggle(false)
                          this.props.closeCallback(false)
                        }}
                        color='red'
                        content='Cancel'></Button>
                      <Button.Or />
                      <Button color='teal'
                        onClick={() => { this.setState({ CParty: null, LawFirm: null, Role: '' }); handleReset() }}
                        type='button'
                        disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear'>
                      </Button>
                      <Button.Or />
                      <Button color='orange'
                        onClick={() => { setStatus({}); setErrors({}) }}
                        type='button'
                        //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Errors'>
                      </Button>
                      <Button.Or />
                      <Button type='submit'
                        // disabled={isSubmitting}
                        //Eventually use the below, the one above is just for testing
                        disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                        icon='upload'
                        loading={isSubmitting}
                        floated='right'
                        color={dirty ? 'green' : 'grey'}
                        content={(this.props.update === true ? 'Edit ' : 'Add ') + `${this.props.typeOfPerson}`}></Button>
                    </Button.Group>
                    {/* <DisplayState state={this.state} />
                    <DisplayFormikState /> */}
                  </Form>
                </Segment>
              </div>
            )}
        </Formik>

      </div>
    )
  }
}


const PersonSchema = Yup.object().shape({
  PName: Yup
    .string()
    .min(3, 'Person Name must be at least 3 characters.')
    .max(255, 'Person Name cannot exceed 255 characters.')
    .required('Must have a name for the person.'),
  PEmail: Yup
    .string()
    .email('Email must be a valid email.')
    .optional(),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPerson))

AddPerson.defaultProps = {
  update: false,
  cleanadd: true,
  fromAddCPInfo: false,
  // showCPartyFormToggle: () => { },
  // showAddLawyerFormToggle: () => { },
  // showAddTycoonFormToggle: () => { },
  // showAddCounterpartyFormToggle: () => { },
  // showCounterpartyInfoFormToggle: () => { },
  closeCallback: () => { },
  getLawyers: () => { },
  getTycoons: () => { },
  headingForForm: 'Add Person',
  typeOfPerson: 'Person',
  selectedCPartyMatterID: null,
  lawFirmID: null,
  showAddLawyerForm: false,
}


