import React from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react';


export default function ConfirmModal(props) {

  const { confirmModal = () => { },
    closeModal = () => { },
    isOpen = false,
    deleteSubject = 'Object',
    deleteObject = 'instance#1' } = props

  // const [modalOpen, setmodalOpen] = useState(isOpen)

  // // console.log('Here in COnfirmModal and this is modalOpen: ', modalOpen)

  // React.useEffect(
  //   () => { setmodalOpen(isOpen) }, [isOpen]
  // )

  return (
    <Modal
      open={isOpen}
      onClose={() => closeModal()}
      // basic
      size='mini'
      centered={true}
    >
      <Modal.Header ><Icon name='question circle' />Delete {deleteSubject}</Modal.Header>
      <Modal.Content>
        <h3>Are you certain you want to delete {deleteObject}?</h3>
      </Modal.Content>
      <Modal.Actions>
        <Button color='green' onClick={() => confirmModal()} inverted>
          <Icon name='checkmark' />Confirm
            </Button>
        <Button color='red' onClick={() => closeModal()} inverted>
          <Icon name='remove' />Cancel
            </Button>
      </Modal.Actions>
    </Modal>
  )
}
