import * as fetchActions from '../../actions/fetchActions'
// import { clientListAction, clientArrayAction, employeeArrayAction, matterArrayAction, transactionTypesArrayAction } from '../../actions/clientActions'

import { isEmptyNullUndefined } from './Misc'

import store1 from '../../store.js'



//This is a dupe of the function in clientFormServer.js, but I cannot import it here because it is outside of the src folder
function createReturnObj() {
  const returnObj = {}
  returnObj.express = true
  returnObj.validation = false
  returnObj.validationerrors = []
  returnObj.mysql = false
  returnObj.message = null
  returnObj.info = []
  returnObj.errors = []
  returnObj.status = null
  return returnObj
}

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const szenokPostAPI = async (APIURL, values, token) => {

  //First, we can console the incoming information
  // console.log('Here in api and this is the URL: ', APIURL)
  // console.log('Here in api and these are the values: ', values)
  // console.log('Here in api and this is the token length: ', token.length)

  //Now, we set the fetchstate to true (this toggles the avatar graphic on the upper left)
  // store1.dispatch(fetchActions.fetchResetState('all'))
  store1.dispatch(fetchActions.fetchStart())

  //Next, we create our ReturnObj
  const returnObj = new createReturnObj()

  //Pick a host depending on whether we are in production or not:
  const host = process.env.NODE_ENV !== 'production' ? 'http://localhost:4000/' : process.env.REACT_APP_HOSTNAME 
  //'https://so2020app.herokuapp.com/' 
  // './' // This is instead of 'http://so2020app.herokuapp.com' in case that ever changes
  // console.log('This is host: ', host)
  const url = host + APIURL
  // console.log(`This is URL: ${url}`)
  // console.log(`This is payload: ${JSON.stringify({ payload: values })}`)

  try {
    const apiresponse = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        authorization: `Bearer ${token}`,
      },
      //Note: If we are using a GET method or are not passing values in, get rid of the body altogether
      body: JSON.stringify({ payload: values })
    })

    //First, set the responseObj status based on apiresponse.status
    returnObj.status = apiresponse.status
    // console.log('The returnObj.status is: ', returnObj.status)

    //Second, we test if the apiresponse is OK.
    // console.log('apiresponse.ok is: ', apiresponse.ok)
    if (!apiresponse.ok) {
      returnObj.express = false
      returnObj.errors.push(`Response !OK: There was an issue with the request.`)
    }


    //Next, switch between a bunch of different potential error codes:
    switch (apiresponse.status) {
      case 401:
        returnObj.errors.push('Fetch 401 Error:  Unauthorized content.')
        returnObj.express = false
        break
      case 403:
        returnObj.errors.push('Fetch 403 Error:  Forbidden content.')
        returnObj.express = false
        break
      case 404:
        returnObj.errors.push(`Fetch 404 Error:  Not found error.  Cannot connect to the website (${url}) for some reason...`)
        returnObj.express = false
        break
      case 500:
        returnObj.errors.push('Fetch 500 Error:  Internal server error')
        returnObj.express = false
        break
      default:
        break
    }

    //This fetch assumes we are expecting a json response.  If not, then you would skip this and work 
    //directly with apiresponse...

    //Next, check to see if apiresponse is usable json and if so then assign it to responseObj
    // console.log('This is the express response: ', apiresponse)
    const apiresponsetext = await apiresponse.text()
    if (isJson(apiresponsetext)) {
      const jsonresponse = JSON.parse(apiresponsetext)
      // console.log('This is jsonresponse:  ', jsonresponse)

      //Push any errors in jsonresponse to responseObj.errors
      // console.log(jsonresponse.errors)
      if (jsonresponse.hasOwnProperty('errors') && jsonresponse.errors.length > 0) { returnObj.errors.push(jsonresponse.errors) }

      //Next, check if express worked, in which case assign jsonresponseJSON to returnObj
      // console.log('This is returnObj.express: ', returnObj.express)
      if (returnObj.express) {
        Object.assign(returnObj, jsonresponse)
      }


    }

    // return returnObj
  }
  catch (err) {
    console.log('In error in szenokPostAPI.js.')
    returnObj.errors.push(`In error in szenokPostAPI.js.  Fetching error at ${url}: ${err}`)
  }
  finally {

    //Add any fetcherrors into the redux store
    if(!isEmptyNullUndefined(returnObj.errors)) {
      // console.log('In szenokPostAPI and we have errors: ', returnObj.errors)
      store1.dispatch(fetchActions.fetchErrors(returnObj.errors))
    }
    if(!isEmptyNullUndefined(returnObj.info)) {
      // console.log('In szenokPostAPI and we have info: ', returnObj.info)
      store1.dispatch(fetchActions.fetchInfo(returnObj.info))
    }
    //This next one is only if it's true because the MessageBarREdux process will take care of setting it back to false.  Otherwise, all of the background fetches keep resetting it to false.
    if(returnObj.success) {store1.dispatch(fetchActions.fetchSuccessStatus(returnObj.success))}
    if(!isEmptyNullUndefined(returnObj.successmessage)) {store1.dispatch(fetchActions.fetchSuccessMessage(returnObj.successmessage))}

    //Turn off the fetchstatus
    store1.dispatch(fetchActions.fetchEnd())

    return returnObj

  }
}