import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import * as matterInfoActions from '../../actions/matterInfoActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
// import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'
// import NumberFormat from 'react-number-format'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Header, Label, Divider, Button, Segment, Dropdown, Icon } from 'semantic-ui-react';
// import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
// import _ from "lodash"
import { has, get } from 'lodash';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';
// import { isNullOrUndefined } from 'util';

import queryString from 'query-string'

const initialState = {
  visible: false,
  ExistingCParties: [],
  CParty: null,
  LawFirm: null,
  Role: '',
  // showAddCounterpartyForm: false,
}

class CounterpartyInfo extends Component {

  state = initialState

  componentDidMount() {
    this.props.fetchResetState('all')
    this.getCParties()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getCParties(), 200)
    }
    //This bit here is to just watch out if "showAddCP" ever becomes false and to re-query the DB for CParties.  In the modal, user 
    //will add a counterparty and the modal will close and, but for this, the new counterparty would not appear in the list.
    if (prevProps.matterInfo.addCPInfoObject.showAddCP !== this.props.matterInfo.addCPInfoObject.showAddCP && !this.props.matterInfo.addCPInfoObject.showAddCP) {
      setTimeout(() => this.getCParties(), 200)
    }
  }

  // //This is to get rid of the unmounted component no op (as well as that signal: this.abortController stuff below))
  // abortController = new AbortController()
  // componentWillUnmount() {
  //   this.abortController.abort()
  // }

  // showAddCounterpartyFormToggle = () => {
  //   this.setState(prevState => ({ showAddCounterpartyForm: !prevState.showAddCounterpartyForm }))
  // }


  async getCParties() {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'counterparties' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse.payload: ', fetchresponse.payload)
    this.setState({ ExistingCParties: fetchresponse.payload.map(row => [row.counterparty_id, row.counterparty]) })
  }

  CPartyDropDownArray = () => {
    var array = this.state.ExistingCParties.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `CPID${result[0]}`
      obj.text = result[1]
      obj.value = result[0]
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }

  LawFirmDropDownArray = () => {
    var array = this.props.clients.lawfirmsarray.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `LFID${result[0]}`
      obj.text = result[1]
      obj.value = result[0]
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  render() {

    const initialValues = {
      CParty: null,
      LawFirm: null,
      Role: '',
    }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300


    return (
      <div>
        <Header as='h3'>Add Counterparty Information</Header>


        <Formik

          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={CPartyInfoSchema}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              // console.log('This is the matter: ', queryString.parse(this.props.location.search))
              // this.setState({ CParty2Regex: [], CParty2RegexSpecific: '' })
              let fetchresponse = {}
              fetchresponse = await szenokPostAPI('addCounterpartyInfo', {
                CParty: values.CParty, LawFirm: values.LawFirm,
                Role: values.Role,
                Matter: queryString.parse(this.props.location.search).matterID
              }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for addCounterpartyInfo: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                // console.log('Successfully added a counterparty...')

                // //Update the counterparty store
                // reduxupdate('counterparty')

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                actions.resetForm({ ...initialValues })
                this.setState(initialState)
                this.props.getMatterInfo()
                // this.getCParties()
                // this.props.showCounterpartyInfoFormToggle(false)
                this.props.resetCPInfo(true)
                actions.setSubmitting(false)
              }
            }
            catch (error) {
              console.log('In catch in Component-CounterpartyInfo and this is the error: ', error)
              let obj = []
              let str = 'Error in /Component-CounterartyInfo ' + error.name === undefined ? error : `${error.name}: ${error.message}`
              obj.push(str)
              this.props.fetchErrors(obj)
              actions.setSubmitting(false)
            }
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
          }}
          >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment basic>
                  <Form onSubmit={handleSubmit} noValidate autoComplete='off'>

                    {/* Counterparty  */}
                    <Form.Group>

                      <Form.Field required width={6} error={(touched.CParty && isEmptyNullUndefined(values.CParty)) || has(status, 'errors.CParty')}>
                        <label><Icon name='address book outline' />Select a Counterparty</label>

                        <Dropdown
                          placeholder='Select Counterparty'
                          name="CParty"
                          width={2}
                          required
                          search
                          label='Counterparty'
                          error={(touched.CParty && (typeof get(values, 'CParty') === 'undefined' || get(values, 'CParty') === null)) ||
                            (has(status, 'errors.CParty'))}
                          closeOnBlur
                          selection
                          onBlur={() => setFieldTouched('CParty', true)}
                          options={this.CPartyDropDownArray()}
                          // value={this.state.success === true ? null : values.TType}
                          loading={this.state.success}
                          value={values.CParty}
                          onChange={(e, { name, value }) => {
                            setFieldValue(name, value)
                            //console.log(name, value)
                          }}
                        />
                        {this.errorTag(errors, touched, status, 'CParty')}
                      </Form.Field>
                      <Form.Field>
                        <label>(if counterparty is not in dropdown to right)</label>

                        <Button type='button' color='green' onClick={() => {
                          // this.props.showAddCounterpartyFormToggle(true)
                          // this.props.showCounterpartyInfoFormToggle(false)
                          this.props.setAddCP(true)
                        }}>Add a Counterparty</Button>
                      </Form.Field>
                    </Form.Group>

                    {/* Add the Role but don't care about uniqueness */}
                    <Form.Field width={5}>
                      <Form.Input
                        name="Role" type="text"
                        label='Role'
                        loading={this.state.success}
                        required
                        placeholder='Role'
                        error={(errors.Role && touched.Role) || (has(status, 'errors.Role'))}
                        onChange={async (e) => {
                          await handleChange(e);
                          this.setState({ Role: e.target.value })
                          // this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, this.state.ExistingMatters, { Matter: 'Name already used' })
                        }}
                        onBlur={handleBlur}
                        value={values.Role} />
                      {this.errorTag(errors, touched, status, 'Role')}
                    </Form.Field>

                    {/* LawFirm  */}
                    <Form.Group>

                      <Form.Field required width={6} error={(touched.LawFirm && isEmptyNullUndefined(values.LawFirm)) || has(status, 'errors.LawFirm')}>
                        <label><Icon name='balance scale' />Select a Law Firm (can select "None")</label>

                        <Dropdown
                          placeholder='Select Law Firm'
                          name="LawFirm"
                          width={2}
                          required
                          search
                          label='Law Firm'
                          error={(touched.LawFirm && (typeof get(values, 'LawFirm') === 'undefined' || get(values, 'LawFirm') === null)) ||
                            (has(status, 'errors.LawFirm'))}
                          closeOnBlur
                          selection
                          onBlur={() => setFieldTouched('LawFirm', true)}
                          options={this.LawFirmDropDownArray()}
                          // value={this.state.success === true ? null : values.TType}
                          loading={this.state.success}
                          value={values.LawFirm}
                          onChange={(e, { name, value }) => {
                            setFieldValue(name, value)
                            //console.log(name, value)
                          }}
                        />
                        {this.errorTag(errors, touched, status, 'LawFirm')}
                      </Form.Field>
                      <Form.Field>
                        <label>(if law firm is not in dropdown to right)</label>

                        <Button type='button' color='green' onClick={() => {
                          // this.props.showAddLawFirmFormToggle(true)
                          // this.props.showCounterpartyInfoFormToggle(false)
                          this.props.setAddLawFirm(true)
                        }}>Add a Law Firm</Button>
                      </Form.Field>
                    </Form.Group>
                    {this.errorTag(errors, touched, status, 'Matter')}

                    {/* {this.errorTag(errors, touched, status, 'CParty', 'Pick a CParty. ', 'Pick a CParty. ')} */}

                    <Divider />
                    <Button.Group size='large'>
                      <Button type='button' icon='cancel'
                        onClick={() => {
                          handleReset();
                          this.setState({ CParty: null, LawFirm: null, Role: '' })
                          // this.props.showCounterpartyInfoFormToggle(false)
                          this.props.setAddCPInfo(false)
                        }}
                        color='red'
                        content='Cancel'></Button>
                      <Button.Or />
                      <Button color='teal'
                        onClick={() => { this.setState({ CParty: null, LawFirm: null, Role: '' }); handleReset() }}
                        type='button'
                        disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Form'>
                      </Button>
                      <Button.Or />
                      <Button color='orange'
                        onClick={() => { setStatus({}) }}
                        type='button'
                        //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Error Notifications'>
                      </Button>
                      <Button.Or />
                      <Button type='submit'
                        // disabled={isSubmitting}
                        //Eventually use the below, the one above is just for testing
                        disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                        icon='upload'
                        loading={isSubmitting}
                        floated='right'
                        color={dirty ? 'green' : 'grey'}
                        content={'Add Counterparty Information'}></Button>
                    </Button.Group>
                    {/* <DisplayState state={this.state} />
                    <DisplayFormikState /> */}
                  </Form>
                </Segment>
              </div>
            )}
        </Formik>

      </div>
    )
  }
}


const CPartyInfoSchema = Yup.object().shape({
  CParty: Yup
    .number()
    .required()
    .typeError('Must pick a counterparty.'),
  LawFirm: Yup
    .number()
    .required()
    .typeError('Must pick a law firm (but can be "None").'),
  Role: Yup
    .string()
    .min(3, 'Role must be at least 3 characters.')
    .max(255, 'Role cannot exceed 255 characters.')
    .required('Must have a role for a counterparty.'),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions, matterInfoActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CounterpartyInfo))

CounterpartyInfo.defaultProps = {
  // showCounterpartyInfoFormToggle: () => { },
  // showAddCounterpartyFormToggle: () => { },
  // showAddLawFirmFormToggle: () => { },
}


