import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

import DeleteTType from './Component-DeleteTType'
// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'

import { isEmptyNullUndefined } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';

let initialDeleteTType = {
  TTypesExist: true,
  SubTTypesForTTypeExist: true,
}
let initialDeleteSubTType = {
  MattersForSubTTypesExist: true,
}

class TransactionTypes extends Component {

  state = {
    TransactionTypesAndSubs: [],
    selectedTType: null,
    selectedSubTType: null,
    DeleteTType: initialDeleteTType,
    DeleteSubTType: initialDeleteSubTType,
    modalOpen: false,
    Row: {},
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getTTypes()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getTTypes(), 200)
    }
  }


  getTTypes = async () => {
    // console.log('In getTTypes...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getTTypesAndSubs', { fetchstr: 'nothing' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getTTypesAndSubs: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      TransactionTypesAndSubs: fetchresponse.payload.map(item => {
        var arr = {}
        if (item.sub_transaction_type_id !== null) arr.sub_transaction_type_id = item.sub_transaction_type_id
        if (item.sub_transaction_type !== null) arr.sub_transaction_type = item.sub_transaction_type
        arr.transaction_type_id = item.transaction_type_id
        arr.transaction_type = item.transaction_type
        arr.ttype_count = item.ttype_count
        arr.sub_ttype_count = item.sub_ttype_count
        return arr
      }
      )
    })
  }

  setStateTType = (transaction_type_id, callback) => {
    // console.log('In setStateTType and this is the number: ', transaction_type_id)
    this.setState({ DeleteTType: initialDeleteTType })
    this.setState({ selectedTType: transaction_type_id })
    if(callback) callback()
  }
  setStateSubTType = (sub_transaction_type_id, callback) => {
    // console.log('In setStateSubTType and this is the number: ', sub_transaction_type_id)
    this.setState({ DeleteSubTType: initialDeleteSubTType })
    this.setState({ selectedSubTType: sub_transaction_type_id })
    if(callback) callback()
  }

  toggleSubTTypesForTTypeExists = (callback) => {
    // console.log('In setStateTType and this is the number: ', transaction_type_id)
    this.setState({ SubTTypesForTTypeExist: false })
    if(callback) callback()
  }

  DeleteTType = async () => {
    // console.log('Running DeleteTType on this TType: ', this.state.selectedTType)
    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    // console.log('This is predicatesExist: ', this.predicatesExist('sub_ttypes_predicates'))
    if (this.predicatesExist('sub_ttypes_predicates') && !isEmptyNullUndefined(this.state.selectedTType)) {

      try {
        let fetchresponse = {}
        fetchresponse = await szenokPostAPI('deleteTType', {
          fetchstr: 'deleteTType',
          IDNumber: this.state.selectedTType
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse in DeleteTTYpe(): ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {
          
          this.setState({ DeleteTType: initialDeleteTType,
            DeleteSubTType: initialDeleteSubTType,
            TransactionTypesAndSubs: [],
            selectedTType: null,
            selectedSubTType: null })
          
          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          this.getTTypes()
          
          //Update the matters store
          reduxupdate('transactionsandsubs')
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        //FIX:  I don't know if i need this always, but if I don't do this push stuff, sometimes the entire
        //string will come out as an array of characters, instead.  Need to figure out a way to check
        //if I have a proper string or not.
        let errorObj = []
        errorObj.push(error.toString())
        this.props.fetchErrors(errorObj)
      }
    }
  }
  
  DeleteSubTType = async () => {
    // console.log('Running DeleteSubTType on this SubTType: ', this.state.selectedSubTType)
    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')
    
    if (!this.predicatesExist('sub_ttypes_predicates') && !isEmptyNullUndefined(this.state.selectedSubTType)) {
      
      try {
        let fetchresponse = {}
        fetchresponse = await szenokPostAPI('deleteTType', {
          fetchstr: 'deleteSubTType',
          IDNumber: this.state.selectedSubTType
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for DeleteSubTType: ', fetchresponse)
        
        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {
          
          this.setState({ DeleteTType: initialDeleteTType,
            DeleteSubTType: initialDeleteSubTType,
            TransactionTypesAndSubs: [],
            selectedTType: null,
            selectedSubTType: null })

          //Update the matters store
          reduxupdate('transactionsandsubs')
          
          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          this.getTTypes()
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        //FIX:  I don't know if i need this always, but if I don't do this push stuff, sometimes the entire
        //string will come out as an array of characters, instead.  Need to figure out a way to check
        //if I have a proper string or not.
        let errorObj = []
        errorObj.push(error.toString())
        this.props.fetchErrors(errorObj)
      }
    }
  }

  handleDeleteTType = () => {
    // console.log('In Component-TransactionTypes, in handleDeleteTType()...')
    this.setState({ DeleteTType: { ...this.state.DeleteSubTType, MattersForSubTTypesExist: false } })
    this.DeleteTType()
  }
  handleDeleteSubTType = () => {
    // console.log('In Component-TransactionTypes, in handleDeleteSubTType()...')
    this.setState({ DeleteSubTType: { ...this.state.DeleteSubTType, MattersForSubTTypesExist: false } })
    this.DeleteSubTType()
  }

  handleCancelDelete = () => {
    this.setStateSubTType(null)
    this.setStateTType(null)
  }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }
  setRow = (value) => {
    // console.log('This is setRow row: ', value)
    if (!isEmptyNullUndefined(value)) this.setState({ Row: 
      { transaction_type_id: value.subRows.length>0 ? value.subRows[0].original.transaction_type_id : value.values.transaction_type_id, 
        transaction_type: value.subRows.length>0 ? value.subRows[0].original.transaction_type : value.values.transaction_type, 
        sub_transaction_type_id: value.subRows.length>0 ? null : value.values.sub_transaction_type_id, 
        sub_transaction_type: value.subRows.length>0 ? null : value.values.sub_transaction_type } })
    else this.setState({ Row: {} })
  }

  predicatesExist = (type) => {
    let TTypesExist = this.state.DeleteTType.TTypesExist && this.state.DeleteTType.SubTTypesForTTypeExist
    let SubTTypesExist = this.state.DeleteSubTType.MattersForSubTTypesExist
    let predicatesExist = TTypesExist || SubTTypesExist
    // console.log('Returning from predicatesExist: ', predicatesExist.toString())

    switch (type) {
      case 'sub_ttypes':
        return SubTTypesExist
      case 'sub_ttypes_predicates':
        return SubTTypesExist
      default:
        return predicatesExist
    }
  }

  render() {

    return (
      <div>
        <h1> <Icon name='building outline' size='large' circular /> Transaction Types</h1>

        {this.predicatesExist('sub_ttypes') && !isEmptyNullUndefined(this.state.selectedTType) &&
          <DeleteTType SubTTypeID={this.state.selectedSubTType} TTypeID={this.state.selectedTType}
            handleDeleteTType={this.handleDeleteTType}
            handleDeleteSubTType={this.handleDeleteSubTType}
            handleCancelDelete={this.handleCancelDelete}
            toggleSubTTypesForTTypeExists={this.toggleSubTTypesForTTypeExists}
          />
          // getCounterparties={this.getCounterparties}
          // handleDeleteCounterparty={this.handleDeleteCounterparty} handleCancelDelete={this.handleCancelDelete} />
        }
        {/* 
        {this.predicatesExist('ttypes') && !this.predicatesExist('ttypes_predicates') && !isEmptyNullUndefined(this.state.selectedTType) && isEmptyNullUndefined(this.state.selectedSubTType) &&
          <DeleteTType SubTTypeID={this.state.selectedSubTType} TTypeID={this.state.selectedTType}
            handleDeleteSubTType={this.handleDeleteSubTType}
            handleCancelDelete={this.handleCancelDelete}
          />
          // getCounterparties={this.getCounterparties}
          // handleDeleteCounterparty={this.handleDeleteCounterparty} handleCancelDelete={this.handleCancelDelete} />
        } */}

        <ReactTableMaker passedData={this.state.TransactionTypesAndSubs} passedColumns={TTypesAndSubsColumns(this.setStateTType, this.setStateSubTType, this.DeleteTType, this.DeleteSubTType, this.props.history, this.setModalOpen, this.setRow, !!this.props.login.currentObserver)}
          handleClick={(rowID, cellID, rowValues) => {
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
            // this.setState({ selectedTType: rowID })
            // this.getPaymentsForInvoice(rowID)
          }}
          includeSearchBar={false}
          groupByRow={['transaction_type']}
        />

        {/* This is the "are you sure" modal for deleting a row */}
        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              // this.setStateClient(this.state.Row.client_id)
              // this.DeleteClient()
              this.setStateTType(this.state.Row.transaction_type_id)
              this.setStateSubTType(this.state.Row.sub_transaction_type_id)
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Transaction Type'
            deleteObject={this.state.Row.transaction_type.concat(!isEmptyNullUndefined(this.state.Row.sub_transaction_type) ? ' / ' + this.state.Row.sub_transaction_type : '')}
          />}

        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionTypes))

function plusFontWeight(e) {e.target.style.fontWeight ='bold'; e.target.style.color ="#ff8000"}
function minusFontWeight(e) {e.target.style.fontWeight =''; e.target.style.color =""}

const TTypesAndSubsColumns = (setStateTType, setStateSubTType, DeleteTType, DeleteSubTType, history, setModalOpen, setRow, observer ) => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Transaction Type ID</span>,
      accessor: 'transaction_type_id',
      show: true,
      width: 0,
      disableGroupBy: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>SubTransaction Type ID</span>,
      accessor: 'sub_transaction_type_id',
      show: true,
      width: 0,
      disableGroupBy: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Transaction Type</span>,
      accessor: 'transaction_type',
      show: true,
      sortType: 'alphanumeric',
      // aggregate: ['sum', 'count'],
      // Aggregated: ({ cell: { value } }) => `${value} Names`,
      group: true,
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{'float': 'right', cursor: 'pointer'}}
          onClick={() => history.push(`/transactiontype/${cell.value}?ttype=${cell.value}&TTypeID=${row.subRows[0].original.transaction_type_id}`)}
        // onClick={() => console.log('Yo, this is the value: ', cell.value)}
        >
          {cell.value}
        </span>
    },
    // {
    //   // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
    //   accessor: 'transaction_type_id',
    //   // show: true,
    //   // sortType: 'alphanumeric',
    //   Filter: '',
    //   Cell: ({ cell }) => <span style={{ 'float': 'left' }} > <Link to={`editttype/${cell.value}`}><Icon name='edit' />Edit</Link></span>
    // },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Sub-Transaction Type</span>,
      accessor: 'sub_transaction_type',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      disableGroupBy: true,
      Aggregated: ({ cell: { value }, row }) => 
      Object.keys(row.subRows[0].original).length <= 2 ? 
      <span style={{color: 'grey'}}>No Sub-Transactions</span> :
      
      `${row.subRows.length} Sub-Transaction Types ${!row.isExpanded ?
        ': (' + row.subRows.map(item => ' ' + (isEmptyNullUndefined(item.values.sub_transaction_type) ? null : item.values.sub_transaction_type)) + ' )' : ''}`,
      // aggregate: ['sum', 'count'],
      Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },
    // {
    //   // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
    //   accessor: 'sub_transaction_type_id',
    //   // show: true,
    //   // sortType: 'alphanumeric',
    //   Filter: '',
    //   Cell: ({ cell, row }) => row.values['sub_transaction_type'] !== 'General' && <span style={{ 'float': 'left' }} > <Link to={`editsubttype/${cell.value}?TTypeID=${row.values['transaction_type_id']}`}><Icon name='edit' />Edit</Link></span>
    // },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Count</span>,
      accessor: 'sub_ttype_count',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      disableGroupBy: true,
      Cell: ({ cell, row }) => {
        // console.log(row)
        return (row.isGrouped ?
        <span style={{ 'float': 'left' }}>{row.subRows[0].original.ttype_count === 0 ? '-' : row.subRows[0].original.ttype_count}</span> : 
        <span style={{ 'float': 'left', paddingLeft: '30px' }}>-{cell.value === 0 ? '-' : cell.value}-</span>)}
    },
    // {
    //   // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
    //   accessor: 'sub_transaction_type_id',
    //   // show: true,
    //   // sortType: 'alphanumeric',
    //   Filter: '',
    //   Cell: ({ cell, row }) => row.values['sub_transaction_type'] !== 'General' && <span style={{ 'float': 'left' }} > <Link to={`editsubttype/${cell.value}?TTypeID=${row.values['transaction_type_id']}`}><Icon name='edit' />Edit</Link></span>
    // },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
      accessor: 'edit',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }}>
        {
          row.isGrouped ?
            row.isExpanded ? null :
              <Link to={`editttype/${row.subRows[0].original.transaction_type_id}?TTypeID=${row.subRows[0].original.transaction_type_id}`}><Icon name='edit' />Edit (Type)</Link> :
            <Link to={`editsubttype/${row.original.transaction_type_id}?TTypeID=${row.original.transaction_type_id}&subTTypeID=${row.original.sub_transaction_type_id}`}><Icon name='edit' />Edit (SubType)</Link>
        }
      </span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
      accessor: 'delete',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }}>
        {
          row.isGrouped ?
            row.isExpanded ? null :
              <Link to={''} onClick={(e) => {
                e.preventDefault()
                // console.log('1 Clicking on this:')
                // console.log(row.subRows[0].original.transaction_type_id)
                setModalOpen(true)
                setRow(row)


                // setStateTType(row.subRows[0].original.transaction_type_id)
                // setStateSubTType(null)
                // DeleteTType()
              }}>
                <Icon name='delete' color='red' size='small' bordered />Delete (Type)</Link> :
            <Link to={''} onClick={(e) => {
              e.preventDefault()
              // console.log('2 Clicking on this:')
              // console.log(row.original.sub_transaction_type_id)
              setModalOpen(true)
              setRow(row)

              // setStateSubTType(row.original.sub_transaction_type_id)
              // setStateTType(row.original.transaction_type_id)
              // DeleteSubTType()
            }}>
              <Icon name='delete' color='red' size='small' bordered />Delete (SubType)</Link>
        }
      </span>
    },
  ]
}

