import React, { useState } from 'react'
import { Input } from 'semantic-ui-react'

function globalFilter(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// Define a default UI for filtering
function DefaultColumnFilter({
  // state,
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length
  // console.log('In DefaultColumnFilter and this is filterValue: ', filterValue)
  return (
    // <Segment basic compact size='mini' floated={'right'}>
    <div style={{ 'float': 'right', 'padding': '0px', 'margin': '0px', 'border': '0px' }}>

      <Input
        // label='test'
        // transparent
        size='mini'
        value={filterValue || ''}
        onChange={(e, data) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          // console.log(data)
        }}
        placeholder={`Search ${count} records...`}
      />
    </div>
    // </Segment>
  )
}

// Same as DefaultColumnFilter, but left aligned
function DefaultColumnFilterLeft({
  // state,
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length
  // console.log('In DefaultColumnFilter and this is filterValue: ', filterValue)
  return (
    // <Segment basic compact size='mini' floated={'left'}>
    <div style={{ 'float': 'left', 'padding': '0px', 'margin': '0px', 'border': '0px' }}>

      <Input
        // label='test'
        // transparent
        size='mini'
        value={filterValue || ''}
        onChange={(e, data) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          // console.log(data)
        }}
        placeholder={`Search ${count} records...`}
      />
    </div>
    // </Segment>
  )
}

//Filter Out Sub-Zero Values
function SubZeroColumnFilter({
  // state,
  // row: {columnID},
  column: { id, filterValue = false, preFilteredRows, setFilter },
}) {
  // const count = preFilteredRows.length

  const [subZeroCheck, setSubZeroCheck] = useState(false)
  // console.log('In SubZeroColumnFilter and this is filterValue: ', filterValue)
  // console.log('In SubZeroColumnFilter and this is columnID: ', id)
  return (
    <React.Fragment>

      {/* <Input
        // label='test'
        // transparent
        size='mini'
        value={filterValue || ''}
        onChange={(e, data) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          // console.log(data)
        }}
        placeholder={`Search ${count} records...`}
      />
      <br /> */}
      {/* <Segment basic compact size='mini' floated={'right'} color='blue'> */}

      {/* <Checkbox
        label={'Filter out paid invoices.'}
        checked={subZeroCheck}
        onClick={() => {
          setSubZeroCheck(prevState => !prevState)
          setFilter(!subZeroCheck)
        }} />
        <br/> */}
      <div style={{ float: 'right' }}>
        <label style={{ marginRight: '10px' }} htmlFor="filterPaidCheckBox">Unpaid</label>
        <input type="checkbox"
          id='filterPaidCheckBox'
          checked={subZeroCheck}
          onChange={() => {
            setSubZeroCheck(prevState => !prevState)
            setFilter(!subZeroCheck)
          }} />
      </div>
      {/* </Segment> */}
    </React.Fragment>
  )
}

//Filter Out Sub-Zero Values
function NoFilter({
  column: { id, filterValue = false, preFilteredRows, setFilter },
}) {
  return (
    <React.Fragment>

    </React.Fragment>
  )
}

// // Define a default UI for filtering
// function zDefaultColumnFilter2(searchString,
//   setFilter
// ) {
//   // const count = preFilteredRows.length


//   return (
//     <input
//       // value={searchString || ''}
//       value={searchString}
//       onChange={e => {
//         console.log('In special filter now and this is searchString: ', searchString)
//         console.log('In special filter now and this is setFilter: ', setFilter)
//       }}
//       //   setFilter(filterValue || undefined) // Set undefined to remove the filter entirely
//       // }}
//       placeholder={searchString}
//     />
//   )
// }

function getDefaultColumn() {
  const defaultColumn = React.memo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  return defaultColumn
}




export { globalFilter, getDefaultColumn, SubZeroColumnFilter, DefaultColumnFilter, DefaultColumnFilterLeft, NoFilter }
