
export function setCurrentZoomLevel(level) {
  return {
    type: "CURRENT_ZOOM_LEVEL",
    payload: level
  }
}

export function setCurrentLatitude(latitude) {
  return {
    type: "CURRENT_LATITUDE",
    payload: latitude
  }
}

export function setCurrentLongitude(longitude) {
  return {
    type: "CURRENT_LONGITUDE",
    payload: longitude
  }
}