import React, { useEffect } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Geocode from "react-geocode"
import { isEmptyNullUndefined } from '../helper/Misc'
// import '../charts/Charts-Google-Geolocate-Bar.css'
// import '../charts/Charts-Leaflet-Map-Custom-Search.css'
import './Component-Maps-Locations-Map.css'


const GoogleGeolocateBar = ({
  GOOGLEAPI = '',
  draggedCoordinates = { lat: null, lng: null },
  sendAddressToParent = () => { },
  sendCoordinatesToParent = () => { } }) => {

  const [address, setAddress] = React.useState('')
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null
  })

  const handleSelect = async (value) => {
    // console.log('In handleSelect and this is value: ', value)
    const results = await geocodeByAddress(value)
    const latLng = await getLatLng(results[0])
    setCoordinates(latLng)
    setAddress(value)
    // console.log(latLng)
  }

  //Send address to parent
  useEffect(() => {
    if (!isEmptyNullUndefined(address)) {
      // console.log('Sending address to parent: ', address)
      sendAddressToParent(address)
    }
  }, [address, sendAddressToParent])

  //Send coordinates to parent
  useEffect(() => {
    if (!isEmptyNullUndefined(coordinates.lat)) {
      // console.log('Sending coordinates to parent: ', coordinates)
      sendCoordinatesToParent(coordinates)
    }
  }, [coordinates, sendCoordinatesToParent])

  //GeoLocate Dragged Coordinates (if any)
  useEffect(() => {
    if (!isEmptyNullUndefined(draggedCoordinates.lat)) {
      // console.log('Getting drag coordinates: ', draggedCoordinates)
      Geocode.setApiKey(GOOGLEAPI)
      Geocode.setRegion("US")
      Geocode.enableDebug()
      // Get address from latitude & longitude.
      Geocode.fromLatLng(draggedCoordinates.lat, draggedCoordinates.lng).then(
        response => {
          const geoLocatedAddress = response.results[0].formatted_address;
          // console.log('This is the address from GeoCode: ', geoLocatedAddress);
          !isEmptyNullUndefined(geoLocatedAddress) && sendAddressToParent(geoLocatedAddress)
        },
        error => {
          console.error(error);
        }
      )
    }
  }, [draggedCoordinates, GOOGLEAPI, sendAddressToParent])

  return (

    // Need to add this to the index.html to make this work: <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places"></script>
    <div className='jake-search-container2'>

      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                // placeholder: 'Search Places ...',
                onFocus: (e) => { setAddress(''); e.target.placeholder = 'Search Places...' },
                onBlur: (e) => { setAddress(''); e.target.placeholder = '' },
                className: 'jake-control-input2',
              })}
            />
            <div className="jake-autocomplete-dropdown-container2">
              {loading && <div className='jake-autocomplete-loading2'>Loading...</div>}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? 'jake-suggestion-item-active2' : 'jake-suggestion-item2'
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}

      </PlacesAutocomplete >
    </div>

  )
}

export default GoogleGeolocateBar