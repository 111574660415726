import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import ChartsAClassTTypesInvoices from './Charts-AClass-TTypes-Invoices'
import ChartsAClassTTypesAmounts from './Charts-AClass-TTypes-Amounts'
import ChartsAClassTTypesNumbers from './Charts-AClass-TTypes-Numbers'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { isEmptyNullUndefined, Loading } from '../helper/Misc'
import { toIMGcanvas } from '../helper/PDFImage'
import { YearDropDown, EmployeeDropDown, AClassTTypesGetInvoices, AClassTTypesGetMatters } from './Charts-AClass-Functions'

import { Grid, Checkbox, Segment } from 'semantic-ui-react';

// import DisplayState from '../helper/DisplayState';

class ChartsAClassTTypes extends Component {

  state = {
    TokenReady: false,
    loading: false,
    showCombined: false,
    showNumber: true,
    showDealAmount: false,
    showInvoiceTotal: false,
    accSwitch: 'showNumber',
    currentYear: new Date().getFullYear(),
    selectedYear: 0,
    selectedPartner: 0,
    fetchObject: {},
    Matters: [],
    Invoices: [],
    InvoicesByEmployee: [],
    AClasses: [],
    TTypes: [],
    AClassTTypeArray: [],
    ShowRelevant: true,
    AClassTTypeInvoicesCheck: false,
    AClassTTypeInvoicesAllTimeCheck: false,
    AClassTTypeInvoicesByEmployeeCheck: false,
    AClassTTypesGetMattersCheck: false,
  }


  async componentDidMount() {
    if (this.props.login.currentToken.length > 0) {
      this.setState({
        TokenReady: true,
      })
      // this.getMatters()
      // this.getInvoices()
      AClassTTypesGetInvoices('AClassTTypeInvoices', this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ Invoices: value, AClassTTypeInvoicesCheck: true }))
      AClassTTypesGetInvoices('AClassTTypeInvoicesAllTime', this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ InvoicesAllTime: value, AClassTTypeInvoicesAllTimeCheck: true }))
      AClassTTypesGetInvoices('AClassTTypeInvoicesByEmployee', this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ InvoicesByEmployee: value, AClassTTypeInvoicesByEmployeeCheck: true }))
      AClassTTypesGetMatters(this.props.maxYears, this.props.login.currentToken, (value) => !isEmptyNullUndefined(value) && this.setState({
        Matters: value.Matters,
        AClasses: value.AClasses,
        TTypes: value.TTypes,
        AClassTTypeArray: value.AClassTTypeArray,
        AClassTTypesGetMattersCheck: true
      }))
    }
  }

  handleCheckboxChange = (e, { value }) => {
    if (value === 'showInvoiceTotal') {
      this.setState({
        showCombined: false,
        showInvoiceTotal: true,
        showDealAmount: false,
        showNumber: false,
        accSwitch: 'showInvoiceTotal'
      })
    }
    if (value === 'showDealAmount') {
      this.setState({
        showCombined: false,
        showInvoiceTotal: false,
        showDealAmount: true,
        showNumber: false,
        accSwitch: 'showDealAmount'
      })
    }
    if (value === 'showNumber') {
      this.setState({
        showCombined: false,
        showInvoiceTotal: false,
        showDealAmount: false,
        showNumber: true,
        accSwitch: 'showNumber'
      })
    }
    if (value === 'showCombined') {
      this.setState({
        showCombined: true,
        showInvoiceTotal: false,
        showDealAmount: false,
        showNumber: false,
        accSwitch: 'showCombined'
      })
    }
  }


  render() {

    let checkboxStyle = { paddingRight: 30, paddingTop: 10, paddingBottom: 10 }
    let chartStyle = {
      background: "#ebf5fa",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      fontFamily: "'Fira Sans', sans-serif",
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderColor: '#a5a5a5',
      borderRadius: '15px',
      boxShadow: '2px 2px 8px #888888',
      cursor: 'pointer',
    }

    let setYearCallback = (value) => this.setState({ selectedYear: value })
    let setPartnerCallback = (value) => this.setState({ selectedPartner: value })
    let loadingCheck = this.state.AClassTTypeInvoicesCheck && this.state.AClassTTypeInvoicesAllTimeCheck && this.state.AClassTTypeInvoicesByEmployeeCheck && this.state.AClassTTypesGetMattersCheck

    return (

      <React.Fragment>

        {/* <DisplayState state={this.state} /> */}
        {/* <Header as='h3'>Asset and Transaction Type Chart</Header>
        <Divider /> */}

        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <YearDropDown year={this.state.selectedYear} maxYears={this.props.maxYears} includeAll={true} currentYear={this.state.currentYear} callback={setYearCallback} />
            </Grid.Column>
            <Grid.Column width={4}>
              <EmployeeDropDown selectedPartner={this.state.selectedPartner} includeAll={true} callback={setPartnerCallback} />
            </Grid.Column>
            <Grid.Column width={8} />
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={14} >
              <Segment compact color='blue'>
                <Checkbox
                  style={checkboxStyle}
                  label='Show All'
                  value='showCombined'
                  checked={this.state.showCombined}
                  onChange={this.handleCheckboxChange}
                />
                <Checkbox
                  style={checkboxStyle}
                  label='Show By Number of Deals'
                  value='showNumber'
                  checked={this.state.showNumber}
                  onChange={this.handleCheckboxChange}
                />
                <Checkbox
                  style={checkboxStyle}
                  label='Show By Deal Amounts'
                  value='showDealAmount'
                  checked={this.state.showDealAmount}
                  onChange={this.handleCheckboxChange}
                />
                <Checkbox
                  style={checkboxStyle}
                  label='Show By Invoice Amounts Sent'
                  value='showInvoiceTotal'
                  checked={this.state.showInvoiceTotal}
                  onChange={this.handleCheckboxChange}
                />
                <br />
                <Checkbox
                  // style={checkboxStyle}
                  label='Show Only Relevant Asset Classes / Transaction Types'
                  value='showNumber'
                  checked={this.state.ShowRelevant}
                  onChange={() => {
                    this.setState({ ShowRelevant: !this.state.ShowRelevant })
                  }}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={3}></Grid.Column>
          </Grid.Row>

        </Grid>

        {this.state.accSwitch === 'showNumber' &&
          <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
              <div id='Classes/Types' >
                {!loadingCheck ? <Loading /> :
                  <svg
                    style={chartStyle}
                    onClick={() => toIMGcanvas('Classes/Types')}
                    height={600 * this.props.maxViewPercentage / 100}
                    viewBox="00 00 600 325"
                  >
                    <ChartsAClassTTypesNumbers
                      selectedYear={this.state.selectedYear}
                      currentYear={this.state.currentYear}
                      selectedPartner={this.state.selectedPartner}
                      maxViewPercentage={this.props.maxViewPercentage}
                      maxYears={this.props.maxYears}
                      Matters={this.state.Matters}
                      AClasses={this.state.AClasses}
                      TTypes={this.state.TTypes}
                      AClassTTypeArray={this.state.AClassTTypeArray}
                      ShowRelevant={this.state.ShowRelevant}
                    />
                  </svg>
                }
              </div>
            </Segment>
          </div>
        }
        {this.state.accSwitch === 'showDealAmount' &&
          <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
              <div id='Classes/Types' >
                {!loadingCheck ? <Loading /> :
                  <svg
                    style={chartStyle}
                    onClick={() => toIMGcanvas('Classes/Types')}
                    height={600 * this.props.maxViewPercentage / 100}
                    viewBox="00 00 600 325"
                  >
                    <ChartsAClassTTypesAmounts
                      selectedYear={this.state.selectedYear}
                      currentYear={this.state.currentYear}
                      selectedPartner={this.state.selectedPartner}
                      maxViewPercentage={this.props.maxViewPercentage}
                      maxYears={this.props.maxYears}
                      Matters={this.state.Matters}
                      AClasses={this.state.AClasses}
                      TTypes={this.state.TTypes}
                      AClassTTypeArray={this.state.AClassTTypeArray}
                      ShowRelevant={this.state.ShowRelevant}
                    />
                  </svg>
                }
              </div>
            </Segment>
          </div>
        }
        {this.state.accSwitch === 'showInvoiceTotal' &&
          <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
              <div id='Classes/Types' >
                {!loadingCheck ? <Loading /> :
                  <svg
                    style={chartStyle}
                    onClick={() => toIMGcanvas('Classes/Types')}
                    height={600 * this.props.maxViewPercentage / 100}
                    viewBox="00 00 600 325"
                  >
                    <ChartsAClassTTypesInvoices
                      selectedYear={this.state.selectedYear}
                      currentYear={this.state.currentYear}
                      selectedPartner={this.state.selectedPartner}
                      maxViewPercentage={this.props.maxViewPercentage}
                      maxYears={this.props.maxYears}
                      Invoices={this.state.Invoices}
                      InvoicesByEmployee={this.state.InvoicesByEmployee}
                      InvoicesAllTime={this.state.InvoicesAllTime}
                      AClassTTypeArray={this.state.AClassTTypeArray}
                      AClasses={this.state.AClasses}
                      TTypes={this.state.TTypes}
                      ShowRelevant={this.state.ShowRelevant}
                    />
                  </svg>
                }
              </div>
            </Segment>
          </div>
        }
        {this.state.accSwitch === 'showCombined' &&
          <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
              <div id='Classes/Types' >
                {!loadingCheck ? <Loading /> :
                  <svg
                    style={chartStyle}
                    onClick={() => toIMGcanvas('Classes/Types')}
                    height={1730 * this.props.maxViewPercentage / 100}
                    viewBox="00 350 575 1070"
                  >
                    <g transform='translate(0 0)'>
                      <ChartsAClassTTypesNumbers
                        selectedYear={this.state.selectedYear}
                        currentYear={this.state.currentYear}
                        selectedPartner={this.state.selectedPartner}
                        maxViewPercentage={this.props.maxViewPercentage}
                        maxYears={this.props.maxYears}
                        Matters={this.state.Matters}
                        AClasses={this.state.AClasses}
                        TTypes={this.state.TTypes}
                        AClassTTypeArray={this.state.AClassTTypeArray}
                        ShowRelevant={this.state.ShowRelevant}
                      />
                    </g>
                    <g transform='translate(0 350)'>
                      <ChartsAClassTTypesAmounts
                        selectedYear={this.state.selectedYear}
                        currentYear={this.state.currentYear}
                        selectedPartner={this.state.selectedPartner}
                        maxViewPercentage={this.props.maxViewPercentage}
                        maxYears={this.props.maxYears}
                        Matters={this.state.Matters}
                        AClasses={this.state.AClasses}
                        TTypes={this.state.TTypes}
                        AClassTTypeArray={this.state.AClassTTypeArray}
                        ShowRelevant={this.state.ShowRelevant}
                      />
                    </g>
                    <g transform='translate(0 700)'>
                      <ChartsAClassTTypesInvoices
                        selectedYear={this.state.selectedYear}
                        currentYear={this.state.currentYear}
                        selectedPartner={this.state.selectedPartner}
                        maxViewPercentage={this.props.maxViewPercentage}
                        maxYears={this.props.maxYears}
                        Invoices={this.state.Invoices}
                        InvoicesByEmployee={this.state.InvoicesByEmployee}
                        InvoicesAllTime={this.state.InvoicesAllTime}
                        AClassTTypeArray={this.state.AClassTTypeArray}
                        AClasses={this.state.AClasses}
                        TTypes={this.state.TTypes}
                        ShowRelevant={this.state.ShowRelevant}
                      />
                    </g>
                  </svg>
                }
              </div>
            </Segment>
          </div>
        }


      </React.Fragment>

    )
  }
}


const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsAClassTTypes))

ChartsAClassTTypes.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  byOriginating: true,
  // handleSomething: () => { },
}