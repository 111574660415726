//This file stores all of the reducers and the key values we assign to them.

import { combineReducers } from 'redux';
import simpleReducer from './loginReducer'
import clientsReducer from './clientsReducer'
import fetchReducer from './fetchReducer';
import mapReducer from './mapReducer';
import matterInfoReducer from './matterInfoReducer';

export default combineReducers(
  {
    fetch: fetchReducer,
    login: simpleReducer,
    clients: clientsReducer,
    maps: mapReducer,
    matterInfo: matterInfoReducer,
  }
)