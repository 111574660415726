import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

import DeleteAClass from './Component-DeleteAClass'
// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'

import { isEmptyNullUndefined } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';

let initialDeleteAClass = {
  AClassesExist: true,
}

class AssetClasses extends Component {

  state = {
    AssetClasses: [],
    // selectedTType: null,
    DeleteAClass: initialDeleteAClass,
    modalOpen: false,
    Row: {},
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getAClasses()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getAClasses(), 200)
    }
  }

  getAClasses = async () => {
    // console.log('In getAClasses...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getAClasses', { fetchstr: 'nothing' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getAClasses: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      AssetClasses: fetchresponse.payload
    })
  }

  setStateAClass = (asset_class_id, callback) => {
    this.setState({ DeleteAClass: initialDeleteAClass })
    this.setState({ selectedAClass: asset_class_id }, () => {if (callback) callback()})
    // if (callback) callback()
  }

  DeleteAClass = async () => {
    // console.log('Running DeleteAClass on this AClass: ', this.state.selectedAClass)
    // console.log('This is predicatesExist: ', this.predicatesExist)
    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    if (!this.predicatesExist() && !isEmptyNullUndefined(this.state.selectedAClass)) {
      // console.log('In DeleteAClass and no predicates exist...')
      try {
        let fetchresponse = {}
        fetchresponse = await szenokPostAPI('deleteAClass', {
          fetchstr: 'deleteAClass',
          AClassID: this.state.selectedAClass
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for DeleteAClass(): ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {

          //Update the asset classess store
          reduxupdate('assetclasses')

          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          this.getAClasses()
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        //FIX:  I don't know if i need this always, but if I don't do this push stuff, sometimes the entire
        //string will come out as an array of characters, instead.  Need to figure out a way to check
        //if I have a proper string or not.
        let errorObj = []
        errorObj.push(error.toString())
        this.props.fetchErrors(errorObj)
      }
    }
  }

  handleDeleteAClass = () => {
    this.setState({ DeleteAClass: { ...this.state.DeleteAClass, AClassesExist: false } })
      this.DeleteAClass()
    
  }

  handleCancelDelete = () => {
    this.setState({ DeleteAClass: initialDeleteAClass })
    this.setState({ selectedAClass: null })
  }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }
  setRow = (value) => {
    // console.log('In setRow and this is value: ', value)
    if (!isEmptyNullUndefined(value)) this.setState({ Row: { asset_class_id: value.values.asset_class_id, asset_class: value.values.asset_class } })
    else this.setState({ Row: {} })
  }

  predicatesExist = (type) => {
    let AClassesExist = this.state.DeleteAClass.AClassesExist
    let predicatesExist = AClassesExist
    // console.log('Returning from predicatesExist: ', predicatesExist.toString())

    switch (type) {
      case 'asset_classes':
        return AClassesExist
      case 'asset_classesPredicates':
        return AClassesExist
      default:
        return predicatesExist
    }
  }

  render() {

    return (
      <div>
        <h1> <Icon name='clipboard outline' size='large' circular /> Asset Classes</h1>

        {this.predicatesExist('asset_classes') && !isEmptyNullUndefined(this.state.selectedAClass) &&
          <DeleteAClass AClassID={this.state.selectedAClass}
            handleDeleteAClass={this.handleDeleteAClass}
            handleCancelDelete={this.handleCancelDelete}
          />
        }

        <ReactTableMaker passedData={this.state.AssetClasses} passedColumns={AssetClassesColumns(this.setStateAClass, this.DeleteAClass, this.props.history, this.setModalOpen, this.state.modalOpen, this.setRow, !!this.props.login.currentObserver)}
          // handleClick={(rowID, cellID, rowValues) => {console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)}}
          includeSearchBar={false}
        />

        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              this.setStateAClass(this.state.Row.asset_class_id,
                this.DeleteAClass
              )
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Asset Class'
            deleteObject={this.state.Row.asset_class}
          />}

        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetClasses))

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
function minusFontColor(e) { e.target.style.color = "" }

let AssetClassesColumns = (setStateAClass, DeleteAClass, history, setModalOpen, modalOpen, setRow, observer) => {
  return [
    // {
    //   Header: () => <span style={{ 'float': 'left' }}>Asset Class ID</span>,
    //   accessor: 'asset_class_id',
    //   show: true,
    //   width: 0,
    //   sortType: 'alphanumeric',
    //   Filter: 'defaultColumnFilterLeft'
    // },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Asset Class</span>,
      accessor: 'asset_class',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/assetclass/${cell.value}?aclass=${cell.value}&aClassID=${row.values.asset_class_id}`)}
        // onClick={() => console.log('Yo, this is the value: ', cell.value)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Count</span>,
      accessor: 'aclass_count',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => cell.value === 0 ? '-' : cell.value
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Asset Class</span>,
      accessor: 'asset_class_id',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} >
        <span onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
          <Link to={`editassetclass/${cell.value}`}><Icon name='edit' />Edit</Link></span>
      </span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: '22asset_class_id2',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor} >
        <Link to={''} onClick={(e) => {
          e.preventDefault()
          setModalOpen(true)
          setRow(row)
        }}>
          <Icon name='delete' color='red' size='small' bordered />Delete</Link>

      </span>
    },
  ]
}