import React from 'react'
import { Segment, Icon } from 'semantic-ui-react';


export default function MapsSizer({ handleIconClick, viewPercentage }) {
  return (
    <Segment color='brown'>
      <h5>Map Size:</h5>
      <Icon name='minus' size='small' color='blue' circular onClick={handleIconClick} data-tip={`Decrease Map Size (${parseFloat(100 * viewPercentage).toFixed(2)}%)`} />
      <Icon name='plus' size='small' color='blue' circular padding={100} onClick={handleIconClick} data-tip={`Increase Map Size (${parseFloat(100 * viewPercentage).toFixed(2)}%)`} />
      <Icon name='redo' size='small' color='green' circular padding={100} onClick={handleIconClick} data-tip={`Reset Map Size`} />
    </Segment>
  )
}
