import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { isEmptyNullUndefined, RGB_Linear_Shade } from '../helper/Misc'
// import DisplayState from '../helper/DisplayState';

import { VictoryAxis, VictoryTooltip, VictoryScatter, VictoryLabel } from 'victory'

const redColor = "#c43a31";
class ChartsAClassTTypesInvoices extends Component {

  state = {
    ReducedInvoices: [],
    ReducedInvoiceAClasses: [],
    ReducedInvoiceTTypes: [],
    TotalCount: 0,
  }

  async componentDidMount() {
    this.getInvoices()
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedYear !== this.props.selectedYear) {
      this.reduceInvoices()
    }
    if (prevProps.selectedPartner !== this.props.selectedPartner) {
      this.reduceInvoices()
    }
    if (prevProps.ShowRelevant !== this.props.ShowRelevant) {
      this.reduceInvoices()
    }
    if (prevProps.Invoices !== this.props.Invoices || prevProps.InvoicesAllTime !== this.props.InvoicesAllTime || prevProps.InvoicesByEmployee !== this.props.InvoicesByEmployee) {
      this.reduceInvoices()
    }
  }

  getInvoices = async () => {
    this.setState({
      loading: false
    })
    this.reduceInvoices()
  }

  //This creates the count for each type of AClass/TType
  reduceInvoices = () => {
    // console.log('Running reduceInvoices...')
    let reducedInvoices = []

    // All Years + All Partners
    if (this.props.selectedPartner === 0 && this.props.selectedYear === 0) reducedInvoices = this.props.InvoicesAllTime


    // One Year + All Partners
    if (this.props.selectedPartner === 0 && this.props.selectedYear !== 0) reducedInvoices = this.props.Invoices.reduce((acc, item) => {
      if (item.year === this.props.selectedYear)
        acc.push(item)
      return acc
    }, [])


    // All Years + One Partner
    if (this.props.selectedPartner !== 0 && this.props.selectedYear === 0) {
      reducedInvoices = this.props.InvoicesByEmployee.reduce((acc, item) => {
        if (item.employee_id === this.props.selectedPartner)
          acc.push(item)
        return acc
      }, [])
      reducedInvoices = this.groupByKeys(reducedInvoices)
    }


    // One Year + One Partner
    if (this.props.selectedPartner !== 0 && this.props.selectedYear !== 0) {

      reducedInvoices = this.props.InvoicesByEmployee
        .reduce((acc, item) => {
          if (item.employee_id === this.props.selectedPartner)
            acc.push(item)
          return acc
        }, [])
        .reduce((acc, item) => {
          if (item.year === this.props.selectedYear)
            acc.push(item)
          return acc
        }, [])

      reducedInvoices = this.groupByKeys(reducedInvoices)
    }

    // console.log(this.createTotalArray(reducedInvoices))
    if (!this.props.ShowRelevant) reducedInvoices = this.createTotalArray(reducedInvoices)
    // console.log(reducedInvoices)
    if (!isEmptyNullUndefined(reducedInvoices)) reducedInvoices = reducedInvoices.map(item => {
      let obj = {}
      obj.count = item.total_invoices
      obj.x = item.asset_class
      obj.y = item.ttype
      obj.year = item.year
      return obj
    })
    else reducedInvoices = []
    this.setState({
      ReducedInvoices: reducedInvoices,
      ReducedInvoiceAClasses: Array.from(new Set(reducedInvoices.map(item => item.x))),
      ReducedInvoiceTTypes: Array.from(new Set(reducedInvoices.map(item => item.y))),
      // ReducedInvoiceAClasses: Array.from(new Set(this.props.Invoices.map(item => item.asset_class))),
      // ReducedInvoiceTTypes: Array.from(new Set(this.props.Invoices.map(item => item.ttype))),
      TotalCount: reducedInvoices.reduce((acc, item) => {
        acc += item.count
        return acc
      }, 0)
    })
  }

  groupByKeys = (array) => {
    // console.log('In groupByKeys with this array: ', array)
    let returnArray = array.reduce((accumulator, cur) => {
      // //Get the amount of the current item
      // let total = cur.total_invoices

      //See whether there is already a same aclass/ttype in the accumulator
      let found = accumulator.find((item) => (item.asset_class === cur.asset_class) && (item.ttype === cur.ttype));

      //If we find a duplicate date, then add the current.total_invoices to the found.total_invoices
      if (found) {
        // console.log('Found this: ', found)
        found.total_invoices += cur.total_invoices
      }

      //Otherwise, since it's not already in the accumulator, then push this unique current into accumulator
      else accumulator.push(cur);
      return accumulator;
    }, [])
    return returnArray
  }

  createTotalArray = (array) => {
    // console.log('In createTotalArray with this array: ', array)
    let returnArray = this.props.AClassTTypeArray.map(totalItem => {
      let obj = {}
      let found = array.find((item) => (item.asset_class === totalItem.AClass && item.ttype === totalItem.TType))
      if (found) obj = found
      else obj = { total_invoices: 0, asset_class: totalItem.AClass, ttype: totalItem.TType }
      return obj
    })
    // console.log('This is createTotalArray: ', returnArray)
    return returnArray
  }



  render() {

    return (

      <svg
      // height={600 * this.props.maxViewPercentage / 100} 
      viewBox="-100 -25 600 325">
      
      >
        <VictoryLabel x='40%' y={10} style={{
          textAnchor: "middle",
          verticalAnchor: "end",
          fill: "#000000",
          fontFamily: "inherit",
          fontSize: "12px",
          fontWeight: "bold"
        }}
          text={`Invoices by Asset Class / Transaction Type` + (this.props.selectedYear === 0 ? ' (All Years)' : ` (for ${this.props.selectedYear})`)
            + (this.props.selectedPartner === 0 ? ' (All Partners)' : ` (for ${this.props.clients.employeesarray.find(employee => employee[0] === this.props.selectedPartner)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === this.props.selectedPartner)[2]})`)}
        />

        {isEmptyNullUndefined(this.state.ReducedInvoices) &&
          <VictoryLabel
            text={`(No Matters in ${this.props.selectedYear})`}
            style={{
              fill: '#404040',
              fontFamily: "inherit",
              fontSize: 8,
              // fontStyle: "italic"
            }}
            x={200} y={45}
            textAnchor="middle"
          />}

        <VictoryLabel x={-50} y={275}
          style={{
            // fill: '#b7b7b7',
            fontFamily: "inherit",
            fontSize: 8,
          }}
          text={`Total Value of Invoices:\n${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.TotalCount)}`}
        />

        {/* X Axis */}
        {!isEmptyNullUndefined(this.state.ReducedInvoiceAClasses) &&
          < VictoryAxis
            standalone={false}
            domainPadding={{ x: [50, 0], y: [50, 0] }}
            tickValues={this.state.ReducedInvoiceAClasses}
            style={{
              // axis: { stroke: "#756f6a" },
              // axisLabel: {fontSize: 20, padding: 30},
              grid: { stroke: ({ tick }) => tick > 0.5 ? "6d65a0" : "grey" },
              ticks: { stroke: "grey", size: 2 },
              tickLabels: { fontSize: 9, padding: 8, angle: -20 }
            }}
          />
        }


        {/* Y Axis */}
        {!isEmptyNullUndefined(this.state.ReducedInvoiceTTypes) &&
          <VictoryAxis
            dependentAxis
            standalone={false}
            domainPadding={{ x: [50, 0], y: [50, 0] }}
            tickValues={this.state.ReducedInvoiceTTypes}
            style={{
              // axis: { stroke: "#756f6a" },
              // axisLabel: {fontSize: 20, padding: 30},
              grid: { stroke: 'black' },
              ticks: { stroke: "grey", size: 2 },
              tickLabels: { fontSize: 9, paddingRight: 150 }
            }}
          />
        }


        {/* Dots */}
        {!isEmptyNullUndefined(this.state.ReducedInvoices) &&

          <VictoryScatter
            standalone={false}
            renderInPortal={false}
            domainPadding={{ x: [50, 0], y: [50, 0] }}
            style={{
              data: {
                fill: ({ datum }) =>
                  datum.count / this.state.TotalCount > .20 ? RGB_Linear_Shade(-.8, redColor) :
                    datum.count / this.state.TotalCount > .15 ? RGB_Linear_Shade(-.6, redColor) :
                      datum.count / this.state.TotalCount > .10 ? RGB_Linear_Shade(-.4, redColor) :
                        datum.count / this.state.TotalCount > .05 ? RGB_Linear_Shade(-.2, redColor) :
                          datum.count / this.state.TotalCount === 0 ? '#ffffff' : redColor,

                stroke: "#000000",
                strokeWidth: ({ datum }) =>
                  datum.count / this.state.TotalCount > .20 ? 4 :
                    datum.count / this.state.TotalCount > .15 ? 3 :
                      datum.count / this.state.TotalCount > .10 ? 2 :
                        datum.count / this.state.TotalCount > .05 ? 1 :
                          datum.count / this.state.TotalCount === 0 ? 0 : 1,
                opacity: ({ datum }) => datum.count === 0 ? 0 : 1
              }
            }}
            // size={({ datum }) => datum.count  === 0 ? 0 : 75*(datum.count / this.state.TotalCount)}
            bubbleProperty="count"
            maxBubbleSize={25}
            minBubbleSize={0}
            data={this.state.ReducedInvoices}
            labels={({ datum }) => datum.x + ' / ' + datum.y + ' Total Invoices:\n' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(datum.count)}
            labelComponent={< VictoryTooltip
              constrainToVisibleArea
              renderInPortal={false}
              style={{ fill: 'black', fontSize: 8 }}
              flyoutStyle={{
                stroke: "tomato",
                strokeWidth: 1,
                fill: "#ffffe3"
              }}
            />}
          />
        }
      </svg>

    )
  }
}



const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsAClassTTypesInvoices))

ChartsAClassTTypesInvoices.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  currentYear: new Date().getFullYear(),
  selectedYear: 0,
  selectedPartner: 0,
  Invoices: [],
  InvoicesAllTime: [],
  InvoicesByEmployee: [],
  AClassTTypeArray: [],
  AClasses: [],
  TTypes: [],
  ShowRelevant: true
  // handleSomething: () => { },
}