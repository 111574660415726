import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { isEmptyNullUndefined, Loading } from '../helper/Misc'
import { toIMGcanvas } from '../helper/PDFImage'
import ChartsMajorClientsSVG from './Charts-Major-Clients-SVG'
import ChartsStackedAreaFiscalYearsSVG from './Charts-Stacked-Fiscal-Years-SVG'
import ChartsLineIndividualSVG from './Charts-Line-Individual-SVG'
import ChartsPieSVG from './Charts-Pie-SVG'
import ChartsAClassTTypesInvoices from './Charts-AClass-TTypes-Invoices'
import ChartsAClassTTypesAmounts from './Charts-AClass-TTypes-Amounts'
import ChartsAClassTTypesNumbers from './Charts-AClass-TTypes-Numbers'
// import ChartsTest from './Charts-Test-SVG'
import { YearDropDown, EmployeeDropDown, MajorClientsGet, LineIndividualGetBilling, PieGetPartnerGroupBilling, AClassTTypesGetInvoices, AClassTTypesGetMatters } from './Charts-AClass-Functions'

import { Grid, Segment, Checkbox } from 'semantic-ui-react';

// import DisplayState from '../helper/DisplayState';

class ChartsCombined extends Component {

  state = {
    TokenReady: false,
    loading: true,
    PartnerBilling: null,
    currentYear: new Date().getFullYear(),
    selectedYear: 0,
    selectedPartner: 0,
    MajorClients: [],
    fetchObject: [],
    fetchObjectPie: [],
    Matters: [],
    Invoices: [],
    showbars: false,
    showbarsscatter: false,
    showpriortotal: true,
    showcumline: true,
    showcumscatter: false,
    showByInvoicingPartner: !this.props.byOriginating,
    pictureDelay: false,
    initialMaxViewPercentage: 1,
    MajorClientsCheck: false,
    LineIndividualGetBillingCheck: false,
    PieGetPartnerGroupBillingCheck: false,
    AClassTTypeInvoicesCheck: false,
    AClassTTypeInvoicesAllTimeCheck: false,
    AClassTTypeInvoicesByEmployeeCheck: false,
    AClassTTypesGetMattersCheck: false,
    isMounted: false,
  }


  async componentDidMount() {
    this.setState({ isMounted: true })
    if (this.props.login.currentToken.length > 0) {
      this.setState({
        TokenReady: true,
        initialMaxViewPercentage: this.props.maxViewPercentage,
        isMounted: true
      })
      MajorClientsGet(this.props.maxYears, this.props.login.currentToken, (value) => this.state.isMounted && this.setState({ MajorClientsByInvoicing: value[0], MajorClientsByOriginating: value[1], MajorClientsCheck: true }))
      LineIndividualGetBilling(this.props.maxYears, this.props.login.currentToken, (value) => this.state.isMounted && this.setState({ fetchObject: value, LineIndividualGetBillingCheck: true }))
      PieGetPartnerGroupBilling(this.props.maxYears, this.props.login.currentToken, (value) => this.state.isMounted && this.setState({ fetchObjectByInvoicing: value[0], fetchObjectByOriginating: value[1], PieGetPartnerGroupBillingCheck: true }))
      AClassTTypesGetInvoices('AClassTTypeInvoices', this.props.maxYears, this.props.login.currentToken, (value) => this.state.isMounted && this.setState({ Invoices: value, AClassTTypeInvoicesCheck: true }))
      AClassTTypesGetInvoices('AClassTTypeInvoicesAllTime', this.props.maxYears, this.props.login.currentToken, (value) => this.state.isMounted && this.setState({ InvoicesAllTime: value, AClassTTypeInvoicesAllTimeCheck: true }))
      AClassTTypesGetInvoices('AClassTTypeInvoicesByEmployee', this.props.maxYears, this.props.login.currentToken, (value) => this.state.isMounted && this.setState({ InvoicesByEmployee: value, AClassTTypeInvoicesByEmployeeCheck: true }))
      AClassTTypesGetMatters(this.props.maxYears, this.props.login.currentToken, (value) => !isEmptyNullUndefined(value) && this.state.isMounted && this.setState({
        Matters: value.Matters,
        AClasses: value.AClasses,
        TTypes: value.TTypes,
        AClassTTypeArray: value.AClassTTypeArray,
        AClassTTypesGetMattersCheck: true
      }))
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.loading) {
      this.state.isMounted && this.setState({ TokenReady: true })
    }
    if (prevProps.login.currentToken !== this.props.login.currentToken) {
      // MajorClientsGet(this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ MajorClients: value, MajorClientsCheck: true }))
      // LineIndividualGetBilling(this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ fetchObject: value, LineIndividualGetBillingCheck: true }))
      // PieGetPartnerGroupBilling(this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ fetchObjectByInvoicing: value[0], fetchObjectByOriginating: value[1], PieGetPartnerGroupBillingCheck: true }))
      // AClassTTypesGetInvoices('AClassTTypeInvoices', this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ Invoices: value, AClassTTypeInvoicesCheck: true }))
      // AClassTTypesGetInvoices('AClassTTypeInvoicesAllTime', this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ InvoicesAllTime: value, AClassTTypeInvoicesAllTimeCheck: true }))
      // AClassTTypesGetInvoices('AClassTTypeInvoicesByEmployee', this.props.maxYears, this.props.login.currentToken, (value) => this.setState({ InvoicesByEmployee: value, AClassTTypeInvoicesByEmployeeCheck: true }))
      // AClassTTypesGetMatters(this.props.maxYears, this.props.login.currentToken, (value) => !isEmptyNullUndefined(value) && this.setState({
      //   Matters: value.Matters,
      //   AClasses: value.AClasses,
      //   TTypes: value.TTypes,
      //   AClassTTypeArray: value.AClassTTypeArray,
      //   AClassTTypesGetMattersCheck: true
      // }))
    }
  }

  componentWillUnmount() {
    this.setState({ isMounted: false })
  }

  render() {

    let chartStyle = {
      background: "#ebf5fa",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      fontFamily: "'Fira Sans', sans-serif",
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderColor: '#a5a5a5',
      borderRadius: '15px',
      boxShadow: '2px 2px 8px #888888',
      cursor: 'pointer',
    }

    let scaler = this.props.maxViewPercentage / this.state.initialMaxViewPercentage
    let divWidth = 10 + 1400 * this.state.initialMaxViewPercentage / 100
    // let divHeight = 10 + 800 * this.state.initialMaxViewPercentage / 100

    let loadingCheck = this.state.MajorClientsCheck && this.state.LineIndividualGetBillingCheck && this.state.PieGetPartnerGroupBillingCheck && this.state.AClassTTypeInvoicesCheck && this.state.AClassTTypeInvoicesAllTimeCheck && this.state.AClassTTypeInvoicesByEmployeeCheck && this.state.AClassTTypesGetMattersCheck

    return (

      <React.Fragment>

        {/* <DisplayState state={this.state} /> */}
        {/* <Header as='h3'>Combined Chart</Header>
        <Divider /> */}
        {/* Scaler is: {scaler} */}
        <Grid>
          <Grid.Row verticalAlign='bottom'>
            <Grid.Column width={4}>
              <YearDropDown year={this.state.selectedYear} maxYears={this.props.maxYears} includeAll={true} currentYear={this.state.currentYear}
                callback={(value) => this.state.isMounted && this.setState({ selectedYear: value, pictureDelay: true })} />
            </Grid.Column>
            <Grid.Column width={4}>
              <EmployeeDropDown selectedPartner={this.state.selectedPartner} includeAll={true} callback={(value) => this.state.isMounted && this.setState({ selectedPartner: value })} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment compact color='blue' size='tiny'>
                <Checkbox
                  toggle
                  // style={checkboxStyle}
                  label={this.state.showByInvoicingPartner ? 'By Invoicing Partner' : 'By Originating Partner'}
                  // value={this.state}
                  checked={this.state.showByInvoicingPartner}
                  onChange={() => this.state.isMounted && this.setState((prevState) => ({ showByInvoicingPartner: !prevState.showByInvoicingPartner }))}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {!loadingCheck ?
          <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
            <div id='Clients'
              style={{
                overflowY: 'hidden',
                overflowX: 'auto',
                width: divWidth,
                // height: divHeight,
              }}>
              <Loading />
            </div>
          </Segment> :
          //The outer div is just to add a scroll bar if the svg gets too big
          <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
              <div id='Clients'>

                <svg
                  style={chartStyle}
                  onClick={() => toIMGcanvas('Clients')}
                  height={600 * this.props.maxViewPercentage / 100}
                  width={1400 * this.props.maxViewPercentage / 100}
                  overflow="scroll"
                // viewBox="0 0 1550 825"
                >

                  <svg
                    width={600 * scaler} height={600 * scaler}
                    viewBox="0 0 600 325"
                    x={0} y={-150 * scaler} >
                    {/* <rect x="0" y="0" width="5000" height="5000" style={{ fill: 'green', stroke: 'blue', strokeWidth: 5 }} /> */}
                    <ChartsMajorClientsSVG
                      selectedYear={this.state.selectedYear}
                      currentYear={this.state.currentYear}
                      selectedPartner={this.state.selectedPartner}
                      maxViewPercentage={this.props.maxViewPercentage}
                      maxYears={this.props.maxYears}
                      MajorClients={this.state.showByInvoicingPartner ? this.state.MajorClientsByInvoicing : this.state.MajorClientsByOriginating}
                      byInvoicingEmployee={this.state.showByInvoicingPartner}
                      isMounted={this.state.isMounted}
                    />
                  </svg>
                  {this.state.selectedPartner === 0 &&
                    <React.Fragment>
                      <svg width={600 * scaler} height={600 * scaler}
                        viewBox="0 0 600 325"
                        x={430 * scaler} y={-150 * scaler} >
                        {/* <rect x="0" y="0" width="5000" height="5000" style={{ fill: 'green', stroke: 'blue', strokeWidth: 5 }} /> */}
                        <ChartsStackedAreaFiscalYearsSVG
                          selectedYear={this.state.selectedYear}
                          currentYear={this.state.currentYear}
                          selectedPartner={this.state.selectedPartner}
                          maxViewPercentage={this.props.maxViewPercentage}
                          maxYears={this.props.maxYears}
                          fetchObject={this.state.fetchObject}
                          byInvoicingEmployee={this.state.showByInvoicingPartner}
                        />
                      </svg>
                      <svg width={600 * scaler} height={600 * scaler}
                        viewBox="0 0 600 325"
                        x={875 * scaler} y={-150 * scaler} >
                        <ChartsPieSVG
                          selectedYear={this.state.selectedYear}
                          currentYear={this.state.currentYear}
                          selectedPartner={this.state.selectedPartner}
                          maxViewPercentage={this.props.maxViewPercentage}
                          maxYears={this.props.maxYears}
                          fetchObject={this.state.showByInvoicingPartner ? this.state.fetchObjectByInvoicing : this.state.fetchObjectByOriginating}
                          byInvoicingEmployee={this.state.showByInvoicingPartner}
                        />
                      </svg>
                    </React.Fragment>
                  }
                  {this.state.selectedPartner !== 0 &&
                    <svg
                      width={600 * scaler} height={600 * scaler}
                      viewBox="0 0 600 325"
                      x={430 * scaler} y={-150 * scaler} >
                      <ChartsLineIndividualSVG
                        selectedYear={this.state.selectedYear}
                        currentYear={this.state.currentYear}
                        selectedPartner={this.state.selectedPartner}
                        maxViewPercentage={this.props.maxViewPercentage}
                        maxYears={this.props.maxYears}
                        fetchObject={this.state.fetchObject}
                        showbars={true}
                        showbarsscatter={false}
                        showpriortotal={false}
                        showcumline={true}
                        showcumscatter={false}
                        byInvoicingEmployee={this.state.showByInvoicingPartner}
                      />
                    </svg>
                  }
                  <svg
                    width={500 * scaler} height={500 * scaler}
                    viewBox="0 0 600 325"
                    x={0} y={200 * scaler} >
                    <ChartsAClassTTypesNumbers
                      selectedYear={this.state.selectedYear}
                      currentYear={this.state.currentYear}
                      selectedPartner={this.state.selectedPartner}
                      maxViewPercentage={this.props.maxViewPercentage}
                      maxYears={this.props.maxYears}
                      Matters={this.state.Matters}
                      AClasses={this.state.AClasses}
                      TTypes={this.state.TTypes}
                      AClassTTypeArray={this.state.AClassTTypeArray}
                      ShowRelevant={this.state.ShowRelevant}
                    />
                  </svg>
                  <svg
                    width={500 * scaler} height={500 * scaler}
                    viewBox="0 0 600 325"
                    x={430 * scaler} y={200 * scaler} >
                    <ChartsAClassTTypesAmounts
                      selectedYear={this.state.selectedYear}
                      currentYear={this.state.currentYear}
                      selectedPartner={this.state.selectedPartner}
                      maxViewPercentage={this.props.maxViewPercentage}
                      maxYears={this.props.maxYears}
                      Matters={this.state.Matters}
                      AClasses={this.state.AClasses}
                      TTypes={this.state.TTypes}
                      AClassTTypeArray={this.state.AClassTTypeArray}
                      ShowRelevant={this.state.ShowRelevant}
                    />
                  </svg>
                  <svg
                    width={500 * scaler} height={500 * scaler}
                    viewBox="0 0 600 325"
                    x={875 * scaler} y={200 * scaler} >
                    <ChartsAClassTTypesInvoices
                      selectedYear={this.state.selectedYear}
                      currentYear={this.state.currentYear}
                      selectedPartner={this.state.selectedPartner}
                      maxViewPercentage={this.props.maxViewPercentage}
                      maxYears={this.props.maxYears}
                      Invoices={this.state.Invoices}
                      InvoicesByEmployee={this.state.InvoicesByEmployee}
                      InvoicesAllTime={this.state.InvoicesAllTime}
                      AClassTTypeArray={this.state.AClassTTypeArray}
                      AClasses={this.state.AClasses}
                      TTypes={this.state.TTypes}
                      ShowRelevant={this.state.ShowRelevant}
                    />
                  </svg>
                </svg>
              </div>
            </Segment>
          </div>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsCombined))


ChartsCombined.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  byOriginating: true,
  // handleSomething: () => { },
}