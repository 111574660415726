import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { isEmptyNullUndefined, RGB_Linear_Shade } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';

import { VictoryAxis, VictoryLabel, VictoryPie } from 'victory'

import colorScheme from './colorScheme'


class ChartsPieSVG extends Component {

  state = {
    TokenReady: false,
    loading: true,
    currentYear: null,
    selectedYear: null,
    TotalInvoices: 0,
    employees: [],
  }


  async componentDidMount() {
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      this.setState({ currentYear: new Date().getFullYear(), selectedYear: new Date().getFullYear() })
      this.processPartnerBilling()
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.loading) {
      this.setState({ TokenReady: true })
    }
    if (prevState.TokenReady !== this.state.TokenReady) {
      this.processPartnerBilling()
    }
    if (prevProps.fetchObject !== this.props.fetchObject || prevProps.selectedYear !== this.props.selectedYear || prevProps.selectedPartner !== this.props.selectedPartner) {
      this.processPartnerBilling()
    }
    if (prevState.employees !== this.state.employees) {
      this.setState({ dataEmployees: this.state.employees.map(item => {
          return {
            x: item.employee,
            y: item.amount,
            percent: item.amount / this.state.TotalInvoices,
            id: item.employee_id,
            color: item.color
          }})})
    }
  }

  processPartnerBilling = async () => {
    // console.log('Launching processPartnerBilling...')
    let farray = []
    if (!isEmptyNullUndefined(this.props.fetchObject)) {
      farray = JSON.parse(JSON.stringify(this.props.fetchObject))
      if (this.props.selectedYear === 0) farray = this.combineEmployees(farray)
      let reducedEmployees = farray.reduce((acc, item) => {
        if ((item.year === this.props.selectedYear) || this.props.selectedYear === 0) {
          let obj = {}
          obj.employee = item.employee
          obj.employee_id = item['employee_id']
          obj.invoicing_employee_id = item['invoicing_employee_id']
          obj.amount = item.amount
          acc.push(obj)
        }
        return acc
      }, []
      )
      reducedEmployees = reducedEmployees.map(item => {
        item.color = colorScheme[item.employee_id - 1]
        return item
      })
      let total = reducedEmployees.reduce((acc, result) => {
        return acc + result.amount
      }, 0)

      this.setState({
        employees: reducedEmployees,
        TotalInvoices: total,
        loading: false
      })
    }
  }


  combineEmployees = (clients) => {
    // console.log('This is clients: ', clients)
    let returnArray = clients.reduce((accumulator, cur) => {
      //See whether there is already a same client in the accumulator
      let found = accumulator.find((item) => item.employee === cur.employee);
      //If we find a duplicate date, then add the current.amount to the found.amount
      if (found) {
        // console.log('Found this: ', found)
        found.amount += cur.amount
      }
      //Otherwise, since it's not already in the accumulator, then push this unique current into accumulator
      else accumulator.push(cur);
      return accumulator;
    }, [])
    // console.log('This is combineEmployees: ', returnArray)
    return returnArray
  }

  render() {

    return (

      <svg
        viewBox="-25 -25 600 350">

        <g width="0" height="0">
          <defs >
            {colorScheme.map((color, index) =>
              <linearGradient key={`Gradient${index}`} id={`gradient${index}`}
                x1="0%" y1="0%" x2="00%" y2="100%"
              >
                <stop offset="0%" stopColor={color} />
                <stop offset="50%" stopColor={color} />
                <stop offset="80%" stopColor={RGB_Linear_Shade(.7, color)} />
                {/* <stop offset="90%" stopColor={'#ffffff'} /> */}
              </linearGradient>
            )}
          </defs>
        </g>

        <VictoryLabel x='40%' y={20} style={{
          textAnchor: "middle",
          verticalAnchor: "end",
          fill: "#000000",
          fontFamily: "inherit",
          fontSize: "12px",
          fontWeight: "bold"
        }}
          text={`Invoice Percentage by ${this.props.byInvoicingEmployee ? 'Invoicing' : 'Originating'} Partner (${this.props.selectedYear !== 0 ? this.props.selectedYear : 'All Years'})`}
        />

        {this.state.TotalInvoices <= 0 &&
          <VictoryLabel
            text={`(No Invoices Billed Yet in ${this.props.selectedYear})`}
            style={{
              fill: '#404040',
              fontFamily: "inherit",
              fontSize: 12,
              // fontStyle: "italic"
            }}
            x={200} y={35}
            textAnchor="middle"
          />}

        <circle cx={225} cy={175} r={40} stroke="black" strokeWidth="1" fill="#000000" />

        <VictoryLabel
          textAnchor="middle"
          x={225} y={175}
          style={{
            fill: '#ffffff',
            fontFamily: "inherit",
            fontSize: 10,
            // fontStyle: "italic"
          }}
          text={'Total:\n' + this.state.TotalInvoices.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        />


        <VictoryAxis
          tickFormat={x => ''}
          style={{ axis: { stroke: "none" } }}
        />

        {!isEmptyNullUndefined(this.state.dataEmployees) &&
          <VictoryPie
            standalone={false}
            animate={{
              duration: 500,
              onLoad: { duration: 10 }
            }}
            origin={{ x: 225, y: 175 }}
            width={380} height={380}
            data={this.state.dataEmployees}
            sortKey ={'id'}
            innerRadius={50}
            labelRadius={({ radius }) => radius - 40}
            padAngle={({ datum }) => datum.percent * 5}
            labels={({ datum }) => `${datum.x}\n${datum.y.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}\n(${Number(datum.percent).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })})`}
            style={{
              labels: { fontSize: 12 },
              data: {
                fillOpacity: 1, stroke: "#c0c0c0", strokeWidth: 1,
                fill: (datum) => { return datum.datum.color }
              }
            }}
          // animate={{ duration: 1000 }}
          // colorScale={colorScheme}
          />
        }
        {/* </VictoryChart> */}
      </svg>



    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsPieSVG))


ChartsPieSVG.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  currentYear: new Date().getFullYear(),
  selectedYear: new Date().getFullYear(),
  selectedPartner: 1,
  fetchObject: [],
  byInvoicingEmployee: true,
  // handleSomething: () => { },
}