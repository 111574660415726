import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import ReactTableMaker from '../helper/ReactTableMaker'

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, ListMakerSimple } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'
import NumberFormat from 'react-number-format'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Grid, Form, Header, Label, Divider, Button, Segment, List } from 'semantic-ui-react';
import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';
// import { isNullOrUndefined } from 'util';

const initialState = {
  visible: false,
  ExistingMatters: [],
  SelectedClient: null,
  SelectedClientName: '',
  SelectedMatter: null,
  LastUsedInvoiceNumber: '',
  Matter: '',
  Payment2Regex: [],
  Payment2RegexSpecific: '',
  MatterArray: [],
  Description: null,
  InvoicesOutstanding: [],
  SelectedInvoiceID: null,
  SelectedInvoiceDate: null,
  PaymentsForInvoice: null,
  initialValues: {},

}

class AddPayment extends Component {

  state = initialState

  async componentDidMount() {
    this.props.fetchResetState('all')

    this.getInvoicesOutstanding()

    if (this.props.update) {
      await this.getSpecificPayment()
      this.setState({SelectedInvoiceID: this.state.initialValues.invoice_id})
      this.getPaymentsForInvoice(this.state.initialValues.invoice_id)
    }
  }


  getSpecificPayment = async () => {
    // console.log('Running getSpecificPayment....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('paymentInfo', { fetchstr: 'getPayment', paymentID: this.props.match.params.paymentID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getPayment: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { ...fetchresponse.payload },
      SelectedInvoiceDate: fetchresponse.payload.invoice_date
    })
  }

  getInvoicesOutstanding = async () => {

    this.props.fetchResetState('all')
    // console.log('Fetching for this client: ', client_id)
    let fetchresponse = await szenokPostAPI('invoiceInfo', { fetchstr: 'getInvoicesOutstanding' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getInvoicesOutstanding: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    this.setState({
      InvoicesOutstanding: fetchresponse.payload
    })
  }

  getPaymentsForInvoice = async (sentInvoiceID) => {

    this.props.fetchResetState('all')
    const invoiceID = isEmptyNullUndefined(this.state.SelectedInvoiceID) ? sentInvoiceID : this.state.SelectedInvoiceID
    // console.log('Sending this invoiceID: ', this.state.SelectedInvoiceID)
    let fetchresponse = await szenokPostAPI('getInvoicePayments', { fetchstr: 'getPaymentsForInvoice', invoiceID: invoiceID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getPaymentsForInvoice: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    this.setState({
      PaymentsForInvoice: fetchresponse.payload.map(payment => {
        var arr = {}
        arr.payment_id = payment.payment_id
        arr.payment_number = payment.payment_number
        arr.payment_amount = payment.payment_amount
        arr.payment_date = payment.payment_date
        arr.payment_description = payment.payment_description
        return arr
      }
      )
    })
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ Payment2Regex: updatedListGeneralSorted })
    
    if (updatedListSpecific.length > 0) {
      if (this.props.update && updatedListSpecific[0][1] !== this.state.initialValues.payment_number) {
        this.setState({ Payment2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
      else if (this.props.update && updatedListSpecific[0][1] === this.state.initialValues.payment_number) {
        this.setState({ Payment2RegexSpecific: '' })
        setStatus({ errors: {} })
      }
      if (!this.props.update) {
        this.setState({ Payment2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ Payment2RegexSpecific: '' })
      setStatus({ errors: {} })
    }
    
  }, 100)

  render() {

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      PaymentNumber: '',
      PaymentAmount: null,
      PDate: null,
      Description: null,
    } : {
      PaymentNumber: this.state.initialValues.payment_number,
      PaymentAmount: this.state.initialValues.payment_amount,
      PDate: new Date(this.state.initialValues.payment_date),
      Description: this.state.initialValues.payment_description,
    }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300


    return (
      <React.Fragment>
        {this.props.headingForForm.length <= 0 ?
          <Header as='h1'>{this.props.update === true ? 'Edit' : 'Make'} Payment Form</Header> :
          <Header as='h3'>{this.props.headingForForm}</Header>
        }
        {this.props.update && !isEmptyNullUndefined(this.state.initialValues) &&
          <Header as='h2'>Editing: {this.state.initialValues.payment_number}</Header>
        }



        <Formik

          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={AddPaymentSchema}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              this.setState({ Payment2Regex: [], Payment2RegexSpecific: '' })
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editPayment', {
                InvoiceID: this.state.SelectedInvoiceID,
                PaymentNumber: values.PaymentNumber, PaymentAmount: values.PaymentAmount,
                PDate: values.PDate, Description: values.Description,
                paymentID: this.props.match.params.paymentID
              }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addPayment', {
                InvoiceID: this.state.SelectedInvoiceID,
                PaymentNumber: values.PaymentNumber, PaymentAmount: values.PaymentAmount,
                PDate: values.PDate, Description: values.Description
              }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for addPayment: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                //Update the Existing Invoices
                // this.getInvoices()

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                actions.resetForm({ ...initialValues })
                this.setState(initialState)
                await reduxupdate('payments')
                this.getInvoicesOutstanding()
                if (this.props.update) {
                  await this.getSpecificPayment()
                  this.setState({SelectedInvoiceID: this.state.initialValues.invoice_id})
                  this.getPaymentsForInvoice(this.state.initialValues.invoice_id)
                }
                this.props.update && this.props.history.goBack()
              }
            }
            catch (error) { this.props.fetchErrors(error.toString()) }
            actions.setSubmitting(false)
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment>
                  <Grid columns={16} padded divided={!isEmptyNullUndefined(this.state.SelectedInvoiceID) && !isEmptyNullUndefined(this.state.PaymentsForInvoice) ? true : false}>
                    <Grid.Row>
                      <Grid.Column width={!isEmptyNullUndefined(this.state.SelectedInvoiceID) && !isEmptyNullUndefined(this.state.PaymentsForInvoice) ? 13 : 16}>

                        <Form onSubmit={handleSubmit} noValidate autoComplete='off'>

                          {/* First, select an Invoice */}
                          {this.state.InvoicesOutstanding.length > 0 ?
                            isEmptyNullUndefined(this.state.SelectedInvoiceID) ?
                              <React.Fragment>
                                <h3>Select an Invoice (click on row):</h3>
                                <ReactTableMaker passedData={this.state.InvoicesOutstanding} passedColumns={InvoicesOutstandingColumns}
                                  handleClick={(rowID, cellID) => {
                                    // console.log(`Outside here and click on row ${rowID} and column ${cellID}.`)
                                    this.setState({ SelectedInvoiceID: rowID, SelectedInvoiceDate: this.state.InvoicesOutstanding.find(invoice => invoice.invoice_id === rowID).invoice_date })
                                    this.getPaymentsForInvoice(rowID)
                                  }}
                                  includeSearchBar={true}
                                />
                              </React.Fragment>
                              :
                              !isEmptyNullUndefined(this.state.SelectedInvoiceID) ?

                                <Segment color='blue'><h4>Invoice Details</h4>
                                  <Segment secondary>

                                    <h6><b>Client/Matter: </b>{this.state.InvoicesOutstanding.find(invoice => invoice.invoice_id === this.state.SelectedInvoiceID).client_name}{' '}/{' '}
                                      {this.state.InvoicesOutstanding.find(invoice => invoice.invoice_id === this.state.SelectedInvoiceID).matter_name}</h6>
                                    <h6><b>Invoice Number: </b>{this.state.InvoicesOutstanding.find(invoice => invoice.invoice_id === this.state.SelectedInvoiceID).invoice_number}</h6>
                                    <h6><b>Invoice Amount: </b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                                      this.state.InvoicesOutstanding.find(invoice => invoice.invoice_id === this.state.SelectedInvoiceID).invoice_amount)}</h6>
                                    <h6><b>Amount Due: </b><span style={{ 'color': 'red' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                                      this.state.InvoicesOutstanding.find(invoice => invoice.invoice_id === this.state.SelectedInvoiceID).due)}</span></h6>
                                    <h6><b>Invoice Date: </b>{this.state.InvoicesOutstanding.find(invoice => invoice.invoice_id === this.state.SelectedInvoiceID).invoice_date}</h6>
                                    <h6><b>Invoice Description: </b>{this.state.InvoicesOutstanding.find(invoice => invoice.invoice_id === this.state.SelectedInvoiceID).invoice_description}</h6>

                                  </Segment>
                                  <Button color='red' size='mini' onClick={() => {
                                    this.setState({ SelectedInvoiceID: null })
                                    handleReset()
                                  }}>Select a different invoice.</Button>
                                </Segment> : null
                            : null}

                          {this.errorTag(errors, touched, status, 'Client', 'Pick a Client. ', 'Pick a Client. ')}

                          {/* Show the rest of this only if there is a client chosen, first */}
                          {!isEmptyNullUndefined(this.state.SelectedInvoiceID) &&

                            <React.Fragment>

                              {/* Selected Client: {this.state.SelectedClient}, Selected Matter: {this.state.SelectedMatter}
                        <br/>
                        Last Used Invoice Number for this client: {this.state.LastUsedInvoiceNumber}
                        <br /> */}


                              <Form.Group>

                                {/* Add the Payment Number */}
                                <Form.Field width={5}>
                                  <Form.Input
                                    name="PaymentNumber" type="text"
                                    label='Payment Number'
                                    loading={this.props.fetch.fetching}
                                    required
                                    placeholder='Check number, wire number, etc.'
                                    error={(errors.PaymentNumber && touched.PaymentNumber) || (has(status, 'errors.PaymentNumber'))}
                                    onChange={async (e) => {
                                      await handleChange(e);
                                      // setFieldTouched('PaymentNumber', true)
                                      //   this.setState({ PaymentNumber: e.target.value })
                                      //   let arrPaymentsForInvoice = Object.keys(this.state.PaymentsForInvoice).map((key) => 
                                      //       this.state.PaymentsForInvoice[key]
                                      //     )
                                      //     console.log('This is arrPaymentsForInvoice: ', arrPaymentsForInvoice)
                                      //   // console.log('This is PaymentsForInvoice: ', this.state.PaymentsForInvoice)
                                      //   // this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, Object.keys(this.state.PaymentsForInvoice).map((key) => {
                                      //   //   return [Number(key), this.state.PaymentsForInvoice[key]];
                                      //   // }))
                                    }}
                                    // onBlur={(e) => {
                                    //   handleBlur(e)
                                    //   if (isEmptyNullUndefined(values.PaymentNumber)) { values.PaymentNumber = this.createPaymentNumber(values.ClientMatter.split(',')[0]) }
                                    //   setFieldTouched('PaymentNumber', true)
                                    // }}
                                    // options={this.ClientMatterDropDownArray()}
                                    onBlur={handleBlur}
                                    value={values.PaymentNumber} />
                                  {this.errorTag(errors, touched, status, 'PaymentNumber')}
                                </Form.Field>
                              </Form.Group>

                              {/* List Out Payment with Same Name */}
                              <Segment secondary={this.state.Payment2RegexSpecific.length === 0} color={this.state.Payment2RegexSpecific.length > 0 ? 'red' : 'blue'}
                                hidden={this.state.Payment2Regex.length === 0}>
                                {ListMakerSimple(this.state.Payment2Regex, values, this.state.Payment2RegexSpecific, 'Payment')}
                              </Segment>

                              {/* Payment Creation Date */}
                              <Form.Field required width={6} error={(touched.IDate && isNaN(values.IDate)) || has(status, 'errors.IDate')}>
                                <label>Payment Date {!isEmptyNullUndefined(this.state.SelectedInvoiceDate) ? `(invoice created on ${this.state.SelectedInvoiceDate})` : null}</label>
                                <DatePicker
                                  name="PDate"
                                  onSelect={(date) => {
                                    // console.log('This is date: ', date)
                                    setFieldValue('PDate', date)
                                    // handleChange()
                                    //console.log('We have selected this date: ', date)
                                    // setTimeout(setFieldTouched('PDate', true), 10)
                                    // setTimeout(console.log('PDate was set'), 10)
                                    //I cannot console.log the new values.PDate here.  I get the old date.  Can't figure out how to make that work.
                                    // console.log('And this is values: ', values.PDate)
                                  }}
                                  dateFormat='MMMM d, yyyy'
                                  minDate={new Date(!isEmptyNullUndefined(this.state.SelectedInvoiceDate) ? this.state.SelectedInvoiceDate : '2011,01,01')}
                                  maxDate={new Date()}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  withPortal
                                  loading={this.props.fetch.fetching}
                                  onBlur={() => setFieldTouched('PDate', true)}
                                  todayButton={'Today'}
                                  selected={isEmptyNullUndefined(values.PDate) ? values.PDate : null}
                                  placeholderText="Select an origination date"
                                />



                                {this.errorTag(errors, touched, status, 'PDate')}
                              </Form.Field>

                              {/* Amount */}
                              <Form.Field required width={6} error={has(status, 'errors.PaymentAmount')}>
                                <label>Insert Payment Amount (in $)</label>

                                <NumberFormat
                                  name="PaymentAmount"
                                  label='Payment Amount'
                                  thousandSeparator={true}
                                  prefix={'$'}
                                  onBlur={() => setFieldTouched('PaymentAmount', true)}
                                  placeholder='Amount paid...'
                                  value={values.PaymentAmount}
                                  onValueChange={(e) => {
                                    // console.log('This is the value: ', e.floatValue)
                                    setFieldValue('PaymentAmount', isEmptyNullUndefined(e.floatValue) ? null : e.floatValue)
                                  }} />
                                {this.errorTag(errors, touched, status, 'PaymentAmount')}
                              </Form.Field>


                              {/* Add the description */}
                              <Form.Field width={14}>
                                <Form.TextArea
                                  name="Description" type="text"
                                  label={`Description for Payment (optional)`}
                                  loading={this.props.fetch.fetching}
                                  // required
                                  placeholder={`Description for Payment`}
                                  error={(errors.Description && touched.Description) || (has(status, 'errors.Description'))}
                                  onChange={async (e) => {
                                    await handleChange(e);
                                    this.setState({ Description: e.target.value })
                                  }}
                                  onBlur={handleBlur}
                                  value={!isEmptyNullUndefined(values.Description) ? values.Description : ''} />
                                {this.errorTag(errors, touched, status, 'Description')}
                              </Form.Field>

                            </React.Fragment>
                          }

                          <Divider />
                          <Button.Group size='large'>
                            <Button type='button' icon='cancel'
                              onClick={() => {
                                handleReset()
                                this.setState(initialState)
                              }}
                              color='red'
                              content='Cancel'></Button>
                            <Button.Or />
                            <Button color='teal'
                              onClick={() => {
                                setFieldValue('PaymentNumber', '')
                                setFieldValue('PaymentAmount', null)
                                setFieldValue('PDate', 'a')
                                setFieldValue('Description', null)
                                setFieldTouched('PaymentNumber', false)
                                setFieldTouched('PaymentAmount', false)
                                setFieldTouched('PDate', false)
                                setFieldTouched('Description', false)
                                // setFieldTouched({})
                                this.setState({ Description: null, Matter: '', Payment2Regex: [], Payment2RegexSpecific: '' })
                              }}
                              type='button'
                              disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                              content='Clear Form'>
                            </Button>
                            <Button.Or />
                            <Button color='orange'
                              onClick={() => { setStatus({}); setErrors({}) }}
                              type='button'
                              //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                              content='Clear Error Notifications'>
                            </Button>
                            <Button.Or />
                            <Button type='submit'
                              // disabled={isSubmitting}
                              //Eventually use the below, the one above is just for testing
                              disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                              icon='upload'
                              loading={isSubmitting}
                              floated='right'
                              color={dirty ? 'green' : 'grey'}
                              content={(this.props.update === true ? 'Edit' : 'Add') +' Payment'}></Button>
                          </Button.Group>
                          {/* <DisplayState state={this.state} /> */}
                          {/* <DisplayFormikState /> */}
                        </Form>

                      </Grid.Column>
                      {!isEmptyNullUndefined(this.state.SelectedInvoiceID) && !isEmptyNullUndefined(this.state.PaymentsForInvoice) ?
                        <Grid.Column width={3}>
                          {!isEmptyNullUndefined(this.state.PaymentsForInvoice) ?
                            <PaymentList key={this.state.PaymentsForInvoice.length}
                              ExistingPayments={this.state.PaymentsForInvoice}
                              SelectedInvoiceNumber={this.state.InvoicesOutstanding.find(invoice => invoice.invoice_id === this.state.SelectedInvoiceID).invoice_number}
                            /> :
                            // null :
                            null}
                        </Grid.Column> : null}
                    </Grid.Row>
                  </Grid>
                </Segment>
              </div>
            )}
        </Formik>

      </React.Fragment>
    )
  }
}

class PaymentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPaymentDetails: false, selectedPayment: null };
  }


  ReversedExistingPayments = [...this.props.ExistingPayments].reverse()

  SelectedInvoiceNumber = this.props.SelectedInvoiceNumber


  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  showPaymentDetailsToggle = (option) => {
    // console.log('Clicking showPaymentDetailsToggle and this option: ', option)
    switch (option) {
      case false:
        this.setState({ showPaymentDetails: false })
        break
      case true:
        this.setState({ showPaymentDetails: true })
        break
      default:
        this.setState(prevState => ({ showPaymentDetails: !prevState.showPaymentDetails }))
    }
  }

  render() {
    // console.log('This is reversedExistingPayments: ', this.ReversedExistingPayments)
    // console.log('In PaymentList an dthis is SelectedInvoiceNumber: ', SelectedInvoiceNumber2)

    return (
      <React.Fragment>
        Existing Payments for Invoice No.: {this.props.SelectedInvoiceNumber}
        <br />

        <List selection>
          {this.ReversedExistingPayments.map((payment, index) => {
            let amount = this.formatter.format(payment.payment_amount)
            return (
              <List.Item key={index}

                onClick={() => {
                  // console.log('Clicked on this one: ', invoice[0])
                  this.setState({ selectedPayment: payment.payment_id })
                  if (this.state.selectedPayment === payment.payment_id) this.showPaymentDetailsToggle()
                  else this.showPaymentDetailsToggle(true)
                }}>
                {this.props.SelectedPayment === payment.payment_id ?
                  <span style={{ 'color': 'red' }}>
                    <List.Content>
                      <List.Icon name={this.state.showPaymentDetails && this.state.selectedPayment === payment.payment_id ? 'minus' : 'plus'} size='small' />
                      {payment.payment_number}*
                   </List.Content>
                  </span> :
                  <List.Content>
                    <List.Icon name={this.state.showPaymentDetails && this.state.selectedPayment === payment.payment_id ? 'minus' : 'plus'} size='small' />
                    {payment.payment_number}
                  </List.Content>
                }

                {this.state.showPaymentDetails && this.state.selectedPayment === payment.payment_id ?
                  <List bulleted>
                    <List.Item>{payment.payment_date}</List.Item>
                    <List.Item>{amount}</List.Item>
                    {payment.payment_description.length > 0 && <List.Item>{payment.payment_description}</List.Item>}
                  </List> :
                  null
                }
              </List.Item>)
          }
          )
          }
        </List>
      </React.Fragment>
    )
  }
}

const AddPaymentSchema = Yup.object().shape({
  PaymentNumber: Yup
    .string()
    .min(4, 'Payment Number must be at least 4 characters.')
    .max(12, 'Payment Number cannot exceed 12 characters.')
    .required('Must have a Payment Number.'),
  // PDate: Yup
  //   .date()
  //   .required()
  //   .typeError('No date selected.'),
  PaymentAmount: Yup
    .number()
    .nullable()
    .min(0)
    .max(10000000000)
    .required(),
  Description: Yup
    .string()
    .min(3, 'Description must be at least 3 characters.')
    .max(10000, 'Description cannot exceed 10,000 characters.')
    .optional()
    .nullable(),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(AddPayment)

AddPayment.defaultProps = {
  getLawyers: () => { },
  getTycoons: () => { },
  getNotes: () => { },
  headingForForm: '',
}


const InvoicesOutstandingColumns = [
  {
    Header: () => <span style={{ 'float': 'left' }}>Invoice ID</span>,
    accessor: 'invoice_id',
    show: true,
    width: 0,
    sortType: 'alphanumeric',
    Filter: 'defaultColumnFilterLeft'
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'right' }}>Client Name</span>,
    accessor: 'client_name',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'right', cursor: 'pointer' }}>{cell.value}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'right' }}>Matter Name</span>,
    accessor: 'matter_name',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'right', cursor: 'pointer' }}>{cell.value}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'right' }}>Invoice Number</span>,
    accessor: 'invoice_number',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'right', cursor: 'pointer' }}>{cell.value}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'right' }}>Invoice Date</span>,
    accessor: 'invoice_date',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'right', cursor: 'pointer' }}>{cell.value}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'right' }}>Invoice Amount</span>,
    accessor: 'invoice_amount',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'color': 'green', 'float': 'right', cursor: 'pointer' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
  },
  {
    Header: () => <span style={{ 'float': 'right' }}>Paid</span>,
    accessor: 'paid',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) =>
      cell.value <= 0 ? <span style={{ 'color': 'red', 'float': 'right', cursor: 'pointer' }}>-</span> :
        <div onClick={() => null} > <span style={{ 'color': 'red', 'float': 'right' }}>({new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)})</span> </div>
  },
  {
    Header: () => <span style={{ 'float': 'right' }}>Due</span>,
    accessor: 'due',
    sortType: 'alphanumeric',
    Filter: 'subZeroFilter',
    filter: 'subZeroSearch',
    Cell: ({ cell }) => <span style={{ 'color': cell.value < 0 ? 'red' : 'black', 'float': 'right', cursor: 'pointer' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
  }
]
