import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { isEmptyNullUndefined, RGB_Linear_Shade, drawArrow } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';

import { VictoryBar, VictoryAxis, VictoryStack, VictoryLabel, VictoryArea } from 'victory'

import colorScheme from './colorScheme'


let generateQuarters = (beginDate, endDate, allYears = true) => {
  let months = [0, 1, 4, 7, 10]
  // console.log(`This is beginDate: ${beginDate}, and this is endDate: ${endDate}`)
  let getQuarter = (date) => Math.ceil((date.getMonth() + 1) / 3)

  let beginYear = beginDate.getFullYear()
  let endYear = endDate.getFullYear()
  let beginQuarter = getQuarter(beginDate)
  let endQuarter = getQuarter(endDate)
  // console.log(`beginYear: ${beginYear};  endYear: ${endYear}; beginQuarter: ${beginQuarter}; endQuarter: ${endQuarter}`)

  let quarterlist = []
  while (beginYear !== endYear || beginQuarter !== endQuarter) {
    quarterlist.push(new Date(beginYear, months[beginQuarter] - 1, 1));
    beginQuarter++;
    if (beginQuarter > 4) {
      beginQuarter = 1;
      beginYear++;
    }
    if (beginYear === endYear && beginQuarter === endQuarter)
      quarterlist.push(new Date(beginYear, months[beginQuarter] - 1, 1));
    // console.log(`beginYear: ${beginYear};  endYear: ${endYear}; beginQuarter: ${beginQuarter}; endQuarter: ${endQuarter}`)
  }

  if (allYears) quarterlist.push(new Date(`${endYear}-12-31`))
  else { quarterlist.push(new Date(beginYear, months[beginQuarter] + 2, 1)) }
  return quarterlist
}

class ChartsStackedAreaFiscalYearsSVG extends Component {

  state = {
    // TokenReady: false,
    chartMinAmount: 0,
    chartMaxAmount: 10,
    chartMinDate: new Date(),
    chartMaxDate: new Date(),
    chartStartDate: new Date(),
    tempMatters: [],
    // plotMatters: [],
    isMounted: false,
    mattersMounted: false,
    invoicesMounted: false,
    noMatters: true,



    loading: true,
    PartnerBilling: null,
    currentYear: new Date().getFullYear(),
    selectedYear: new Date().getFullYear(),
    employees: [],
    StackInvoices: [],
    employeesMounted: false,
    noEmployees: true,
    quarters: generateQuarters(new Date(new Date().getFullYear(), 1, 1), new Date(new Date().getFullYear(), 11, 30)),
    pictureDelay: false
  }

  today = new Date()
  finalDate = this.today.setMonth(this.today.getMonth() + 1)

  async componentDidMount() {
    this.setState({ isMounted: true })
    // this.props.setLoadingMattersOverTimeChart(true)
    // let fetchResponse = await this.getEmployees()
    !isEmptyNullUndefined(this.props.fetchObject) && this.setState({
      isMounted: true,
      fetchObject: this.props.fetchObject,
      employees: this.createEmployees(this.props.fetchObject)
    })
    // this.getPartnerBilling()
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedYear !== this.props.selectedYear) {
      let quarters = generateQuarters(new Date(this.props.selectedYear, 1, 1), new Date(this.props.selectedYear, 11, 30))
      this.setState({
        employees: this.createEmployees(this.props.fetchObject),
        quarters: quarters,
        chartMinDate: quarters[0],
        chartMaxDate: quarters[quarters.length - 1],
      })
    }
    if (prevProps.fetchObject !== this.props.fetchObject || prevProps.byInvoicingEmployee !== this.props.byInvoicingEmployee) {
      !isEmptyNullUndefined(this.props.fetchObject) && this.setState({
        isMounted: true,
        fetchObject: this.props.fetchObject,
        employees: this.createEmployees(this.props.fetchObject)
      })
    }
    if (prevState.employees !== this.state.employees) {
      if (!isEmptyNullUndefined(this.state.employees)) {
        let quarters = this.props.selectedYear !== 0 ? generateQuarters(new Date(this.props.selectedYear, 1, 1), new Date(this.props.selectedYear, 11, 30)) : this.generateQuarters(this.state.employees)
        this.setState({
          noEmployees: false,
          TotalInvoices: this.state.employees.reduce((acc, result) => {
            return acc + result.invoices[result.invoices.length - 1].amount
          }, 0),
          StackInvoices: this.reducedEmployees(this.state.employees),
          employeesMounted: true,
          quarters: quarters,
          chartMinDate: quarters[0],
          chartMaxDate: quarters[quarters.length - 1],
        })
      }
      else {
        this.setState({
          noEmployees: true,
          TotalInvoices: 0,
          StackInvoices: [],
          employeesMounted: false,
          quarters: generateQuarters(new Date(new Date().getFullYear(), 1, 1), new Date(new Date().getFullYear(), 11, 30)),
        })
      }
    }
    if (prevState.StackInvoices !== this.state.StackInvoices) {
      this.setState({
        PostStackInvoices: this.stackInvoices(this.state.StackInvoices)
      })
    }
    if (prevState.PostStackInvoices !== this.state.PostStackInvoices) {
      this.setState({
        chartMaxAmount: !isEmptyNullUndefined(this.state.PostStackInvoices) ? this.getMaxAmount(this.state.PostStackInvoices[this.state.PostStackInvoices.length - 1].invoices) + 1 : 0
        // chartMaxAmount: 2000000
      })
    }
  }


  generateQuarters = (employees) => {
    let arrayOfMinDates = employees.reduce((acc2, item) => {
      let reducedArray = item.invoices.reduce((acc, invoice) => {
        if (invoice.amount > 0) acc.push(invoice.date)
        return acc
      }, [])
      if (reducedArray.length > 0) acc2.push(new Date(this.getMinDate2(reducedArray)))
      return acc2
    }, [])
    let arrayOfMaxDates = employees.reduce((acc2, item) => {
      let reducedArray = item.invoices.reduce((acc, invoice) => {
        if (invoice.amount > 0) acc.push(invoice.date)
        return acc
      }, [])
      if (reducedArray.length > 0) acc2.push(new Date(this.getMaxDate2(reducedArray)))
      return acc2
    }, [])
    let startDate = new Date(this.getMinDate2(arrayOfMinDates))
    let endDate = new Date(this.getMaxDate2(arrayOfMaxDates))
    this.setState({
      chartMinDate: startDate,
      chartMaxDate: endDate,
    })
    // console.log('This is startDate: ', startDate)
    // console.log('This is endDate: ', endDate)
    let quarters = generateQuarters(startDate, endDate, false)
    // console.log('This is quarters: ', quarters)
    return quarters
  }

  componentWillUnmount() {
    this.setState({ isMounted: false })
    this.props.setLoadingMattersOverTimeChart(false)
  }

  daysIntoYear = (date) => {
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
  }

  daysBetweenDates = (date1, date2) => {
    // get total seconds between two dates
    var res = Math.abs(date1 - date2) / 1000;
    var days = Math.floor(res / 86400)
    return days
  }

  getMinDate2 = (array) => {
    // console.log('This is array: ', array)
    let mindate = !isEmptyNullUndefined(array) ? array.reduce((min, p) => new Date(p).getTime() < new Date(min).getTime() ? new Date(p).getTime() : new Date(min), array[0]) : null
    return mindate
  }
  getMaxDate2 = (array) => {
    // console.log('This is array: ', array)
    let maxdate = !isEmptyNullUndefined(array) ? array.reduce((min, p) => new Date(p).getTime() > new Date(min).getTime() ? new Date(p).getTime() : new Date(min), array[0]) : null
    return maxdate
  }

  createEmployees = (payload) => {
    let employees = []
    employees = this.props.clients.employeesarray.map((item, i) => {
      var employee = {}
      employee.id = item[0]
      employee.name = item[1] + ' ' + item[2]
      employee.color = colorScheme[i]
      employee.invoices = this.createPartnerInvoices(item[0], payload)
      let lastDate = new Date(employee.invoices[employee.invoices.length - 1].date.getFullYear(), employee.invoices[employee.invoices.length - 1].date.getMonth() + 1, 1)
      // console.log('This is lastDate: ', lastDate)
      let lastInvoiceDate = this.daysBetweenDates(employee.invoices[employee.invoices.length - 1].date, lastDate)
      // console.log('This is lastInvoiceDate: ', lastInvoiceDate)
      let firstInvoiceDate = this.daysBetweenDates(employee.invoices[0].date, lastDate)
      // console.log('This is firstInvoiceDate: ', firstInvoiceDate)
      let percentage = isEmptyNullUndefined(employee.invoices) ? 1 : employee.invoices[employee.invoices.length - 1].amount === 0 ? 1 : 1 - lastInvoiceDate / firstInvoiceDate
      // console.log('This is percentage: ', percentage)
      // console.log(`For ${employee.name} the first date is: ${firstInvoiceDate} and the last date is ${lastInvoiceDate} and percentage is ${percentage}`)
      // console.log(this.daysIntoYear(new Date(employee.invoices[employee.invoices.length - 1].date)) / 365)
      employee.yearPercentage = this.props.selectedYear !== 0 ? this.daysIntoYear(new Date(employee.invoices[employee.invoices.length - 1].date)) / 365 : percentage
      // employee.yearPercentage = this.daysIntoYear(new Date(employee.invoices[employee.invoices.length - 1].date)) / 365
      return employee
    }
    )
    return employees
  }

  createPartnerInvoices = (partnerID = null, array = null) => {
    let cumul = 0
    // console.log('In createPartnerInvoices and this is props.selectedYear: ', this.props.selectedYear)
    // console.log('In createPartnerInvoices and this is array: ', array)
    let returnArray = array.reduce((acc, item, i, array) => {
      if (this.props.selectedYear === 0 || (new Date(item.date) >= new Date(`${this.props.selectedYear}-01-01`) && new Date(item.date) <= new Date(`${this.props.selectedYear}-12-31`))) {
        // console.log('This is item: ', new Date(item.date))
        let arr = {}
        if (!partnerID) cumul += parseFloat(item.amount)
        else {
          if (i === 0) cumul = 0
          if (item[this.props.byInvoicingEmployee ? 'invoicing_employee_id' : 'employee_id'] === partnerID) cumul = cumul + parseFloat(array[i].amount)
        }
        // console.log(`In createPartnerInvoices and this is cumul: `, cumul)
        arr.amount = cumul
        arr.date = new Date(Date.parse(item.date))
        acc.push(arr)
      }
      return acc
    }, [])
    // console.log('This is returnArray in createPartnerInvoices after first reduction: ', returnArray)

    //Now add a January 1 point
    let arr = {}
    arr.amount = 0
    arr.date = this.props.selectedYear !== 0 ? new Date(`${this.props.selectedYear}-01-01`) : new Date(array[0].date)
    returnArray.unshift(arr)

    if (this.props.selectedYear !== this.state.currentYear && this.props.selectedYear !== 0) {

      //Now add a December 31 point if we're not in the current year
      let arr1 = {}
      arr1.amount = returnArray[returnArray.length - 1].amount
      arr1.date = new Date(`${this.props.selectedYear}-12-31`)
      returnArray.push(arr1)
    }

    // console.log('This is createPartnerInvoices returnArray: ', returnArray)
    return returnArray
  }

  //This takes out any employee that invoiced nothing
  reducedEmployees = (earray) => {
    // console.log('This is earray: ', earray)
    let reducedEmployees = earray.reduce((acc, result) => {
      // console.log('This is result: ', result)
      if (result.invoices[result.invoices.length - 1].amount > 0) {
        acc.push(result)
      }
      return acc
    }, []
    )
    // console.log('This is reducedEmployees: ', JSON.stringify(reducedEmployees[0].invoices))
    return reducedEmployees
  }

  //This stacks the invoices
  stackInvoices = (sarray) => {
    // console.log('This is earray: ', sarray)
    let stackedInvoices = sarray.reduce((acc, result, index, array) => {
      // console.log('This is result: ', result)
      let invoicesnumber = array[0].invoices.length
      let employeeObj = {}
      employeeObj.id = result.id
      employeeObj.color = result.color
      employeeObj.name = result.name
      employeeObj.total = result.invoices[result.invoices.length - 1].amount
      employeeObj.quarters = result.quarters
      employeeObj.yearPercentage = result.yearPercentage
      employeeObj.invoices = []
      for (let employee = 0; employee <= index; employee++) {
        let invoiceArray = []
        for (let invoice = 0; invoice < invoicesnumber; invoice++) {
          let cumamount = 0
          for (let round = 0; round <= index; round++) {
            cumamount += array[round].invoices[invoice].amount
          }
          invoiceArray.push({ date: array[employee].invoices[invoice].date, amount: cumamount })
          // console.log(`This is employeeID: ${array[employee].id} and this is invoice amount: ${array[employee].invoices[invoice].amount}`)
        }
        employeeObj.invoices = invoiceArray
      }
      if (true) {
        acc.push(employeeObj)
      }
      return acc
    }, []
    )

    //Now figure out the percentage of total invoices for each employee
    let stackedInvoices2 = stackedInvoices.reduce((acc, result) => {
      result.percentage = result.total / this.state.TotalInvoices
      acc.push(result)
      return acc
    }, [])

    //Now figure out the cumulative percentage for each employee (so it is 100% by the last one)
    let cumpercentage = 0
    let stackedInvoices3 = stackedInvoices2.reduce((acc, result, i) => {
      // console.log(`This is ${employee.name}, this is index: ${index}, this is i: ${i}, and this is employee.percentage: ${employee.percentage}`)
      cumpercentage += result.percentage
      result.cumpercentage = cumpercentage
      acc.push(result)
      return acc
    }, [])
    // console.log('This is test: ', test)
    // console.log('This is stackedInvoices: ', stackedInvoices)
    return stackedInvoices3
  }


  // getDates = () => {
  //   let tempState = Object.assign({}, this.state)
  //   // console.log('This is tempState: ', tempState)
  //   let mindate = this.getMinDate(tempState)
  //   // let startDate = this.getStartDate(mindate)
  //   this.state.isMounted && this.setState({ chartMinDate: mindate, chartStartDate: this.quarterifyDates([{ date: new Date(this.props.ClientInfo.origination_date), amount: 1 }])[0].date })
  // }

  // getMinAmount = (array) => {
  //   return !isEmptyNullUndefined(array) ? array.reduce((min, p) => p.amount < min ? p.amount : min, array[0].amount) : null
  // }

  getMaxAmount = (array) => {
    return !isEmptyNullUndefined(array) ? array.reduce((max, p) => p.amount > max ? p.amount : max, array[0].amount) : null
  }
  // getMaxDate = () => {
  //   let date = !isEmptyNullUndefined(this.state.plotMatters) ? this.state.plotMatters.reduce((max, p) => p.date > max ? p.date : max, this.state.plotMatters[0].date) : null
  //   return !isEmptyNullUndefined(this.state.plotMatters) ? date : null
  // }

  // getMinDate = (tempState) => {
  //   let mindate = tempState.plotMatters.reduce((min, p) => p.date < min ? p.date : min, tempState.plotMatters[0].date)
  //   return mindate
  // }

  // getStartDate = (mindate) => {
  //   let startdate = new Date(mindate)
  //   startdate.setMonth(startdate.getMonth() - bufferMonths)
  //   return startdate
  // }

  // quarterifyDates = (matters) => {
  //   let returnArray = matters.reduce((acc, item, i, matters) => {
  //     let months = [0, 1, 4, 7, 10]
  //     let obj = {}
  //     let quarter = Math.ceil((item.date.getMonth() + 1) / 3)
  //     // obj.date = item.date
  //     // obj.quarter = quarter
  //     obj.date = new Date(item.date.getFullYear(), months[quarter] - 1, 1)
  //     obj.amount = item.amount
  //     acc.push(obj)
  //     return acc
  //   }, [])
  //   // console.log('This is quartifyarray: ', returnArray)
  //   return returnArray
  // }

  // sumDates = (matters) => {
  //   let returnArray = matters.reduce((accumulator, cur) => {
  //     //Get the date of the current item
  //     let date = cur.date

  //     //See whether there is already a same date in the accumulator
  //     let found = accumulator.find((item) => item.date.getTime() === date.getTime());

  //     //If we find a duplicate date, then add the current.amount to the found.amount
  //     if (found) {
  //       // console.log('Found this: ', found)
  //       found.amount += cur.amount
  //     }

  //     //Otherwise, since it's not already in the accumulator, then push this unique current into accumulator
  //     else accumulator.push(cur);
  //     return accumulator;
  //   }, [])
  //   return returnArray
  // }

  render() {

    // let yTickValues = () => {
    //   let tickArray = []
    //   for (let i = 1; i <= this.state.chartMaxAmount; i++) {
    //     tickArray.push(i)
    //   }
    //   return tickArray
    // }

    const BLUE_COLOR = "#00a3de"
    const RED_COLOR = "#7c270b"

    let getPath = (from, to, lineWidth, arrowheadWidth, arrowheadLength) => {
      let path = drawArrow(from, to, lineWidth, arrowheadWidth, arrowheadLength)
      let pathd = path.getAttribute('d')
      let pathtransform = path.getAttribute('transform')
      let pathclass = path.getAttribute('class')
      return <path d={pathd} transform={pathtransform} className={pathclass} />
    }
    let renderSVG = (from, to, lineWidth, arrowheadWidth, arrowheadLength, fillcolor) => {
      // console.log(from, to)
      return (
        <svg id="test" className={'arrow-line'} stroke='black' strokeWidth={0.5} fill={fillcolor}>
          {getPath(from, to, lineWidth, arrowheadWidth, arrowheadLength)}
        </svg>
      )
    }

    return (

      <svg
        viewBox="-25 -25 600 350"
      >

        <g width="0" height="0">
          <defs >
            {colorScheme.map((color, index) =>
              <linearGradient key={`Gradient${index}`} id={`gradient${index}`}
                x1="70%" y1="0%" x2="00%" y2="0%"
              >
                <stop offset="0%" stopColor={color} />
                <stop offset="80%" stopColor={RGB_Linear_Shade(-.7, color)} />
                {/* <stop offset="90%" stopColor={'#ffffff'} /> */}
              </linearGradient>
            )}
          </defs>
        </g>

        <VictoryLabel x='40%' y={20} style={{
          textAnchor: "middle",
          verticalAnchor: "end",
          fill: "#000000",
          fontFamily: "inherit",
          fontSize: "12px",
          fontWeight: "bold"
        }}
          text={`Stacked Invoice Areas (${this.props.selectedYear === 0 ? 'All Years' : this.props.selectedYear})`}
        />

        {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
          <VictoryLabel x={45} y={30} style={{
            textAnchor: "end",
            fill: BLUE_COLOR,
            fontFamily: "inherit",
            fontSize: 8,
            fontStyle: "italic"
          }}
            text={"Invoices Sent"}
          />
        }

        {this.state.TotalInvoices > 10000 &&
          <VictoryLabel x={45} y={41}
            style={{
              textAnchor: "end",
              fill: BLUE_COLOR,
              fontFamily: "inherit",
              fontSize: 7,
              fontStyle: "italic"
            }}
            text={"(in thousands)"}
          />}


        <VictoryLabel x={425} y={30} style={{
          fill: RED_COLOR,
          fontFamily: "inherit",
          fontSize: 8,
          fontStyle: "italic"
        }}
          text={`Totals\n(by ${this.props.byInvoicingEmployee ? 'Invoicing' : 'Originating'} partner)`}
        />

        <VictoryLabel x={415} y={270} style={{
          fill: RED_COLOR,
          textAnchor: 'start',
          fontFamily: "inherit",
          fontSize: 8,
          fontStyle: "italic"
        }}
          text={`Total Invoiced:\n${!isEmptyNullUndefined(this.state.TotalInvoices) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.TotalInvoices) : null}`}
        />

        {!isEmptyNullUndefined(this.state.employees) && <React.Fragment>
          {/* <g transform={"translate(0, 0)"}> */}
          {/* X Axis */}
          {/* Quarters */}
          <VictoryAxis
            scale="time"
            standalone={false}
            style={{
              axis: { stroke: "black", strokeWidth: 1 },
              ticks: {
                size: 5,
                stroke: "black",
                strokeWidth: 1
              },
              tickLabels: { fontSize: 6, padding: 1 },
            }}
            tickValues={this.state.quarters}
            tickFormat={(q) => q.getMonth() === 0 ? 'Q1' : q.getMonth() === 3 ? 'Q2' : q.getMonth() === 6 ? 'Q3' : q.getMonth() === 9 ? 'Q4' : null}
          />
          {/* Years */}
          <VictoryAxis
            scale="time"
            standalone={false}
            style={{
              axis: { stroke: "black", strokeWidth: 1 },
              tickLabels: { fontSize: 8, padding: 12 }
            }}
            tickValues={this.state.quarters}
            tickFormat={(q) => q.getMonth() === 0 ? q.getFullYear() : ''}
          />
        </React.Fragment>}

        {!isEmptyNullUndefined(this.state.employees) && <React.Fragment>

          {/* Y Axis */}
          <VictoryAxis
            dependentAxis
            orientation="left"
            standalone={false}
            // style={{
            //   // axis: { stroke: "#756f6a" },
            //   axisLabel: { fontSize: 10, padding: 20 },
            //   // grid: { stroke: ({ tick }) => tick > 0.5 ? "6d65a0" : "grey" },
            //   // ticks: { stroke: "grey", size: 3 },
            //   tickLabels: { fontSize: 6, padding: 2 }
            // }}
            style={{
              grid: {
                stroke: ({ tick }) =>
                  tick === 0 ? "#000000" : "#ffffff",
                strokeWidth: 2
              },
              axis: { stroke: '#000000', strokeWidth: 1 },
              ticks: { strokeWidth: 0 },
              tickLabels: {
                fill: BLUE_COLOR,
                fontFamily: "inherit",
                fontSize: 8
              }
            }}
            tickFormat={(q) => '$' + new Intl.NumberFormat('en-US', { currency: 'USD', style: 'decimal' }).format(q / 1000)}
            // label='Invoices'
            domain={[0, this.state.chartMaxAmount]}
          // tickValues={yTickValues()}
          />
        </React.Fragment>}

        {!isEmptyNullUndefined(this.state.PostStackInvoices) && this.state.quarters.length > 1 && <React.Fragment>

          {this.state.PostStackInvoices.map((employee, index) =>
            <VictoryArea
              animate={{
                duration: 500,
                onLoad: { duration: 10 }
              }}
              key={'PartnerID-' + employee.id}
              standalone={false}
              data={this.state.PostStackInvoices[index].invoices}
              x={(data) => data.date}
              y={(data) => data.amount}
              interpolation="monotoneX"
              scale={{ x: "time", y: "linear" }}
              style={{
                data: {
                  stroke: BLUE_COLOR, strokeWidth: 2.5,
                  //This finds the index of the employee.id in the employeesarray (since not every employee may have invoices)
                  fill: `url(#gradient${this.props.clients.employeesarray.findIndex(e => e[0] === employee.id)})`
                }
              }}
              domain={{ x: [this.state.quarters[0], this.state.quarters[this.state.quarters.length - 1]], y: [0, this.state.chartMaxAmount] }}
            // labels={({ data, index, datum }) => parseInt(index) === data.length ? datum.amount : null}
            // labels={({ data, index, datum }) => parseInt(index) === data.length - 1 ? employee.total.toLocaleString('en-US', {
            //   style: 'currency',
            //   currency: 'USD',
            // }) + '\n' + employee.name : null}
            // labelComponent={<VictoryLabel style={{ fill: 'black', fontSize: 6, fontWeight: 900, }} dx={-20} dy={15} />}
            />
          ).reverse()}
        </React.Fragment>}

        {!isEmptyNullUndefined(this.state.PostStackInvoices) && <React.Fragment>

          {/* </g> */}
          <g transform={"translate(380, 0)"}>
            {/* <VictoryBar 
                      style={{ data: { fill: "#c43a31" } }}
                      standalone={false}
                      data={this.state.PostStackInvoices.map(item => {return {x: item.id, y: item.invoices[item.invoices.length-1].amount}})}
                    /> */}

            <VictoryStack
              standalone={false}
              colorScale={this.state.PostStackInvoices.map(item => item.color)}
              style={{
                data: { stroke: "black", strokeWidth: 1 }
              }}
              animate={{
                duration: 500,
                onLoad: { duration: 10 }
              }}
            >
              {this.state.PostStackInvoices.map((employee, index) =>
                <VictoryBar
                  key={'PartnerBarID-' + employee.id}
                  barWidth={20}
                  // data={[{ y: this.state.PostStackInvoices[index].invoices[this.state.PostStackInvoices[index].invoices.length - 1].amount}]}
                  data={[{ y: employee.total }]}
                  labels={({ data, index, datum }) => employee.percentage >= 0.01 ? employee.total.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }) + '\n' + employee.name + '\n' + parseFloat(employee.percentage * 100).toFixed(2) + "%" : null}
                  labelComponent={<VictoryLabel style={{ fill: RED_COLOR, fontSize: employee.percentage > 0.1 ? 6 : 4, fontWeight: 900, textAnchor: "start" }} dx={15} dy={employee.percentage > 0.1 ? 20 : 15} />}
                />
              )}
            </VictoryStack>

          </g>

          {/* Arrows pointing to the bar graph */}
          {!this.state.pictureDelay && this.state.PostStackInvoices.map((employee, index) =>
            employee.percentage > .05 && <g key={'PartnerPicID-' + index}>
              {renderSVG({ x: 54 + this.state.PostStackInvoices[0].yearPercentage * 351, y: 53 + (1 - employee.cumpercentage) * 200 },
                { x: 417, y: 53 + (1 - employee.cumpercentage) * 200 }, 3, 7, 6, employee.color)}
            </g>
          )}

          {/* Pictures inside the bar graph */}
          {!this.state.pictureDelay && this.state.PostStackInvoices.map((employee, index) => employee.percentage > .1 &&
            <g key={`Gradients${index}`} transform={`translate(424, 
                          ${58 + 200 * (1 - this.state.PostStackInvoices.reduce((acc, result, i) => {
              // console.log(`This is ${employee.name}, this is index: ${index}, this is i: ${i}, and this is employee.percentage: ${employee.percentage}`)
              if (i <= index) { acc += result.percentage }
              // console.log(`Doing ${employee.name} and this is acc: ${acc}`)
              return acc
            }, 0)
              )}) `}>
              <image height={13} href={this.props.clients.employeesarray.find(employeex => employeex[0] === employee.id)[4]} />
            </g>
          )}
        </React.Fragment>}

      </svg>


    )
  }
}


const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsStackedAreaFiscalYearsSVG))


ChartsStackedAreaFiscalYearsSVG.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  currentYear: new Date().getFullYear(),
  selectedYear: new Date().getFullYear(),
  selectedPartner: 1,
  fetchObject: [],
  byInvoicingEmployee: true,
  setLoadingMattersOverTimeChart: () => { },
  // handleSomething: () => { },
}