import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

// import ErrorMessage from '../helper/MessageBar'
import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, ListMaker, filterCENameInput, filterCFNameInput, filterCLNameInput } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'


import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Header, Label, Divider, Button, Segment, Checkbox } from 'semantic-ui-react';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';

import config from '../../JO-CONFIG'

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';


class AddClient extends Component {

  state = {
    fetcherrors: [],
    fetchinfo: [],
    fetchmessage: '',
    clienttype: '',
    visible: true,
    CName: '',
    success: null,
    CEName2Regex: [],
    CEName2RegexSpecific: '',
    initialValues: {},
    Matters: [],
  }

  componentDidMount() {
    this.props.fetchResetState('all')
    if (this.props.update) this.getSpecificClient()
  }

  getSpecificClient = async () => {
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('clientInfo', { fetchstr: 'getClient', clientID: this.props.match.params.clientID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getSpecificClient: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { cname: fetchresponse.payload.cename.length > 0 ? fetchresponse.payload.cename : fetchresponse.payload.clname.concat(', ', fetchresponse.payload.cfname), ...fetchresponse.payload }
    })
    this.state.initialValues.cename.length > 0 ? this.setState({ clienttype: 'entity' }) : this.setState({ clienttype: 'person' })
  }

  switchFormType = (setFieldValue, values) => {
    //console.log('This is "values": ', values)
    if (this.state.clienttype === 'entity') {
      setFieldValue('CFName', '')
      setFieldValue('CLName', '')
    }
    else {
      setFieldValue('CEName', '')
    }
  }

  employeeDropDownArray = () => {
    var array = this.props.clients.employeesarray
      //First, reduce to get only qualifying partners
      .reduce((acc, result) => {
        //Reduce array to only those whose level is equal to or below ORIGINATING_PARTNER_LEVEL
        // console.log('This is result: ', result)
        if (result[5] <= config.LEVELS.ORIGINATING_PARTNER_LEVEL) {
          acc.push(result)
        }
        return acc
      }, []
      )
      //Next make dropdown array
      .map(result => {
        // console.log('This is result: ', result)
        var obj = {}
        obj.key = `EID${result[0]}`
        obj.text = result[2] + ', ' + result[1]
        obj.value = result[0]
        // obj.image = `./images/${result[4]}`
        obj.image = result[4]
        return obj
      }
      )
    // console.log('This is array: ', array)
    return array
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  resetFetchState = (stateID) => {
    switch (stateID) {
      case 'fetcherrors':
        this.setState({ fetcherrors: [] })
        break
      case 'fetchinfo':
        this.setState({ fetchinfo: [] })
        break
      case 'success':
        this.setState({ success: false })
        break
      case 'all':
        this.setState({ fetcherrors: [], fetchinfo: [], success: false, successmessage: '' })
        break
      default:
        break
    }
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ CEName2Regex: updatedListGeneralSorted })
    if (updatedListSpecific.length > 0) {
      // console.log('This is concacted cename/cfname/clname: ', this.state.initialValues.cename.concat(',', this.state.initialValues.cfname, ',', this.state.initialValues.clname))
      console.log('This is updatedListSpecific[0].slice(1).join(): ', updatedListSpecific[0].slice(1).join())
      if (this.props.update && updatedListSpecific[0].slice(1).join() !== this.state.initialValues.cename.concat(',', this.state.initialValues.cfname, ',', this.state.initialValues.clname)) {
        this.setState({ CEName2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
      else if (this.props.update && updatedListSpecific[0].slice(1).join() === this.state.initialValues.cename.concat(',', this.state.initialValues.cfname, ',', this.state.initialValues.clname)) {
        this.setState({ CEName2RegexSpecific: '' })
        setStatus({ errors: {} })
      }
      else {
        this.setState({ CEName2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ CEName2RegexSpecific: '' })
      setStatus({ errors: {} })
    }
  }, 100)

  // {JSON.stringify(get(status, 'errors.'+Field))}
  render() {

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      CEName: '',
      CFName: '',
      CLName: '',
      CDate: 'a',
      //Put this section back after testing
      PartnerPick: null,
      //Delete this after testing
      // PartnerPick: 2,
      EngagementLetter: false,
    } : {
        CEName: this.state.initialValues.cename,
        CFName: this.state.initialValues.cfname,
        CLName: this.state.initialValues.clname,
        CDate: new Date(this.state.initialValues.origination_date),
        //Put this section back after testing
        PartnerPick: this.state.initialValues.employee_id,
        EngagementLetter: !!this.state.initialValues.engagement_letter,
      }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300

    return (
      <div>
        <Header as='h1'>Client {this.props.update === true ? 'Edit' : 'Add'} Form</Header>
        {this.props.update &&
          <Header as='h2'>Editing: {this.state.initialValues.cname}</Header>
        }

        {/* <ErrorMessage generalmessage='Something Went Wrong!'
            errorarray={this.state.fetcherrors}
            infoarray={this.state.fetchinfo}
            submessage={`We ran into ${this.state.fetcherrors.length === 1 ? 'a problem' : this.state.fetcherrors.length + ' problems'}...`}
            success={this.state.success}
            successmessage={this.state.successmessage}
            resetFetchState={this.resetFetchState} />
          <br /> */}


        {/* <Message hidden={this.state.fetcherrors.length === 0} floating negative>
          <Message.Header>We have encountered {this.state.fetcherrors.length === 1 ? 'a problem:' : 'some problems:'}</Message.Header>
          <Message.Content>{this.state.fetcherrors.map((item, i) => <li key={i}>{item}</li>)}</Message.Content>
        </Message> */}

        {/* This is only for development purposes to see what MySQL server said */}
        {/* <Message hidden={!this.state.fetchmessage.length > 0} floating positive>
          <Message.Header>Successfully added row.  Here is what the MySQL server said:</Message.Header>
          <Message.Content>{this.state.fetchmessage}</Message.Content>
        </Message> */}

        <Formik

          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={ClientAddEntitySchema}
          //const clearClient={() => { console.log('YOOO!!') }}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              this.setState({ fetchmessage: '', successmessage: '', CEName2Regex: [], CEName2RegexSpecific: '' })
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editClient', { ...values, clienttype: this.state.clienttype, clientID: this.props.match.params.clientID }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addClient', { ...values, clienttype: this.state.clienttype }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for edit/addClient: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })
              this.setState({
                fetcherrors: fetchresponse.errors,
                fetchinfo: fetchresponse.info,
                fetchmessage: fetchresponse.message,
                success: fetchresponse.success,
                successmessage: fetchresponse.successmessage,
              })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                //Update the clients store
                reduxupdate('clients')
                reduxupdate('clientsarray')
                if (this.props.update) await this.getSpecificClient()

                // clientupdate()
                // clientArrayUpdate()

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                actions.resetForm({ ...initialValues })
                this.props.update && this.props.history.goBack()
              }
            }
            catch (error) { this.setState({ fetcherrors: [...this.state.fetcherrors, error.toString()] }) }
            actions.setSubmitting(false)
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
            //this.setState({error: null})
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment color='grey' raised>
                  {/* {this.state.error ?
                    <React.Fragment>
                      <Message negative attached>
                        <Message.Header>Add New Client Error</Message.Header>
                        <Message.Content>{this.state.error}</Message.Content>
                      </Message>
                      <Divider hidden />
                    </React.Fragment>
                    : null} */}
                  {/* {this.state.success ?
                    <React.Fragment>
                      <Transition visible={this.state.visible} animation='fade' duration={transitionduration}>
                        <Message positive attached>
                          <Message.Header>Success</Message.Header>
                          <Message.Content><span><b>{this.state.CName}</b></span> successfully added</Message.Content>
                        </Message>
                      </Transition>
                      <Divider hidden />
                    </React.Fragment>
                    : null} */}
                  <Form onSubmit={handleSubmit} noValidate autoComplete='off'>


                    <Form.Group>
                      <Form.Field width={4}>
                        <Form.Input
                          name="CEName" type="text"
                          label='Client (Entity) Name'
                          loading={this.props.fetch.fetching}
                          required={this.state.clienttype === 'entity' || this.state.clienttype === ''}
                          placeholder='Client Name'
                          error={(errors.CEName && touched.CEName) || (has(status, 'errors.CEName'))}
                          onChange={async (e) => {
                            await handleChange(e);
                            this.setState({ clienttype: 'entity', CName: e.target.value })
                            this.switchFormType(setFieldValue, values)
                            //This function is to add the duplicate clients list
                            this.debouncedFilter(e.target.value, filterCENameInput, setStatus, this.props.clients.clientsarray, { CEName: 'Name already used' })
                            // var { updatedListGeneralSorted, updatedListSpecific } = await filterCENameInput(this.props.clients.clientsarray, e.target.value)
                            // this.setState({ CEName2Regex: updatedListGeneralSorted })
                            // if (updatedListSpecific.length > 0) {
                            //   this.setState({ CEName2RegexSpecific: updatedListSpecific })
                            //   setStatus({ errors: { CEName: 'Name already used' } })
                            // }
                            // else {
                            //   this.setState({ CEName2RegexSpecific: '' })
                            //   setStatus({ errors: {} })
                            // }
                            // if (this.state.CEName2RegexSpecific.length > 0) {
                            //   setStatus({ errors: { CEName: 'Name already used' } })
                            // }
                            // else {
                            //   setStatus({ errors: {} })
                            // }
                          }}
                          onBlur={handleBlur}
                          value={values.CEName}
                        />

                        {this.errorTag(errors, touched, status, 'CEName')}

                      </Form.Field>

                    </Form.Group>

                    <Form.Group>
                      <Form.Field width={4}>
                        <Form.Input
                          //autoComplete='off'
                          name="CFName" type="text"
                          label='Client First Name'
                          required={this.state.clienttype === 'person' || this.state.clienttype === ''}
                          placeholder='First Name'
                          loading={this.props.fetch.fetching}
                          error={(errors.CFName && touched.CFName) || (has(status, 'errors.CFName'))}
                          onChange={async (e) => {
                            await handleChange(e);
                            this.setState({ clienttype: 'person', CName: values.CLName + ', ' + e.target.value });
                            this.switchFormType(setFieldValue, values)

                            //This function is to add the duplicate clients list
                            this.debouncedFilter([e.target.value, values.CLName], filterCFNameInput, setStatus, this.props.clients.clientsarray, { CFName: 'Name already used', CLName: 'Name already used' })

                            // var { updatedListGeneralSorted, updatedListSpecific } = await filterCFNameInput(this.props.clients.clientsarray, [e.target.value, values.CLName])
                            // // console.log(`This is updatedListGeneralSorted: ${updatedListGeneralSorted}`)
                            // this.setState({ CEName2Regex: updatedListGeneralSorted })
                            // if (updatedListSpecific.length > 0) {
                            //   this.setState({ CEName2RegexSpecific: updatedListSpecific })
                            //   setStatus({ errors: { CFName: 'Name already used', CLName: 'Name already used' } })
                            // }
                            // else {
                            //   this.setState({ CEName2RegexSpecific: '' })
                            //   setStatus({ errors: {} })
                            // }
                          }}
                          onBlur={handleBlur}
                          value={values.CFName}
                        />

                        {this.errorTag(errors, touched, status, 'CFName')}

                      </Form.Field>

                      <Form.Field width={4}>
                        <Form.Input
                          //autoComplete='off'
                          name="CLName" type="text"
                          label='Client Last name'
                          required={this.state.clienttype === 'person' || this.state.clienttype === ''}
                          placeholder='Last Name'
                          //disabled={!values.CFName.length > 0}
                          error={(errors.CLName && touched.CLName) || (has(status, 'errors.CLName'))}
                          value={values.CLName}
                          loading={this.props.fetch.fetching}
                          onChange={async (e) => {
                            await handleChange(e);
                            this.setState({ clienttype: 'person', CName: e.target.value + ', ' + values.CFName });
                            this.switchFormType(setFieldValue, values)

                            //This function is to add the duplicate clients list
                            this.debouncedFilter([values.CFName, e.target.value], filterCLNameInput, setStatus, this.props.clients.clientsarray, { CFName: 'Name already used', CLName: 'Name already used' })

                            // var { updatedListGeneralSorted, updatedListSpecific } = await filterCLNameInput(this.props.clients.clientsarray, [values.CFName, e.target.value])
                            // // console.log(`This is updatedListGeneralSorted: ${updatedListGeneralSorted}`)
                            // this.setState({ CEName2Regex: updatedListGeneralSorted })
                            // if (updatedListSpecific.length > 0) {
                            //   this.setState({ CEName2RegexSpecific: updatedListSpecific })
                            //   setStatus({ errors: { CFName: 'Name already used', CLName: 'Name already used' } })
                            // }
                            // else {
                            //   this.setState({ CEName2RegexSpecific: '' })
                            //   setStatus({ errors: {} })
                            // }
                          }}
                          onBlur={handleBlur}
                        />

                        {this.errorTag(errors, touched, status, 'CLName')}

                      </Form.Field>
                    </Form.Group>

                    <Segment secondary={this.state.CEName2RegexSpecific.length === 0} color={this.state.CEName2RegexSpecific.length > 0 ? 'red' : 'blue'}
                      hidden={this.state.CEName2Regex.length === 0}>
                      {ListMaker(this.state.CEName2Regex, values, this.state.CEName2RegexSpecific, this.state.clienttype)}
                    </Segment>

                    {/* <Label basic attached='top' >Select an attorney <span style={{fontWeight: 'bold', color: 'red'}}>*</span> <br />                      </Label> */}
                    <Form.Select
                      placeholder='Select Attorney'
                      name="PartnerPick"
                      width={2}
                      required
                      label='Originating Partner'
                      error={(touched.PartnerPick && (typeof get(values, 'PartnerPick') === 'undefined' || get(values, 'PartnerPick') === null)) ||
                        (has(status, 'errors.PartnerPick'))}
                      closeOnBlur
                      selection
                      onBlur={() => setFieldTouched('PartnerPick', true)}
                      options={this.employeeDropDownArray()}
                      value={values.PartnerPick}
                      loading={this.props.fetch.fetching}
                      onChange={(e, { name, value }) => {
                        setFieldValue(name, value)
                        //console.log(name, value)
                      }}
                    />

                    {this.errorTag(errors, touched, status, 'PartnerPick')}

                    <Divider hidden fitted />

                    <Form.Field required width={6} error={(touched.CDate && isNaN(values.CDate)) || has(status, 'errors.CDate')}>
                      {/* <Button onClick={e=> this.onClickButton(e, status)}>click here</Button> */}
                      <label>Origination Date</label>


                      <DatePicker
                        name="CDate"
                        // onSelect={async (date) => {
                        //   //console.log('We have selected this date: ', date)
                        //   setFieldValue('CDate', date)
                        //   setFieldTouched('CDate', true)
                        //   //I cannot console.log the new values.CDate here.  I get the old date.  Can't figure out how to make that work.
                        //   // console.log('And this is values: ', values.CDate)
                        // }}
                        onChange={async (dateString) => {
                          // let testdate = new Date(dateString)
                          // console.log('this is testdate: ', (Boolean(+date) && date.getDate() == day))
                          // await setFieldTouched('CDate', false)
                          // await setFieldValue('CDate', dateString, false)
                          // console.log('Changed the date to: ', values.CDate)
                          // console.log(dateString)
                          await setFieldValue('CDate', dateString)
                        }}

                        // onChange={() => console.log(values.CDate)}
                        dateFormat='MMMM d, yyyy'
                        minDate={new Date(2011, 0, 1)}
                        maxDate={new Date()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        withPortal
                        loading={this.props.fetch.fetching}
                        onBlur={() => setFieldTouched('CDate', true)}
                        todayButton={'Today'}
                        selected={values.CDate !== 'a' ? values.CDate : null}
                        placeholderText="Select an origination date"
                      />

                    </Form.Field>

                    {this.errorTag(errors, touched, status, 'CDate', 'Pick a Date. ', 'Pick a Date. ')}

                    <Form.Field required width={6} error={(touched.EngagementLetter && isNaN(values.EngagementLetter)) || has(status, 'errors.EngagementLetter')}>
                      {/* <Button onClick={e=> this.onClickButton(e, status)}>click here</Button> */}
                      <label>Engagement Letter Obtained</label>


                      <Checkbox
                        name="EngagementLetter"
                        // onSelect={async (date) => {
                        //   //console.log('We have selected this date: ', date)
                        //   setFieldValue('CDate', date)
                        //   setFieldTouched('CDate', true)
                        //   //I cannot console.log the new values.CDate here.  I get the old date.  Can't figure out how to make that work.
                        //   // console.log('And this is values: ', values.CDate)
                        // }}
                        onChange={() => {
                          setFieldValue('EngagementLetter', !values.EngagementLetter)
                        }}

                        // onChange={() => console.log(values.CDate)}
                        checked={values.EngagementLetter}
                        onBlur={() => setFieldTouched('EngagementLetter', true)}
                        selected={values.CDate !== 'a' ? values.EngagementLetter : null}
                      />

                    </Form.Field>

                    {this.errorTag(errors, touched, status, 'EngagementLetter', 'Pick a Value. ', 'Pick a Value. ')}

                    <Divider />
                    <Button.Group size='large'>
                      <Button type='button' icon='cancel'
                        onClick={() => {
                          handleReset();
                          this.setState({ clienttype: '', CName: '', fetcherrors: [], fetchmessage: '', CEName2Regex: [], CEName2RegexSpecific: '' });
                          setFieldValue('PartnerPick', null)
                        }}
                        color='red'
                        content='Cancel'></Button>
                      <Button.Or />
                      <Button color='teal'
                        onClick={() => { this.setState({ clienttype: '', CName: '', CEName2Regex: [], CEName2RegexSpecific: '' }); handleReset(); setFieldValue('PartnerPick', null) }}
                        type='button'
                        disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Form'>
                      </Button>
                      <Button.Or />
                      <Button color='orange'
                        onClick={() => {
                          setStatus({})
                          setErrors({})
                        }}
                        type='button'
                        //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Error Notifications'>
                      </Button>
                      <Button.Or />
                      <Button type='submit'
                        // disabled={isSubmitting}
                        //Eventually use the below, the one above is just for testing
                        disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                        icon='upload'
                        loading={isSubmitting}
                        floated='right'
                        color={dirty ? 'green' : 'grey'}
                        content={(this.props.update === true ? 'Edit' : 'Add') + ' Client'}></Button>
                    </Button.Group>
                    {/* <DisplayState state={this.state} />
                    <DisplayFormikState /> */}
                  </Form>
                </Segment>
              </div>
            )}
        </Formik>

      </div>
    )
  }
}


const ClientAddEntitySchema = Yup.object().shape({
  CEName: Yup
    .string()
    .min(3, 'Client (entity) name must be at least 3 characters.')
    .max(50, 'Client (entity) name cannot exceed 50 characters.'),
  CFName: Yup
    .string()
    .min(3, 'Client first name must be at least 3 characters.')
    .max(50, 'Client first name cannot exceed 50 characters.'),
  CLName: Yup
    .string()
    .min(3, 'Client last name must be at least 3 characters.')
    .max(50, 'Client last name cannot exceed 50 characters.'),
  PartnerPick: Yup
    .number()
    .required()
    .typeError('Must pick an originating partner.'),
  //Fix:  Cannot understand why I cannot get date to validate.
  // CDate: Yup
  //   .date()
  //   .required()
  //   .default(() => (new Date()))
  // .typeError('No date selected .'),
})




const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(AddClient)




