import React from 'react'
import {  Icon, Modal } from 'semantic-ui-react';
import AddBill from '../pages/Component-AddBill'

export default function BillModal(props) {

  const { 
    closeModal = () => { },
    clearBill = () => { },
    isOpen = false,
    // deleteSubject = 'Bill',
    lawfirm = 'unknown law firm',
    counterparty = 'unknown counterparty',
    counterparty_matter_id = null,
    matter_deal_amount = null,
    billID = null,
    getBills = () => { },
    update = false,
    billType = 'Legal',
  } = props

  // const [modalOpen, setmodalOpen] = useState(isOpen)

  // // console.log('Here in COnfirmModal and this is modalOpen: ', modalOpen)

  // React.useEffect(
  //   () => { setmodalOpen(isOpen) }, [isOpen]
  // )

  return (
    <Modal
      open={isOpen}
      onClose={() => closeModal()}
      // basic
      size='small'
      centered={true}
    >
      <Modal.Header ><Icon name='inbox' />{update? 'Edit Bill' : 'Create Bill'}</Modal.Header>
      <Modal.Content>
        <h3>{update? 'Edit' : 'Create'} bill for {lawfirm} (representing {counterparty})?</h3>
        <AddBill
          CMID={counterparty_matter_id}
          matter_deal_amount={matter_deal_amount}
          billType={billType}
          getBills={getBills}
          update={update}
          billID={billID}
          clearBill={clearBill}
        />
      </Modal.Content>
      {/* <Modal.Actions>
        <Button color='green' onClick={() => confirmModal()} inverted>
          <Icon name='checkmark' />Confirm
            </Button>
        <Button color='red' onClick={() => closeModal()} inverted>
          <Icon name='remove' />Cancel
            </Button>
      </Modal.Actions> */}
    </Modal>
  )
}
