import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'

import { isEmptyNullUndefined } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';



class Payments extends Component {

  state = {
    Payments: [],
    selectedTType: null,
    modalOpen: false,
    Row: {},
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getPayments()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getPayments(), 200)
    }
  }


  getPayments = async () => {

    this.props.fetchResetState('all')
    // console.log('Fetching for this client: ', client_id)
    let fetchresponse = await szenokPostAPI('getAllPayments', { fetchstr: 'nothing' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getAllPayments: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    this.setState({
      Payments: fetchresponse.payload.map(payment => {
        var arr = {}
        arr.payment_id = payment.payment_id
        arr.payment_number = payment.payment_number
        arr.payment_amount = payment.payment_amount
        arr.payment_date = payment.payment_date
        arr.payment_description = payment.payment_description
        arr.invoice_number = payment.invoice_number
        arr.invoice_id = payment.invoice_id
        arr.matter_name = payment.matter_name
        arr.matter_id = payment.matter_id
        arr.client_name = payment.client_name
        arr.client_id = payment.client_id
        return arr
      }
      )
    })
  }

  handleDeletePayment = async (payment_id) => {
    // console.log('Running handleDeletePayment...')
    // console.log(`This is payment_id: ${payment_id}`)
    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deletePayment', {
        paymentID: payment_id
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deletePayment: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getPayments()
        reduxupdate('payments')

      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }
  setRow = (value) => {
    if (!isEmptyNullUndefined(value)) this.setState({ Row: { payment_id: value.values.payment_id, payment_number: value.values.payment_number } })
    else this.setState({ Row: {} })
  }

  render() {

    return (
      <div>
        <h1> <Icon name='money bill alternate outline' size='large' circular /> Payments</h1>

        <ReactTableMaker passedData={this.state.Payments} passedColumns={PaymentsColumns(this.handleDeletePayment, this.props.history, this.setModalOpen, this.setRow, !!this.props.login.currentObserver)}
          handleClick={(rowID, cellID, rowValues) => {
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
            this.setState({ selectedTType: rowID })
            // this.getPaymentsForInvoice(rowID)
          }}
          includeSearchBar={false}
        />


        {/* This is the "are you sure" modal for deleting a row */}
        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              this.handleDeletePayment(this.state.Row.payment_id)
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Payment'
            deleteObject={'payment number: ' + this.state.Row.payment_number}
          />}

        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payments))

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
function minusFontColor(e) { e.target.style.color = "" }

const PaymentsColumns = (handleDeletePayment, history, setModalOpen, setRow, observer) => [
  // {
  //   Header: () => <span style={{ 'float': 'left' }}>Payment ID</span>,
  //   accessor: 'payment_id',
  //   show: true,
  //   width: 0,
  //   sortType: 'alphanumeric',
  //   Filter: 'defaultColumnFilterLeft'
  // },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Payment Number</span>,
    accessor: 'payment_number',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell, row }) =>
      <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        style={{ 'float': 'left', cursor: 'pointer' }}
        onClick={() => history.push(`/payment/${row.original.payment_id}?payment=${row.original.payment_number}&paymentID=${row.original.payment_id}`)}
      >
        {cell.value}
      </span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'right' }}>Payment Amount</span>,
    accessor: 'payment_amount',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'color': 'green', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Payment Date</span>,
    accessor: 'payment_date',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Payment Description</span>,
    accessor: 'payment_description',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Number</span>,
    accessor: 'invoice_number',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell, row }) =>
      <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        style={{ 'float': 'left', cursor: 'pointer' }}
        onClick={() => history.push(`/invoice/${row.original.invoice_id}?invoice=${row.original.invoice_number}&invoiceID=${row.original.invoice_id}`)}
      >
        {cell.value}
      </span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client</span>,
    accessor: 'client_name',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell, row }) =>
      <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        style={{ 'float': 'left', cursor: 'pointer' }}
        onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
      >
        {cell.value}
      </span>
  },
  {
    Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
    accessor: 'matter_name',
    show: true,
    sortType: 'alphanumeric',
    Cell: ({ cell, row }) =>
      <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        style={{ 'float': 'left', cursor: 'pointer' }}
        onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
      >
        {cell.value}
      </span>
  },
  {
    // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
    accessor: 'payment_id',
    // show: true,
    // sortType: 'alphanumeric',
    Filter: '',
    Cell: ({ cell }) => !observer &&  <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
      <Link to={`editpayment/${cell.value}`}><Icon name='edit' size='small' bordered />Edit</Link></span>
  },
  {
    // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
    accessor: 'payment_id2',
    // show: true,
    // sortType: 'alphanumeric',
    Filter: '',
    Cell: ({ cell, row }) => !observer &&  <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
      <Link to={''} onClick={(e) => {
        e.preventDefault()
        setModalOpen(true)
        setRow(row)
        // handleDeletePayment(row.values.payment_id)
      }}>
        <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
  },

]

