
export function fetchStart() {
  // console.log('In fetchStart')
  return {
    type: "FETCH_START",
  }
}

export function fetchEnd() {
  // console.log('In fetchEnd')
 return {
   type: "FETCH_END",
 }
}

export function fetchSuccessStatus(truefalse) {
  // console.log('In fetchErrors and this is errorsObj: ', errorsObj)
  return {
    type: "FETCH_SUCCESS_STATUS",
    payload: truefalse
  }
}

export function fetchSuccessMessage(successstring) {
  // console.log('In fetchErrors and this is errorsObj: ', errorsObj)
  return {
    type: "FETCH_SUCCESS_MESSAGE",
    payload: successstring
  }
}

export function fetchErrors(errorsObj) {
  // console.log('In fetchErrors action and this is errorsObj: ', errorsObj)
  // console.log('In fetchErrors and checking if array: ', Array.isArray(errorsObj))
  return {
    type: "FETCH_ERRORS",
    payload: errorsObj
  }
}

export function fetchInfo(infoObj) {
  // console.log('In fetchInfo and this is infoObj: ', infoObj)
  return {
    type: "FETCH_INFO",
    payload: infoObj
  }
}

export function fetchResetState(type) {
  // console.log('In fetchResetState')
  return {
    type: "FETCH_RESET",
    payload: type
  }
}