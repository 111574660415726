import React, { Component } from 'react'
import {  Link } from 'react-router-dom'

// import { HashLink as Link } from 'react-router-hash-link'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon, Table } from 'semantic-ui-react';

// import ReactTableMaker from '../helper/ReactTableMaker'


import queryString from 'query-string'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

class PaymentInfo extends Component {

  state = {
    TokenReady: false,
    Loaded: false,
    querystr: {},
    PaymentInfo: {},
    PaymentInfoArray: [],
  }


  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
    }
    const querystr = queryString.parse(this.props.location.search)
    // console.log('Logging querystr: ', querystr)
    this.setState({ querystr: querystr })
  }


  // //This is to get rid of the unmounted component no op (as well as that signal: this.abortController stuff below))
  // abortController = new AbortController()
  // componentWillUnmount() {
  //   this.abortController.abort()
  // }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true })
    }
    if (prevState.querystr !== this.state.querystr) {
      this.getPayment()
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getPayment(), 200)
    }
  }

  getPayment = async () => {

    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('paymentInfo', {
      fetchstr: 'getPaymentWithDetails',
      paymentID: this.state.querystr.paymentID
    },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getPayment: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      PaymentInfo: fetchresponse.payload
    }
      , () => this.setState({ PaymentInfoArray: this.createPaymentInfo() })
    )

  }

  createPaymentInfo = () => {
    let infoarray = [...Array(7)].map(x => Array(3).fill(0))
    infoarray[0][0] = 'Invoice Number:'
    infoarray[0][1] = this.state.PaymentInfo.invoice_number
    infoarray[0][2] = 'invoice_number'
    infoarray[0][3] = this.state.PaymentInfo.invoice_id
    infoarray[1][0] = 'Payment Amount:'
    infoarray[1][1] = this.state.PaymentInfo.payment_amount
    infoarray[1][2] = 'payment_amount'
    infoarray[1][3] = this.state.PaymentInfo.payment_id
    infoarray[2][0] = 'Payment Date:'
    infoarray[2][1] = this.state.PaymentInfo.payment_date
    infoarray[2][2] = 'payment_date'
    infoarray[2][3] = this.state.PaymentInfo.payment_id
    infoarray[3][0] = 'Payment Description:'
    infoarray[3][1] = this.state.PaymentInfo.payment_description
    infoarray[3][2] = 'payment_description'
    infoarray[3][3] = this.state.PaymentInfo.payment_id
    infoarray[4][0] = 'Matter:'
    infoarray[4][1] = this.state.PaymentInfo.matter_name
    infoarray[4][2] = 'matter_name'
    infoarray[4][3] = this.state.PaymentInfo.matter_id
    infoarray[5][0] = 'Originating Partner:'
    infoarray[5][1] = this.state.PaymentInfo.originating_employee
    infoarray[5][2] = 'originating_employee'
    infoarray[5][3] = this.state.PaymentInfo.invoice_id
    infoarray[6][0] = 'Invoicing Partner:'
    infoarray[6][1] = this.state.PaymentInfo.invoicing_employee
    infoarray[6][2] = 'invoicing_employee'
    infoarray[6][3] = this.state.PaymentInfo.invoice_id

    return infoarray
  }

  render() {
    return (
      <div>
        <h1 style={{ float: 'left' }}>Payment Information For Payment:</h1>
        <h2 style={{ marginLeft: '320px' }}>
          <Icon name='dollar sign' size='large' />
          <span style={{ color: '#04337d' }}>
            {this.state.PaymentInfo.payment_number} (ID: {this.state.querystr.paymentID})
        </span>
        </h2>



        <h2>Payment Information:</h2>

        {!isEmptyNullUndefined(this.state.PaymentInfoArray) &&
          <Table basic='very' celled collapsing striped>
            <Table.Body >
              {this.state.PaymentInfoArray.map((item, i) => {
                return <PaymentInfoMaker rowdata={item}
                  number={i}
                  invoiceID={this.state.querystr.invoiceID}
                  // handleAClick={this.props.handleClick}
                  key={i}
                  history={this.props.history}
                />
              })}
            </Table.Body>
          </Table>
        }


        {/* <DisplayState state={this.state} /> */}

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo)


const PaymentInfoMaker = (props) => {
  // console.log('In RowMaker and here are props: ', props.rowdata[2])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  // function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
  function plusFontColor(e) { e.target.style.color = "#ff8000" }
  // function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
  function minusFontColor(e) { e.target.style.color = "" }

  return (
    <Table.Row key={props.rowdata[2] + props.number}>
      <Table.Cell>
        {props.rowdata[0]}
      </Table.Cell >
      <Table.Cell>
        <React.Fragment>
          {props.rowdata[2] === 'matter_name' ?
            <span
              style={{ 'float': 'left', cursor: 'pointer' }}
              onMouseOver={plusFontColor} onMouseLeave={minusFontColor}
              onClick={() => props.history.push(`/matter/${props.rowdata[3]}?matter=${props.rowdata[1]}&matterID=${props.rowdata[3]}`)}
            >
              {props.rowdata[1]}
            </span> :
            props.rowdata[2] === 'invoice_number' ?
              <span
                style={{ 'float': 'left', cursor: 'pointer' }}
                onMouseOver={plusFontColor} onMouseLeave={minusFontColor}
                onClick={() => props.history.push(`/invoice/${props.rowdata[3]}?invoice=${props.rowdata[1]}&invoiceID=${props.rowdata[3]}`)}
              >
                {props.rowdata[1]}
              </span> :
              props.rowdata[2] === 'payment_amount' ?
                formatter.format(props.rowdata[1])
                :
                props.rowdata[1]}
        </React.Fragment>
      </Table.Cell>
      <Table.Cell>
        <Link to={`/editpayment/${props.rowdata[3]}`}><Icon name='edit' />Edit</Link>
      </Table.Cell>
    </Table.Row >
  )
}
