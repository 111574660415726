import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import { connect } from 'react-redux';

import { Icon, Menu, Divider, Table, Image, Grid } from 'semantic-ui-react';

import Invoices from './Component-Invoices';
import Precedents from './Component-Precedents';
import ClientDashboard from './Component-ClientInfoDashboard';
// import LocationsInfo from './Component-LocationsInfo'

import queryString from 'query-string'

// import DisplayState from '../helper/DisplayState';


// import ErrorMessage from '../helper/MessageBar'
// import TableMaker from '../helper/TableMaker.js'
import ReactTableMaker from '../helper/ReactTableMaker'

// import SeachBarMaker from '../helper/SearchBarMaker.js'
import { isEmptyNullUndefined } from '../helper/Misc';

// import USAMap from "react-usa-map"
import { StatesGetAllStates } from '../charts/Charts-AClass-Functions'
import Map from '../charts/Charts-RSM-Map'
import allStates from '../charts/allstates.json'

class ClientInfo extends Component {

  state = {

    fetcherrors: [],
    fetchinfo: [],
    fetchmessage: '',
    FilteredSearchData: [],
    MatterInfo: [],
    Data: [],
    Message: '',
    TokenReady: false,
    Loaded: false,
    selectedClient: null,
    ClientInfo: [],
    querystr: '',
    activeMenuItem: 'Basic Info',
    Locations: [],
  }

  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      // this.getTable()
    }
    const querystr = queryString.parse(this.props.location.search)
    this.setState({ querystr: querystr })
  }

  componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    //This on
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true, FilteredSearchData: this.state.Data })
    }
    if (prevState.querystr !== this.state.querystr) {
      // console.log('Here is aClassID.', this.state.querystr.aClassID)
      this.getClientInfo()
      this.getMatters()
      this.getLocations()
      StatesGetAllStates(this.props.maxYears, this.props.login.currentToken, (value) => this.setState({
        States: value.map(item => ({
          ...item,
          amount: 0,
          topoID: parseInt(allStates.find(topo => topo.id === item.state_abbr).val)
        })),
        Loading: false
      }))
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => {
        this.getClientInfo()
        this.getMatters()
        this.getLocations()
      }, 200)
    }
  }

  getClientInfo = async () => {
    // console.log('Launching getTable...')
    let fetchresponse = await szenokPostAPI('clientInfo', { fetchstr: 'getClientInfoWithStates', clientID: this.props.match.params.clientID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for mattersforclient: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      ClientInfo: fetchresponse.payload
    })
  }

  getMatters = async () => {
    // console.log('Launching getTable...')
    const fetchresponse = await szenokPostAPI('matterInfo', { fetchstr: 'getMattersForClientWithStates', clientID: this.props.match.params.clientID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for mattersforclient: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      MatterInfo: fetchresponse.payload.map(item => {
        return {
          ...item,
          // employee_name: this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[2]
        }
      }
      )
    })
  }

  getLocations = async () => {
    // console.log('Launching getTable...')
    const fetchresponse = await szenokPostAPI('getStates', { fetchstr: 'locationsForSpecificClient', IDNumber: this.props.match.params.clientID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for mattersforclient: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Locations: fetchresponse.payload.map(item => {return {...item, latitude: item.location.x, longitude: item.location.y}})
    })
  }


  handleItemClick = (e, { name }) => this.setState({ activeMenuItem: name })

  render() {

    let chartWidth = 350
    let chartHeight = 250

    return (
      <div>

        <h1 style={{ float: 'left' }}>Client Information For:</h1>
        <h2 style={{ marginLeft: '320px' }}>
          <Icon name='chess queen' size='large' />
          <span style={{ color: '#04337d' }}>
            {this.state.querystr.cname} (ID: {this.props.match.params.clientID})
        </span>
        </h2>

        <Divider />
        <Menu tabular size='large' fluid compact pointing stackable >
          <Menu.Item
            name='Basic Info'
            active={this.state.activeMenuItem === 'Basic Info'}
            onClick={this.handleItemClick}
            color='blue'
          />
          <Menu.Item
            name='Dashboard Info'
            active={this.state.activeMenuItem === 'Dashboard Info'}
            onClick={this.handleItemClick}
            color='green'
            icon='drivers license'
          />
          {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&

            <Menu.Item
              name='Invoice Info'
              active={this.state.activeMenuItem === 'Invoice Info'}
              onClick={this.handleItemClick}
              color='purple'
              icon='dollar sign'
            />
          }
          <Menu.Item
            name='Precedents'
            active={this.state.activeMenuItem === 'Precedents'}
            onClick={this.handleItemClick}
            color='olive'
            icon='eye'
          />
        </Menu>

        {this.state.activeMenuItem === 'Basic Info' &&

          <React.Fragment>

            <h2 id='BasicInfo'>Client Basic Information:</h2>

            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Table celled collapsing striped color='black'>
                    <Table.Body >
                      <Table.Row>
                        <Table.Cell>Origination Date:</Table.Cell>
                        <Table.Cell>{!isEmptyNullUndefined(this.state.ClientInfo) ? this.state.ClientInfo.origination_date : ''}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Originating Partner:</Table.Cell>
                        <Table.Cell>{!isEmptyNullUndefined(this.state.ClientInfo) ?
                          <React.Fragment>
                            {/* <Image src={window.location.origin + '/images/' + this.props.clients.employeesarray.find(employee => employee[0] === this.state.ClientInfo.employee_id)[4]} verticalAlign='bottom' width='20px' /> */}
                            <Image src={this.props.clients.employeesarray.find(employee => employee[0] === this.state.ClientInfo.employee_id)[4]} verticalAlign='bottom' width='20px' />
                            {' '}
                            {this.state.ClientInfo.employee}
                          </React.Fragment>
                          : ''}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Engagement Letter Obtained:</Table.Cell>
                        <Table.Cell>{!isEmptyNullUndefined(this.state.ClientInfo) ? this.state.ClientInfo.engagement_letter ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' /> : ''}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><Icon name='map' />States</Table.Cell>
                        <Table.Cell>{this.state.ClientInfo.states}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>{!isEmptyNullUndefined(this.state.ClientInfo) ? <Link to={`/editclient/${this.state.ClientInfo.client_id}`}><Icon name='edit' />Edit</Link> : ''}</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column width={8}>
                  <svg
                    // style={{background: '#f3f'}}
                    // onClick={() => toIMGcanvas('States')}
                    height={chartHeight}
                    width={chartWidth}
                    viewBox={`0 5 ${100} ${75}`}
                  >
                    <Map
                      selectedYear={0}
                      currentYear={this.state.currentYear}
                      selectedPartner={0}
                      Matters={this.state.MatterInfo}
                      States={this.state.States}
                      mapHandler={this.mapHandler}
                      chartWidth={chartWidth}
                      chartHeight={chartHeight}
                      simpleMap={true}
                    />
                  </svg>
                </Grid.Column>
                {/* <Grid.Column width={6}>
                  <LocationsInfo Locations={this.state.Locations} addressOnly={true} simple={true}/>
                </Grid.Column> */}
              </Grid.Row>
            </Grid>



            <h2><Icon name='chess bishop' size='large' />Matters:</h2>

            {isEmptyNullUndefined(this.state.MatterInfo) ? <h2>No matters created for this client yet.</h2> : null}

            {!isEmptyNullUndefined(this.state.MatterInfo) &&
              <ReactTableMaker passedData={this.state.MatterInfo} passedColumns={MattersColumns(this.props.history)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
              />
            }
          </React.Fragment>
        }

        {this.state.activeMenuItem === 'Dashboard Info' &&

          <ClientDashboard clientID={this.props.match.params.clientID} ClientInfo={this.state.ClientInfo} matters={this.state.MatterInfo} Locations={this.state.Locations}/>

        }

        {this.state.activeMenuItem === 'Invoice Info' &&

          <Invoices clientID={this.state.querystr.clientID} />
        }

        {this.state.activeMenuItem === 'Precedents' &&

          <Precedents clientID={this.state.querystr.clientID} />
        }

        {/* <DisplayState state={this.state} /> */}


      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(loginActions, dispatch) }

export default connect(mapStateToProps, mapDispatchToProps)(ClientInfo)


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

let MattersColumns = (history) => {
  return [

    {
      Header: () => <span style={{ 'float': 'left' }}>Matter Name</span>,
      accessor: 'matter_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Origination Date</span>,
      accessor: 'matter_origination_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Deal Amount</span>,
      accessor: 'matter_deal_amount',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell }) => <span style={{ 'color': cell.value < 0 ? 'red' : 'black', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Transaction Type</span>,
      accessor: 'transaction_type',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'right', cursor: 'pointer' }}
          onClick={() => history.push(`/transactiontype/${cell.value}?ttype=${cell.value}&TTypeID=${row.original.transaction_type_id}`)}
        // onClick={() => console.log('Yo, this is the value: ', cell.value)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Sub-Transaction Type</span>,
      accessor: 'sub_transaction_type',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'right', cursor: 'pointer' }}
          onClick={() => history.push(`/transactiontype/${row.original.transaction_type_id}?ttype=${row.original.transaction_type}&TTypeID=${row.original.transaction_type_id}`)}
        // onClick={() => console.log('Yo, this is the value: ', cell.value)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Asset Class</span>,
      accessor: 'asset_class',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'right', cursor: 'pointer' }}
          onClick={() => history.push(`/assetclass/${cell.value}?aclass=${cell.value}&aClassID=${row.original.asset_class_id}`)}
        // onClick={() => console.log('Yo, this is the value: ', cell.value)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>States</span>,
      accessor: 'states',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },
  ]
}