import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon, Checkbox } from 'semantic-ui-react';

import DeletePayment from './Component-DeletePayment'
// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'

import { isEmptyNullUndefined } from '../helper/Misc'
import './Component-Invoices.css'

// import DisplayState from '../helper/DisplayState';

let initialDeleteInvoice = {
  PaymentsExist: true,
  InvoicesExist: true,
}

class Invoices extends Component {

  state = {
    Invoices: [],
    selectedInvoice: null,
    selectedMatter: null,
    selectedClient: null,
    DeleteInvoice: initialDeleteInvoice,
    modalOpen: false,
    Row: {},
    detailedView: false,
    // DetailedInvoicesLoaded: [],
    // DetailedInvoices: [],
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getInvoicesOutstanding()
    // this.getDetailedInvoicesOutstanding()
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.clientID !== this.props.clientID) {
      this.props.fetchResetState('all')
      this.getInvoicesOutstanding()
    }
    if (prevProps.matterID !== this.props.matterID) {
      this.props.fetchResetState('all')
      this.getInvoicesOutstanding()
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getInvoicesOutstanding(), 200)
    }
    if (prevState.detailedView !== this.state.detailedView) {
      if (this.state.detailedView) this.setState({ DetailedInvoices: this.state.DetailedInvoicesLoaded })
      else this.setState({ DetailedInvoices: [] })
    }
  }

  getInvoicesOutstanding = async () => {

    this.props.fetchResetState('all')
    // console.log('Fetching for this matter: ', this.props.matterID)
    let fetchresponse = {}
    if (isEmptyNullUndefined(this.props.matterID) && isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('invoiceInfo', { fetchstr: 'getDetailedInvoicesOutstanding' },
        this.props.login.currentToken)
    }
    //This version is if we have a clientInfo
    else if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('invoiceInfo', { fetchstr: 'getInvoicesForClient', clientID: parseInt(this.props.clientID) },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for getInvoicesForClient: ', fetchresponse)
    }
    //This second version is if we're in MatterInfo
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      fetchresponse = await szenokPostAPI('invoiceInfo', { fetchstr: 'getInvoicesForMatter', matterID: parseInt(this.props.matterID) },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for getInvoicesForMatter: ', fetchresponse)
    }
    //Set ExistingMatters to the other matters for the loaded client
    this.setState({
      Invoices: fetchresponse.payload
    })
  }

  // getDetailedInvoicesOutstanding = async () => {

  //   this.props.fetchResetState('all')
  //   // console.log('Fetching for this matter: ', this.props.matterID)
  //   let fetchresponse = {}
  //   fetchresponse = await szenokPostAPI('invoiceInfo', { fetchstr: 'getDetailedInvoicesOutstanding' },
  //     this.props.login.currentToken)

  //   console.log('Now printing fetchresponse for getDetailedInvoicesOutstanding: ', fetchresponse)
  //   //Set ExistingMatters to the other matters for the loaded client
  //   this.setState({
  //     DetailedInvoicesLoaded: !isEmptyNullUndefined(fetchresponse.payload) ? fetchresponse.payload : [],
  //   })
  // }

  setStateInvoice = (invoice_id, callback) => {
    // console.log('In setStateInvoice and this is invoice_id: ', invoice_id)
    this.setState({ DeleteInvoice: initialDeleteInvoice })
    this.setState({ selectedInvoice: invoice_id }, () => { if (callback) callback() })
    // if (callback) callback()
  }
  // setStateMatter = (matter_id) => this.setState({ selectedInvoice: matter_id })
  // setStateClient = (client_id) => this.setState({ selectedInvoice: client_id })

  handleDeleteInvoice = async () => {
    // console.log('Running handleDeleteInvoice on this invoice: ', this.state.selectedInvoice)
    // console.log(`This is invoice_id: ${this.state.selectedInvoice}`)

    //First, find any payments for the invoice (and if any then break)
    // await this.getInvoicesOutstanding()
    // let paymentsExist = this.state.Invoices.find(item => item.invoice_id === this.state.selectedInvoice).paid > 0
    // if (paymentsExist) { console.log('There is a problem.  paymentsExist is: ', paymentsExist); return }

    if (!this.state.DeleteInvoice.PaymentsExist) try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      // let fetchresponse = {}
      let fetchresponse = await szenokPostAPI('deleteInvoice', {
        invoiceID: this.state.selectedInvoice
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponsefor handleDeleteInvoice(): ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getInvoicesOutstanding()
        reduxupdate('invoices')
        reduxupdate('payments')
        this.setState({ selectedInvoice: null })
      }
    }
      catch (error) {
        console.log('This is the errors: ', error)
        this.props.fetchErrors(error.toString())
      }
  }

  handleDeletePayment = () => {
    this.setState({ DeleteInvoice: { ...this.state.DeleteInvoice, PaymentsExist: false } })
    this.handleDeleteInvoice()
  }

  handleCancelDelete = () => { this.setState({ selectedInvoice: null }) }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }
  setRow = (value) => {
    if (!isEmptyNullUndefined(value)) this.setState({ Row: { invoice_id: value.values.invoice_id, invoice_number: value.values.invoice_number } })
    else this.setState({ Row: {} })
  }
  render() {

    let displaytype = !isEmptyNullUndefined(this.props.forAddInvoicesMatter) ? 'addInvoices' : !isEmptyNullUndefined(this.props.clientID) ? 'clientinvoices' : !isEmptyNullUndefined(this.props.matterID) ? 'matterinvoices' : this.state.detailedView ? 'detailed' : 'general'

    return (
      <div>
        <div className='invoices-header'>
          <div className='invoices-header-left'>
            {isEmptyNullUndefined(this.props.forAddInvoicesMatter) &&
              (isEmptyNullUndefined(this.props.clientID) && isEmptyNullUndefined(this.props.matterID) ?
                <h1> <Icon name='dollar sign' size='large' circular /> Invoices</h1>
                :
                <h2 className='invoices-header-h2' id='Invoices'><Icon name='dollar sign' size='large' />Invoices:</h2>
              )
            }
          </div>
          <div className='invoices-header-right' onClick={() => this.setState({ detailedView: !this.state.detailedView })}>
            {isEmptyNullUndefined(this.props.forAddInvoicesMatter) && isEmptyNullUndefined(this.props.clientID) && isEmptyNullUndefined(this.props.matterID) &&
              <React.Fragment>
                <Checkbox checked={this.state.detailedView}  />
                <span style={{ paddingLeft: '5px' }}>Detailed View</span>
              </React.Fragment>
            }
          </div>
        </div>

        {this.state.DeleteInvoice.PaymentsExist && (!isEmptyNullUndefined(this.state.selectedInvoice) || !isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeletePayment invoiceID={this.state.selectedInvoice}
            handleDeleteInvoice={this.handleDeleteInvoice}
            handleDeletePayment={this.handleDeletePayment}
            handleCancelDelete={this.handleCancelDelete} />
        }

        {!isEmptyNullUndefined(this.state.Invoices) &&
          <ReactTableMaker passedData={this.state.Invoices}
            passedColumns={InvoicesInfoColumns(displaytype, this.props.history, this.setModalOpen, this.setRow, !!this.props.login.currentObserver, this.props.forAddInvoicesMatter)}
            handleClick={(rowID, cellID, rowValues) => {
              // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
              // this.setState({ selectedTType: rowID })
              // this.getPaymentsForInvoice(rowID)
            }}
            includeSearchBar={false}
            color='purple'
          />
        }

        {/* This is the "are you sure" modal for deleting a row */}
        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              this.setStateInvoice(this.state.Row.invoice_id,
                this.handleDeleteInvoice
              )
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Invoice'
            deleteObject={'Invoice Number: ' + this.state.Row.invoice_number}
          />}

        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invoices))


Invoices.defaultProps = {
  matterID: null,
  forAddInvoicesMatter: null,
}

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const shadeColor = '#ffd7d7'
const InvoicesInfoColumns = (type, history, setModalOpen, setRow, observer, forAddInvoicesMatter) => {
  let results = []
  switch (type) {
    case 'general':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Number</span>,
          accessor: 'invoice_number',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/invoice/${row.original.invoice_id}?invoice=${row.original.invoice_number}&invoiceID=${row.original.invoice_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Date</span>,
          accessor: 'invoice_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Description</span>,
          accessor: 'invoice_description',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Invoice Amount</span>,
          accessor: 'invoice_amount',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'color': 'green', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: () => <span style={{ 'float': 'right' }}>Paid</span>,
          accessor: 'paid',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) =>
            cell.value <= 0 ? <span style={{ 'color': 'red', 'float': 'right' }}>-</span> :
              <div onClick={() => null} > <span style={{ 'color': 'red', 'float': 'right' }}>({new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)})</span> </div>
        },
        {
          Header: () => <span style={{ 'float': 'right' }}>Due</span>,
          accessor: 'due',
          sortType: 'alphanumeric',
          Filter: 'subZeroFilter',
          filter: 'subZeroSearch',
          Cell: ({ cell }) => <span style={{ 'color': cell.value < 0 ? 'red' : 'black', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          accessor: 'invoice_id',
          Filter: '',
          Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} > <Link to={`/editinvoice/${cell.value}`}><Icon name='edit' />Edit</Link></span>
        },
        {
          accessor: 'invoice_id2',
          Filter: '',
          Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} > <Link to={''} onClick={(e) => {
            e.preventDefault()
            setModalOpen(true)
            setRow(row)
          }}>
            <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
        },
      ]
      break;
    case 'detailed':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Asset Class</span>,
          accessor: 'asset_class',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Transaction Type</span>,
          accessor: (d) => `${d.transaction_type} - ${d.sub_transaction_type}`,
          id: 'ttype',
          show: true,
          sortType: 'alphanumeric',
          // Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Matter Amount</span>,
          accessor: 'matter_deal_amount',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Number</span>,
          accessor: 'invoice_number',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/invoice/${row.original.invoice_id}?invoice=${row.original.invoice_number}&invoiceID=${row.original.invoice_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Date</span>,
          accessor: 'invoice_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Description</span>,
          accessor: 'invoice_description',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Invoice Amount</span>,
          accessor: 'invoice_amount',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'color': 'green', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: () => <span style={{ 'float': 'right' }}>Paid</span>,
          accessor: 'paid',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) =>
            cell.value <= 0 ? <span style={{ 'color': 'red', 'float': 'right' }}>-</span> :
              <div onClick={() => null} > <span style={{ 'color': 'red', 'float': 'right' }}>({new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)})</span> </div>
        },
        {
          Header: () => <span style={{ 'float': 'right' }}>Due</span>,
          accessor: 'due',
          sortType: 'alphanumeric',
          Filter: 'subZeroFilter',
          filter: 'subZeroSearch',
          Cell: ({ cell }) => <span style={{ 'color': cell.value < 0 ? 'red' : 'black', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          accessor: 'invoice_id',
          Filter: '',
          Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} > <Link to={`/editinvoice/${cell.value}`}><Icon name='edit' />Edit</Link></span>
        },
        {
          accessor: 'invoice_id2',
          Filter: '',
          Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} > <Link to={''} onClick={(e) => {
            e.preventDefault()
            setModalOpen(true)
            setRow(row)
          }}>
            <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
        },
      ]
      break;
    case 'clientinvoices':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Matter Name</span>,
          accessor: 'matter_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Number</span>,
          accessor: 'invoice_number',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/invoice/${row.original.invoice_id}?invoice=${row.original.invoice_number}&invoiceID=${row.original.invoice_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Date</span>,
          accessor: 'invoice_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Description</span>,
          accessor: 'invoice_description',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Invoice Amount</span>,
          accessor: 'invoice_amount',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'color': 'green', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: () => <span style={{ 'float': 'right' }}>Paid</span>,
          accessor: 'paid',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) =>
            cell.value <= 0 ? <span style={{ 'color': 'red', 'float': 'right' }}>-</span> :
              <div onClick={() => null} > <span style={{ 'color': 'red', 'float': 'right' }}>({new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)})</span> </div>
        },
        {
          Header: () => <span style={{ 'float': 'right' }}>Due</span>,
          accessor: 'due',
          sortType: 'alphanumeric',
          Filter: 'subZeroFilter',
          filter: 'subZeroSearch',
          Cell: ({ cell }) => <span style={{ 'color': cell.value < 0 ? 'red' : 'black', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'invoice_id',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} > <Link to={`/editinvoice/${cell.value}`}><Icon name='edit' />Edit</Link></span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'invoice_id2',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} > <Link to={''} onClick={(e) => {
            e.preventDefault()
            setModalOpen(true)
            setRow(row)
            // setStateInvoice(row.values.invoice_id)
            // handleDeleteInvoice()
          }}>
            <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
        },
      ]
      break;
    case 'addInvoices':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Matter Name</span>,
          accessor: 'matter_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer', backgroundColor: row.original.matter_id === forAddInvoicesMatter ? shadeColor : null }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}dfdf
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Number</span>,
          accessor: 'invoice_number',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer', backgroundColor: row.original.matter_id === forAddInvoicesMatter ? shadeColor : null }}
              onClick={() => history.push(`/invoice/${row.original.invoice_id}?invoice=${row.original.invoice_number}&invoiceID=${row.original.invoice_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Date</span>,
          accessor: 'invoice_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) => <span style={{ 'float': 'left', backgroundColor: row.original.matter_id === forAddInvoicesMatter ? shadeColor : null }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Description</span>,
          accessor: 'invoice_description',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) => <span style={{ 'float': 'left', backgroundColor: row.original.matter_id === forAddInvoicesMatter ? shadeColor : null }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Invoice Amount</span>,
          accessor: 'invoice_amount',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) => <span style={{ 'color': 'green', 'float': 'right', backgroundColor: row.original.matter_id === forAddInvoicesMatter ? shadeColor : null }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: () => <span style={{ 'float': 'right' }}>Paid</span>,
          accessor: 'paid',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            cell.value <= 0 ? <span style={{ 'color': 'red', 'float': 'right' }}>-</span> :
              <div onClick={() => null} > <span style={{ 'color': 'red', 'float': 'right', backgroundColor: row.original.matter_id === forAddInvoicesMatter ? shadeColor : null }}>({new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)})</span> </div>
        },

      ]
      break;
    case 'matterinvoices':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Number</span>,
          accessor: 'invoice_number',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/invoice/${row.original.invoice_id}?invoice=${row.original.invoice_number}&invoiceID=${row.original.invoice_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Date</span>,
          accessor: 'invoice_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Description</span>,
          accessor: 'invoice_description',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Invoice Amount</span>,
          accessor: 'invoice_amount',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'color': 'green', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: () => <span style={{ 'float': 'right' }}>Paid</span>,
          accessor: 'paid',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) =>
            cell.value <= 0 ? <span style={{ 'color': 'red', 'float': 'right' }}>-</span> :
              <div onClick={() => null} > <span style={{ 'color': 'red', 'float': 'right' }}>({new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)})</span> </div>
        },
        {
          Header: () => <span style={{ 'float': 'right' }}>Due</span>,
          accessor: 'due',
          sortType: 'alphanumeric',
          Filter: 'subZeroFilter',
          filter: 'subZeroSearch',
          Cell: ({ cell }) => <span style={{ 'color': cell.value < 0 ? 'red' : 'black', 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'invoice_id',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} > <Link to={`/editinvoice/${cell.value}`}><Icon name='edit' />Edit</Link></span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'invoice_id2',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} > <Link to={''} onClick={(e) => {
            e.preventDefault()
            setModalOpen(true)
            setRow(row)
            // setStateInvoice(row.values.invoice_id)
            // handleDeleteInvoice()
          }}>
            <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
        },
      ]
      break;
    default:
    // code block
  }
  return results
}