import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import SearchResults from '../search/Search-Results'

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { isEmptyNullUndefined } from '../helper/Misc'
import { has, get } from 'lodash';
import isEmpty from 'lodash/isEmpty';

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Grid, Checkbox, Label, Divider, Button, Segment, Icon } from 'semantic-ui-react';

// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';

const initialState = {
  SearchString: null,
  Results: [],
  NoResults: false,
  searchClients: false,
  searchPrecedents: false,
  searchNotesMatters: false,
  searchNotesLawyers: false,
  searchNotesCounterparties: false,
}

class Search extends Component {

  state = initialState

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  toggleAll = (bool) => {
    this.setState({
      searchClients: bool,
      searchPrecedents: bool,
      searchNotesMatters: bool,
      searchNotesLawyers: bool,
      searchNotesCounterparties: bool,
    })
  }

  checkAll = () => {
    if (this.state.searchClients && this.state.searchPrecedents && this.state.searchNotesMatters && this.state.searchNotesLawyers && this.state.searchNotesCounterparties) return true
    else return false
  }
  checkNone = () => {
    if (!this.state.searchClients && !this.state.searchPrecedents && !this.state.searchNotesMatters && !this.state.searchNotesLawyers && !this.state.searchNotesCounterparties) return true
    else return false
  }



  render() {

    const initialValues = {
      SearchString: null,
    }

    const initialStatus = {
      errors: {},
    }

    let checkboxStyle = { paddingRight: 30, paddingTop: 10, paddingBottom: 10 }

    return (

      <React.Fragment>

        <h1> <Icon name='search' size='large' circular /> Search</h1>



        <Formik
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={SearchSchema}
          // validateOnBlur={true}
          // validateOnChange={true}
          onSubmit={async (values, actions) => {
            // console.log('Submitting with values ', values)
            try {
              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              let fetchresponse = {}
              fetchresponse = await szenokPostAPI('search', {
                UserID: this.props.login.currentUserID,
                SearchString: values.SearchString,
                Searches: [
                  { name: 'clients', toggle: this.state.searchClients, results: {} },
                  { name: 'precedents', toggle: this.state.searchPrecedents, results: {} },
                  { name: 'matternotes', toggle: this.state.searchNotesMatters, results: {} },
                  { name: 'lawyernotes', toggle: this.state.searchNotesLawyers, results: {} },
                  { name: 'tycoonnotes', toggle: this.state.searchNotesCounterparties, results: {} },
                ]

                // values.Table,
              }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for /search: ', fetchresponse)
              actions.setSubmitting(false)

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                if (!isEmptyNullUndefined(fetchresponse.payload)) {
                  this.setState({ Results: fetchresponse.payload.map(item => item), NoResults: false })
                }
                else {
                  // console.log('Got no results...')
                  this.setState({ Results: [], NoResults: true })
                }

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                // actions.resetForm({ ...initialValues })
                // this.setState(initialState)
              }
            }
            catch (error) {
              let obj = []
              let str = 'Error in /Component-Search ' + error.name === undefined ? error : `${error.name}: ${error.message}`
              obj.push(str)
              this.props.fetchErrors(obj)
              actions.setSubmitting(false)
            }
          }}
          onReset={(actions) => {
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isValid,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
            <div>
              <Segment basic>

                <Form onSubmit={handleSubmit} noValidate autoComplete='off'>

                  <Form.Group>

                    <Form.Field width={16}>
                      <label>Pick Area to Search</label>
                      <Grid>

                        <Grid.Row>
                          <Grid.Column width={9} >
                            <Segment compact color='blue'>
                              <Checkbox
                                style={checkboxStyle}
                                label='Clients'
                                // value={this.state}
                                checked={this.state.searchClients}
                                onChange={() => this.setState((prevState) => ({ searchClients: !prevState.searchClients }))}
                              />
                              <Checkbox
                                style={checkboxStyle}
                                label='Precedents'
                                // value={this.state}
                                checked={this.state.searchPrecedents}
                                onChange={() => this.setState((prevState) => ({ searchPrecedents: !prevState.searchPrecedents }))}
                              />
                              <Checkbox
                                style={checkboxStyle}
                                label='Notes re Matters'
                                // value={this.state}
                                checked={this.state.searchNotesMatters}
                                onChange={() => this.setState((prevState) => ({ searchNotesMatters: !prevState.searchNotesMatters }))}
                              />
                              <Checkbox
                                style={checkboxStyle}
                                label='Notes re Lawyers'
                                // value={this.state}
                                checked={this.state.searchNotesLawyers}
                                onChange={() => this.setState((prevState) => ({ searchNotesLawyers: !prevState.searchNotesLawyers }))}
                              />
                              <Checkbox
                                style={{ paddingRight: 30, paddingTop: 10, paddingBottom: 10 }}
                                label='Notes re Counterparties'
                                // value={this.state}
                                checked={this.state.searchNotesCounterparties}
                                onChange={() => this.setState((prevState) => ({ searchNotesCounterparties: !prevState.searchNotesCounterparties }))}
                              />
                              <br />
                            </Segment>
                          </Grid.Column>
                          <Grid.Column width={1} >
                            <Segment compact color='green'>
                              <Checkbox
                                style={checkboxStyle}
                                label='Select All'
                                // value={this.state}
                                checked={this.checkAll()}
                                onChange={() => this.toggleAll(true)}
                              />
                              <Checkbox
                                style={checkboxStyle}
                                label='Select None'
                                // value={this.state}
                                checked={this.checkNone()}
                                onChange={() => this.toggleAll(false)}
                              />

                              <br />
                            </Segment>
                          </Grid.Column>
                        </Grid.Row>

                      </Grid>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group>

                    {/* Type in the search string */}
                    <Form.Field width={4}>
                      <Form.Input
                        name="SearchString" type="text"
                        label={`Enter Search String`}
                        loading={this.state.success}
                        required
                        placeholder={`Search String`}
                        error={(errors.SearchString && touched.SearchString) || (has(status, 'errors.SearchString'))}
                        onChange={async (e) => {
                          // setFieldTouched('SearchString')
                          await handleChange(e);
                          setFieldValue('SearchString', e.target.value)

                          this.setState({ SearchString: e.target.value })
                        }}
                        onBlur={handleBlur}
                        value={!isEmptyNullUndefined(values.SearchString) ? values.SearchString : ''} />
                      {this.errorTag(errors, touched, status, 'SearchString')}
                    </Form.Field>
                  </Form.Group>

                  <Divider />
                  <Button.Group size='large'>
                    <Button type='button' icon='cancel'
                      onClick={() => {
                        handleReset();
                        this.setState({ SearchString: '', Results: [] })
                      }}
                      color='red'
                      content='Cancel'></Button>
                    <Button.Or />
                    <Button color='teal'
                      onClick={() => { this.setState({ SearchString: '', Results: [] }); handleReset() }}
                      type='button'
                      disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                      content='Clear Form'>
                    </Button>
                    <Button.Or />
                    <Button color='orange'
                      onClick={() => { setStatus({}); setErrors({}) }}
                      type='button'
                      //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                      content='Clear Error Notifications'>
                    </Button>
                    <Button.Or />
                    <Button type='submit'
                      disabled={isSubmitting || !isEmpty(errors) || !isEmpty(status.errors) || this.checkNone()}
                      icon='upload'
                      loading={isSubmitting}
                      floated='right'
                      color={isValid ? 'green' : 'grey'}
                      content={`Search`}></Button>
                  </Button.Group>


                  {/* <DisplayState state={this.state} /> */}
                  {/* <DisplayFormikState /> */}

                </Form>
              </Segment>
            </div>
          )}
        </Formik>

        {!isEmptyNullUndefined(this.state.Results) && this.state.Results.map(item =>
          item.payload.length > 0 ?
            <React.Fragment key={`For ${item.table}`}>
              <h4>Results for {item.table}:</h4>
              <SearchResults
                key={`Results for ${item.table}`}
                type={item.table}
                Results={item.payload}
                history={this.props.history}
                SearchString={this.state.SearchString}
              />
            </React.Fragment>
            :
            <h4 key={`No Results for ${item.table}`}>No results for {item.table}...</h4>
        )}


        {this.state.NoResults && <h3>No Results</h3>}

      </React.Fragment>

    )
  }
}

const SearchSchema = Yup.object().shape({
  SearchString: Yup
    .string()
    .min(3, 'Search string must be at least 3 characters.')
    .max(200, 'Search string cannot exceed 200 characters.')
    .required('Must have a search string for this.'),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
