import React, { useState, useEffect } from 'react'
import LocationMap from './Component-AddMatter-AddLocation'
import Geocode from "react-geocode"
import { isEmptyNullUndefined } from "../helper/Misc";
import { szenokPostAPI } from '../helper/szenokPostAPI'
import ReactTableMaker from '../helper/ReactTableMaker'
import { Icon, Button } from 'semantic-ui-react';

const LocationContainer = ({
  Height = 200,
  locationsForEditMatter = [],
  sendLocationsToParent = () => { },
  token = '',
}) => {

  const [GOOGLEAPI, setGOOGLEAPI] = useState('')
  const [locations, setLocations] = useState(locationsForEditMatter)
  const [showMap, setShowMap] = useState(false)

  useEffect(() => {
    let getGOOGLEAPI = async () => {
      let fetchresponse = await szenokPostAPI('google', { fetchstr: 'getAPI' },
        token)
      // console.log('Now printing fetchresponse for google getAPI: ', fetchresponse)
      setGOOGLEAPI(fetchresponse.payload)
    }
    getGOOGLEAPI()
  }, [token])

  const handleSelectedLocation = async (coordinates = [], address = '', selectedMatter = null) => {
    // console.log(`Here in handleSelectedLocation and here are coordinates: ${coordinates}, address: ${address}, and selectedMatter: ${selectedMatter}`)
    let info = await getState(coordinates, GOOGLEAPI)
    let state = !isEmptyNullUndefined(info) ? info.state : null
    let geoID = !isEmptyNullUndefined(info) ? info.geoID : null
    // console.log('This is state: ', state)
    if (locations.find(location => location.geoID === geoID)) alert('Location is already listed!')
    else if (!isEmptyNullUndefined(state)) {
      let newLocations = [...locations, { coordinates: coordinates, address: address, state: state, geoID: geoID }]
      setLocations(locations => [...locations, { coordinates: coordinates, address: address, state: state, geoID: geoID }])
      sendLocationsToParent(newLocations)

      setShowMap(false)
    }
    else alert('Address is either not in the United States or otherwise wrong...')
  }

  return (
    <div>
      {!isEmptyNullUndefined(locations) &&
        <ReactTableMaker passedData={locations} passedColumns={LocationsColumns(locations, setLocations, sendLocationsToParent)}
          handleClick={(rowID, cellID, rowValues) => {
            // cellID !== 5 && cellID !== 6 && this.props.history.push(`/client/${rowID}?cname=${this.state.Clients.find(o => o.client_id === rowID)['client_id']}`)
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
            // this.setState({ selectedTType: rowID })
            // this.getPaymentsForInvoice(rowID) 
          }}
          includeSearchBar={false}
          includePaginationFooter={false}
        />
      }
      <p />
      {/* {'locations: '+ JSON.stringify(locations)}<p/> */}
      {showMap && <Button content='Cancel Map' color='red' size='mini' onClick={() => setShowMap(false)} />}
      {isEmptyNullUndefined(GOOGLEAPI) && showMap && <h2>Missing Google API Key (contact admin...)</h2>}
      {!isEmptyNullUndefined(GOOGLEAPI) && !showMap && <Button content='Add Location' color='green' size='mini' onClick={() => setShowMap(true)} />}
      {!isEmptyNullUndefined(GOOGLEAPI) && showMap &&
        <div style={{ paddingTop: '5px' }}>
          <LocationMap
            GOOGLEAPI={GOOGLEAPI}
            Height={Height}
            handleSelectedLocation={handleSelectedLocation}
          />
        </div>
      }

    </div>
  )
}

export default LocationContainer

const getState = async (coordinates, GOOGLEAPI) => {

  let state = ''
  let country = ''
  let geoID = ''
  Geocode.setApiKey(GOOGLEAPI)
  Geocode.setRegion("US")
  Geocode.enableDebug()
  // Get address from latitude & longitude.
  try {
    let response = await Geocode.fromLatLng(coordinates.lat, coordinates.lng)
    const geoLocatedAddress = response.results[0]
    // console.log(geoLocatedAddress)
    // Not sure why, but I think Geocode automatically sends a console.log at this point
    // console.log(response.results)
    geoID = geoLocatedAddress.place_id
    for (let ac = 0; ac < geoLocatedAddress.address_components.length; ac++) {
      let component = geoLocatedAddress.address_components[ac];
      if (component.types.includes('administrative_area_level_1')) {
        state = component.short_name;
      }
      if (component.types.includes('country')) {
        country = component.short_name;
      }
    }
    // console.log(`This is country: ${country} and this is state: ${state}`)
  }
  catch (error) {
    console.error(error)
  }
  if (country === 'US') return { state: state, geoID: geoID }
  else return null
}


// function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function plusFontColor(e) { e.target.style.color = "#ff8000" }
// function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
function minusFontColor(e) { e.target.style.color = "" }

let LocationsColumns = (locations, setLocations, sendLocationsToParent) => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Coordinates</span>,
      accessor: (d) => `Lat: ${d.coordinates.lat.toFixed(6)}; Lng: ${d.coordinates.lng.toFixed(6)}`,
      // accessor: (coordinates) => JSON.stringify(coordinates),
      id: 'latlng',
      show: true,
      Filter: '',
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Address</span>,
      accessor: 'address',
      show: true,
      Filter: ''
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>State</span>,
      accessor: 'state',
      show: true,
      Filter: ''
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>ID</span>,
      accessor: 'geoID',
      show: false,
      Filter: ''
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'geoIDs',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          style={{ 'float': 'left', cursor: 'pointer' }}
          onMouseOver={plusFontColor}
          onMouseOut={minusFontColor}
          onClick={() => {
            setLocations(locations.filter(location => { return location.geoID !== row.original.geoID }))
            sendLocationsToParent(locations.filter(location => { return location.geoID !== row.original.geoID }))
          }}
        >
          <Icon name='delete' color='red' size='small' bordered />Delete
        </span>
    },

  ]
}
