const initialstate = {
  addCPInfoObject: {
    showAddCPInfoButton: true,
    showAddCPInfoModule: false,
    showAddCP: false,
    showAddLawFirm: false
  },
  showAddBusinessMan: false,
  showAddLawyer: false,
  showAddBill: false
}


export default (state =
  initialstate,
  action) => {
  switch (action.type) {

    case 'RESET_COUNTERPARTY_INFO':
      // console.log('Changing zoom level....', action.payload)
      return {
        ...initialstate
      }
    case 'CURRENT_ADD_COUNTERPARTY_INFO':
      // console.log('Changing zoom level....', action.payload)
      return {
        ...initialstate,
        addCPInfoObject: {
          showAddCPInfoButton: action.payload ? false : true,
          showAddCPInfoModule: action.payload ? true : false,
          showAddCP: false,
          showAddLawFirm: false
        }
      }
    case 'CURRENT_ADD_COUNTERPARTY':
      // console.log('Changing add counterparty....', action.payload)
      return {
        ...state,
        addCPInfoObject: {
          ...state.addCPInfoObject,
          // showAddCPInfoButton: action.payload ? false : true,
          showAddCP: action.payload,
          // showAddCP: true,
          // showAddLawFirm: false
        }
      }
    case 'CURRENT_ADD_LAWFIRM':
      // console.log('Changing add law firm....', action.payload)
      return {
        ...state,
        addCPInfoObject: {
          ...state.addCPInfoObject,
          // showAddCPInfoButton: action.payload ? false : true,
          showAddLawFirm: action.payload,
          // showAddCP: true,
          // showAddLawFirm: false
        }
      }
    case 'CURRENT_ADD_BUSINESSMAN':
      // console.log('Changing add businessman....', action.payload)
      return {
        ...initialstate,
        showAddBusinessMan: true
      }
    case 'CURRENT_ADD_LAWYER':
      // console.log('Changing add lawyer....', action.payload)
      return {
        ...initialstate,
        showAddLawyer: true
      }
    case 'CURRENT_ADD_BILL':
      // console.log('Changing add bill....', action.payload)
      return {
        ...initialstate,
        showAddBill: true
      }
    default:
      return state
  }
} 