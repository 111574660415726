import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

import DeletePrecedent from './Component-DeletePrecedent'
// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'

import { isEmptyNullUndefined } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';

let initialDeletePrecedent = {
  PrecedentsExist: true,
}

class Precedents extends Component {

  state = {
    selectedPrecedent: null,
    selectedMatter: null,
    selectedClient: null,
    DeletePrecedent: initialDeletePrecedent,
    modalOpen: false,
    Row: {},
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getPrecedents()
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.matterID !== this.props.matterID) {
      this.props.fetchResetState('all')
      this.getPrecedents()
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getPrecedents(), 200)
    }
  }

  getPrecedents = async () => {

    // console.log('This is props.clientID: ', this.props.clientID)
    // console.log('This is props.matterID: ', this.props.matterID)
    this.props.fetchResetState('all')
    // console.log('Fetching for this matter: ', this.props.matterID)
    let fetchresponse = {}
    if (isEmptyNullUndefined(this.props.matterID) && isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('precedentInfo', { fetchstr: 'getAllPrecedents' },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for getAllPrecedents: ', fetchresponse)
    }
    // This version is if we have a clientInfo
    else if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('precedentInfo', { fetchstr: 'getPrecedentsForClient', clientID: parseInt(this.props.clientID) },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for getPrecedentsForClient: ', fetchresponse)
    }
    //This second version is if we're in MatterInfo
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      fetchresponse = await szenokPostAPI('precedentInfo', { fetchstr: 'getPrecedentsForMatter', matterID: parseInt(this.props.matterID) },
        this.props.login.currentToken)
      // console.log('Now printing fetchresponse for getPrecedentsForMatter: ', fetchresponse)
    }
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Precedents: fetchresponse.payload
    })
  }

  setStatePrecedent = (precedent_id, callback) => {
    // console.log('In setStatePrecedent and this is precedent_id: ', precedent_id)
    this.setState({ DeletePrecedent: initialDeletePrecedent })
    this.setState({ selectedPrecedent: precedent_id }, () => { if (callback) callback() })

  }

  handleDeletePrecedent = async () => {
    // console.log('Running handleDeletePrecedent on this precedent: ', this.state.selectedPrecedent)
    // console.log(`This is precedent_id: ${this.state.selectedPrecedent}`)

    // if (!this.state.DeletePrecedent.PrecedentsExist) 
    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      // let fetchresponse = {}
      // console.log('About to deletePrecedent for this precedentID: ', this.state.selectedPrecedent)
      let fetchresponse = await szenokPostAPI('deletePrecedent', {
        precedentID: this.state.selectedPrecedent
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponsefor handleDeletePrecedent(): ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getPrecedents()
        reduxupdate('precedents')
        this.setState({ selectedPrecedent: null })
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  handleCancelDelete = () => { this.setState({ selectedPrecedent: null }) }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }
  setRow = (value) => {
    // console.log('This is value: ', value)
    if (!isEmptyNullUndefined(value)) this.setState({ Row: { precedent_id: value.values.precedent_id, precedent_file: value.values.precedent_file } })
    else this.setState({ Row: {} })
  }
  render() {

    let displaytype = !isEmptyNullUndefined(this.props.clientID) ? 'clientprecedents' : !isEmptyNullUndefined(this.props.matterID) ? 'matterprecedents' : 'general'

    return (
      <div>
        {isEmptyNullUndefined(this.props.clientID) && isEmptyNullUndefined(this.props.matterID) ?
          <h1> <Icon name='eye' size='large' circular /> Precedents</h1>
          :
          <h2 id='Precedents'><Icon name='eye' size='large' />Precedents:</h2>
        }

        {this.state.DeletePrecedent.PrecedentsExist && (!isEmptyNullUndefined(this.state.selectedPrecedent) || !isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeletePrecedent
            handleDeletePrecedent={this.handleDeletePrecedent}
            handleCancelDelete={this.handleCancelDelete} />
        }

        {!isEmptyNullUndefined(this.state.Precedents) &&
          < ReactTableMaker passedData={this.state.Precedents}
            passedColumns={PrecedentsColumns(displaytype, this.props.history, this.setModalOpen, this.setRow, !!this.props.login.currentObserver)}
            handleClick={(rowID, cellID, rowValues) => {
              // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
            }}
            includeSearchBar={false}
            color='olive'
          />
        }

        {/* This is the "are you sure" modal for deleting a row */}
        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              // console.log('This is state.Row: ', this.state.Row)
              this.setStatePrecedent(this.state.Row.precedent_id,
                this.handleDeletePrecedent
              )
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Precedent'
            deleteObject={`Precedent File: ${this.state.Row.precedent_file}`}
          />}

        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Precedents))


Precedents.defaultProps = {
  matterID: null,
}

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const PrecedentsColumns = (type, history, setModalOpen, setRow, observer) => {
  let results = []
  switch (type) {
    case 'general':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Type</span>,
          accessor: 'precedent_type',
          show: true,
          Filter: 'defaultColumnFilterLeft',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent File</span>,
          accessor: 'precedent_file',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Description</span>,
          accessor: 'precedent_description',
          show: true,
          Filter: 'defaultColumnFilterLeft',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Date</span>,
          accessor: 'precedent_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'precedent_id',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} > <Link to={`/editprecedent/${cell.value}`}><Icon name='edit' />Edit</Link></span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'precedent_id2',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} > <Link to={''} onClick={(e) => {
            e.preventDefault()
            setModalOpen(true)
            setRow(row)
          }}>
            <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
        },
      ]
      break;
    case 'clientprecedents':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Type</span>,
          accessor: 'precedent_type',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent File</span>,
          accessor: 'precedent_file',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Description</span>,
          accessor: 'precedent_description',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Date</span>,
          accessor: 'precedent_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'precedent_id',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} > <Link to={`/editprecedent/${cell.value}`}><Icon name='edit' />Edit</Link></span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'precedent_id2',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} > <Link to={''} onClick={(e) => {
            e.preventDefault()
            setModalOpen(true)
            setRow(row)
          }}>
            <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
        },
      ]
      break;
    case 'matterprecedents':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Type</span>,
          accessor: 'precedent_type',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent File</span>,
          accessor: 'precedent_file',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Description</span>,
          accessor: 'precedent_description',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Date</span>,
          accessor: 'precedent_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'precedent_id',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} > <Link to={`/editprecedent/${cell.value}`}><Icon name='edit' />Edit</Link></span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'precedent_id2',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} > <Link to={''} onClick={(e) => {
            e.preventDefault()
            setModalOpen(true)
            setRow(row)
          }}>
            <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
        },
      ]
      break;
    default:
    // code block
  }
  return results
}


// const PrecedentsInfoColumns = (history, setModalOpen, setRow) => [
//   // {
//   //   Header: () => <span style={{ 'float': 'left' }}>Precedent ID</span>,
//   //   accessor: 'precedent_id',
//   //   show: true,
//   //   width: 0,
//   //   sortType: 'alphanumeric',
//   //   Filter: 'defaultColumnFilterLeft'
//   // },

// ]