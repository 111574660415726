import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';
import ReactTableMaker from '../helper/ReactTableMaker'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Button, Segment } from 'semantic-ui-react';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

const initialState = {
  Precedents: null,
  PrIDs: [],
}

class DeletePrecedent extends Component {

  state = {
    hasError: false, ...initialState
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    if (!isEmptyNullUndefined(this.props.matterID) || !isEmptyNullUndefined(this.props.clientID)) {
      // console.log('Getting precedents...')
      this.getPrecedents()
    }
  }


  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.matterID !== this.props.matterID || prevProps.clientID !== this.props.clientID) {
      this.props.fetchResetState('all')
      // console.log('Getting precedents...')
      this.getPrecedents()
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  getPrecedents = async () => {

    this.props.fetchResetState('all')
    this.setState({ ...this.state, ...initialState })
    // console.log('In DeletePrecedent and fetching precedents for this matter/client: ', this.props.matterID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('precedentInfo', { fetchstr: 'getPrecedentsForClient', clientID: this.props.clientID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      fetchresponse = await szenokPostAPI('precedentInfo', { fetchstr: 'getPrecedentsForMatter', matterID: this.props.matterID },
        this.props.login.currentToken)
    }
    // else if (!isEmptyNullUndefined(this.props.invoiceID)) {
    //   fetchresponse = await szenokPostAPI('precedentInfo', { fetchstr: 'getPaymentsForInvoice', invoiceID: this.props.invoiceID },
    //     this.props.login.currentToken)
    // }
    // console.log('Now printing fetchresponse for getPrecedents(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        Precedents: fetchresponse.payload,
        PrIDs: Array.from(new Set(fetchresponse.payload.map(precedent => precedent.precedent_id))),
      })
    }
    else {
      // console.log('GOT TO HERE!!!!###$#')
      this.props.handleDeletePrecedent()
      // console.log('CLICKED ON IT!!!')
    }
  }


  handleDeletePrecedent = async () => {

    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deletePrecedent', {
        precedentID: this.state.PrIDs.join()
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deletePrecedent: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        reduxupdate('precedents')
        this.props.handleDeletePrecedent()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }



  render() {
    if (this.state.hasError) return <h1>Something went wrong in Component-DeletePrecedent</h1>
    else
      return (
        <div>

          {/* This will show a list of all of the counterparty-matter connections */}
          {!isEmptyNullUndefined(this.state.Precedents) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Delete All Precedents Filed on This {!isEmptyNullUndefined(this.props.clientID) ? 'Client' : !isEmptyNullUndefined(this.props.matterID) ? 'Matter' : 'Invoice'}</h4>
              <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h6>

              <Button primary onClick={() => this.handleDeletePrecedent()}>Delete {this.state.Precedents.length > 1 ? 'All Precedents' : 'This Precedent'}</Button>
              <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

              <ReactTableMaker passedData={this.state.Precedents}
                // passedColumns={PrecedentsColumns()}
                passedColumns={DeleteColumns('deleteprecedents', this.props.history)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />

              {/* {this.state.Precedents.map((item, i) => {
                return <PrecedentsListMaker
                  {...item}
                  number={i}
                  matterID={this.props.matterID}
                  clientID={this.props.clientID}
                  matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                  precedent={item.precedent_number}
                  key={`PrLM${i}`}
                />
              })
              } */}
            </Segment>}

          {/* <DisplayState state={this.state} /> */}

        </div>
      )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeletePrecedent))


DeletePrecedent.defaultProps = {
  Counterparties: [],
  invoiceID: null,
  matterID: null,
  clientID: null,
  handleDeletePrecedent: () => { },
  handleCancelDelete: () => { },
}

// const PrecedentsListMaker = (props) => {
//   // console.log('This is the props in PrecedentListMaker: ', props)
//   if (!isEmptyNullUndefined(props.precedent_id))
//     return (

//       <Segment secondary>

//         <h6><b>Precedent Type: </b>{props.precedent_type}</h6>
//         <h6><b>Precedent File: </b>{props.precedent_file}</h6>
//         <h6><b>Precedent Description: </b>{props.precedent_description}</h6>
//         <h6><b>Precedent Date: </b>{props.precedent_date}</h6>
//         {!isEmptyNullUndefined(props.matter_id) && <h6><b>(For Matter Number): </b>{props.matter_id}</h6>}
//         {!isEmptyNullUndefined(props.matter_name) && <h6><b>(For Matter): </b>{props.matter_name}</h6>}
//         {!isEmptyNullUndefined(props.client_name) && <h6><b>(For Client): </b>{props.client_name}</h6>}


//       </Segment>
//     )
//   else return null
// }


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const DeleteColumns = (type, history) => {
  let results = []

  switch (type) {
    case 'deleteprecedents':
      results = [
        // {
        //   Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
        //   accessor: 'client_name',
        //   sortType: 'alphanumeric',
        //   Filter: '',
        //   Cell: ({ cell, row }) =>
        //     <span
        //       onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        //       style={{ 'float': 'left', cursor: 'pointer' }}
        //       onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        //     >
        //       {cell.value}
        //     </span>
        // },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Deal Amount</span>,
          accessor: 'matter_deal_amount',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Date</span>,
          accessor: 'precedent_date',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Type</span>,
          accessor: 'precedent_type',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent File</span>,
          accessor: 'precedent_file',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Description</span>,
          accessor: 'precedent_description',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
      

      ]
      break;
    default:
    // code block
  }
  return results
}