import React, { useState } from 'react'
import ReactTableMaker_Headerarray from './ReactTableMaker_Headerarray'
import ReactTableMakerReactTable from './ReactTableMaker_ReactTable'
import { DefaultColumnFilter, DefaultColumnFilterLeft, SubZeroColumnFilter } from './ReactTableMaker_Filters'
import { filterResultsAll, filterResultsAny } from '../helper/SearchBarMaker'

import { isNullOrUndefined } from 'util';

export function ReactTableMaker(
  { passedColumns = [],
    passedData = [],
    // handleClick = (rowID, cellID, rowValues) => console.log(`This is rowID: ${rowID} and this is cellID: ${cellID}, and this is rowValues: ${rowValues}`),
    handleClick = () => { },
    includeSearchBar = false,
    includePaginationFooter = true,
    groupTable = false,
    groupByRow = [],
    color = 'blue',
    showDownloads = false,
    passedPageSize = 10,
    includeDensePaginationFooter = false,
    ...props }
) {

  // console.log('In ReactTableMaker and this is passedData: ', passedData)
  // console.log('In ReactTableMaker and this is passedColumns: ', passedColumns)

  const [searchString, setSearchString] = useState('')
  const [searchAll, setSearchAll] = useState(false)

  const setSearchStringHandler = (string) => {
    // console.log(`Setting the searchString to ${string}`)
    setSearchString(string)
  }

  //Set up object to pass around by reference
  let setFilterObj = { setFilter: null }

  // Now we memoize data and columns (to avoid re-rendering if inputs haven't changed)
  const data = React.useMemo(() => filterData(passedData, searchString, searchAll), [passedData, searchString, searchAll])
  const columns = React.useMemo(() => ColumnHandler(passedColumns, passedData, filterCreator(searchString, setFilterObj)), [passedColumns, passedData, searchString, setFilterObj])

  // console.log('This is searchString: ', searchString)
  // console.log('This is columns: ', columns)
  // console.log('This is setFilter: ', setFilterObj)
  // console.log('This is data: ', data)

  return (
    <React.Fragment>
      <div id='ReactTableWrapper'>
        {/* Search all: {searchAll.toString()} */}
        <ReactTableMakerReactTable columns={columns} data={data} handleClick={handleClick}
          searchString={searchString}
          setSearchStringHandler={setSearchStringHandler}
          searchAll={searchAll}
          setSearchAll={setSearchAll}
          setFilterObj={setFilterObj}
          passedData={data}
          passedDataLength={passedData.length}
          includeSearchBar={includeSearchBar}
          color={color}
          includePaginationFooter={includePaginationFooter}
          includeDensePaginationFooter={includeDensePaginationFooter}
          groupTable={groupTable}
          groupByRow={groupByRow}
          showDownloads={showDownloads} 
          passedPageSize={passedPageSize}
          />
      </div>
      {/* {showDownloads && <Icon name='file pdf outline' style={{ cursor: 'pointer' }} size='large' color='grey' onClick={() => toPDFcanvas('ReactTableWrapper')} ></Icon>}
      {showDownloads && <Icon name='image' style={{ cursor: 'pointer' }} size='large' color='blue' onClick={() => toIMGcanvas('ReactTableWrapper')} ></Icon>} */}
    </React.Fragment>
  )

}

//Fix:  Right now if I set a column width of 0 (i.e., to make the IDs column disappear),
//this filter will still return true if the search string contains the ID number
//(That is, column IDs on invisible rows still get searched.)
function filterData(data, searchString, searchAll) {
  if (searchAll) return filterResultsAll(data, searchString)
  else return filterResultsAny(data, searchString)
}

//Create columns if none sent through, or
//Tweak the columns that were sent through (e.g., to add DefaultColumnFilter, etc.)
function ColumnHandler(passedColumns, passedData, filterCreator) {
  // console.log('Running columnhandler...and this is filterCreator: ', filterCreator.props.value)
  // str = filterCreator.props.value
  // If no columns were sent, generate our own.
  if (passedColumns.length <= 0) {
    passedColumns = ReactTableMaker_Headerarray(passedData)
  }

  // let tempobj = {}
  passedColumns.forEach((obj) => {
    // console.log('This is passCol obj: ', obj)
    // console.log('This is searchString in passedColumns.forEach: ', searchString)
    if (isNullOrUndefined(obj.Filter)) {
      obj.Filter = DefaultColumnFilter
      obj.filter = 'defaultSearch'
    }
    switch (obj.Filter) {

      case 'defaultColumnFilterLeft':
        obj.Filter = DefaultColumnFilterLeft
        obj.filter = 'defaultSearch'
        break;
      case 'subZeroFilter':
        obj.Filter = SubZeroColumnFilter
        obj.filter = 'subZeroSearch'
        break;
      default:
        break;
    }
    if (obj.Header === 'All') {
      // obj.Filter = zDefaultColumnFilter2(searchString, [])
      obj.Filter = filterCreator
      obj.searchString = filterCreator.props.value
      // console.log('This is obj: ', obj)
    }
    // obj.searchString = searchString
  }
  )
  // console.log('This is the result of ColumnHandler: ', passedColumns)
  return passedColumns

}


//I never could get this working (the return never updates),
//but am leaving it in here in case it's ever useful again.
function filterCreator(getString, setFilter) {

  // console.log('Doing Filter with this searchstring: ', getString())
  // console.log('Doing Filter with this setFilter: ', setFilter.setFilter)
  let searchString = getString
  return (
    <input
      key={searchString.length * Math.random()}
      // value={searchString || ''}
      value={searchString || ''}
      onChange={e => {
        console.log(e.target.value)
        console.log('In special filter now and this is searchString: ', getString())
        console.log('In special filter now and this is setFilter: ', setFilter)
      }}
      //   setFilter.setFilter('All', searchString || undefined) // Set undefined to remove the filter entirely
      // }}
      placeholder={searchString}
    />
  )

}

export default ReactTableMaker
