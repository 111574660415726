//I think can delete this
// export function logInSAction(number) {
//    return {
//      type: "LOG_IN",
//      payload: number
//    }
//  }


 //For this, you can use: import * as user from '../simpleAction'
 //Then dispatch by user.logIn(true) or somethign like that
 //Or, you can import { logIn } from '../simpleAction'
 //Then you can dispatch like logIn(true)

// export function logOutAction(number) {
//   return {
//     type: "LOG_OUT",
//     payload: number
//   }
// }

export function authenticateAction(authenticated) {
  return {
    type: "AUTHENTICATE",
    payload: authenticated
  }
}

export function currentUserEmailAction(email) {
  return {
    type: "CURRENT_USER_EMAIL",
    payload: email
  }
}


export function currentUserNameAction(name) {
  return {
    type: "CURRENT_USER_NAME",
    payload: name
  }
}

export function currentTokenAction(token) {
  return {
    type: "CURRENT_TOKEN",
    payload: token
  }
}

export function currentIconAction(icon) {
  // console.log('currentIconAction: ', icon)
  return {
    type: "CURRENT_ICON",
    payload: icon
  }
}

export function currentIDAction(id) {
  return {
    type: "CURRENT_ID",
    payload: id
  }
}

export function currentLevelAction(level) {
  return {
    type: "CURRENT_LEVEL",
    payload: level
  }
}

export function currentGuestAction(guest) {
  return {
    type: "CURRENT_GUEST",
    payload: guest
  }
}

export function currentObserverAction(observer) {
  return {
    type: "CURRENT_OBSERVER",
    payload: observer
  }
}

export function currentShowChartsAction(show_charts) {
  return {
    type: "CURRENT_SHOW_CHARTS",
    payload: show_charts
  }
}

export function currentShowFinancesAction(show_finances) {
  return {
    type: "CURRENT_SHOW_FINANCES",
    payload: show_finances
  }
}

export function currentDBAction(DB) {
  return {
    type: "CURRENT_DB",
    payload: DB
  }
}

export function currentDevAction(dev) {
  return {
    type: "CURRENT_DEV",
    payload: dev
  }
}

export function loginResetState(type) {
  // console.log('In fetchResetState')
  return {
    type: "LOGIN_RESET",
    payload: type
  }
}

export function refreshToken(tokenValue) {
  // console.log('In refreshToken and this is tokenValue: ', tokenValue)
  return {
    type: "REFRESH_TOKEN_RESET",
    payload: tokenValue
  }
}