import React, { Component } from 'react';
import { Grid, Form, Input, Radio } from 'semantic-ui-react'
import _ from 'lodash';

export default class SearchBarMaker extends Component {

  state = {
    //The following 3 states are just for the search bar.
    results: [{}],
    value: '',
    searchtype: 'any',
  }

  handleRadioChange = (e, { value }) => {
    //If we change the radio button, it sets the searchtype and then runs the appropriate search and 
    //sends the results to handlefilter in the parent component
    this.setState({ searchtype: value })
    value === 'any' ? this.props.handlefilter(filterResultsAny(this.state.results, this.state.value)) :
      this.props.handlefilter(filterResultsAll(this.state.results, this.state.value))
  }
  resetComponent = () => {
    this.setState({ results: this.props.propsdata, value: '' })
    this.props.handlefilter(this.props.propsdata)
  }

  handleSearchChange = (e, { value }) => {

    this.setState({ value })

    setTimeout(() => {
      if (this.state.value.length < 1) {
        this.props.getSearchString('')
        return this.resetComponent()}

      this.props.handlefilter(this.state.searchtype === 'any' ?
        filterResultsAny(this.state.results, value) :
        filterResultsAll(this.state.results, value))
      this.props.getSearchString(this.state.value)
    }, 0)
  }

  componentDidMount() {

    this.setState({ results: this.props.propsdata })

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.propsdata !== prevState.propsdata) {
      // console.log('props changed. Return an object to change state.  This is nextprops.data: ', nextProps.propsdata, ' and this is prevState.data: ', prevState.data);
      return {
        results: nextProps.propsdata
      }
    } return null
  }


  render() {
    const { value, results } = this.state

    return (
      <Grid>
        <Grid.Column width={8}>
          <Input
            //style={'width=100px'}
            //size='large'
            fluid
            //transparent
            icon='search'
            color='blue'
            iconPosition='left'
            placeholder='Search...'
            onChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            results={results}
            value={value}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Form.Field>
            <Radio
              label='Search Any'
              name='radioGroup'
              value='any'
              checked={this.state.searchtype === 'any'}
              onChange={this.handleRadioChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='Search All'
              name='radioGroup'
              value='all'
              checked={this.state.searchtype === 'all'}
              onChange={this.handleRadioChange}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
    )
  }
}


//This version tests results against EACH word/fragment in the search bar
export const filterResultsAll = (results, search) => {

  // console.log('This is results, ', results, ' and this is search: ', search)

  //First, we escape all the regex values.  Second, we split each word into an array.
  //Third, we join each word or partial word into regex groups of this pattern: (?=.*[word]), which will use lookaheads
  //to find each single word or partial word.
  const regexGroups = _.escapeRegExp(search.trim()).split(' ').map(d => `(?=.*${d})`).join('');

  //Now, we create a new regexp using the grouped words
  const re = new RegExp(regexGroups, 'i')

  //We go through our array of objects and we join all the Object.values so we end up with array of strings  
  const stringarray = results.map(element => Object.values(element)).map(element => element.join())

  //Now we create an array of true/false values by testing each string against the regex
  const truefalsearray = stringarray.map(element => re.test(element) ? true : false)

  //Finally, we create a new array that includes only the elements of our data that are 'true' in the truefalsearray.
  const finalarray = results.reduce((newObj, element, index) => truefalsearray[index] ? [...newObj, element] : newObj, [])

  //console.log(truefalsearray)
  //console.log(finalarray)

  return finalarray

}


//This version tests results against ANY word/fragment in the search bar
export const filterResultsAny = (results, search) => {

  //console.log('This is results, ', results, ' and this is search: ', search)

  //First, we escape all the regex values.  Second, we split each word into an array.
  //Third, we join each word or partial word into regex groups separated by '|'
  //to find any one of the words or partial words.
  const regexGroups = _.escapeRegExp(search.trim()).split(' ').join('|');

  //Now, we create a new regexp using the grouped words
  const re = new RegExp(regexGroups, 'i')

  //This version is if we are looking for ANY of the words
  //It maps through each array, then maps through each Object.value.  If any Object.value passes the regex test, then
  //the array element in which it is housed is marked 'true' and otherwise it stays 'false'.
  //Finally, we map through each element and if that element is 'true' we add it to the resulting array. 
  const joinarray = results.reduce((arr, element) => Object.values(element).reduce((newObj, item) =>
    re.test(item) ? newObj = true : newObj, false) ? [...arr, element] : arr, [])

  return joinarray

}

// module.exports.filterResultsAll = filterResultsAll
// module.exports.filterResultsAny = filterResultsAny