import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

// import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon, Image, Button } from 'semantic-ui-react';

// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'

import { isEmptyNullUndefined } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';

class Employees extends Component {

  state = {
    Employees: [],
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getEmployees()
  }


  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getEmployees(), 200)
    }
  }


  getEmployees = async () => {
    // console.log('In getEmployees...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getEmployees', { fetchstr: 'nothing' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getAClasses: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Employees: fetchresponse.payload.map(item => item)
    })
  }

  render() {

    return (
      <div>
        <h1> <Icon name='user secret' size='large' circular />Employees</h1>

        <ReactTableMaker passedData={this.state.Employees} passedColumns={EmployeesColumns(this.props.history, 
        this.props.login.currentToken, this.getEmployees, this.props.loggedUsers, this.props.socket)}
          // handleClick={(rowID, cellID, rowValues) => {console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)}}
          includeSearchBar={false}
        />

        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Employees))

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
function minusFontColor(e) { e.target.style.color = "" }

let EmployeesColumns = (history, currentToken, getEmployees, loggedUsers, socket) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Employee</span>,
      accessor: 'employee',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => // loggedUsers.find(user => user.email === row.values.e_email)? 'Logged: ' + cell.value : 'Not logged'
        <span
          style={{ 'color': loggedUsers.find(user => user.email === row.values.e_email) ? 'green' : 'red' }}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Start Date</span>,
      accessor: 'e_start_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Picture File</span>,
      accessor: 'e_picture',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
      <span
        onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
        style={{ 'float': 'left', cursor: 'pointer', textDecoration: 'none' }}
        onClick={() => {
          window.location.assign(cell.value)
        }}
      >
        {cell.value.substring(67)}
      </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Image</span>,
      accessor: 'size2',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) =>
        <span
          style={{ 'float': 'right', cursor: 'pointer' }}
          onClick={() => {
            window.location.assign(row.original.e_picture)
          }}
        >
          {/* <Image src={window.location.origin + '/images/' + row.values.e_picture} size={'mini'} /> */}
          <Image src={row.values.e_picture} size={'mini'} />
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Level</span>,
      accessor: 'e_level',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Guest</span>,
      accessor: 'guest',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => <div style={{ textAlign: 'center' }}><span style={{ display: 'inline-block' }}>{cell.value ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}</span></div>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Observer</span>,
      accessor: 'observer',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => <div style={{ textAlign: 'center' }}><span style={{ display: 'inline-block' }}>{cell.value ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}</span></div>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Show Charts</span>,
      accessor: 'show_charts',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => <div style={{ textAlign: 'center' }}><span style={{ display: 'inline-block' }}>{cell.value ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}</span></div>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Show Finances</span>,
      accessor: 'show_finances',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => <div style={{ textAlign: 'center' }}><span style={{ display: 'inline-block' }}>{cell.value ? <Icon size='large' color='green' name='check circle' /> : <Icon size='large' color='red' name='x' />}</span></div>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Email</span>,
      accessor: 'e_email',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Force<br />Logout</span>,
      accessor: 'logout',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => loggedUsers.find(user => user.email === row.values.e_email) ?
        <Button
          basic
          size='tiny'
          color='red'
          onClick={() => {
            // console.log('Logging out: ', row.values.e_email)
            socket.emit('logout', { email: row.values.e_email }, (error) => {
              console.log('Got this error: ', error)
            })
            // console.log('Trying to emit logout of: ', row.values.e_email)
          }
          }
        >Logout</Button>
        :
        null
    },
    {
      accessor: '22employee_id',
      Filter: '',
      Cell: ({ cell, row }) => <span style={{ 'float': 'left' }} >
        <span onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
          <Link to={`editemployee/${row.original.employee_id}`}><Icon name='edit' />Edit</Link></span>
      </span>
    },
    {
      accessor: 'employee_id',
      Filter: '',
      Cell: ({ cell, row }) => <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor} >
        <Link to={''} onClick={async (e) => {
          e.preventDefault()
          await szenokPostAPI('deleteEmployee', {
            fetchstr: 'deleteEmployee',
            EmployeeID: cell.value
          }, currentToken)
          // console.log('This is fetchresponse: ', fetchresponse)
          getEmployees()
        }}>
          <Icon name='delete' color='red' size='small' bordered />Delete</Link>

      </span>
    },
  ]
}

