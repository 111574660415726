import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

import DeleteNotes from './Component-DeleteNotes'
import DeletePerson from './Component-DeletePerson'
import DeleteInvoice from './Component-DeleteInvoice'
import DeletePayment from './Component-DeletePayment'

import DeleteCounterparty from './Component-DeleteCounterparty'
import DeletePrecedent from './Component-DeletePrecedent'
// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'

import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'
import { isEmptyNullUndefined } from '../helper/Misc'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';
// import { get } from 'http';

let initialDeleteMatter = {
  LNotesExist: true,
  LawyersExist: true,
  TNotesExist: true,
  TycoonsExist: true,
  MNotesExist: true,
  PaymentsExist: true,
  InvoicesExist: true,
  CounterpartiesExist: true,
  PrecedentsExist: true,
}

class Matters extends Component {

  state = {
    // SearchString: '',
    // FilteredSearchData: [],
    // Data: [],
    // Message: '',
    Matters: [],
    TokenReady: false,
    Loaded: false,
    selectedMatter: null,
    DeleteMatter: initialDeleteMatter,
    modalOpen: false,
    Row: {},
    // selectedClient: null,
  }

  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      this.getMatters()
      this.props.fetchResetState('all')
    }
    // this.setState({ DeleteMatter: initialDeleteMatter })
  }

  componentDidUpdate(prevProps) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    //This on
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true })
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getMatters(), 200)
    }
  }

  getMatters = async () => {
    // console.log('Launching getMatters...')

    this.props.fetchResetState('all')

    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'mattersformainpage2' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for tablesFetch: ', fetchresponse)

    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Matters: fetchresponse.payload.map(item => item
      )
    })
  }

  setStateMatter = (matter_id, callback) => {
    this.setState({ DeleteMatter: initialDeleteMatter })
    this.setState({ selectedMatter: matter_id }, () => { if (callback) callback() })
    // if (callback) callback()
  }

  DeleteMatter = async () => {
    // console.log('Running DeleteMatter on this matter: ', this.state.selectedMatter)
    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    if (!this.predicatesExist() && !isEmptyNullUndefined(this.state.selectedMatter)) {

      try {
        let fetchresponse = {}
        if (!isEmptyNullUndefined(this.props.clientID)) fetchresponse = await szenokPostAPI('deleteMatter', {
          fetchstr: 'deleteMatterFromClient',
          matterID: this.state.CounterpartyMatterIDsSOMETHINGSOMETHING.join()
        }, this.props.login.currentToken)
        else fetchresponse = await szenokPostAPI('deleteMatter', {
          fetchstr: 'deleteMatter',
          matterID: this.state.selectedMatter
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteMatter: ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {

          //Update the matters store
          reduxupdate('matters')

          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          this.getMatters()
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        //FIX:  I don't know if i need this always, but if I don't do this push stuff, sometimes the entire
        //string will come out as an array of characters, instead.  Need to figure out a way to check
        //if I have a proper string or not.
        let errorObj = []
        errorObj.push(error.toString())
        this.props.fetchErrors(errorObj)
      }
    }
  }

  handleDeleteLNote = () => {
    this.setState({ DeleteMatter: { ...this.state.DeleteMatter, LNotesExist: false } })
    this.DeleteMatter()
  }

  handleDeleteLawyers = () => {
    this.setState({ DeleteMatter: { ...this.state.DeleteMatter, LawyersExist: false } })
    this.DeleteMatter()
  }

  handleDeleteTNote = () => {
    this.setState({ DeleteMatter: { ...this.state.DeleteMatter, TNotesExist: false } })
    this.DeleteMatter()
  }

  handleDeleteTycoons = () => {
    this.setState({ DeleteMatter: { ...this.state.DeleteMatter, TycoonsExist: false } })
    this.DeleteMatter()
  }

  handleDeleteMNote = () => {
    this.setState({ DeleteMatter: { ...this.state.DeleteMatter, MNotesExist: false } })
    this.DeleteMatter()
  }

  handleDeletePayment = () => {
    this.setState({ DeleteMatter: { ...this.state.DeleteMatter, PaymentsExist: false } })
    this.DeleteMatter()
  }

  handleDeleteInvoice = () => {
    this.setState({ DeleteMatter: { ...this.state.DeleteMatter, InvoicesExist: false } })
    this.DeleteMatter()
  }

  handleDeleteCounterparty = () => {
    this.setState({ DeleteMatter: { ...this.state.DeleteMatter, CounterpartiesExist: false } })
    this.DeleteMatter()
  }

  handleDeletePrecedent = () => {
    this.setState({ DeleteMatter: { ...this.state.DeleteMatter, PrecedentsExist: false } })
    this.DeleteMatter()
  }

  handleCancelDelete = () => {
    this.setStateMatter(null)
  }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }
  setRow = (value) => {
    // console.log('In setRow and this is value: ', value)
    if (!isEmptyNullUndefined(value)) this.setState({ Row: { matter_id: value.values.matter_id, matter_name: value.values.matter_name } })
    else this.setState({ Row: {} })
  }

  predicatesExist = (type) => {
    let notesExist = this.state.DeleteMatter.MNotesExist || this.state.DeleteMatter.LNotesExist || this.state.DeleteMatter.TNotesExist
    let personsExist = this.state.DeleteMatter.LawyersExist || this.state.DeleteMatter.TycoonsExist
    let paymentsExist = this.state.DeleteMatter.PaymentsExist
    let invoicesExist = this.state.DeleteMatter.InvoicesExist
    let counterpartiesExist = this.state.DeleteMatter.CounterpartiesExist
    let precedentsExist = this.state.DeleteMatter.PrecedentsExist
    let predicatesExist = notesExist || personsExist || paymentsExist || invoicesExist || counterpartiesExist || precedentsExist
    // console.log('Returning from predicatesExist: ', predicatesExist.toString())

    switch (type) {
      case 'notes':
        return notesExist
      case 'persons':
        return personsExist
      case 'personsPredicates':
        return notesExist
      case 'payments':
        return paymentsExist
      case 'paymentsPredicates':
        return notesExist || personsExist
      case 'invoices':
        return invoicesExist
      case 'invoicesPredicates':
        return notesExist || personsExist || paymentsExist
      case 'counterparties':
        return counterpartiesExist
      case 'counterpartiesPredicates':
        return notesExist || personsExist || paymentsExist || invoicesExist
      case 'precedents':
        return precedentsExist
      case 'precedentsPredicates':
        return notesExist || personsExist || paymentsExist || invoicesExist || counterpartiesExist
      default:
        return predicatesExist
    }
  }

  render() {


    return (
      <div>
        <h1> <Icon name='chess bishop' size='large' circular /> Matters</h1>

        {this.predicatesExist('notes') && !isEmptyNullUndefined(this.state.selectedMatter) &&
          <DeleteNotes matterID={this.state.selectedMatter}
            handleDeleteLNote={this.handleDeleteLNote} handleDeleteTNote={this.handleDeleteTNote} handleDeleteMNote={this.handleDeleteMNote}
            handleCancelDelete={this.handleCancelDelete}
          />
          // getCounterparties={this.getCounterparties}
          // handleDeleteCounterparty={this.handleDeleteCounterparty} handleCancelDelete={this.handleCancelDelete} />
        }


        {this.predicatesExist('persons') && !this.predicatesExist('personsPredicates') && !isEmptyNullUndefined(this.state.selectedMatter) &&
          <DeletePerson matterID={this.state.selectedMatter}
            // getCounterparties={this.getCounterparties}
            handleDeleteLawyers={this.handleDeleteLawyers} handleDeleteTycoons={this.handleDeleteTycoons}
            handleCancelDelete={this.handleCancelDelete} />
        }


        {this.predicatesExist('payments') && !this.predicatesExist('paymentsPredicates') && (!isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeletePayment matterID={this.state.selectedMatter}
            handleDeletePayment={this.handleDeletePayment}
            handleCancelDelete={this.handleCancelDelete} />
        }

        {this.predicatesExist('invoices') && !this.predicatesExist('invoicesPredicates') &&
          (!isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeleteInvoice matterID={this.state.selectedMatter}
            handleDeleteInvoice={this.handleDeleteInvoice}
            handleCancelDelete={this.handleCancelDelete} />
        }

        {this.predicatesExist('counterparties') && !this.predicatesExist('counterpartiesPredicates') && (!isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeleteCounterparty matterID={this.state.selectedMatter}
            handleDeleteCounterparty={this.handleDeleteCounterparty}
            handleCancelDelete={this.handleCancelDelete} />
        }

        {this.predicatesExist('precedents') && !this.predicatesExist('precedentsPredicates') && (!isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeletePrecedent matterID={this.state.selectedMatter}
            handleDeletePrecedent={this.handleDeletePrecedent}
            handleCancelDelete={this.handleCancelDelete} />
        }

        <ReactTableMaker passedData={this.state.Matters} passedColumns={MattersColumns(this.setStateMatter, this.DeleteMatter, this.props.history, this.setModalOpen, this.setRow, !!this.props.login.currentObserver)}
          handleClick={(rowID, cellID, rowValues) => {
            // cellID !== 3 && cellID !== 4 && !isEmptyNullUndefined(this.state.Matters) && this.props.history.push(`/matter/${rowID}?matter=${this.state.Matters.find(o => o.matter_id === rowID)['matter_name']}&matterID=${rowID}`)
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
            // this.setState({ selectedTType: rowID })
            // this.getPaymentsForInvoice(rowID) 
          }}
          includeSearchBar={false}
        // includeSearchBar={true}
        />

        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              this.setStateMatter(this.state.Row.matter_id,
                this.DeleteMatter
              )
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Matter'
            deleteObject={this.state.Row.matter_name}
          />}

        {/* <DisplayState state={this.state} /> */}

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
function minusFontColor(e) { e.target.style.color = "" }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Matters))

let MattersColumns = (setStateMatter, DeleteMatter, history, setModalOpen, setRow, observer) => {
  return [
    // {
    //   Header: () => <span style={{ 'float': 'left' }}>Matter ID</span>,
    //   accessor: 'matter_id',
    //   show: true,
    //   width: 0,
    //   sortType: 'alphanumeric',
    //   Filter: 'defaultColumnFilterLeft'
    // },

    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
      accessor: 'matter_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row, rows }) => !isEmptyNullUndefined(rows) &&
        <React.Fragment>
          <span
            onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
            style={{ 'float': 'left', cursor: 'pointer' }}
            onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
          >
            {cell.value}
          </span>
          {!isEmptyNullUndefined(rows[row.index]) && <span style={{ 'float': 'right', fontSize: 'x-small', paddingLeft: '15px' }}>{rows[row.index].original.matter_notes_total > 0 ? ` (${rows[row.index].original.matter_notes_total} notes for matter)` : null}</span>}
        </React.Fragment>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer', color: 'grey' }}
          onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        >
          ({cell.value})
      </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Origination Date</span>,
      accessor: 'origination_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Closing Date</span>,
      accessor: 'closing_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell }) => isEmptyNullUndefined(cell.value) ? <Icon  size='large' color='red' name='x' />: <span style={{ 'float': 'left' }}>{cell.value}</span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>States</span>,
      accessor: 'states',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
      accessor: 'matter_id',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
        <Link to={`/editmatter/${cell.value}`}><Icon name='edit' />Edit</Link></span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'matter_id2',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
        <Link to={''} onClick={(e) => {
          e.preventDefault()
          setModalOpen(true)
          setRow(row)
        }}>
          <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
    },
  ]
}
