import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, filterSimpleInput, ListMakerSimpleClickable } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'
// import NumberFormat from 'react-number-format'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Header, Label, Divider, Button, Segment } from 'semantic-ui-react';
// import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';
// import { isNullOrUndefined } from 'util';

const initialState = {
  visible: false,
  ExistingCParties: [],
  CParty: '',
  CParty2Regex: [],
  CParty2RegexSpecific: '',
  CPartyArray: [],
  initialValues: {},

}

class AddCounterparty extends Component {

  state = initialState

  componentDidMount() {
    this.props.fetchResetState('all')
    this.getCParties()
    if (this.props.update) this.getSpecificCounterparty()

  }

  getSpecificCounterparty = async () => {
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('counterpartyInfo', { fetchstr: 'getCounterparty', IDNumber: this.props.match.params.counterpartyID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getSpecificCounterparty: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { CParty: fetchresponse.payload.counterparty }
    })
  }

  async getCParties() {
    let fetchresponse = await szenokPostAPI('tablesFetch', { fetchstr: 'counterparties' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse.payload: ', fetchresponse.payload)
    this.setState({ ExistingCParties: fetchresponse.payload.map(row => [row.counterparty_id, row.counterparty]) })
  }

  CPartiesDropDownArray = (cparray) => {
    var array = cparray.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `CPID${result[0]}}`
      obj.text = result[1]
      obj.value = result[0]
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ CParty2Regex: updatedListGeneralSorted })
    if (updatedListSpecific.length > 0) {
      if (this.props.update && updatedListSpecific[0][1] !== this.state.initialValues.CParty) {
        this.setState({ CParty2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
      else if (this.props.update && updatedListSpecific[0][1] === this.state.initialValues.CParty) {
        this.setState({ CParty2RegexSpecific: '' })
        setStatus({ errors: {} })
      }
      if (!this.props.update) {
        this.setState({ CParty2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ CParty2RegexSpecific: '' })
      setStatus({ errors: {} })
    }

  }, 100)

  render() {

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      CParty: '',
      Note: null,
    } :
      {
        CParty: this.state.initialValues.CParty,
        Note: null,
      }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300


    return (
      <div>
        <Header as='h3'>Counterparty {this.props.update === true ? 'Edit' : 'Add'} Form</Header>
        {this.props.update &&
          <Header as='h2'>Editing: {this.state.initialValues.CParty}</Header>
        }

        <Formik
          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={AddCPartySchema}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              this.setState({ CParty2Regex: [], CParty2RegexSpecific: '' })
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editCounterparty', { CParty: values.CParty, CPartyID: this.props.match.params.counterpartyID }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addCounterparty', { CParty: values.CParty }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse add/editCounterparty: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                // console.log('Successfully added a counterparty...')

                // //Update the counterparty store
                reduxupdate('counterparties')

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                actions.resetForm({ ...initialValues })
                this.setState(initialState)
                this.getCParties()
                if (this.props.update) {
                  await this.getSpecificCounterparty()
                }
                // this.props.showCounterpartyInfoFormToggle(true)
                // this.props.showAddCounterpartyFormToggle(false)
                this.props.closeCallback(false)
                this.props.update && this.props.history.goBack()
              }
            }
            catch (error) { this.props.fetchErrors(error.toString()) }
            actions.setSubmitting(false)
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment>
                  <Form onSubmit={handleSubmit} noValidate autoComplete='off'>

                    {/* First, put in the Counterparty name */}
                    <Form.Group>
                      <Form.Field width={5}>
                        <Form.Input
                          name="CParty" type="text"
                          label='Counterparty Name'
                          loading={this.props.fetch.fetching}
                          required
                          placeholder='Counterparty'
                          error={(errors.CParty && touched.CParty) || (has(status, 'errors.CParty'))}
                          onChange={async (e) => {
                            await handleChange(e);
                            this.setState({ CParty: e.target.value })
                            this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, this.state.ExistingCParties, { CParty: 'Name already used' })
                          }}
                          onBlur={handleBlur}
                          value={values.CParty} />
                        {this.errorTag(errors, touched, status, 'CParty')}
                      </Form.Field>
                    </Form.Group>

                    {/* {this.errorTag(errors, touched, status, 'CParty', 'Pick a CParty. ', 'Pick a CParty. ')} */}

                    {/* Show the rest of this only if there is a client chosen, first */}
                    {!isEmptyNullUndefined(values.CParty) &&
                      <React.Fragment>

                        <Form.Group>

                          {/* Add a note */}

                        </Form.Group>
                      </React.Fragment>
                    }

                    <Segment secondary={this.state.CParty2RegexSpecific.length === 0} color={this.state.CParty2RegexSpecific.length > 0 ? 'red' : 'blue'}
                      hidden={this.state.CParty2Regex.length === 0}>
                      {ListMakerSimpleClickable(this.state.CParty2Regex, values,
                        this.state.CParty2RegexSpecific, 'CParty', (item) => {

                          // console.log('Clicked: ' + item)
                          let found = this.state.ExistingCParties.find(row => row[0] === item)
                          // console.log('This is found: ', found)
                          setFieldValue('CParty', found[1])
                        })}
                    </Segment>



                    <Divider />
                    <Button.Group size='large'>
                      <Button type='button' icon='cancel'
                        onClick={() => {
                          handleReset();
                          this.setState({ CParty: '', CParty2Regex: [], CParty2RegexSpecific: '' })
                          this.props.closeCallback(false)
                          // this.props.showAddCounterpartyFormToggle(false)
                          // this.props.showCounterpartyInfoFormToggle(true)
                        }}
                        color='red'
                        content='Cancel'></Button>
                      <Button.Or />
                      <Button color='teal'
                        onClick={() => { this.setState({ CParty: '', CParty2Regex: [], CParty2RegexSpecific: '' }); handleReset() }}
                        type='button'
                        disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Form'>
                      </Button>
                      <Button.Or />
                      <Button color='orange'
                        onClick={() => { setStatus({}) }}
                        type='button'
                        //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Error Notifications'>
                      </Button>
                      <Button.Or />
                      <Button type='submit'
                        // disabled={isSubmitting}
                        //Eventually use the below, the one above is just for testing
                        disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                        icon='upload'
                        loading={isSubmitting}
                        floated='right'
                        color={dirty ? 'green' : 'grey'}
                        content={(this.props.update === true ? 'Edit' : 'Add') + ' Counterparty'}></Button>
                    </Button.Group>
                    {/* <DisplayState state={this.state} />
                    <DisplayFormikState /> */}
                  </Form>
                </Segment>
              </div>
            )}
        </Formik>

      </div>
    )
  }
}


const AddCPartySchema = Yup.object().shape({
  CParty: Yup
    .string()
    .min(3, 'Counterparty name must be at least 3 characters.')
    .max(10000, 'Counterparty name cannot exceed 10,000 characters.')
    .required('Must have a counterparty name.'),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(AddCounterparty)


AddCounterparty.defaultProps = {
  update: false,
  // showAddCounterpartyFormToggle: () => { },
  // showCounterpartyInfoFormToggle: () => { },
  closeCallback: () => { },
}


