//import * as loginActions from '../../actions/loginActions'
import {
  clientListAction, clientArrayAction, employeeArrayAction, matterArrayAction,
  transactionTypesArrayAction, transactionTypesAndSubsArrayAction, assetClassArrayAction,
  lawFirmsArrayAction, counterpartiesTotalAction, personsTotalAction, invoicesTotalAction, paymentsTotalAction, precedentsTotalAction,
  locationsTotalAction, billsTotalAction, fetchAllReduxAction
} from '../../actions/clientActions'
import store1 from '../../store.js'
import { szenokPostAPI } from './szenokPostAPI'
import { isEmptyNullUndefined } from './Misc'

export async function reduxupdate(type) {
  // console.log('Here in clientupdate')
  const state = store1.getState()
  const authToken = state.login.currentToken
  // console.log('These are errors: ', state.fetch.fetcherrors)
  // const inFetchError = !isEmptyNullUndefined(state.fetch.fetcherrors)
  // const list0 = szenokPostAPI('reduxFetchAll', { fetchstr: type }, authToken)
  const list = type === 'all' ?
    await szenokPostAPI('reduxFetchAll', { fetchstr: type }, authToken) :
    await szenokPostAPI('reduxFetch', { fetchstr: type }, authToken)
  // console.log('This is the list in reduxupdate: ', list)
  if (!isEmptyNullUndefined(list.errors)) { console.log('We got some errors in reduxFetch response: ', list.errors) }
  else {
    // console.log('Here is the type in reduxupdate: ', type)
    // console.log('This is list: ', list)
    // console.log('This is the redux state in reduxupdate: ', state)
    // // console.log('In reduxupdate and inFetchError is: ', inFetchError, ', and here are the errors: ', JSON.stringify(state.fetch.fetcherrors))
    // if(inFetchError)
    switch (type) {
      case 'clients':
        store1.dispatch(clientListAction(list.payload))
        break;
      case 'clientsarray':
        store1.dispatch(clientArrayAction(list.payload))
        break;
      case 'matters':
        store1.dispatch(matterArrayAction(list.payload))
        break;
      case 'employees':
        store1.dispatch(employeeArrayAction(list.payload))
        break;
      case 'transactions':
        store1.dispatch(transactionTypesArrayAction(list.payload))
        break;
      case 'transactionsandsubs':
        store1.dispatch(transactionTypesAndSubsArrayAction(list.payload))
        break;
      case 'assetclasses':
        store1.dispatch(assetClassArrayAction(list.payload))
        break;
      case 'lawfirms':
        store1.dispatch(lawFirmsArrayAction(list.payload))
        break;
      case 'counterparties':
        store1.dispatch(counterpartiesTotalAction(list.payload))
        break;
      case 'persons':
        store1.dispatch(personsTotalAction(list.payload))
        break;
      case 'invoices':
        store1.dispatch(invoicesTotalAction(list.payload))
        break;
      case 'payments':
        store1.dispatch(paymentsTotalAction(list.payload))
        break;
      case 'precedents':
        store1.dispatch(precedentsTotalAction(list.payload))
        break;
      case 'locations':
        // console.log('This is locations: ', list.payload)
        store1.dispatch(locationsTotalAction(list.payload))
        break;
      case 'bills':
        // console.log('This is bills: ', list.payload)
        store1.dispatch(billsTotalAction(list.payload))
        break;
      case 'all':
        store1.dispatch(fetchAllReduxAction(list.payload))
        // store1.dispatch(clientListAction(list.payload))
        // store1.dispatch(clientArrayAction(list.payload))
        // store1.dispatch(matterArrayAction(list.payload))
        // store1.dispatch(transactionTypesArrayAction(list.payload))
        // store1.dispatch(transactionTypesAndSubsArrayAction(list.payload))
        // store1.dispatch(assetClassArrayAction(list.payload))
        // store1.dispatch(lawFirmsArrayAction(list.payload))
        // store1.dispatch(counterpartiesTotalAction(list.payload))
        // store1.dispatch(personsTotalAction(list.payload))
        // store1.dispatch(invoicesTotalAction(list.payload))
        // store1.dispatch(paymentsTotalAction(list.payload))
        // store1.dispatch(precedentsTotalAction(list.payload))
        break;
      default:
        break;
    }
  }
}
//I replaced all the below functions with the one above.  I think I can delete them.

// export async function clientupdate() {
//   // console.log('Here in clientupdate')
//   const state = store1.getState()
//   // console.log('This is the redux state in clientupdate: ', state)
//   const authToken = state.login.currentToken
//   const list = await szenokPostAPI('reduxFetch', { fetchstr: 'clients' }, authToken)
//   // console.log('Here is the list in clientupdate: ', list.payload)
//   store1.dispatch(clientListAction(list.payload))
// }

// export async function clientArrayUpdate() {
//   //console.log('Here in clientupdate')
//   const state = store1.getState()
//   //console.log('This is the redux state in clientupdate: ', state)
//   const authToken = state.login.currentToken
//   const list = await szenokPostAPI('reduxFetch', { fetchstr: 'clientsarray' }, authToken)
//   //console.log('Here is the list in clientupdate: ', list)
//   store1.dispatch(clientArrayAction(list.payload))
// }

// export async function employeeArrayUpdate() {
//   // console.log('Here in employeeArrayUpdate') 
//   const state = store1.getState()
//   //console.log('This is the redux state in employeearrayupdate: ', state)
//   const authToken = state.login.currentToken
//   const list = await szenokPostAPI('reduxFetch', { fetchstr: 'employees' }, authToken)
//   //console.log('Here is the list in clientupdate: ', list)
//   store1.dispatch(employeeArrayAction(list.payload))
// }

// export async function mattersArrayUpdate() {
//   // console.log('Here in matterArrayUpdate') 
//   const state = store1.getState()
//   // console.log('This is the redux state in matterupdate: ', state)
//   const authToken = state.login.currentToken
//   const list = await szenokPostAPI('reduxFetch', { fetchstr: 'matters' }, authToken)
//   // console.log('Here is the list in matterupdate: ', list)
//   store1.dispatch(matterArrayAction(list.payload))
// }

// export async function transactionTypesArrayUpdate() {
//   // console.log('Here in matterArrayUpdate') 
//   const state = store1.getState()
//   // console.log('This is the redux state in matterupdate: ', state)
//   const authToken = state.login.currentToken
//   const list = await szenokPostAPI('reduxFetch', { fetchstr: 'transactions' }, authToken)
//   // console.log('Here is the list in matterupdate: ', list)
//   store1.dispatch(transactionTypesArrayAction(list.payload))
// }
