import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';
import ReactTableMaker from '../helper/ReactTableMaker'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Button, Segment } from 'semantic-ui-react';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';


class DeleteInvoice extends Component {

  state = {
    Invoices: [],
    InvoiceIDs: [],
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    if (!isEmptyNullUndefined(this.props.invoiceID) || !isEmptyNullUndefined(this.props.matterID) || !isEmptyNullUndefined(this.props.clientID)) this.getInvoices()
  }


  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.invoiceID !== this.props.invoiceID || prevProps.matterID !== this.props.matterID || prevProps.clientID !== this.props.clientID) {
      this.props.fetchResetState('all')
      this.getInvoices()
    }
  }


  getInvoices = async () => {

    this.props.fetchResetState('all')
    // console.log('In DeleteInvoice and fetching invoices...')
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('invoiceInfo', { fetchstr: 'getInvoicesForClient', clientID: this.props.clientID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      fetchresponse = await szenokPostAPI('invoiceInfo', { fetchstr: 'getInvoicesForMatter', matterID: this.props.matterID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.invoiceID)) {
      fetchresponse = await szenokPostAPI('getInvoicePayments', { fetchstr: 'getPaymentsForInvoice', invoiceID: this.props.invoiceID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse for getInvoices(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        Invoices: fetchresponse.payload,
        InvoiceIDs: Array.from(new Set(fetchresponse.payload.map(invoice => invoice.invoice_id)))
      })
    }
    else {
      // console.log('GOT TO HERE!!!!###$#')
      this.props.handleDeleteInvoice()
      // console.log('CLICKED ON IT!!!')
    }
  }



  handleDeleteInvoices = async () => {

    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deleteInvoice', {
        invoiceID: this.state.InvoiceIDs.join()
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteInvoice: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        // this.getInvoices()
        // this.props.getInvoicesOutstanding()
        reduxupdate('invoices')
        this.props.handleDeleteInvoice()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  render() {
    return (
      <div>
        {!isEmptyNullUndefined(this.state.Invoices) &&

          <Segment color='blue' attached='top'>
            <h4>To Proceed, Must First Delete All Invoices Issued on This {!isEmptyNullUndefined(this.props.clientID) ? 'Client' : !isEmptyNullUndefined(this.props.matterID) ? 'Matter' : 'Invoice'}</h4>
            <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h6>

            <Button primary onClick={() => this.handleDeleteInvoices()}>Delete {this.state.Invoices.length > 1 ? 'All Invoices' : 'This Invoice'}</Button>
            <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

            <ReactTableMaker passedData={this.state.Invoices}
              // passedColumns={PrecedentsColumns()}
              passedColumns={DeleteColumns('deleteinvoices', this.props.history)}
              handleClick={(rowID, cellID, rowValues) => {
                // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
              }}
              includeSearchBar={false}
              includePaginationFooter={false}
              color='red'
            />

            {/* {this.state.Invoices.map((item, i) => {
              return <InvoicesListMaker
                {...item}
                number={i}
                matterID={this.props.matterID}
                clientID={this.props.clientID}
                matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                invoice={item.invoice_number}
                // handleDeleteTycoon={this.state.handleDeleteTycoon}
                key={`PLM${i}`}
              />
            })
            } */}
          </Segment>}

        {/* <DisplayState state={this.state} /> */}

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteInvoice))


DeleteInvoice.defaultProps = {
  payments: [],
  invoiceID: null,
  matterID: null,
  ClientID: null,
  getInvoicesOutstanding: () => { },
  handleDeleteInvoice: () => { },
  handleCancelDelete: () => { },
}


// const InvoicesListMaker = (props) => {
//   // console.log('This is the props in InvoiceListMaker: ', props)
//   if (!isEmptyNullUndefined(props.invoice_id))
//     return (

//       <Segment secondary>

//         <h6><b>Invoice Number: </b>{props.invoice_number}</h6>
//         {!isEmptyNullUndefined(props.matterID) && <h6><b>For Matter Number: </b>{props.matter}</h6>}
//         <h6><b>Invoice Amount: </b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.invoice_amount)}</h6>
//         <h6><b>Invoice Date: </b>{props.invoice_date}</h6>
//         <h6><b>Invoice Description: </b>{props.invoice_description}</h6>
//         {!isEmptyNullUndefined(props.matter_name) && <h6><b>(For Matter): </b>{props.matter_name}</h6>}
//         {!isEmptyNullUndefined(props.client_name) && <h6><b>(For Client): </b>{props.client_name}</h6>}

//       </Segment>
//     )
//   else return null
// }

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const DeleteColumns = (type, history) => {
  let results = []

  switch (type) {
    case 'deleteinvoices':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Number</span>,
          accessor: 'invoice_number',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Date</span>,
          accessor: 'invoice_date',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Invoice Description</span>,
          accessor: 'invoice_description',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Invoice Amount</span>,
          accessor: 'invoice_amount',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Paid</span>,
          accessor: 'paid',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => cell.value <= 0 ? <span style={{ 'color': 'red', 'float': 'right' }}>-</span> :
          <div onClick={() => null} > <span style={{ 'color': 'red', 'float': 'right' }}>({new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)})</span> </div>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'right' }}>Due</span>,
          accessor: 'due',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },

      ]
      break;
    default:
    // code block
  }
  return results
}