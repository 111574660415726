import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { szenokPostAPI } from '../helper/szenokPostAPI'

// import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'

import { isEmptyNullUndefined } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';



class LocationsInfo extends Component {

  state = {
    // Payments: [],
    // selectedTType: null,
    // modalOpen: false,
    // Row: {},
  }

  async componentDidMount() {
    // this.props.fetchResetState('all')
    // this.getPayments()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      // setTimeout(() => this.getPayments(), 200)
    }
  }



  render() {

    return (
      <div>

        <ReactTableMaker passedData={this.props.Locations} passedColumns={LocationsColumns(this.props.history, this.props.handleIndividualCenter, this.props.showClient, this.props.showMatter, this.props.showCoords, this.props.noFilters)}
          handleClick={(rowID, cellID, rowValues) => {
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
            this.setState({ selectedTType: rowID })
            // this.getPaymentsForInvoice(rowID)
          }}
          includeSearchBar={false}
          includeDensePaginationFooter={true}
        />


        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationsInfo))


LocationsInfo.defaultProps = {
  Locations: [],
  handleIndividualCenter: () => { },
  showClient: true,
  showMatter: true,
  showCoords: true,
  noFilters: false,
  // handleSomething: () => { },
}

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
// function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
// function minusFontColor(e) { e.target.style.color = "" }

const LocationsColumns = (history, handleIndividualCenter, showClient, showMatter, showCoords, noFilters) => {
  return [
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: showClient ? true : false,
      sortType: 'alphanumeric',
      Filter: noFilters? '' : 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
      accessor: 'matter_name',
      show: showMatter ? true : false,
      sortType: 'alphanumeric',
      Filter: noFilters? '': 'defaultColumnFilterLeft',
      Cell: ({ cell, row, rows }) => !isEmptyNullUndefined(rows) &&
        <React.Fragment>
          <span
            onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
            style={{ 'float': 'left', cursor: 'pointer' }}
            onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
          >
            {cell.value}
          </span>
          {!isEmptyNullUndefined(rows[row.index]) && <span style={{ 'float': 'right', fontSize: 'x-small', paddingLeft: '15px' }}>{rows[row.index].original.matter_notes_total > 0 ? ` (${rows[row.index].original.matter_notes_total} notes for matter)` : null}</span>}
        </React.Fragment>
    },
   !showCoords ? {accessor: 'bunk', Filter: '', show: false} :  {
      Header: () => <span style={{ 'float': 'left' }}>Coordinates</span>,
      accessor: (d) => !showCoords ? null : `Lat: ${d.latitude.toFixed(6)}; Lng: ${d.longitude.toFixed(6)}`,
      // accessor: (coordinates) => JSON.stringify(coordinates),
      id: 'latlng',
      show: true,
      Filter: '',
      Cell: ({ cell, row, rows }) => !isEmptyNullUndefined(rows) && showCoords &&
        <React.Fragment>
          <span
            onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
            style={{ 'float': 'left', cursor: 'pointer' }}
            onClick={() => handleIndividualCenter(row.original.latitude, row.original.longitude)}
          >
            {cell.value}
          </span>
        </React.Fragment>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Address</span>,
      accessor: 'address',
      show: true,
      Filter: (!showCoords && !showMatter && !showCoords) ? '' : noFilters? '' :'defaultColumnFilterLeft',
      Cell: ({ cell, row, rows }) => !isEmptyNullUndefined(rows) &&
        <React.Fragment>
          <span
            onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
            style={{ 'float': 'left', cursor: 'pointer' }}
            onClick={() => handleIndividualCenter(row.original.latitude, row.original.longitude)}
          >
            {cell.value}
          </span>
        </React.Fragment>
    },
  ]
}
