import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

import DeletePerson from './Component-DeletePerson'

// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
// import { getHighlightedText } from '../helper/FormHelper'
import ReactTableMaker from '../helper/ReactTableMaker'
import ConfirmModal from '../helper/ConfirmModal'
import { isEmptyNullUndefined } from '../helper/Misc'

// import DisplayState from '../helper/DisplayState';



class Persons extends Component {

  state = {
    Persons: [],
    selectedPerson: null,
    modalOpen: false,
    Row: {},
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    this.getPersons()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getPersons(), 200)
    }
  }


  getPersons = async () => {
    // console.log('In getCounterparties...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getAllPersons' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getPerson: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Persons: fetchresponse.payload
    })
  }

  setStatePerson = (person_id, callback) => {
    this.setState({ selectedPerson: person_id }, () => {if (callback) callback()})
    // if (callback) callback()
  }

  handleDeletePerson = async () => {
    // console.log('Running handleDeletePerson on this person: ', this.state.selectedPerson)

    let fetchresponse = {}

    //First, find any lawyers for this person (and if any then break)
    fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getLawyerCounterpartiesForPerson', IDNumber: this.state.selectedPerson },
      this.props.login.currentToken)
    let personsExist = !isEmptyNullUndefined(fetchresponse.payload)
    if (personsExist) { 
      // console.log('There is a problem.  personsExist is: ', personsExist); 
      return }

    //Second, find any 'tycoons' for the person (and if any then break)
    fetchresponse = await szenokPostAPI('getPerson', { fetchstr: 'getTycoonCounterpartiesForPerson', IDNumber: this.state.selectedPerson },
      this.props.login.currentToken)
    personsExist = !isEmptyNullUndefined(fetchresponse.payload)
    if (personsExist) { 
      // console.log('There is a problem.  personsExist is: ', personsExist); 
      return }

    //If we passed the above tests, move on
    if (!personsExist) try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      // let fetchresponse = {}
      let fetchresponse = await
        szenokPostAPI('deletePerson', { fetchstr: 'deleteSpecificPerson', IDNumber: this.state.selectedPerson },
          this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteSpecificPerson: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getPersons()
        reduxupdate('persons')
        this.setState({ selectedPerson: null })
      }
    }
      catch (error) {
        console.log('This is the errors: ', error)
        this.props.fetchErrors(error.toString())
      }
  }

  handleCancelDelete = () => { this.setState({ selectedPerson: null }) }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }
  setRow = (value) => {
    if (!isEmptyNullUndefined(value)) this.setState({ Row: { person_id: value.values.person_id, person_name: value.values.person_name } })
    else this.setState({ Row: {} })
  }

  render() {

    return (
      <div>
        <h1> <Icon name='male' size='large' circular /> Persons</h1>

        {(!isEmptyNullUndefined(this.state.selectedPerson) || !isEmptyNullUndefined(this.state.selectedMatter) || !isEmptyNullUndefined(this.state.selectedClient)) &&
          <DeletePerson personID={this.state.selectedPerson} matterID={this.state.selectedMatter}
            clientID={this.state.selectedClient}
            // getCounterparties={this.getCounterparties}
            handleDeletePerson={this.handleDeletePerson} handleCancelDelete={this.handleCancelDelete} />
        }

        <ReactTableMaker passedData={this.state.Persons}
          passedColumns={PersonsColumns(this.setStatePerson, this.handleDeletePerson, this.props.history, this.setModalOpen, this.setRow, !!this.props.login.currentObserver)}
          handleClick={(rowID, cellID, rowValues) => {
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
          }}
          includeSearchBar={false}
          includePaginationFooter={true}
        />

        {/* This is the "are you sure" modal for deleting a row */}
        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)
              this.setStatePerson(this.state.Row.person_id,
                this.handleDeletePerson
              )
            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Person'
            deleteObject={this.state.Row.person_name}
          />}


        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function plusFontColor(e) { e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
function minusFontColor(e) { e.target.style.color = "" }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Persons))

let PersonsColumns = (setStatePerson, handleDeletePerson, history, setModalOpen, setRow, observer) => {
  return [
    // {
    //   Header: () => <span style={{ 'float': 'left' }}>Person ID</span>,
    //   accessor: 'person_id',
    //   show: true,
    //   width: 0,
    //   sortType: 'alphanumeric',
    //   Filter: 'defaultColumnFilterLeft'
    // },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Person Name</span>,
      accessor: 'person_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/person/${cell.value}?person=${cell.value}&personID=${row.values.person_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Person Email</span>,
      accessor: 'person_email',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/person/${row.values.person_name}?person=${row.values.person_name}&personID=${row.values.person_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'person_id',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
        <Link to={`editperson/${cell.value}`}><Icon name='edit' />Edit</Link></span>
    },
    {
      // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
      accessor: 'person_id2',
      // show: true,
      // sortType: 'alphanumeric',
      Filter: '',
      Cell: ({ cell, row }) => !observer && <span style={{ 'float': 'left' }} onMouseOver={plusFontColor} onMouseOut={minusFontColor}>
        <Link to={''} onClick={(e) => {
          e.preventDefault()
          // console.log('Trying to click on: ', row.values.person_id)
          setModalOpen(true)
          setRow(row)
          // setStatePerson(row.values.person_id)
          // handleDeletePerson()
        }}>
          <Icon name='delete' color='red' size='small' bordered />Delete</Link></span>
    },
  ]
}

