import React, { Component } from 'react'
import { withOktaAuth } from '@okta/okta-react';
// import { szenokPostAPI } from '../helper/szenokPostAPI'
import { isEmptyNullUndefined } from '../helper/Misc'

import Toasts from '../helper/Toasts'
import { toast } from 'react-toastify';

import { Button, Icon, Image } from 'semantic-ui-react'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';
// import { isEmptyNullUndefined } from '../helper/Misc';
import SzenokLogo from './SzenokLogo'


class Header extends Component {

  state = {
    logoutCounter: 0,
  }

  componentDidMount() {
    //Set an interval to check logged in status every 5 minutes.  If we get to 15 minutes without being
    //authenticated, then force a logout
    this.logoutInterval = setInterval(() => {
      // console.log('Trying interval and this is auth: ', this.props.authState.isAuthenticated)
      if (this.props.authState.isAuthenticated) this.setState({ logoutCounter: 0 })
      else this.setState({ logoutCounter: this.state.logoutCounter + 1 })
      if (this.state.logoutCounter > 2) {
        this.props.authService.logout('/')
        this.props.loginResetState('all')
      }
    }, 300000)
  }

  componentDidUpdate(prevProps) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    // if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
    // console.log('Something changed in Header.js...')
    // }
    if (prevProps.forceLogOut !== this.props.forceLogOut) {
      // console.log('forceLogOut changed...', this.props.forceLogOut)
      if (this.props.forceLogOut) {
        // console.log('Trying to log out from Header.js...')
        this.props.authService.logout('/')
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.logoutInterval);
  }

  render() {

    let logout = async () => {
      // console.log('Hit logout in Home')
      this.props.authService.logout('/')
      this.props.loginResetState('all')
      this.props.resetLogState()
      // this.forceUpdate()
    }

    let login = async () => {
      // console.log('Hit login in Home')
      this.props.authService.login('/');
    }

    let refreshToken = async () => {
      this.props.refreshToken('all,' + Date.now())
    }


    const { currentUserEmail, currentUserName } = this.props.login;

    const logbutton = this.props.login.authenticated ?
      <Button color='red' onClick={() => {
        logout()
      }}>Logout</Button> :
      <Button color='green' onClick={() => {
        // this.props.setLoggingIn()
        login()
      }}>Login</Button>



    // const headerContent = this.props.login.authenticated ? (
    //   <div style={{ paddingTop: '10px' }}>
    //     {/* <Button basic disabled >{currentUserName} ({currentUserEmail})</Button> */}
    //     <span style={{ color: '#949494', paddingTop: '10px', paddingRight: '10px' }}>{currentUserName} ({currentUserEmail})</span>
    //     {logbutton}
    //   </div>
    // ) : (
    //     <div style={{ paddingTop: '10px' }}>
    //       {logbutton}
    //     </div>
    //   )

    // const logColor = this.props.login.authenticated ?
    //   '#f2f7ff'
    //   : '#f9f9ed'

    // const vertAlign = this.props.login.authenticated ?
    //   'top'
    //   : 'top'

    // const szenokImage = 'SzenOkConflictsLogo.png'

    let Warning = () => {
      if (isEmptyNullUndefined(this.props.login.currentDB)) {
        return (
          <React.Fragment>(Development -- {<span style={{ color: 'grey' }}>no DB found</span>})</React.Fragment>
        )
      }
      if (this.props.login.currentDB.toLowerCase().includes('live')) {
        return (
          <React.Fragment>(Development -- {<span style={{ color: 'blue', fontWeight: 'bolder', background: 'yellow', border: '1px solid yellow' }}>!{this.props.login.currentDB} CAREFULL!</span>})</React.Fragment>
        )
      }

      return <React.Fragment>(Development -- {<span style={{ color: 'red' }}>{this.props.login.currentDB}</span>})</React.Fragment>
    }

    return (
      <React.Fragment>

        {/* Put this toasts back in! */}
        <Toasts />

        <div className={`so-header-container ${(!this.props.login.authenticated) ? 'not-logged' : ''}`}>
          <div className='so-header-left'>
            <div className='so-header-left-icon'>
              <StatusIcon {...this.props} refreshToken={refreshToken} />
            </div>
          </div>
          <div className='so-header-middle-container'>
            <div className='so-header-middle' onClick={() => { console.log('Clicking!'); window.location.reload() }}>
              {/* <svg width={340} height={50}> */}

              {/* <Image
              style={{ width: 425, margin: '-8px', border: '0px', paddingTop: '10px' }}
              src={window.location.origin + '/images/' + szenokImage}
            verticalAlign='bottom' href='/' /> */}
              <svg viewBox='0 0 880 80'>
                <SzenokLogo />
              </svg>
              {/* {(window.location.host.split('.')[0].includes('dev') || window.location.host.split('.')[0].includes('local')) && `(Development${!isEmptyNullUndefined(this.props.login.currentDB) ? ` -- ${this.props.login.currentDB}` : ''})`} */}
              {this.props.login.currentDev && Warning()}
              {/* </svg> */}
            </div>
          </div>
          <div className='so-header-name-lobutton-container'>

            <div className='so-header-name-container'>
              {/* <div className='so-header-name-'> */}
              {this.props.login.authenticated ? <div>{currentUserName}<br />({currentUserEmail})</div> : null}
              {/* </div> */}
            </div>
            <div className='so-header-logbutton-container' >
              {/* <div className='so-header-logbutton'> */}
              {/* {this.props.login.authenticated ? `${currentUserName}(${currentUserEmail})` : null} */}
              {logbutton}
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* <div style={{clear: 'both'}}><p/></div>  */}

        {/* <Grid>
          <Grid.Row textAlign='left' verticalAlign={vertAlign} style={{ backgroundColor: logColor, paddingTop: '5px', paddingBottom: '5px', height: '60px' }}>
            <Grid.Column width={3} textAlign='center'><StatusIcon {...this.props} refreshToken={refreshToken} /></Grid.Column>
            <Grid.Column width={7} textAlign='left'>
              <Image
                style={{ width: 425, margin: '-8px', border: '0px', paddingTop: '10px' }}
                src={window.location.origin + '/images/' + szenokImage} verticalAlign='bottom' href='/' />
            </Grid.Column>
            <Grid.Column width={6} textAlign='right'>
              {headerContent}
            </Grid.Column>
          </Grid.Row>
        </Grid> */}
      </React.Fragment>
    )
  }
}

const StatusIcon = (props) => {
  let userIcon = props.login.currentIcon
  if (!props.login.authenticated) {
    return (
      <Icon name='question circle' size='large' color='blue' circular />
    )
  }
  else if (props.fetch.fetching) {
    return (
      <Icon name='settings' size='large' color='red' circular loading />
    )
  }
  else {
    return (
      typeof userIcon === 'undefined' || userIcon === '' ?
        <Icon name='question circle' size='large' color='red' circular

        />
        :
        //Fix:  I need to do this for now because webpack doesn't handle images well, but eventually replace
        //this with a weblink as in the commented bit below.
        <span style={{ cursor: 'pointer' }}>
          {/* <Image src={window.location.origin + '/images/' + props.login.currentIcon} verticalAlign='bottom' width='50px' circular */}
          <Image src={props.login.currentIcon} verticalAlign='bottom' width='50px' circular
            onClick={() => { props.refreshToken('all'); toast.dismiss() }} />
        </span>
      // <Image src={`./images/${props.login.currentIcon}`} verticalAlign='bottom' width='50px' circular  />
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(Header))