import React, { Component } from 'react'
import { Message } from 'semantic-ui-react'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { isEmptyNullUndefined } from './Misc'
import { toast } from 'react-toastify';

const SuccessToast = ({ closeToast, message = '', errorlist = () => { }, fetcherrors = [] }, props) => {
  return (
    <Message positive header={message} />
  )
}

const ErrorToast = ({ closeToast, generalmessage = 'Something went wrong!', fetcherrors = [] }, props) => {
  return (
    // <Segment color='blue'>
    <Message attached negative>
      <Message.Header><React.Fragment>{generalmessage}</React.Fragment></Message.Header>
      <p><React.Fragment>We ran into {fetcherrors.length === 1 ? 'a problem' : fetcherrors.length + ' problems'}...</React.Fragment></p>
      {errorlist(fetcherrors)}
    </Message>
    // </Segment>
  )
}

const InfoToast = ({ closeToast, fetchinfo = [] }, props) => {
  return (
    //In production don't show the info bar, only the error bar and the success bar
    process.env.NODE_ENV !== 'production' &&
      typeof fetchinfo !== 'undefined' && fetchinfo.length > 0 ?

      //If so, show a neutral message and list all the info
      <Message attached>
        <Message.Header>Info About Request:</Message.Header>
        {/* <p>{submessage}</p> */}
        {errorlist(fetchinfo)}
      </Message>
      :
      //If not, then show nothing
      null
  )
}


class MessageBarRedux extends Component {

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fetch.fetchsuccessmessage !== this.props.fetch.fetchsuccessmessage) {
      // console.log('Successmessage changed: ', this.props.fetch.fetchsuccessmessage)
      if (!isEmptyNullUndefined(this.props.fetch.fetchsuccessmessage)) toast.success(
        <SuccessToast message={this.props.fetch.fetchsuccessmessage} />, { containerId: 'Success' })
    }
    if (prevProps.fetch.fetcherrors !== this.props.fetch.fetcherrors) {
      // console.log('fetcherrors changed: ', this.props.fetch.fetcherrors)
      if (!isEmptyNullUndefined(this.props.fetch.fetcherrors) && this.props.fetch.fetcherrors.length > 0) toast.error(
        <ErrorToast
          generalmessage={'Something went wrong!'}
          fetcherrors={this.props.fetch.fetcherrors}
        />, { containerId: 'Error' })
    }
    if (prevProps.fetch.fetchinfo !== this.props.fetch.fetchinfo) {
      // console.log('fetcherrors changed: ', this.props.fetch.fetcherrors)
      if (process.env.NODE_ENV !== 'production' && !isEmptyNullUndefined(this.props.fetch.fetchinfo) && this.props.fetch.fetchinfo.length > 0) toast(
        <InfoToast
          fetchinfo={this.props.fetch.fetchinfo}
        />, { containerId: 'Info' })
    }
  }

  fetchResetState = (type) => {
    // console.log('In fetchResetState inside MessageBarRedux.')
    this.props.fetchResetState(type)
  }

  render() {
    // const successmessage = this.props.fetch.successmessage
    // const fetcherrors = this.props.fetch.fetcherrors
    // const fetchinfo = this.props.fetch.fetchinfo
    const {  fetchsuccessmessage, fetcherrors, fetchinfo } = this.props.fetch

    // if (!isEmptyNullUndefined(fetchsuccessmessage)) toast.info(<CustomToast generalmessage={fetchsuccess} submessage={fetchsuccessmessage} />, {
    //   closeButton: false
    // })

    if (!isEmptyNullUndefined(fetchsuccessmessage) || !isEmptyNullUndefined(fetcherrors) || !isEmptyNullUndefined(fetchinfo)) {

      return (null
        // <Segment basic>

        //   {/* This one prints out messages (such as successful additions to a sql table). */}
        //   {fetchsuccess ?
        //     messSuccessBar({ success: fetchsuccess, successmessage: fetchsuccessmessage, resetFetchState: this.fetchResetState }) : null}

        //   {/* This one prints out any error messages. */}
        //   {isEmptyNullUndefined(fetcherrors) ? null :
        //     messErrorBar({ fetcherrors: fetcherrors, submessage: `We ran into ${fetcherrors.length === 1 ? 'a problem' : fetcherrors.length + ' problems'}...`, generalmessage: 'Something went wrong!', resetFetchState: this.fetchResetState })}

        //   {/* This next one is only for development purposes.  Can comment out in final build. */}
        //   {isEmptyNullUndefined(fetchinfo) ? null :
        //     process.env.NODE_ENV !== 'production' ? messInfoBar({ fetchinfo: fetchinfo, resetFetchState: this.fetchResetState }) : null}

        //   {/* If there are any error or info messages above, then add a button to delete them (though they also delete by click on the message, itself). */}
        //   {!isEmptyNullUndefined(fetcherrors) || !isEmptyNullUndefined(fetchinfo) ? <Button size='tiny' inverted color='blue' onClick={() => this.fetchResetState('all')}>Clear Messages</Button> : null}

        // </Segment>
      )
    }
    else { return null }
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default connect(mapStateToProps, mapDispatchToProps)(MessageBarRedux)


const messErrorBar = ({ fetcherrors, submessage, generalmessage, resetFetchState }) => {

  // console.log(`This is fetcherrors: ${JSON.stringify(fetcherrors)}`)
  // console.log(`This is fetcherrors not stringified: ${fetcherrors}`)
  return (

    // typeof fetcherrors !== 'undefined' && fetcherrors.length > 0 ?

    //If so, show a negative message and list all the errors
    <div onClick={() => resetFetchState('errors')}>
      <Message attached negative>
        <Message.Header>{generalmessage}</Message.Header>
        <p>{submessage}</p>
        {errorlist(fetcherrors)}
      </Message>
    </div>
    // :

    // //If not, then show nothing
    // // null
    // <div>Yo!!!! </div> //{fetcherrors.length}
  )
}

// const messInfoBar = ({ fetchinfo, resetFetchState }) => {

//   // console.log(`This is fetchinfo: ${JSON.stringify(fetchinfo)}`) 

//   return (

//     //In production don't show the info bar, only the error bar and the success bar
//     process.env.NODE_ENV !== 'production' &&
//       typeof fetchinfo !== 'undefined' && fetchinfo.length > 0 ?

//       //If so, show a neutral message and list all the info
//       <div onClick={() => resetFetchState('info')}>

//         <Message attached>
//           <Message.Header>Info About Request:</Message.Header>
//           {/* <p>{submessage}</p> */}
//           {errorlist(fetchinfo)}
//         </Message>
//       </div>
//       :

//       //If not, then show nothing
//       null
//   )
// }

// const messSuccessBar = ({ success, successmessage, resetFetchState }) => {
//   //Check if we have success
//   if (success) {
//     setTimeout(() => { resetFetchState('message') }, 4500)
//     return (
//       //If so, show a positive message explaining success
//       <Transition animation='fade' duration={4000} transitionOnMount={true}>
//         <Message attached positive
//           header={successmessage}
//         />
//       </Transition>
//     )
//   }
//   // else {
//   //   return (
//   //     //If not, then show default message
//   //     <Message attached warning
//   //       header='Careful!'
//   //       content='These actions will overwrite all your data.'
//   //     />
//   //   )
//   // }
// }


const errorlist = (array) => {
  // console.log('In errorlist with this array: ', array)
  return (
    array.map((item, index) =>
      <li key={`errorlist${index}`}>{item}</li>
    )
  )
}

export { messErrorBar }