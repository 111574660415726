import React from 'react'
import { Grid, Form, Radio, Input } from 'semantic-ui-react'

// import { filterResultsAny, filterResultsAll } from '../helper/SearchBarMaker'


function ReactTableMakerSearchBar(setSearchStringHandler, searchAll, setSearchAll) {

  // const [allCheck, setAllCheck] = useState(false)

  // const handleRadioChange = () => {
  //   setAllCheck(prevState => !prevState)

  // }
  // console.log('This is data:')
  // console.log(data)

  // const handleSearchChange = (e) => {

  //   console.log('This is value: ', e)

  //   let test = filterResultsAll(data, e)
  //   return test
  // }

  // const returnInputValue = (e) => {

  //   console.log('This is value: ', e)

  //   return e
  // }

  return (
    <Grid>
      <Grid.Column width={8}>
        {/* This is allCheck: {allCheck.toString()} */}
        <Input
          //style={'width=100px'}
          //size='large'
          fluid
          //transparent
          icon='search'
          color='blue'
          iconPosition='left'
          placeholder='Search...'
          // onChange={(e) => _.debounce(handleSearchChange(e.target.value), 500, {
          //   leading: true,
          // })}
          onChange={(e) =>  {
            setSearchStringHandler(e.target.value)
            // handleData(searchBarStringObj ,e.target.value, setFilter)
          }}
        // results={results}
        // value={value}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <Form.Field>
          <Radio
            label='Search Any'
            name='radioGroup'
            value='any'
          checked={!searchAll}
          // onChange={() => setAllCheck(prevState => !prevState)}
          onChange={() => {if(searchAll) setSearchAll(prevState => !prevState)}}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='Search All'
            name='radioGroup'
            value='all'
          checked={searchAll}
          // onChange={() => setAllCheck(prevState => !prevState)}
          onChange={() => {if(!searchAll) setSearchAll(prevState => !prevState)}}

          // onChange={this.handleRadioChange}
          />
        </Form.Field>
      </Grid.Column>
    </Grid>

  )
}

export default ReactTableMakerSearchBar

