import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'
import { isEmptyNullUndefined } from '../helper/Misc'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import {  Segment } from 'semantic-ui-react';
import MapsSizer from './Component-Maps-Sizer'
import ReactTableMaker from '../helper/ReactTableMaker'

import Map from '../charts/Charts-RSM-Map'
// import allStates from '../charts/allstates.json'
import './Component-Maps-Locations-Container.css'

// import DisplayState from '../helper/DisplayState';

class MapsStates extends Component {

  state = {

    selectedState: null,
    MattersForState: [],
    ReducedMattersForState: [],
    reset: false,
    dots: false,
    showDots: true,
  }

  async componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedState !== this.state.selectedState) {
      this.setState({ MattersForState: this.getMatters() })
      // console.log(this.state.selectedState)
    }
    if (prevState.MattersForState !== this.state.MattersForState || prevProps.selectedYear !== this.props.selectedYear || prevProps.selectedPartner !== this.props.selectedPartner || prevProps.selectedClient !== this.props.selectedClient) {
      // console.log(this.state.MattersForState)
      this.setState({ ReducedMattersForState: this.reduceMattersStates() })
    }
  }

  reduceMattersStates = () => {
    let array = JSON.parse(JSON.stringify(this.state.MattersForState)) || []
    if (isEmptyNullUndefined(array)) return []
    // console.log('In reduceMattersStates and this is array: ', array)
    let returnArray = array.reduce((acc, item, i, array) => {
      if (this.props.selectedYear === 0 || (new Date(item.origination_date) >= new Date(`${this.props.selectedYear}-01-01`) && new Date(item.origination_date) <= new Date(`${this.props.selectedYear}-12-31`))) {
        if (this.props.selectedPartner === 0 || this.props.selectedPartner === item.originating_employee_id) {
          if (this.props.selectedClient === 0 || this.props.selectedClient === item.client_id) {
            // console.log('This is item: ', item)
            acc.push(item)
          }
        }
      }
      return acc
    }, [])
    // console.log('This is reduceMattersStates returnArray: ', returnArray)
    return returnArray
  }

  getMatters = async () => {
    let fetchresponse = await szenokPostAPI('getStates', { fetchstr: 'mattersforspecificstate', IDNumber: this.state.selectedState },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for tablesFetch: ', fetchresponse)

    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      MattersForState: fetchresponse.payload.map(item => {
        return {
          client_id: item.client_id,
          client_name: item.client_name,
          matter_deal_amount: item.matter_deal_amount,
          matter_id: item.matter_id,
          matter_name: item.matter_name,
          originating_employee_id: item.originating_employee,
          origination_date: item.origination_date,
          state_id: item.state_id
        }})
    })
  }

  mapHandler = (event) => {
    this.setState({ selectedState: this.props.States.find(item => item.topoID === event).state_id })
  }

  clickHandler = () => {
    this.child.current.getAlert();
  }

  render() {

    let chartStyle = {
      background: "#ebf5fa",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      fontFamily: "'Fira Sans', sans-serif",
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderColor: '#a5a5a5',
      borderRadius: '15px',
      boxShadow: '2px 2px 8px #888888',
      cursor: 'pointer',
    }
    let chartWidth = 800 * this.props.viewPercentage
    let chartHeight = 600 * this.props.viewPercentage

    return (
      <div>
        <div className='leafmapcontainerparent'>
          <div className='leafmapcontainer'>
            <Segment compact raised
              style={{ backgroundColor: '#ffffe8' }}>
              <div id='States' width={chartWidth} height={chartHeight}>
                <svg
                  style={chartStyle}
                  // onClick={() => toIMGcanvas('States')}
                  height={chartHeight * this.props.maxViewPercentage / 100}
                  viewBox={`0 0 ${chartWidth} ${chartHeight}`}
                >
                  <Map ref={(ref) => this.myMap = ref}
                    selectedYear={this.props.selectedYear}
                    currentYear={this.props.currentYear}
                    selectedPartner={this.props.selectedPartner}
                    selectedClient={this.props.selectedClient}
                    Matters={this.props.Matters}
                    States={this.props.States}
                    Locations={this.props.Locations}
                    mapHandler={this.mapHandler}
                    chartWidth={chartWidth}
                    chartHeight={chartHeight}
                    zoomable={true}
                    showDots={this.state.showDots}
                    allowDots={this.props.allowDots}
                  />
                  {/* <circle r={30} fill="#F53" onClick={() => {this.myMap.zoomGetter(); console.log('resetting')}}/> */}
                  {/* Reset */}
                  {!isEmptyNullUndefined(this.state.selectedState) && <g transform={`translate(${chartWidth - 100},${chartHeight - 40})`}
                    onMouseOver={() => this.setState({ reset: true })}
                    onMouseOut={() => this.setState({ reset: false })}
                    onClick={() => this.setState({ selectedState: null, selectedPartner: 0, selectedYear: 0 })}>
                    <rect x={0} y={0} width="50" height="25"
                      fill={this.state.reset ? 'yellow' : 'blue'}
                    />
                    <text x={3} y={18} fontFamily="Verdana" fontSize="15"
                      fill={this.state.reset ? 'black' : "#fff"} alignmentBaseline="left" >Reset</text>
                  </g>}
                  {/* Turn on/off dots */}
                  {this.props.allowDots && <g transform={`translate(${chartWidth - 175},${chartHeight - 40})`}
                    onMouseOver={() => this.setState({ dots: true })}
                    onMouseOut={() => this.setState({ dots: false })}
                    onClick={() => this.setState({ showDots: !this.state.showDots })}>
                    {this.state.showDots && <rect x={0} y={0} width="69" height="25"
                      fill={'black'}
                    />}
                    <rect x={0} y={0} width={this.state.showDots ? "67" : "69"} height={this.state.showDots ? "23" : "25"}
                      fill={this.state.dots ? 'yellow' : this.state.showDots ? '#808000' : 'green'}
                    />
                    <text x={3} y={18} fontFamily="Verdana" fontSize="15"
                      fill={this.state.dots ? 'black' : "#fff"} alignmentBaseline="left" >Markers</text>
                  </g>}
                </svg>

              </div>
            </Segment>
          </div>
          <div className='leafmaplegend'>
            <MapsSizer handleIconClick={this.props.handleIconClick} viewPercentage={this.props.viewPercentage} />

          </div>
        </div>

        <div style={{paddingTop: '15px'}} >
          {!isEmptyNullUndefined(this.state.selectedState) && <h3 style={{ display: 'inline' }}>{isEmptyNullUndefined(this.state.ReducedMattersForState) ? 'No' : this.state.ReducedMattersForState.length} Matters (at least partially) in {this.props.States.find(item => item.state_id === this.state.selectedState).state_name}</h3>}
          {!isEmptyNullUndefined(this.state.selectedState) && !isEmptyNullUndefined(this.props.selectedYear) && ` (${this.props.selectedYear === 0 ? 'all years' : this.props.selectedYear})`}
          {!isEmptyNullUndefined(this.state.selectedState) && (this.props.selectedPartner === 0 ? ' [all partners]' : isEmptyNullUndefined(this.props.clients.employeesarray.find(item => item[0] === this.props.selectedPartner)) ? 'no?' : ` [${this.props.clients.employeesarray.find(item => item[0] === this.props.selectedPartner)[1]} ${this.props.clients.employeesarray.find(item => item[0] === this.props.selectedPartner)[2]}]`)}
          {!isEmptyNullUndefined(this.state.selectedState) && (this.props.selectedClient === 0 ? ' -all clients-' : ` -${this.props.ClientsMattersDropDownArray.find(client => client.ID === this.props.selectedClient).text}-`)}
          {/* !isEmptyNullUndefined(this.props.clients.employeesarray.find(item => item[0] === this.props.selectedPartner)) ? 'no?': `[${this.props.clients.employeesarray.find(item => item[0] === this.props.selectedPartner)[1]} ${this.props.clients.employeesarray.find(item => item[0] === this.props.selectedPartner)[2]}]`} */}
          {!isEmptyNullUndefined(this.state.selectedState) && !isEmptyNullUndefined(this.state.ReducedMattersForState) &&
            <ReactTableMaker passedData={this.state.ReducedMattersForState} passedColumns={MattersColumns(this.props.history)}
              handleClick={(rowID, cellID, rowValues) => {
                // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
              }}
              includeSearchBar={false}
            />
          }
        </div>



        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MapsStates))

MapsStates.defaultProps = {
  maxViewPercentage: 100,
  viewPercentage: 1,
  handleIconClick: () => { },
  maxYears: 5,
  byOriginating: true,
  currentYear: new Date().getFullYear(),
  selectedYear: new Date().getFullYear(),
  selectedPartner: 0,
  selectedClient: 0,
  States: [],
  Matters: [],
  allowDots: true,

  // handleSomething: () => { },
}


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

let MattersColumns = (history) => {
  return [

    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Matter Name</span>,
      accessor: 'matter_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Origination Date</span>,
      accessor: 'origination_date',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },

  ]
}
