import React from 'react'
import { ToastContainer,  Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function Toasts() {
  return (
    <div>

      {/* <ToastContainer
        autoClose={2000}
        position="top-center"
        className="toast-container"
        toastClassName="dark-toast"
        style={{
          width: "600px", padding: '0', margin: '0', border: '0',
        }}
      // style={{backgroundColor: '#555f33', opacity: 0.5}}
      /> */}

      <ToastContainer
        enableMultiContainer
        containerId={'Success'}
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Flip}
      />
      <ToastContainer
        enableMultiContainer
        containerId={'Error'}
        position='top-center'
        style={{ width: "700px" }}
        closeButton={true}
        hideProgressBar={true}
        closeOnClick={true}
        autoClose={false}
        transition={Flip}
      />
      <ToastContainer
        enableMultiContainer
        containerId={'Info'}
        position='top-right'
        style={{ width: "900px" }}
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Flip}
      />
      <ToastContainer
        enableMultiContainer
        containerId={'Users'}
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Flip}
      />
    </div>
  )
}
