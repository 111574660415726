
import React, { Component } from 'react'
import { szenokPostAPI } from '../helper/szenokPostAPI'
// import Papa from 'papaparse';
import { CSVLink } from "react-csv"
// import { szenokBlobAPI, downloadBlob } from '../helper/szenokBlobAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

// import JSZip from 'jszip'

// import Profile from '../layout/Profile'
import { Icon, Button, Dropdown, Grid } from 'semantic-ui-react';
import { isEmptyNullUndefined } from '../helper/Misc';

// import { reduxupdate } from '../helper/ReduxHelp'

import ReactTableMaker from '../helper/ReactTableMaker'

class DownLoadTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selectedTable: null,
      DBTablesDropDownArray: null,
      tableCheck: false,
      Table: [],
    };

    // this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.getTableSizes()

  }

  getTableSizes = async () => {
    // console.log('In getTableSizes...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getDatabase', { fetchstr: 'tablesSizes' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getTableSizes: ', fetchresponse.payload)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      DBTables: fetchresponse.payload.map(table => table.TableName),
      DBTablesDropDownArray: this.TableDropDownArray(fetchresponse.payload.map(table => table.TableName))
    })
  }

  getTable = async (table) => {
    // console.log('In getTableSizes...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getDatabase', { fetchstr: 'getTable', table: this.state.selectedTable },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getTableSizes: ', fetchresponse.payload)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Table: fetchresponse.payload
    }, () => this.setState({ tableCheck: !isEmptyNullUndefined(this.state.Table) ? true : false }))
  }

  getFields = async () => {
    // console.log('In getTableSizes...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getDatabase', { fetchstr: 'tableFields', table: this.state.selectedTable },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getTableSizes: ', fetchresponse.payload)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      fields: fetchresponse.payload,
      fieldsColumns: fetchresponse.payload.map(item => item.COLUMN_NAME).slice(1, fetchresponse.payload.length)
    }, () => {
      let columns = this.state.fieldsColumns.map(item => {
        return {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>{item}</span>,
          accessor: item,
          show: true,
          sortType: 'alphanumeric',
          Filter: ''
        }
      })
      this.setState({ TableColumns: columns })
      // console.log('TableColumns: ', columns)
    }
    )
  }

  TableDropDownArray = (tables) => {
    var array = tables.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `EID${result}`
      obj.text = result
      obj.value = result
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }


  render() {

    return (
      <div>
        <h1> <Icon name='cloud download' size='large' circular />Download Table as CSV</h1>

        <Grid>

          <Grid.Row>
            <Grid.Column width={8}>

              <h3>Pick a Table (into which to insert CSV info)</h3>
              <Dropdown
                placeholder='Select Table'
                name="Table"
                width={6}
                required
                search
                label='Table'
                selection
                value={this.state.selectedTable}
                // onBlur={() => { setFieldTouched('ClientMatter', true) }}
                options={this.state.DBTablesDropDownArray}
                // value={this.state.success === true ? null : values.TType}
                loading={this.state.success}
                onChange={async (e, { name, value }) => {
                  // setFieldValue(name, value)
                  // setFieldValue('InvoiceNumber', '')
                  this.setState({
                    selectedTable: value,
                  }, () => {
                    this.getFields()
                    this.getTable()
                    // this.getInvoices(value.split(',')[0])
                    // setFieldValue('PartnerPick', this.state.SelectedEmployeeID)
                  }
                  )
                }}
              />


            </Grid.Column>

          </Grid.Row>
          {!isEmptyNullUndefined(this.state.TableColumns) &&
            <Grid.Row>
              <Grid.Column width={2}><h5>Columns from Table:</h5></Grid.Column>
              <Grid.Column width={14}>
                <ReactTableMaker passedData={[]} passedColumns={this.state.TableColumns}
                  // handleClick={(rowID, cellID, rowValues) => {console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)}}
                  color={this.state.tableCheck ? 'green' : 'blue'}
                  includeSearchBar={false}
                  includePaginationFooter={false}
                />
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Column width={16}>
              <h3>Clicking Button Will Download CSV Data from Table</h3>
              <p />
              <Button
                color='green'
                disabled={!this.state.tableCheck}
                onClick={async () => {
                  // alert('Eventually, this will upload CSV info into the appropriate table...')

                  // await szenokPostAPI('uploadCSV', { actionType: 'uploadToTable', table: this.state.selectedTable, csvarray: this.state.CSVArray },
                  //   this.props.login.currentToken)

                  // setTimeout(() => {
                  //   reduxupdate('clients')
                  //   reduxupdate('clientsarray')
                  //   reduxupdate('employees')
                  // }, 400)

                }}>
                <CSVLink style={{ float: 'right', marginRight: '15px' }} data={this.state.Table} enclosingCharacter={`'`}>
                Download Table as CSV
                </CSVLink>
              </Button>
            </Grid.Column>
          </Grid.Row>

        </Grid>

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default connect(mapStateToProps, mapDispatchToProps)(DownLoadTable)


// let urls = new WeakMap()

// let blobUrl = blob => {
//   if (urls.has(blob)) {
//     return urls.get(blob)
//   } else {
//     let url = URL.createObjectURL(blob)
//     urls.set(blob, url)
//     return url
//   }
// }





DownLoadTable.defaultProps = {
  file: null,
}

