import React, { useState } from 'react'
import { isEmptyNullUndefined } from '../helper/Misc';
import {  Icon, List } from 'semantic-ui-react';
import { szenokPostAPI } from '../helper/szenokPostAPI'
import { useDispatch } from 'react-redux';


import { reduxupdate } from '../helper/ReduxHelp';

import { fetchResetState, fetchErrors } from '../../actions/fetchActions'

import BillModal from '../helper/BillModal'


const BillsLister = (props) => {

  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [billID, setBillID] = useState(null)

  const clearBill = () => {
    setModal(false)
    setBillID(null)
  }

  const DeleteBill = async (bill_id) => {
    // console.log('Running DeleteBill on this Bill: ', bill_id)

    //Clear fetcherrors, info and such
    dispatch(fetchResetState('all'))

    try {
      let fetchresponse = {}


      if (!isEmptyNullUndefined(bill_id))
        fetchresponse = await szenokPostAPI('deleteBill', {
          billID: bill_id
        }, props.token)

      // console.log('Now printing fetchresponse for DeleteBill: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        reduxupdate('bills')
        props.getBills()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      //FIX:  I don't know if i need this always, but if I don't do this push stuff, sometimes the entire
      //string will come out as an array of characters, instead.  Need to figure out a way to check
      //if I have a proper string or not.
      let errorObj = []
      errorObj.push(error.toString())
      dispatch(fetchErrors(errorObj))
    }
  }



  // console.log('this is props: ', props)
  if (!isEmptyNullUndefined(props.Bills)) {
    // console.log('In here and this is props.Bills: ', props.Bills)
    return (
      <React.Fragment>

        {props.Bills.map((item, i) => {
          return <BillIndividual
            bill_id={item.bill_id}
            bill_amount={item.bill_amount}
            bill_description={item.bill_description}
            DeleteBill={DeleteBill}
            key={`Bill${i}`}
            setModal={setModal}
            setBillID={setBillID}
          />
        })}


        <BillModal
          closeModal={() => {
            this.setBillModalOpen(false)
            // this.setRow()
          }}
          confirmModal={() => {
            this.setBillModalOpen(false)

            // this.gatherCPartyData()

          }}
          isOpen={modal}
          // deleteSubject='Bill'
          lawfirm={props.lawfirm}
          lawfirm_id={props.LawFirmID}
          counterparty={props.counterparty}
          counterparty_id={props.counterparty_id}
          counterparty_matter_id={props.counterparty_matter_id}
          getBills={props.getBills}
          update={true}
          billID={billID}
          clearBill={clearBill}
        />


      </React.Fragment>
    )
  }
  else return null
}


export default BillsLister

const BillIndividual = (props) => {
  // console.log('This is the props in BillIndividual: ', props)
  if (!isEmptyNullUndefined(props.bill_id))
    return (

      <List>
        <Icon name='inbox' size='small' />
        <React.Fragment>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.bill_amount)}

          {!props.currentObserver && <Icon name='edit' color='grey' size='tiny' bordered link onClick={() => {
            // if (props.lawyertype) props.handleDeleteLawyer(props.person_id, props.counterparty_matter_id)
            // else props.handleDeleteTycoon(props.person_id, props.counterparty_matter_id)
            props.setModal(true)
            props.setBillID(props.bill_id)
          }
          } />}
          {!props.currentObserver && <Icon name='delete' color='red' size='tiny' bordered link onClick={() => {
            // if (props.lawyertype) props.handleDeleteLawyer(props.person_id, props.counterparty_matter_id)
            // else props.handleDeleteTycoon(props.person_id, props.counterparty_matter_id)
            props.DeleteBill(props.bill_id)
          }
          } />}
          {!isEmptyNullUndefined(props.bill_description) && <React.Fragment><br />{props.bill_description}</React.Fragment>}

          {/* <br />And this is the personID: {props.person_id}
     <br />And this is the counterparty_matter_id: {props.counterparty_matter_id}
     <br />And this is the props.lawyer: {props.lawyer ? 'true' : 'false'} */}
        </React.Fragment>
      </List>
    )
  else return null
}

