import React from 'react'
import { Link } from 'react-router-dom'


import { Icon } from 'semantic-ui-react';

import ReactTableMaker from '../helper/ReactTableMaker'
import { getHighlightedTextRed } from '../helper/FormHelper'
// import { isEmptyNullUndefined } from '../helper/Misc'

function SearchResults(props) {
  // console.log(`In SearchResults with these props: ${props}`)

  return (

    <React.Fragment>

      <ReactTableMaker passedData={props.Results}
        // passedColumns={PrecedentsColumns()}
        passedColumns={ResultsColumns(props.type, props.history, props.SearchString)}
        handleClick={(rowID, cellID, rowValues) => {
          // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
        }}
        includeSearchBar={false}
        color='olive'
      />


    </React.Fragment>
  )
}

SearchResults.defaultProps = {
  Results: [],
  type: '',
  invoiceID: null,
  matterID: null,
  ClientID: null,
  getInvoicesOutstanding: () => { },
  handleDeleteInvoice: () => { },
  handleCancelDelete: () => { },
}


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const ResultsColumns = (type, history, SearchString) => {
  let results = []

  switch (type) {
    case 'clients':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {getHighlightedTextRed('prec', cell.value, SearchString)}
            </span>
        },
      ]
      break;
    case 'precedents':
      results = [
        // {
        //   Header: () => <span style={{ 'float': 'left' }}>Precedent ID</span>,
        //   accessor: 'precedent_id',
        //   show: true,
        //   width: 0,
        //   sortType: 'alphanumeric',
        //   Filter: 'defaultColumnFilterLeft'
        // },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Type</span>,
          accessor: 'precedent_type',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{getHighlightedTextRed('prec', cell.value, SearchString)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent File</span>,
          accessor: 'precedent_file',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{getHighlightedTextRed('prec', cell.value, SearchString)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Description</span>,
          accessor: 'precedent_description',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{getHighlightedTextRed('prec', cell.value, SearchString)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Precedent Date</span>,
          accessor: 'precedent_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          // Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'precedent_id',
          // show: true,
          // sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }} > <Link to={`/editprecedent/${cell.value}`}><Icon name='edit' />Edit</Link></span>
        },
      ]
      break;
    case 'matternotes':
      results = [
        // {
        //   Header: () => <span style={{ 'float': 'left' }}>Note ID</span>,
        //   accessor: 'note_id',
        //   show: true,
        //   width: 0,
        //   sortType: 'alphanumeric',
        //   Filter: 'defaultColumnFilterLeft'
        // },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note By</span>,
          accessor: 'employee',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note Date</span>,
          accessor: 'note_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note</span>,
          accessor: 'note',
          show: true,
          Filter: 'defaultColumnFilterLeft',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{getHighlightedTextRed('prec', cell.value, SearchString)}</span>
        },

      ]
      break;
    case 'lawyernotes':
      results = [
        // {
        //   Header: () => <span style={{ 'float': 'left' }}>Note ID</span>,
        //   accessor: 'note_id',
        //   show: true,
        //   width: 0,
        //   sortType: 'alphanumeric',
        //   Filter: 'defaultColumnFilterLeft'
        // },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
          accessor: 'counterparty',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Law Firm</span>,
          accessor: 'law_firm',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note By</span>,
          accessor: 'employee',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note Date</span>,
          accessor: 'note_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note</span>,
          accessor: 'note',
          show: true,
          Filter: 'defaultColumnFilterLeft',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{getHighlightedTextRed('prec', cell.value, SearchString)}</span>
        },

      ]
      break;
    case 'tycoonnotes':
      results = [
        // {
        //   Header: () => <span style={{ 'float': 'left' }}>Note ID</span>,
        //   accessor: 'note_id',
        //   show: true,
        //   width: 0,
        //   sortType: 'alphanumeric',
        //   Filter: 'defaultColumnFilterLeft'
        // },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Counterparty</span>,
          accessor: 'counterparty',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note By</span>,
          accessor: 'employee',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note Date</span>,
          accessor: 'note_date',
          show: true,
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Note</span>,
          accessor: 'note',
          show: true,
          Filter: 'defaultColumnFilterLeft',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{getHighlightedTextRed('prec', cell.value, SearchString)}</span>
        },

      ]
      break;
    default:
    // code block
  }
  return results
}

export default SearchResults
