import React, { useState, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import { isEmptyNullUndefined } from "../helper/Misc";
import { Map, Marker, Tooltip, TileLayer, ZoomControl } from 'react-leaflet'
import L from 'leaflet'
import useSupercluster from "use-supercluster"
import GoogleGeolocateBar from './Component-Maps-Locations-Geolocation-Bar'
import AddLocation from './Component-Maps-Location-AddLocation'
import ZoomChoices from './Component-Maps-Location-ZoomChoices'
import { Icon as ReactIcon } from 'semantic-ui-react';
import 'leaflet/dist/leaflet.css'
import './Component-Maps-Locations-Map.css'

import { setCurrentZoomLevel, setCurrentLatitude, setCurrentLongitude } from '../../actions/mapActions'
// import store1 from '../../store.js'
import { useSelector, useDispatch } from 'react-redux';


const LeafletMap = ({
  Facilities = [],
  ClientsMatters = [],
  Height = '100vh',
  Width = '100%',
  history,
  zoomProp = 10,
  center = { lat: 40.73, lng: -73.93 },
  clusterSize = 75,
  sendZoomCenterToParent = () => { },
  individualCenter = [],
  GOOGLEAPI }) => {


  const [bounds, setBounds] = useState(null);
  // const [bounds2, setBounds2] = useState([]);
  const [zoom, setZoom] = useState(13);
  const mapRef = useRef();
  const currentZoomLevel = useSelector(state => state.maps.currentZoomLevel)
  const currentLatitude = useSelector(state => state.maps.currentLatitude)
  const currentLongitude = useSelector(state => state.maps.currentLongitude)
  const dispatch = useDispatch()
  // const [stateClusters, setStateClusters] = useState([])
  // const [stateSupercluster, setStateSupercluster] = useState([])
  const [selectedFacility, setSelectedFacility] = useState(null)
  const [address, setAddress] = useState('')
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null })
  const [draggedCoordinates, setDraggedCoordinates] = useState({ lat: null, lng: null })

  const points = Facilities.map(facility => ({
    type: "Feature",
    properties: { cluster: false, facilityID: facility.location_id, category: 'facility' },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(facility.longitude), //longitude first?
        parseFloat(facility.latitude)
      ]
    }
  }))
  // console.log('This is points: ', points)


  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: clusterSize, maxZoom: 20 },
  })
  // console.log('This is clusters: ', clusters)

  //Save clusters to state (just for testing purposes)
  // React.useEffect(() => {
  //   setStateClusters(clusters)
  // }, [clusters])
  // useEffect(() => {
  //   setStateSupercluster(supercluster)
  // }, [supercluster])

  // get map bounds
  function updateMap() {
    // console.log('Getting bounds, zoom, etc....')
    const b = mapRef.current.leafletElement.getBounds();
    const z = mapRef.current.leafletElement.getZoom()
    // const c = mapRef.current.leafletElement.getCenter()
    // console.log('This is c: ', c)
    // console.log('Received bounds, zoom, etc....')
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat
    ]);
    // setBounds2(b)
    setZoom(z);
    mapRef.current.leafletElement.on('dragend', function () {
      // const b2 = mapRef.current.leafletElement.getBounds();
      // const z1 = mapRef.current.leafletElement.getZoom()
      // const c2 = mapRef.current.leafletElement.getCenter()
      // if (bounds2 !== b2) {
      //   console.log('This is c2: ', c2)
      //   // console.log('Moving to : ', mapRef.current.leafletElement.getCenter())
      //   // sendZoomCenterToParent(z, c)
      //   // dispatch(setCurrentZoomLevel(z))
      //   console.log(`This is currentLatitude: ${currentLatitude} and this is c2.lat: ${c2.lat} and they are ${currentLongitude === c2.lat ? 'equal' : 'not equal'}`)
      //   // console.log(`currentLatitude is a ${typeof currentLatitude}`)
      //   // console.log(`c2.lat is a ${typeof c2.lat}`)
      //   // if (currentLatitude !== c2.lat) dispatch(setCurrentLatitude(c2.lat))
      //   // if (currentLongitude !== c2.lng) dispatch(setCurrentLongitude(c2.lng))

      // }






      // console.log('Moving to : ', mapRef.current.leafletElement.getCenter())
      // sendZoomCenterToParent(mapRef.current.leafletElement.getZoom(), mapRef.current.leafletElement.getCenter())
      // dispatch(setCurrentZoomLevel(z))
      // console.log(`This is currentLatitude: ${currentLatitude} and this is c.lat: ${c.lat}`)
      // if (currentLatitude !== c.lat) dispatch(setCurrentLatitude(c.lat))
      // if (currentLongitude !== c.lng) dispatch(setCurrentLongitude(c.lng))

    })
  }

  React.useEffect(() => {
    updateMap();
  }, []);

  React.useEffect(() => {
    // console.log('Changed bounds to: ', bounds)
    const z1 = mapRef.current.leafletElement.getZoom()
    const c2 = mapRef.current.leafletElement.getCenter()
    // console.log(`This is currentLatitude: ${currentLatitude} and this is c2.lat: ${c2.lat} and they are ${currentLongitude === c2.lat ? 'equal' : 'not equal'}`)
    if (currentZoomLevel !== z1) dispatch(setCurrentZoomLevel(z1))
    if (currentLatitude !== c2.lat) dispatch(setCurrentLatitude(c2.lat))
    if (currentLongitude !== c2.lng) dispatch(setCurrentLongitude(c2.lng))
  }, [bounds, currentZoomLevel, currentLatitude, currentLongitude, dispatch]);


  React.useEffect(() => {
    if (!isEmptyNullUndefined(individualCenter)) {
      mapRef.current.leafletElement.setView(individualCenter, 10, {
        animate: true
      })
      // sendZoomCenterToParent(10, {lat: individualCenter.lat, lng: individualCenter.lng})
      // console.log(individualCenter)

    }
  }, [individualCenter]);

  const icons = {};
  const fetchIcon = (count, size) => {
    if (!icons[count]) {
      icons[count] = L.divIcon({
        html: `<div class="cluster-marker2" style="width: ${size}px; height: ${size}px;">
          ${count}
        </div>`
      });
    }
    return icons[count];
  };

  //Get current lat-lng
  const handleClick = (e) => {
    // console.log(e.latlng);
    setSelectedFacility(null)
  }

  //Set Address if receive info from geolocating child component
  const handleAddressReceipt = (address) => {
    setAddress(address)
  }

  //Set Coordinates if receive info from geolocating child component
  const handleCoordinatesReceipt = (coordinates) => {
    setCoordinates(coordinates)
  }

  React.useEffect(() => {
    !isEmptyNullUndefined(coordinates.lat) && mapRef.current.leafletElement.setView([coordinates.lat, coordinates.lng], 17, {
      animate: true
    })
  }, [coordinates]);

  React.useEffect(() => {
    // console.log('Trying to change coordinates because of draggedCoordinates')
    !isEmptyNullUndefined(draggedCoordinates.lat) && setCoordinates(draggedCoordinates)
  }, [draggedCoordinates]);

  React.useEffect(() => {
    // This will force a change the size of the map if Height is changed.
    mapRef.current.leafletElement.invalidateSize()
  }, [Height]);


  return (
    <div style={{ height: Height, width: Width }} className='parent-div2'>
      {/* Need to add this to the index.html to make this work: <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places"></script> */}
      {/* <AddressForm /> */}
      {/* Zoom: {currentZoomLevel}, Lat: {currentLatitude}, Lng: {currentLongitude} */}
      <Map 
        center={[currentLatitude, currentLongitude]}
        zoom={currentZoomLevel}
        //Not sure why the leaflet map doesn't resize when the parent div ('parent-div2') resizes.
        style={{height: Height}}
        onMoveEnd={updateMap}
        ref={mapRef}
        zoomControl={false}
        onClick={handleClick}
        attributionControl={false}
      >
        {/* <MapInfo /> */}
        <TileLayer
          //Different map choices are here:  https://leaflet-extras.github.io/leaflet-providers/preview/
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          // url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png"
          // url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          attribution=''
        />

        <ZoomControl position='topright' />

        {clusters.map((cluster, i) => {
          // every cluster point has coordinates
          const [longitude, latitude] = cluster.geometry.coordinates;
          // the point may be either a cluster or a crime point
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties;

          // we have a cluster to render
          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                position={[latitude, longitude]}
                icon={fetchIcon(
                  pointCount,
                  10 + (pointCount / points.length) * 40
                )}
                onclick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    17
                  );
                  const leaflet = mapRef.current.leafletElement;
                  leaflet.setView([latitude, longitude], expansionZoom, {
                    animate: true
                  });
                }}
              />
            );
          }

          // we have a single point (facility) to render
          return (
            <Marker
              key={`facility-sm:${cluster.properties.facilityID}-${i}`}
              position={[latitude, longitude]}
              icon={L.divIcon({
                className: "custom icon", iconAnchor: [12, 18], tooltipAnchor: [12, -22], html: ReactDOMServer.renderToString(
                  <ReactIcon name='map pin' 
                  color={!isEmptyNullUndefined(facilityFinder(Facilities, cluster.properties.facilityID)) ? facilityFinder(Facilities, cluster.properties.facilityID).color : 'violet'} 
                  disabled={isEmptyNullUndefined(facilityFinder(Facilities, cluster.properties.facilityID)) ? false : !isEmptyNullUndefined(facilityFinder(Facilities, cluster.properties.facilityID).closing_date) ? false : true} 
                  size='large' />
                )
              })}
              onClick={() => {
                // console.log('This is the facility: ', Facilities.find(facility => facility.location_id === cluster.properties.facilityID).matter_name)
                setSelectedFacility(cluster.properties.facilityID)
                mapRef.current.leafletElement.setView([facilityFinder(Facilities, cluster.properties.facilityID).latitude, facilityFinder(Facilities, cluster.properties.facilityID).longitude], zoom, {
                  animate: true
                })
              }}
            >
              {!isEmptyNullUndefined(facilityFinder(Facilities, cluster.properties.facilityID)) && <Tooltip direction='top' opacity={1} >{
                <div style={{ backgroundColor: '#111', color: '#fff' }}>
                  <span style={{ color: '#ff8040' }}>Client:  </span>{facilityFinder(Facilities, cluster.properties.facilityID).client_name}<br />
                  <span style={{ color: '#ff8040' }}>Matter:  </span>{facilityFinder(Facilities, cluster.properties.facilityID).matter_name}<br />
                  <span style={{ color: '#ff8040' }}>Origination Date:  </span>{facilityFinder(Facilities, cluster.properties.facilityID).origination_date}<br />
                  <span style={{ color: '#ff8040' }}>Address:  </span>{facilityFinder(Facilities, cluster.properties.facilityID).address}<br />
                </div>
              }</Tooltip>}
            </Marker>
          );
        })}
        {!isEmptyNullUndefined(coordinates.lat) &&
          <Marker
            position={[coordinates.lat, coordinates.lng]}
            icon={L.divIcon({ className: "custom icon", iconAnchor: [30, 48], tooltipAnchor: [30, -52], html: ReactDOMServer.renderToString(<ReactIcon name='pin' color='violet' size='huge' />) })}
            onClick={() => {
              // console.log('This is the facility: ', Facilities.find(facility => facility.location_id === cluster.properties.facilityID).matter_name)
            }}
            draggable={true}
            ondragend={(e) => {
              let newcoords = e.target.getLatLng()
              // console.log(`This is new position: [${newcoords.lat}, ${newcoords.lng}]`)
              setDraggedCoordinates({ lat: newcoords.lat, lng: newcoords.lng })
            }}
          >
            <Tooltip direction='top' opacity={1} >{
              <div>
                <span style={{ color: '#ff8040' }}>Address:  </span>{address}<br />
                <span style={{ color: '#ff8040' }}>Latitude:  </span>{coordinates.lat}<br />
                <span style={{ color: '#ff8040' }}>Longitude:  </span>{coordinates.lng}<br />
                (Drag icon if not correct location)
              </div>
            }</Tooltip>
          </Marker>
        }
      </Map>
      {!isEmptyNullUndefined(selectedFacility) && facilityInfoIcon(Facilities.find(facility => facility.location_id === selectedFacility), history)}
      <GoogleGeolocateBar
        GOOGLEAPI={GOOGLEAPI}
        sendAddressToParent={handleAddressReceipt}
        sendCoordinatesToParent={handleCoordinatesReceipt}
        draggedCoordinates={draggedCoordinates} />
      <AddLocation
        ClientsMatters={ClientsMatters}
        address={address}
        coordinates={coordinates}
        sendAddressToParent={handleAddressReceipt}
        sendCoordinatesToParent={handleCoordinatesReceipt}
      />
      <ZoomChoices
        leaflet={!isEmptyNullUndefined(mapRef.current) ? mapRef.current.leafletElement : null}
        sendZoomCenterToParent={sendZoomCenterToParent}
      />
    </div>
  )
}

export default LeafletMap


const facilityInfoIcon = (facility, history) => {
  // console.log(facility)
  return (
    <div className='info-card2'>
      <div className='info-card-header2'>FACILITY INFORMATION:</div>
      <div className='info-card-details2'>
        <div className='info-card-clientmatter2'>
          <span onClick={() => history.push(`/client/${facility.client_id}?cname=${facility.client_name}&clientID=${facility.client_id}`)}>Client: {facility.client_name}<br /></span>
        </div>
        <div className='info-card-clientmatter2'>
          <span onClick={() => history.push(`/matter/${facility.matter_id}?matter=${facility.matter_name}&matterID=${facility.matter_id}`)}>Matter: {facility.matter_name}<br /></span>
        </div>
                Address: {facility.address}
        <br />
                Latitude: {facility.latitude}
        <br />
                Longitude: {facility.longitude}
      </div>
    </div>
  );
};


let facilityFinder = (Facilities, facilityID) => {
  // console.log('This is Facilities: ', Facilities, 'and this is facilityID: ', facilityID)
  let found = Facilities.find(item => item.location_id === facilityID)
  // console.log(found)
  if (found) return found
  else return null
}