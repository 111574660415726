import React, { Component, createRef } from 'react'
import { withRouter, Link } from 'react-router-dom'
// import { HashLink as Link } from 'react-router-hash-link'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import * as mapActions from '../../actions/mapActions'
import * as matterInfoActions from '../../actions/matterInfoActions'
import { connect } from 'react-redux';

import { Grid, Segment, Table, Icon, Menu, Divider, Sticky } from 'semantic-ui-react';

import MatterInfoNotes from './Component-MatterInfo-Notes';
import MattersInfoCparties from './Component-MatterInfo-CPartyInfo';
import Invoices from './Component-Invoices';
import Precedents from './Component-Precedents';
// import AddCounterparty from './Component-AddCounterparty';
// import CounterpartyInfo from './Component-CounterpartyInfo';
// import AddLawFirms from './Component-AddLawFirm';
// import AddPerson from './Component-AddPerson';
// import AddNote from './Component-AddNote';
import LocationsInfo from './Component-LocationsInfo'

import queryString from 'query-string'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

// import ErrorMessage from '../helper/MessageBar'
// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
import { isEmptyNullUndefined } from '../helper/Misc';

import { StatesGetAllStates } from '../charts/Charts-AClass-Functions'
import Map from '../charts/Charts-RSM-Map'
import allStates from '../charts/allstates.json'

class MatterInfo extends Component {

  state = {
    TokenReady: false,
    Loaded: false,
    MapStatesLoaded: false,
    MapInfoLoaded: false,
    activeMenuItem: 'Basic Info',
    querystr: '',
    MatterInfo: {
      // Counterparties: [],
    },
    Counterparties: [],
    Lawyers: [],
    Tycoons: [],
    LawyerNotes: [],
    TycoonNotes: [],
    MatterNotes: [],
    Locations: [],
    Bills: [],
    // NotesLength: 0,
    BasicInfoArray: [],
    ListOfStates: [],
    ArrayOfStates: [],
    mapConfig: {},
    showCounterpartyInfoForm: false,
    showAddCounterpartyForm: false,
    showAddLawFirmForm: false,
    showAddLawyerForm: false,
    showAddTycoonForm: false,
    showAddNoteForm: false,
    selectedCParty: null,
    selectedCPartyMatterID: null,
    selectedLawFirmID: null,
    menuExpanded: true,
  }

  handleContextRef = ref => this.setState({ context: ref })

  handleItemClick = (e, { name }) => this.setState({ activeMenuItem: name })

  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
    }
    const querystr = queryString.parse(this.props.location.search)
    this.setState({ querystr: querystr })
    this.getMatterInfo()
  }


  // //This is to get rid of the unmounted component no op (as well as that signal: this.abortController stuff below))
  // abortController = new AbortController()
  // componentWillUnmount() {
  //   this.abortController.abort()
  // }

  componentDidUpdate(prevProps) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true, FilteredSearchData: this.state.Data })
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      // setTimeout(() => this.getMatterInfo(), 200)
    }
    if (prevProps.matterInfo.showAddBusinessMan !== this.props.matterInfo.showAddBusinessMan && !this.props.matterInfo.showAddBusinessMan) {
      setTimeout(() => this.getTycoons(), 200)
    }
    if (prevProps.matterInfo.showAddLawyer !== this.props.matterInfo.showAddLawyer && !this.props.matterInfo.showAddLawyer) {
      setTimeout(() => this.getLawyers(), 200)
    }
  }

    showAddNoteFormToggle = (option) => {
    // console.log('Clicking showaddnoteformtoggle and this option: ', option)
    switch (option) {
      case false:
        this.setState({ showAddNoteForm: false })
        break
      case true:
        this.setState({ showAddNoteForm: true })
        break
      default:
        this.setState(prevState => ({ showAddNoteForm: !prevState.showAddNoteForm }))
    }
  }

  menuToggle = (option) => {
    // console.log('Clicking menueExpanded and this option: ', option)
    switch (option) {
      case false:
        this.setState({ menuExpanded: false })
        break
      case true:
        this.setState({ menuExpanded: true })
        break
      default:
        this.setState(prevState => ({ menuExpanded: !prevState.menuExpanded }))
    }
  }

  selectCParty = (CPartyID, CPartyMatterID) => {
    // console.log('Clicking selectCParty and this option: ', CPartyID, ' and this is the counterparty_matter: ', CPartyMatterID)
    let lawFirmID = this.state.Counterparties.find(o => o.counterparty_matter_id === CPartyMatterID).law_firm_id
    // console.log('Now selecting law firm: ', lawFirmID)
    this.setState({ selectedCParty: CPartyID, selectedCPartyMatterID: CPartyMatterID, selectedLawFirmID: lawFirmID })
  }

  getMatterInfo = async () => {
    // console.log('Launching getTable...')

    // Get the basic matter information
    let fetchresponse = await szenokPostAPI('matterInfo', { fetchstr: 'getMatter', matterID: this.props.match.params.matterID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getMatter: ', fetchresponse)
    this.setState({ MatterInfo: fetchresponse.payload, MatterInfoForStates: fetchresponse.payload }, () => this.setState({ MapStatesLoaded: true }))

    fetchresponse = await szenokPostAPI('getCounterparty', { IDNumber: this.state.querystr.matterID, fetchstr: 'getCPartiesForMatter' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse for getCPartiesForMatter: ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {
      this.setState({ Counterparties: fetchresponse.payload }) //, MatterInfo: { ...this.state.MatterInfo, Counterparties: fetchresponse.payload }
    }
    else this.setState({ Counterparties: [] })

    fetchresponse = await szenokPostAPI('getCounterparty', { IDNumber: this.state.querystr.matterID, fetchstr: 'getLawyersForMatter' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse for getLawyerForMatter: ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {
      this.setState({ Lawyers: fetchresponse.payload }) //, MatterInfo: { ...this.state.MatterInfo, Counterparties: fetchresponse.payload }
      // console.log('THis is law_firm_id: ', this.state.Counterparties.law_firm_id)
      // let lawfirm = this.props.clients.lawfirmsarray.find(o => o[0] === this.state.MatterInfo.law_firm_id)[1]
    }

    this.getLawyers()
    this.getTycoons()
    this.getNotes()
    this.getLocations()
    this.getBills()

    StatesGetAllStates(this.props.maxYears, this.props.login.currentToken, (value) => this.setState({
      States: value.map(item => ({
        ...item,
        amount: 0,
        topoID: parseInt(allStates.find(topo => topo.id === item.state_abbr).val)
      }))
    }, () => this.setState({ MapInfoLoaded: true })))

    this.setState({ BasicInfoArray: this.createBasicInfo(this.props.login.currentObserver) })
    this.setState({ Loaded: true })
  }

  // stateShader = (stateArray) => {
  //   let obj = {}
  //   let stateCode = ''
  //   for (let i = 0; i < stateArray.length; i++) {
  //     stateCode = this.state.statesArray.find(item => item.state_id === stateArray[i]).state_abbr
  //     // console.log('This is stateCode: ', stateCode)
  //     obj[stateCode] = { fill: '#CC0000' }
  //   }
  //   this.setState({ mapConfig: obj })
  // }

  getLawyers = async () => {
    let fetchresponse = await szenokPostAPI('getCounterparty', { IDNumber: this.state.querystr.matterID, fetchstr: 'getLawyersForMatter' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse for getLawyerForMatter: ', fetchresponse)
    // if (!isEmptyNullUndefined(fetchresponse.payload)) {
    this.setState({ Lawyers: fetchresponse.payload }) //, MatterInfo: { ...this.state.MatterInfo, Counterparties: fetchresponse.payload }
    // console.log('THis is law_firm_id: ', this.state.Counterparties.law_firm_id)
    // let lawfirm = this.props.clients.lawfirmsarray.find(o => o[0] === this.state.MatterInfo.law_firm_id)[1]
    // console.log('Updated lawyers to this: ', this.state.Lawyers)
    // }
  }

  getTycoons = async () => {
    let fetchresponse = await szenokPostAPI('getCounterparty', { IDNumber: this.state.querystr.matterID, fetchstr: 'getTycoonsForMatter' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse for getTycoonsForMatter: ', fetchresponse)
    // if (!isEmptyNullUndefined(fetchresponse.payload)) {
    this.setState({ Tycoons: fetchresponse.payload }) //, MatterInfo: { ...this.state.MatterInfo, Counterparties: fetchresponse.payload }
    // console.log('THis is law_firm_id: ', this.state.Counterparties.law_firm_id)
    // let lawfirm = this.props.clients.lawfirmsarray.find(o => o[0] === this.state.MatterInfo.law_firm_id)[1]
    // console.log('Updated tycoons to this: ', this.state.Tycoons)
    // }
  }

  getNotes = async () => {
    let fetchresponse = await szenokPostAPI('getCounterparty', { IDNumber: this.state.querystr.matterID, fetchstr: 'getLawyerNotesForMatter' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse for getLawyerNotesForMatter: ', fetchresponse)
    // if (!isEmptyNullUndefined(fetchresponse.payload)) {
    this.setState({ LawyerNotes: fetchresponse.payload }) //, MatterInfo: { ...this.state.MatterInfo, Counterparties: fetchresponse.payload }
    // }
    fetchresponse = await szenokPostAPI('getCounterparty', { IDNumber: this.state.querystr.matterID, fetchstr: 'getTycoonNotesForMatter' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse for getTycoonNotesForMatter: ', fetchresponse)
    // if (!isEmptyNullUndefined(fetchresponse.payload)) {
    this.setState({ TycoonNotes: fetchresponse.payload }) //, MatterInfo: { ...this.state.MatterInfo, Counterparties: fetchresponse.payload }
    // }
    fetchresponse = await szenokPostAPI('getCounterparty', { IDNumber: this.state.querystr.matterID, fetchstr: 'getMatterNotes' },
      this.props.login.currentToken)
    // console.log('This is fetchresponse for getNotesForMatter: ', fetchresponse)
    // if (!isEmptyNullUndefined(fetchresponse.payload)) {
    this.setState({ MatterNotes: fetchresponse.payload }) //, MatterInfo: { ...this.state.MatterInfo, Counterparties: fetchresponse.payload }
    // }
    this.setState({ NotesLength: this.state.LawyerNotes.length + this.state.TycoonNotes.length + this.state.MatterNotes.length })
  }

  getLocations = async () => {
    // console.log('Launching getTable...')

    let fetchresponse = await szenokPostAPI('getStates', { fetchstr: 'locationsForSpecificMatter', IDNumber: this.props.match.params.matterID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for locationsformatter: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Locations: fetchresponse.payload.map(item => { return { ...item, latitude: item.location.x, longitude: item.location.y } })
    })
  }

  getBills = async () => {
    // console.log('Launching getTable...')

    let fetchresponse = await szenokPostAPI('billInfo', { fetchstr: 'getBillsForMatter', matterID: this.props.match.params.matterID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for billsformatter: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Bills: fetchresponse.payload.map(item => item)
    })
  }


  handleDeleteNote = async (noteID, type = null) => {
    // console.log('Running handleDeleteNote...')
    // console.log('This is props: ', this.props)
    // console.log(`Running handeDeleteNote and this is noteID: ${noteID} and this is type: ${type}`)
    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}

      //Find out what kind of note it is and delete the matching table

      if (type === 'lawyerNote') {
        fetchresponse = await szenokPostAPI('getNote', {
          fetchstr: 'getLawyerCounterpartiesForNote',
          IDNumber: noteID
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for getLawyerCounterpartiesForNote: ', fetchresponse)
        // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

        let tempNLCID = fetchresponse.payload[0].note_lawyer_counterparty_id
        // console.log('This is NLCID: ', tempNLCID)

        fetchresponse = await szenokPostAPI('deleteNote', {
          fetchstr: 'deleteNoteLawyerCounterParty',
          IDNumber: tempNLCID
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteNoteLawyerCounterParty: ', fetchresponse)

        fetchresponse = await szenokPostAPI('deleteNote', {
          fetchstr: 'deleteNote',
          IDNumber: noteID
        }, this.props.login.currentToken)
      }
      if (type === 'tycoonNote') {
        fetchresponse = await szenokPostAPI('getNote', {
          fetchstr: 'getTycoonCounterpartiesForNote',
          IDNumber: noteID
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for getTycoonCounterpartiesForNote: ', fetchresponse)
        // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

        let tempNTCID = fetchresponse.payload[0].note_tycoon_counterparty_id
        // console.log('This is NTCID: ', tempNTCID)

        fetchresponse = await szenokPostAPI('deleteNote', {
          fetchstr: 'deleteNoteTycoonsCounterParty',
          IDNumber: tempNTCID
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteNoteTycoonsCounterParty: ', fetchresponse)

        fetchresponse = await szenokPostAPI('deleteNote', {
          fetchstr: 'deleteNote',
          IDNumber: noteID
        }, this.props.login.currentToken)
      }
      if (type === 'matterNote') {
        fetchresponse = await szenokPostAPI('getNote', {
          fetchstr: 'getMattersForNote',
          IDNumber: noteID
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for getMattersForNote: ', fetchresponse)
        // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

        let tempNMID = fetchresponse.payload[0].note_matter_id
        // console.log('This is NMID: ', tempNMID)

        fetchresponse = await szenokPostAPI('deleteNote', {
          fetchstr: 'deleteNoteMattersForMatter',
          IDNumber: tempNMID
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteNoteTycoonsCounterParty: ', fetchresponse)

        fetchresponse = await szenokPostAPI('deleteNote', {
          fetchstr: 'deleteNote',
          IDNumber: noteID
        }, this.props.login.currentToken)
      }

      else {

        fetchresponse = await szenokPostAPI('deleteNote', {
          fetchstr: 'deleteNote',
          IDNumber: noteID
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteNote: ', fetchresponse)
        // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })


        fetchresponse = await szenokPostAPI('deleteNote', {
          fetchstr: 'deleteNoteLawyerCounterParty',
          IDNumber: noteID
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteNoteLawyerCounterParty: ', fetchresponse)

        fetchresponse = await szenokPostAPI('deleteNote', {
          fetchstr: 'deleteNoteTycoonsCounterParty',
          IDNumber: noteID
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteNoteTycoonsCounterParty: ', fetchresponse)

        fetchresponse = await szenokPostAPI('deleteNote', {
          fetchstr: 'deleteNoteMatters',
          IDNumber: noteID
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteNoteMatters: ', fetchresponse)

      }


      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getNotes()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }

  }

  handleDeleteTycoon = async (tycoon_id, counterparty_matter_id) => {
    // console.log('Running handleDeleteTycoon...')
    // console.log(`This is tycoon_id: ${tycoon_id} and this is counterparty_matter_id: ${counterparty_matter_id}`)
    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deleteTycoonCounterparty', {
        tycoon_id: tycoon_id, counterparty_matter_id: counterparty_matter_id
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteTycoonCounterparty: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getTycoons()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  handleDeleteLawyer = async (lawyer_id, counterparty_matter_id) => {
    // console.log('Running handleDeleteLawyer...')
    // console.log(`This is lawyer_id: ${lawyer_id} and this is counterparty_matter_id: ${counterparty_matter_id}`)
    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deleteLawyerCounterparty', {
        lawyer_id: lawyer_id, counterparty_matter_id: counterparty_matter_id
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deleteLawyerCounterparty: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        this.getLawyers()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }


  createBasicInfo = () => {
    let infoarray = [...Array(5)].map(x => Array(3).fill(0))
    infoarray[0][0] = 'calendar alternate outline'
    infoarray[0][1] = 'Origination Date'
    infoarray[0][2] = this.state.MatterInfo.matter_origination_date
    infoarray[0][3] = 'matter_origination_date'
    infoarray[0][4] = this.state.MatterInfo.matter_id
    infoarray[1][0] = 'building outline'
    infoarray[1][1] = 'Transaction Type'
    infoarray[1][2] = this.state.MatterInfo.transaction_type
    infoarray[1][3] = 'transaction_type'
    infoarray[1][4] = this.state.MatterInfo.transaction_type_id
    infoarray[2][0] = 'box'
    infoarray[2][1] = 'Sub-transaction Type'
    infoarray[2][2] = this.state.MatterInfo.sub_transaction_type
    infoarray[2][3] = 'subtransaction_type'
    infoarray[2][4] = this.state.MatterInfo.sub_transaction_type_id
    infoarray[3][0] = 'clipboard outline'
    infoarray[3][1] = 'Asset Class'
    infoarray[3][2] = this.state.MatterInfo.asset_class
    infoarray[3][3] = 'asset_class'
    infoarray[3][4] = this.state.MatterInfo.asset_class_id
    infoarray[4][0] = 'money bill alternate outline'
    infoarray[4][1] = 'Deal Amount'
    infoarray[4][2] = this.state.MatterInfo.matter_deal_amount
    infoarray[4][3] = 'matter_deal_amount'
    infoarray[4][4] = 'matter_deal_amount'
    infoarray[4][0] = 'map'
    infoarray[4][1] = 'States'
    infoarray[4][2] = this.state.MatterInfo.states
    infoarray[4][3] = 'states'
    infoarray[4][4] = 'states'

    return infoarray
  }

  // stateLister = (states) => {
  //   // let fetchresponse = await szenokPostAPI('getStates', { fetchstr: 'getAllStates' },
  //   //   this.props.login.currentToken)
  //   // // console.log('This is fetchresponse: ', fetchresponse)
  //   // this.setState({ statesArray: fetchresponse.payload })
  //   // console.log('this is states: ', states)
  //   let arrayOfStates = this.state.MatterInfo.states.split(',').map(item => parseInt(item))
  //   // console.log('This is arrayOfStates: ', arrayOfStates)
  //   let returnString = []
  //   if (!isEmptyNullUndefined(states)) {
  //     for (let i = 0; i < arrayOfStates.length + 1; i++) {
  //       let found = states.find(item => item.state_id === arrayOfStates[i])
  //       if (found) returnString.push(found.state_name)
  //     }
  //   }
  //   // console.log('This is returnString: ', returnString)
  //   return returnString.join(', ')
  // }

  plusFontWeight = (e) => { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
  minusFontWeight = (e) => { e.target.style.fontWeight = ''; e.target.style.color = '#9ca7b8' }

  contextRef = createRef()

  render() {

    let chartWidth = 350
    let chartHeight = 250

    return (
      <div>
        <h1 style={{ float: 'left' }}>Matter Information For:</h1>
        <h2 style={{ marginLeft: '320px' }}>
          <Icon name='chess queen' size='large' />
          <span style={{ color: '#9ca7b8', cursor: 'pointer' }}
            onMouseOver={this.plusFontWeight} onMouseLeave={this.minusFontWeight}
            onClick={() => this.props.history.push(`/client/${this.state.MatterInfo.client_id}?cname=${this.state.MatterInfo.client_name}&clientID=${this.state.MatterInfo.client_id}`)}   >
            {this.state.MatterInfo.client_name} (ID: {this.state.MatterInfo.client_id}):
        </span>
          <Icon name='chess bishop' size='large' />
          <span style={{ color: '#04337d' }}>
            {this.state.querystr.matter} (ID: {this.state.querystr.matterID})
        </span>
        </h2>

        <div ref={this.contextRef}>
          <Divider />

          <Sticky context={this.contextRef} >
            <Menu style={{ backgroundColor: '#ffffff' }} tabular size='large' fluid compact pointing stackable >
              <Menu.Item
                name='Basic Info'
                active={this.state.activeMenuItem === 'Basic Info'}
                onClick={this.handleItemClick}
                color='blue'
              />
              <Menu.Item
                name='Counterparty Info'
                active={this.state.activeMenuItem === 'Counterparty Info'}
                onClick={this.handleItemClick}
                color='green'
                icon='address book outline'
              />
              {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
                <Menu.Item
                  name='Invoice Info'
                  active={this.state.activeMenuItem === 'Invoice Info'}
                  onClick={this.handleItemClick}
                  color='purple'
                  icon='dollar sign'
                />
              }
              <Menu.Item
                name='Notes'
                active={this.state.activeMenuItem === 'Notes'}
                onClick={this.handleItemClick}
                color='orange'
                icon='comment alternate outline'
              />
              <Menu.Item
                name='Precedents'
                active={this.state.activeMenuItem === 'Precedents'}
                onClick={this.handleItemClick}
                color='olive'
                icon='eye'
              />
            </Menu>
          </Sticky>
          {/* <div ref={this.handleContextRef}> */}
          <div >

            {this.state.activeMenuItem === 'Basic Info' &&


              <Segment color='blue'>
                <Grid padded>

                  <Grid.Row>
                    <Grid.Column width={16}>

                      <h2 id='BasicInfo'>Matter Basic Information:</h2>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8}>



                      {/* <SampleTable /> */}

                      <Table color='black' celled collapsing striped>
                        <Table.Body >
                          {this.state.BasicInfoArray.map((item, i) => {
                            return <BasicInfoMaker rowdata={item}
                              number={i}
                              matterID={this.state.querystr.matterID}
                              // handleAClick={this.props.handleClick}
                              key={i}
                              history={this.props.history}
                              currentObserver={!!this.props.login.currentObserver}
                              states={this.state.ListOfStates}
                            />
                          })}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      {this.state.MapInfoLoaded && this.state.MapStatesLoaded &&

                        <svg
                          // style={{background: '#f3f'}}
                          // onClick={() => toIMGcanvas('States')}
                          height={chartHeight}
                          width={chartWidth}
                          viewBox={`0 5 ${100} ${75}`}
                        >
                          <Map
                            selectedYear={0}
                            currentYear={this.state.currentYear}
                            selectedPartner={0}
                            selectedClient={this.state.MatterInfoForStates.client_id}
                            Matters={[this.state.MatterInfoForStates]}
                            States={this.state.States}
                            mapHandler={this.mapHandler}
                            chartWidth={chartWidth}
                            chartHeight={chartHeight}
                            simpleMap={true}
                          />
                        </svg>

                      }


                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <h4>Locations:</h4><br />
                      <LocationsInfo Locations={this.state.Locations}
                        showClient={false} showMatter={false} showCoords={true} noFilters={true}
                        handleIndividualCenter={(lat, lng) => {
                          this.props.setCurrentLatitude(lat)
                          this.props.setCurrentLongitude(lng)
                          this.props.setCurrentZoomLevel(15)
                          this.props.history.push(`/maps/specific?lat=${lat}&lng=${lng}`)
                          // console.log('Clicking on ', lat, ' and: ', lng)
                        }
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            }

            {this.state.activeMenuItem === 'Counterparty Info' &&

              <MattersInfoCparties
                Counterparties={this.state.Counterparties}
                Lawyers={this.state.Lawyers}
                Tycoons={this.state.Tycoons}
                LawyerNotes={this.state.LawyerNotes}
                TycoonNotes={this.state.TycoonNotes}
                Bills={this.state.Bills}
                MatterInfo={this.state.MatterInfo}
                showAddLawyerForm={this.state.showAddLawyerForm}
                showAddTycoonForm={this.state.showAddTycoonForm}
                showAddNoteForm={this.state.showAddNoteForm}
                selectCParty={this.selectCParty}
                selectedCParty={this.state.selectedCParty}
                selectedCPartyMatterID={this.state.selectedCPartyMatterID}
                getLawyers={this.getLawyers}
                getTycoons={this.getTycoons}
                getNotes={this.getNotes}
                showCounterpartyInfoForm={this.state.showCounterpartyInfoForm}
                showAddCounterpartyForm={this.state.showAddCounterpartyForm}
                getMatterInfo={this.getMatterInfo}
                handleDeleteTycoon={this.handleDeleteTycoon}
                handleDeleteLawyer={this.handleDeleteLawyer}
                getBills={this.getBills}
              // key={i}
              />
            }

            {this.state.activeMenuItem === 'Invoice Info' &&

              <Invoices matterID={this.state.querystr.matterID} />
            }

            {this.state.activeMenuItem === 'Precedents' &&

              <Precedents matterID={this.state.querystr.matterID} />
            }

            {this.state.activeMenuItem === 'Notes' &&

              <MatterInfoNotes
                Counterparties={this.state.Counterparties}
                Lawyers={this.state.Lawyers}
                Tycoons={this.state.Tycoons}
                LawyerNotes={this.state.LawyerNotes}
                TycoonNotes={this.state.TycoonNotes}
                MatterNotes={this.state.MatterNotes}
                showAddLawyerForm={this.state.showAddLawyerForm}
                showAddTycoonForm={this.state.showAddTycoonForm}
                showAddNoteFormToggle={this.showAddNoteFormToggle}
                showAddNoteForm={this.state.showAddNoteForm}
                selectCParty={this.selectCParty}
                selectedCParty={this.state.selectedCParty}
                selectedCPartyMatterID={this.state.selectedCPartyMatterID}
                selectedLawFirmID={this.state.selectedLawFirmID}
                getLawyers={this.getLawyers}
                getTycoons={this.getTycoons}
                getNotes={this.getNotes}
                Matter={this.state.querystr.matter}
                MatterID={this.state.querystr.matterID}
                handleDeleteNote={this.handleDeleteNote}
              // NotesLength={this.state.NotesLength}
              />

            }
          </div>
        </div>

        {/* For some reason, the handleContextRef ruins DisplayState */}
        {/* <DisplayState state={this.state} /> */}


      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions, mapActions, matterInfoActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatterInfo))


const BasicInfoMaker = (props) => {
  // console.log('In RowMaker and here are props: ', props)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  // function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
  function plusFontColor(e) { e.target.style.color = "#ff8000" }
  // function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
  function minusFontColor(e) { e.target.style.color = "" }

  return (
    <Table.Row key={props.rowdata[3] + props.number}>
      <Table.Cell>
        <Icon name={props.rowdata[0]} size='large' />
      </Table.Cell>
      <Table.Cell>
        {props.rowdata[1]}
      </Table.Cell >
      <Table.Cell>
        <React.Fragment>
          {props.rowdata[3] === 'transaction_type' ?
            <span
              style={{ 'float': 'left', cursor: 'pointer' }}
              onMouseOver={plusFontColor} onMouseLeave={minusFontColor}
              onClick={() => props.history.push(`/transactiontype/${props.rowdata[2]}?ttype=${props.rowdata[2]}&TTypeID=${props.rowdata[4]}`)}
            >
              {props.rowdata[2]}
            </span> :
            props.rowdata[3] === 'asset_class' ?
              <span
                style={{ 'float': 'left', cursor: 'pointer' }}
                onMouseOver={plusFontColor} onMouseLeave={minusFontColor}
                onClick={() => props.history.push(`/assetclass/${props.rowdata[2]}?aclass=${props.rowdata[2]}&aClassID=${props.rowdata[4]}`)}
              >
                {props.rowdata[2]}
              </span> :
              props.rowdata[3] === 'matter_deal_amount' ?
                formatter.format(props.rowdata[2])
                :
                props.rowdata[3] === 'states' ?
                  props.rowdata[2]
                  : props.rowdata[2]}
        </React.Fragment>
      </Table.Cell>
      {!props.currentObserver && <Table.Cell>
        <Link to={`/editmatter/${props.matterID}`}><Icon name='edit' />Edit</Link>
      </Table.Cell>}
    </Table.Row >
  )
}


