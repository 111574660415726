import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

import { Loading } from '../helper/Misc'
import { toIMGcanvas } from '../helper/PDFImage'
import ChartsMajorClientsSVG from './Charts-Major-Clients-SVG'
import { YearDropDown, EmployeeDropDown, MajorClientsGet } from './Charts-AClass-Functions'

import { Segment, Grid, Checkbox } from 'semantic-ui-react';

// import DisplayState from '../helper/DisplayState';

class ChartsMajorClients extends Component {

  state = {
    TokenReady: false,
    loading: true,
    PartnerBilling: null,
    currentYear: new Date().getFullYear(),
    selectedYear: new Date().getFullYear(),
    selectedPartner: 0,
    MajorClients: [],
    Loading: true,
    pictureDelay: false,
    showByInvoicingPartner: !this.props.byOriginating,
    isMounted: false,
  }


  async componentDidMount() {
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      MajorClientsGet(this.props.maxYears, this.props.login.currentToken, (value) => this.state.isMounted && this.setState({ MajorClientsByInvoicing: value[0], MajorClientsByOriginating: value[1], Loading: false }))
    }
    this.setState({isMounted : true})
  }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.loading) {
      this.setState({ TokenReady: true })
    }
    if (prevProps.login.currentToken !== this.props.login.currentToken) {
      MajorClientsGet(this.props.maxYears, this.props.login.currentToken, (value) => this.state.isMounted && this.setState({ MajorClientsByInvoicing: value[0], MajorClientsByOriginating: value[1], Loading: false }))
    }
  }

  componentWillUnmount() {
    this.setState({ isMounted: false })
  }

  render() {

    let chartStyle = {
      background: "#ebf5fa",
      boxSizing: "border-box",
      display: "inline",
      padding: 0,
      fontFamily: "'Fira Sans', sans-serif",
      borderStyle: 'solid',
      borderWidth: 'thin',
      borderColor: '#a5a5a5',
      borderRadius: '15px',
      boxShadow: '2px 2px 8px #888888',
      cursor: 'pointer',
    }

    return (

      <div className='so-specific-chart-container'>

        {/* <DisplayState state={this.state} /> */}
        {/* <Header as='h3'>Major Clients Chart</Header>
        <Divider /> */}

        <Grid>
          <Grid.Row verticalAlign='bottom'>
            <Grid.Column width={4}>
              <YearDropDown year={this.state.selectedYear} maxYears={this.props.maxYears} includeAll={true} currentYear={this.state.currentYear}
                callback={(value) => this.state.isMounted && this.setState({ selectedYear: value, pictureDelay: true })} />
            </Grid.Column>
            <Grid.Column width={4}>
              <EmployeeDropDown selectedPartner={this.state.selectedPartner} includeAll={true} callback={(value) => this.state.isMounted && this.setState({ selectedPartner: value })} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment compact color='blue'>
                <Checkbox
                  toggle
                  // style={checkboxStyle}
                  label={this.state.showByInvoicingPartner ? 'By Invoicing Partner' : 'By Originating Partner'}
                  // value={this.state}
                  checked={this.state.showByInvoicingPartner}
                  onChange={() => this.state.isMounted && this.setState((prevState) => ({ showByInvoicingPartner: !prevState.showByInvoicingPartner }))}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div  >
          <Segment compact raised style={{ backgroundColor: '#ffffe8' }}>
            <div id='Clients' className='so-charts-chart-container'>
              {!this.state.isMounted ? null : this.state.Loading ? <Loading /> :
                <svg
                  style={chartStyle}
                  onClick={() => toIMGcanvas('Clients')}
                  // width={500}
                  // width={300}
        // overflow="auto"
                  height={600 * this.props.maxViewPercentage / 100}
                  viewBox="0 0 600 350">

                  <ChartsMajorClientsSVG
                    selectedYear={this.state.selectedYear}
                    currentYear={this.state.currentYear}
                    selectedPartner={this.state.selectedPartner}
                    maxViewPercentage={this.props.maxViewPercentage}
                    maxYears={this.props.maxYears}
                    MajorClients={this.state.showByInvoicingPartner ? this.state.MajorClientsByInvoicing : this.state.MajorClientsByOriginating}
                    byInvoicingEmployee={this.state.showByInvoicingPartner}
                    isMounted={this.state.isMounted}
                  />
                </svg>
              }
            </div>
          </Segment>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsMajorClients))


ChartsMajorClients.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  byOriginating: true
  // handleSomething: () => { },
}