import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

// import { bindActionCreators } from 'redux'
// import { useDispatch } from "react-redux"
import { refreshToken } from '../../actions/loginActions'
import store1 from '../../store.js'
// import * as loginActions from '../../actions/loginActions'
// import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
// import { connect } from 'react-redux';
// import io from 'socket.io-client'

import { isEmptyNullUndefined } from '../helper/Misc'
import { toast } from 'react-toastify';
import ReactTooltip from "react-tooltip"

import { Segment } from 'semantic-ui-react';

// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';

// let socket

const LoggedUsers = ({ employees, login, setParentUsers, socket, forceLogOut }) => {

  // const dispatch = useDispatch()

  const [message, setMessage] = useState('')
  const [sqlAction, setSqlAction] = useState('')
  const [logoutAction, setLogOutAction] = useState('')
  const [loggedUsers, setLoggedUsers] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [socketConnected, setSocketConnected] = useState(false)

  // Connect to Socket.io and Join
  useEffect(() => {

    const { currentUserName, currentUserEmail } = login
    // const HOST = process.env.NODE_ENV !== 'production' ? 'http://localhost:4000/' : 'https://so2020app.herokuapp.com/'
    // // const HOST = 'https://so2020app.herokuapp.com/'

    // if (isEmptyNullUndefined(socket)) {
    //   socket = io(HOST, {
    //     'reconnection': false,
    //     'reconnectionDelay': 1000,
    //     'reconnectionDelayMax': 5000,
    //     'reconnectionAttempts': 5
    //   })
    // }

    if (!socketConnected) {
      try {
        // console.log('Connecting to socket.io...', socket)
        // console.log('Socket.connected up here is: ', socket.id)
        // console.log('socketConnected up here is: ', socketConnected)
        // socket.on('connect', () => {
        //   console.log('Connecting to socket...')
        if (!isEmptyNullUndefined(login) && socket.connected) {
          socket.emit('join', { name: currentUserName, email: currentUserEmail }, (error) => {
            console.log('Got this error: ', error)
          })
          // console.log('Socket is: ', socket)
          setSocketConnected(true)
        }
        // })
        // socket.on('reconnect', () => {
        //   if (!isEmptyNullUndefined(login) && socket.connected) {
        //     socket.emit('join', { name: currentUserName, email: currentUserEmail }, (error) => {
        //       console.log('Got this error: ', error)
        //     })
        //     // console.log('Socket is: ', socket)
        //     setSocketConnected(true)
        //   }
        // }) 
      }
      catch (error) {
        console.log(error);
      }
    }

    return () => {
      // console.log('Unmounting this component...')
      if (socketConnected) {
        socket.emit('disconnect')
        socket.disconnect()
      }
    }
    // }, [])
  }, [login, socket, socketConnected])

  //Test
  // useEffect(() => {
  //   console.log('This is LoggedUsers: ')
  // })

  //Deal with connection issues
  useEffect(() => {
    socket.on('connect_error', err => console.log('This is connect_error: ', err));
    socket.on('connect_failed', err => console.log('This is connect_failed: ', err))
  })

  //Receive messages (for display)
  useEffect(() => {
    socket.on('message', (message, error) => {
      if (error) console.log('Got this error: ', error)
      else { setMessage(message) }
    })
  }, [message, socket])

  //Receive array of other users
  useEffect(() => {
    socket.on('roomData', ({ users }) => {
      // console.log('Receiving this users: ', users)
      setLoggedUsers(users)
    })
    // console.log('This is now users: ', users)
  }, [loggedUsers, socket])

  //Once we receive the array of other users, re-create array and determine who is logged in
  useEffect(() => {
    if (isEmptyNullUndefined(loggedUsers)) return
    setAllUsers(employees.map(employee => {
      let obj = {}
      obj.name = employee[1] + ' ' + employee[2]
      obj.email = employee[6]
      obj.image = employee[4]
      obj.loggedIn = loggedUsers.some(user => user.email === employee[6])
      return obj
    }))
    // console.log('This is all partners: ', allUsers)
    // console.log('This is now users: ', users)
  }, [loggedUsers, employees])

  //Update parent entity with loggedUsers (Content.js)
  useEffect(() => {
    setParentUsers(loggedUsers)
  }, [loggedUsers, setParentUsers])

  //Toastify and then erase messages after 3 seconds
  useEffect(() => {
    const { currentUserEmail } = login
    // if(!isEmptyNullUndefined(message)) {console.log('Message: ', message); console.log('ID: ', socket.id)}
    if ((message.id !== socket.id) && (message.useremail !== currentUserEmail)) toast.warning(message.text, { containerId: 'Users' })
    //This timeout is just here in case I want to go back to using the segment below
    setTimeout(() => { setMessage('') }, 1)
  }, [message, socket.id, login])

  //If we ever get any sqlAction alerts from the server, print them out
  useEffect(() => {
    socket.on('sqlAction', ({ sqlAction: sqlActionReceived }, error) => {
      if (!isEmptyNullUndefined(sqlAction)) { console.log('sqlAction: ', sqlAction); console.log('ID: ', socket.id) }
      if (error) console.log('Got this error: ', error)
      else {
        // console.log('Receiving this sqlAction: ', sqlActionReceived + ' -- ' + Date.now(), `...and this is sqlAction: ${sqlAction}`)

        //I don't know why, but this useEffect seems to run even if I don't do a setSqlAction here.
        //Plus, if I DO run a setSqlAction here, it re-runs this multiple times.
        // setSqlAction(sqlActionReceived)

        store1.dispatch(refreshToken(sqlActionReceived))
      }
    })
  }, [sqlAction, socket])

  //Erase sqlAction messages after 3 seconds
  useEffect(() => {
    const { currentUserEmail } = login
    // if(!isEmptyNullUndefined(sqlAction)) {console.log(`sqlAction.useremail is ${sqlAction.useremail} and currentUserEmail is ${currentUserEmail}`)}
    if (!isEmptyNullUndefined(sqlAction) && sqlAction.useremail !== currentUserEmail)
      toast.warning(sqlAction.text, { containerId: 'Users' })
    setTimeout(() => { setSqlAction('') }, 3000)
  }, [sqlAction, login])

  //If we ever get logout instructions from the server, print them out
  useEffect(() => {
    socket.on('logout', (data, error) => {
      if (error) console.log('Got this error: ', error)
      else {
        // console.log('Receiving this logout: ', data)
        forceLogOut(true)
        //I don't know why, but this useEffect seems to run even if I don't do a setLogOutAction here.
        //Plus, if I DO run a setLogOutAction here, it re-runs this multiple times.
        setLogOutAction(data)

        // store1.dispatch(refreshToken(sqlActionReceived))
      }
    })
  })

  //Erase sqlAction messages after 3 seconds
  useEffect(() => {
    setTimeout(() => { setLogOutAction('') }, 3000)
  }, [logoutAction])



  const avatarStyleNot = {
    border: '2px solid #fc2716',
    borderRadius: 150 / 2,
    width: 30,
    height: 30,
    overflow: "hidden",
    opacity: 0.5,
    marginRight: 20,
  }
  const avatarStyleLogged = {
    border: '2px solid #00ff00',
    borderRadius: 150 / 2,
    width: 30,
    height: 30,
    overflow: "hidden",
    marginRight: 20,
  }

  return (
    <React.Fragment>

      {socketConnected &&

        <Segment.Group >
          {/* Employees Logged In */}
          <Segment color='grey' >
            {/* <Button name='Yo' onClick={() => console.log('This is socket: ', socket.connected)}/> */}
            {allUsers.map((item, i) => {
              if (!item.loggedIn) return null
              return (
                // <img src={window.location.origin + '/images/' + item.image}
                <img src={item.image}
                  style={item.loggedIn ? avatarStyleLogged : avatarStyleNot}
                  alt='user'
                  key={`user${i}`}
                  data-tip={employees.find(employee => employee[6] === item.email)[1]}
                />
              )
            })}
          </Segment>

          {/* Employees Logged Out */}
          <Segment secondary >

            {allUsers.map((item, i) => {
              if (item.loggedIn) return null
              return (
                // <img src={window.location.origin + '/images/' + item.image}

                <span key={`userspan${i}`}>
                  <ReactTooltip type='success' place='bottom' />
                  <img src={item.image}
                    style={item.loggedIn ? avatarStyleLogged : avatarStyleNot}
                    alt='user'
                    key={`user${i}`}
                    data-tip={employees.find(employee => employee[6] === item.email)[1]}
                  />
                </span>
              )

            })}
          </Segment>

          {/* Show Messages at the Bottom */}
          {false && !isEmptyNullUndefined(message) ?
            <Segment  >
              {message.text}
            </Segment> :
            null}

        </Segment.Group>
      }

    </React.Fragment>
  )
}

export default withRouter(LoggedUsers)

