
export default function ReactTableMaker_Headerarray(passedData) {
  //First, get the keys from the first item in the array (this will give you the header names)
  const namesArray = Object.keys(passedData[0])
  // console.log('namesArray: ', namesArray)
  //Second, figure out how many keys there are.  ID will take up one space.  The rest will divide evenly into 15 (since Semantic UI has 16 slots overall).
  // const headerlength = Math.min(Math.floor(15 / (namesArray.length - 1)), 15)
  let headerarray = namesArray.map((headername, i) => {
    let obj = {}
    obj['Header'] = headername
    obj['accessor'] = headername
    obj['sortType'] = 'alphanumeric'
    obj['show'] = true
    return obj
  })
  // console.log('This is headerarray: ', headerarray)
  return headerarray
}
