import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Divider } from 'semantic-ui-react';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
// import * as fetchActions from './actions/fetchActions'
import { connect } from 'react-redux';

import Content from './Content'
import ContentLogin from './ContentLogin'
import Header from './Header';

// import Header from './Header'
import { Button } from 'semantic-ui-react'
import { szenokPostAPI } from '../helper/szenokPostAPI'
import { isEmptyNullUndefined } from '../helper/Misc'

const mapStateToProps = state => ({ ...state })

function mapDispatchToProps(dispatch) { return bindActionCreators(loginActions, dispatch) }



export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      iconExists: false,
      forceLogOut: false,
      Logging_authenticated: !isEmptyNullUndefined(this.props.authState.isAuthenticated) ? this.props.authState.isAuthenticated : false,
      Logging_userTested: false,
      Logging_userIdentified: false,
      Logging_tokenRetrieved: false,
      Logging_userSet: false,
      Logging_loggedIn: false,
      Logging_CheckedDB: false,
      Logging_DBUserExists: false,
      DBExists: true,
    };
    this.checkOktaUser = this.checkOktaUser.bind(this);
    this.forceLogOut = this.forceLogOut.bind(this);
  }

  checkOktaUser = async () => {
    const retrievedToken = await this.props.authService.getAccessToken()
    // console.log('This is retrievedToken: ', retrievedToken)

    // console.log('This is props.authState at this point: ', this.props.authState)
    if (!isEmptyNullUndefined(this.props.authState) && this.props.authState.isAuthenticated && !this.state.userInfo) {
      // console.log('First step of checkOktaUser')
      const userInfo = await this.props.authService.getUser();
      // console.log('This is userInfo at this point: ', userInfo)
      //I moved the getAccessToken() up to above this if statement from right here.  Sometimes, we can be isAuthenticated but expired, so decided to keep checking above.
      this.setState({ Logging_userTested: true })
      !isEmptyNullUndefined(userInfo) && this.setState({ Logging_userIdentified: true })

      // const oktaResponse = this.props.authState.tokenManager.get('accessToken')


      !isEmptyNullUndefined(retrievedToken) && this.setState({ Logging_tokenRetrieved: true })

      //If there is no userInfo, it may be because there is an expired authentication JWT in localstorage but no actually authenticated user, so set loggingIn to false
      // isEmptyNullUndefined(userInfo) && this.setState({ loggingIn: false })

      !isEmptyNullUndefined(userInfo) && this.setState({ userInfo: userInfo }, () => {
        // console.log('Set userInfo and this is userInfo: ', this.state.userInfo)
        //If there is userInfo,then set loggingIn to true (to let <ContentLogin> know)
        this.setState({ Logging_userSet: true })
        this.props.authenticateAction(this.props.authState.isAuthenticated)
        this.props.currentUserEmailAction(this.state.userInfo.email)
        this.props.currentUserNameAction(this.state.userInfo.given_name + ' ' + this.state.userInfo.family_name)
        this.props.currentTokenAction(retrievedToken)
        this.getIconAndLevel(retrievedToken)
        this.setState({ Logging_loggedIn: true })
      });
    }
  }

  getIconAndLevel = async (retrievedToken) => {
    // Try to retrieve user information
    if (!this.state.iconExists && this.props.authState.isAuthenticated && isEmptyNullUndefined(this.props.login.currentIcon) && isEmptyNullUndefined(this.props.fetch.fetcherrors)) {

      let retrievedIcon = {}
      //This part is just to print out what database we're using (mostly for development)
      let currentDB = await szenokPostAPI('getTableType', { fetchstr: 'none' },
        retrievedToken)
      console.log('This is currentDB: ', currentDB)
      this.props.currentDBAction(currentDB.payload)
      if (window.location.host.split('.')[0].includes('dev') || window.location.host.split('.')[0].includes('local')) this.props.currentDevAction(true)
      else this.props.currentDevAction(false)

      //The rest is the real user info code...
      // console.log('Starting fetch process to get retrievedIcon...')
      retrievedIcon = await szenokPostAPI('tablesFetch', { fetchstr: 'userinfo', userEmail: this.state.userInfo.email },
        retrievedToken)
      // console.log('This is retrievedIcon: ', retrievedIcon)
      if (retrievedIcon.status === 500 || isEmptyNullUndefined(retrievedIcon.payload)) this.setState({ DBExists: false })
      //If user does not exist in the database
      if (isEmptyNullUndefined(retrievedIcon.payload)) { this.setState({ Logging_DBUserExists: false, Logging_CheckedDB: true }) }
      //If user does exist in the database  
      if (!isEmptyNullUndefined(retrievedIcon.payload)) {
        // console.log('We got an icon and now setting it...')
        this.setState({ iconExists: true, Logging_DBUserExists: true, Logging_CheckedDB: true })
        this.props.currentIconAction(retrievedIcon.payload[0].e_picture)
        this.props.currentLevelAction(retrievedIcon.payload[0].e_level)
        this.props.currentIDAction(retrievedIcon.payload[0].employee_id)
        this.props.currentGuestAction(retrievedIcon.payload[0].guest)
        this.props.currentObserverAction(retrievedIcon.payload[0].observer)
        this.props.currentShowChartsAction(retrievedIcon.payload[0].show_charts)
        this.props.currentShowFinancesAction(retrievedIcon.payload[0].show_finances)
      }
      if (this.state.userInfo.email === 'info@szenok.com') { this.setState({ Logging_DBUserExists: true, Logging_CheckedDB: true }) }
    }
  }

  async componentDidMount() {
    this.checkOktaUser();
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.authState.isAuthenticated !== this.props.authState.isAuthenticated) {
      // console.log('This is isauthenticated in didUpdate: ', this.props.authState.isAuthenticated)
      this.props.authState.isAuthenticated && this.setState({ Logging_authenticated: true })
      this.checkOktaUser();
    }
  }

  forceLogOut = (newstate) => this.setState({ forceLogOut: newstate })
  resetLogState = () => this.setState({
    Logging_authenticated: this.props.authState.isAuthenticated,
    Logging_userTested: false,
    Logging_userIdentified: false,
    Logging_tokenRetrieved: false,
    Logging_userSet: false,
    Logging_loggedIn: false,
    Logging_DBUserExists: false,
    Logging_CheckedDB: false,
    iconExists: false
  })

  // if (this.props.authState.isPending) {
  //   return (
  //     <div>Loading...</div>
  //   );
  // }

  handleRecreateTable = async () => {
    await szenokPostAPI('recreateTables', { test: 'Not much here' },
      this.props.login.currentToken)
    await szenokPostAPI('seedTable', { actiontype: 'seedemployees' },
      this.props.login.currentToken)
  }

  render() {
    // console.log(process.env)

    // const mainContent = this.props.login.authenticated && this.props.login.currentToken.length > 0 ? (
    // const mainContent = this.state.Logging_userSet ? (
    const mainContent = this.state.iconExists ? (
      <Content
        forceLogOut={this.forceLogOut}
        Logging_authenticated={this.state.Logging_authenticated}
        Logging_userTested={this.state.Logging_userTested}
        Logging_userIdentified={this.state.Logging_userIdentified}
        Logging_tokenRetrieved={this.state.Logging_tokenRetrieved}
        Logging_userSet={this.state.Logging_userIdentified}
        Logging_loggedIn={this.state.Logging_loggedIn}
        Logging_DBUserExists={this.state.Logging_DBUserExists}
        Logging_CheckedDB={this.state.Logging_CheckedDB}
        iconExists={this.state.iconExists}
      />
    ) : (
        <ContentLogin
          Logging_authenticated={this.state.Logging_authenticated}
          Logging_userTested={this.state.Logging_userTested}
          Logging_userIdentified={this.state.Logging_userIdentified}
          Logging_tokenRetrieved={this.state.Logging_tokenRetrieved}
          Logging_userSet={this.state.Logging_userIdentified}
          Logging_loggedIn={this.state.Logging_loggedIn}
          Logging_DBUserExists={this.state.Logging_DBUserExists}
          Logging_CheckedDB={this.state.Logging_CheckedDB}
        />
      )

    return (
      <div className='so-overall-container'>
        <div className='so-overall-container2'>
          <Header
            forceLogOut={this.state.forceLogOut}
            resetLogState={this.resetLogState}
            userInfo={this.state.userInfo}

          // logout2={this.logout}
          // login2={this.login} 
          />

          <Divider />
          {/* <br /> */}
          {mainContent}
          {!this.state.DBExists &&
            <React.Fragment>
              <h3>Problem with database...</h3>
              {this.state.userInfo.email === 'info@szenok.com' && <Button color='red' onClick={() => this.handleRecreateTable()}>Recreate Database DANGER!!!!</Button>}
            </React.Fragment>
          }
        </div >
      </div >
    )
  }
}
)
)