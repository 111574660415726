import React from 'react'
import { Menu, Icon,  Input, Dropdown, List } from 'semantic-ui-react'
import { CSVLink } from "react-csv"
import ReactTooltip from "react-tooltip"

import { toIMGcanvas, toPDFcanvas } from '../helper/PDFImage'
import { CSVIcon2 } from '../helper/Misc';

export default function ReactTableMaker_PaginationFooter(gotoPage, previousPage, nextPage, canNextPage, pageCount,
  canPreviousPage, pageIndex, pageOptions, pageSize, setPageSize, rowCount, passedData, passedDataLength, filteredRowCount, filteredData, includeDensePaginationFooter) {
  // console.log('This is pageSize: ', pageSize)

  // Create the dropdown for how many pages to show
  let sizearray = [5]
  for (let i = 1; i < rowCount / 10; i++) sizearray.push(i * 10)
  sizearray.push(filteredRowCount)
  // console.log('This is sizearray: ', sizearray)
  let sizeOptions = []
  sizeOptions = sizearray.map((size, i) => {
    if (sizearray.length === i + 1) {
      let obj = {}
      obj.key = 'i+1' + size
      obj.value = size
      obj.text = `Show All`
      // console.log('This is obj: ', obj)
      return obj
    } else {
      let obj = {}
      obj.key = size
      obj.value = size
      obj.text = `Show ${size}`
      // console.log('This is obj: ', obj)
      return obj
    }
  })

  // console.log('This is sizeOptions: ', sizeOptions)
  return (
    <React.Fragment>
      <ReactTooltip type='info' place='bottom' />
      <span style={{ 'paddingBottom': '10px', 'paddingRight': '10px', 'verticalAlign': 'bottom' }}>
        <List floated='left'>
          <List.Item>Records: {passedDataLength}</List.Item>
          <List.Item>Filtered: {filteredRowCount}</List.Item>
        </List>
        {/* </Label> */}
      </span>

      <Menu pagination size='tiny'>
        <Menu.Item as='a' icon onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <Icon name='chevron left' />
          <Icon name='chevron left' />
        </Menu.Item>
        <Menu.Item as='a' icon onClick={() => previousPage()} disabled={!canPreviousPage}>
          <Icon name='chevron left' />
        </Menu.Item>
        <Menu.Item as='a' icon onClick={() => nextPage()} disabled={!canNextPage}>
          <Icon name='chevron right' />
        </Menu.Item>
        <Menu.Item as='a' icon onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <Icon name='chevron right' />
          <Icon name='chevron right' />
        </Menu.Item>
      </Menu>

      {!includeDensePaginationFooter &&
        <React.Fragment>
          <span style={{ 'display': 'inline-block', 'paddingBottom': '8px', 'paddingRight': '10px', 'verticalAlign': 'bottom' }} >
            <span>&nbsp;&nbsp;</span>Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
        | Go to page:{' '}
          </span>


          <Input
            type="number"
            defaultValue={pageIndex + 1}
            min={1}
            max={pageCount}
            onChange={(e, data) => {
              // console.log('This is data: ', data)
              if (data.value > pageCount) e.target.value = pageCount
              if (data.value < 1 || data.value === '') e.target.value = 1
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
          <Dropdown
            compact
            selection
            value={pageSize}
            onChange={(e, { name, value }) => {
              setPageSize(Number(value))
            }}
            options={sizeOptions}
            upward
          />

          {/* Add picture and pdf downloads */}
          {/* Add CSV download */}
          <CSVLink style={{ float: 'right', paddingRight: '45px' }} data={filteredData.map(row => row.values)} enclosingCharacter={`'`} data-tip="Download (filtered) table as CSV">
            {/* To CSV */}
            {/* <Image src={window.location.origin + '/images/csv.png'} verticalAlign='bottom' width='30px' /> */}
            <CSVIcon2 width='20px' type='green' />
          </CSVLink>
          <CSVLink style={{ float: 'right', marginRight: '15px' }} data={passedData.map(item => item)} enclosingCharacter={`'`} data-tip="Download (entire) table as CSV">
            {/* To CSV */}
            <CSVIcon2 width='20px' type='red' />
          </CSVLink>
          <Icon.Group style={{ float: 'right', cursor: 'pointer', marginRight: '15px' }} onClick={() => toPDFcanvas('ReactTableWrapper')} size='big' data-tip="Download table as PDF">
            <Icon name='file pdf outline' color='grey'  ></Icon>
            <Icon name='arrow circle down' color='green' corner></Icon>
          </Icon.Group>
          <Icon.Group style={{ float: 'right', cursor: 'pointer', marginRight: '15px', marginLeft: '15px' }} onClick={() => toIMGcanvas('ReactTableWrapper')} size='big' data-tip="Download table as PNG">
            <Icon name='image' color='blue' ></Icon>
            <Icon name='arrow circle down' color='green' corner></Icon>
          </Icon.Group>
        </React.Fragment>
      }
    </React.Fragment>
  )
}