import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import ReactTableMaker from '../helper/ReactTableMaker'

// import "react-table/react-table.css"
import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, filterSimpleInputOn2, filterSimpleInputOn3 } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'

import queryString from 'query-string'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Header, Label, Divider, Button, Segment, Checkbox } from 'semantic-ui-react';
// import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';
// import { isNullOrUndefined } from 'util';


class AddTransactionTypes extends Component {

  state = {
    subtransaction: false,
    visible: true,
    showtable: false,
    // success: null,
    TType2Regex: [],
    TType2RegexSpecific: '',
    SubTTypeArray: [],
    SubTType2Regex: [],
    SubTType2RegexSpecific: '',
    selectedTType: null,
    initialValues: {},
  }

  componentDidMount() {
    this.props.fetchResetState('all')
    if (this.props.update && !this.props.subTType) {
      // console.log(`We are in the update page for TType ${this.props.match.params.tTypeID}`)
      this.getSpecificTType()
    }
    if (this.props.update && this.props.subTType) {
      this.getSpecificSubTType()
      this.selectTType(parseInt(queryString.parse(this.props.location.search).TTypeID))
      // console.log(`We are in the update page for subTType ${this.props.match.params.subTTypeID}`)
    }
    this.setState({TTypeDropDownArray: this.TTypeDropDownArray()})
  }

  getSpecificTType = async () => {

    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getTType', { fetchstr: 'none', TTypeID: parseInt(queryString.parse(this.props.location.search).TTypeID) },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getTType: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { TType: fetchresponse.payload, SubTType: null }
    })
  }

  getSpecificSubTType = async () => {
    // console.log('Getthing this subtype: ', queryString.parse(this.props.location.search).subTTypeID)
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getTType', { fetchstr: 'none', TTypeID: queryString.parse(this.props.location.search).TTypeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getTType: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { TType: fetchresponse.payload }
    })
    fetchresponse = await szenokPostAPI('getSubTType', { fetchstr: 'none', SubTTypeID: queryString.parse(this.props.location.search).subTTypeID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getSubTType: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { ...this.state.initialValues, SubTType: fetchresponse.payload }
    })
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ TType2Regex: updatedListGeneralSorted })
    if (updatedListSpecific.length > 0) {
      // console.log('This is updatedListSpecific: ', updatedListSpecific)
      if (this.props.update && updatedListSpecific[0][2] !== this.state.initialValues.TType) {
        this.setState({ TType2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
      else if (this.props.update && updatedListSpecific[0][2] === this.state.initialValues.TType) {
        this.setState({ TType2RegexSpecific: '' })
        setStatus({ errors: {} })
      }
      if (!this.props.update) {
        this.setState({ TType2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ TType2RegexSpecific: '' })
      setStatus({ errors: {} })
    }
  }, 100)

  debouncedSubFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    // console.log(`Trying debounedSubFilter with this e: ${e}, this filterfunction ${filterfunction}, this setstatus: ${setStatus}, this filterarray: ${filterarray}, and this errObj: ${errObj}`)
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ SubTType2Regex: updatedListGeneralSorted })
    if (updatedListSpecific.length > 0) {
      // console.log('This is updatedListSpecific: ', updatedListSpecific)

      if (this.props.update && updatedListSpecific[0][1] !== this.state.initialValues.SubTType) {
        this.setState({ SubTType2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
      else if (this.props.update && updatedListSpecific[0][1] === this.state.initialValues.SubTType) {
        this.setState({ SubTType2RegexSpecific: '' })
        setStatus({ errors: {} })
      }
      if (!this.props.update) {
        this.setState({ SubTType2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ SubTType2RegexSpecific: '' })
      setStatus({ errors: {} })
    }
  }, 100)

  toggleCheckBox = (setFieldValue) => {
    setFieldValue('SubTType', '')
    // console.log('Starting with this for subtransaction: ', this.state.subtransaction)
    this.setState({ subtransaction: !this.state.subtransaction }, () => {
      this.state.subtransaction ? setFieldValue('TType', this.state.TTypeDropDownArray[0].value) : setFieldValue('TType', '')
    })
    
    
  }

  selectTType = (TType) => {
    // console.log('Inside selectTType with this TType: ', TType)
    let subTTypearr = this.props.clients.transactiontypesandsubsarray.filter(innerArray => innerArray[0] === TType)
    this.setState({ SubTTypeArray: subTTypearr })
  }

  toggletable = () => this.setState((prevState) => ({ showtable: !prevState.showtable }))

  TTypeDropDownArray = () => {
    var array = this.props.clients.transactiontypesarray.map(result => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `EID${result[0]}`
      obj.text = result[1]
      obj.value = result[0]
      return obj
    }
    )
    // console.log('This is array: ', array)
    return array
  }

  // {JSON.stringify(get(status, 'errors.'+Field))}
  render() {


    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      SubTType: '',
      TType: this.state.subtransaction ? '' : ''
    } : { SubTType: this.state.initialValues.SubTType, TType: this.state.initialValues.TType }


    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300


    let TTypeTableObj =
      this.state.TType2Regex.length <= 0 ?
        this.props.clients.transactiontypesandsubsarray.reduce((tempobj, v) => {
          let obj = {}
          obj['ttypes_id'] = v[0]
          obj['subttypes_id'] = v[1]
          obj['ttype'] = v[2]
          obj['subttype'] = v[3]
          // console.log('This is obj: ', obj)
          tempobj.push(obj)
          return tempobj;
        }, [])
        :
        this.state.TType2Regex.reduce((tempobj, v) => {
          let obj = {}
          obj['ttypes_id'] = v[0]
          obj['subttypes_id'] = v[1]
          obj['ttype'] = v[2]
          obj['subttype'] = v[3]
          // console.log('This is obj: ', obj)
          tempobj.push(obj)
          return tempobj;
        }, [])


    let SubTTypeTableObj =
      this.state.SubTTypeArray.reduce((tempobj, v) => {
        let obj = {}
        obj['ttypes_id'] = v[0]
        obj['subttypes_id'] = v[1]
        obj['ttype'] = v[2]
        obj['subttype'] = v[3]
        // console.log('This is obj: ', obj)
        tempobj.push(obj)
        return tempobj;
      }, [])
    // if (TTypeTableObj.length > 0) console.log(TTypeTableObj)

    // This counts the unique 'ttype' items in the array so we can set the pivot table length properly
    // let pagesize = Array.from(new Set(TTypeTableObj.map(item => item['ttype']))).length

    return (
      <div>
        <Header as='h1'>{!this.props.update ? 'Transaction Type (and Sub-Transaction Type) Add Form' : this.props.subTType ? 'Sub-Transaction Type Edit Form' : 'Transaction Type Edit Form'}</Header>
        {this.props.update && !this.props.subTType &&
          <Header as='h2'>Editing Transaction Type: {this.state.initialValues.TType}</Header>
        }
        {this.props.update && this.props.subTType &&
          <Header as='h2'>Editing Sub-Transaction Type:  {this.state.initialValues.TType} / {this.state.initialValues.SubTType}</Header>
        }

        <Formik

          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={this.state.subtransaction ? AddSubTransactionTypeSchema : AddTransactionTypeSchema}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              this.setState({ TType2Regex: [], TType2RegexSpecific: '', SubTType2Regex: [], SubTType2RegexSpecific: '' })
              let fetchresponse = {}
              if (this.props.update && !this.props.subTType) fetchresponse = await szenokPostAPI('editTType', { TType: values.TType, TTypeID: parseInt(queryString.parse(this.props.location.search).TTypeID) }, this.props.login.currentToken)
              else if (this.props.update && this.props.subTType) fetchresponse = await szenokPostAPI('editSubTType', {
                SubTType: values.SubTType,
                TTypeID: parseInt(queryString.parse(this.props.location.search).TTypeID), SubTTypeID: parseInt(queryString.parse(this.props.location.search).subTTypeID)
              }, this.props.login.currentToken)
              else {
                if (!this.state.subtransaction) {
                  fetchresponse = await szenokPostAPI('addTType', { TType: values.TType }, this.props.login.currentToken)
                }
                else {
                  fetchresponse = await szenokPostAPI('addSubTType', { SubTType: values.SubTType, TTypeID: values.TType }, this.props.login.currentToken)
                }
              }
              // console.log('Now printing fetchresponse addSubTType: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                //Update the clients store
                reduxupdate('transactions')
                reduxupdate('transactionsandsubs')
                // if (this.props.update && !this.props.subTType) this.getSpecificTType()
                // if (this.props.update && this.props.subTType) this.getSpecificSubTType()

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                // setTimeout(
                //   function () {
                //     //this.setState({ success: false });
                //     this.setState({ fetcherrors: [], success: null, successmessage: '', visible: true, CName: '', clienttype: '' })
                //   }
                //     .bind(this),
                //   transitionduration - 100
                // )
                actions.resetForm({ ...initialValues })
              }
            }
            catch (error) { this.props.fetchErrors(error.toString()) }
            actions.setSubmitting(false)
            this.props.history.push(`/transactiontypes`)

          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
            //this.setState({error: null})
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment color='grey' raised>
                  <Form onSubmit={handleSubmit} noValidate autoComplete='off'>
                    <Form.Group>
                      {!this.state.subtransaction && !this.props.subTType?
                        // 'Ttype'
                        <Form.Field width={12}>
                          <Form.Input
                            name="TType" type="text"
                            label='Transaction Type'
                            loading={this.props.fetch.fetching}
                            required={true}
                            placeholder='Transaction Description'
                            error={(errors.TType && touched.TType) || (has(status, 'errors.TType'))}
                            onChange={async (e) => {
                              await handleChange(e);
                              this.setState({ TType: e.target.value })
                              //This function is to add the duplicate clients list
                              // this.state.subtransaction || (this.props.update && this.props.TType) ?
                              //   this.debouncedSubFilter(e.target.value, filterSimpleInputOn3, setStatus, this.state.SubTTypeArray, { SubTType: 'Name already used' })
                              //   :
                              this.debouncedFilter(e.target.value, filterSimpleInputOn2, setStatus, this.props.clients.transactiontypesandsubsarray, { TType: 'Name already used' })
                            }}
                            onBlur={handleBlur}
                            value={values.TType || ''}
                          />
                          {this.errorTag(errors, touched, status, 'TType')}
                        </Form.Field>
                        :
                        // 'SubTType'
                        <Form.Field width={12}>
                          <Form.Input
                            name="SubTType" type="text"
                            label='Subtransaction Type'
                            loading={this.props.fetch.fetching}
                            required={true}
                            placeholder='Subtransaction Description'
                            error={(errors.SubTType && touched.SubTType) || (has(status, 'errors.SubTType'))}
                            onChange={async (e) => {
                              await handleChange(e);
                              this.setState({ SubTType: e.target.value })
                              //This function is to add the duplicate clients list
                              // this.state.subtransaction || (this.props.update && this.props.subTType) ?
                              this.debouncedSubFilter(e.target.value, filterSimpleInputOn3, setStatus, this.state.SubTTypeArray, { SubTType: 'Name already used' })
                              // :
                              // this.debouncedFilter(e.target.value, filterSimpleInputOn2, setStatus, this.props.clients.transactiontypesandsubsarray, { SubTType: 'Name already used' })
                            }}
                            onBlur={handleBlur}
                            value={values.SubTType || ''}
                          />
                          {this.errorTag(errors, touched, status, 'SubTType')}
                        </Form.Field>

                      }

                    </Form.Group>

                    {!this.props.update &&
                      <Checkbox
                        label='Sub-category of...'
                        onChange={() => {
                          this.toggleCheckBox(setFieldValue)
                          // this.selectTType(values.TType)
                          this.selectTType(this.state.TTypeDropDownArray[0].value)
                         
                        }}
                        checked={this.state.subtransaction}
                      />}

                    {this.state.subtransaction ?
                      <Form.Select
                        placeholder='Select Transaction Type'
                        name="TType"
                        width={2}
                        required
                        label='Transaction Type'
                        error={(touched.SuperTType && (typeof get(values, 'TType') === 'undefined' || get(values, 'TType') === null)) ||
                          (has(status, 'errors.TType'))}
                        closeOnBlur
                        selection
                        onBlur={() => setFieldTouched('TType', true)}
                        options={this.state.TTypeDropDownArray}
                        value={values.TType || ''}
                        loading={this.state.success}
                        onChange={(e, { name, value }) => {
                          setFieldValue(name, value)
                          this.selectTType(value)
                        }}
                      /> : null}

                    {/* 
                    <Segment secondary={this.state.TType2RegexSpecific.length === 0} color={this.state.TType2RegexSpecific.length > 0 ? 'red' : 'blue'}
                      hidden={this.state.TType2Regex.length === 0}>
                      {ListMakerSimple(this.state.TType2Regex, values, this.state.TType2RegexSpecific)}
                    </Segment> */}


                    <Divider />
                    <Button.Group size='large'>
                      <Button type='button' icon='cancel'
                        onClick={() => {
                          handleReset();
                          this.setState({ TType: '', TType2Regex: [], TType2RegexSpecific: '', SubTType2Regex: [], SubTType2RegexSpecific: '' })
                        }}
                        color='red'
                        content='Cancel'></Button>
                      <Button.Or />
                      <Button color='teal'
                        onClick={() => { this.setState({ TType: '', TType2Regex: [], TType2RegexSpecific: '', SubTType2Regex: [], SubTType2RegexSpecific: '' }); handleReset() }}
                        type='button'
                        disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Form'>
                      </Button>
                      <Button.Or />
                      <Button color='orange'
                        onClick={() => { setStatus({}) }}
                        type='button'
                        //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Error Notifications'>
                      </Button>
                      <Button.Or />
                      <Button type='submit'
                        // disabled={isSubmitting}
                        //Eventually use the below, the one above is just for testing
                        disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                        icon='upload'
                        loading={isSubmitting}
                        floated='right'
                        color={dirty ? 'green' : 'grey'}
                        content={this.props.update && !this.props.subTType ? 'Edit Transaction Type' : this.props.update && this.props.subTType ? 'Edit Sub-Transaction Type' :
                          this.state.subtransaction ? `Add Subtransaction Type` : `Add Transaction Type`}></Button>
                    </Button.Group>
                    {/* <DisplayState state={this.state} />
                    <DisplayFormikState /> */}
                  </Form>
                </Segment>
              </div>
            )}
        </Formik>

        <p />

        <Button fluid basic color={this.state.showtable ? 'blue' : 'green'}
          onClick={this.toggletable}>
          {this.state.showtable ? 'Hide' : 'Show'} Transaction Types Table</Button>
        {this.state.showtable ?

          <ReactTableMaker passedData={this.state.subtransaction ? SubTTypeTableObj : TTypeTableObj} passedColumns={TTypesAndSubsColumns()}
            handleClick={(rowID, cellID, rowValues) => {

            }}
            includeSearchBar={false}
            includePaginationFooter={false}
            groupByRow={['ttype']}
          />

          : null}

      </div>
    )
  }
}


const AddTransactionTypeSchema = Yup.object().shape({
  TType: Yup
    .string()
    .min(3, 'Transaction type must be at least 3 characters.')
    .max(200, 'Transaction type name cannot exceed 50 characters.')
    .required('Must have a sub-transaction type.'),
})

const AddSubTransactionTypeSchema = Yup.object().shape({
  SubTType: Yup
    .string()
    .min(3, 'Sub-Transaction type must be at least 3 characters.')
    .max(200, 'Sub-Transaction type name cannot exceed 50 characters.')
    .required('Must have a sub-transaction type.'),
  TType: Yup
    .number()
    .required()
    .typeError('Must pick a transaction type.'),
})



const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddTransactionTypes))

AddTransactionTypes.defaultProps = {
  update: false,
  subTType: false,
}




const TTypesAndSubsColumns = () => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Transaction Type ID</span>,
      accessor: 'ttypeid',
      show: true,
      width: 0,
      disableGroupBy: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>SubTransaction Type ID</span>,
      accessor: 'subttypeid',
      show: true,
      width: 0,
      disableGroupBy: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Transaction Type</span>,
      accessor: 'ttype',
      show: true,
      sortType: 'alphanumeric',
      // aggregate: ['sum', 'count'],
      // Aggregated: ({ cell: { value } }) => `${value} Names`,
      group: true,
      Filter: '',
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Sub-Transaction Type</span>,
      accessor: 'subttype',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
      disableGroupBy: true,
      Aggregated: ({ cell: { value }, row }) =>
        Object.keys(row.subRows[0].original).length <= 2 ?
          <span style={{ color: 'grey' }}>No Sub-Transactions</span> :

          `${row.subRows.length} Sub-Transaction Types ${!row.isExpanded ?
            ': (' + row.subRows.map(item => ' ' + (isEmptyNullUndefined(item.values.subttype) ? null : item.values.subttype)) + ' )' : ''}`,
      // aggregate: ['sum', 'count'],
      Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
    },

  ]
}