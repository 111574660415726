import React, { Component } from 'react'
import { Route, withRouter } from "react-router-dom";
import { Loader, Dimmer, Image, Segment } from 'semantic-ui-react'
import { reduxupdate } from '../helper/ReduxHelp'
import { isEmptyNullUndefined } from '../helper/Misc'

import Welcome from '../pages/Component-Welcome';
import SideMenu from './Content-Menu';
import MessageBarRedux from '../helper/MessageBarRedux'
import FakeDataComponent from '../pages/Fake-Data-Component';
import Employees from '../pages/Component-Employees';
import Pictures from '../pages/Component-Pictures';
import UploadCSV from '../pages/Component-UploadCSV';
// import TestComponent4 from '../pages/TestComponent4';
import LoggedUsers from '../pages/LoggedUsers';
import Search from '../pages/Component-Search';
import Charts from '../pages/Component-Charts';
import Maps from '../pages/Component-Maps';
import AddClient from '../pages/Component-AddClient';
import Clients from '../pages/Component-Clients';
import ClientInfo from '../pages/Component-ClientInfo';
import Matters from '../pages/Component-Matters';
import MatterInfo from '../pages/Component-MatterInfo';
import TransactionTypes from '../pages/Component-TransactionTypes';
import TransactionTypeInfo from '../pages/Component-TransactionTypeInfo'
import AssetClasses from '../pages/Component-AssetClasses';
import AssetClassInfo from '../pages/Component-AssetClassInfo'
import LawFirms from '../pages/Component-LawFirms';
import LawFirmInfo from '../pages/Component-LawFirmInfo'
import Counterparties from '../pages/Component-Counterparties';
import CounterpartyInfo from '../pages/Component-CounterpartiesInfo'
import Persons from '../pages/Component-Persons';
import PersonInfo from '../pages/Component-PersonInfo'
import Invoices from '../pages/Component-Invoices';
import InvoiceInfo from '../pages/Component-InvoiceInfo';
import Precedents from '../pages/Component-Precedents';
import Bills from '../pages/Component-Bills';
import Payments from '../pages/Component-Payments';
import PaymentInfo from '../pages/Component-PaymentInfo';
import AddTransactionType from '../pages/Component-AddTransactionType';
import AddAssetClasses from '../pages/Component-AddAssetClass';
import AddLawFirm from '../pages/Component-AddLawFirm';
import AddCounterparty from '../pages/Component-AddCounterparty';
import AddPerson from '../pages/Component-AddPerson';
import AddMatter from '../pages/Component-AddMatter'
import AddInvoice from '../pages/Component-AddInvoice'
import AddPayment from '../pages/Component-AddPayment'
import AddPrecedent from '../pages/Component-AddPrecedent'
import AddEmployee from '../pages/Component-AddEmployee'
import Logs from '../pages/Component-Logs'
import Database from '../pages/Component-Database'
import DownLoadTable from '../pages/Component-DownloadTable'

import { bindActionCreators } from 'redux'
//import * as loginActions from '../../actions/loginActions'
import * as clientActions from '../../actions/clientActions'
import { connect } from 'react-redux';

import io from 'socket.io-client'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState'
// import DisplayClients from '../helper/DisplayClients'


class Content extends Component {

  constructor() {
    super();
    this.state = {
      clientmatter: null,
      lawyers: null,
      loading: true,
      AdminLock: true,
      loggedUsers: [],
    }
    this.HOST = process.env.NODE_ENV !== 'production' ? 'http://localhost:4000/' : process.env.REACT_APP_HOSTNAME //'https://so2020app.herokuapp.com/'
    this.socket = io(this.HOST, {
      'reconnection': false,
      'reconnectionDelay': 1000,
      'reconnectionDelayMax': 5000,
      'reconnectionAttempts': 5
    })
    // this.socket = {}
  }


  async componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      isEmptyNullUndefined(this.props.fetch.fetcherrors) && await reduxupdate('all')
      this.setState({ loading: false })
    }
  }

  async componentDidUpdate(prevProps) {
    this.props.checkAuthentication()
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      // reduxupdate('all')
      let refreshToken = this.props.login.refreshToken.split(',')[0]
      // console.log('This is refreshToken: ', refreshToken)
      switch (refreshToken) {
        case 'clients':
          reduxupdate('clients')
          reduxupdate('clientsarray')
          break;
        case 'matters':
          reduxupdate('matters')
          break;
        case 'employees':
          reduxupdate('employees')
          break;
        //Fall-through here for OR
        case 'transaction_types':
        case 'sub_transaction_types':
          reduxupdate('transactionsandsubs')
          reduxupdate('transactions')
          break;
        case 'asset_classes':
          reduxupdate('assetclasses')
          break;
        case 'law_firms':
          reduxupdate('lawfirms')
          break;
        case 'counterparties':
          reduxupdate('counterparties')
          break;
        case 'persons':
          reduxupdate('persons')
          break;
        case 'invoices':
          reduxupdate('invoices')
          break;
        case 'payments':
          reduxupdate('payments')
          break;
        case 'precedents':
          reduxupdate('precedents')
          break;
        case 'locations':
          reduxupdate('locations')
          break;
        case 'bills':
          reduxupdate('bills')
          break;
        case 'all':
          reduxupdate('all')
          break;
        default:
          break
      }
    }
  }

  setLoggedUsers = (loggedUsers) => {
    this.setState({ loggedUsers: loggedUsers })
  }

  render() {
    return (
      // <Grid.Row >
        <div className='content-columns-container'>

          <div className='content-column-left'>
            <div className='content-column-left-sub'>
              <SideMenu
                loading={this.state.loading}
                // test={'/test'}
              />
              {this.state.loading ? <Loading /> :
                <div className='content-column-left-loggedusers'>
                  <LoggedUsers login={this.props.login} employees={this.props.clients.employeesarray}
                    setParentUsers={this.setLoggedUsers} socket={this.socket} forceLogOut={this.props.forceLogOut} />
                </div>
              }
            </div>
          </div>

          <div className='content-column-right'>

            {this.state.loading ? <Loading /> :
              <React.Fragment>
                <Route path='/' exact render={(props) =>
                  <Welcome
                    Logging_authenticated={this.props.Logging_authenticated}
                    Logging_userTested={this.props.Logging_userTested}
                    Logging_userIdentified={this.props.Logging_userIdentified}
                    Logging_tokenRetrieved={this.props.Logging_tokenRetrieved}
                    Logging_userSet={this.props.Logging_userIdentified}
                    Logging_loggedIn={this.props.Logging_loggedIn}
                    iconExists={this.props.iconExists}
                  />} />
                <Route path='/' render={(props) => <MessageBarRedux />} />
                <Route path="/clients" render={(props) => <Clients />} />
                <Route path='/client/:clientID' render={(props) => <ClientInfo {...props} />} />
                <Route path="/matters" render={(props) => <Matters />} />
                <Route path='/matter/:matterID' render={(props) => <MatterInfo {...props} />} />
                {!this.props.login.currentObserver && <Route path="/addclient" render={(props) => <AddClient />} />}
                {!this.props.login.currentObserver && <Route path="/addmatter" render={(props) => <AddMatter />} />}
                {!this.props.login.currentObserver && <Route path="/addinvoice" render={(props) => <AddInvoice />} />}
                {!this.props.login.currentObserver && <Route path="/addpayment" render={(props) => <AddPayment />} />}
                {!this.props.login.currentObserver && <Route path="/addprecedent" render={(props) => <AddPrecedent />} />}
                <Route path="/transactiontypes" render={(props) => <TransactionTypes />} />
                <Route path='/transactiontype/:TTYpeID' render={(props) => <TransactionTypeInfo {...props} />} />
                <Route path="/assetclasses" render={(props) => <AssetClasses />} />
                <Route path='/assetclass/:aClassID' render={(props) => <AssetClassInfo {...props} />} />
                <Route path="/lawfirms" render={(props) => <LawFirms />} />
                <Route path='/lawfirm/:lawFirmID' render={(props) => <LawFirmInfo {...props} />} />
                <Route path="/counterparties" render={(props) => <Counterparties />} />
                <Route path='/counterparty/:counterpartyID' render={(props) => <CounterpartyInfo {...props} />} />
                <Route path="/persons" render={(props) => <Persons />} />
                <Route path='/person/:personID' render={(props) => <PersonInfo {...props} />} />
                {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
                  <Route path="/invoices" render={(props) => <Invoices />} />
                }
                {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
                  <Route path='/invoice/:invoiceID' render={(props) => <InvoiceInfo {...props} />} />
                }
                <Route path="/precedents" render={(props) => <Precedents />} />
                <Route path="/bills" render={(props) => <Bills />} />
                {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
                  <Route path="/payments" render={(props) => <Payments />} />
                }
                {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowFinances === 1) &&
                  <Route path='/payment/:paymentID' render={(props) => <PaymentInfo {...props} />} />
                }
                {!this.props.login.currentObserver && <Route path="/addttype" render={(props) => <AddTransactionType />} />}
                {!this.props.login.currentObserver && <Route path="/addassetclass" render={(props) => <AddAssetClasses />} />}
                {!this.props.login.currentObserver && <Route path="/editassetclass/:aClassID" render={(props) => <AddAssetClasses {...props} update={true} />} />}
                {!this.props.login.currentObserver && <Route path="/editclient/:clientID" render={(props) => <AddClient {...props} update={true} />} />}
                {!this.props.login.currentObserver && <Route path="/editmatter/:matterID" render={(props) => <AddMatter {...props} update={true} />} />}
                {!this.props.login.currentObserver && <Route path="/editinvoice/:invoiceID" render={(props) => <AddInvoice {...props} update={true} />} />}
                {!this.props.login.currentObserver && <Route path="/editpayment/:paymentID" render={(props) => <AddPayment {...props} update={true} />} />}
                {!this.props.login.currentObserver && <Route path="/editprecedent/:precedentID" render={(props) => <AddPrecedent {...props} update={true} />} />}
                {!this.props.login.currentObserver && <Route path="/editttype/:tTypeID" render={(props) => <AddTransactionType {...props} update={true} subTType={false} />} />}
                {!this.props.login.currentObserver && <Route path="/editsubttype/:subTTypeID" render={(props) => <AddTransactionType {...props} update={true} subTType={true} />} />}
                {!this.props.login.currentObserver && <Route exact path="/addlawfirm" render={(props) => <AddLawFirm />} />}
                {!this.props.login.currentObserver && <Route path="/addlawfirm/:lawFirmID" render={(props) => <AddLawFirm {...props} update={true} />} />}
                {!this.props.login.currentObserver && <Route exact path="/addcounterparty" render={(props) => <AddCounterparty />} />}
                {!this.props.login.currentObserver && <Route exact path="/addperson" render={(props) => <AddPerson />} />}
                {!this.props.login.currentObserver && <Route path="/editcounterparty/:counterpartyID" render={(props) => <AddCounterparty {...props} update={true} />} />}
                {!this.props.login.currentObserver && <Route path="/editperson/:personID" render={(props) => <AddPerson {...props} update={true} />} />}
                <Route path="/search" render={(props) => <Search />} />
                <Route path="/maps" render={(props) => <Maps {...props} />} />
                {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentShowCharts === 1) &&
                  <Route path="/charts" render={(props) => <Charts />} />
                }
                {(this.props.login.currentUserEmail === 'info@szenok.com' || this.props.login.currentLevel === 0) &&
                  <React.Fragment>
                    {/* <Route path="/test" render={(props) => <TestComponent4 {...props} />} /> */}
                    <Route path="/fakedata" render={(props) => <FakeDataComponent />} />
                    <Route path="/employees" render={(props) => <Employees loggedUsers={this.state.loggedUsers} socket={this.socket} />} />
                    <Route path="/pictures" render={(props) => <Pictures />} />
                    <Route path="/logs" render={(props) => <Logs />} />
                    <Route path="/database" render={(props) => <Database />} />
                    <Route path="/downloadTable" render={(props) => <DownLoadTable />} />
                    {!this.props.login.currentObserver && <Route path="/addemployee" render={(props) => <AddEmployee />} />}
                    {!this.props.login.currentObserver && <Route path="/editemployee/:employeeID" render={(props) => <AddEmployee {...props} update={true} />} />}
                    {!this.props.login.currentObserver && <Route path="/upload" render={(props) => <UploadCSV {...props} />} />}
                  </React.Fragment>}
              </React.Fragment>
            }
          </div>
        </div>

      // </Grid.Row>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(clientActions, dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps, undefined, { pure: false })(Content))

Content.defaultProps = {
  checkAuthentication: () => { },
}


const Loading = () => {
  return (
    <Segment size='massive'>
      <Dimmer active>
        <Loader content='Loading' />
      </Dimmer>

      {/* //Fix:  I need to do this for now because webpack doesn't handle images well, but eventually replace
        //this with a weblink as in the commented bit below.  */}
      <Image src={window.location.origin + '/images/short-paragraph.png'} />
      <Image src={window.location.origin + '/images/short-paragraph.png'} />
      <Image src={window.location.origin + '/images/short-paragraph.png'} />
      <Image src={window.location.origin + '/images/short-paragraph.png'} />
      <Image src={window.location.origin + '/images/short-paragraph.png'} />
      <Image src={window.location.origin + '/images/short-paragraph.png'} />
      {/* <Image src={`./images/${props.login.currentIcon}`} verticalAlign='bottom' width='50px' circular  /> */}
    </Segment>
  )
}
