import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokBlobAPI, downloadBlob } from '../helper/szenokBlobAPI'

// // import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
// import ConfirmModal from '../helper/ConfirmModal'
import { connect } from 'react-redux';

// import downloadjs from 'downloadjs'

import ReactTableMaker from '../helper/ReactTableMaker'


import { szenokPostAPI } from '../helper/szenokPostAPI'
import { isEmptyNullUndefined } from '../helper/Misc'
// import { has, get } from 'lodash';
// import isEmpty from 'lodash/isEmpty';

// import { Formik } from 'formik'
// import * as Yup from 'yup';
import { Icon, Button, Segment, Grid, Divider } from 'semantic-ui-react';

// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';

class Logs extends Component {

  state = {
    Logs: [],
    SpecificLogName: '',
    SpecificLog: [],
  }

  async componentDidMount() {
    this.getLogs()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getLogs(), 200)
    }
  }

  getLogs = async () => {
    // console.log('In getCounterparties...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getLogs', { fetchstr: 'allLogs' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getlogs: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      // Logs: fetchresponse.payload,
      LogsArray: fetchresponse.payload
    })
  }

  getSpecificLog = async (log) => {
    // console.log('In getCounterparties...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getLogs', { fetchstr: 'specificLog', logID: log },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getSpecificLog: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    if (!isEmptyNullUndefined(fetchresponse.payload)) this.setState({
      SpecificLog: fetchresponse.payload
    })
    else this.setState({ SpecificLog: {} })
  }

  downloadSpecificLog = async (log) => {
    // console.log('In getCounterparties...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getLogs', { fetchstr: 'specificLog', logID: log },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getSpecificLog: ', fetchresponse)

    const str = JSON.stringify(fetchresponse.payload);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: "application/json;charset=utf-8"
    })

    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${log}`
    a.click()
  }

  deleteSpecificLog = async (log) => {
    // console.log('In getCounterparties...')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getLogs', { fetchstr: 'deleteSpecificLog', logID: log },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for deleteSpecificLog: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      // Logs: fetchresponse.payload,
      LogsArray: fetchresponse.payload.map(item => {
        let obj = {}
        obj.name = item
        return obj
      }
      )
    })
  }

  setSpecificLogName = (name) => {
    // console.log('Here and with this name: ', name)
    this.setState({ SpecificLogName: name })
  }

  render() {
    return (
      <React.Fragment>
        <h1><Icon name='th' size='large' circular />Logs</h1>

        <Grid>
          <Grid.Row>
            <Grid.Column width={10}>
              <ReactTableMaker passedData={this.state.LogsArray}
                // passedColumns={PrecedentsColumns()}
                passedColumns={LogColumns('logs', this.setSpecificLogName, this.getSpecificLog, this.downloadSpecificLog, this.deleteSpecificLog)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                color='olive'
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Segment color='grey'>
                <h4>Batch Actions</h4>
                <Divider />
                <Button
                  color='blue'
                  size='tiny'
                  basic
                  onClick={async () => {
                    await szenokPostAPI('getLogs', { fetchstr: 'deleteEmptyLogs' }, this.props.login.currentToken)
                    this.getLogs()
                  }
                  }>Delete Empty Logs</Button>
                <p />
                <Button
                  color='purple'
                  size='tiny'
                  basic
                  onClick={async () => {
                    //Download a .zip blob containing all files dated earlier than today
                    let response = await szenokBlobAPI('blob', { fetchstr: 'allLogsZip' },
                      this.props.login.currentToken)
                    // console.log('Response is: ', response)

                    //Convert data into blob
                    const blob = await response.blob()
                    // console.log('This is blob: ', blob)

                    //Generate a download link and force it to be clicked
                    await downloadBlob(blob, 'sample.zip')
                  }
                  }>Download All Logs (older than today)</Button>
                <p />
                <Button
                  color='green'
                  size='tiny'
                  basic
                  onClick={async () => {
                    //Download a .zip blob containing all files dated earlier than today
                    let response = await szenokBlobAPI('blob', { fetchstr: 'allLogsAsJSONZip' },
                      this.props.login.currentToken)
                    // console.log('Response is: ', response)

                    //Convert data into blob
                    const blob = await response.blob()
                    // console.log('This is blob: ', blob)

                    //Generate a download link and force it to be clicked
                    await downloadBlob(blob, 'sample.zip')
                  }
                  }>Download All Logs AS JSON (older than today)</Button>
                <p />
                <Button
                  color='red'
                  size='tiny'
                  basic
                  onClick={async () => {
                    console.log('Trying deleteOldLogs')
                    await szenokPostAPI('getLogs', { fetchstr: 'deleteOldLogs' }, this.props.login.currentToken)
                    this.getLogs()
                  }
                  }>Delete All Logs (older than today)</Button>
              </Segment>
            </Grid.Column>
            <Grid.Column width={2} />
          </Grid.Row>
        </Grid>





        {!isEmptyNullUndefined(this.state.SpecificLog) &&
          // <code>{JSON.stringify(this.state.SpecificLog, null, 2)}</code>
          <Segment color='black'>
            <h4>Logs for {this.state.SpecificLogName}:</h4>
            <ul>
              {/* Reversing the array so newest logs appear up top (to avoid having to scroll down the screen) */}
              {this.state.SpecificLog.reverse().map((item, i) =>
                // <span
                // key={'log' + i}
                // style={{ cursor: 'pointer' }}
                // onClick={() => this.getSpecificLog(item)}>

                <li key={'log' + i}><code>{JSON.stringify(item, null, 2)}</code></li>
                // </span>

              )}
            </ul>
          </Segment>
        }

        {!isEmptyNullUndefined(this.state.SpecificLogName) && isEmptyNullUndefined(this.state.SpecificLog) &&
          <Segment>
            <h4>Log {this.state.SpecificLogName} is empty</h4>
          </Segment>
        }


        {/* <DisplayState state={this.state} /> */}
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logs))

function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const LogColumns = (type, setSpecificLogName, getSpecificLog, downloadSpecificLog, deleteSpecificLog) => {
  let results = []

  let testDate = (name) => {
    let myYear = name.slice(0, 4)
    // console.log('This is myYear: ', myYear)
    let myMonth = name.slice(5, 7)
    // console.log('This is myMonth: ', myMonth)
    let myDay = name.slice(8, 10)
    // console.log('This is myDay: ', myDay)
    // console.log('This is name: ', name)
    let mydate = new Date(myYear, myMonth - 1, myDay)
    // console.log('mydate = ', mydate)
    let today = new Date((new Date()).toString().substring(0, 15))
    // console.log('today = ', today)
    // console.log(`Is this date less than today? ${mydate<today ? 'yes' : 'no'}`)
    return mydate < today
  }

  switch (type) {
    case 'logs':
      results = [
        // {
        //   Header: () => <span style={{ 'float': 'left' }}>Precedent ID</span>,
        //   accessor: 'precedent_id',
        //   show: true,
        //   width: 0,
        //   sortType: 'alphanumeric',
        //   Filter: 'defaultColumnFilterLeft'
        // },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Log Name</span>,
          accessor: 'name',
          show: true,
          sortType: 'alphanumeric',
          Filter: 'defaultColumnFilterLeft',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => {
                setSpecificLogName(cell.value)
                getSpecificLog(cell.value)
              }}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Log Size</span>,
          accessor: 'size',
          show: true,
          sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) =>
            <span
              style={{ 'float': 'right', cursor: 'pointer' }}
            >
              {cell.value} bytes
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Download</span>,
          accessor: 'download',
          show: true,
          sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => {
                // console.log(`Downloading this file: ${row.values.name}`)


                downloadSpecificLog(row.values.name)
              }}
            >
              {/* <a href={`./server/log/2020-03-23-node-results.log`} title={row.values.name} download={row.values.name}> */}

              <Icon name='download' color='blue' /> Download
              {/* </a> */}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Delete</span>,
          accessor: 'delete',
          show: true,
          sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={testDate(row.values.name.substr(0, 10)) ? plusFontWeight : null} onMouseLeave={testDate(row.values.name.substr(0, 10)) ? minusFontWeight : null}
              style={{ 'float': 'left', cursor: !testDate(row.values.name.substr(0, 10)) ? '' : 'pointer' }}
              onClick={() => {
                if (testDate(row.values.name.substr(0, 10))) {
                  // console.log(`Deleting this file: ${row.values.name}`)
                  testDate(row.values.name.substr(0, 10))
                  deleteSpecificLog(row.values.name)
                }
                else console.log(`Cannot delete today's log.`)
              }}
            >
              <Icon name='delete' color='red' size='small' bordered disabled={!testDate(row.values.name.substr(0, 10))} />Delete
            </span>
        },
      ]
      break;

    default:
    // code block
  }
  return results
}

