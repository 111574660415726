import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';
import ReactTableMaker from '../helper/ReactTableMaker'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Button, Segment, Dropdown } from 'semantic-ui-react';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

const initialState = {
  TTypes: null,
  TTIDs: [],
  TTypeMatters: null,
  TTMIDs: [],
  SubTTypes: null,
  STTIDs: [],
  SubTTypeMatters: null,
  STTMIDs: [],
  EmptyTTypeChildren: null,
  ETTCIDs: [],
  TTypeDropDownArray: [],
  SubTTypeDropDownArray: [],
}

class DeleteTType extends Component {

  state = {
    hasError: false, ...initialState,
    selectedSwitchSubTType: null,
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    if (!isEmptyNullUndefined(this.props.TTypeID)) {
      this.getTTypes()
    }
    if (!isEmptyNullUndefined(this.props.SubTTypeID)) {
      this.getSubTTypes()
    }
    this.setState({ TTypeDropDownArray: this.TTypeDropDownArray() })
    this.setState({ SubTTypeDropDownArray: this.SubTTypeDropDownArray() })
  }


  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.TTypeID !== this.props.TTypeID) {
      this.props.fetchResetState('all')
      this.getTTypes()
    }
    if (prevProps.SubTTypeID !== this.props.SubTTypeID) {
      this.props.fetchResetState('all')
      this.getSubTTypes()
    }
    if (prevProps.clients !== this.props.clients) {
      // console.log('Updating clients...')
      this.setState({ TTypeDropDownArray: this.TTypeDropDownArray() })
      this.setState({ SubTTypeDropDownArray: this.SubTTypeDropDownArray() })
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  getTTypes = async () => {

    this.props.fetchResetState('all')
    this.setState({ ...this.state, ...initialState })
    // console.log('In DeleteTType and fetching matters for this TType: ', this.props.TTypeID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.TTypeID)) {
      fetchresponse = await szenokPostAPI('tTypeInfo', { fetchstr: 'getMattersForTType', IDNumber: this.props.TTypeID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse for getTTypes(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        TTypeMatters: fetchresponse.payload,
        TTMIDs: Array.from(new Set(fetchresponse.payload.map(matter => matter.matter_id))),
      })
    }
    else {
      // console.log('GOT TO HERE!!!!###$#')
      this.getEmptyTTypeChildren()
      // console.log('CLICKED ON IT!!!')
    }
  }

  getSubTTypes = async () => {

    this.props.fetchResetState('all')
    this.setState({ ...this.state, ...initialState })
    // console.log('In DeleteSubTType and fetching matters for this SubTType: ', this.props.SubTTypeID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.SubTTypeID)) {
      fetchresponse = await szenokPostAPI('tTypeInfo', { fetchstr: 'getMattersForSubTType', IDNumber: this.props.SubTTypeID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse for getSubTTypes(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        SubTTypeMatters: fetchresponse.payload,
        STTMIDs: Array.from(new Set(fetchresponse.payload.map(matter => matter.matter_id))),
      })
    }
    else {
      // console.log('GOT TO HERE!!!!###$#')
      this.props.handleDeleteSubTType()
      // console.log('CLICKED ON IT!!!')
    }
  }

  getEmptyTTypeChildren = async () => {

    this.props.fetchResetState('all')
    this.setState({ ...this.state, ...initialState })
    // console.log('In DeleteSubTType and fetching empty SubTTypes for this TType: ', this.props.TTypeID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.TTypeID)) {
      fetchresponse = await szenokPostAPI('tTypeInfo', { fetchstr: 'getSubTTypesForTType', IDNumber: this.props.TTypeID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse for getEmptyTTypeChildren(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        EmptyTTypeChildren: fetchresponse.payload,
        ETTCIDs: Array.from(new Set(fetchresponse.payload.map(matter => matter.sub_transaction_type_id))),
      })
    }
    else {
      // console.log('GOT TO HERE!!!!###$#')
      this.props.handleDeleteTType()
      // console.log('CLICKED ON IT!!!')
    }
  }

  handleSwitchSubTType = async (specificMatter) => {

    if (isEmptyNullUndefined(specificMatter)) {
      // console.log('No specific matter to deal with so switching them all...')
      try {

        //Clear fetcherrors, info and such
        this.props.fetchResetState('all')

        let fetchresponse = {}
        fetchresponse = await szenokPostAPI('tTypeInfo', {
          fetchstr: 'switchSubTType',
          matterID: isEmptyNullUndefined(this.props.SubTTypeID) ? this.state.TTMIDs.join() : this.state.STTMIDs.join(),
          switchTType: this.state.selectedSwitchSubTType.split(',')[0],
          switchSubTType: this.state.selectedSwitchSubTType.split(',')[1]
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse from handleSwitchSubTType in deleteTType: ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {

          //Update the matters store
          // reduxupdate('transactionsandsubs')
          this.setState({ selectedSwitchSubTType: null })

          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          // this.getPaymentsForInvoice()
          // this.props.getInvoicesOutstanding()
          reduxupdate('transactionsandsubs')
          if (!isEmptyNullUndefined(this.props.TTypeID)) {
            this.getTTypes()
          }
          if (!isEmptyNullUndefined(this.props.SubTTypeID)) {
            this.getSubTTypes()
          }
          // this.getEmptyTTypeChildren()
          // this.props.toggleSubTTypesForTTypeExists()
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        let errorObj = []
        errorObj.push(error.toString())

        this.props.fetchErrors(errorObj)
      }
    }
    else {
      // console.log('We got a specific matter to deal with and it is #', specificMatter)
      try {

        //Clear fetcherrors, info and such
        this.props.fetchResetState('all')

        let fetchresponse = {}
        fetchresponse = await szenokPostAPI('tTypeInfo', {
          fetchstr: 'switchSubTType',
          matterID: specificMatter.matter_id,
          switchTType: specificMatter.selected.split(',')[0],
          switchSubTType: specificMatter.selected.split(',')[1]
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse from handleSwitchSubTType in deleteTType: ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {

          //Update the matters store
          // reduxupdate('transactionsandsubs')
          this.setState({ selectedSwitchSubTType: null })

          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          // this.getPaymentsForInvoice()
          // this.props.getInvoicesOutstanding()
          reduxupdate('transactionsandsubs')
          if (!isEmptyNullUndefined(this.props.TTypeID)) {
            this.getTTypes()
          }
          if (!isEmptyNullUndefined(this.props.SubTTypeID)) {
            this.getSubTTypes()
          }
        }
      }
      catch (error) {
        console.log('This is the errors: ', error)
        let errorObj = []
        errorObj.push(error.toString())

        this.props.fetchErrors(errorObj)
      }
    }

  }

  handleDeleteEmptySubTTypes = async (ETTCIDs) => {

    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}

      if (!isEmptyNullUndefined(ETTCIDs)) {
        // console.log('Running deleteTType-deleteSubTType with these ETTCIDs: ', ETTCIDs)
        fetchresponse = await szenokPostAPI('deleteTType', {
          fetchstr: 'deleteSubTType',
          IDNumber: ETTCIDs
        }, this.props.login.currentToken)
      }
      else {
        // console.log('Running deleteTType-deleteSubTType with this IDNumber: ', this.state.selectedSwitchSubTType)
        fetchresponse = await szenokPostAPI('deleteTType', {
          fetchstr: 'deleteSubTType',
          IDNumber: this.state.selectedSubTType
        }, this.props.login.currentToken)
      }
      // console.log('Now printing fetchresponse for handleDeleteEmptySubTTypes: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        reduxupdate('transactionsandsubs')

        //Rest of stuff
        this.setState({ ...initialState })
        this.setState(prevState => ({ visible: !prevState.visible }))
        // this.getPaymentsForInvoice()
        // this.props.getInvoicesOutstanding()
        this.props.handleDeleteTType()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }

  TTypeDropDownArray = () => {

    // console.log('In TTypeDropDownArray with this props.TTypeID: ', this.props.TTypeID)

    var array = this.props.clients.transactiontypesandsubsarray.reduce((acc, result) => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `EID${result[0]},${result[1]}`
      obj.text = result[2] + ' -- ' + result[3]
      obj.value = result[0] + ',' + result[1]
      obj.ttype = result[0]


      if (obj.ttype === this.props.TTypeID) {
        // console.log(`Not including transaction type with ID of ${obj.value} (which is the one called '${this.props.clients.transactiontypesandsubsarray.find(ttype => ttype[0] + ',' + ttype[1] === obj.value)}')`)
        return acc
      }
      else {
        acc.push(obj)
        return acc
      }
    },
      []
    )
    // console.log('This is array: ', array)
    return array
  }

  SubTTypeDropDownArray = () => {

    var array = this.props.clients.transactiontypesandsubsarray.reduce((acc, result) => {
      // console.log('This is result: ', result)
      var obj = {}
      obj.key = `EID${result[0]},${result[1]}`
      obj.text = result[2] + ' -- ' + result[3]
      obj.value = result[0] + ',' + result[1]

      if (obj.value === this.props.TTypeID + ',' + this.props.SubTTypeID) {
        // console.log(`Not including transaction type with ID of ${obj.value} (which is the one called '${this.props.clients.transactiontypesandsubsarray.find(ttype => ttype[0] + ',' + ttype[1] === obj.value)}')`)
        return acc
      }
      else {
        acc.push(obj)
        return acc
      }
    },
      []
    )
    // console.log('This is array: ', array)
    return array
  }



  render() {
    if (this.state.hasError) return <h1>Something went wrong in Component-DeleteTType</h1>
    else
      return (
        <div>

          {/* This will show a list of all of the matters for a transaction type */}
          {!isEmptyNullUndefined(this.state.TTypeMatters) && isEmptyNullUndefined(this.props.SubTTypeID) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Switch All Matters Using This Transaction Type
                (i.e., {this.props.clients.transactiontypesandsubsarray.find(ttype => ttype[0] === this.props.TTypeID)[2]})
                to a Different Transaction Type</h4>
              <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Switch' button!)</h6>

              <Button primary disabled={isEmptyNullUndefined(this.state.selectedSwitchSubTType)} onClick={() => this.handleSwitchSubTType()}>Switch</Button>
              {/* <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button> */}
              <Button color='red' onClick={() => {
                if (isEmptyNullUndefined(this.state.selectedSwitchSubTType)) this.props.handleCancelDelete()
                else this.setState({ selectedSwitchSubTType: null })
              }
              }>Cancel</Button>
              <br /><br />

              <Dropdown
                placeholder='Select Transaction Type'
                name="TType"
                width={5}
                selection
                search
                label='Transaction Type'
                options={this.state.TTypeDropDownArray}
                value={this.state.selectedSwitchSubTType}
                loading={this.state.success}
                onChange={(e, { name, value }) => {
                  // setFieldValue(name, value)
                  this.setState({ selectedSwitchSubTType: value })
                  // console.log(name, value)
                }}
              />

              <ReactTableMaker passedData={this.state.TTypeMatters}
                passedColumns={DeleteColumns('deletettypes', this.props.history, this.handleSwitchSubTType, this.state.TTypeDropDownArray)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />

              {/* {this.state.TTypeMatters.map((item, i) => {
                return <SubTTypesListMaker
                  {...item}
                  number={i}
                  handleSwitchSubTType={this.handleSwitchSubTType}
                  selectedSwitchSubTType={this.state.selectedSwitchSubTType}
                  // SubTTypeID={this.props.SubTTypeID}
                  SubTTypeDropDownArray={this.state.TTypeDropDownArray}
                  // matterID={this.props.matterID}
                  // clientID={this.props.clientID}
                  // matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                  // invoice={item.invoice_number}
                  // handleDeleteTycoon={this.state.handleDeleteTycoon}
                  key={`ACLM${i}`}
                />
              })
              } */}
            </Segment>}

          {/* This will show a list of all of the matters for a sub-transaction-type */}
          {!isEmptyNullUndefined(this.state.SubTTypeMatters) && !isEmptyNullUndefined(this.props.SubTTypeID) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Switch All Matters Using This Sub-Transaction Type
                (i.e., {this.props.clients.transactiontypesandsubsarray.find(ttype => ttype[0] + ',' + ttype[1] === this.props.TTypeID + ',' + this.props.SubTTypeID).slice(2, 50).join(' - ')})
                to a Different Transaction Type</h4>
              <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Switch' button!)</h6>

              <Button primary disabled={isEmptyNullUndefined(this.state.selectedSwitchSubTType)} onClick={() => this.handleSwitchSubTType()}>Switch</Button>
              {/* <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button> */}
              <Button color='red' onClick={() => this.setState({ selectedSwitchSubTType: null })}>Cancel</Button>
              <br /><br />

              <Dropdown
                placeholder='Select Transaction Type'
                name="TType"
                width={5}
                selection
                // required
                search
                label='Transaction Type'
                options={this.state.SubTTypeDropDownArray}
                value={this.state.selectedSwitchSubTType}
                loading={this.state.success}
                onChange={(e, { name, value }) => {
                  // setFieldValue(name, value)
                  this.setState({ selectedSwitchSubTType: value })
                  // console.log(name, value)
                }}
              />

              <ReactTableMaker passedData={this.state.SubTTypeMatters}
                passedColumns={DeleteColumns('deletesubttypes', this.props.history, this.handleSwitchSubTType, this.state.SubTTypeDropDownArray)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />
{/* 
              {this.state.SubTTypeMatters.map((item, i) => {
                return <SubTTypesListMaker
                  {...item}
                  number={i}
                  handleSwitchSubTType={this.handleSwitchSubTType}
                  selectedSwitchSubTType={this.state.selectedSwitchSubTType}
                  SubTTypeID={this.props.SubTTypeID}
                  SubTTypeDropDownArray={this.state.SubTTypeDropDownArray}
                  // matterID={this.props.matterID}
                  // clientID={this.props.clientID}
                  // matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                  // invoice={item.invoice_number}
                  // handleDeleteTycoon={this.state.handleDeleteTycoon}
                  key={`ACLM${i}`}
                />
              })
              } */}
            </Segment>}

          {/* This will show a list of all of the counterparty-matter connections */}
          {!isEmptyNullUndefined(this.state.EmptyTTypeChildren) && !isEmptyNullUndefined(this.props.TTypeID) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Delete All Sub-Transaction Types For This Transaction Type (i.e., {this.props.clients.transactiontypesandsubsarray.find(ttype => ttype[0] === this.props.TTypeID)[2]}))</h4>
              <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h6>

              <Button primary onClick={() => this.handleDeleteEmptySubTTypes(this.state.ETTCIDs)}>Delete {this.state.EmptyTTypeChildren.length > 1 ? 'All Sub-Transactions' : 'This Sub-Transaction'}</Button>
              <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

              <ReactTableMaker passedData={this.state.EmptyTTypeChildren}
                passedColumns={DeleteColumns('deleteemptychildren', this.props.history, this.handleSwitchSubTType, this.state.SubTTypeDropDownArray)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />

              {/* {this.state.EmptyTTypeChildren.map((item, i) => {
                return <EmptySubTTypesListMaker
                  {...item}
                  number={i}
                  TTypeID={this.props.TTypeID}
                  // handleDeleteTycoon={this.state.handleDeleteTycoon}
                  key={`PLM${i}`}
                />
              })
              } */}
            </Segment>}

          {/* <DisplayState state={this.state} /> */}

        </div>
      )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteTType))


DeleteTType.defaultProps = {
  // Counterparties: [],
  TTypeID: null,
  SubTTypeID: null,
  // matterID: null,
  // clientID: null,
  handleDeleteTType: () => { },
  handleDeleteSubTType: () => { },
  handleCancelDelete: () => { },
  toggleSubTTypesForTTypeExists: () => { }
}

// const SubTTypesListMaker = (props) => {
//   // console.log('This is the props in AClassesListMaker: ', props)

//   const [selected, setSelected] = useState(null)

//   if (!isEmptyNullUndefined(props.matter_id))
//     return (

//       <Segment secondary>
//         <Grid>

//           <Grid.Column width={8}>


//             <h6><b>Matter Number: </b>{props.matter_id}</h6>
//             <h6><b>Matter Name: </b>{props.matter_name}</h6>
//             <h6><b>Matter Amount: </b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.matter_deal_amount)}</h6>
//             <h6><b>Matter Date: </b>{props.matter_origination_date}</h6>
//             <h6><b>Transaction Type: </b>{props.transaction_type}</h6>
//             {!isEmptyNullUndefined(props.sub_transaction_type) && <h6><b>Sub-Transaction Type: </b>{props.sub_transaction_type}</h6>}
//             <br /><br />
//           </Grid.Column>
//           <Grid.Column width={8}>

//             <h6 style={{ color: 'blue' }}>(You can switch each matter individually, as well...)</h6>
//             <Button primary disabled={isEmptyNullUndefined(selected)} onClick={() => props.handleSwitchSubTType({ selected: selected, matter_id: props.matter_id })}>Switch</Button>
//             {/* <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button> */}
//             <Button color='red' onClick={() => setSelected(null)}>Cancel</Button>
//             <br />
//             <Dropdown
//               placeholder='Select Transaction Type'
//               name="TType"
//               width={5}
//               selection
//               // required
//               search
//               label='Transaction Type'
//               // error={(touched.AClass && (typeof get(values, 'AClass') === 'undefined' || get(values, 'AClass') === null)) ||
//               //   (has(status, 'errors.AClass'))}
//               // closeOnBlur
//               // selection
//               // onBlur={() => setFieldTouched('AClass', true)}
//               options={props.SubTTypeDropDownArray}
//               value={selected}
//               // loading={this.state.success}
//               onChange={(e, { name, value }) => {
//                 // setFieldValue(name, value)
//                 // this.setState({selectedSwitchSubTTpe: value})
//                 setSelected(value)
//                 console.log(name, value)
//               }}
//             />
//           </Grid.Column>

//         </Grid>
//       </Segment>
//     )
//   else return null
// }


// const EmptySubTTypesListMaker = (props) => {
//   // console.log('This is the props in EmptySubTTypesListMaker: ', props)
//   return (

//     <Segment secondary>

//       <h6><b>Transaction Type: </b>{props.transaction_type}</h6>
//       {!isEmptyNullUndefined(props.sub_transaction_type) && <h6><b>Sub-Transaction Type: </b>{props.sub_transaction_type}</h6>}


//     </Segment>
//   )
// }


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const DeleteColumns = (type, history, handleSwitchSubTType, TTypeDropDownArray) => {
  let results = []

  switch (type) {
    case 'deletettypes':
      results = [
        {
          Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Amount</span>,
          accessor: 'matter_deal_amount',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Origination Date</span>,
          accessor: 'matter_origination_date',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>
            <React.Fragment>
              Switch
              <h6 style={{ color: 'blue' }}>(You can switch each matter individually, as well...)</h6>
            </React.Fragment>
          </span>,
          accessor: 'matter_origination_date2',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) => <Switcher
            TTypeDropDownArray={TTypeDropDownArray}
            matter_id={row.original.matter_id}
            handleSwitchSubTType={handleSwitchSubTType}
          />
        },
      ]
      break;
    case 'deletesubttypes':
      results = [
        {
          Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
          accessor: 'client_name',
          sortType: 'alphanumeric',
          Filter: '',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}&clientID=${row.original.client_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Name</span>,
          accessor: 'matter_name',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) =>
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ 'float': 'left', cursor: 'pointer' }}
              onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
            >
              {cell.value}
            </span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Amount</span>,
          accessor: 'matter_deal_amount',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Matter Origination Date</span>,
          accessor: 'matter_origination_date',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Transaction Type</span>,
          accessor: 'transaction_type',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Sub-Transaction Type</span>,
          accessor: 'sub_transaction_type',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>
            <React.Fragment>
              Switch
              <h6 style={{ color: 'blue' }}>(You can switch each matter individually, as well...)</h6>
            </React.Fragment>
          </span>,
          accessor: 'matter_origination_date2',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell, row }) => <Switcher
            TTypeDropDownArray={TTypeDropDownArray}
            matter_id={row.original.matter_id}
            handleSwitchSubTType={handleSwitchSubTType}
          />
        },
      ]
      break;
    case 'deleteemptychildren':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Transaction Type</span>,
          accessor: 'transaction_type',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Sub-Transaction Type</span>,
          accessor: 'sub_transaction_type',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
      ]
      break;
    default:
    // code block
  }
  return results
}


class Switcher extends Component {

  state = {
    hasError: false, ...initialState,
    selected: null,
  }

  render() {

    return (
      <React.Fragment>

        <Button primary disabled={isEmptyNullUndefined(this.state.selected)} size='tiny' onClick={() => this.props.handleSwitchSubTType({ selected: this.state.selected, matter_id: this.props.matter_id })}>Switch</Button>
        <Button color='red' size='tiny' onClick={() => this.setState({ selected: null })}>Cancel</Button>
        <Button size="tiny">
          <Dropdown
            placeholder='Select Transaction Type'
            name="TType"
            search
            label='Transaction Type'
            options={this.props.TTypeDropDownArray}
            value={this.state.selected}
            onChange={(e, { name, value }) => {
              this.setState({ selected: value })
              // console.log(name, value)
            }}
          />
        </Button>
      </React.Fragment>
    )
  }
}
