import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk"
import rootReducer from './reducers/rootReducer.js'


//Create redux store, pull the reducers from rootReducer and then add the Chrome extension tools for redux
export default createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk),
    // other store enhancers if any
  )
)