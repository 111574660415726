import React, { Component } from 'react'
import { isEmptyNullUndefined } from '../helper/Misc';


let urls = new WeakMap()

let blobUrl = blob => {
  if (urls.has(blob)) {
    return urls.get(blob)
  } else {
    let url = URL.createObjectURL(blob)
    urls.set(blob, url)
    return url
  }
}



export default class DragDrop extends Component {
  state = { counter: 0, size: '' }

  refresh = () => {
    this.setState(({ counter }) => ({ counter: counter + 1 }))
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, 100)
  }
  
  // componentDidUpdate(prevProps) {
  //   if (prevProps.file !== this.props.file) {
  //     this.setState({fileSize: this.props.file.size})
  //   }
  // }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  onDrag = event => {
    event.preventDefault()
  }

  onDrop = event => {
    event.preventDefault()
    let file = event.dataTransfer.files[0]
    // this.setState({ file })
    this.props.setFile(file)
    // var dimensions = sizeOf(file)
    // console.log(dimensions)
    this.setState({ fileSize: file.size })
  }

  render() {
    let { file } = this.props
    let url = file && blobUrl(file)

    return (
      <div onDragOver={this.onDrag} onDrop={this.onDrop} style={DropArea}>
        {isEmptyNullUndefined(this.state.fileSize) && <p>Drop an image!</p>}
        <div style={{ backgroundColor: '#ffffcc' }}>
          <img src={url} alt='' style={{maxWidth: 300, maxHeight: 200}}/>
        </div>
      </div>
    )
  }
}


DragDrop.defaultProps = {
  file: null,
}

const DropArea = {
  background: '#efefef',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'calc(30vw - 80px)',
  height: 'calc(30vh - 80px)',
  border: 'solid 40px transparent',
  transition: 'all 250ms ease-in-out 0s',
  position: 'relative',
}

// const Status = {
//   background: 'transparent',
//   display: 'block',
//   fontFamily: 'Helvetica',
//   color: 'black',
//   fontSize: '60px',
//   fontWeight: 'bold',
//   textAlign: 'center',
//   lineHeight: 'calc(80vh - 80px)',
//   position: 'absolute',
//   top: 0,
//   bottom: 0,
//   left: 0,
//   right: 0,
//   transition: 'all 250ms ease-in-out 0s',
// }