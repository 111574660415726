import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'
import { isEmptyNullUndefined } from '../helper/Misc'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import * as mapActions from '../../actions/mapActions'
import { connect } from 'react-redux';

import { Icon, Menu, Checkbox, Form } from 'semantic-ui-react';
import { YearDropDown, EmployeeDropDown } from '../charts/Charts-AClass-Functions'
import { StatesGetAllStates } from '../charts/Charts-AClass-Functions'

import MapsStates from './Component-Maps-States'
import MapsLocations from './Component-Maps-Locations-Container'
import allStates from '../charts/allstates.json'

import queryString from 'query-string'

// import DisplayState from '../helper/DisplayState';



class Maps extends Component {

  state = {
    loading: true,
    currentYear: new Date().getFullYear(),
    selectedYear: 0,
    selectedPartner: 0,
    States: [],
    Matters: [],
    selectedClient: 0,
    selectedState: null,
    MattersForState: [],
    ReducedMattersForState: [],
    showByInvoicingPartner: !this.props.byOriginating,
    reset: false,
    ClientsMattersDropDownArray: [],
    activeMenuItem: 'Year',
    showLocations: false,
    // Point Items
    GOOGLEAPI: '',
    zoom: 10,
    center: { lat: 40.73, lng: -73.93 },
    maxViewPercentage: 75,
    viewPercentage: 1,
  }

  async componentDidMount() {

    //First, if there is a specific location in the querystring, then setstate showlocations to true and check the redux values (in case someone copied and pasted into the URL)
    const querystr = queryString.parse(this.props.location.search)
    // console.log('This is querystr: ', querystr)
    if (!isEmptyNullUndefined(querystr)) {
      this.setState({ showLocations: true })
      if (this.props.maps.currentLatitude !== querystr.lat) this.props.setCurrentLatitude(querystr.lat)
      if (this.props.maps.currentLongitude !== querystr.lng) this.props.setCurrentLongitude(querystr.lng)
    }

    // Then move on with things...
    this.props.fetchResetState('all')
    this.getStateLocations()

    let droparray = this.props.clients.clientsarray.map(item => {
      return {
        key: `CMID${item[0]}`,
        ID: item[0],
        text: !isEmptyNullUndefined(item[1]) ? item[1] : item[3] + ', ' + item[2],
        value: parseInt(item[0])
      }
    })
    droparray.unshift({ key: 'CMID00', text: 'All Clients', value: 0 })
    this.setState({
      ClientsMattersDropDownArray: droparray
    })
    setTimeout(() => this.getPointLocations(), 200)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => this.getStateLocations(), 200)
    }
    if (prevState.selectedPartner !== this.state.selectedPartner) {
      // console.log(this.state.MattersForState)
      if (prevState.selectedPartner === 0) this.setState({ selectedClient: 0 })
    }
  }

  getStateLocations = async () => {
    StatesGetAllStates(this.props.maxYears, this.props.login.currentToken, (value) => this.setState({
      States: value.map(item => ({
        ...item,
        amount: 0,
        topoID: parseInt(allStates.find(topo => topo.id === item.state_abbr).val)
      })),
      Loading: false
    }))

    let fetchresponse = await szenokPostAPI('getStates', { fetchstr: 'mattersforstates' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for tablesFetch: ', fetchresponse)

    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Matters: fetchresponse.payload.map(item => item)
    })

    fetchresponse = await szenokPostAPI('getStates', { fetchstr: 'getAllLocations' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getAllLocations: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      Locations: fetchresponse.payload
    })
  }

  getPointLocations = async () => {
    let fetchresponse = await szenokPostAPI('google', { fetchstr: 'getAPI' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for google getAPI: ', fetchresponse)
    this.setState({ GOOGLEAPI: fetchresponse.payload, GoogleAPILoaded: true })

    fetchresponse = await szenokPostAPI('getStates', { fetchstr: 'locationsForMatters' },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for locationsForMatters: ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload)) {

      this.setState({
        Facilities: fetchresponse.payload.map(item => {
          return {
            latitude: item.location.x,
            longitude: item.location.y,
            address: item.address,
            matter_name: item.matter_name,
            matter_id: item.matter_id,
            client_name: item.client_name,
            client_id: item.client_id,
            origination_date: item.origination_date,
            closing_date: item.closing_date,
            originating_employee_id: item.originating_employee_id,
            originating_employee: item.originating_employee,
            location_id: item.location_id
          }
        })
      })
    }
  }

  handleZoomCenterChange = (zoom, center) => {
    // !isEmptyNullUndefined(zoom) && !isEmptyNullUndefined(center) &&
    //   console.log(zoom)
    // console.log('This is center: ', center)
    if (!isEmptyNullUndefined(zoom)) this.setState({ zoom: zoom })
    if (!isEmptyNullUndefined(center.lat)) this.setState({ center: { lat: center.lat, lng: center.lng } })
    // this.setState({
    //   zoom: zoom,
    //   center: { lat: center.lat, lng: center.lng }
    // })
  }

  handleItemClick = (e, { name }) => this.setState({ activeMenuItem: name })

  handleIconClick = (e, { name }) => {
    if (name === 'plus') { if (this.state.maxViewPercentage < 100) this.setState({ maxViewPercentage: 5 + this.state.maxViewPercentage, viewPercentage: (5 + this.state.maxViewPercentage) / 75 }) }
    if (name === 'minus') { if (this.state.maxViewPercentage > 5) this.setState({ maxViewPercentage: this.state.maxViewPercentage - 5, viewPercentage: (this.state.maxViewPercentage - 5) / 75 }) }
    if (name === 'redo') { this.setState({ maxViewPercentage: this.props.maxViewPercentage, viewPercentage: 1 }) }
  }

  render() {

    return (
      <div>
        <h1> <Icon name='map signs' size='large' circular /> Locations</h1>

        <div style={{ backgroundColor: '#111', paddingTop: '5px', paddingRight: '5px', paddingBottom: '-5px', display: 'inline-block', borderRadius: '5px' }}>
          <Menu tabular size='large' inverted compact stackable >
            <Menu.Item
              name=''
              // active={this.state.activeMenuItem === 'Year'}
              onClick={this.handleItemClick}
              color='blue'
              icon='calendar alternate outline'
            />
            <Form autoComplete="off" >
              <YearDropDown year={this.state.selectedYear} maxYears={this.props.maxYears}
                currentYear={this.state.currentYear} includeAll={true} includeHeader={false} callback={(value) => this.setState({ selectedYear: value })} />
            </Form>
            <Menu.Item
              name=''
              // active={this.state.activeMenuItem === 'Partner'}
              onClick={this.handleItemClick}
              color='green'
              icon='user secret'
            />
            <Form autoComplete="off" >
              <EmployeeDropDown selectedPartner={this.state.selectedPartner} includeAll={true} includeHeader={false} callback={(value) => this.setState({ selectedPartner: value })} />
            </Form>
            <Menu.Item
              name=''
              // active={this.state.activeMenuItem === 'Client'}
              onClick={this.handleItemClick}
              color='purple'
              icon='chess queen'
            />
            <Form autoComplete="off" >
              <Form.Dropdown
                placeholder='Select Client/Matter'
                name="ClientMatter"
                width={6}
                search
                selection
                // autoComplete="off"
                options={this.state.ClientsMattersDropDownArray.map(item => { return { key: item.key, text: item.text, value: item.value } })}
                clearable
                value={this.state.selectedClient}
                onChange={async (e, { name, value }) => {
                  // console.log(name, value)
                  this.setState({ selectedClient: !isEmptyNullUndefined(value) ? value : 0, selectedPartner: 0 })
                }}
              />
            </Form>
            <Menu.Item
              name=''
              // active={this.state.activeMenuItem === 'Client'}
              onClick={this.handleItemClick}
              color='purple'
              icon='map'
            />
            <div style={{ backgroundColor: this.state.showLocations ? '#e7e6f0' : '#d5ece4', marginBottom: '15px', borderRadius: '3px', display: 'flex' }} >
              <div style={{ display: 'inline-block', paddingLeft: '5px', paddingRight: '5px', color: !this.state.showLocations ? '#ff8040' : '#111' }}>
                States
              </div>
              <div style={{ padding: '5px', display: 'inline-block' }}>
                <Form autoComplete="off">

                  <Checkbox
                    name={'spix'}
                    toggle
                    onFocus={(event) => {
                      event.target.setAttribute('autocomplete', 'off');
                      // console.log(event.target.autocomplete);
                    }}
                    // style={checkboxStyle}
                    label=''
                    // value={this.state}
                    autoComplete="new-password"
                    checked={this.state.showLocations}
                    onChange={() => this.setState((prevState) => ({ showLocations: !prevState.showLocations }))}
                  />
                </Form>
              </div>
              <div style={{ display: 'inline-block', paddingRight: '5px', color: this.state.showLocations ? '#ff8040' : '#111' }}>
                Locations
              {/* {this.state.showLocations ? 'Show States' : 'Show Locations'} */}
              </div>
            </div>
          </Menu>
        </div>

        {/* <Grid>
          <Grid.Row verticalAlign='bottom'>
            <Grid.Column width={4}>
              <YearDropDown year={this.state.selectedYear} maxYears={this.props.maxYears}
                currentYear={this.state.currentYear} includeAll={true} callback={(value) => this.setState({ selectedYear: value })} />
            </Grid.Column>
            <Grid.Column width={4}>
              <EmployeeDropDown selectedPartner={this.state.selectedPartner} includeAll={true} includeHeader={true} callback={(value) => this.setState({ selectedPartner: value })} />
            </Grid.Column>
            <Grid.Column width={4}>
              {!isEmptyNullUndefined(this.state.ClientsMattersDropDownArray) &&
                <React.Fragment>
                  <Header as='h4'>Pick a client:</Header>
                  <Dropdown
                    placeholder='Select Client/Matter'
                    name="ClientMatter"
                    width={6}
                    search
                    selection
                    options={this.state.ClientsMattersDropDownArray.map(item => { return { key: item.key, text: item.text, value: item.value } })}
                    clearable
                    value={this.state.selectedClient}
                    onChange={async (e, { name, value }) => {
                      console.log(name, value)
                      this.setState({ selectedClient: !isEmptyNullUndefined(value) ? value : 0, selectedPartner: 0 })
                    }}
                  />
                </React.Fragment>
              }
            </Grid.Column>
            <Grid.Column width={4}>
            </Grid.Column>
          </Grid.Row>
        </Grid> */}

        <div className='so-charts-charts-selector'>

          {!this.state.showLocations &&
            <MapsStates
              Matters={this.state.Matters}
              States={this.state.States}
              Locations={this.state.Locations}
              selectedYear={this.state.selectedYear}
              currentYear={this.state.currentYear}
              selectedPartner={this.state.selectedPartner}
              selectedClient={this.state.selectedClient}
              ClientsMattersDropDownArray={this.state.ClientsMattersDropDownArray}
              allowDots={true}
              handleIconClick={this.handleIconClick}
              viewPercentage={this.state.viewPercentage}
            />
          }
          {this.state.showLocations &&
            <MapsLocations
              selectedYear={this.state.selectedYear}
              currentYear={this.state.currentYear}
              selectedPartner={this.state.selectedPartner}
              selectedClient={this.state.selectedClient}
              token={this.props.login.currentToken}
              GOOGLEAPI={this.state.GOOGLEAPI}
              GoogleAPILoaded={this.state.GoogleAPILoaded}
              Facilities={this.state.Facilities}
              zoom={this.state.zoom}
              center={this.state.center}
              sendZoomCenterToParent={this.handleZoomCenterChange}
              employeesarray={this.props.clients.employeesarray}
              handleIconClick={this.handleIconClick}
              viewPercentage={this.state.viewPercentage}
            />
          }
        </div>
        {/* <DisplayState state={this.state} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions, mapActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Maps))

Maps.defaultProps = {
  maxViewPercentage: 75,
  maxYears: 5,
  byOriginating: true,
  currentYear: new Date().getFullYear(),
  selectedYear: new Date().getFullYear(),
  selectedPartner: 1,
  // handleSomething: () => { },
}

