import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import ReactTableMaker from '../helper/ReactTableMaker'

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { getHighlightedText, filterSimpleInput } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Header, Label, Divider, Button, Segment } from 'semantic-ui-react';
// import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
import _ from "lodash"
import { has, get } from 'lodash';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';
// import { isNullOrUndefined } from 'util';

class AddLawFirms extends Component {

  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
  }

  state = {
    visible: false,
    LawFirm: '',
    LawFirm2Regex: [],
    LawFirm2RegexSpecific: '',
    LawFirmArray: [],
    rtablesize: -1,
    rtablenone: false,
    rtablefiltered: false,
    initialValues: {},
  }

  componentDidMount() {
    this.props.fetchResetState('all')
    if (this.props.update) this.getSpecificLawFirm()
  }

  getSpecificLawFirm = async () => {
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('lawFirmInfo', { fetchstr: 'getLawFirm', IDNumber: this.props.match.params.lawFirmID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getLawFirm: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: { LawFirm: fetchresponse.payload.law_firm }
    })
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  debouncedFilter = _.debounce(async (e, filterfunction, setStatus, filterarray, errObj) => {
    var { updatedListGeneralSorted, updatedListSpecific } = await filterfunction(filterarray, e)
    this.setState({ LawFirm2Regex: updatedListGeneralSorted })
    if (updatedListSpecific.length > 0) {
      if (this.props.update && updatedListSpecific[0][1] !== this.state.initialValues.LawFirm) {
        this.setState({ LawFirm2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
      else if (this.props.update && updatedListSpecific[0][1] === this.state.initialValues.LawFirm) {
        this.setState({ LawFirm2RegexSpecific: '' })
        setStatus({ errors: {} })
      } 
      if (!this.props.update) {
        this.setState({ LawFirm2RegexSpecific: updatedListSpecific })
        setStatus({ errors: errObj })
      }
    }
    else {
      this.setState({ LawFirm2RegexSpecific: '' })
      setStatus({ errors: {} })
    }

    if (this.state.LawFirm2Regex.length === 0 && this.state.LawFirm.length > 0) this.setState({ rtablenone: true })
    else this.setState({ rtablenone: false })
    // this.setState({ rtablefiltered: true })
    // let newsize = updatedListGeneralSorted.length //records.getResolvedState().sortedData.length
    // newsize > 0 ? this.setState({ rtablesize: newsize, rtablenone: false }) : this.setState({ rtablesize: -1, rtablenone: true })


  }, 100)

  // toggletable = () => this.setState((prevState) => ({ showtable: !prevState.showtable }))



  render() {


    // const records = this.reactTable.current
    // console.log('This is records: ', records)

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      LawFirm: '',
    } : { LawFirm: this.state.initialValues.LawFirm }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300

    // const filterCaseInsensitive = ({ id, value }, row) =>
    //   row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true

    // let LawFirmColumns = [
    //   {
    //     Header: "Law Firm ID",
    //     accessor: 'law_firm_id',
    //     show: false
    //   },
    //   {
    //     Header: "Law Firms",
    //     accessor: 'law_firm',
    //   }
    // ]

    let Firms = this.state.LawFirm2Regex.length > 0 ? this.state.LawFirm2Regex : this.props.clients.lawfirmsarray

    let LawFirmTableObj = this.state.rtablenone ? [] :
      Firms.reduce((tempobj, v) => {
        let obj = {}
        obj['law_firm_id'] = v[0]
        obj['law_firm'] = v[1]
        // console.log('This is obj: ', obj)
        tempobj.push(obj)
        return tempobj;
      }, [])



    // if (LawFirmTableObj.length > 0) console.log(LawFirmTableObj)

    return (
      <div>
        <Header as='h1'>Law Firm {this.props.update === true ? 'Edit' : 'Add'} Form</Header>
        {this.props.update &&
          <Header as='h2'>Editing: {this.state.initialValues.LawFirm}</Header>
        }
        {/* <TableMaker
          propsdata={this.props.clients.transactiontypesandsubsarray}
          handleClick={() => null}
          handleHover={() => null}
          updateDataState={() => console.log('Nothin')} 
        includeIDColumn={true}/> */}

        <Formik

          enableReinitialize={true}
          getHighlightedText={getHighlightedText}
          // test1={test1}
          test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={AddLawFirmSchema}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              this.setState({ LawFirm2Regex: [], LawFirm2RegexSpecific: '' })
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editLawFirm', { LawFirm: values.LawFirm, LawFirmID: this.props.match.params.lawFirmID }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addLawFirm', { LawFirm: values.LawFirm }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for edit/addLawFirm: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (isEmptyNullUndefined(fetchresponse.validationerrors) && fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                //Update the clients store
                reduxupdate('lawfirms')
                if (this.props.update) await this.getSpecificLawFirm()

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                actions.resetForm({ ...initialValues })
                this.setState({ rtablenone: false })
                // this.props.showCounterpartyInfoFormToggle(true)
                // this.props.showAddLawFirmFormToggle(false)
                this.props.closeCallback(false)
                actions.setSubmitting(false)
                //Send it back only if not inside the CP Info Screen
                !this.props.fromAddCPInfo && this.props.history.push(`/lawfirms`)
              }
            }
            catch (error) {
              actions.setSubmitting(false)
              this.props.fetchErrors(error.toString())
            }
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
            //this.setState({error: null})
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment color='grey' raised>
                  <Form onSubmit={handleSubmit} noValidate autoComplete='off'>
                    <Form.Group>
                      <Form.Field width={12}>
                        <Form.Input
                          name="LawFirm" type="text"
                          label='Law Firm'
                          loading={this.props.fetch.fetching}
                          required={true}
                          placeholder='Law Firm Name'
                          error={(errors.LawFirm && touched.LawFirm) || (has(status, 'errors.LawFirm'))}
                          onChange={async (e) => {
                            await handleChange(e);
                            this.setState({ LawFirm: e.target.value })
                            //This function is to add the duplicate clients list
                            this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, this.props.clients.lawfirmsarray, { LawFirm: 'Name already used' })

                           // var { updatedListGeneralSorted, updatedListSpecific } = await filterCLNameInput(this.props.clients.clientsarray, [values.CFName, e.target.value])
                            // // console.log(`This is updatedListGeneralSorted: ${updatedListGeneralSorted}`)
                            // this.setState({ CEName2Regex: updatedListGeneralSorted })
                            if (this.state.LawFirm2RegexSpecific.length > 0) {
                              setStatus({ errors: { LawFirm: 'Name already used' } })
                            }
                            else {
                              setStatus({ errors: {} })
                            }

                          }}
                          onBlur={handleBlur}
                          value={values.LawFirm}
                        />

                        {this.errorTag(errors, touched, status, 'LawFirm')}

                      </Form.Field>

                    </Form.Group>



{/* 
                    <Segment secondary={this.state.LawFirm2RegexSpecific.length === 0} color={this.state.LawFirm2RegexSpecific.length > 0 ? 'red' : 'blue'}
                      hidden={this.state.LawFirm2Regex.length === 0}>
                      {ListMakerSimple(this.state.LawFirm2Regex, values, this.state.LawFirm2RegexSpecific, 'LawFirm')}
                    </Segment> */}



                    {/* <ReactTable className="-striped -highlight"
                      key={LawFirmTableObj.length}
                      data={LawFirmTableObj}
                      columns={LawFirmColumns}
                      // defaultPageSize={10}
                      defaultPageSize={this.state.rtablenone ? 3 : this.state.rtablefiltered ? Math.min(this.state.rtablesize, 10) : Math.min(LawFirmTableObj.length, 10)}
                      ref={this.reactTable}
                      noDataText={this.state.rtablenone || this.state.LawFirm.length > 0 ? `No such firm.  So go ahead and click to ${this.props.update ? 'edit' : 'add'}...` : "Loading..."}
                      defaultFilterMethod={filterCaseInsensitive}
                      // showPagination={true}
                      // filterable
                      onFilteredChange={() => {
                        this.setState({ rtablefiltered: true })
                        let newsize = records.getResolvedState().sortedData.length
                        newsize > 0 ? this.setState({ rtablesize: newsize, rtablenone: false }) : this.setState({ rtablesize: -1, rtablenone: true })
                      }}
                      getTdProps={(state, rowInfo, column, instance) => {

                        return {
                          onClick: (e, handleOriginal) => {
                            // console.log("A Td Element was clicked!");
                            // console.log("It was in this row:", rowInfo.row._original);
                            // console.log("It was in this column:", column);
                            // console.log("It was in this instance:", instance);
                            if (!this.state.rtablenone) console.log('We clicked on: ', rowInfo.row._original)

                            // IMPORTANT! React-Table uses onClick internally to trigger
                            // events like expanding SubComponents and pivots.
                            // By default a custom 'onClick' handler will override this functionality.
                            // If you want to fire the original onClick handler, call the
                            // 'handleOriginal' function.
                            if (handleOriginal) {
                              handleOriginal();
                            }
                          }
                        };
                      }}
                    /> */}







                    <Divider />
                    <Button.Group size='large'>
                      <Button type='button' icon='cancel'
                        onClick={() => {
                          handleReset();
                          this.setState({ LawFirm: '', LawFirm2Regex: [], LawFirm2RegexSpecific: '' })
                          // this.props.showAddLawFirmFormToggle(false)
                          // this.props.showCounterpartyInfoFormToggle(true)
                          this.props.closeCallback(false)
                        }}
                        color='red'
                        content='Cancel'></Button>
                      <Button.Or />
                      <Button color='teal'
                        onClick={() => { this.setState({ LawFirm: '', LawFirm2Regex: [], LawFirm2RegexSpecific: '' }); handleReset() }}
                        type='button'
                        disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Form'>
                      </Button>
                      <Button.Or />
                      <Button color='orange'
                        onClick={() => { setStatus({}) }}
                        type='button'
                        //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Error Notifications'>
                      </Button>
                      <Button.Or />
                      <Button type='submit'
                        // disabled={isSubmitting}
                        //Eventually use the below, the one above is just for testing
                        disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                        icon='upload'
                        loading={isSubmitting}
                        floated='right'
                        color={dirty ? 'green' : 'grey'}
                        content={(this.props.update === true ? 'Edit' : 'Add') + ' Law Firm'}></Button>
                    </Button.Group>
                    {/* <DisplayState state={this.state} />
                    <DisplayFormikState /> */}
                  </Form>
                </Segment>
              </div>
            )}
        </Formik>

        <p />

        <ReactTableMaker passedData={LawFirmTableObj}
          passedColumns={LawFirmsColumns()}
          handleClick={(rowID, cellID, rowValues) => {
            // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
            // this.setState({ selectedTType: rowID })
            // this.getPaymentsForInvoice(rowID) 
          }}
          includeSearchBar={false}
        // includePaginationFooter={false}
        />

      </div>
    )
  }
}


const AddLawFirmSchema = Yup.object().shape({
  LawFirm: Yup
    .string()
    .min(3, 'Law firm name must be at least 3 characters.')
    .max(50, 'Law firm name cannot exceed 50 characters.')
    .required('Must have a law firm name.'),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddLawFirms))



AddLawFirms.defaultProps = {
  update: false,
  fromAddCPInfo: false,
  // showAddLawFirmFormToggle: () => { },
  // showCounterpartyInfoFormToggle: () => { },
  closeCallback: () => { },
}


let LawFirmsColumns = () => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Law Firm ID</span>,
      accessor: 'law_firm_id',
      show: true,
      width: 0,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },
    {
      Header: ({ Header }) => <span style={{ 'float': 'left' }}>Existing Law Firms</span>,
      accessor: 'law_firm',
      show: true,
      sortType: 'alphanumeric',
      Filter: '',
    },

  ]
}