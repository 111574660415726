import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import { szenokPostAPI } from '../helper/szenokPostAPI'
import { isEmptyNullUndefined } from '../helper/Misc'
import SzenokTinyLogo from '../layout/SzenokTinyLogo'

import { Dropdown, Header } from 'semantic-ui-react';


let YearDropDownArray = (maxYears = 5, currentYear = new Date().getFullYear(), includeAll = false) => {
  let yearsArray = []
  for (let i = 0; i < maxYears; i++) {
    let obj = {}
    let year = currentYear - i
    obj.key = `YDA${i}`
    obj.text = `Fiscal Year ${year}`
    obj.value = year
    yearsArray.push(obj)
  }
  if (includeAll) {
    let obj = {}
    obj.key = `YDAx`
    obj.text = `All Years`
    obj.value = 0
    yearsArray.unshift(obj)
  }
  return yearsArray
}

let EmployeeDropDownArray = (employeesarray = [], includeAll = false) => {
  let partnersArray = []
  for (let i = 0; i < employeesarray.length; i++) {
    let obj = {}
    obj.key = `PDA${i}`
    obj.text = `${employeesarray[i][1] + ' ' + employeesarray[i][2]}`
    obj.value = i + 1
    obj.image = employeesarray[i][4]
    partnersArray.push(obj)
  }
  if (includeAll) {
    let obj = {}
    obj.key = `PDAx`
    obj.text = `All Partners`
    obj.value = 0
    obj.image = <svg width={33} height={25} viewBox='5 0 55 40 '><SzenokTinyLogo /></svg>
    partnersArray.unshift(obj)
  }
  return partnersArray
}

let YearDropDown = ({ year = 0, maxYears = 5, includeAll = false, includeHeader = true, currentYear = new Date().getFullYear(), callback = () => { } }) => {
  // const [selectedYear, setSelectedYear] = useState(year)
  const [yearDropDownArray, setYearDropDownArray] = useState([])

  useEffect(() => {
    setYearDropDownArray(YearDropDownArray(maxYears, currentYear, includeAll))
  }, [maxYears, currentYear, includeAll])

  return (
    <React.Fragment>
      {includeHeader && <Header as='h4'>Pick a year:</Header>}
      <Dropdown
        placeholder='Select Year'
        name="Year"
        width={6}
        required
        search
        label='Year'
        closeOnBlur
        selection={true}
        value={year}
        options={yearDropDownArray || []}
        onChange={async (e, { name, value }) => {
          // setSelectedYear(value)
          callback(value)
        }}
      />

    </React.Fragment>
  )
}

let EmployeeDropDown = ({ selectedPartner = 0, includeAll = false, includeHeader = true, callback = () => { } }) => {
  const { clients: { employeesarray } } = useSelector(state => state)
  const [selectedEmployee, setSelectedEmployee] = useState(selectedPartner)
  const [employeeDropDownArray, setEmployeeDropDownArray] = useState([])
  useEffect(() => {
    setEmployeeDropDownArray(EmployeeDropDownArray(employeesarray, includeAll))
  }, [employeesarray, selectedEmployee, includeAll])

  return (
    <React.Fragment>
      {includeHeader && <Header as='h4'>Pick a partner:</Header>}
      <Dropdown
        placeholder='Select Partner'
        name="Partner"
        width={6}
        required
        search
        label='Partner'
        closeOnBlur
        selection={true}
        value={selectedPartner}
        options={employeeDropDownArray || []}
        onChange={async (e, { name, value }) => {
          setSelectedEmployee(value)
          callback(value)
        }}
      />

    </React.Fragment>
  )
}

let AClassTTypesGetInvoices = async (type = '', maxYears = 5, token = '', callback = () => { }) => {
  let fetchresponse = await szenokPostAPI('getCharts', { fetchstr: type, years: maxYears },
    token)
  // console.log('Now printing fetchresponse for getInvoices(fetchresponse): ', fetchresponse)
  if (!isEmptyNullUndefined(fetchresponse.payload)) callback(fetchresponse.payload)
  else callback({})
}

let createObjectArray = (array) => {
  let arr = []
  // console.log('This is array: ', array)
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < array[i][1].length; j++) {
      let obj = {}
      // console.log('This is array[i][0]: ', array[i][0])
      obj.AClass = array[i][0]
      obj.TType = array[i][1][j]
      arr.push(obj)
    }
  }
  return arr
}

let AClassTTypesGetMatters = async (maxYears = 5, token = '', callback = () => { }) => {
  let fetchresponse = await szenokPostAPI('getCharts', { fetchstr: 'matters', years: maxYears },
    token)
  // console.log('Now printing fetchresponse for getInvoices(fetchresponse): ', fetchresponse)
  if (!isEmptyNullUndefined(fetchresponse.payload)) {
    let Matters = fetchresponse.payload
    let AClasses = Array.from(new Set(Matters.map(item => item.asset_class))).sort()
    let TTypes = Array.from(new Set(Matters.map(item => item.ttype))).sort()
    let AClassTTypeArray = createObjectArray(AClasses.map(item => {
      let types = TTypes.map(item => item)
      let arr = []
      arr[0] = item
      arr[1] = types
      return arr
    }))
    let obj = { Matters: Matters, AClasses: AClasses, TTypes: TTypes, AClassTTypeArray: AClassTTypeArray }
    callback(obj)
  }
  else callback({})
}

let LineIndividualGetBilling = async (maxYears = 5, token = '', callback = () => { }) => {
  let fetchresponse = await szenokPostAPI('getCharts', { fetchstr: 'partnerbilling', years: maxYears },
    token)
  // console.log('Now printing fetchresponse for LineIndividualGetBilling: ', fetchresponse)
  if (!isEmptyNullUndefined(fetchresponse.payload)) callback(fetchresponse.payload)
  else callback({})
}

let MajorClientsGet = async (maxYears = 5, token = '', callback = () => { }) => {
  let fetchresponse = await szenokPostAPI('getCharts', { fetchstr: 'majorclients', years: maxYears, invoicingOrOriginating: 'i.invoicing_employee_id' },
    token)
  let fetchresponse2 = await szenokPostAPI('getCharts', { fetchstr: 'majorclients', years: maxYears, invoicingOrOriginating: 'e.employee_id' },
    token)
  console.log('Now printing fetchresponse for PieGetPartnerGroupBilling: ', fetchresponse)
  if (!isEmptyNullUndefined(fetchresponse.payload) && !isEmptyNullUndefined(fetchresponse2.payload)) callback([fetchresponse.payload, fetchresponse2.payload])
  else callback({})
}

let PieGetPartnerGroupBilling = async (maxYears = 5, token = '', callback = () => { }) => {
  let fetchresponse = await szenokPostAPI('getCharts', { fetchstr: 'partnerGroupBilling', years: maxYears, invoicingOrOriginating: 'i.invoicing_employee_id' },
    token)
  let fetchresponse2 = await szenokPostAPI('getCharts', { fetchstr: 'partnerGroupBilling', years: maxYears, invoicingOrOriginating: 'e.employee_id' },
    token)
  // console.log('Now printing fetchresponse for PieGetPartnerGroupBilling: ', fetchresponse)
  if (!isEmptyNullUndefined(fetchresponse.payload) && !isEmptyNullUndefined(fetchresponse2.payload)) callback([fetchresponse.payload, fetchresponse2.payload])
  else callback({})
}

let StatesGetAllStates = async (maxYears = 5, token = '', callback = () => { }) => {
  let fetchresponse = await szenokPostAPI('getStates', { fetchstr: 'getAllStates', years: maxYears },
    token)
  // console.log('Now printing fetchresponse for PieGetPartnerGroupBilling: ', fetchresponse)
  if (!isEmptyNullUndefined(fetchresponse.payload)) callback(fetchresponse.payload)
  else callback({})
}

export { YearDropDown, EmployeeDropDown, AClassTTypesGetInvoices, AClassTTypesGetMatters, LineIndividualGetBilling, MajorClientsGet, PieGetPartnerGroupBilling, StatesGetAllStates }