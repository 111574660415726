import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';
import ReactTableMaker from '../helper/ReactTableMaker'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Button, Segment } from 'semantic-ui-react';

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

const initialState = {
  Payments: null,
  PIDs: [],
}

class DeletePayment extends Component {

  state = {
    hasError: false, ...initialState
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    if (!isEmptyNullUndefined(this.props.invoiceID) || !isEmptyNullUndefined(this.props.matterID) || !isEmptyNullUndefined(this.props.clientID)) {
      this.getPayments()
    }
  }


  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.matterID !== this.props.matterID || prevProps.clientID !== this.props.clientID) {
      this.props.fetchResetState('all')
      this.getPayments()
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  getPayments = async () => {

    this.props.fetchResetState('all')
    this.setState({ ...this.state, ...initialState })
    // console.log('In DeletePayment and fetching payments for this matter/client: ', this.props.matterID)
    let fetchresponse = {}
    if (!isEmptyNullUndefined(this.props.clientID)) {
      fetchresponse = await szenokPostAPI('getInvoicePayments', { fetchstr: 'getPaymentsForClient', clientID: this.props.clientID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.matterID)) {
      fetchresponse = await szenokPostAPI('getInvoicePayments', { fetchstr: 'getPaymentsForMatter', matterID: this.props.matterID },
        this.props.login.currentToken)
    }
    else if (!isEmptyNullUndefined(this.props.invoiceID)) {
      fetchresponse = await szenokPostAPI('getInvoicePayments', { fetchstr: 'getPaymentsForInvoice', invoiceID: this.props.invoiceID },
        this.props.login.currentToken)
    }
    // console.log('Now printing fetchresponse for getPayments(): ', fetchresponse)
    if (!isEmptyNullUndefined(fetchresponse.payload) && fetchresponse.payload.length > 0) {
      this.setState({
        Payments: fetchresponse.payload,
        PIDs: Array.from(new Set(fetchresponse.payload.map(payment => payment.payment_id))),
      })
    }
    else {
      // console.log('GOT TO HERE!!!!###$#')
      this.props.handleDeletePayment()
      // console.log('CLICKED ON IT!!!')
    }
  }


  handleDeletePayment = async () => {

    try {

      //Clear fetcherrors, info and such
      this.props.fetchResetState('all')

      let fetchresponse = {}
      fetchresponse = await szenokPostAPI('deletePayment', {
        paymentID: this.state.PIDs.join()
      }, this.props.login.currentToken)
      // console.log('Now printing fetchresponse for deletePayment: ', fetchresponse)

      //Check if everything has been successfull and then put in success notification
      if (fetchresponse.express) {

        //Update the matters store
        // reduxupdate('matters')

        //Rest of stuff
        this.setState(prevState => ({ visible: !prevState.visible }))
        // this.getPaymentsForInvoice()
        // this.props.getInvoicesOutstanding()
        reduxupdate('payments')
        this.props.handleDeletePayment()
      }
    }
    catch (error) {
      console.log('This is the errors: ', error)
      this.props.fetchErrors(error.toString())
    }
  }



  render() {
    if (this.state.hasError) return <h1>Something went wrong in Component-DeletePayment</h1>
    else
      return (
        <div>

          {/* This will show a list of all of the counterparty-matter connections */}
          {!isEmptyNullUndefined(this.state.Payments) &&

            <Segment color='blue' attached='top'>
              <h4>To Proceed, Must First Delete All Payments Made on This {!isEmptyNullUndefined(this.props.clientID) ? 'Client' : !isEmptyNullUndefined(this.props.matterID) ? 'Matter' : 'Invoice'}</h4>
              <h6 style={{ color: 'red' }}>(This is permanent, so be certain before clicking the 'Delete' button!)</h6>

              <Button primary onClick={() => this.handleDeletePayment()}>Delete {this.state.Payments.length > 1 ? 'All Payments' : 'This Payment'}</Button>
              <Button color='red' onClick={() => this.props.handleCancelDelete()}>Cancel</Button>

              <ReactTableMaker passedData={this.state.Payments}
                // passedColumns={PrecedentsColumns()}
                passedColumns={DeleteColumns('deletepayments', this.props.history)}
                handleClick={(rowID, cellID, rowValues) => {
                  // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
                }}
                includeSearchBar={false}
                includePaginationFooter={false}
                color='red'
              />

              {/* {this.state.Payments.map((item, i) => {
                return <PaymentsListMaker
                  {...item}
                  number={i}
                  matterID={this.props.matterID}
                  clientID={this.props.clientID}
                  matter={!isEmptyNullUndefined(this.props.matterID) ? this.props.clients.mattersarray.find(matter => matter[0] === this.props.matterID)[1] : null}
                  invoice={item.invoice_number}
                  // handleDeleteTycoon={this.state.handleDeleteTycoon}
                  key={`PLM${i}`}
                />
              })
              } */}
            </Segment>}

          {/* <DisplayState state={this.state} /> */}

        </div>
      )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeletePayment))


DeletePayment.defaultProps = {
  Counterparties: [],
  invoiceID: null,
  matterID: null,
  clientID: null,
  handleDeletePayment: () => { },
  handleCancelDelete: () => { },
}

// const PaymentsListMaker = (props) => {
//   // console.log('This is the props in PaymentListMaker: ', props)
//   if (!isEmptyNullUndefined(props.payment_id))
//     return (

//       <Segment secondary>

//         <h6><b>Payment Number: </b>{props.payment_number}</h6>
//         <h6><b>For Invoice Number: </b>{props.invoice_number}</h6>
//         <h6><b>Payment Amount: </b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.payment_amount)}</h6>
//         <h6><b>Payment Date: </b>{props.payment_date}</h6>
//         <h6><b>Payment Description: </b>{props.payment_description}</h6>
//         {!isEmptyNullUndefined(props.matter_id) && <h6><b>(For Matter Number): </b>{props.matter_id}</h6>}
//         {!isEmptyNullUndefined(props.matter_name) && <h6><b>(For Matter): </b>{props.matter_name}</h6>}
//         {!isEmptyNullUndefined(props.client_name) && <h6><b>(For Client): </b>{props.client_name}</h6>}


//       </Segment>
//     )
//   else return null
// }

// function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
// function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

const DeleteColumns = (type, history) => {
  let results = []

  switch (type) {
    case 'deletepayments':
      results = [
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Payment Number</span>,
          accessor: 'payment_number',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Payment Amount</span>,
          accessor: 'payment_amount',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell.value)}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Payment Date</span>,
          accessor: 'payment_date',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
        {
          Header: ({ Header }) => <span style={{ 'float': 'left' }}>Payment Description</span>,
          accessor: 'payment_description',
          Filter: '',
          sortType: 'alphanumeric',
          Cell: ({ cell }) => <span style={{ 'float': 'left' }}>{cell.value}</span>
        },
      ]
      break;
    default:
    // code block
  }
  return results
}