import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';

let toIMGcanvas = (id, width = 1) => {
  // console.log('Height is: ', document.getElementById(id).offsetHeight)
  // console.log('Width is: ', document.getElementById(id).offsetWidth * this.state.maxViewPercentage/100)

  html2canvas(document.getElementById(id), {
    // scale: 5,
    width: document.getElementById(id).offsetWidth * width,
    height: document.getElementById(id).offsetHeight
    // windowWidth: document.getElementById(id).scrollWidth,
    // windowHeight: document.getElementById(id).scrollHeight
  }).then(function (canvas) {
    var a = document.createElement('a');
    a.href = canvas.toDataURL("image/png");
    a.download = 'myfile.png';
    a.click();
  })
}

let toPDFcanvas = (id, width = 1) => {

  html2canvas(document.getElementById(id), {
    // scale: .95,
    width: document.getElementById(id).offsetWidth * width,
    height: document.getElementById(id).offsetHeight
    // windowWidth: document.getElementById(id).scrollWidth,
    // windowHeight: document.getElementById(id).scrollHeight
  }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF({
      orientation: 'landscape',
      // unit: 'in',
      // format: [4, 2]
    });

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('download.pdf')

    // pdf.addImage(imgData, 'PNG', 0, 0);
    // pdf.save("download.pdf")
  })
}

export {toIMGcanvas, toPDFcanvas}