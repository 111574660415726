import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
import { reduxupdate } from '../helper/ReduxHelp'
import { isEmptyNullUndefined } from '../helper/Misc'
import NumberFormat from 'react-number-format'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Label, Divider, Button } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import { has, get } from 'lodash';

class AddBill extends Component {

  state = {
    initialValues: {},
  }

  async componentDidMount() {
    this.props.fetchResetState('all')
    if (this.props.update) {
      await this.getSpecificBill()
      // this.setState({ SelectedBill: this.state.initialValues.client_id, SelectedMatter: this.state.initialValues.matter_id, SelectedEmployeeID: this.state.initialValues.invoicing_employee_id })
    }
  }

  getSpecificBill = async () => {
    // console.log('Running getSpecificInvoice....')
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('billInfo', { fetchstr: 'getBill', billID: this.props.billID },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getBill: ', fetchresponse)
    //Set ExistingMatters to the other matters for the loaded client
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      initialValues: fetchresponse.payload
    })
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  render() {

    const initialValues = isEmptyNullUndefined(this.state.initialValues) ? {
      BillType: this.props.billType,
      BillAmount: null,
      DealAmount: this.props.matter_deal_amount,
      Description: null,
    } : {
        BillType: this.state.initialValues.bill_type,
        BillAmount: this.state.initialValues.bill_amount,
        DealAmount: this.state.initialValues.bill_deal_amount,
        Description: this.state.initialValues.bill_description,
      }

    const initialStatus = {
      errors: {},
    }

    return (
      <div>
        <Formik

          enableReinitialize={true}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={AddBillSchema}

          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out these values: ', values)
              let fetchresponse = {}
              if (this.props.update) fetchresponse = await szenokPostAPI('editBill', {
                BillID: this.state.initialValues.bill_id,
                BillType: values.BillType,
                BillAmount: values.BillAmount,
                CMID: this.state.initialValues.counterparty_matter_id,
                DealAmount: values.DealAmount,
                Description: values.Description
              }, this.props.login.currentToken)
              else fetchresponse = await szenokPostAPI('addBill', {
                CMID: this.props.CMID,
                BillType: values.BillType,
                BillAmount: values.BillAmount,
                DealAmount: values.DealAmount,
                Description: values.Description
              }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for addBill: ', fetchresponse)
              // this.setState({ deleteerrormessage: [...this.state.deleteerrormessage, JSON.stringify(fetchresponse)] })

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                //Update the Existing Invoices
                // this.getInvoices()

                //Rest of stuff
                // this.setState(prevState => ({ visible: !prevState.visible }))
                // actions.resetForm({ ...initialValues })
                // this.setState(initialState)
                this.props.clearBill()
                reduxupdate('bills')
                this.props.getBills()
                if (this.props.update) {
                  // await this.getSpecificInvoice()
                  // await this.getInvoices(this.state.initialValues.client_id)
                  // this.setState({ SelectedMatter: this.state.initialValues.matter_id })
                }
                // this.props.update && this.props.history.goBack()

              }
            }
            catch (error) { this.props.fetchErrors(error.toString()) }
            actions.setSubmitting(false)
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (


              <Form
                loading={isSubmitting}
                onSubmit={handleSubmit} noValidate autoComplete='off'>

                <React.Fragment>


                  {/* Bill Type */}
                  <Form.Field width={8}>
                    <Form.Input
                      name="BillType" type="text"
                      label='Bill Type (Legal, Local Counsel, Brokerage, etc.)'
                      required={true}
                      placeholder='Asset Class Description'
                      error={(errors.BillType && touched.BillType) || (has(status, 'errors.BillType'))}
                      onChange={async (e) => {
                        await handleChange(e);
                        this.setState({ BillType: e.target.value })
                        //This function is to add the duplicate clients list
                        // this.debouncedFilter(e.target.value, filterSimpleInput, setStatus, this.props.clients.assetclassarray, { AssetClass: 'Name already used' })
                      }}
                      onBlur={handleBlur}
                      value={!isEmptyNullUndefined(values.BillType) ? values.BillType : ''}
                    // value={values.BillType}
                    />

                    {this.errorTag(errors, touched, status, 'BillType')}

                  </Form.Field>

                  {/* Amount */}
                  <Form.Field required width={6} error={has(status, 'errors.BillAmount')}>
                    <label>Insert Amount of Bill (in $)</label>

                    <NumberFormat
                      name="BillAmount"
                      label='Bill Amount'
                      thousandSeparator={true}
                      prefix={'$'}
                      onBlur={() => setFieldTouched('BillAmount', true)}
                      placeholder='Amount billed...'
                      value={values.BillAmount}
                      // loading={isSubmitting}
                      onValueChange={(e) => {
                        // console.log('This is the value: ', e.floatValue)
                        setFieldValue('BillAmount', isEmptyNullUndefined(e.floatValue) ? null : e.floatValue)
                      }} />
                    {this.errorTag(errors, touched, status, 'BillAmount')}
                  </Form.Field>

                  {/* DealAmount */}
                  <Form.Field width={8} error={has(status, 'errors.DealAmount')}>
                    <label>Insert Amount of Deal (in $)<br />
                      <span style={{ fontWeight: 'lighter' }}>(Default is amount of deal, but the bill may be for just a portion (e.g., a law firm representing just the mortgage lender in a deal that also includes mezz).)</span></label>

                    <NumberFormat
                      name="DealAmount"
                      label='Bill Amount'
                      thousandSeparator={true}
                      prefix={'$'}
                      onBlur={() => setFieldTouched('DealAmount', true)}
                      placeholder='Amount billed...'
                      value={values.DealAmount}
                      // loading={isSubmitting}
                      onValueChange={(e) => {
                        // console.log('This is the value: ', e.floatValue)
                        setFieldValue('DealAmount', isEmptyNullUndefined(e.floatValue) ? null : e.floatValue)
                      }} />
                    {this.errorTag(errors, touched, status, 'DealAmount')}
                  </Form.Field>


                  {/* Add the description */}
                  <Form.Field width={14}>
                    <Form.TextArea
                      name="Description" type="text"
                      label={`Additional Detail for Bill (optional)`}
                      required={false}
                      placeholder={`Description for Invoice`}
                      error={(errors.Description && touched.Description) || (has(status, 'errors.Description'))}
                      onChange={async (e) => {
                        await handleChange(e);
                        this.setState({ Description: e.target.value })
                      }}
                      onBlur={handleBlur}
                      value={!isEmptyNullUndefined(values.Description) ? values.Description : ''} />
                    {this.errorTag(errors, touched, status, 'Description')}
                  </Form.Field>


                </React.Fragment>

                <Divider />
                <Button.Group size='large'>
                  <Button type='button' icon='cancel'
                    onClick={() => {
                      handleReset()
                      this.props.clearBill()
                      // let ClientsMatters = this.state.ClientsMatters
                      // this.setState(initialState)
                      // this.setState({ ClientsMatters: ClientsMatters })
                    }}
                    color='red'
                    content='Cancel'></Button>
                  <Button.Or />
                  <Button color='teal'
                    onClick={() => {
                      setFieldValue('BillAmount', null)
                      setFieldValue('BillType', '')
                      setFieldValue('DealAmount', null)
                      setFieldValue('Description', null)
                      setFieldTouched('BillType', false)
                      setFieldTouched('BillAmount', false)
                      setFieldTouched('DealAmount', false)
                      setFieldTouched('Description', false)
                      // setFieldTouched({})
                      // this.setState({ Description: null, Matter: '', Invoice2Regex: [], Invoice2RegexSpecific: '' })
                    }}
                    type='button'
                    disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                    content='Clear Form'>
                  </Button>
                  <Button.Or />
                  <Button color='orange'
                    onClick={() => { setStatus({}); setErrors({}) }}
                    type='button'
                    //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                    content='Clear Error Notifications'>
                  </Button>
                  <Button.Or />
                  <Button type='submit'
                    // disabled={isSubmitting}
                    //Eventually use the below, the one above is just for testing
                    disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                    icon='upload'
                    loading={isSubmitting}
                    floated='right'
                    color={dirty ? 'green' : 'grey'}
                    content={(this.props.update === true ? 'Edit' : 'Add') + ' Bill'}></Button>
                </Button.Group>
                {/* <DisplayState state={this.state} /> */}
                {/* <DisplayFormikState /> */}
              </Form>



            )}
        </Formik>
      </div>
    )
  }
}

const AddBillSchema = Yup.object().shape({
  BillAmount: Yup
    .number()
    .nullable()
    .min(0)
    .max(10000000000)
    .required(),
  DealAmount: Yup
    .number()
    .nullable()
    .min(0)
    .max(10000000000)
    .optional(),
  Description: Yup
    .string()
    .min(3, 'Description must be at least 3 characters.')
    .max(10000, 'Description cannot exceed 10,000 characters.')
    .optional()
    .nullable(),
  BillType: Yup
    .string()
    .min(3, 'Bill type must be at least 3 characters.')
    .max(50, 'Bill type name cannot exceed 50 characters.')
    .required('Must have a bill type.'),
})


const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(AddBill)

AddBill.defaultProps = {
  CMID: null,
  matter_deal_amount: () => { },
  billType: 'Legal'
  // getLawyers: () => { },
  // getTycoons: () => { },
  // getNotes: () => { },
  // headingForForm: '',
}
