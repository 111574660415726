import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { reduxupdate } from '../helper/ReduxHelp';

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import * as matterInfoActions from '../../actions/matterInfoActions'
import { connect } from 'react-redux';

import { Button, Segment, Table, Icon, List } from 'semantic-ui-react';

// import AddCounterparty from './Component-AddCounterparty';
import CounterpartyInfo from './Component-CounterpartyInfo';
import AddLawFirms from './Component-AddLawFirm';
import AddPerson from './Component-AddPerson';
import DeleteNotes from './Component-DeleteNotes'
import BillsLister from './Component-MatterInfo-Bills'

import ConfirmModal from '../helper/ConfirmModal'
import BillModal from '../helper/BillModal'
import ModalAddCP from '../helper/ModalAddCounterparty'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

import { isEmptyNullUndefined } from '../helper/Misc';

let initialDeleteMatterCParty = {
  LNotesExist: true,
  TNotesExist: true,
}

class MattersInfoCparties extends Component {

  state = {
    DeleteMatterCParty: initialDeleteMatterCParty,
    selectedMatterCParty: null,
  }

  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
      // console.log('this is matterInfoActions: ', this.props)
    }
    // this.props.resetCPInfo(true)
    this.props.resetCPInfo(true)
  }

  componentDidUpdate() {
    //If we did not have a token initially, see if it's come in and then run fetch.
    //This on
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true, FilteredSearchData: this.state.Data })
    }
  }

  setStateMatterCParty = (cparty_id, callback) => {
    this.setState({ DeleteMatterCParty: initialDeleteMatterCParty })
    this.setState({ selectedMatterCParty: cparty_id }, () => { if (callback) callback() })
    // if (callback) callback()
  }

  DeleteMatterCParty = async () => {
    // console.log('Running DeleteMatterCParty on this CParty: ', this.state.selectedMatterCParty)
    //Clear fetcherrors, info and such
    this.props.fetchResetState('all')

    if (!this.predicatesExist() && !isEmptyNullUndefined(this.state.selectedMatterCParty)) {

      try {
        let fetchresponse = {}


        if (!isEmptyNullUndefined(this.state.selectedMatterCParty)) fetchresponse = await szenokPostAPI('deleteCounterpartyMatter', {
          counterpartyMatterID: this.state.selectedMatterCParty
        }, this.props.login.currentToken)

        // console.log('Now printing fetchresponse for deleteCounterpartyMatter: ', fetchresponse)

        //Check if everything has been successfull and then put in success notification
        if (fetchresponse.express) {

          //Update the matters store
          reduxupdate('matters')
          reduxupdate('bills')

          //Rest of stuff
          this.setState(prevState => ({ visible: !prevState.visible }))
          // console.log('Refetching getMatterInfo()...')
          this.props.getMatterInfo()
        }


        // // Delete the counterpartymatter
        // fetchresponse = await szenokPostAPI('deleteCounterpartyMatter', {
        //   counterpartyMatterID: IDs.counterparty_matter_id
        // }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteCounterpartyMatter: ', fetchresponse)
        // this.props.getMatterInfo()
      }
      catch (error) {
        console.log('This is the errors: ', error)
        //FIX:  I don't know if i need this always, but if I don't do this push stuff, sometimes the entire
        //string will come out as an array of characters, instead.  Need to figure out a way to check
        //if I have a proper string or not.
        let errorObj = []
        errorObj.push(error.toString())
        this.props.fetchErrors(errorObj)
      }
    }
  }

  handleDeleteLNote = () => {
    this.setState({ DeleteMatterCParty: { ...this.state.DeleteMatterCParty, LNotesExist: false } })
    this.DeleteMatterCParty()
  }

  handleDeleteTNote = () => {
    this.setState({ DeleteMatterCParty: { ...this.state.DeleteMatterCParty, TNotesExist: false } })
    this.DeleteMatterCParty()
  }

  handleCancelDelete = () => {
    this.setState({ DeleteMatterCParty: initialDeleteMatterCParty })
    this.setState({ selectedMatterCParty: null })
  }

  predicatesExist = (type) => {
    let notesExist = this.state.DeleteMatterCParty.LNotesExist || this.state.DeleteMatterCParty.TNotesExist
    let predicatesExist = notesExist
    // console.log('Returning from predicatesExist: ', predicatesExist.toString())

    switch (type) {
      case 'notes':
        return notesExist
      case 'precedentsPredicates':
        return notesExist
      default:
        return predicatesExist
    }
  }

  render() {

    //I ended up not using this because it doesn't lodge the current page into history and so back doesn't go back correctly.
    // if(this.state.selectedClient !== null) {
    //   // If we click on a row, then React-Router redirects to the /client route
    //   // The param will be the client ID and the query string will be the 'Client Name' from the Data state that holds that ID number.
    //   // return (<Redirect to={`/client/${this.state.selectedClient}?matter=${this.state.Data.find(o => o.ID === this.state.selectedClient)['Client Name']}`} />)
    // }

    return (


      <Segment color='green'>

        <h2 id='CPartyInfo' ><Icon name='address book outline' size='large' />Counterparty Information:</h2>

        {this.predicatesExist('notes') && !isEmptyNullUndefined(this.state.selectedMatterCParty) &&
          <DeleteNotes matterCPartyID={this.state.selectedMatterCParty}
            handleDeleteLNote={this.handleDeleteLNote} handleDeleteTNote={this.handleDeleteTNote}
            handleCancelDelete={this.handleCancelDelete}
          />
          // getCounterparties={this.getCounterparties}
          // handleDeleteCounterparty={this.handleDeleteCounterparty} handleCancelDelete={this.handleCancelDelete} />
        }

        {this.props.matterInfo.addCPInfoObject.showAddCPInfoButton ?
          <React.Fragment>
            <br />
            <Button color='green'
              onClick={() => {
                this.props.setAddCPInfo(true)
              }}
            >
              Add Counterparty Information</Button>
          </React.Fragment> : null
        }

        {this.props.matterInfo.addCPInfoObject.showAddCP ?
          <ModalAddCP type={'counterparty'} />
          : null}
        {this.props.matterInfo.addCPInfoObject.showAddLawFirm ?
          <ModalAddCP type={'law firm'} />
          : null}
        {this.props.matterInfo.addCPInfoObject.showAddCPInfoModule ?
          <Segment>
            <CounterpartyInfo getMatterInfo={this.props.getMatterInfo} />
          </Segment>
          : null}

        {this.props.showAddLawFirmForm ?
          <Segment>
            <AddLawFirms />
          </Segment>
          : null}



        <Table basic='very' celled striped >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Counterparty</Table.HeaderCell>
              <Table.HeaderCell>Businessmen</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell>Law Firm Representing</Table.HeaderCell>
              <Table.HeaderCell>Lawyers</Table.HeaderCell>
              <Table.HeaderCell>Bill</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body >
            {this.props.Counterparties.length === 0 ? null :
              this.props.Counterparties.map((item, i) => {
                return <ConnectedCounterpartyInfoMaker rowdata={item}
                  lawfirm={this.props.clients.lawfirmsarray.find(o => o[0] === item.law_firm_id)[1]}
                  LawFirmID={item.law_firm_id}
                  lawyers={this.props.Lawyers.filter(o => o.law_firm_id === item.law_firm_id)}
                  tycoons={this.props.Tycoons.filter(o => o.counterparty_matter_id === item.counterparty_matter_id)}
                  lawyernotes={this.props.LawyerNotes.filter(o => o.counterparty_matter_id === item.counterparty_matter_id)}
                  tycoonnotes={this.props.TycoonNotes.filter(o => o.counterparty_matter_id === item.counterparty_matter_id)}
                  Bills={this.props.Bills.filter(o => o.counterparty_matter_id === item.counterparty_matter_id)}
                  MatterInfo={this.props.MatterInfo}
                  number={i}
                  showAddLawyerForm={this.props.showAddLawyerForm}
                  showAddTycoonForm={this.props.showAddTycoonForm}
                  showAddNoteForm={this.props.showAddNoteForm}
                  selectCParty={this.props.selectCParty}
                  selectedCParty={this.props.selectedCParty}
                  selectedCPartyMatterID={this.props.selectedCPartyMatterID}
                  getLawyers={this.props.getLawyers}
                  getTycoons={this.props.getTycoons}
                  getMatterInfo={this.props.getMatterInfo}
                  getNotes={this.props.getNotes}
                  key={i}
                  handleDeleteTycoon={this.props.handleDeleteTycoon}
                  handleDeleteLawyer={this.props.handleDeleteLawyer}
                  history={this.props.history}
                  login={this.props.login}
                  setStateMatterCParty={this.setStateMatterCParty}
                  currentObserver={!!this.props.login.currentObserver}
                  getBills={this.props.getBills}
                />
              })}

          </Table.Body>
        </Table>

        {isEmptyNullUndefined(this.props.Counterparties) ? <h4>No counterparties to show yet...</h4> : null}

        {/* <DisplayState state={this.state} /> */}
      </Segment>



    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions, matterInfoActions), dispatch) }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MattersInfoCparties))


class CounterpartyInfoMaker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCPartyForm: false,
      modalOpen: false,
      billModalOpen: false,
      row: {}
    };
  }

  // showCPartyFormToggle = (option) => {
  //   // console.log('Clicking showCPartyFormToggle and this option: ', option)
  //   switch (option) {
  //     case false:
  //       this.setState({ showCPartyForm: false })
  //       break
  //     case true:
  //       this.setState({ showCPartyForm: true })
  //       break
  //     default:
  //       this.setState(prevState => ({ showCPartyForm: !prevState.showCPartyForm }))
  //   }
  // }

  gatherCPartyData = async () => {
    let IDs = {}
    // let fetchresponse = {}

    IDs.tycoon_person_ids = this.props.tycoons.map(tycoon => {
      let tycoonObj = {}
      tycoonObj.person_id = tycoon.person_id
      tycoonObj.counterparty_matter_id = tycoon.counterparty_matter_id
      return tycoonObj
    })

    IDs.lawyers_person_ids = this.props.lawyers.map(lawyer => {
      let lawyerObj = {}
      lawyerObj.person_id = lawyer.person_id
      lawyerObj.counterparty_matter_id = lawyer.counterparty_matter_id
      return lawyerObj
    })

    IDs.counterparty_matter_id = this.props.rowdata.counterparty_matter_id

    // console.log('This is IDs: ', IDs)

    //Delete all tycoons
    if (IDs.tycoon_person_ids.length > 0) {
      const tycoonPromises = IDs.tycoon_person_ids.map(async tycoon => {
        await szenokPostAPI('deleteTycoonCounterparty', {
          tycoon_id: tycoon.person_id, counterparty_matter_id: tycoon.counterparty_matter_id
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteTycoonCounterparty: ', fetchresponse)
      })
      Promise.all(tycoonPromises).then(arrayOfResponses => {
        // console.log('This is the arrayofresponses for tycoonPromises: ', arrayOfResponses)
        this.props.getTycoons()
      })
    }

    //Delete all lawyers
    if (IDs.lawyers_person_ids.length > 0) {
      const lawyerPromises = IDs.lawyers_person_ids.map(async lawyer => {
        await szenokPostAPI('deleteLawyerCounterparty', {
          lawyer_id: lawyer.person_id, counterparty_matter_id: lawyer.counterparty_matter_id
        }, this.props.login.currentToken)
        // console.log('Now printing fetchresponse for deleteLawyerCounterparty: ', fetchresponse)
      })
      Promise.all(lawyerPromises).then(arrayOfResponses => {
        // console.log('This is the arrayofresponses for lawyerPromises: ', arrayOfResponses)
        this.props.getLawyers()
      })
    }

    this.props.setStateMatterCParty(IDs.counterparty_matter_id)
    // // Delete the counterpartymatter
    // fetchresponse = await szenokPostAPI('deleteCounterpartyMatter', {
    //   counterpartyMatterID: IDs.counterparty_matter_id
    // }, this.props.login.currentToken)
    // console.log('Now printing fetchresponse for deleteCounterpartyMatter: ', fetchresponse)
    // this.props.getMatterInfo()

  }

  setModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ modalOpen: truefalse })
    else this.setState({ modalOpen: !this.state.modalOpen })
  }

  setBillModalOpen = (truefalse) => {
    if (!isEmptyNullUndefined(truefalse)) this.setState({ billModalOpen: truefalse })
    else this.setState({ billModalOpen: !this.state.billModalOpen })
  }

  setRow = (counterparty_matter_id, counterparty) => {
    // console.log('In setRow and this is value: ', value)
    if (!isEmptyNullUndefined(counterparty_matter_id)) this.setState({ Row: { counterparty_matter_id: counterparty_matter_id, counterparty: counterparty } })
    else this.setState({ Row: {} })
  }

  render() {
    // console.log('In CounterpartyInfoMaker and here are props: ', this.props)

    function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
    function plusFontColor(e) { e.target.style.color = "#ff8000" }
    function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }
    function minusFontColor(e) { e.target.style.color = "" }

    return (

      <React.Fragment>

        <Table.Row key={`CIM${this.props.rowdata.counterparty_id}`}>
          <Table.Cell width={3}>
            <Icon name='address book outline' size='large' />
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.history.push(`/counterparty/${this.props.rowdata.counterparty}?counterparty=${this.props.rowdata.counterparty}&counterpartyID=${this.props.rowdata.counterparty_id}`)}
            // onClick={() => console.log('Yo, this is the value: ', cell.value)}
            >
              {this.props.rowdata.counterparty}
            </span>
            <p />
            <Button disabled={!!this.props.login.currentObserver} compact size='mini' icon color='green' basic labelPosition='left'
              onClick={() => {
                this.props.selectCParty(this.props.rowdata.counterparty_id, this.props.rowdata.counterparty_matter_id)
                this.props.setAddBusinessMan(true)
                // console.log('This is the id: ', this.props.rowdata.counterparty_matter_id, 'and this is props.selectedCparty: ', this.props.selectedCParty)
              }}
            >
              <Icon name='plus' size='small' />
            Add a businssman
        </Button>
          </Table.Cell>
          <Table.Cell>
            <React.Fragment>
              {/* {this.state.showCPartyForm && this.props.rowdata.counterparty_matter_id === this.props.selectedCPartyMatterID && this.props.showAddTycoonForm && */}
              {this.props.rowdata.counterparty_matter_id === this.props.selectedCPartyMatterID && this.props.matterInfo.showAddBusinessMan &&

                <Segment >

                  <AddPerson
                    headingForForm={`Add a businessperson to ${this.props.rowdata.counterparty} (for this transaction)`}
                    typeOfPerson={`Businessperson`}
                    cleanadd={false}
                    showAddLawyerForm={this.props.showAddLawyerForm}
                    showAddTycoonForm={this.props.showAddTycoonForm}
                    selectedCPartyMatterID={this.props.selectedCPartyMatterID}
                    selectedCParty={this.props.selectedCParty}
                    LawFirmID={this.props.LawFirmID}
                    getLawyers={this.props.getLawyers}
                    getTycoons={this.props.getTycoons}
                    closeCallback={this.props.resetCPInfo}
                    fromAddCPInfo={true}
                  />

                </Segment>}

              {this.props.tycoons.map((item, i) => {
                return <LawyerListMaker lawyer={item.person_name}
                  number={i}
                  counterparty_matter_id={item.counterparty_matter_id}
                  person_id={item.person_id}
                  handleDeleteTycoon={this.props.handleDeleteTycoon}
                  lawyertype={false}
                  history={this.props.history}
                  plusFontColor={plusFontColor}
                  minusFontColor={minusFontColor}
                  plusFontWeight={plusFontWeight}
                  minusFontWeight={minusFontWeight}
                  // handleAClick={this.props.handleClick}
                  key={`LTM${i}`}
                  currentObserver={!!this.props.currentObserver}
                />
              })
              }

            </React.Fragment>
          </Table.Cell>
          <Table.Cell>{this.props.rowdata.role}</Table.Cell>
          <Table.Cell width={3}><Icon name='balance scale' size='large' />
            <span
              onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.history.push(`/lawfirm/${this.props.lawfirm}?lawfirm=${this.props.lawfirm}&lawFirmID=${this.props.rowdata.law_firm_id}`)}
            >
              {this.props.lawfirm}
            </span>
            <p />
            <Button disabled={!!this.props.login.currentObserver} compact size='mini' icon color='blue' basic labelPosition='left'
              onClick={() => {
                this.props.selectCParty(this.props.rowdata.counterparty_id, this.props.rowdata.counterparty_matter_id)
                this.props.setAddLawyer(true)
                // console.log('This is the id: ', this.props.rowdata.counterparty_matter_id, 'and this is props.selectedCparty: ', this.props.selectedCParty)
              }}
            >
              <Icon name='plus' size='small' />
            Add a lawyer
            </Button>

          </Table.Cell>
          <Table.Cell>
            <React.Fragment>
              {this.props.rowdata.counterparty_matter_id === this.props.selectedCPartyMatterID && this.props.matterInfo.showAddLawyer &&

                <Segment >
                  <AddPerson
                    headingForForm={`Add a lawyer to ${this.props.lawfirm} (for this transaction)`}
                    typeOfPerson={`Lawyer`}
                    cleanadd={false}
                    showAddLawyerForm={true}
                    showAddTycoonForm={this.props.showAddTycoonForm}
                    selectedCPartyMatterID={this.props.selectedCPartyMatterID}
                    selectedCParty={this.props.selectedCParty}
                    LawFirmID={this.props.LawFirmID}
                    getLawyers={this.props.getLawyers}
                    getTycoons={this.props.getTycoons}
                    closeCallback={this.props.resetCPInfo}
                    fromAddCPInfo={true}
                  />

                </Segment>}

              {this.props.lawyers.map((item, i) => {
                return <LawyerListMaker lawyer={item.person_name}
                  number={i}
                  counterparty_matter_id={item.counterparty_matter_id}
                  person_id={item.person_id}
                  handleDeleteLawyer={this.props.handleDeleteLawyer}
                  lawyertype={true}
                  // handleAClick={this.props.handleClick}
                  key={`LLM${i}`}
                  history={this.props.history}
                  plusFontColor={plusFontColor}
                  minusFontColor={minusFontColor}
                  plusFontWeight={plusFontWeight}
                  minusFontWeight={minusFontWeight}
                  currentObserver={!!this.props.currentObserver}
                />
              })
              }
              <LawyerListMaker />
            </React.Fragment>
          </Table.Cell>

          {/* Bill for opposing lawyers */}
          <Table.Cell>

            <BillsLister
              Bills={this.props.Bills}
              token={this.props.login.currentToken}
              getBills={this.props.getBills}
              lawfirm={this.props.lawfirm}
              lawfirm_id={this.props.lawfirm_id}
              counterparty_id={this.props.counterparty_id}
              counterparty={this.props.counterparty}
              counterparty_matter_id={this.props.rowdata.counterparty_matter_id}
            />


            <Button disabled={!!this.props.login.currentObserver} compact size='mini' icon color='purple' basic labelPosition='left'
              onClick={() => {
                // console.log('This is the id: ', this.props.rowdata.counterparty_matter_id, 'and this is props.selectedCparty: ', this.props.selectedCParty)
                // console.log('This is the law firm: ', this.props.lawfirm, 'and this is law firm id: ', this.props.LawFirmID)
                this.setBillModalOpen(true)
                this.props.setAddBill(true)
              }}
            >
              <Icon name='plus' size='small' />
            Add a bill
            </Button>

            {/* {!isEmptyNullUndefined(this.state.Row) && */}
            <BillModal
              closeModal={() => {
                this.setBillModalOpen(false)
                // this.setRow()
              }}
              confirmModal={() => {
                this.setBillModalOpen(false)

                // this.gatherCPartyData()

              }}
              isOpen={this.state.billModalOpen}
              // deleteSubject='Bill'
              lawfirm={this.props.lawfirm}
              lawfirm_id={this.props.LawFirmID}
              counterparty={this.props.rowdata.counterparty}
              counterparty_id={this.props.rowdata.counterparty_id}
              counterparty_matter_id={this.props.rowdata.counterparty_matter_id}
              matter_deal_amount={this.props.MatterInfo.matter_deal_amount}
              getBills={this.props.getBills}
              update={false}
              billType={this.props.rowdata.role.toLowerCase() === 'broker' ? 'Broker' : `Legal (${this.props.rowdata.role})`}
              clearBill={() => this.setBillModalOpen(false)}
            />
            {/* } */}

          </Table.Cell>

          <Table.Cell><Button
            color='red'
            size='small'
            disabled={!!this.props.login.currentObserver}
            basic
            icon='delete'
            onClick={async () => {
              // console.log(`Clicked on ${this.props.rowdata.counterparty_id}.`)
              // this.gatherCPartyData()
              this.setModalOpen(true)
              this.setRow(this.props.rowdata.counterparty_matter_id, this.props.rowdata.counterparty)
            }}
            content='Delete' />
          </Table.Cell>
        </Table.Row>

        {!isEmptyNullUndefined(this.state.Row) &&
          <ConfirmModal closeModal={() => {
            this.setModalOpen(false)
            this.setRow()
          }}
            confirmModal={() => {
              this.setModalOpen(false)

              this.gatherCPartyData()

            }}
            isOpen={this.state.modalOpen}
            deleteSubject='Counterparty'
            deleteObject={this.state.Row.counterparty + ' (for this matter)'}
          />}

      </React.Fragment>
    )
  }

}

let ConnectedCounterpartyInfoMaker = connect(mapStateToProps, mapDispatchToProps)(CounterpartyInfoMaker)

const LawyerListMaker = (props) => {
  // console.log('This is the props in LawyerListMaker: ', props)
  if (!isEmptyNullUndefined(props.person_id))
    return (

      <List>
        <Icon name='male' size='small' />
        <React.Fragment>
          <span
            onMouseOver={props.plusFontWeight} onMouseLeave={props.minusFontWeight}
            style={{ cursor: 'pointer' }}
            onClick={() => props.history.push(`/person/${props.lawyer}?person=${props.lawyer}&personID=${props.person_id}`)}
          >
            {props.lawyer}
          </span>

          {/* <br />And this is the personID: {props.person_id}
        <br />And this is the counterparty_matter_id: {props.counterparty_matter_id}
        <br />And this is the props.lawyer: {props.lawyer ? 'true' : 'false'} */}
          {!props.currentObserver && <Icon name='delete' color='red' size='tiny' bordered link onClick={() => {
            if (props.lawyertype) props.handleDeleteLawyer(props.person_id, props.counterparty_matter_id)
            else props.handleDeleteTycoon(props.person_id, props.counterparty_matter_id)
          }
          } />}
        </React.Fragment>
      </List>
    )
  else return null
}