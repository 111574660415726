export function clientListAction(clientslist) {
  //console.log('Here in clientListAction and this is clientslist: ', clientslist)
  return {
    type: "GET_CLIENTS",
    payload: clientslist
  }
}

export function clientArrayAction(clientslist) {
  //console.log('Here in clientArrayAction and this is clientslist: ', clientslist)
  return {
    type: "GET_CLIENTS_ARRAY",
    payload: clientslist
  }
}

export function employeeArrayAction(employeeslist) {
  // console.log('Here in clientArrayAction and this is employeeslist: ', employeeslist)
  return {
    type: "GET_EMPLOYEES_ARRAY",
    payload: employeeslist
  }
}

export function matterArrayAction(matterslist) {
  //console.log('Here in clientArrayAction and this is clientslist: ', clientslist)
  return {
    type: "GET_MATTERS_ARRAY",
    payload: matterslist
  }
}

export function transactionTypesArrayAction(transactiontypeslist) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_TRANSACTION_TYPES_ARRAY",
    payload: transactiontypeslist
  }
}

export function transactionTypesAndSubsArrayAction(transactiontypeslist) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_TRANSACTION_TYPES_AND_SUBS_ARRAY",
    payload: transactiontypeslist
  }
}

export function assetClassArrayAction(assetclasslist) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_ASSET_CLASS_ARRAY",
    payload: assetclasslist
  }
}

export function lawFirmsArrayAction(lawfirmslist) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_LAW_FIRMS_ARRAY",
    payload: lawfirmslist
  }
}

export function counterpartiesTotalAction(counterparties_total) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_COUNTERPARTIES_TOTAL",
    payload: counterparties_total
  }
}

export function personsTotalAction(persons_total) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_PERSONS_TOTAL",
    payload: persons_total
  }
}

export function invoicesTotalAction(invoices_total) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_INVOICE_TOTAL",
    payload: invoices_total
  }
}

export function paymentsTotalAction(payments_total) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_PAYMENTS_TOTAL",
    payload: payments_total
  }
}

export function precedentsTotalAction(precedents_total) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_PRECEDENTS_TOTAL",
    payload: precedents_total
  }
}

export function locationsTotalAction(locations_total) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_LOCATIONS_TOTAL",
    payload: locations_total
  }
}

export function billsTotalAction(bills_total) {
  //console.log('Here in transactionTypesArrayAction and this is transactiontypeslist: ', clientslist)
  return {
    type: "GET_BILLS_TOTAL",
    payload: bills_total
  }
}

export function fetchAllReduxAction(blob) {
  // console.log('Here in fetchAllReduxAction and this is blob: ', blob)
  return {
    type: "GET_ALL",
    payload: blob
  }
}