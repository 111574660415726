import React, { useEffect } from 'react'
import './Charts-Locations-AddLocation.css'
import { isEmptyNullUndefined } from "../../helper/Misc";
import { Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux';




const AddLocation = ({
  ClientsMatters = [],
  address = '',
  coordinates = [],
  clients = [],
  login = [],
  matterSelector = true,
  sendAddressToParent = () => { },
  sendCoordinatesToParent = () => { },
  sendSelectedMatterToParent = () => { },
  sendSelectedLocationToParent = () => { },
}) => {

  const [clientMatterDropdownArray, setClientMatterDropdownArray] = React.useState([])
  const [selectedMatter, setSelectedMatter] = React.useState(null)


  useEffect(() => {
    !isEmptyNullUndefined(ClientsMatters) && setClientMatterDropdownArray(ClientMatterDropDownArrayCreator(ClientsMatters))
  }, [ClientsMatters])

  useEffect(() => {
    sendSelectedMatterToParent(selectedMatter)
  }, [selectedMatter, sendSelectedMatterToParent])

  return (

    !isEmptyNullUndefined(address) && !isEmptyNullUndefined(coordinates.lat) &&
    <div className='container'>
      <div className='address'><span className='address-header'>Address Found:</span>{address}</div>
      {/* <Button color='red' compact size='large'>Clear</Button> */}
      {matterSelector && <Dropdown
        placeholder='Select Client/Matter'
        name="ClientMatter"
        width={6}
        search
        selection
        options={clientMatterDropdownArray}
        clearable
        onChange={async (e, { name, value }) => {
          // console.log(name, value, e)
          setSelectedMatter(isEmptyNullUndefined(value) ? null : parseInt(value.split(',')[1]))
        }}
      />}
      <div>
        <div className='Jbutton clearButton' onClick={() => { sendAddressToParent(''); sendCoordinatesToParent({ lat: null, lng: null }); setSelectedMatter(null) }}>Clear</div>
        <div
          className={(matterSelector && isEmptyNullUndefined(selectedMatter)) ? 'disabledButton acceptButtonTooltip' : 'Jbutton acceptButton'}
          onClick={() => {
            if (matterSelector && isEmptyNullUndefined(selectedMatter)) {}
            else {
              sendSelectedLocationToParent(coordinates, address, selectedMatter)
              //Then reset everything
              sendAddressToParent(''); sendCoordinatesToParent({ lat: null, lng: null }); setSelectedMatter(null)
            }
          }} 
            >
          Accept
          {(matterSelector && isEmptyNullUndefined(selectedMatter)) && <span className="tooltiptext">Must pick a client/matter!</span>}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ clients, login }) => ({
  clients, login
})

export default connect(mapStateToProps)(AddLocation)

let ClientMatterDropDownArrayCreator = (ClientsMatters) => {
  // console.log('This is clientMatterArray: ', ClientsMatters)
  let array = ClientsMatters.map(result => {
    // console.log('This is result: ', result)
    var obj = {}
    obj.key = `CMID${result[0]},${result[2]}`
    obj.text = result[1] + ' -- ' + result[3]
    obj.value = result[0] + ',' + result[2] // + ',' + result[4]
    // obj.employee = result[4]
    return obj
  }
  )
  // console.log('This is ClientMatterDropDownArray: ', array)
  return array
}