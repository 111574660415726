const initialstate = {
  zoom: 10,
  currentZoomLevel: 10,
  currentLatitude: 40.73,
  currentLongitude: -73.93,
}


export default (state =
  initialstate,
  action) => {
  switch (action.type) {

    case 'CURRENT_ZOOM_LEVEL':
      // console.log('Changing zoom level....', action.payload)
      return {
        ...state,
        currentZoomLevel: action.payload
      }
    case 'CURRENT_LATITUDE':
      // console.log('Changing latitude....', action.payload)
      return {
        ...state,
        currentLatitude: action.payload
      }
    case 'CURRENT_LONGITUDE':
      // console.log('Changing longitude....', action.payload)
      return {
        ...state,
        currentLongitude: action.payload
      }
    default:
      return state
  }
} 