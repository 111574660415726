import React, { Component } from 'react'
// import { HashLink as Link } from 'react-router-hash-link'
import { szenokPostAPI } from '../helper/szenokPostAPI'

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

// import AddCounterparty from './Component-AddCounterparty';
// import CounterpartyInfo from './Component-CounterpartyInfo';
// import AddLawFirms from './Component-AddLawFirm';
// import AddPerson from './Component-AddPerson';
// import AddNote from './Component-AddNote';
import ReactTableMaker from '../helper/ReactTableMaker'


import queryString from 'query-string'

//For testing purposes only.  Delete later
// import DisplayState from '../helper/DisplayState';

// import ErrorMessage from '../helper/MessageBar'
// import TableMaker from '../helper/TableMaker.js'
// import SeachBarMaker from '../helper/SearchBarMaker.js'
import { isEmptyNullUndefined } from '../helper/Misc';

class PersonInfo extends Component {

  state = {
    TokenReady: false,
    Loaded: false,
    querystr: {},
    personName: '',
    personEmail: '',
    LawFirmsForPerson: [],
    CounterpartiesForPerson: [],
  }


  componentDidMount() {
    //Wait on fetching to make sure that they Okta token is passed down from the parent
    if (this.props.login.currentToken.length > 0) {
      this.setState({ TokenReady: true })
    }
    const querystr = queryString.parse(this.props.location.search)
    // console.log('Logging querystr: ', querystr)
    this.setState({ querystr: querystr })
  }


  // //This is to get rid of the unmounted component no op (as well as that signal: this.abortController stuff below))
  // abortController = new AbortController()
  // componentWillUnmount() {
  //   this.abortController.abort()
  // }

  async componentDidUpdate(prevProps, prevState) {
    //If we did not have a token initially, see if it's come in and then run fetch.
    if (this.props.login.currentToken.length > 0 && !this.state.TokenReady && !this.state.Loaded) {
      this.setState({ TokenReady: true })
    }
    if (prevState.querystr !== this.state.querystr) {
      this.getPersonName()
      this.getLawFirmsForPerson()
      this.getCounterpartiesForPerson()
      // if (!isEmptyNullUndefined(this.state.querystr.personID)) {
      //   this.setState({
      //     // lawFirmName: this.props.clients.lawfirmsarray.find(lawfirm => lawfirm[0] === parseInt(this.state.querystr.lawFirmID))[1]
      //   })
    }
    if (prevProps.login.refreshToken !== this.props.login.refreshToken) {
      setTimeout(() => {
        this.getPersonName()
        this.getLawFirmsForPerson()
        this.getCounterpartiesForPerson()
      }, 200)
    }
  }

  getPersonName = async () => {

    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getPerson', {
      fetchstr: 'getSpecificPerson',
      IDNumber: this.state.querystr.personID
    },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getCounterpartyInfo: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      personName: fetchresponse.payload[0].person_name,
      personEmail: fetchresponse.payload[0].person_email,
    })

  }


  getLawFirmsForPerson = async () => {
    //  console.log('In getLawFirmInfo...', this.state.querystr.lawFirmID)
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getPerson', {
      fetchstr: 'getClientsForPerson',
      IDNumber: this.state.querystr.personID
    },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getLawFirmInfo: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      LawFirmsForPerson: fetchresponse.payload.map(item => {
        return {
          ...item,
          // employee_name: this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[2]
        }
      })
    })
  }

  getCounterpartiesForPerson = async () => {
    //  console.log('In getLawFirmInfo...', this.state.querystr.lawFirmID)
    this.props.fetchResetState('all')
    let fetchresponse = await szenokPostAPI('getPerson', {
      fetchstr: 'getTycoonsForPerson',
      IDNumber: this.state.querystr.personID
    },
      this.props.login.currentToken)
    // console.log('Now printing fetchresponse for getLawFirmInfo: ', fetchresponse)
    !isEmptyNullUndefined(fetchresponse.payload) && this.setState({
      CounterpartiesForPerson: fetchresponse.payload.map(item => {
        return {
          ...item,
          // employee_name: this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[1] + ' ' + this.props.clients.employeesarray.find(employee => employee[0] === item.employee_id)[2]
        }
      })
    })
  }



  render() {
    return (
      <div>
        <h1 style={{ float: 'left' }}>Person Information For:</h1>
        <h2 style={{ marginLeft: '320px' }}>
          <Icon name='male' size='large' />
          <span style={{ color: '#9ca7b8' }}>
            {this.state.personName}  [{this.state.personEmail}] (ID: {this.state.querystr.personID})
        </span>
        </h2>

        {isEmptyNullUndefined(this.state.LawFirmsForPerson) && isEmptyNullUndefined(this.state.CounterpartiesForPerson) &&
          <h3>No law firms or counterparties to show for this person.</h3>
        }

        {/* This will show all clients/matters and law firms that this firm was involved in */}
        {!isEmptyNullUndefined(this.state.LawFirmsForPerson) &&
          <React.Fragment>
            <h4 >Client/Matters for This Person (as lawyer)</h4>
            <ReactTableMaker passedData={this.state.LawFirmsForPerson} passedColumns={PersonMattersColumns(this.props.history)}
              handleClick={(rowID, cellID, rowValues) => {
                // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
              }}
              includeSearchBar={false}
            />
          </React.Fragment>
        }

        {/* This will show all business positions this person holds */}
        {!isEmptyNullUndefined(this.state.CounterpartiesForPerson) &&
          <React.Fragment>
            <h4 >Business Roles for this Person (as businessman)</h4>
            <ReactTableMaker passedData={this.state.CounterpartiesForPerson} passedColumns={CounterpartyPersonColumns(this.props.history)}
              handleClick={(rowID, cellID, rowValues) => {
                // console.log(`Outside here and click on row ${rowID} and column ${cellID}.  This is rowValues: ${JSON.stringify(rowValues)}`)
              }}
              includeSearchBar={false}
            />
          </React.Fragment>
        }

        {/* <DisplayState state={this.state} /> */}

      </div>
    )
  }
}

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default connect(mapStateToProps, mapDispatchToProps)(PersonInfo)


function plusFontWeight(e) { e.target.style.fontWeight = 'bold'; e.target.style.color = "#ff8000" }
function minusFontWeight(e) { e.target.style.fontWeight = ''; e.target.style.color = "" }

let PersonMattersColumns = (history) => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_name}${row.original.client_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Matter Name</span>,
      accessor: 'matter_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Counterparty Represented</span>,
      accessor: 'counterparty',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/counterparty/${row.original.counterparty}?counterparty=${row.original.counterparty}&counterpartyID=${row.original.counterparty_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Role of Counterparty</span>,
      accessor: 'role',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Law Firm (where person employed)</span>,
      accessor: 'law_firm',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/lawfirm/${row.original.law_firm}?lawfirm=${row.original.law_firm}&lawFirmID=${row.original.law_firm_id}`)}
        >
          {cell.value}
        </span>
    },

  ]
}

let CounterpartyPersonColumns = (history) => {
  return [
    {
      Header: () => <span style={{ 'float': 'left' }}>Client Name</span>,
      accessor: 'client_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/client/${row.original.client_id}?cname=${row.original.client_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Matter Name</span>,
      accessor: 'matter_name',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/matter/${row.original.matter_id}?matter=${row.original.matter_name}&matterID=${row.original.matter_id}`)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Counterparty (where person employed)</span>,
      accessor: 'counterparty',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft',
      Cell: ({ cell, row }) =>
        <span
          onMouseOver={plusFontWeight} onMouseLeave={minusFontWeight}
          style={{ 'float': 'left', cursor: 'pointer' }}
          onClick={() => history.push(`/counterparty/${row.original.counterparty}?counterparty=${row.original.counterparty}&counterpartyID=${row.original.counterparty_id}`)}
        // onClick={() => console.log('Yo, this is the value: ', cell.value)}
        >
          {cell.value}
        </span>
    },
    {
      Header: () => <span style={{ 'float': 'left' }}>Role</span>,
      accessor: 'role',
      show: true,
      sortType: 'alphanumeric',
      Filter: 'defaultColumnFilterLeft'
    },

  ]
}
