import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

import { bindActionCreators } from 'redux'
import * as loginActions from '../../actions/loginActions'
import * as fetchActions from '../../actions/fetchActions'
import { connect } from 'react-redux';

import { szenokPostAPI } from '../helper/szenokPostAPI'
// import { reduxupdate } from '../helper/ReduxHelp'
// import { getHighlightedText, filterSimpleInput, filterSimpleInputOn2, ListMakerSimpleClickableNameEmail } from '../helper/FormHelper'
import { isEmptyNullUndefined } from '../helper/Misc'
// import NumberFormat from 'react-number-format'

import { Formik } from 'formik'
import * as Yup from 'yup';
import { Form, Header, Label, Divider, Button, Segment } from 'semantic-ui-react';
// import DatePicker from 'react-datepicker'
import isEmpty from 'lodash/isEmpty';
// import _ from "lodash"
import { has, get } from 'lodash';

//For testing purposes only.  Delete later
// import DisplayFormikState from '../helper/DisplayFormikState';
// import DisplayState from '../helper/DisplayState';

// import queryString from 'query-string'

const initialState = {
  visible: false,
  ExistingPersons: [],
  PName: null,
  PEmail: null,
  showAddPersonForm: false,
  PName2Regex: [],
  PName2RegexSpecific: '',
  PNameArray: [],
}

class AddNote extends Component {

  state = initialState

  componentDidMount() {
    this.props.fetchResetState('all')
    // console.log('These are the props on loading AddPerson: ', this.props)
  }

  //Just for testing.  To prevent submission of form on button click
  onClickButton(event, status) {
    event.preventDefault()
    // console.log('This is status: ', has(status, 'error2s'))
    // console.log('This is date: ', get(values, 'PartnerPick'))
  }

  errorTag = (errors, touched, status, Field, YupMessage, JOIMessage) => {
    return (
      <React.Fragment key={Field + '1'}>
        {/* Errors:  The first one is for Yup errors in form.  The second one is for Joi validation errors received back from server. */}
        {errors[Field] && touched[Field] ?
          errors[Field] && touched[Field] && <Label pointing color='red'>{YupMessage}{errors[Field]} |YUP issue|</Label>
          :
          get(status, `errors.${Field}`, false) && <Label pointing color='red'>{JOIMessage}{status.errors[Field]} |JOI Issue|</Label>
        }
      </React.Fragment>
    )
  }

  render() {

    const initialValues = {
      Note: null,
    }

    const initialStatus = {
      errors: {},
    }

    // const transitionduration = 1300


    return (
      <div>
        <Header as='h3'>{this.props.headingForForm}</Header>


        <Formik

          // getHighlightedText={getHighlightedText}
          // addPersonAction={this.addPersonAction}
          // // test1={test1}
          // test2={this.test2}
          initialValues={initialValues}
          initialStatus={initialStatus}
          validationSchema={PersonSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={async (values, actions) => {
            try {

              //Clear fetcherrors, info and such
              this.props.fetchResetState('all')

              // console.log('Sending out this type: ', this.props.noteType)
              // console.log('This is the matter: ', queryString.parse(this.props.location.search))
              // this.setState({ CParty2Regex: [], CParty2RegexSpecific: '' })
              let fetchresponse = {}
              fetchresponse = await szenokPostAPI('addNote', {
                UserID: this.props.login.currentUserID,
                Note: values.Note,
                CPartyMatterID: this.props.selectedCPartyMatterID,
                CpartyID: this.props.selectedCParty,
                LawFirmMatterID: this.props.LawFirmID,
                LawFirmID: this.props.selectedLawFirmID,
                MatterID: this.props.MatterID,
                Type: this.props.noteType,
              }, this.props.login.currentToken)
              // console.log('Now printing fetchresponse for addNote: ', fetchresponse)
              actions.setSubmitting(false)

              if (fetchresponse.validationerrors !== null) {
                actions.setStatus({ errors: JSON.parse(JSON.stringify(fetchresponse.validationerrors)) })
              }

              //Now we've added the person so add to the lawyers_counterparty_matter
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                // console.log('This is the ID of the inserted row: ', fetchresponse.insertID)
              }

              //Check if everything has been successfull and then put in success notification
              if (fetchresponse.express && fetchresponse.validation && fetchresponse.mysql) {

                // console.log('Successfully added a counterparty...')

                // //Update the counterparty store
                // reduxupdate('counterparty')

                //Rest of stuff
                this.setState(prevState => ({ visible: !prevState.visible }))
                actions.resetForm({ ...initialValues })
                this.setState(initialState)
                this.props.getLawyers()
                this.props.getTycoons()
                this.props.getNotes()
                this.props.showCPartyFormToggle(false)
                this.props.showAddLawyerFormToggle(false)
                this.props.showAddTycoonFormToggle(false)
                this.props.showAddNoteFormToggle(false)
              }
            }
            catch (error) {
              console.log('In catch in Component-AddNote and this is the error: ', error)
              let obj = []
              let str = 'Error in /Component-AddNote ' + error.name === undefined ? error : `${error.name}: ${error.message}`
              obj.push(str)
              this.props.fetchErrors(obj)
              actions.setSubmitting(false)
            }
          }}
          onReset={(actions) => {
            //actions.setStatus({ msg: 'Set some arbitrary status or data' })
            //Not sure what I want yet.  Cancel will reset state.  But maybe in future I want it to get rid of modal/component.
          }}
        >
          {({
            values,
            touched,
            status,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            handleReset,
            setStatus,
            setErrors,
            setFieldTouched,
          }) => (
              <div>
                <Segment basic>
                  {/* This is the selectedCParty: {this.props.selectedCParty}
                      <br/>
                      This is CPartyMatterID: {this.props.selectedCPartyMatterID} */}
                  <Form onSubmit={handleSubmit} noValidate autoComplete='off'>
                    <Form.Group>

                      {/* Add the note */}
                      <Form.Field width={14}>
                        <Form.TextArea
                          name="Note" type="text"
                          label={`Note for ${this.props.typeOfNote}`}
                          loading={this.state.success}
                          required
                          placeholder={`Note for ${this.props.typeOfNote}`}
                          error={(errors.Note && touched.Note) || (has(status, 'errors.Note'))}
                          onChange={async (e) => {
                            await handleChange(e);
                            this.setState({ Note: e.target.value })
                          }}
                          onBlur={handleBlur}
                          value={!isEmptyNullUndefined(values.Note) ? values.Note : ''} />
                        {this.errorTag(errors, touched, status, 'Note')}
                      </Form.Field>
                    </Form.Group>

                    <Divider />
                    <Button.Group size='large'>
                      <Button type='button' icon='cancel'
                        onClick={() => {
                          handleReset();
                          this.setState({ Note: '' })
                          this.props.showAddNoteFormToggle(false)
                        }}
                        color='red'
                        content='Cancel'></Button>
                      <Button.Or />
                      <Button color='teal'
                        onClick={() => { this.setState({ Note: '' }); handleReset() }}
                        type='button'
                        disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Form'>
                      </Button>
                      <Button.Or />
                      <Button color='orange'
                        onClick={() => { setStatus({}); setErrors({}) }}
                        type='button'
                        //disabled={(!dirty && isEmpty(errors)) || isSubmitting}
                        content='Clear Error Notifications'>
                      </Button>
                      <Button.Or />
                      <Button type='submit'
                        // disabled={isSubmitting}
                        //Eventually use the below, the one above is just for testing
                        disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(status.errors)}
                        icon='upload'
                        loading={isSubmitting}
                        floated='right'
                        color={dirty ? 'green' : 'grey'}
                        content={`Add ${this.props.typeOfNote} Note`}></Button>
                    </Button.Group>
                    {/* <DisplayState state={this.state} />
                    <DisplayFormikState /> */}
                  </Form>
                </Segment>
              </div>
            )}
        </Formik>

      </div>
    )
  }
}


const PersonSchema = Yup.object().shape({
  Note: Yup
    .string()
    .min(3, 'Note must be at least 3 characters.')
    .max(10000, 'Person Name cannot exceed 10,000 characters.')
    .required('Must have a note for this.'),
})

const mapStateToProps = state => ({ ...state })
function mapDispatchToProps(dispatch) { return bindActionCreators(Object.assign({}, loginActions, fetchActions), dispatch) }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNote))

AddNote.defaultProps = {
  showCPartyFormToggle: () => { },
  showAddLawyerFormToggle: () => { },
  showAddTycoonFormToggle: () => { },
  showAddNoteFormToggle: () => { },
  getLawyers: () => { },
  getTycoons: () => { },
  getNotes: () => { },
  headingForForm: 'Add Note',
  typeOfNote: 'Note',
}


